import React, { useEffect, useState } from "react";
import { useTransition, config, animated } from "react-spring";
import axios from "axios";
import "./JoyPage.scss";
const JoyPage = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [joyImages, setJoyImages] = useState<Array<any>>([]);
  const transitions = useTransition(
    joyImages[imageIndex],
    (item) => item?.Url,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    }
  );
  useEffect(() => {
    const fetchJoyImages = async () => {
      const images = await axios({
        method: "GET",
        url: "/IBYPublicImages",
      });
      //   console.log(images.data);
      setJoyImages([...images.data]);
    };

    fetchJoyImages();
  }, []);

  const incrementImageIndex = () => {
    setImageIndex((imageIndex) => (imageIndex + 1) % joyImages.length);
  };

  useEffect(() => {
    if (joyImages.length > 0) {
      setInterval(incrementImageIndex, 3000);
    }
    return () => {
      clearInterval();
    };
  }, [joyImages]); // eslint-disable-line react-hooks/exhaustive-deps

  // Music works only if user interacts with dom before music is loaded
  useEffect(() => {
    const music = new Audio("/mixkit-dance-with-me-3.mp3");
    music.play();
    return () => {
      music.pause();
    };
  }, []);

  return transitions.length > 0 ? (
    <>
      {/* <audio src={"/mixkit-dance-with-me-3.mp3"} muted loop /> */}
      {transitions.map(({ item, props, key }) => (
        <>
          <div className="bg-black"></div>
          <animated.div
            className="joy-bg"
            key={key}
            style={{ ...props, backgroundImage: `url(${item?.Url})` }}
          >
            <div className="joy-description">
              <p style={{ padding: "1rem 0" }}>{item?.Description}</p>
            </div>
          </animated.div>
        </>
      ))}
    </>
  ) : (
    <></>
  );
};

export default JoyPage;
