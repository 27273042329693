import React, { useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { FiArrowLeft } from "react-icons/fi";
import { observer, inject } from "mobx-react";
import { motion } from "framer-motion";
import IframeResizer from "iframe-resizer-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { postVariants } from "../../components/util";

const PaymentPage: React.FC<any> = ({
  authStore,
  navigationStore,
  ecomStore,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.onmessage = (event: MessageEvent) => {
      if (
        typeof event.data === "string" &&
        event.data.toUpperCase() === "SUCCESS"
      ) {
        ecomStore.onCheckout();
        navigationStore.push("/checkout/confirmation");
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("PaymentPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <div className="cartIframe">
          <IframeResizer
            log={false}
            scrolling={false}
            src={ecomStore.cart.PaymentUrl}
            style={{ width: 1, minWidth: "100%", minHeight: 1000, border: 0 }}
          />
        </div>
      </motion.div>

      <Row className="align-items-center">
        <Col sm="6" className="text-center text-sm-right order-sm-2"></Col>
        <Col
          sm="6"
          className="text-center text-sm-left order-sm-1 mt-3 mt-sm-0"
        >
          <Button
            type="button"
            color="default-link"
            onClick={() => navigationStore.replace("/checkout/orderreview")}
          >
            <FiArrowLeft className="mr-2" />
            {`Previous page`}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default inject(
  "authStore",
  "navigationStore",
  "ecomStore"
)(observer(PaymentPage));
