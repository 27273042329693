import React, { useState, useEffect } from 'react';
import { observer,inject } from 'mobx-react'
import { CustomInput, Button, Label, Alert } from 'reactstrap';
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { AxiosError } from 'axios';
import Skeleton from 'react-loading-skeleton';
import { 
  AiOutlinePrinter
} from 'react-icons/ai';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

const ContractsPage: React.FC<any> = ({authStore, clientStore}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [agree, setAgree] = useState(false);
  const [pin, setPin] = useState('');
  const [err, setErr] = useState('');
  const [loading,setLoading] = useState(true);
  const [getContracts, setContracts] = useState(clientStore.contracts);

  useEffect(()=>{
    console.log(getContracts);
  },[getContracts])

  useEffect(()=>{
    getContract();
  },[selectedIndex, clientStore])

  async function getContract(){
    setLoading(true);
    setErr('');
    return clientStore.getContractDetail(getContracts[selectedIndex].Key).then(()=>setLoading(false));
  }

  async function acceptContract(e:any){
    setErr('');
    e.preventDefault();
    e.stopPropagation();
    if(agree){
      await clientStore.acceptContract(pin)
      .then(async()=> {
        await clientStore.getClientInfo().then( (res:any) => {
          setContracts(res.Contracts);
        });
        getContract()
      })
      .catch((err:AxiosError)=>{
        setErr(t('ContractsPage.invalidPIN'))
      });
    }
  }

  if(getContracts.length < 1) {
    return (<></>)
  }

  return (
    <>
      <Helmet>
        <title>{t('ContractsPage.urlTitle')} {getContracts[selectedIndex].Description} {authStore.companyName || authStore.companyName !== '' ? (
          '| ' + authStore.companyName
        ) : ' '}</title>
      </Helmet>
      <section className="page-section page-section-2column">
        <div className="page-section-main">
          <h1 className="subtitle">{t('ContractsPage.title')}</h1>
          <h2 className="h1">{getContracts[selectedIndex].Description}</h2>
          <ul className="list-unstyled meta" role="presentation">
            <li>{t('ContractsPage.uploaded')} {getContracts[selectedIndex].UploadedDateStringFormatted} 
            {getContracts[selectedIndex].ActionRequired &&
            <span className={`badge-status ml-3 ${getContracts[selectedIndex].ActionRequired?'badge-danger':'badge-primary'}`}>
              {getContracts[selectedIndex].Status}
              </span>
            }
            </li>
            {!getContracts[selectedIndex].ActionRequired &&
              <li className="meta">{t('ContractsPage.accepted')} {getContracts[selectedIndex].AcceptedDate} 
              <span className={`badge-status ml-3 ${getContracts[selectedIndex].ActionRequired?'badge-danger':'badge-primary'}`}>
                {getContracts[selectedIndex].Status}
                </span>
            </li>
            }
          </ul>
          <p><Button color="default-link-bold" className="order-xl-1 d-print-none" onClick={()=>window.print()}><AiOutlinePrinter size="18" className="mr-2" />{t('ContractsPage.printContract')}</Button></p>
              {(!!getContracts[selectedIndex] && !loading) ?
              <div dangerouslySetInnerHTML={{__html: clientStore.contractDetail.Html}} className="forceHtml"></div>
              :
              <>
                <p style={{ maxWidth: '100%', width: 250}}><Skeleton count={2} width={250} /></p>
                <p><Skeleton count={3} /></p>
                <p><Skeleton count={5} /><Skeleton width={250} /></p>
              </>
              }

              {(getContracts[selectedIndex]?.ActionRequired && !loading) &&
                <>
                  <form className="mt-5" onSubmit = {acceptContract}>
                    <CustomInput 
                    onClick = {(e)=>setAgree(e.currentTarget.checked)} 
                    type="switch" 
                    id="checkboxAgree" 
                    className="font-weight-bold mb-5" 
                    label={t('ContractsPage.formLabel')} />
                    {clientStore.contractDetail.RequirePIN&&
                    <>
                      <Label htmlFor = "pin">{t('ContractsPage.enterPin')}</Label>
                      <input onChange = {(e:any)=>setPin(e.target.value)}  id="pin" className="form-control font-weight-bold" />
                    </>

                    }
                    {!!err &&
                    <Alert color= "danger" className="mt-2">{err}</Alert>

                    }
                    <Button color="warning px-5" className="mt-4" disabled = {clientStore.contractDetail.RequirePIN?(!agree || !!!pin):!agree}>{t('ContractsPage.confirm')}</Button>
                  </form>
                </>
              }

        </div>
        <aside className="page-section-aside d-print-none">


        {getContracts.map((c:any, i:number)=>(
                    <div key = {i} className={`card-form ${i===selectedIndex?'card-form-active':''}`} onClick = {()=>setSelectedIndex(i)}>
                    <p className="mb-0"><span className={`badge-status ${c.ActionRequired?'badge-danger':'badge-primary'}`}>{c.Status}</span></p>
                    <h4>{c.Description}</h4>
                    <ul className="list-icon">
                      <li>
                        <AiOutlineCalendar />
                        {c.SessionDate}
                      </li>
                      <li>
                        <AiOutlineClockCircle />
                        {`${c.SessionStartTime} to ${c.SessionEndTime}`}
                      </li>
                    </ul>
                    <ul className="list-unstyled meta">
                      <li>
                      {t('ContractsPage.uploaded')} {c.UploadedDateStringFormatted}
                      </li>
                      {!getContracts[i].ActionRequired || c.AcceptedDate ?
                        <li>{t('ContractsPage.accepted')} {c.AcceptedDate}</li>
                        : ''
                      }
                    </ul>
                  </div>
        ))}
        </aside>
      </section>
    </>
  );
}

export default inject(
  'authStore',
  'clientStore', 
  )(observer(ContractsPage))
