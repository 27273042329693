import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { AiOutlinePrinter, AiOutlineForm } from "react-icons/ai";
import { MdCropRotate, MdFormatShapes } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { MdClose } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Helmet } from "react-helmet";
import * as Scroll from "react-scroll";
import { useTranslation } from "react-i18next";

import { useQuery } from "../../components/util";
import { InvoiceLineItem } from "../../interfaces/invoice.int";
import { ShippingMethod } from "../../interfaces/shipping.int";
import ProgressSpinner from "../../components/spinner/spinner";
import noImg from "../../components/imageTile/broken-img.png";

const OrderDetailsPage: React.FC<any> = ({
  authStore,
  ecomStore,
  sessionStore,
  navigationStore,
  createOrderStore,
  compositeStore,
}) => {
  const { t } = useTranslation();
  const query = useQuery();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [shipping, setShipping] = useState<any>({});
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [btnConfirm, setBtnConfirm] = useState(false);

  let scroll = Scroll.animateScroll;
  let scroller = Scroll.scroller;

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  async function getData() {
    const id = query.get("id");
    if (!id) {
      navigationStore.replace("/orders");
    }
    await ecomStore.getOrderHistoryDetail(id);
    const order = ecomStore.cartInvoiceItems;
    //console.log('check order');
    //console.log(order);

    if (ecomStore.activeInvoiceError) {
      navigationStore.replace("/orders");
    } else {
      setShipping(
        ecomStore.shippingMethods?.find(
          (m: ShippingMethod) => m.Key === order.ShippingMethodKey
        )
      );
    }
  }

  useEffect(() => {
    setOrderDetail(ecomStore.activeInvoice);
    //console.log(ecomStore.activeInvoice);
  }, [ecomStore.activeInvoice]);

  useEffect(() => {
    if (showMakePaymentModal) {
      window.onmessage = (event: MessageEvent) => {
        ecomStore.getOrderHistoryDetail(query.get("id"), true);
        togglePayment();
      };
    } else {
      window.onmessage = () => null;
    }
  }, [showMakePaymentModal]);

  function togglePayment() {
    setShowMakePaymentModal(!showMakePaymentModal);
  }

  useEffect(() => {
    getData();
    scroll.scrollToTop();
  }, []);

  const scrollPosition = (ref: any) => {
    scroller.scrollTo(ref, {
      smooth: "easeInOutQuad",
      offset: isBreakDownSm ? -130 : -30,
    });
  };

  const setAsCheckout = () => {
    const inValid = ecomStore.validateCart(ecomStore?.cartInvoiceItems);
    if (inValid?.key) {
      scrollPosition(`itemKey${inValid.key}`);
    } else {
      toggleConfirm();
    }
  };

  function toggleConfirm() {
    setModalConfirm(!modalConfirm);
  }

  const confirmPrepaidOrderSubmit = async () => {
    var prepaidOrderKey = query.get("id");
    const req = {
      InvoiceKey: prepaidOrderKey,
      PrepaidOrder: false,
    };
    setBtnConfirm(true);
    await ecomStore
      .updateCartMeta(req, prepaidOrderKey, true)
      .then(() => {
        toggleConfirm();
        setBtnConfirm(false);
      })
      .catch(() => {
        setBtnConfirm(false);
      });
  };

  async function handleComposite(itm: any) {
    compositeStore.compositeCartItem = {
      itemKey: itm.Key,
      compositeKey: itm.CompositeKey,
      compositeTemplate: itm.compositeTemplateKey,
    };

    if (itm.CompositeKey) {
      await compositeStore.getCompositeByKey(itm.CompositeKey);
    } else {
      await compositeStore.getCompositeTemplate(itm.compositeTemplateKey);
    }
  }

  function thumbnail(itm: any, i: number) {
    if (itm.PackageHeader) {
      return;
    }
    let mainImage: string = noImg;

    if (!ecomStore.getAllowImageSelection(itm)) {
      return "";
    }

    if (itm.compositeTemplateKey) {
      if (
        typeof itm?.compositeImage === "object" &&
        Array.isArray(itm?.compositeImage)
      ) {
        mainImage = Object.assign({}, itm?.compositeImage)[0]?.ThumbUrl;
      } else if (itm?.ImageUrl) {
        mainImage = itm.ImageUrl;
        if (itm?.CropQueryString) {
          mainImage += itm.CropQueryString;
        }
      }
    } else if (itm?.FileName) {
      // mainImage = sessionStore.getImgUrl(itm.SessionKey, itm.FileName);
      mainImage = itm.ImageUrl;
      if (itm?.CropQueryString) {
        mainImage += itm.CropQueryString;
      }
    }

    return (
      <>
        <figure
          className={`thumb-edit${
            ecomStore.imageUpdate === itm.Key ? " loading" : ""
          }`}
        >
          <div
            className="thumb"
            style={{
              backgroundImage: `url(${
                `${mainImage}${
                  !!itm?.ColorizationKey
                    ? `&colorizationKey=${itm.ColorizationKey}`
                    : ""
                }` || noImg
              }`,
            }}
          >
            <img src={`${mainImage}`} alt={itm.Description} />
            {ecomStore.imageUpdate === itm.Key && (
              <div className="spinner">
                <Spinner color="primary" size="sm" />
              </div>
            )}
          </div>

          {!!orderDetail.PrepaidOrder && (
            <ul role="presentation">
              {itm.compositeTemplateKey &&
              authStore.EnableCompositeTemplates ? (
                <li>
                  <Button
                    onClick={() => {
                      ecomStore.scrollCart = itm.Key;
                      handleComposite(itm);
                      createOrderStore.setStepNumber(7);
                      createOrderStore.toggleSlider();
                    }}
                  >
                    <MdFormatShapes size="22" />
                    <span>Customize</span>
                  </Button>
                </li>
              ) : (
                <>
                  <li>
                    <Button
                      onClick={() => {
                        sessionStore.swappedPhoto = itm;
                        ecomStore.swapPhotoCartItemKey = itm.Key;
                        createOrderStore.setStepNumber(5);
                        createOrderStore.toggleSlider();
                      }}
                    >
                      <AiOutlineForm className="color-primary" size="22" />
                      <span className="color-primary">
                        {`${!!itm.FileName ? "Swap" : "Select"} `}
                        {t("OrderDetailsPage.photo")}
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      disabled={!itm.FileName}
                      onClick={() => {
                        ecomStore.setCroppedPhoto(itm.Key, true);
                        createOrderStore.setStepNumber(6);
                        createOrderStore.toggleSlider();
                      }}
                    >
                      <MdCropRotate className="color-primary" size="22" />
                      <span className="color-primary">
                        {t("OrderDetailsPage.setCrop")}
                      </span>
                    </Button>
                  </li>
                </>
              )}
            </ul>
          )}
        </figure>
        {ecomStore?.cartErrors[i]?.includes("compositeRequired") && (
          <p className="text-danger font-weight-normal font-size-sm mt-3">
            {t("OrderDetailsPage.compositeRequired")}
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("cropRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            {t("OrderDetailsPage.cropRequired")}
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("imageRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            {t("OrderDetailsPage.imageRequired")}
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("enhancementRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            Enhancement Required
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("optionRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            Option Required
          </p>
        )}
      </>
    );
  }

  function cartItem(itm: any, i: number) {
    return (
      <Tr
        key={`cartItem${i}`}
        className="parent-product"
        name={`itemKey${itm.Key}`}
      >
        <Td className="th-product-thumbs" scope="row">
          <div className="mb-2 description">
            <strong>{itm.Description}</strong>{" "}
            <span className="font-weight-normal text-muted">
              {ecomStore.getAllowImageSelection(itm) &&
                !itm.PackageHeader &&
                `(Image ${itm.ImageNumber})`}
            </span>
          </div>
          {thumbnail(itm, i)}
        </Td>
        <Td className="td-price text-right">{itm.PriceStringFormatted}</Td>
        <Td className="text-center td-product-quantity">{itm.Quantity}</Td>
        <Td className="text-right">
          {!itm.PackageItemKey && itm.ExtendedStringFormatted}
        </Td>
      </Tr>
    );
  }

  function packageComponent(itm: any, i: number) {
    return (
      <Tr key={i} className="sub-product" name={`itemKey${itm.Key}`}>
        <Td className="th-product-thumbs" scope="row">
          {ecomStore.getAllowImageSelection(itm) ? (
            <div
              className="d-flex description"
              style={{ paddingRight: isBreakDownSm ? "5rem" : "" }}
            >
              <div
                style={{
                  maxWidth: 100,
                  flex: "0 0 100px",
                  marginRight: "1rem",
                }}
              >
                {thumbnail(itm, i)}
              </div>
              <div>
                {itm.Description}{" "}
                <span className="font-weight-normal text-muted">
                  {itm.ImageNumber && `(Image ${itm.ImageNumber})`}
                </span>
              </div>
            </div>
          ) : (
            <div className="description">{itm.Description}</div>
          )}
        </Td>
        <Td className="td-price text-right responsive-hidden"></Td>
        <Td
          className={`text-center${itm.Quantity ? " td-product-quantity" : ""}`}
        >
          {itm.Quantity}
        </Td>
        <Td className="td-subtotal text-right responsive-hidden"></Td>
      </Tr>
    );
  }

  function packageComponentPrepaid(itm: any, i: number) {
    return (
      <Tr key={i} className="sub-product" name={`itemKey${itm.Key}`}>
        <Td className="th-product-thumbs" scope="row">
          <div className="mb-2 description">
            <strong>{itm.Description}</strong>{" "}
            <span className="font-weight-normal text-muted">
              {ecomStore.getAllowImageSelection(itm) &&
                itm.ImageNumber &&
                `(Image ${itm.ImageNumber})`}
            </span>
          </div>
          {thumbnail(itm, i)}
        </Td>
        <Td className="td-price text-right responsive-hidden"></Td>
        <Td
          className={`text-center${itm.Quantity ? " td-product-quantity" : ""}`}
        >
          {itm.Quantity}
        </Td>
        <Td className="td-subtotal text-right responsive-hidden"></Td>
      </Tr>
    );
  }

  return (
    <>
      <ProgressSpinner
        showSpinner={ecomStore.activeInvoiceLoading}
        color="primary"
      />

      {!ecomStore.activeInvoiceLoading && (
        <>
          {orderDetail?.InvoiceNumber && (
            <Helmet>
              <title>
                {t("OrderDetailsPage.urlTitle")} #{orderDetail?.InvoiceNumber}{" "}
                {authStore.companyName || authStore.companyName !== ""
                  ? "| " + authStore.companyName
                  : " "}
              </title>
            </Helmet>
          )}
          <section className="page-section pb-0">
            <Link
              to={{
                pathname: "/orders",
                hash: !!orderDetail.PrepaidOrder ? "#preorders" : "",
                state: { scrollToInvoice: orderDetail?.InvoiceNumber },
              }}
            >
              <Button color="default-link" className="d-print-none">
                <FiArrowLeft size="18" className="mr-2" />
                {t("OrderDetailsPage.viewOrders")}
              </Button>
            </Link>

            <Row className="align-items-end mt-3 mb-5">
              <Col xl="6" className="mb-3 mb-xl-0">
                <h1 className="mb-md-2">
                  {t("OrderDetailsPage.order")} {orderDetail?.InvoiceNumber}
                </h1>
                <p>
                  {t("OrderDetailsPage.orderDate")}{" "}
                  {`${orderDetail.InvoiceDate} `}
                  <span className="badge-status badge-primary">
                    {orderDetail.StatusDescription}
                  </span>
                </p>
              </Col>
              <Col
                xl="6"
                className="d-flex align-items-xl-center justify-content-xl-end d-print-none"
              >
                {orderDetail.OpenBalance > 0 && authStore.requirePaymentOnline && (
                  <Button
                    color="primary"
                    onClick={togglePayment}
                    className="px-5 ml-xl-3 mr-3 mr-xl-0 order-xl-2 d-none d-sm-inline-block"
                  >
                    {t("OrderDetailsPage.makePayment")}
                  </Button>
                )}
                <Button
                  color="default-link-bold"
                  className="order-xl-1"
                  onClick={() => window.print()}
                >
                  <AiOutlinePrinter size="18" className="mr-2" />
                  {t("OrderDetailsPage.printOrder")}
                </Button>
              </Col>
            </Row>
          </section>
          <section className="page-section page-section-2column pt-0">
            <div className="page-section-main">
              <h3 className="subtitle">{t("OrderDetailsPage.orderDetails")}</h3>

              <Table className="table table-cart">
                <Thead>
                  <Tr>
                    <Th scope="col">{t("OrderDetailsPage.tableProduct")}</Th>
                    <Th scope="col" className="text-right">
                      {t("OrderDetailsPage.tablePrice")}
                    </Th>
                    <Th scope="col" className="text-right">
                      {t("OrderDetailsPage.tableQty")}
                    </Th>
                    <Th scope="col" className="text-right">
                      {t("OrderDetailsPage.tableTotal")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {!!orderDetail.PrepaidOrder ? (
                    <>
                      {ecomStore?.cartInvoiceItems?.map(
                        (itm: InvoiceLineItem, i: number) =>
                          !!itm.PackageItemKey
                            ? packageComponentPrepaid(itm, i)
                            : cartItem(itm, i)
                      )}
                    </>
                  ) : (
                    <>
                      {ecomStore?.cartInvoiceItems?.map(
                        (itm: InvoiceLineItem, i: number) =>
                          !!itm.PackageItemKey
                            ? packageComponent(itm, i)
                            : cartItem(itm, i)
                      )}
                    </>
                  )}
                </Tbody>

                {!isBreakDownSm && (
                  <tfoot className="cart-footer">
                    <tr>
                      <th scope="row" colSpan={3} className="font-weight-bold">
                        {t("OrderDetailsPage.subtotal")}
                      </th>
                      <td className="text-right font-weight-bold">
                        {orderDetail.SubtotalStringFormatted}
                      </td>
                    </tr>
                    {orderDetail.Promotions?.map((pro: any) => (
                      <tr key={pro.Key}>
                        <th scope="row" colSpan={2}>
                          {t("OrderDetailsPage.promo")} {pro.PromoCode}
                        </th>
                        <td className="text-right" colSpan={2}>
                          ({pro.Description})
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <th scope="row" colSpan={3}>
                        {t("OrderDetailsPage.shipping")}
                      </th>
                      <td className="text-right">
                        {orderDetail.ShippingAmountStringFormatted}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={3}>
                        {t("OrderDetailsPage.tax")}
                      </th>
                      <td className="text-right">
                        {orderDetail.TaxTotalStringFormatted}
                      </td>
                    </tr>
                    <tr className="border-top">
                      <th
                        scope="row"
                        colSpan={3}
                        className="font-weight-bold font-size-lg text-uppercase"
                      >
                        {t("OrderDetailsPage.tableTotal")}
                      </th>
                      <td className="text-right font-weight-bold text-primary font-size-lg">
                        {orderDetail.TotalStringFormatted}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" colSpan={3}>
                        {t("OrderDetailsPage.payment")}
                      </th>
                      <td className="text-right">
                        {orderDetail.PaymentsTotalStringFormatted}
                      </td>
                    </tr>
                    <tr className="border-top">
                      <th scope="row" colSpan={3} className="font-weight-bold">
                        {orderDetail.OpenBalance < 0
                          ? "Refund Due"
                          : "Amount Due"}
                      </th>
                      <td className="text-right font-weight-bold">
                        {orderDetail.OpenBalanceAbsValueStringFormatted}
                      </td>
                    </tr>
                  </tfoot>
                )}
              </Table>

              {isBreakDownSm && (
                <div className="cart-footer">
                  <div className="row">
                    <div className="col-7">
                      {t("OrderDetailsPage.subtotal")}
                    </div>
                    <div className="col-5 text-right">
                      {orderDetail.SubtotalStringFormatted}
                    </div>
                  </div>

                  {orderDetail.Promotions?.map((pro: any) => (
                    <div className="row mt-2" key={pro.Key}>
                      <div className="col-7">
                        {t("OrderDetailsPage.promo")} {pro.PromoCode}
                      </div>
                      <div className="col-5 text-right">
                        ({pro.Description})
                      </div>
                    </div>
                  ))}

                  <div className="row mt-2">
                    <div className="col-7">
                      {t("OrderDetailsPage.shipping")}
                    </div>
                    <div className="col-5 text-right">
                      {orderDetail.ShippingAmountStringFormatted}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-7">{t("OrderDetailsPage.tax")}</div>
                    <div className="col-5 text-right">
                      {orderDetail.TaxTotalStringFormatted}
                    </div>
                  </div>
                  <div className="row mt-2 pt-2 border-top">
                    <div className="col-7 font-weight-bold">
                      {t("OrderDetailsPage.total")}
                    </div>
                    <div className="col-5 text-right">
                      {orderDetail.TotalStringFormatted}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-7">{t("OrderDetailsPage.payment")}</div>
                    <div className="col-5 text-right">
                      {orderDetail.PaymentsTotalStringFormatted}
                    </div>
                  </div>
                  <div className="row mt-2 pt-2 border-top">
                    <div className="col-7 font-weight-bold">
                      {orderDetail.OpenBalance < 0
                        ? "Refund Due"
                        : "Amount Due"}
                    </div>
                    <div className="col-5 text-right font-weight-bold text-primary">
                      {orderDetail.OpenBalanceAbsValueStringFormatted}
                    </div>
                  </div>
                </div>
              )}

              <Row className="d-print-none mt-5">
                {!!orderDetail.PrepaidOrder && (
                  <Col sm="6">
                    <Button
                      color="primary"
                      onClick={setAsCheckout}
                      className={`px-5 mt-2 ${
                        orderDetail.OpenBalance > 0 ? "mr-1" : ""
                      } mr-md-0`}
                    >
                      {t("OrderDetailsPage.finalize")}
                    </Button>
                  </Col>
                )}

                {orderDetail.OpenBalance > 0 && authStore.requirePaymentOnline && (
                  <Col sm="6" className="text-sm-right">
                    <Button
                      color="primary"
                      onClick={togglePayment}
                      className="px-5 mt-2 ml-md-1"
                    >
                      {t("OrderDetailsPage.makePayment")}
                    </Button>
                  </Col>
                )}
              </Row>
            </div>

            {authStore?.accountName !== "tpp" && (
              <aside className="page-section-aside pt-xl-4">
                {orderDetail.ShippingAddressName ||
                orderDetail.ShippingAddressLine1 ||
                orderDetail.ShippingAddressCity ||
                orderDetail.ShippingAddressState ||
                orderDetail.ShippingAddressPostalCode ? (
                  <div className="card-form">
                    <h4 className="subtitle font-size-lg">
                      {t("OrderDetailsPage.shippingInfo")}
                    </h4>
                    <address className="mb-0">
                      {orderDetail.ShippingAddressName}
                      <br />
                      {orderDetail.ShippingAddressLine1}
                      <br />
                      {`${orderDetail.ShippingAddressCity}, ${orderDetail.ShippingAddressState} ${orderDetail.ShippingAddressPostalCode}`}
                    </address>
                  </div>
                ) : (
                  ""
                )}

                {shipping?.Description ? (
                  <div className="card-form">
                    <h4 className="subtitle font-size-lg">
                      {t("OrderDetailsPage.delivery")}
                    </h4>
                    <div>{shipping?.Description}</div>
                    {/* <div>Tracking <a href="#" target="_blank" rel="nofollow noreferrer">#111252342349134024<span className="sr-only">(opens new window)</span></a></div> */}
                  </div>
                ) : (
                  ""
                )}
              </aside>
            )}
          </section>
        </>
      )}

      <Modal
        isOpen={showMakePaymentModal}
        toggle={togglePayment}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={togglePayment}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={togglePayment}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("OrderDetailsPage.payment")}
        </ModalHeader>
        <ModalBody>
          <div className="embed-responsive embed-responsive-full-height">
            <iframe
              className="embed-responsive-item"
              src={orderDetail.PaymentUrl}
            ></iframe>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalConfirm}
        toggle={toggleConfirm}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={toggleConfirm}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleConfirm}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("OrderDetailsPage.confirm")}
        </ModalHeader>
        <ModalBody>
          <p>{t("OrderDetailsPage.warning")}</p>
          <Button
            color="light"
            onClick={toggleConfirm}
            className={`px-5 mt-2 ml-md-1${btnConfirm && " wait"}`}
            disabled={btnConfirm}
          >
            {t("OrderDetailsPage.cancel")}
          </Button>
          <Button
            color="primary"
            onClick={confirmPrepaidOrderSubmit}
            className={`px-5 mt-2 ml-md-1${btnConfirm && " wait"}`}
            disabled={btnConfirm}
          >
            {btnConfirm
              ? t("OrderDetailsPage.submitting")
              : t("OrderDetailsPage.yesConfirm")}
            {btnConfirm && <Spinner color="#fff" size="sm" />}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default inject(
  "authStore",
  "ecomStore",
  "sessionStore",
  "navigationStore",
  "createOrderStore",
  "compositeStore"
)(observer(OrderDetailsPage));
