import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";
import axios from "axios";

class WordPressClass {
  @persist("list") allBlogs: Array<any> = [];
  @persist("object") @observable activeBlog: any = {};
  @persist("object") activeBlogData: any = {};
  @persist("list") activeBlogComments: Array<any> = [];

  @observable hydrated: boolean = false;

  @action async getAllBlogs() {
    return axios({
      method: "GET",
      url: "/IBYBlogPosts",
    }).then((res) => {
      // debugger;
      this.allBlogs = res.data;
      this.allBlogs = res.data?.sort(function (a: any, b: any) {
        return b.sticky - a.sticky;
      });
      console.log("BLog response => ", this.allBlogs);
    });
  }

  @action async getBlogPost(postId: any, forceAjax: false) {
    postId = parseInt(postId);
    if (
      this.allBlogs.map((blog: any) => blog.id).includes(postId) &&
      !forceAjax
    ) {
      this.activeBlog = this.allBlogs.find((blog) => blog.id === postId);
      console.log("activeBlog", this.activeBlog);
      this.activeBlogData = Object.assign<{}, any>({}, this.activeBlog);
      return this.activeBlogData;
    } else {
      // return axios({
      //   method: "GET",
      //   url: "/IBYBlogPosts",
      // }).then((res) => {
      //   console.lg("BLog response => ", res)
      //   this.allBlogs = res.data;
      // });
    }
  }

  @action async getBlogComments(postId: string) {
    return axios({
      method: "GET",
      url: "/IBYBlogComments/",
      params: {
        postId: postId,
      },
    }).then((res) => {
      // console.log("getBlogComments", res.data);
      this.activeBlogComments = res.data;
    });
  }

  @action async putBlogComment(post: any) {
    // console.log("Post", post);
    return axios({
      method: "PUT",
      url: "/IBYBlogComment/",
      data: { ...post },
    }).then((res) => {
      console.log("updateComment", res);
    });
  }

  @action onLogout() {
    this.allBlogs = [];
    this.activeBlog = {};
    this.activeBlogComments = [];
  }
}

const hydrate = create({});
const WPStore = new WordPressClass();
hydrate("wordPress", WPStore).then((WPStore) => {
  WPStore.hydrated = true;
});
export default WPStore;
