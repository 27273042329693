import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Label,
  Button,
  Row,
  FormFeedback,
  Alert,
  CustomInput,
  Col,
  Spinner,
} from "reactstrap";
import { observer, inject } from "mobx-react";
import { AxiosError } from "axios";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  allCountriesFormattedForDropdown,
  AllStateAbbreviations,
} from "../../util";
import InputFloat from "../inputFloat";

const EditAddressForm: React.FC<any> = ({
  ecomStore,
  clientStore,
  onSuccess,
  index,
  authStore,
}) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");

  async function updateClient(obj: any) {
    await ecomStore
      .setShippingAddress({
        ...obj,
        Key: ecomStore.allShippingAddresses?.[index].Key,
      })
      .catch((err: AxiosError) => setAsyncErr(err.message));
    onSuccess({});
  }

  const validationSchema = Yup.object().shape({
    Name: Yup.string(),
    Country: Yup.string(),
    Line1: Yup.string(),
    Line2: Yup.string(),
    Postcode: Yup.string(),
    State: Yup.string(),
    City: Yup.string(),
    PhoneNumber: Yup.string(),
    DeliveryInstructions: Yup.string(),
    AccessCode: Yup.string(),
    Default: Yup.boolean(),
    SaturdayOK: Yup.boolean(),
    SundayOK: Yup.boolean(),
  });

  const initialValues = {
    Name: ecomStore.allShippingAddresses?.[index].Name,
    Country: ecomStore.allShippingAddresses?.[index].Country,
    Line1: ecomStore.allShippingAddresses?.[index].Line1,
    Line2: ecomStore.allShippingAddresses?.[index].Line2,
    Postcode: ecomStore.allShippingAddresses?.[index].Postcode,
    State: ecomStore.allShippingAddresses?.[index].State,
    City: ecomStore.allShippingAddresses?.[index].City,
    PhoneNumber: ecomStore.allShippingAddresses?.[index].PhoneNumber,
    DeliveryInstructions:
      ecomStore.allShippingAddresses?.[index].DeliveryInstructions,
    AccessCode: ecomStore.allShippingAddresses?.[index].AccessCode,
    Default: ecomStore.allShippingAddresses?.[index].Default,
    SaturdayOK: ecomStore.allShippingAddresses?.[index].SaturdayOK,
    SundayOK: ecomStore.allShippingAddresses?.[index].SundayOK,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.name")}
                name="Name"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Name">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.phoneNumber")}
                name="PhoneNumber"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="PhoneNumber">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>

            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.addressLine1")}
                name="Line1"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Line1">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Label className="sr-only">Line2</Label>
              <Field
                label={t("editAddressFormComponent.addressLine2")}
                name="Line2"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Line2">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.city")}
                name="City"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="City">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            {values.Country === "USA" && (
              <FormGroup className="col-sm-6">
                <div className="form-field-float active">
                  <Label>{t("editAddressFormComponent.region")}</Label>
                  <Select
                    className="react-select-group-container"
                    classNamePrefix="react-select-group"
                    placeholder={t("editAddressFormComponent.region")}
                    defaultValue={{ label: values.State, value: values.State }}
                    onChange={(e: any) => setFieldValue("State", e.value)}
                    options={AllStateAbbreviations.map((state: string) => ({
                      label: state,
                      value: state,
                    }))}
                    isDisabled={isSubmitting}
                  />
                </div>
              </FormGroup>
            )}
            {values.Country !== "USA" && (
              <FormGroup className="col-sm-6">
                <Field
                  label={t("editAddressFormComponent.region")}
                  name="State"
                  className="form-control"
                  component={InputFloat}
                  disabled={isSubmitting}
                />
                <ErrorMessage name="State">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            )}
            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.postalCode")}
                name="Postcode"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Postcode">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <Col sm="6">
              <FormGroup className="form-field-float active">
                <Label htmlFor="country">
                  {t("editAddressFormComponent.country")}
                </Label>
                {authStore.accountName.toLowerCase() === "annegeddes" ? (
                  <>
                    <Select
                      id="country"
                      classNamePrefix="react-select-group"
                      className={`react-select-group-container ${
                        errors.Country && touched.Country ? " is-invalid" : ""
                      }`}
                      name="country"
                      defaultValue={allCountriesFormattedForDropdown.find(
                        (opt: any) => opt.value === values.Country
                      )}
                      options={allCountriesFormattedForDropdown}
                      placeholder={t("personalInfoComponent.pleaseSelect")}
                      onChange={(val: any) => {
                        setFieldValue("Country", val.value);
                      }}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      isDisabled={true}
                    />
                  </>
                ) : (
                  <>
                    <Select
                      id="country"
                      classNamePrefix="react-select-group"
                      className={`react-select-group-container ${
                        errors.Country && touched.Country ? " is-invalid" : ""
                      }`}
                      name="country"
                      defaultValue={allCountriesFormattedForDropdown.find(
                        (opt: any) => opt.value === values.Country
                      )}
                      options={allCountriesFormattedForDropdown}
                      placeholder={t("personalInfoComponent.pleaseSelect")}
                      onChange={(val: any) => {
                        setFieldValue("Country", val.value);
                      }}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </>
                )}
                <ErrorMessage name="Country">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            </Col>

            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.deliveryInstructions")}
                name="DeliveryInstructions"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="DeliveryInstructions">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Field
                label={t("editAddressFormComponent.accessCode")}
                name="AccessCode"
                className="form-control"
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="AccessCode">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
          </Row>
          <FormGroup>
            <ul className="list-unstyled list-expand mt-3" role="presentation">
              <li>
                <CustomInput
                  type="checkbox"
                  id="Default"
                  label={t("editAddressFormComponent.defaultAddress")}
                  checked={values.Default}
                  onChange={(e: any) =>
                    setFieldValue("Default", e.target.checked)
                  }
                  disabled={isSubmitting}
                />
              </li>
              {/* <li>
                                    <CustomInput 
                                    type="checkbox" 
                                    id="SaturdayOK" 
                                    label="It is ok to deliver on Saturdays" 
                                    checked = {values.SaturdayOK}
                                    onChange = {(e:any)=>setFieldValue('SaturdayOK', e.target.checked)}
                                    disabled={isSubmitting}
                                    />
                                </li>
                                <li>
                                    <CustomInput 
                                    type="checkbox" 
                                    id="com3" 
                                    label="It is ok to deliver on Sundays" 
                                    checked = {values.SundayOK}
                                    onChange = {(e:any)=>setFieldValue('SundayOK', e.target.checked)}
                                    disabled={isSubmitting}
                                    />
                                </li> */}
            </ul>
          </FormGroup>
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {t("editAddressFormComponent.applyChange")}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject("ecomStore", "authStore")(observer(EditAddressForm));
