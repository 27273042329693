import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Label,
  Button,
  Row,
  // FormFeedback,
  Alert,
  // CustomInput,
  Spinner,
} from "reactstrap";

import { observer, inject } from "mobx-react";
import Select from "react-select";
import { FamilyMemberRoles, Gender } from "../../util";
import { AxiosError } from "axios";
import InputFloat from "../inputFloat";
import { DatePickerField } from "../../../components/util/DatePicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const FamilyMemberEditForm: React.FC<any> = ({
  authStore,
  clientStore,
  onSuccess,
  editAddressIndex,
}) => {
  const [asyncErr, setAsyncErr] = useState("");
  const { t } = useTranslation();
  const [otherFamilyMemberRole, setOtherFamilyMemberRole] = useState(false);

  async function putFamilyMember(obj: any) {
    if (obj.Relationship === "Other") {
      obj.Relationship = obj.CustomTextField02;
    }
    await clientStore
      .putFamilyMember({
        ...obj,
        Key: clientStore.allFamilyMembers?.[editAddressIndex].Key,
      })
      .catch((err: AxiosError) => setAsyncErr(err.message));
    onSuccess();
  }

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "No special characters or numbers allowed.")
      .required("Please fill this field"),
    Relationship: Yup.string(),
    Gender: Yup.string(),
    BirthDate: Yup.string().nullable(),
    CustomTextField02: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "No special characters or numbers allowed.")
      .when("Relationship", {
        is: (value) => value === "Other",
        then: Yup.string().required("Please fill this field"),
      }),
  });

  const initialValues = {
    FirstName: clientStore.allFamilyMembers?.[editAddressIndex].FirstName || "",
    Relationship:
      clientStore.allFamilyMembers?.[editAddressIndex].Relationship || "",
    Gender: clientStore.allFamilyMembers?.[editAddressIndex].Gender || "",
    BirthDate: clientStore.allFamilyMembers?.[editAddressIndex].Birthdate
      ? moment(
          clientStore.allFamilyMembers?.[editAddressIndex].Birthdate
        ).format("MM/DD/YYYY")
      : null,

    CustomTextField02:
      clientStore.allFamilyMembers?.[editAddressIndex].Relationship || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={putFamilyMember}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-6">
              <Field
                label={t("familyMemberComponent.nickname")}
                name="FirstName"
                className="form-control"
                id="FirstName"
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.FirstName && touched.FirstName ? (
                <div className="invalid-feedback">{errors.FirstName}</div>
              ) : null}
            </FormGroup>
            <FormGroup className="col-sm-6">
              <div className="form-field-float active">
                <Label htmlFor="familyMemberRole">
                  {t("familyMemberComponent.familyMember")}
                </Label>
                <Select
                  id="familyMemberRole"
                  name="Relationship"
                  className="react-select-group-container"
                  classNamePrefix="react-select-group"
                  placeholder={t("familyMemberComponent.familyMember")}
                  defaultValue={{
                    label: values.Relationship,
                    value: values.Relationship,
                  }}
                  onChange={(e: any) => {
                    if (e.value) {
                      setFieldValue("Relationship", e.value);
                    }
                    console.log(e.value);
                    if (e.value === "Other") {
                      setOtherFamilyMemberRole(true);
                    } else {
                      setOtherFamilyMemberRole(false);
                    }
                  }}
                  options={FamilyMemberRoles.map((role: string) => ({
                    label: role,
                    value: role,
                  }))}
                  isDisabled={isSubmitting}
                />
                {errors.Relationship && touched.Relationship ? (
                  <div className="invalid-feedback">{errors.Relationship}</div>
                ) : null}
              </div>
            </FormGroup>
            {otherFamilyMemberRole && (
              <FormGroup className="col-sm-6">
                <Field
                  label="Other"
                  name="CustomTextField02"
                  className="form-control"
                  id="CustomTextField02"
                  component={InputFloat}
                  disabled={isSubmitting}
                />
                {errors.CustomTextField02 && touched.CustomTextField02 ? (
                  <div className="invalid-feedback">
                    {errors.CustomTextField02}
                  </div>
                ) : null}
              </FormGroup>
            )}

            <FormGroup className="col-sm-6">
              <div className="form-field-float active">
                <Label htmlFor="gender">
                  {t("familyMemberComponent.gender")}
                </Label>
                <Select
                  id="gender"
                  name="Gender"
                  className="react-select-group-container"
                  classNamePrefix="react-select-group"
                  placeholder={t("familyMemberComponent.gender")}
                  defaultValue={{
                    label: values.Gender,
                    value: values.Gender,
                  }}
                  onChange={(e: any) => setFieldValue("Gender", e.value)}
                  options={Gender.map((role: string) => ({
                    label: role,
                    value: role,
                  }))}
                  isDisabled={isSubmitting}
                />
                {errors.Gender && touched.Gender ? (
                  <div className="invalid-feedback">{errors.Gender}</div>
                ) : null}
              </div>
            </FormGroup>

            <FormGroup className="col-sm-6">
              <label className="mt-minus-2">
                {t("familyMemberComponent.BirthDate")}
              </label>

              <DatePickerField name="BirthDate" />

              {errors.BirthDate && touched.BirthDate ? (
                <div className="invalid-feedback">{errors.BirthDate}</div>
              ) : null}
            </FormGroup>
          </Row>
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {t("editAddressFormComponent.applyChange")}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject(
  "clientStore",
  "authStore"
)(observer(FamilyMemberEditForm));
