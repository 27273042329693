import React, { useState, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineShoppingCart,
  AiOutlineRead,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { useMediaQuery } from "react-responsive";

import useWindowDimensions from "../util/getDimensions";
import ImageTileButton from "./imgTIleButton/imgTileButton";
import ImageTileDropdown from "./imgTileDropdown/imgTileDropdown";

import brokenImg from "./broken-img.png";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ImageTile: React.FC<any> = ({
  images,
  lightboxStore,
  imageGroupStore,
  createOrderStore,
  sessionStore,
  authStore,
  ecomStore,
  navigationStore,
  compositeStore,
  img,
  titleData,
  single,
  noActions = false,
  index = 0,
  groupName,
  favorites = false,
}) => {
  const { t } = useTranslation();
  const [hideButtons, setHideButtons] = useState(true);
  const [modalCart, setModalCart] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { innerHeight } = useWindowDimensions();

  const location = useLocation();
  function showLightbox() {
    if (authStore.privateLabel === "picturepeople") {
      return;
    }
    if (imageGroupStore.hiddenImageList.includes(img.fileName)) {
      // Don't open lightbox if the img is in the hidden folder
      return;
    }
    if (favorites) {
      // If the image is in a favorites folder, need to find the index by fileName
      const index = sessionStore.activeSessionImages.findIndex(
        (sessionImg: any) => sessionImg.fileName.includes(img.fileName)
      );
      lightboxStore.openLightboxAtIndex(index);
    } else {
      lightboxStore.openLightboxAtIndex(index);
    }
  }

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  function handleImgErr(e: any) {
    if (e.target.src !== brokenImg) {
      e.target.onerror = null;
      e.target.src = brokenImg;
    }
  }

  function handleHeartClick() {
    if (imageGroupStore.favoriteImageList.includes(img.fileName)) {
      unfavoriteImage();
    } else {
      imageGroupStore.clientAddImageToGroup(img.fileName, "Favorites");
    }
  }

  function removeImageFromGroup() {
    imageGroupStore.statefulRemoveImageFromGroup(img.fileName, groupName);
  }

  function unfavoriteImage() {
    imageGroupStore.statefulRemoveImageFromGroup(img.fileName, "Favorites");
  }

  async function addToCartSelected(e: any) {
    if (ecomStore.cart.InvoiceDetails?.length) {
      for (let cartItem of ecomStore.cart.InvoiceDetails) {
        if (sessionStore.activeSession.Key !== cartItem.SessionKey) {
          toggleCartModal(e);
          return;
        }
      }
    }
    console.log(img);

    if (img.compositeKey) {
      // Runs when trying to add an previously created composite to the cart
      await compositeStore.getCompositeByKey(img.compositeKey);
      const templateKeyToFind = compositeStore.activeComposite.TemplateKey;
      // Find price list item
      for (let priceListGroup of ecomStore.priceList.Groups) {
        // if (sessionStore.activeSession.Key !== )

        for (let item of priceListGroup.Items) {
          if (!item.CompositeTemplateKey) {
            continue;
          }
          if (item.CompositeTemplateKey === templateKeyToFind) {
            ecomStore.selectProductGroup(priceListGroup.Key);
            ecomStore.selectProduct(item.Key);
            createOrderStore.setStepNumber(3);
          }
        }
      }
    } else {
      createOrderStore.setStepNumber(1);
    }

    await createOrderStore.toggleSlider();
    ecomStore.selectedImageFileName = img.fileName;
    ecomStore.selectedImageData = img;

    /*
    console.log('Pressed cart icon');
    console.log('Session: ' + img.sessionKey );
    console.log('Item Key: ' + img.key );
    console.log('Filname: ' + img.fileName );
    */
  }

  async function handleDeleteCartAndAddItem(e: any) {
    // Show spinner for delete button
    setSpinner(true);

    // Call DELETE API
    await ecomStore.deleteCart();

    // Toggle Modal
    toggleCartModal(e);

    // Set spinner to false
    setSpinner(false);

    // Return flow to addCartToSelected
    addToCartSelected(e);
  }

  const toggleCartModal = (e: any) => {
    setModalCart(!modalCart);
    e.preventDefault();
  };

  const imgElement = React.useRef(null);

  const imageLoaded = (img: any) => {
    setTimeout(function () {
      setHideButtons(false);
    }, 100);
  };

  const goToSession = () => {
    navigationStore.push("/sessions");
  };

  return (
    <Fragment>
      <figure
        className={`img-tile${single ? " is-single" : " is-multi"}${
          !hideButtons ? " loaded" : ""
        }`}
        style={{
          height:
            single && isBreakDownSm ? `calc(${innerHeight}px - 4rem)` : "",
          backgroundImage: single ? `url(${img.preview})` : "",
        }}
        onClick={() => (single ? goToSession() : "")}
        onContextMenu={(e) => e.preventDefault()}
      >
        {!single && (
          <div
            className="image-ratio"
            style={{
              width: `100%`,
              paddingTop: `${
                (img.calculatedHeight / img.calculatedWidth) * 100 || 120
              }%`,
            }}
          ></div>
        )}

        <div className={`img-wrapper ${single ? `d-none` : ``}`}>
          <img
            onClick={showLightbox}
            className="w-100"
            src={img.thumbnail}
            alt={`session ${index + 1}`}
            onError={handleImgErr}
            ref={imgElement}
            width={img.calculatedWidth}
            height={img.calculatedHeight}
            onLoad={(img) => imageLoaded(img)}
          />
        </div>
        <div className="caption-gradient" onClick={showLightbox}></div>
        <div className="caption">
          {!!titleData && (
            <figcaption className="session-title">
              <h3 className="title text-white">{titleData.title}</h3>
              <p className="meta">
                <time dateTime="2019-08-28">{titleData.subHeading}</time>
                {sessionStore?.activeSessionImages?.length && (
                  <Badge color="primary">{`${sessionStore.activeSessionImages.length} photos`}</Badge>
                )}
              </p>
            </figcaption>
          )}
          {!hideButtons && !noActions && (
            <>
              <div className="actions">
                {location.pathname !== "/folders" && (
                  <>
                    <ImageTileButton
                      onClick={addToCartSelected}
                      className="btn btn-add-to-cart"
                    >
                      <AiOutlineShoppingCart size="24" />
                    </ImageTileButton>
                    <ImageTileButton id="heart-icon" onClick={handleHeartClick}>
                      {imageGroupStore.favoriteImageList.includes(
                        img.fileName
                      ) ? (
                        <AiFillHeart size="24" />
                      ) : (
                        <AiOutlineHeart size="24" />
                      )}
                    </ImageTileButton>
                    <ImageTileDropdown img={img} />
                  </>
                )}
                {location.pathname === "/folders" && (
                  <>
                    <ImageTileButton
                      onClick={addToCartSelected}
                      className="btn btn-add-to-cart"
                    >
                      <AiOutlineShoppingCart size="24" />
                    </ImageTileButton>
                    <ImageTileButton onClick={handleHeartClick}>
                      {imageGroupStore.favoriteImageList.includes(
                        img.fileName
                      ) ? (
                        <AiFillHeart size="24" />
                      ) : (
                        <AiOutlineHeart size="24" />
                      )}
                    </ImageTileButton>
                    <ImageTileDropdown
                      img={img}
                      isGroup={true}
                      groupName={groupName}
                      removeImageFromGroup={removeImageFromGroup}
                    />
                  </>
                )}
              </div>
            </>
          )}

          {noActions && (
            <div className="actions">
              <Link to="/sessions" className="body-link-dark">
                {t("imageTileComponent.view")}{" "}
                {authStore.jobSingular.toLowerCase()}
              </Link>
            </div>
          )}
        </div>

        {sessionStore.activeSession?.Yearbook?.Image1FileName ===
          img.fileName && (
          <div className="yearbook-icon" onClick={showLightbox}>
            <span className="icon-container">
              <AiOutlineRead size="24" />
              <Badge>1</Badge>
            </span>
            <span className="sr-only">
              {t("imageTileComponent.setAsPrimary")}
            </span>
          </div>
        )}
        {sessionStore.activeSession?.Yearbook?.Image2FileName ===
          img.fileName && (
          <div className="yearbook-icon" onClick={showLightbox}>
            <span className="icon-container">
              <AiOutlineRead size="24" />
              <Badge>2</Badge>
            </span>
            <span className="sr-only">
              {t("imageTileComponent.setAsSecondary")}
            </span>
          </div>
        )}

        {!titleData && !hideButtons && (
          <figcaption className="session-filename" onClick={showLightbox}>
            {t("imageTileComponent.image")} {img.imageNumber}
          </figcaption>
        )}
      </figure>
      <Modal
        isOpen={modalCart}
        toggle={toggleCartModal}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="md"
      >
        <ModalHeader
          toggle={toggleCartModal}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleCartModal}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("imageTileComponent.caution")}
        </ModalHeader>
        <ModalBody>
          <p>
            {t("imageTileComponent.separateSessionsMessage1")}{" "}
            {t("imageTileComponent.sessions", {
              sessions: authStore.jobPlural.toLowerCase(),
            })}{" "}
            {t("imageTileComponent.separateSessionsMessage2")}
          </p>
          <Row>
            <Col>
              <Button onClick={toggleCartModal} block className="mb-3">
                {t("imageTileComponent.doNotAdd")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={handleDeleteCartAndAddItem} block color="danger">
                {spinner ? (
                  <Spinner size="lg" />
                ) : (
                  t("imageTileComponent.clearCart")
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default inject(
  "lightboxStore",
  "imageGroupStore",
  "createOrderStore",
  "sessionStore",
  "ecomStore",
  "authStore",
  "navigationStore",
  "compositeStore"
)(observer(ImageTile));
