import React from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";
import { observer,inject } from 'mobx-react';

import CreateOrderFlowComponent from '../create-order';
import NavBarPage from '../nav-bar-page/navBarPage';

const ProtectedRoute:React.FC<any> = ({ component: Component, navbar=true, render, authStore, ...rest })=>{
  const canView = !!authStore.token;
    return (
      <>
        {authStore.hydrated && 
          <Route
          {...rest}
          render={props =>{
            return(
              canView ? (
              render ?
              render(): (
                <>
                  { navbar ? <NavBarPage><Component {...props} /></NavBarPage> : <Component {...props} /> }
                  <CreateOrderFlowComponent />
                </>
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )
            )
          }
        }
        />
        }
    </>

      );
  }
  export default inject('authStore', 'navigationStore')(observer(ProtectedRoute))
