import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Label, FormFeedback, Button, Spinner } from "reactstrap";
import { MdMailOutline, MdLockOutline } from "react-icons/md";

interface ChildProps {
  onSubmit: (obj: LoginFormData, actions: any) => void;
  emailLabel: string;
  emailError: string;
  passwordLabel: string;
  passwordError: string;
  ajaxError: string;
  submitBtn: string;
}

export interface LoginFormData {
  email: string;
  password: string;
}

const LoginForm: React.FC<ChildProps> = ({
  onSubmit,
  emailLabel,
  emailError,
  passwordLabel,
  passwordError,
  ajaxError,
  submitBtn,
}) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      // TODO add regex for password
      // .email('Please enter a valid email')
      .required(emailError),
    password: Yup.string().required(passwordError),
  });

  const initialValues: LoginFormData = {
    email: "",
    password: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} target="_blank">
          {ajaxError && (
            <div className="alert alert-danger" role="alert">
              {ajaxError}
            </div>
          )}
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="email_input"
              className={`${
                errors.email && touched.email ? "error " : ""
              }sr-only`}
            >
              {emailLabel}
            </Label>
            <MdMailOutline size="20" />
            <Field
              className={`form-control ${
                errors.email && touched.email ? "is-invalid" : ""
              }`}
              type="text"
              name="email"
              id="email_input"
              placeholder={emailLabel}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
            />
            <ErrorMessage name="email">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="password_input"
              className={`${
                errors.password && touched.password ? "error " : ""
              }sr-only`}
            >
              {passwordLabel}
            </Label>
            <MdLockOutline size="20" />
            <Field
              id="password_input"
              className={`form-control ${
                errors.password && touched.password ? "is-invalid" : ""
              }`}
              type="password"
              name="password"
              placeholder={passwordLabel}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting}
            />
            <ErrorMessage name="password">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {submitBtn}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
