import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormGroup, Button, Row, Alert, Spinner, Col } from "reactstrap";
import { observer, inject } from "mobx-react";
// import { FamilyMemberRoles, Gender } from "../util";
import { AxiosError } from "axios";

import InputFloat from "../forms/inputFloat";
import moment from "moment";

import { DatePickerField } from "../../components/util/DatePicker";

import "react-datepicker/dist/react-datepicker.css";

const SessionsEditForm: React.FC<any> = ({
  authStore,
  clientStore,
  sessionStore,
  onSuccess,
  editSessionCurrentIndex,
}) => {
  const [asyncErr, setAsyncErr] = useState("");

  async function updateClient(obj: any) {
    await sessionStore
      .putSession({
        ...obj,
        Key: sessionStore.allSessionList?.[editSessionCurrentIndex].SessionKey,
      })
      .then(() => {})
      .catch((err: AxiosError) => setAsyncErr(err.message));
    await clientStore.getClientSessionInfo();
    await clientStore.getClientInfo();
    onSuccess();
  }

  const validationSchema = Yup.object().shape({
    Description: Yup.string().required("Please fill this field"),
    SessionDate: Yup.date().required("Please fill this field"),
  });

  const initialValues = {
    Description:
      sessionStore.allSessionList?.[editSessionCurrentIndex]
        .SessionDescription || "",
    SessionDate:
      moment(
        sessionStore.allSessionList?.[editSessionCurrentIndex].SessionDate
      ).format("MM/DD/YYYY") || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-12 col-12">
              <Field
                type="textarea"
                label="Description"
                name="Description"
                className="form-control"
                id="Description"
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.Description && touched.Description ? (
                <div className="invalid-feedback">{errors.Description}</div>
              ) : null}
            </FormGroup>

            <FormGroup className="col-sm-12 col-12">
              <DatePickerField name="SessionDate" />

              {errors.SessionDate && touched.SessionDate ? (
                <div className="invalid-feedback">{errors.SessionDate}</div>
              ) : null}
            </FormGroup>
          </Row>
          <Row className="row-sm">
            <Col className="col-12">
              {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
              <Button
                type="submit"
                color="primary"
                block
                className={isSubmitting ? "wait" : ""}
                disabled={isSubmitting}
              >
                Update
                {isSubmitting && <Spinner color="#fff" size="sm" />}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default inject(
  "clientStore",
  "authStore",
  "sessionStore"
)(observer(SessionsEditForm));
