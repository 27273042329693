import { observable, action, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios from "axios";

import ClientStore from "./clientStore";
import AuthStore from "./authStore";
import LoginPromptStore from "./loginPromptStore";
import SessionStore from "./sessionStore";

class ImageGroupClass {
  @observable hydrated: boolean = false;
  @persist("object") @observable activeSessionImageGroups: any = {};

  @observable addToGroup: any = {
    showModal: false,
    fileName: "",
  };

  @computed get groupsForAddToGroupImage() {
    return Object.keys(this.activeSessionImageGroups)
      .filter(
        (key: string) =>
          key.toUpperCase() !== "FAVORITES" &&
          this.activeSessionImageGroups[key].includes(this.addToGroup.fileName)
      )
      .map((key: string) => ({ label: key, value: key }));
  }

  @action imageSelectedForGroup(fileName: string) {
    this.addToGroup.fileName = fileName;
    this.toggleModal();
  }

  @action toggleModal() {
    this.addToGroup.showModal = !this.addToGroup.showModal;
  }

  @computed get imageGroupsFormattedForDropdown() {
    return Object.keys(this.activeSessionImageGroups)
      .filter(
        (key: string) =>
          key.toUpperCase() !== "FAVORITES" && key.toUpperCase() !== "HIDDEN"
      )
      .map((key: string) => ({ label: key, value: key }));
  }
  // list for /folders
  @computed get imageGroupList() {
    return Object.keys(this.activeSessionImageGroups)
      .filter((key: string) => key.toUpperCase() !== "FAVORITES")
      .map((key: string) => ({
        GroupName: key,
        FileNames: this.activeSessionImageGroups[key],
      }));
  }

  @computed get hiddenImageList() {
    if (this.activeSessionImageGroups.Hidden) {
      return this.activeSessionImageGroups.Hidden.map((f: string) => f);
    } else {
      return [];
    }
  }

  @computed get favoriteImageList() {
    if (this.activeSessionImageGroups.Favorites) {
      return this.activeSessionImageGroups.Favorites.map((f: string) => f);
    } else {
      return [];
    }
  }

  @action clientAddImageToGroup(fileName: string, groupName: string) {
    if (!!AuthStore.token && !AuthStore.isGuestSession) {
      if (!this.activeSessionImageGroups?.[groupName]) {
        this.activeSessionImageGroups[groupName] = [];
      }
      this.activeSessionImageGroups[groupName].push(fileName);
      return this.addImageToGroup(groupName, fileName).catch((err: any) => {
        if (!!this.activeSessionImageGroups[groupName]) {
          this.activeSessionImageGroups[
            groupName
          ] = this.activeSessionImageGroups[groupName].filter(
            (file: string) => file !== fileName
          );
        }
      });
    } else {
      LoginPromptStore.setShowLoginPrompt(true);
    }
  }

  @action statefulRemoveImageFromGroup(fileName: string, group: string) {
    this.activeSessionImageGroups[group] = this.activeSessionImageGroups[
      group
    ].filter((file: string) => file !== fileName);
    this.deleteImageFromGroup(group, fileName).catch(() =>
      this.activeSessionImageGroups[group].push(fileName)
    );
  }

  @action deleteImageFromGroup(groupName: string, fileName: string) {
    return axios({
      method: "delete",
      url: `/ibyImageGroupMember`,
      params: {
        FileName: fileName,
        GroupName: groupName,
        SessionKey: SessionStore.activeSession.Key,
        ClientKey: ClientStore.clientInfo.Key,
      },
    }).then((res: any) => {
      return res;
    });
  }

  @action deleteSingleImage(key: string) {
    return axios({
      method: "delete",
      url: `/ibySessionImage/${key}`,
      params: {
        SessionKey: SessionStore.activeSession.Key,
      },
    }).then((res: any) => {
      console.log(res);
      return res;
    });
  }

  @action addImageToGroup(groupName: string, fileName: string) {
    return axios({
      method: "PUT",
      url: `/ibyImageGroupMember`,
      data: {
        FileName: fileName,
        GroupName: groupName,
        SessionKey: SessionStore.activeSession.Key,
        ClientKey: ClientStore.clientInfo.Key,
      },
    }).then((res: any) => {
      return res;
    });
  }

  @action setImageGroups(groups: Array<any>) {
    this.activeSessionImageGroups = {};
    groups.forEach((group: any) => {
      this.activeSessionImageGroups[group.GroupName] = group.FileNames;
    });
  }

  @action onLogout() {
    this.activeSessionImageGroups = {};
  }
}

const hydrate = create({});
const ImageGroupStore = new ImageGroupClass();
hydrate("imgGroup", ImageGroupStore).then((store) => {
  store.hydrated = true;
});
export default ImageGroupStore;
