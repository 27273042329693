import React, { useState, useEffect, SyntheticEvent } from "react";
import { observer, inject } from "mobx-react";
import {
  AiOutlineCopy,
  AiOutlineMail,
  AiOutlineForm,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
//import { FaRegCheckCircle } from 'react-icons/fa'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import EmailGuestForm from "../../components/forms/guests/emailGuestForm";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { GuestInvitation } from "../../interfaces/guest.int";
import LinkGuestForm from "../../components/forms/guests/linkGuestForm";
import ProgessSpinner from "../../components/spinner/spinner";
import { AxiosError } from "axios";

const GuestsPage: React.FC<any> = ({
  guestStore,
  sessionStore,
  clientStore,
  authStore,
  navigationStore,
}) => {
  const { t } = useTranslation();
  const [guestEditIndex, setGuestEditIndex] = useState<number>(0);
  const [modalEdit, setModalEdit] = useState(false);
  // const [modalLink, setModalLink] = useState(false);
  const [modalManage, setModalManage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  const alertFixedToggle = () => setAlertMessage("");

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    if (!!alertMessage) {
      setTimeout(() => {
        setAlertMessage("");
      }, 3000);
      guestStore.getAllGuests();
    }
  }, [alertMessage]);

  useEffect(() => {
    if (clientStore.hydrated && clientStore.clientKey) {
      guestStore.getAllGuests();
    }
  }, [clientStore.hydrated, clientStore.clientKey]);

  useEffect(() => {
    if (
      !modalEdit ||
      !modalManage
      // || !modalLink
    ) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [
    modalEdit,
    modalManage,
    // modalLink
  ]);

  const toggleEdit = (e: any) => {
    setModalEdit(!modalEdit);
    e.preventDefault();
  };

  const toggleManage = (e: any) => {
    setModalManage(!modalManage);

    e.preventDefault();
  };

  const toggleManageSuccess = (e: any) => {
    setModalManage(false);
    setTimeout(function () {
      setModalManage(true);
    }, 1000);

    e.preventDefault();
  };

  const closeGuestModalFirst = (e: any) => {
    authStore.userflowCreateAccount = false;
    setModalManage(false);
    navigationStore.push("/blog");

    console.log("End Create Account flow", authStore.userflowCreateAccount);

    e.preventDefault();
  };

  const closeGuestModal = (e: any) => {
    setModalManage(false);
    if (authStore.userflowCreateAccount) {
      navigationStore.push("/sessions-list");
      authStore.userflowCreateAccount = false;
      return;
    } else {
      navigationStore.push("/guests");
    }
    e.preventDefault();
  };

  async function resendLink(key: string) {
    guestStore.guestUpdating = key;

    if (!!key) {
      var errMsg = "";
      await guestStore
        .setGuest({ Key: key }, { resendEmail: true })
        .catch((err: AxiosError) => {
          if (err.message.includes("400")) {
            errMsg = t("GuestsPage.error");
          } else {
            errMsg = err.message;
          }
        });
      if (errMsg) {
        setAlertMessage(errMsg);
      } else {
        setAlertMessage(t("GuestsPage.success"));
      }
      guestStore.guestUpdating = false;
    }
  }

  function copyLink(linkObj: GuestInvitation) {
    var copyText;
    if (linkObj.LoginUrl != null) {
      copyText = linkObj.LoginUrl!;
    } else {
      copyText = `${window.location.origin}?shareToken=${linkObj.InvitationGuid}`;
    }
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = copyText;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setAlertMessage(t("GuestsPage.copy"));
  }

  useEffect(() => {
    if (
      guestStore.inviteLinks?.length === 0 &&
      guestStore.allGuestInvitations?.length === 0
    ) {
      setModalManage(true);
    } else {
      setModalManage(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        {/* <title>
          {t("GuestsPage.urlTitle")}{" "}
          {sessionStore.activeShareSession && (
            <>
              {sessionStore.activeShareSession.SessionDescription}{" "}
              {authStore.companyName || authStore.companyName !== ""
                ? "| " + authStore.companyName
                : " "}
            </>
          )}
        </title> */}
      </Helmet>
      <Alert
        color="secondary"
        isOpen={!!alertMessage}
        toggle={alertFixedToggle}
        fade={true}
        className="alert-fixed alert-fixed-bottom-right"
      >
        {/* <FaRegCheckCircle size="18" className="mr-2" /> */}
        {alertMessage}
      </Alert>

      <section className="page-section">
        <div className="row align-items-center mb-5">
          <h1 className="col-md-3 mb-md-0 text-truncate">
            {t("GuestsPage.title")}
          </h1>
        </div>
        <ProgessSpinner showSpinner={guestStore.showGuestsSpinner} />

        {!!clientStore.clientInfo.SessionCount && (
          <>
            <Row className="mb-2 mt-5 align-items-center">
              <Col className="text-sm-right mb-3 mb-sm-0 order-sm-2">
                <Button color="primary text-truncate" onClick={toggleManage}>
                  {t("GuestsPage.createNew")}
                </Button>
              </Col>
              <h3 className="subtitle mb-1 mb-sm-0 col-sm order-sm-1">
                {t("GuestsPage.invitationLinks")}
              </h3>
            </Row>

            {guestStore.inviteLinks.length === 0 && (
              <p>
                {t("GuestsPage.noLinksFound")}{" "}
                {authStore.jobSingular.toLowerCase()}.
              </p>
            )}

            <Table className="table table-cart-sm table-hover">
              <Thead>
                <Tr>
                  <Th scope="col">{t("GuestsPage.tableDescription")}</Th>
                  <Th scope="col">{t("GuestsPage.tableDateCreated")}</Th>
                  <Th scope="col">{t("GuestsPage.tableDateFirstUsed")}</Th>
                  <Th scope="col">{authStore.jobSingular}</Th>
                  <Th scope="col">
                    <span className="sr-only">
                      {t("GuestsPage.tableAction")}
                    </span>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {guestStore.inviteLinks.map(
                  (guest: GuestInvitation, i: number) => (
                    <Tr key={i}>
                      <Td scope="row">{guest.Description}</Td>
                      <Td>{guest?.CreateDate}</Td>
                      <Td>{guest?.FirstUsageDate}</Td>
                      <Td>
                        {guest?.ShareAllSessions
                          ? "All Sessions"
                          : guest.SessionDescription || guest.SessionNumber}
                      </Td>
                      <Td className="text-right">
                        {guestStore.guestUpdating === guest.Key ? (
                          <Spinner color="primary" size="sm" />
                        ) : (
                          <ul
                            className="list-inline list-table-actions"
                            role="presentation"
                          >
                            <li className="list-inline-item">
                              <a role="button" onClick={() => copyLink(guest)}>
                                <AiOutlineCopy size="18" />
                                <span>{t("GuestsPage.copyLink")}</span>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                role="button"
                                className="text-danger"
                                onClick={() =>
                                  guestStore.deleteGuest(guest.Key)
                                }
                              >
                                <AiOutlineDelete size="18" />
                                <span>{t("GuestsPage.remove")}</span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>

            <Row className="mb-2 mt-5 align-items-center">
              <h3 className="subtitle mb-1 mb-sm-0 col-sm">
                {t("GuestsPage.invitedTitle")}
              </h3>
            </Row>

            {guestStore.allGuestInvitations.length === 0 && (
              <p>
                {t("GuestsPage.noGuestsFound")}{" "}
                {authStore.jobSingular.toLowerCase()}.
              </p>
            )}

            <Table className="table table-cart-sm table-hover">
              <Thead>
                <Tr>
                  <Th scope="col">{t("GuestsPage.tableName")}</Th>
                  <Th scope="col">{t("GuestsPage.tableEmail")}</Th>
                  <Th scope="col">{t("GuestsPage.tableDateInvited")}</Th>
                  <Th scope="col">{t("GuestsPage.tableDateFirstUsed")}</Th>
                  <Th scope="col">{authStore.jobSingular}</Th>
                  <Th scope="col">
                    <span className="sr-only">
                      {t("GuestsPage.tableAction")}
                    </span>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {guestStore.allGuestInvitations?.map(
                  (guest: GuestInvitation, i: number) => (
                    <Tr key={guest.Key}>
                      <Td scope="row">{`${guest.FirstName} ${guest.LastName}`}</Td>
                      <Td>
                        <a role="button" className="font-weight-bold">
                          {guest.Email}
                        </a>
                      </Td>
                      <Td>{guest?.CreateDate}</Td>
                      <Td>{guest?.FirstUsageDate}</Td>
                      <Td>
                        {guest?.ShareAllSessions
                          ? "All Sessions"
                          : guest.SessionDescription || guest.SessionNumber}
                      </Td>
                      <Td className="text-right">
                        {guestStore.guestUpdating === guest.Key ? (
                          <Spinner color="primary" size="sm" />
                        ) : (
                          <ul
                            className="list-inline list-table-actions"
                            role="presentation"
                          >
                            <li className="list-inline-item">
                              <a
                                role="button"
                                onClick={(e: SyntheticEvent) => {
                                  setGuestEditIndex(i);
                                  toggleEdit(e);
                                }}
                              >
                                <AiOutlineForm size="18" />
                                <span>{t("GuestsPage.edit")}</span>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                role="button"
                                onClick={() => {
                                  resendLink(guest?.Key || "");
                                }}
                              >
                                <AiOutlineMail size="18" />
                                <span>{t("GuestsPage.resend")}</span>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                role="button"
                                className="text-danger"
                                onClick={() =>
                                  guestStore.deleteGuest(guest.Key)
                                }
                              >
                                <AiOutlineDelete size="18" />
                                <span>{t("GuestsPage.remove")}</span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </>
        )}
      </section>

      <Modal
        isOpen={modalEdit}
        toggle={toggleEdit}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={toggleEdit}
          close={
            <Button color="icon-action" className="close" onClick={toggleEdit}>
              <MdClose size="18" />
            </Button>
          }
        >
          {t("GuestsPage.updateGuest")}
        </ModalHeader>
        <ModalBody>
          {!!guestStore.allGuestInvitations?.[guestEditIndex] && (
            <EmailGuestForm
              initialData={{
                Email: guestStore.allGuestInvitations?.[guestEditIndex]?.Email,
                FirstName:
                  guestStore.allGuestInvitations?.[guestEditIndex]?.FirstName,
                LastName:
                  guestStore.allGuestInvitations?.[guestEditIndex]?.LastName,
                key: guestStore.allGuestInvitations?.[guestEditIndex]?.Key,
                ImageGroup:
                  guestStore.allGuestInvitations?.[guestEditIndex]?.ImageGroup,
              }}
              onSuccess={toggleEdit}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalManage}
        toggle={toggleManage}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader>{t("GuestsPage.createNewGuestInvitation")}</ModalHeader>

        <ModalBody>
          <p>{t("GuestsPage.generateText")}</p>

          <Nav role="presentation" className="nav-tabs">
            <NavItem>
              <NavLink
                className={`${activeTab === "1" ? "active" : ""}`}
                onClick={() => {
                  toggle("1");
                }}
              >
                {t("GuestsPage.emailInvite")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "2" ? "active" : ""}`}
                onClick={() => {
                  toggle("2");
                }}
              >
                {t("GuestsPage.sharableLink")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <EmailGuestForm onSuccess={toggleManageSuccess} />
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <LinkGuestForm onSuccess={toggleManageSuccess} />
            </TabPane>
          </TabContent>

          {authStore.userflowCreateAccount &&
            guestStore.allGuests?.length === 0 && (
              <Row className="mb-2 mt-4 align-items-center">
                <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                  <Button
                    color="secondary text-truncate"
                    className="w-100"
                    onClick={closeGuestModalFirst}
                  >
                    Skip for Now
                  </Button>
                </Col>
              </Row>
            )}

          {guestStore.allGuests?.length === 0 &&
            !authStore.userflowCreateAccount && (
              <Row className="mb-2 mt-4 align-items-center">
                <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                  <Button
                    color="secondary text-truncate"
                    className="w-100"
                    onClick={closeGuestModal}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            )}

          {guestStore.allGuests?.length > 0 && (
            <Row className="mb-2 mt-4 align-items-center">
              <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                <Button
                  color="secondary text-truncate"
                  className="w-100"
                  onClick={closeGuestModal}
                >
                  No More Guests
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default inject(
  "guestStore",
  "sessionStore",
  "clientStore",
  "authStore",
  "navigationStore"
)(observer(GuestsPage));
