import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  //FacebookShareCount,
  FacebookShareButton,
  TwitterShareButton,
  //FacebookMessengerShareButton,
  PinterestShareCount,
  PinterestShareButton
} from "react-share";
import {
  FaTwitter,
  FaFacebookF,
  //FaFacebookMessenger,
  FaPinterest,
} from "react-icons/fa";

const SocialShare: React.FC<any> = ({authStore, size = 24, url='', title='View my images', imgUrl, fbId='669251793174452', className = ''}) => {
  const newTitle = title + ' - ' + authStore.companyName;

  return (
    <div className={`social-icons ${className}`}>
      <TwitterShareButton url={url} title={newTitle}>
        <span className="icon">
          <FaTwitter size={size} color="#1DA1F2" />
        </span>
        <span className="label">Twitter</span>
        <span className="sr-only">(opens new window)</span>
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={newTitle}>
        <span className="icon">
          <FaFacebookF size={size} color="#4267B2" />
        </span>
        <span className="label">
          Facebook
          {/* <FacebookShareCount url={url}>
            {count => (' (' + count + ')')}
          </FacebookShareCount> */}
        </span>
        <span className="sr-only">(opens new window)</span>
      </FacebookShareButton>
      {/* <FacebookMessengerShareButton url={url} appId={fbId}>
        <span className="icon">        
          <FaFacebookMessenger size={size} color="#00B2FF" />
        </span>
        <span className="label">Messenger</span>
        <span className="sr-only">(opens new window)</span>
      </FacebookMessengerShareButton> */}
      <PinterestShareButton url={url} media={imgUrl} description={newTitle}>
        <span className="icon">
          <FaPinterest size={size} color="#E60023" />
        </span>
        <span className="label">
          Pinterest
          <PinterestShareCount url={url}>
            {count => count !== 0 && (' ' + count)}
          </PinterestShareCount>
        </span>
        <span className="sr-only">(opens new window)</span>
      </PinterestShareButton>
    </div>
  );
}
export default inject('authStore')(observer(SocialShare));