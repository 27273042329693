import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Provider } from "mobx-react";
import AuthStore from "./stores/authStore";
import ClientStore from "./stores/clientStore";
import navigationStore from "./stores/navigationStore";
import CreateOrderStore from "./stores/createOrderStore";
import LightboxStore from "./stores/lightboxStore";
import EventStore from "./stores/eventStore";
import SessionStore from "./stores/sessionStore";
import LoginPromptStore from "./stores/loginPromptStore";
import ImageGroupStore from "./stores/imageGroupStore";
import ImageShareStore from "./stores/imageShareStore";
import EcomStore from "./stores/ecomStore";
import GlobalErrorStore from "./stores/globalErrorStore";
import GuestStore from "./stores/guestStore";
import CompositeStore from "./stores/compositeStore";
import WPStore from "./stores/WordPressStore";
// import {enableLogging} from 'mobx-logger';

// enableLogging();

ReactDOM.render(
  <Provider
    authStore={AuthStore}
    clientStore={ClientStore}
    navigationStore={navigationStore}
    createOrderStore={CreateOrderStore}
    lightboxStore={LightboxStore}
    eventStore={EventStore}
    sessionStore={SessionStore}
    loginPromptStore={LoginPromptStore}
    imageGroupStore={ImageGroupStore}
    imageShareStore={ImageShareStore}
    ecomStore={EcomStore}
    globalErrorStore={GlobalErrorStore}
    guestStore={GuestStore}
    compositeStore={CompositeStore}
    wpStore={WPStore}
  >
    <App />
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
