//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import Cropper from "react-easy-crop";
import { AiOutlinePlusCircle, AiOutlineInteraction } from "react-icons/ai";
import { Spinner, Button } from "reactstrap";

import DropzoneImage from "./DropZone.png";

import {
  CompositeTemplateZoneLayer,
  CompositeTemplateColor,
  CompositeTemplateZoneLayerImage,
} from "../../../interfaces/composite.int";

const CompositeLayerZone: React.FC<any> = ({
  compositeStore,
  photo = {},
  index,
  handleClicked,
  sessionStore,
  key,
  activePhoto,
  setActivePhoto,
  ...props
}) => {
  let zone: CompositeTemplateZoneLayer =
    compositeStore.activeRecipeNewLayers[index];
  const ref = useRef(null);
  const [mainPhoto, setMainPhoto] = useState({} as any);
  const [crop, onCropChange] = useState({ x: 0, y: 0 } as any);
  const [zoom, onZoomChange] = useState(1);
  const [initialized, setInitialized] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [firstImageLoad, setFirstImageLoad] = useState(false);
  const [initialCropped, setInitialCropped] = useState({} as any);
  const [fitZoom, setFitZoom] = useState(1);
  const [objectFit, setObjectFit] = useState(undefined);

  // console.log(compositeStore.activeRecipeNewLayers);
  let width: number = zone?.Width * compositeStore.canvasRatio;
  let height: number = zone?.Height * compositeStore.canvasRatio;
  let x: number = Number(zone?.X * compositeStore.canvasRatio);
  let y: number = Number(zone?.Y * compositeStore.canvasRatio);
  let fillColor: CompositeTemplateColor = zone?.FillColor;
  let imageLayer: CompositeTemplateZoneLayerImage = zone?.ZoneImage;
  const [scale, setScale] = useState(1);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  // let imageZoom: number = compositeStore.activeImageCropper[index]?.zoom;
  let orgImage: CompositeTemplateZoneLayerImage =
    compositeStore?.activeRecipe?.Layers[index]?.ZoneImage;

  useEffect(() => {
    if (photo?.fileName) {
      setMainPhoto(photo);
    } else {
      setImageLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      photo?.fileName !== mainPhoto?.fileName &&
      compositeStore.activeRecipeLayerIndex === index
    ) {
      setMainPhoto(photo);
      setImageLoading(true);
      setFirstImageLoad(false);
    }
  }, [photo, compositeStore.activeRecipeLayerIndex]);

  async function handleFirstImage(mediaSize: any) {
    if (imageLayer.Width !== 0 && !initialized) {
      let initWidth, initHeight, initX, initY, initZoom;
      if (mediaSize.naturalWidth < mediaSize.naturalHeight) {
        initWidth = mediaSize.naturalWidth;
        initHeight = Math.ceil(initWidth / (width / height));
        initX = imageLayer.X * -1;
        initY = imageLayer.Y * -1;
      } else {
        initHeight = mediaSize.naturalHeight;
        initWidth = Math.ceil(initHeight * (width / height));
        initX = imageLayer.X * -1;
        initY = imageLayer.Y * -1;
      }

      // if (initHeight < mediaSize.naturalHeight) {
      //   alert("here");
      //   initHeight *= mediaSize.naturalHeight / initHeight;
      // }

      // if (initWidth < mediaSize.naturalWidth) {
      //   alert("here 2");
      //   initWidth *= mediaSize.naturalWidth / initWidth;
      // }

      if (zone.Height !== zone.ZoneImage.Height) {
        initZoom = initWidth / zone.Width;
      } else {
        initZoom = 1;
        initX *= initWidth / zone.Width;
        initY *= initHeight / zone.Height;
      }

      // zoom = Math.max(
      //   imageLayer.Width / zone.Width,
      //   imageLayer.Height / zone.Height,
      //   1
      // );
      // const newWidth = initWidth * compositeStore.canvasRatio * zoom;
      // const newHeight = initHeight * compositeStore.canvasRatio * zoom;
      let newWidth, newHeight;
      let zoom = 1;
      zoom = Math.max(
        imageLayer.Width / mediaSize.naturalWidth,
        imageLayer.Height / mediaSize.naturalHeight,
        1
      );

      setScale(zoom);

      newWidth = zone.Width / zoom;
      newHeight = zone.Height / zoom;

      // const newX =
      //   (imageLayer.X * -1 * newWidth * (mediaSize.naturalWidth / width)) /
      //   zone.Width;

      // const newY =
      //   (imageLayer.Y * -1 * newHeight * (mediaSize.naturalHeight / height)) /
      //   zone.Height;

      const newX = imageLayer.X * -1 * (1 / zoom);

      const newY = imageLayer.Y * -1 * (1 / zoom);
      let fitZoom = 1;
      let objFit = undefined;
      console.log(mediaSize.width);
      console.log(
        `index: ${index}, mediaWidth: ${mediaSize.width}, width: ${width}, mediaHeight: ${mediaSize.height}, height: ${height}`
      );

      if (mediaSize.width < Math.round(width)) {
        fitZoom = Math.max(fitZoom, width / mediaSize.width);
        objFit = "horizontal-cover";
      } else if (mediaSize.height < Math.round(height)) {
        let newFit = height / mediaSize.height;
        if (newFit > fitZoom) {
          fitZoom = Math.max(fitZoom, height / mediaSize.height);
          objFit = "vertical-cover";
        }
      }
      setObjectFit(objFit);
      onZoomChange(zoom * fitZoom);
      setFitZoom(fitZoom);
      console.log(newWidth * fitZoom);
      setScaleX(mediaSize.naturalWidth / width);
      setScaleY(mediaSize.naturalHeight / height);
      if (index === 1) {
        console.log(
          `Width: ${width}, Image layer width: ${imageLayer.Width}, naturalWidth: ${mediaSize.naturalWidth}, zone width: ${zone.Width}, new width: ${newWidth}`
        );
        console.log(
          `Height: ${height}, Image layer Height: ${imageLayer.Height}, naturalHeight: ${mediaSize.naturalHeight}, zone Height: ${zone.Height}, new height: ${newHeight}`
        );
        console.log(
          `Image layer x: ${imageLayer.X} new x: ${newX}, Image layer y: ${imageLayer.Y} new Y: ${newY}`
        );
        console.log(
          mediaSize.naturalWidth / width,
          mediaSize.naturalHeight / height
        );
      }

      await setInitialCropped(
        orgImage.Filename === imageLayer.Filename
          ? {
              x: newX,
              y: newY,
              width: newWidth,
              height: newHeight,
            }
          : false
      );
    }
    setScale(imageLayer.Width / zone.Width);
    setImageLoading(false);
    setFirstImageLoad(true);
  }

  const zoneText = "ontouchstart" in window ? "Tap" : "Click";
  return (
    <div
      style={{
        width,
        height,
        top: y,
        left: x,
        zIndex: index + 1,
        outline: `${
          !compositeStore.activeLines ? "2px dashed rgba(255,255,255,0)" : ""
        }`,
        boxShadow: `${
          !compositeStore.activeLines ? "0 2px 20px rgba(0,0,0,0)" : ""
        }`,
      }}
      className={`composite-layer layer-zone${
        compositeStore.activeRecipeLayerIndex === index ? " active" : ""
      }${imageLoading ? " loading" : ""}${
        mainPhoto?.preview ? " has-photo" : ""
      }`}
    >
      <Button
        color="edit"
        onClick={() => {
          handleClicked(index, mainPhoto, "grid");
        }}
      >
        <AiOutlineInteraction size={22} />
      </Button>
      <div
        className="btn-invisible"
        onClick={() => {
          handleClicked(index, mainPhoto, "grid");
        }}
      ></div>

      <div
        className={`composite-layer-pads`}
        style={{
          width,
          height,
          opacity: zone.Opacity,
          backgroundColor:
            zone.FillZone && fillColor
              ? `rgba(${fillColor.R},${fillColor.G},${fillColor.B},1)`
              : "",
        }}
        onClick={() => {
          if (compositeStore.activeRecipeLayerIndex !== index) {
            handleClicked(index, mainPhoto, "zone");
            compositeStore.updateActiveCompositeZoom({
              zoom,
            });
          }
          if (
            Object.keys(activePhoto).length > 0 &&
            compositeStore.activeRecipeLayerIndex === index
          ) {
            compositeStore.updateActiveComposite({
              ZoneImage: {
                ...compositeStore.activeRecipeLayer.ZoneImage,
                Filename: activePhoto.fileName,
                Width: activePhoto.width,
                Height: activePhoto.height,
              },
            });
          }
          setActivePhoto({});
        }}
        {...props}
      >
        {imageLoading && (
          <div className="spinner">
            <Spinner color="primary" size="sm" />
          </div>
        )}
        {!mainPhoto?.preview ? (
          <div className="no-photo">
            <img
              src={DropzoneImage}
              style={{
                width: width / 2,
              }}
              alt="Logo"
            />
            {/* <AiOutlinePlusCircle size={16} />
            <div
              style={{
                fontSize: (width / 3) * compositeStore.canvasRatio,
              }}
            >
              {zoneText} here and then {zoneText.toLowerCase()} on an image
            </div>
            <div
              style={{
                fontSize: (width / 3) * compositeStore.canvasRatio,
              }}
            >
              You can drag and zoom
            </div> */}
          </div>
        ) : (
          <div
            style={{
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: index + 1,
              transform: "translate(-50%,-50%)",
              transition: "all ease-in-out 0.3s",
            }}
          >
            <Cropper
              image={mainPhoto?.preview}
              crop={crop}
              zoom={zoom}
              showGrid={false}
              onCropChange={() => {}}
              onZoomChange={() => {}}
              key={key}
              style={{
                containerStyle: {
                  zIndex: -1000,
                  opacity: 0,
                },
              }}
              onMediaLoaded={handleFirstImage}
            />

            {firstImageLoad && (
              <Cropper
                image={mainPhoto?.preview}
                crop={crop}
                zoom={zoom}
                showGrid={false}
                key={key}
                // objectFit={objectFit}
                ref={ref}
                // objectFit={"cover"}
                // aspect={zone.Width / zone.Height}
                onCropChange={onCropChange}
                onZoomChange={(z: number) => {
                  let newZoom = z < 1 ? 1 : z;
                  if (
                    (zone.Height * z) / fitZoom < zone.Height ||
                    (zone.Width * z) / fitZoom < zone.Width
                  ) {
                    return;
                  }
                  console.log(z);
                  console.log((zone.Height * zoom) / fitZoom);
                  console.log(zone.Height);
                  onZoomChange(newZoom);
                  compositeStore.updateActiveCompositeZoom({
                    zoom: newZoom,
                  });
                }}
                zoomSpeed={0.07}
                maxZoom={9}
                cropSize={{ width, height }}
                initialCroppedAreaPixels={initialCropped}
                onCropComplete={(
                  croppedArea?: any,
                  croppedAreaPixels?: any
                ) => {
                  if (!initialized) {
                    setInitialized(true);
                    return;
                  }
                  // console.log(croppedAreaPixels);
                  // console.log(fitZoom);
                  // console.log(zoom);
                  // console.log((zone.Width * zoom) / fitZoom);
                  setImageLoading(false);
                  compositeStore.setActiveRecipeLayer(index);
                  compositeStore.updateActiveComposite({
                    ZoneImage: {
                      ...imageLayer,
                      FileName: zone.ZoneImage.Filename,
                      Width: Math.floor((zone.Width * zoom) / fitZoom),
                      Height: Math.floor((zone.Height * zoom) / fitZoom),
                      X:
                        croppedAreaPixels.x === 0
                          ? 0
                          : Math.floor(
                              croppedAreaPixels.x *
                                -1 *
                                (zone.Width / croppedAreaPixels.width)
                            ),
                      Y:
                        croppedAreaPixels.y === 0
                          ? 0
                          : Math.floor(
                              croppedAreaPixels.y *
                                -1 *
                                (zone.Height / croppedAreaPixels.height)
                            ),
                    },
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default inject(
  "compositeStore",
  "sessionStore"
)(observer(CompositeLayerZone));
