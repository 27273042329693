import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import LoginPage from "../pages/auth/login";
import SignupPage from "../pages/auth/signup";
import EventSearchPage from "../pages/event-search/event-search";
import ForgotPasswordPage from "../pages/auth/forgotPassword";
import ResetPasswordPage from "../pages/auth/resetPassword";
import OrganizationSearchPage from "../pages/organization-search/organization-search";

const HomeRouter: React.FC<any> = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path={`${match.path}/login`} component={LoginPage} />
        <Route
          exact
          path={`${match.path}/forgot-password`}
          component={ForgotPasswordPage}
        />
        <Route exact path={`${match.path}/signup`} component={SignupPage} />
        <Route
          exact
          path={`${match.path}/event-search`}
          component={EventSearchPage}
        />
        <Route
          exact
          path={`${match.path}/organization-search`}
          component={OrganizationSearchPage}
        />
        <Route
          exact
          path={`${match.path}/reset-password`}
          component={ResetPasswordPage}
        />
        <Route>
          <Redirect to={`${match.path}/login`} />
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

export default HomeRouter;
