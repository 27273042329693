import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import LoginForm, {
  LoginFormData,
} from "../../components/forms/auth/loginForm";
import EventSearchForm, {
  EventSearchFormData,
} from "../../components/forms/eventSearch/eventSearchForm";
import { guestAuthParams } from "../../interfaces/auth.int";

const Embed: React.FC<any> = ({
  authStore,
  sessionStore,
  navigationStore,
  eventStore,
}) => {
  const [activeTab, setActiveTab] = useState("login");
  //const [getUrl, setUrl] = useState<any>();
  const { t } = useTranslation();

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    fetchData();
  }, [authStore]);

  async function fetchData() {
    var subdomain = window.location.host.split(".")[0];
    const hasSubdomain = subdomain !== window.location.host;

    if (hasSubdomain) {
      if (subdomain.indexOf("-") >= 0) {
        subdomain = subdomain.substring(0, subdomain.indexOf("-"));
      }

      await authStore.getCompanyInfo(subdomain).catch((e: any) => {
        console.log(e);
      });
    }
  }

  function redirect() {
    if (!!authStore.token) {
      navigationStore.push({
        pathname: "/dashboard",
        search: "?embed=1&clientLoginToken=" + authStore.token,
      });
      //window.parent.location.href = '/clientLoginToken=' + authStore.token;
      //setUrl({pathname: '/embed', state: { userName: 'dss' }});
    }
  }

  async function onSubmitLogin(obj: LoginFormData) {
    await authStore.login(obj).then((res: any) => {
      redirect();
    });
  }

  async function onSubmitSearch(obj: EventSearchFormData) {
    switch (obj.search_by) {
      case "code":
        const event: any = await eventStore.getPublicEcomEventById(obj.code);
        if (event?.SessionKey) {
          const params: guestAuthParams = {
            eventKey: event.SessionKey,
            eventDate: event.EventDate,
          };
          await authStore.guestAuthentication(params);
          await sessionStore.getSessionDetailByKey(event.SessionKey);
          await redirect();
        }
        return;
      case "publicCode":
        const params: guestAuthParams = {
          eventKey: obj.publicCode,
          eventDate: obj.date,
        };
        await authStore.guestAuthentication(params);
        await sessionStore.getSessionDetailByKey(obj.publicCode);
        await redirect();
        return;
      case "date":
        const dateParams: guestAuthParams = {
          eventKey: obj.code_by_date,
          eventDate: obj.date,
        };
        await authStore.guestAuthentication(dateParams);
        await sessionStore.getSessionDetailByKey(obj.code_by_date);
        await redirect();
        return;
    }
  }

  if (authStore.isLoggingIn || sessionStore.loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>Logging you in...</p>
          <Spinner style={{ margin: "0 auto" }} color="primary" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="embed-page">
        <div className="container-fluid">
          <header className="auth-header">
            <nav>
              <div
                className="branding sr-only"
                style={{ backgroundImage: `url(${authStore.logoUrl})` }}
              >
                {authStore.companyName}
              </div>
              <Nav role="presentation" className="nav-tabs">
                <NavItem>
                  <NavLink
                    className={`${activeTab === "login" ? "active" : ""}`}
                    onClick={() => {
                      toggle("login");
                    }}
                  >
                    {t("HomePage.signIn")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "search" ? "active" : ""}`}
                    onClick={() => {
                      toggle("search");
                    }}
                  >
                    {t("HomePage.eventSearch")}
                  </NavLink>
                </NavItem>
              </Nav>
            </nav>
          </header>
          <main className="embed-main">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="login">
                <h1>{t("LoginPage.title")}</h1>
                {!authStore.disableEventSearch && (
                  <p>{t("LoginPage.description")}</p>
                )}
                {!authStore.disableSignUpTab && (
                  <p>
                    If you already have an account, log in here to view your
                    images, or click 'Sign Up' to create an account
                  </p>
                )}
                <LoginForm
                  emailLabel={t("LoginPage.email")}
                  emailError={t("LoginPage.emailError")}
                  passwordLabel={t("LoginPage.password")}
                  passwordError={t("LoginPage.passwordError")}
                  ajaxError={
                    authStore.loginError ? t("LoginPage.ajaxError") : ""
                  }
                  submitBtn={t("LoginPage.loginButton")}
                  onSubmit={onSubmitLogin}
                />
              </TabPane>

              <TabPane tabId="search">
                <h1>{t("EventSearchPage.title")}</h1>
                <p>{t("EventSearchPage.p1")}</p>
                <EventSearchForm
                  publicEvents={eventStore.dropdownPublicEcomEvents}
                  content={{
                    date: t("EventSearchPage.date"),
                    code: t("EventSearchPage.code"),
                    public: t("EventSearchPage.public"),
                    search: t("EventSearchPage.search"),
                  }}
                  onSubmit={onSubmitSearch}
                />
              </TabPane>
            </TabContent>
          </main>
        </div>
      </div>
    </>
  );
};

export default inject(
  "authStore",
  "navigationStore",
  "sessionStore",
  "eventStore"
)(observer(Embed));
