import { observable, action, computed, reaction } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse } from "axios";
import {
  Session,
  SessionAbstract,
  SessionImageListItem,
} from "../interfaces/session.int";
import ClientStore from "./clientStore";
import ImageGroupStore from "./imageGroupStore";
import EcomStore from "./ecomStore";
import { setAxiosSessionKey } from "../components/axios-config";
import { setAxiosLocationNumber } from "../components/axios-config";
import AuthStore from "./authStore";

class SessionStoreClass {
  @persist("object") @observable defaultSession: any = {};
  @persist("object") @observable activeSession: Session = {};
  @persist("object") @observable guestSession: Session = {};
  @persist("list") @observable allSessions: Array<SessionAbstract> = [];
  @persist("list") @observable allSessionDetail: any = [];

  @persist @observable sessionKey: string = "";
  @persist @observable locationNumber: string = "";
  @persist @observable errorSessions: boolean = false;

  // @observable existingSessions: Array<Session> = [];
  @persist("object") @observable swappedPhoto: any = {};

  @observable loading: boolean = false;

  @persist @observable clientSelectionkey: any = "";

  @persist @observable newSessionKey: any = "";

  @observable clientSelectionloading: boolean = false;

  @observable hydrated: boolean = false;
  @observable updateOnActiveSessionChanges: VoidFunction = () => null;

  constructor() {
    this.updateOnActiveSessionChanges = reaction(
      () => this.activeSession,
      (activeSession) => {
        if (!!activeSession?.Key && this.hydrated) {
          this.afterSessionCallback(activeSession);
        }
      }
    );
  }

  @computed get allSessionList() {
    return this.allSessions?.map((add: SessionAbstract) => add) || [];
  }

  @action async getAllSessionDetail() {
    this.allSessionDetail = [];
    this.loading = true;
    this.allSessions.map((s: SessionAbstract, i: number) =>
      axios({
        method: "GET",
        url: `/ibySession/${s.SessionKey}`,
        params: ClientStore?.clientInfo?.Key
          ? {
              clientKey: ClientStore.clientInfo.Key,
            }
          : null,
      }).then((res: AxiosResponse) => {
        this.loading = false;
        this.allSessionDetail.push(res.data);
        if (Object.keys(this.guestSession).length > 0) {
          this.allSessionDetail.push(this.guestSession);
          this.activeSession = this.guestSession;
          this.defaultSession = this.guestSession;
        } else if (ClientStore.clientInfo.DefaultRecordKey === s.SessionKey) {
          this.activeSession = res.data;
          this.defaultSession = res.data;
        }
        // if (!!!this.activeSession?.Images && i === 0) {
        //   this.activeSession = res.data;
        // }
      })
    );
  }

  @action refreshSessionDetail(sessionKey: string) {
    // Get session details.
    this.loading = true;

    axios({
      method: "GET",
      url: `/ibySession/${sessionKey}`,
      params: ClientStore?.clientInfo?.Key
        ? {
            clientKey: ClientStore.clientInfo.Key,
          }
        : null,
    }).then((res: AxiosResponse) => {
      this.loading = false;
      var newSession = true;
      this.allSessionDetail.map((s: Session) => {
        if (s.Key == sessionKey) {
          newSession = false;
          return;
        }
      });

      if (newSession === true) {
      } else {
        this.allSessionDetail = this.allSessionDetail.filter((s: Session) => {
          return s.Key !== sessionKey;
        });
      }

      this.allSessionDetail.push(res.data);
      this.activeSession = res.data;
    });
  }

  @computed get sessionsDropdown() {
    return this.allSessions
      ?.map((s: any) => ({
        label: s.SessionDescription,
        value: s.SessionKey,
      }))
      .filter((s: any) => s.value !== this.activeSession.Key);
  }

  @computed get allNonGuestSessions() {
    if (!this.allSessions) {
      return [];
    }
    return this.allSessions?.filter((s: any) => !s.IsGuest);
  }

  @computed get activeShareSession() {
    if (this.activeSession?.IsGuest) {
      return this.allNonGuestSessions[0];
    } else {
      return this.allSessions?.filter(
        (s: any) => s.SessionKey === this.activeSession.Key
      )[0];
    }
  }

  @computed get nonGuestSessionsDropdown() {
    return this.allNonGuestSessions
      .map((s: any) => ({
        label: s.SessionDescription,
        value: s.SessionKey,
      }))
      .filter((s: any) => s.value !== this.activeSession?.Key);
  }

  @computed get hasMultipleSessions() {
    return this.allSessions.length > 1;
  }

  @computed get hasMultipleNonGuestSessions() {
    return this.allNonGuestSessions.length > 1;
  }

  // returns a usable array of image information for display and download
  @action parseImages(session: Session) {
    return (
      session?.Images?.Images?.map((img: SessionImageListItem) => {
        const sessionObj = session?.Images || null;
        if (sessionObj?.ViewerUrl) {
          return {
            preview: `${sessionObj.ViewerUrl}${img.PreviewQueryString}${img.CropQueryString}${img.AdjustmentsQueryString}`,
            thumbnail: `${sessionObj.ViewerUrl}${img.ThumbQueryString}${img.CropQueryString}${img.AdjustmentsQueryString}`,
            downloadURL: img.DownloadQueryString
              ? `${sessionObj.SingleImageDownloadUrl}${img.DownloadQueryString}`
              : undefined,
            fileName: img.FileName,
            key: img.Key,
            sessionKey: session.Key,
            height: img.Height,
            width: img.Width,
            calculatedHeight: img.CropH ? img.Height * img.CropH : img.Height,
            calculatedWidth: img.CropW ? img.Width * img.CropW : img.Width,
            allowDownload: img.AllowDownload,
            imageNumber: img.ImageNumber,
            defaultColorization: img.DefaultColorization,
            compositeKey: img.CompositeKey,
          };
        }
      }) || []
    );
  }

  @computed get allUnfilteredImages() {
    return this.parseImages(this.activeSession);
  }

  @computed get activeSessionImages() {
    return this.parseImages(this.activeSession).filter(
      (img: any) => !ImageGroupStore.hiddenImageList.includes(img.fileName)
    );
  }

  @computed get activeSessionFavoritedImages() {
    return this.parseImages(this.activeSession).filter((img: any) =>
      ImageGroupStore.favoriteImageList.includes(img.fileName)
    );
  }
  // find image url for cart item
  @action getImgUrl(sessionKey?: string, fileName?: string, preview = false) {
    const session = this.allSessionDetail.find(
      (s: Session) => s.Key === sessionKey
    );
    if (!session) {
      return false;
    }
    const img = session?.Images?.Images?.filter(
      (img: SessionImageListItem) =>
        img.FileName?.toUpperCase() === fileName?.toUpperCase()
    ).shift();
    if (!img?.ThumbQueryString) {
      return false;
    }
    if (preview) {
      return `${session.Images.ViewerUrl}${img?.PreviewQueryString}`;
    }
    return `${session.Images.ViewerUrl}${img?.ThumbQueryString}`;
  }

  @action getImgKey(sessionKey?: string, fileName?: string) {
    const session = this.allSessionDetail.find(
      (s: Session) => s.Key === sessionKey
    );
    if (!session) {
      return false;
    }
    return session?.Images?.Images?.find(
      (img: SessionImageListItem) =>
        img.FileName?.toUpperCase() === fileName?.toUpperCase()
    )?.Key;
  }

  @action getImagesBySession(sessionKey: any) {
    const theSessionObj: any = this.allSessionDetail.find(
      (s: any) => s.Key === sessionKey
    );
    return theSessionObj
      ? this.parseImages(theSessionObj).filter(
          (img: any) => !ImageGroupStore.hiddenImageList.includes(img.fileName)
        )
      : [];
  }

  @action getImageByFilename(filename: string, sessionKey?: string) {
    return this.getImagesBySession(
      sessionKey ? sessionKey : this.activeSession.Key
    ).find((img: any) => img.fileName === filename);
  }

  @computed get swapPhotoCurrentSession() {
    return this.allSessionDetail.find(
      (d: Session) => d.Key === this.swappedPhoto?.SessionKey
    );
  }
  @computed get swapPhotoCurrentSessionImages() {
    return this.parseImages(this.swapPhotoCurrentSession);
  }

  @action setYearbookPhoto(obj: any) {
    const data = { ...obj, SessionKey: this.activeSession.Key };
    return axios({
      method: "PUT",
      url: `/ibyYearbook`,
      data: data,
    }).then((res: AxiosResponse) => {
      // update active session for on-screen feedback
      this.activeSession.Yearbook = {
        ...this.activeSession.Yearbook,
        ...res.data,
      };
      // update session detail so the new values stay cached
      this.allSessionDetail = this.allSessionDetail.map((s: Session) =>
        s.Key === res.data.SessionKey
          ? { ...s, Yearbook: { ...s.Yearbook, ...res.data } }
          : s
      );
    });
  }

  @action getSessionDetailByShareToken(token?: string) {
    this.activeSession = {};
    this.loading = true;
    return axios({
      method: "GET",
      url: `/ibySession`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        shareToken: token,
      },
    })
      .then((res: AxiosResponse) => {
        this.loading = false;
        this.activeSession = res.data;
      })
      .catch(() => {
        this.errorSessions = true;
      });
  }

  @action getSessionDetailByKey(key?: string) {
    if (!key) return;
    // this.activeSession = {};
    this.loading = true;
    return axios({
      method: "GET",
      url: `/ibySession/${key}`,
      params: ClientStore?.clientInfo?.Key
        ? {
            clientKey: ClientStore.clientInfo.Key,
          }
        : null,
    })
      .then((res: AxiosResponse) => {
        this.loading = false;
        this.activeSession = res.data;
        if (res.data.IsGuest) {
          this.guestSession = res.data;
        }
      })
      .catch(() => {
        this.errorSessions = true;
      });
  }

  @action async getDefaultRecord(key: string) {
    this.loading = true;
    return axios({
      method: "GET",
      url: `/ibySession/${key}`,
      params: ClientStore?.clientInfo?.Key
        ? {
            clientKey: ClientStore.clientInfo.Key,
          }
        : null,
    })
      .then(async (res: AxiosResponse) => {
        this.loading = false;
        this.defaultSession = res.data;
        if (!this.activeSession?.Images) {
          this.activeSession = res.data;
          return;
        }
      })
      .catch(() => {
        this.errorSessions = true;
      });
  }

  // invoked only in the reaction() in the constructor
  @action async afterSessionCallback(session: Session) {
    this.sessionKey = session?.Key || "";
    setAxiosSessionKey(this.sessionKey);
    this.locationNumber = session?.LocationNumber || "";
    setAxiosLocationNumber(this.locationNumber);
    if (!!session?.PriceListKey && ClientStore?.clientInfo?.Key) {
      EcomStore.getPriceList(session.PriceListKey);
    }
    if (!AuthStore.isGuestSession) {
      ImageGroupStore.setImageGroups(session?.Groups || []);
    }
  }

  @action clientSelectSession(key: string) {
    this.clientSelectionloading = true;
    this.clientSelectionkey = key;
    // this.activeSession = {};
    // this.activeSession = this.allSessionDetail.find((s:Session)=>(s.Key === key))
    // this.loading = false;
    this.refreshSessionDetail(this.clientSelectionkey);

    setTimeout(() => {
      this.clientSelectionloading = false;
    }, 5000);
  }

  @action bustCache() {
    this.allSessions = [];
  }

  @action async onLogin(res: any) {
    this.allSessions = res.Sessions?.sort((a: any, b: any) =>
      a.SessionKey.localeCompare(b.SessionKey)
    ).reverse();
    await this.getAllSessionDetail().catch(() => {
      this.errorSessions = true;
    });
    return true;
  }

  @action initImageUpload(imageCount: number, FileNames: any) {
    return axios({
      method: "PUT",
      url: `/ibySessionImageUpload`,
      data: {
        Action: "Start",
        imageCount: imageCount,
        FileNames: FileNames,
        SessionKey: this.activeSession?.Key,
        ClientKey: ClientStore.clientInfo.Key,
      },
    })
      .then((res: AxiosResponse) => {
        // this.putSessionImageUploadBlobStorage(res.data, payload.Images);
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @action putSessionImageUploadBlobStorage(
    uploadURL: string,
    images: any,
    imagesLength: any
  ) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
        "x-ms-blob-type": "BlockBlob",
        "Content-Length": imagesLength,
      },
      body: images,
    };

    return (
      fetch(uploadURL, requestOptions)
        .then((response) => console.log("blobResponse", response))
        //.then((data) => console.log("blobResponse2", data))
        .catch((e) => {
          console.log(e);
        })
    );
  }

  @action completeImageUpload(uploadJobId: any) {
    return axios({
      method: "PUT",
      url: `/ibySessionImageUpload`,
      data: {
        Action: "Complete",
        UploadJobId: uploadJobId,
        SessionKey: this.activeSession?.Key,
        ClientKey: ClientStore.clientInfo.Key,
      },
    })
      .then((res: AxiosResponse) => {
        console.log("CompletedResponse", res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @action putSession(session: SessionAbstract) {
    this.clientSelectionloading = true;
    return axios({
      method: "PUT",
      url: `/ibySession`,
      data: {
        ...session,
        ClientKey: ClientStore.clientInfo.Key,
        SessionTypeID: session.SessionKey ? undefined : "00-999-47",
      },

      // data: { ...session },
    }).then((res: AxiosResponse) => {
      this.activeSession = res.data;
      this.newSessionKey = this.activeSession?.Key;
      setTimeout(() => {
        this.clientSelectionloading = false;
      }, 3000);
    });
  }

  @action deleteSession(key: string) {
    return axios({
      method: "DELETE",
      url: `/ibySession/${key}`,
    }).then((res: AxiosResponse) => {
      this.activeSession = res.data;
      this.getAllSessionDetail();
    });
  }

  @action onLogout() {
    this.activeSession = {};
    this.guestSession = {};
    this.allSessions = [];
    this.allSessionDetail = [];
    this.defaultSession = {};
    this.errorSessions = false;
    this.sessionKey = "";
    this.loading = false;
  }
}

const hydrate = create({});
const SessionStore = new SessionStoreClass();
export default SessionStore;
hydrate("session", SessionStore).then((store) => {
  setAxiosSessionKey(store.sessionKey);
  store.hydrated = true;
});
