import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
export const DatePickerField = ({ ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      maxDate={moment().toDate()}
      // {...field}
      // {...props}
      className="form-control"
      selected={field.value ? new Date(field.value) : null}
      onChange={(date: any) => {
        if (!date) {
          setFieldValue(field.name, null);
        } else {
          setFieldValue(field.name, moment(date).format("MM/DD/YYYY"));
        }
      }}
    />
  );
};
