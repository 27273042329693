import React, { useRef, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import sanitizeHtml from "sanitize-html-react";
import Moveable from "react-moveable";

import {
  CompositeTemplateTextLayer,
  CompositeTemplateColor,
  CompositeTemplateDropShadow,
  CompositeTemplateFont,
} from "../../../interfaces/composite.int";

const CompositeLayerText: React.FC<any> = ({
  compositeStore,
  textData = {},
  index,
  handleClicked,
  ...props
}) => {
  const ref: any = useRef(null);
  const [offsetWidth, setOffsetWidth] = useState(0);

  useEffect(() => {
    const { offsetWidth, offsetHeight } = ref.current;
    setOffsetWidth(offsetWidth);
  }, []);
  const canvasWidth =
    compositeStore?.activeComposite?.Recipe?.Width * compositeStore.canvasRatio;
  const canvasHeight =
    compositeStore?.activeComposite?.Recipe?.Height *
    compositeStore.canvasRatio;

  let zone: CompositeTemplateTextLayer =
    compositeStore.activeRecipeNewLayers[index];

  let width: number = zone.Width * compositeStore.canvasRatio;
  let height: number = zone.Height * compositeStore.canvasRatio;
  let x: number = zone.X * compositeStore.canvasRatio;
  let y: number = zone.Y * compositeStore.canvasRatio;
  let textColor: CompositeTemplateColor = zone?.Color;
  let textOpacity = zone?.Opacity;
  let bgColor: CompositeTemplateColor = zone?.BackColor;
  let bgOpacity = zone?.BackgroundOpacity;
  let textShadow: CompositeTemplateDropShadow = zone?.DropShadow;
  let textShadowColor: CompositeTemplateColor = textShadow?.Color;
  let font: CompositeTemplateFont = zone?.Font;
  let fontStyle: any = font?.Style?.replace(/\s/g, "").split(",");

  function clearText(e: any) {
    console.log(e.currentTarget.innerText);
    let Text: any = sanitizeHtml(e.currentTarget.innerText, {
      allowedTags: [],
      allowedAttributes: {},
    });
    console.log(Text);
    compositeStore.updateActiveComposite({
      Text,
      // Font: {
      //   ...font,
      //   Family: "mistral",
      // },
    });
  }
  let translateX = 0;
  if (font?.Rotation >= 0) {
    if (font?.Rotation <= 30) {
      translateX = 0;
    } else if (font?.Rotation <= 45) {
      translateX = -30;
    } else if (font?.Rotation <= 60) {
      translateX = -50;
    } else if (font?.Rotation === 90) {
      translateX = -Math.max(100 * (width / offsetWidth), 100);
    }
  } else {
    if (font?.Rotation >= -30) {
      translateX = 0;
    }
    if (font?.Rotation >= -45) {
      translateX = -20;
    } else if (font?.Rotation >= -60) {
      translateX = -22;
    } else if (font?.Rotation === -90) {
      translateX = -30;
    }
  }
  // if (font?.Rotation >= 0) {
  //   if (font?.Rotation <= 30) {
  //     translateX = 0;
  //   } else if (font?.Rotation <= 90) {
  //     translateX = (-1 * width * (font?.Rotation - 30)) / 90;
  //   }
  // } else {
  //   if (font?.Rotation >= -30) {
  //     translateX = 0;
  //   }
  //   if (font?.Rotation >= -45) {
  //     translateX = -20;
  //   } else if (font?.Rotation >= -60) {
  //     translateX = -22;
  //   } else if (font?.Rotation === -90) {
  //     translateX = -30;
  //   }
  // }
  translateX *= Math.sign(font?.Rotation);
  return (
    <>
      {/* <Moveable
        className="movable-text"
        style={{}}
        target={
          compositeStore.activeRecipeLayerIndex === index &&
          compositeStore.activeLines &&
          zone.AllowEditPosition
            ? (document.querySelector(`.layer-text-${index}`) as any)
            : null
        }
        edge={compositeStore.activeLines && true}
        origin={false}
        checkInput={true}
        padding={{ left: 15, top: 15, right: 15, bottom: 15 }}
        draggable={compositeStore.activeLines && true}
        onDrag={({ target, left, top, currentTarget }: any) => {
          let x: any = left;
          let y: any = top;
          let width: any = currentTarget.state.width;
          let height: any = currentTarget.state.height;

          if (left < 0) {
            x = 0 + "px";
          } else if (left + width > canvasWidth) {
            x = canvasWidth - width + "px";
          } else {
            x = left + "px";
          }
          if (top < 0) {
            y = 0 + "px";
          } else if (top + height > canvasHeight) {
            y = canvasHeight - height + "px";
          } else {
            y = top + "px";
          }

          target!.style.left = x;
          target!.style.top = y;
        }}
        onDragEnd={({ isDrag, target }: any) => {
          if (isDrag) {
            compositeStore.updateActiveComposite({
              X: parseInt(target.style.left) / compositeStore.canvasRatio,
              Y: parseInt(target.style.top) / compositeStore.canvasRatio,
            });
          }
        }}
        resizable={compositeStore.activeLines && true}
        onResize={({ target, width, height, drag }: any) => {
          let x: any = drag.left;
          let y: any = drag.top;
          let nheight: any = height;
          let nwidth: any = width;

          if (x < 0) {
            x = 0 + "px";
          } else if (x + nwidth > canvasWidth) {
            nwidth = canvasWidth - target?.style?.left + "px";
          } else {
            x = x + "px";
            nwidth = nwidth + "px";
          }

          if (y < 0) {
            y = 0 + "px";
          } else if (y + nheight > canvasHeight) {
            nheight = canvasHeight - target?.style?.top + "px";
          } else {
            y = y + "px";
            nheight = nheight + "px";
          }

          target.style.left = x;
          target.style.top = y;
          target.style.height = nheight;
          target.style.width = nwidth;
        }}
        onResizeEnd={({ target }: any) => {
          compositeStore.updateActiveComposite({
            X: parseInt(target.style.left) / compositeStore.canvasRatio,
            Y: parseInt(target.style.top) / compositeStore.canvasRatio,
            Width: parseInt(target.style.width) / compositeStore.canvasRatio,
            Height: parseInt(target.style.height) / compositeStore.canvasRatio,
          });
        }}
        // rotatable={compositeStore.activeLines && true}
        onRotate={({ target, rotate }: any) => {
          target!.style.transform = `rotate(${rotate}deg)`;
        }}
        onRotateStart={({ set }: any) => {
          set(font.Rotation);
        }}
        onRotateEnd={({ target, currentTarget }: any) => {
          let trans: any = target?.style?.transform.match(/\(([^)]+)(deg)\)/);
          compositeStore.updateActiveComposite({
            Font: {
              ...font,
              Rotation: parseFloat(trans[1]),
            },
          });
        }}
      /> */}
      <div
        className={`composite-layer layer-text layer-text-${index}${
          compositeStore.activeRecipeLayerIndex === index ? " active" : ""
        }`}
        style={{
          width: width,
          height: height,
          top: y,
          left: x,
          overflow: "hidden",
          textOverflow: "clip",
          backgroundColor: bgColor
            ? `rgba(${bgColor?.R},${bgColor?.G},${bgColor?.B},${bgOpacity})`
            : "",
          color: textColor
            ? `rgba(${textColor?.R},${textColor?.G},${textColor?.B},${textOpacity})`
            : "",
          textShadow: textShadow?.DropShadowOn
            ? `${textShadow?.OffsetX * compositeStore.canvasRatio}px ${
                textShadow?.OffsetY * compositeStore.canvasRatio
              }px ${
                textShadow?.Smoothing * compositeStore.canvasRatio
              }px rgba(${textShadowColor?.R},${textShadowColor?.G},${
                textShadowColor?.B
              },${textShadow?.Opacity})`
            : "",
          textAlign:
            font.Rotation === 90
              ? "right"
              : font.Rotation === -90
              ? "left"
              : zone.AlignmentHorizontal,
          // paddingLeft:
          //   font.Rotation === 0
          //     ? `${
          //         font.PointSize >= 300
          //           ? font.PointSize / 200
          //           : font.PointSize / 300
          //       }rem`
          //     : undefined,
          // paddingTop:
          //   font.Rotation === 90 ? `${font.PointSize / 300}rem` : undefined,
          // paddingBottom:
          //   font.Rotation === -90 ? `${font.PointSize / 300}rem` : undefined,
          // padding: "5rem",
          verticalAlign: zone.AlignmentVertical,
          // fontFamily: "mistral",
          fontFamily: font?.Family,

          fontSize:
            font?.PointSize > 0 &&
            `${font?.PointSize * compositeStore.canvasRatio * 1.08}pt`,
          fontWeight: fontStyle.includes("Bold") ? "bold" : "",
          fontStyle: fontStyle.includes("Italic") ? "italic" : "",
          textDecoration: fontStyle.includes("Underline") ? "underline" : "",
          letterSpacing:
            font?.Kerning > 0 &&
            `${font?.Kerning * compositeStore.canvasRatio}pt`,
          lineHeight:
            font?.LineSpacing > 0 &&
            `${font?.LineSpacing * compositeStore.canvasRatio}pt`,
          zIndex: index + 1,
          outline: `${
            !compositeStore.activeLines ? "2px dashed rgba(255,255,255,0)" : ""
          }`,
          boxShadow: `${
            !compositeStore.activeLines ? "0 2px 20px rgba(0,0,0,0)" : ""
          }`,
          // transform: font?.Rotation !== 0 && `rotate(-${font?.Rotation}deg)`,
        }}
        {...props}
      >
        <div
          style={{
            overflow: "hidden",
            // display: "inline-block",
            margin: "0",
            outline: "none",
            // height: `${Math.max(width, height)}px`,
            // width: `${Math.max(width, height)}px`,
            // lineHeight: `${height}px`,
            // width,
            whiteSpace: "nowrap",
            paddingLeft: "1rem",
            lineHeight: "normal",
            position: "relative",
            // display: "flex",
            display: "inline-block",
            transformOrigin: `${
              font?.Rotation * -1 < 0 ? "right top" : "left top"
            }`,
            transform: `translateX(${translateX}%) rotate(${
              font?.Rotation ? font?.Rotation * -1 : 0
            }deg)`,
          }}
          ref={ref}
          contentEditable={zone?.AllowEditText && true}
          aria-multiline={true}
          role="textbox"
          onClick={() => {
            handleClicked(index, zone, "text");
          }}
          tabIndex={0}
          suppressContentEditableWarning={true}
          data-placeholder="Textbox"
          onBlur={clearText}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === "Meta") e.preventDefault();
          }}
          {...props}
        >
          {zone.Text !== "Textbox" ? `${zone?.Text}` : ""}
        </div>
      </div>
    </>
  );
};

export default inject("compositeStore")(observer(CompositeLayerText));
