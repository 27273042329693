import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AiOutlineEyeInvisible,
  AiOutlineCloudDownload,
  AiOutlineFolderAdd,
  AiOutlineEye,
  AiOutlineFolder,
  AiOutlineRead,
  AiOutlineShareAlt,
  AiOutlineSmile,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
import JoyForm from "../../../components/forms/joy/joyForm";
import "./imgTileDropdown.scss";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
const ImageTileDropdown: React.FC<any> = ({
  img,
  imageGroupStore,
  imageShareStore,
  sessionStore,
  inLightBox = false,
  isGroup = false,
  groupName,
  removeImageFromGroup,
  authStore,
  loginPromptStore,
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalJoy, setModalJoy] = useState(false);

  async function downloadImage(img: any) {
    const a: any = document.createElement("a");
    a.href = img.downloadURL;
    a.download = img.fileName;
    a.rel = "noopener noreferrer";
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function addToYearbook(key: string, img: any) {
    sessionStore.setYearbookPhoto({ [key]: img.fileName });
  }

  const toggleJoy = (e: any) => {
    setModalJoy(!modalJoy);
  };

  return (
    <Dropdown
      direction="down"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className="downdown-menu-icon-container"
    >
      <DropdownToggle
        color="link"
        className={`btn-icon btn-ellipsis ${inLightBox ? "inLightBox" : ""}`}
      >
        <span className="sr-only">{t("imgTileDropdownComponent.moreNav")}</span>
        <span className="bullet bullet-1"></span>
        <span className="bullet bullet-2"></span>
        <span className="bullet bullet-3"></span>
      </DropdownToggle>
      <DropdownMenu
        right
        positionFixed
        flip={false}
        className="dropdown-menu-icon"
      >
        {img?.allowDownload && (
          <DropdownItem
            onClick={() => {
              if (authStore.isGuestSession) {
                loginPromptStore.setShowLoginPrompt(true);
                return;
              }
              downloadImage(img);
            }}
          >
            <AiOutlineCloudDownload size="24" />
            {t("imgTileDropdownComponent.download")}
          </DropdownItem>
        )}
        {isGroup ? (
          <>
            {groupName.toUpperCase() !== "HIDDEN" && (
              <DropdownItem onClick={removeImageFromGroup}>
                <AiOutlineFolder size="24" />
                {t("imgTileDropdownComponent.removeFrom")}{" "}
                <br className="d-sm-none" />
                {groupName}
              </DropdownItem>
            )}
          </>
        ) : (
          <DropdownItem
            onClick={() => {
              if (authStore.isGuestSession) {
                loginPromptStore.setShowLoginPrompt(true);
              } else {
                imageGroupStore.imageSelectedForGroup(img?.fileName);
              }
            }}
          >
            <AiOutlineFolderAdd size="24" />
            {t("imgTileDropdownComponent.modifyImage")}{" "}
            <br className="d-sm-none" />
            {t("imgTileDropdownComponent.folders")}
          </DropdownItem>
        )}

        {!!sessionStore.activeSession?.Yearbook?.AllowImage1Selection && (
          <DropdownItem onClick={() => addToYearbook("Image1FileName", img)}>
            <div className="icon-container">
              <AiOutlineRead size="24" />
              <Badge color="dark">1</Badge>
            </div>
            <div className="label-container">
              {t("imgTileDropdownComponent.makePrimary")}
              <br />
              {t("imgTileDropdownComponent.yearbookImage")}
            </div>
          </DropdownItem>
        )}
        {!!sessionStore.activeSession?.Yearbook?.AllowImage2Selection && (
          <DropdownItem onClick={() => addToYearbook("Image2FileName", img)}>
            <div className="icon-container">
              <AiOutlineRead size="24" />
              <Badge color="dark">2</Badge>
            </div>
            <div className="label-container">
              {t("imgTileDropdownComponent.makeSecondary")}
              <br />
              {t("imgTileDropdownComponent.yearbookImage")}
            </div>
          </DropdownItem>
        )}

        {authStore?.accountName !== "tpp" &&
          !sessionStore.activeSession?.IsGuest &&
          authStore.allowSocialShare && (
            <DropdownItem
              onClick={() => {
                imageShareStore.statefulSharing(img);
              }}
            >
              <AiOutlineShareAlt size="24" />
              {t("imgTileDropdownComponent.socialShare")}
            </DropdownItem>
          )}

        {authStore.accountName.toLowerCase() === "annegeddes" &&
          !sessionStore.activeSession.IsGuest && (
            <DropdownItem onClick={toggleJoy}>
              <div className="icon-container">
                <AiOutlineSmile size="24" />
              </div>
              <div className="label-container">
                {t("imgTileDropdownComponent.joyShare")}
              </div>
            </DropdownItem>
          )}

        {imageGroupStore.hiddenImageList.includes(img?.fileName) ? (
          <DropdownItem
            onClick={() =>
              imageGroupStore.statefulRemoveImageFromGroup(
                img?.fileName,
                "Hidden"
              )
            }
          >
            <AiOutlineEye size="24" />
            {t("imgTileDropdownComponent.unhide")}
          </DropdownItem>
        ) : (
          <DropdownItem
            onClick={() =>
              imageGroupStore.clientAddImageToGroup(img?.fileName, "Hidden")
            }
          >
            <AiOutlineEyeInvisible size="24" />
            {t("imgTileDropdownComponent.hide")}
          </DropdownItem>
        )}
      </DropdownMenu>
      <Modal
        isOpen={modalJoy}
        toggle={toggleJoy}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={toggleJoy}
          close={
            <Button color="icon-action" className="close" onClick={toggleJoy}>
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.shareJoy")}
        </ModalHeader>
        <ModalBody>
          <JoyForm imgKey={img?.key} onSuccess={toggleJoy} />
        </ModalBody>
      </Modal>
    </Dropdown>
  );
};
export default inject(
  "imageGroupStore",
  "imageShareStore",
  "ecomStore",
  "sessionStore",
  "clientStore",
  "loginPromptStore",
  "authStore"
)(observer(ImageTileDropdown));
