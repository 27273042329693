import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss'
import { observer,inject } from 'mobx-react';
import { 
    // Button,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
     } from 'reactstrap';
import ImageGroupStore from '../../stores/imageGroupStore';
import SessionStore from '../../stores/sessionStore';
import { MdClose } from 'react-icons/md';

const LoginPrompt: React.FC<any> = ({loginPromptStore, navigationStore, authStore}) => {
  const {t} = useTranslation();

  useEffect(()=>{
    if( loginPromptStore.showLoginPrompt ) {
      document.documentElement.classList.add('modal-open');
    }else{
      document.documentElement.classList.remove('modal-open');
    }
  });

  const toggle = () => loginPromptStore.togglePrompt();

  function statefulRedirect(url:string){
    toggle();
    authStore.redirectUrl = navigationStore.history.location.pathname;
    SessionStore.bustCache();
    ImageGroupStore.onLogout();
    navigationStore.push(url);
  }

  function renderBtn(url:string, messageString:string){
    return <a href="#" onClick = {(e) => {statefulRedirect(url); e.preventDefault()} }>{messageString}</a>
  }

  function renderMessage(message:string){
    const messageArr = message.split('--')
    return (
        <>
        {messageArr[0]}
        {renderBtn('/home/login',messageArr[1])}
        {messageArr[2]}
        {renderBtn('/home/signup',messageArr[3])}
        {messageArr[4]}
        </>
    )
  }

  return (
      <Modal 
      isOpen={loginPromptStore.showLoginPrompt} 
      toggle={toggle} 
      wrapClassName = "modal-box" 
      modalTransition={{ timeout: 500 }} 
      backdropTransition={{ timeout: 0 }}
      centered
      >
        <ModalHeader 
        toggle = {toggle}
        close={
                <Button color="icon-action" className="close" onClick={toggle}>
                    <MdClose size="18" />
                </Button>}
        
        > {t("loginPromptComponent.title")} </ModalHeader>
        <ModalBody>
          {renderMessage(t("loginPromptComponent.message"))}
        </ModalBody>
      </Modal>
  );
}
export default inject('loginPromptStore', 'navigationStore', 'authStore')(observer(LoginPrompt))