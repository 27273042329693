import React, { useRef } from "react";
import { observer, inject } from "mobx-react";
import ImageGallery from "react-image-gallery";
import { Button } from "reactstrap";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const ProductGallery: React.FC<any> = ({ ecomStore }) => {
  const { t } = useTranslation();
  const gallery: any = useRef(null);

  const fullNav = (onClick: any, isFullscreen: boolean) => {
    return (
      <Button
        color="icon"
        onClick={onClick}
        aria-label="Open fullscreen"
        className="slide-nav-fullscreen"
      >
        <AiOutlineFullscreenExit size="24" color="#fff" />
      </Button>
    );
  };

  return (
    <div id="ProductGallery">
      <div className="gallery-container">
        <ImageGallery
          items={ecomStore.selectedProductGalleryImages || []}
          showNav={false}
          renderFullscreenButton={fullNav}
          showPlayButton={false}
          showThumbnails={
            ecomStore.selectedProductGalleryImages.length > 1 ? true : false
          }
          onClick={() => gallery?.current?.toggleFullScreen() || null}
          ref={gallery}
        />
        {ecomStore.selectedProductGalleryImages.length > 1 && (
          <>
            <Button
              color="icon"
              aria-label="Previous image"
              className="thumbnail-nav thumbnail-nav-left"
              onClick={(e) => {
                e.preventDefault();
                gallery.current.slideToIndex(
                  gallery.current.getCurrentIndex() - 1
                );
              }}
            >
              <FiArrowLeft size={24} />
            </Button>
            <Button
              color="icon"
              aria-label="Next image"
              className="thumbnail-nav thumbnail-nav-right"
              onClick={(e) => {
                e.preventDefault();
                gallery.current.slideToIndex(
                  gallery.current.getCurrentIndex() + 1
                );
              }}
            >
              <FiArrowRight size={24} />
            </Button>
          </>
        )}
      </div>
      <span
        onClick={() => gallery?.current?.toggleFullScreen() || null}
        className="product-view-larger pt-2"
      >
        {" "}
        <FiSearch />
        {t("productGalleryComponent.viewLarger")}
      </span>
    </div>
  );
};
export default inject("ecomStore")(observer(ProductGallery));
