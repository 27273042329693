import React, { useState, useEffect } from "react";
import CheckoutRouter from "../../router/checkoutRouter";
import { observer, inject } from "mobx-react";
import { Progress } from "reactstrap";
import Sticky from "react-stickynode";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { isActiveRoute } from "../../components/util";

const CheckoutPage: React.FC<any> = ({ authStore, navigationStore }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(33);
  const [stickyProgress, setStickyProgress] = useState(0);
  const isBreakDownSm = useMediaQuery({
    query: "(min-width: 768px)",
  });

  useEffect(() => {
    if (!isBreakDownSm) {
      setStickyProgress(64);
    }
  }, [isBreakDownSm]);

  useEffect(() => {
    switch (navigationStore?.history?.location?.pathname) {
      case "/checkout/shippingaddress":
        setProgress(33);
        break;
      case "/checkout/orderreview":
        setProgress(66);
        break;
      case "/checkout/payment":
        setProgress(100);
        break;
    }
  }, [navigationStore.history.location.pathname]);

  return (
    <>
      <Helmet>
        <title>
          {t("CheckoutPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section
        className={`page-section${
          !isActiveRoute("/checkout/confirmation") ? ` pt-0` : ``
        }`}
      >
        <div className="container-800">
          {!isActiveRoute("/checkout/confirmation") && (
            <>
              <Sticky enabled={true} innerZ={8} top={stickyProgress}>
                <Progress value={progress} className="progress-sticky" />
              </Sticky>

              <div className="title-icon title-icon-checkout my-5">
                <h1 className="h2">{t("CheckoutPage.title")}</h1>
                <p>{t("CheckoutPage.subtitle")}</p>
              </div>
            </>
          )}

          <CheckoutRouter />
        </div>
      </section>
    </>
  );
};

export default inject("authStore", "navigationStore")(observer(CheckoutPage));
