import React from "react";

import { observer, inject } from "mobx-react";

const PrivacyStatement: React.FC<any> = () => {
  return (
    <>
      <div id="right_col" className="p-5">
        <ol type="I">
          <li>Collection of Personal Information</li>
          <ol type="a">
            <li>What do we collect</li>
            <li>What do we track</li>
          </ol>
          <li>How we use the information we collect</li>
          <li>When do we share your information</li>
          <li>Third-party use of information</li>
          <li>Security of your information</li>
          <li>Your options</li>
          <li>Your consent and changes to this policy</li>
          <li>Your California Privacy Rights</li>
          <li>Your Comments</li>
        </ol>
        
        <p>
          StudioPlus Software LLC (“StudioPlus”, “we” or “us”), recognizes and
          respects the importance of maintaining the privacy of our
          photographers, consumers and other users of our software, website, and
          other services we provide (collectively, our “Services”).{" "}
        </p>
        <p>
          In this Privacy Policy (“Privacy Policy”) we describe why we gather
          information from You, what information we collect, how we collect it,
          what we use the information for and how You can instruct us if You
          prefer to limit the use of that information. This Privacy Policy is
          part of the Terms of Use for any and all StudioPlus Services,
          including, but not limited to, the StudioPlus+ software suite,
          StudioPlusSoftware.com, and MyStudioPlus.com. Your use of any of the
          Services indicates You are accepting the practices described in this
          Privacy Policies and agree to receive our current privacy policies
          through our web site. If you do not wish to have your information used
          and disclosed as provided in this Privacy Policy, please refrain from
          providing your information and/or delete the information you may have
          submitted in the past. In doing so, please understand that your access
          to information, services and other benefits may be substantially
          limited. Further assistance is available via email to{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>
          .
        </p>
        <p>
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify You by revising the date at the top of the
          policy and, in some cases, we may provide You with additional notice
          (such as adding a statement to our homepage or sending You an email
          notification). We encourage You to review the Privacy Policy whenever
          You access the Services to stay informed about our information
          practices and the ways You can help protect Your privacy.
        </p>
        <p>
          <strong>I. Collection of Information</strong>
        </p>
        <p>
          Personal information is data relating to a person that can be used to
          help uniquely identify them, either directly or indirectly. You may be
          asked to provide personal information when in contact with us. If you
          do not provide us with certain requested personal information we may
          not be able to provide you with some or all of our Services.
        </p>
        <p>
          We reserve the right to gather information from and about You to allow
          us and certain third parties to communicate with You, to process Your
          orders, to improve our Services, to provide an enhanced and more
          personalized interactive shopping experience and to inform You, and
          Your invited guests, of special offers and discounts. Some of these
          offers and discounts may be offered by on behalf of third parties,
          some of which may be independent and not under the control of
          StudioPlus Software.
        </p>
        <p>
          This Privacy Policy does not cover and is not intended to restrict
          what we do with data that is aggregated and anonymized, so it is no
          longer associated with a personally identifiable user.
        </p>
        <p>
          <strong>A. What do we collect?</strong>
        </p>
        <p>We collect personal information when:</p>
        <ul>
          <li>
            you register to become a member of our Services or any other partner
            services{" "}
          </li>
          <li>
            you use our Services to store, share, and/or print Your pictures
            online{" "}
          </li>
          <li>you make online purchases </li>
          <li>you submit questions or comments to us </li>
          <li>
            you participate in online surveys, forums, chats, bulletin boards,
            discussion groups{" "}
          </li>
          <li>
            you participate in online promotions, premiums, sweepstakes or
            contests{" "}
          </li>
          <li>
            you request that we send information to You, Your friends, family
            and colleagues
          </li>
        </ul>
        <p></p>
        <p>
          This information is collected through our Web Site and emails.
          Information You enter might be collected even if You cancel or do not
          complete the intended use, registration, or order process. The types
          of information we may collect include Your name, email address, postal
          address, phone number, fax number, credit card information and other
          contact or identifying information You choose to provide, date, time,
          computer identity, crash data, device type and setting, operating
          system, language, region, log preferences, registration key, license
          level, license code, license code status, code registration date, last
          validation date, Ethernet address, IP address, MAC address, licensed
          software name and version, OS version, and any other information about
          user actions required to improve, support and maintain our software.
          If You choose to use Your real name (or include any identifying
          information as part of Your "nickname") that information may be
          publicly displayed through the Services. Therefore, please use caution
          in determining how You wish to be identified in using the Services.
        </p>
        <p>
          When You place an order or access Your Web Site account, <u>You</u>{" "}
          are automatically using our secure server. The secure server software
          (SSL) encrypts all information You enter before it's sent to us. This
          means that all of the customer information we gather is protected
          against unauthorized access.{" "}
        </p>
        <p>
          If Your web browser does not permit a secure connection, the web site
          will not accept Your credit card information. Your browser should
          display a "lock" or "key" icon at the bottom screen when a secure
          connection is made, and the URL bar on the top of Your browser
          typically shows the first characters as "https." All of the customer
          data we collect, once submitted by You, is reasonably protected
          against unauthorized access.
        </p>
        <p>
          <strong>Note</strong>:{" "}
          <em>
            If You opt-out from receiving further promotional emails from us, we
            will not share any of Your information with any third parties,
            including our Affiliates.
          </em>
        </p>
        <p>
          <strong>B. When do we track?</strong>
        </p>
        <p>
          When You access or use our Services, we may also automatically collect
          information about You, including:
        </p>
        <ul>
          <li>
            Log Information: We log information about Your use of the Services,
            including the type of browser You use, access times, pages viewed,
            Your IP address and the page You visited before navigating to our
            Services.
          </li>
          <li>
            {" "}
            Device Information: We collect information about the computer or
            mobile device You use to access our Services, including the hardware
            model, operating system and version, screen resolution, color and
            depth, unique device identifiers and mobile network information.
          </li>
          <li>
            {" "}
            Location Information: We may collect information about the location
            of Your device each time You access or use one of our mobile
            applications or otherwise consent to the collection of this
            information.
          </li>
          <li>
            Information Collected by Cookies and Other Tracking Technologies: We
            use various technologies to collect information, and this may
            include sending cookies to Your computer or mobile device. We may
            also collect information using web beacons (sometimes called
            “tracking pixels”). Web beacons are electronic images that may be
            used in our Services and help deliver cookies, count visits,
            understand usage and campaign effectiveness and determine whether an
            email has been opened and acted upon.
          </li>
        </ul>{" "}
        <p></p>
        <p>
          Please note that a number of companies offer utilities designed to
          help You visit web sites anonymously. Although this may interfere with
          and limit our ability to provide You with the Services, we want You to
          be aware that these tools exist. We do not warrant the effectiveness
          of these utilities in coordination with the provision of our Services,
          and we do not represent that we will be able to process Your requests
          or provide the Services to You should You use such a utility.
        </p>
        <p>
          From time to time, if authorized by You, we may also obtain
          information from other sources and combine that with information we
          collect through our Services. For example, if You create or log into
          Your account through a social media site, we will have access to
          certain information from that site, such as Your name, account
          information and friends lists, in accordance with the authorization
          procedures determined by such social media site. We may receive
          updated information about You, such as an updated billing address,
          from the financial institution issuing Your credit card in connection
          with our billing for the Services.
        </p>
        <p>
          <em>What are "cookies" and how do we use them?</em>
        </p>
        <p>
          A cookie is a piece of data stored on Your hard drive, which contains
          information about You, such as the web pages You visit. These cookies
          allow the web site to remember important information that will make
          Your use of the Service more enjoyable. StudioPlus cookies do not
          contain any personal information and are used for these reasons: (1)
          cookies allow us to keep track of the pictures You upload and
          download; (2) cookies allow us to remember You when You login to the
          places on our site which require Membership; (3) to remember Your
          country and language preferences; (4) to help us understand the size
          of our audience, traffic, and bandwidth patterns;(5) to manage and
          present site information; (6) to deliver information specific to Your
          interests; and (7) to manage site information and pictures displayed
          on Your computer.
        </p>
        <p>
          If you wish to restrict, remove or refuse to accept cookies which are
          set by any website (including this website), you should do this
          through the browser settings for each browser you use to access the
          Internet. The Help function within your browser should tell you how to
          do so. For further information about how to restrict, remove, and
          refuse to accept cookies, visit{" "}
          <a
            href="http://www.aboutcookies.org"
            title="www.aboutcookies.org"
            target="www.aboutcookies.org"
          >
            www.aboutcookies.org
          </a>{" "}
          or{" "}
          <a
            href="http://www.allaboutcookies.org"
            title="www.allaboutcookies.org"
            target="www.allaboutcookies.org"
          >
            www.allaboutcookies.org
          </a>
          . You can opt out of Facebook collection{" "}
          <a href="http://www.aboutads.info/choices" title="Facebook optout">
            here
          </a>
          . You can opt out of Google analytics 
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            title="Google Analytics"
          >
            here
          </a>
          , and adjust other Google privacy settings 
          <a
            href="https://www.google.com/settings/u/0/ads/authenticated"
            title="Google Privacy"
          >
            here
          </a>
          . Twitter also uses conversion tracking and tailored audience products
          for advertisements. You can modify your Twitter privacy settings 
          <a
            href="https://support.twitter.com/articles/20170405"
            title="Twitter Privacy"
          >
            here
          </a>
          . You can opt out of LiveRamp{" "}
          <a href="https://liveramp.com/opt_out/" title="Live Ramp Optout">
            here
          </a>
          . You can opt out of Amobee{" "}
          <a
            href="http://www.amobee.com/trust/consumer-opt-out"
            title="Amobee Optout"
          >
            here
          </a>
          . Our website may also provide hyperlinks to other websites owned and
          controlled by others. StudioPlus is not responsible for the privacy
          practices of any website that it does not own or otherwise control,
          and you should read the privacy policies of websites owned and
          controlled by others before deciding whether to proceed.
        </p>
        <p>
          <strong>II. How we use the information we collect</strong>
        </p>
        <p>
          We may use information about You for various purposes, including to:
        </p>
        <ul>
          <li> Communicate with You</li>
          <li>
            {" "}
            Forward Your orders to Your photographer, or to fulfill them through
            our partners, and Provide and deliver the products You order,
            process Your subscription to the Services and other transactions,
            and send You related information, including confirmations and
            invoices;
          </li>
          <li> Register You in programs</li>
          <li> Create and maintain accounts</li>
          <li>
            Send friends, families and colleagues information on Your behalf{" "}
          </li>
          <li>
            {" "}
            Send information You request, including technical notices, updates,
            security alerts and support and administrative messages;
          </li>
          <li> Provide, maintain and improve our Services;</li>
          <li>
            Facilitate Your use of, and our administration and operation of the
            Services;
          </li>
          <li>
            {" "}
            Monitor and analyze trends, usage and activities in connection with
            our Services;
          </li>
          <li>
            {" "}
            Personalize and improve the Services and provide advertisements,
            content or features that match user profiles or interests;
          </li>
          <li> Process and deliver contest entries and rewards;</li>
          <li>
            Link or combine with information we get from others to help
            understand Your needs and provide You with better service; and
          </li>
          <li>
            Carry out any other purpose for which the information was collected.
          </li>
        </ul>
        <p></p>
        <p>
          The information we collect is governed by U.S. law. By accessing or
          using the Services or otherwise providing information to us,{" "}
          <u>You</u> consent to the processing and transfer of information in
          and to the U.S. and any other applicable country. By using the
          Services or providing us with Your information, <u>You</u> hereby
          consent to this transfer. In addition, if You provide data to us about
          other individuals (such as Your message recipients), You agree that
          You have obtained consent from each such individual to the submission
          and processing of such information under the terms of this Privacy
          Policy, including without limitation the transfer of such data to the
          United States. Although we will collect and use Your information only
          as stated in this Privacy Policy, laws generally applicable to the
          protection of personal data in the United States may not be as
          stringent as those in some users’ home jurisdictions.
        </p>
        <p>
          Your personal information will be retained for as long as it is
          necessary to carry out the purposes set out in this Privacy Policy
          (unless longer retention is required by applicable law). However, we
          will not retain any of your personal information beyond this period
          and the retention of your personal information will be subject to
          periodic review. We may keep an anonymized form of your personal
          information, which will no longer refer to you, for statistical
          purposes without time limits, to the extent that we have a legitimate
          and lawful interest in doing so.
        </p>
        <p>
          <strong>III. When do we share your information?</strong>
        </p>
        <p>
          We may share information about You as follows or as otherwise
          described in this Privacy Policy:
        </p>
        <ul>
          {" "}
          <li>
            With vendors, consultants and other service providers who need
            access to such information to carry out work on our behalf;
          </li>
          <li>
            If You purchase prints or other products from one of our subscribers
            so that such subscriber may provide You with assistance or answer
            questions You may have in connection with Your purchases;
          </li>
          <li>
            In response to a request for information if we believe disclosure is
            in accordance with any applicable law, regulation or legal process,
            or as otherwise required by any applicable law, rule or regulation;
          </li>
          <li>
            If we believe Your actions are inconsistent with the spirit or
            language of our user agreements or policies, or to protect the
            rights, property and safety any individual;
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing or acquisition of all or a portion of our
            business to another company; and
          </li>
          <li>
            With Your consent or at Your direction, including if we notify You
            through our Services that the information You provide will be shared
            in a particular manner and You provide such information
          </li>
        </ul>
        . We may also share aggregated or anonymized information that does not
        directly identify You.<p></p>
        <p>
          Note that we may disclose any information You provide to us (i) when
          we believe disclosure to be appropriate to comply with any law,
          regulation, or governmental or law enforcement request, or to comply
          with judicial process; (ii) if such disclosure is necessary or
          appropriate to operate the Services or to process any orders or
          registrations or to conduct any promotions, contests, or sweepstakes
          You participate in, or in order to provide partner-sponsored feature
          enhancements; (iii) to protect the rights or property of StudioPlus,
          its affiliates, users, customers, recipients, Members, Sponsors,
          Providers, Licensors, or Merchants (as such terms are defined in the
          applicable Terms of Use); (iv) when necessary to investigate, prevent,
          or take action regarding illegal activities, suspected fraud,
          situations involving potential threats to the physical safety of any
          person, violations of our Terms and Conditions or Use, or as otherwise
          required by law; (v) to our legal advisors who may need to manage or
          litigate a claim; or, (vi) for any other purpose when we have your
          permission.{" "}
        </p>
        <p>
          Please further note that any personal information You reveal or post
          on one our public forums (as defined in the Terms of Use), such as a
          message board, chat room, discussion group, folder, survey, contest,
          sweepstakes, user review and rate forum, and all User Submissions, are
          not protected by this Privacy Policy and may be collected, shared and
          used by us or with third parties, including to contact You.
          Information You give out or post on our public forums You disclose at
          Your own risk.{" "}
        </p>
        <p>
          Portions of the Services (such as our Member registration and
          guest/event login screen) require registration and log-in processes in
          which You will need a user ID and password (collectively, the
          “Password”). Any Password provided to You will be the exclusive
          property and confidential information of StudioPlus and may be used by
          You solely for Your individual use of this Web Site. You are
          responsible for maintaining the confidentiality of any Password
          provided to You. Any activities that occur using Your Password are
          Your responsibility, whether or not authorized by You. You agree to
          immediately notify us of any unauthorized use of Your Password or
          accounts.{" "}
        </p>
        <p>
          We do not knowingly collect personal information from children under
          age thirteen (13) (or the otherwise applicable age of digital consent
          in any particular jurisdiction). Children under the applicable age of
          digital consent are not permitted to use our websites and services,
          and must request a team adult, parent or guardian provide any personal
          data in connection with the site and/or services. We will delete any
          information later determined to have been collected from an underage
          user.
        </p>
        <p>
          <strong>IV. Third-party use of information</strong>
        </p>
        <p>
          From time to time, we may allow others to serve advertisements on our
          behalf across the Internet and to provide analytics services. These
          entities may use cookies, web beacons and other technologies to
          collect information about Your use of the Services and other websites,
          including Your IP address, web browser, pages viewed, time spent on
          pages, links clicked and conversion information. This information may
          be used by StudioPlus and others to, among other things, analyze and
          track data, determine the popularity of certain content, deliver
          advertising and content targeted to Your interests on our Services and
          other websites and better understand Your online activity.
        </p>
        <p>
          <strong>V. Security of your information</strong>
        </p>
        <p>
          StudioPlus takes reasonable measures to help protect information about
          You from loss, theft, misuse and unauthorized access, disclosure,
          alteration and destruction. However, no transmission of data over the
          Internet or any other public network can be guaranteed to be 100%
          secure. Although we make reasonable efforts to safeguard Your personal
          information once we receive it, we cannot warrant the security of
          information You transmit to us. StudioPlus is committed to seeking to
          safeguard all personal information that you provide to us; seeking to
          ensure that it remains confidential and secure; and taking all
          reasonable steps to ensure that personal privacy is respected. All our
          data is stored in written or electronic form on our servers and
          computers and in various physical locations. We maintain physical,
          electronic and procedural safeguards to protect your personal
          information from misuse, unauthorized access or disclosure, and loss
          or corruption by computer viruses and other sources of harm. We limit
          access to personal information to those staff members, joint venture
          partners, subsidiary companies and third parties who need to know that
          information for the purposes set out in this Privacy Policy.
        </p>
        <p>
          <strong>VI. Your Options</strong>
        </p>
        <p>
          <em>From the Company and its Affiliates </em>
        </p>
        <p>
          If You prefer not to have us provide personal information about You to
          third parties (other than our Affiliates, subcontractors, or agents
          acting on our behalf in compliance with this Privacy Policy), please
          let us know by <strong>Emailing us at</strong>:{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>
        </p>
        <p>
          <strong>Note:</strong> If You opt-out from receiving further
          promotional emails from us, we will not share Your email address with
          any third parties, including our Affiliates and partners.
        </p>
        <p>
          If You prefer not to receive further product information and
          promotional mailings/emails from us based on information You provided
          to us, please indicate this in Your instructions to us and please
          include Your mailing address. Any email from us for which the primary
          purpose is the advertising or promotion of a product or service will
          contain contact information/link and a reply email address to which
          You may reply to opt-out from receiving similar future emails. Please
          note that Your instructions do not govern the use of personal
          information You may have provided to third party merchants, including
          Your Photographer, whose products and services may be displayed or
          accessible on or through the Services. The use of information You have
          provided to these <u>third party</u> merchants is governed by their
          privacy <u>polices </u>and terms. Also, <u>Your</u> instructions do
          not apply to “transactional or relationship” email messages which
          include, but are not limited to, registration, order status,
          confirmation or reply emails. (These email messages may also contain
          promotional items and offers however, that is not their primary
          purpose.)
        </p>
        <p>
          Your instructions will only apply to the StudioPlus Services, not to
          any third party or their web sites. Please refer to the policies and
          opt-out provisions of those other web sites.{" "}
        </p>
        <p>
          Your instructions to limit the use of Your information will be
          processed as soon as reasonably practicable and in accordance with
          applicable law.
          <em>EU/EEA Rights</em>
          If you are in the European Union and/or European Economic Area, to
          process your data lawfully we need to rely on one or more valid legal
          grounds. The grounds we may rely upon include: (1) your consent to
          particular processing activities. For example, where you have
          consented to us using your information for marketing purposes; (2) our
          legitimate interests as a business (except where your interests or
          fundamental rights override these). For example, it is within our
          legitimate interests to use your data to send you our electronic
          newsletter and product updates if you represent a corporate entity
          (unless you have unsubscribed); (3) our compliance with a legal
          obligation to which we are subject. For example, we have a duty to
          investigate and respond to complaints made against us and may need to
          process your personal information as part of such investigation; or
          (4) if you are a customer, or are representing a customer, because
          processing your personal data is necessary for the performance of a
          contract. Please contact us at{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>{" "}
          at any time to exercise any of your data protection rights. Data
          protection law applicable to individuals in the European Union and/or
          European Economic Area provides individuals with certain rights,
          including the right to access, rectify, withdraw consent, erase,
          restrict, transport, and object to the processing of their personal
          information. Individuals also have the right to lodge a complaint with
          the relevant information protection authority if they believe that
          their personal information is not being processed in accordance with
          the law. Further information about your rights is set out below:
        </p>
        <blockquote>
          <p>
            {" "}
            <strong>Right to obtain a copy of your personal information</strong>
            . You may have the right to obtain a copy of the personal
            information we hold about you.
            <strong>Right to rectification</strong>. You may request that we
            rectify any inaccurate and/or complete any incomplete personal
            information. If we disagree and believe the information to be
            accurate and complete, we will advise you and include a notation on
            the record that you dispute the information’s accuracy. We will
            respond to your request to correct or supplement your personal
            information within a reasonable time period and, in any event,
            within any time period specified in relevant laws.
            <strong>Right to withdraw consent</strong>. You may, as permitted by
            law, withdraw your consent to the processing of your personal
            information at any time. Such withdrawal will not affect the
            lawfulness of processing based on your previous consent. Please note
            that if you withdraw your consent, you may not be able to benefit
            from certain service features for which the processing of your
            personal information is essential.
            <strong>Right to object to processing</strong>. You may, as
            permitted by law, request that we stop processing your personal
            information. You also have the right to ask us not to process your
            personal data for marketing purposes.
            <strong>Right to erasure</strong>. You may request that we erase
            your personal information and we will comply, unless there is a
            lawful reason for not doing so.
            <strong>
              Your right to lodge a complaint with the supervisory authority
            </strong>
            . We suggest that you contact us about any questions or if you have
            a complaint in relation to how we process your personal information.
            However, you do have the right to contact the relevant supervisory
            authority in the relevant country directly. In the UK, this is the
            Information Commissioner’s Office at https://ico.org.uk.
          </p>
        </blockquote>
        <p>
          Individuals in the European Union should be aware that StudioPlus may
          transfer your personal data to a third party in countries outside the
          EU for further processing in accordance with the purposes set out in
          this Privacy Policy. In particular, your personal data may be
          transferred throughout the StudioPlus group and to our outsourced
          service providers located abroad. In these circumstances, we will, as
          required by applicable law, ensure that your privacy rights are
          adequately protected by appropriate technical, organization,
          contractual or other lawful means. Please contact StudioPlus’s Data
          Protection Officer through Info@StudioPlusSoftware.com for further
          information about the safeguards which we have put in place to protect
          your personal data and privacy rights in these circumstances.
        </p>
        <p>
          <strong>VII. Your Consent and Changes to this Policy </strong>
          Subject to the above provisions, by using our Web Site, <u>
            You
          </u>{" "}
          consent to the collection and use of information by us and the terms
          of this Privacy Policy. We may change our Privacy Policy from time to
          time and we will post those changes so that You are always aware of
          what information we collect, how we use it, and under what
          circumstances we may disclose it. In addition, if You would like prior
          notice of changes to the Privacy Policy or Terms of Use, email us at{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>{" "}
          from the email address to which You would like notice sent. By using
          the Services after such changes and posting, <u>You</u> agree to be
          legally bound and to abide by the changes and other provisions
          contained in the Privacy Policy and Terms of Use of which this policy
          is a part.{" "}
        </p>
        <p>
          <strong>VIII. Your California Privacy Rights</strong>
          California law permits residents of California to request certain
          details about how their information is shared with third parties for
          direct marketing purposes. If You are a California resident and would
          like to make such a request, please contact us at{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>
          .
        </p>
        <p>
          <strong>IX. Your Comments </strong>
        </p>
        <p>
          We welcome feedback concerning this Privacy Policy. Please send Your
          comments and questions to{" "}
          <a href="mailto:Info@StudioPlusSoftware.com">
            Info@StudioPlusSoftware.com
          </a>
          .
        </p>
        <p>
          <em>Last revised: May 25, 2018</em>
        </p>
      </div>
    </>
  );
};

export default inject()(observer(PrivacyStatement));
