import { observable, action, computed } from 'mobx';
import { create, persist } from 'mobx-persist'
import axios, { AxiosResponse, AxiosError } from 'axios'; 

import { GuestInvitation } from '../interfaces/guest.int';
import ClientStore from './clientStore';
import SessionStore from './sessionStore';
import AuthStore from './authStore';
import LoginPromptStore from './loginPromptStore';

class ImageShareClass {
    @observable hydrated:boolean = false;
    @persist('object') @observable selectedImage:any = {};

    @computed get activeSessionKey(){
      return SessionStore.activeShareSession?.SessionKey;
    }

    @observable addToShare:any = {
        showModal:false,
        fileName:'',
    };

    @action toggleModal(){
        this.addToShare.showModal = !this.addToShare.showModal;
    }

    @action statefulSharing(img:object = {}) {
      if(!!AuthStore.token && !AuthStore.isGuestSession){
        this.selectedImage = img;
        this.toggleModal();
      }else{
        LoginPromptStore.setShowLoginPrompt(true);
      }
    }

    @action async generateUrl(obj:GuestInvitation, params = {}){
      const data:GuestInvitation = 
        {...obj, 
            CreatedByClientKey:ClientStore.clientKey,
            ShareAllSessions:true,
            SessionKey:this.activeSessionKey,
        }
      return axios({
        method:'PUT',
              url:`/ibyGuestInvitation`,
              data:data,
              params:params
        }).then(async(res:AxiosResponse) => {
          return res.data;
        }).catch((err: AxiosError) => {
          console.log(err.message)
        })
    }
}

const hydrate = create({});
const ImageShareStore = new ImageShareClass();
hydrate('imgShare', ImageShareStore).then((store) => {
    store.hydrated = true;
})
export default ImageShareStore;