import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { Spinner } from "reactstrap";

import ImageTileList from "../../components/imageTileList/imageTileList";
import ResponsiveHeader from "../../components/responsiveHeader/responsiveHeader";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Spinner,
  Row,
  Col,
  Container,
  Card,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import BackgroundImage from "./sessionHomeBackground.png";
import { FiArrowLeft, FiRefreshCcw } from "react-icons/fi";

const SessionHome: React.FC<any> = ({
  authStore,
  sessionStore,
  ecomStore,
  createOrderStore,
  clientStore,
  navigationStore,
}) => {
  const history = useHistory();
  const sessionText =
    new Date(sessionStore?.activeSession.SessionDate).getTime() > Date.now()
      ? `Waiting For your Session on
  ${sessionStore.activeSession.SessionDate}`
      : "Waiting for your Images";
  const isBreakUpSm = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });
  console.log(clientStore.clientInfo);
  const [navOpen, setNavOpen] = useState(false);
  function productsSelected() {
    if (isBreakDownSm) {
      navUpdate();
    }
    createOrderStore.setStepNumber(1);
    createOrderStore.toggleSlider();
  }

  function navUpdate() {
    setNavOpen(!navOpen);
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 600);
    return;
  }

  function navUpdateMobile() {
    setNavOpen(true);
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 600);
    return;
  }

  return (
    <div className="text-center">
      <div
        style={{
          background: `url(${BackgroundImage})`,
          backgroundColor: "darkcyan",
          backgroundSize: "50% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row
          className="text-white"
          style={{
            height: "10rem",
            alignItems: "center",
            backgroundColor: "rgba(0, 139, 139,0.85)",
          }}
        >
          <Col xs={12} style={{ opacity: 1 }}>
            <h1 className="text-white">{sessionText}</h1>
            <h3 className="text-white">In the meantime...</h3>
          </Col>
        </Row>
      </div>
      <Container>
        <Row className="mt-3 justify-center">
          <Col xs={12} sm={6} lg={4} className="my-3">
            <Card
              body
              className="my-2"
              style={{
                width: "18rem",
                margin: "0 auto",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "solid 3px darkcyan",
              }}
            >
              <CardTitle className="text-dark" tag="h3">
                Browse Our Catalog
              </CardTitle>
              <Button
                style={{
                  backgroundColor: "darkcyan",
                  borderRadius: "30px",
                  width: "80%",
                }}
                className="text-white"
                onClick={productsSelected}
              >
                PRODUCTS
              </Button>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-3">
            {" "}
            <Card
              body
              className="my-2"
              style={{
                width: "18rem",
                margin: "0 auto",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "solid 3px darkcyan",
              }}
            >
              <CardTitle className="text-dark" tag="h3">
                Update Account Info
              </CardTitle>
              <Button
                style={{
                  backgroundColor: "darkcyan",
                  borderRadius: "30px",
                  width: "80%",
                }}
                className="text-white"
                onClick={() => history.push("/account")}
              >
                MY ACCOUNT
              </Button>
            </Card>
          </Col>
          <Col xs={12} sm={6} lg={4} className="my-3">
            {" "}
            <Card
              body
              className="my-2"
              style={{
                width: "18rem",
                margin: "0 auto",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "solid 3px darkcyan",
              }}
            >
              <CardTitle className="text-dark" tag="h3">
                Make a Prepaid Order
              </CardTitle>
              <Button
                style={{
                  backgroundColor: "darkcyan",
                  borderRadius: "30px",
                  width: "80%",
                }}
                className="text-white"
                onClick={async () => {
                  if (ecomStore.cart.Key) {
                    await ecomStore.deleteCart();
                  }
                  await ecomStore.addToCart(undefined, true);
                  createOrderStore.toggleSlider();

                  createOrderStore.setStepNumber(1);
                }}
              >
                ORDER NOW
              </Button>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3" style={{ justifyContent: "center" }}>
          {clientStore?.clientInfo?.WebForms &&
            clientStore?.clientInfo?.WebForms.length > 0 && (
              <Col xs={12} sm={6} lg={4} className="my-3">
                {" "}
                <Card
                  body
                  className="my-2"
                  style={{
                    width: "18rem",
                    margin: "0 auto",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border: "solid 3px darkcyan",
                  }}
                >
                  <CardTitle className="text-dark" tag="h3">
                    Complete Forms
                  </CardTitle>
                  <Button
                    style={{
                      backgroundColor: "darkcyan",
                      borderRadius: "30px",
                      width: "80%",
                    }}
                    className="text-white"
                    onClick={() => history.push("/forms")}
                  >
                    FORMS
                  </Button>
                </Card>
              </Col>
            )}

          {clientStore?.Contracts && clientStore?.Contracts?.length > 0 && (
            <Col xs={12} sm={6} lg={4} className="my-3">
              {" "}
              <Card
                body
                className="my-2 p-3"
                style={{
                  width: "18rem",
                  margin: "0 auto",
                  height: "100%",
                  justifyContent: "center",

                  alignItems: "center",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  border: "solid 3px darkcyan",
                }}
              >
                <CardTitle className="text-dark" tag="h3">
                  <span style={{ display: "block" }}>Sign</span> Contracts
                </CardTitle>
                <Button
                  style={{
                    backgroundColor: "darkcyan",
                    borderRadius: "30px",
                    width: "80%",
                  }}
                  className="text-white"
                  onClick={() => history.push("/contracts")}
                >
                  CONTRACTS
                </Button>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default inject(
  "authStore",
  "sessionStore",
  "ecomStore",
  "createOrderStore",
  "clientStore",
  "navigationStore"
)(observer(SessionHome));
