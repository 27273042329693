import React from "react";

import { Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { Field } from "formik";
import { inject, observer } from "mobx-react";
import AddressFormSection from "../../../addressFormSection/addressFormSection";
import { useTranslation } from "react-i18next";

const ShippingSection: React.FC<any> = ({
  authStore,
  clientStore,
  errors,
  values,
  setFieldValue,
  touched,
  ecomStore,
  content,
  publicEvents,
  eventStore,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="align-items-center">
        <Col sm="6" className="mb-3 mb-sm-5">
          <h2>{`Shipping information`}</h2>
        </Col>
        <Col sm="6" className="mb-3 mb-sm-5 d-flex justify-content-end">
          <div style={{ width: 350, maxWidth: "100%" }}>
            <Select
              className="react-select-group-container-simple"
              classNamePrefix="react-select-group"
              placeholder="Select saved address"
              options={ecomStore.allShippingAddressesFormattedForDropdown}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
              onChange={(e: any) => {
                setFieldValue("use_selected_address", !!e);
                setFieldValue(
                  "first_name",
                  e?.value?.Name?.split(" ")?.[0] || ""
                );
                setFieldValue(
                  "last_name",
                  e?.value?.Name?.split(" ")?.[1] || ""
                );
                setFieldValue("street_1", e?.value?.Line1 || "");
                setFieldValue("street_2", e?.value?.Line2 || "");
                setFieldValue("city", e?.value?.City || "");
                setFieldValue("state", e?.value?.State || "");
                setFieldValue("zip", e?.value?.Postcode || "");
                setFieldValue("country", e?.value?.Country || "");
                setFieldValue(
                  "same_as_default",
                  !e && !!ecomStore.defaultShippingAddress
                );
                setFieldValue("save_address_as_default", false);
                setFieldValue("selected_address_key", e?.value?.Key);
              }}
              isClearable
              isSearchable={false}
            />
          </div>
        </Col>
      </Row>
      {values.use_selected_address && (
        <FormGroup className="ml-4">
          <p className="mb-3">
            {t("shippingSectionComponent.usingSelectedAddress")}
          </p>
          <address>
            <div>{`${values.first_name} ${values.last_name}`}</div>
            <div>{`${values.street_1}`}</div>
            <div>{`${values.city}, ${values.state} ${values.zip}`}</div>
            <div>{`${values.country}`}</div>
          </address>
        </FormGroup>
      )}

      {!values.use_selected_address && (
        <>
          {!!ecomStore.defaultShippingAddress?.Line1 && (
            <FormGroup>
              <div className="custom-checkbox custom-control">
                <Field
                  type="checkbox"
                  id="same_as_default"
                  className={`custom-control-input ${
                    errors.same_as_default && touched.same_as_default
                      ? "is-invalid"
                      : ""
                  }`}
                  name="same_as_default"
                />
                <Label
                  htmlFor="same_as_default"
                  className={`${
                    errors.same_as_default && touched.same_as_default
                      ? "error "
                      : ""
                  }custom-control-label`}
                >
                  <p className="mb-3">
                    {t("shippingSectionComponent.useDefaultAddress")}
                  </p>
                  {values.same_as_default && (
                    <address>
                      <div>{ecomStore.defaultShippingAddress?.Name}</div>
                      <div>{`${ecomStore.defaultShippingAddress?.Line1}`}</div>
                      <div>{`${ecomStore.defaultShippingAddress?.Line2}`}</div>
                      <div>{`${ecomStore.defaultShippingAddress?.City}${
                        ecomStore.defaultShippingAddress?.City ? "," : ""
                      } ${ecomStore.defaultShippingAddress?.State} ${
                        ecomStore.defaultShippingAddress?.Postcode
                      }`}</div>
                      <div>{`${
                        ecomStore.defaultShippingAddress?.Country ||
                        authStore.countryFormatted
                      }`}</div>
                    </address>
                  )}
                </Label>
              </div>
            </FormGroup>
          )}
          {/* {!ecomStore.defaultShippingAddress?.Line1 && (
            <FormGroup>
              <div className="custom-checkbox custom-control">
                <Field
                  type="checkbox"
                  id="same_as_client"
                  className={`custom-control-input ${
                    errors.same_as_client && touched.same_as_client
                      ? "is-invalid"
                      : ""
                  }`}
                  name="same_as_client"
                />
                <Label
                  htmlFor="same_as_client"
                  className={`${
                    errors.same_as_client && touched.same_as_client
                      ? "error "
                      : ""
                  }custom-control-label`}
                >
                  <p className="mb-3">
                    {t("shippingSectionComponent.useClientAddress")}
                  </p>
                  {values.same_as_client && (
                    <address>
                      <div>{`${clientStore.clientInfo?.FirstName} ${clientStore.clientInfo?.LastName}`}</div>
                      <div>{`${clientStore.clientInfo?.AddressLine1}`}</div>
                      <div>{`${clientStore.clientInfo?.AddressLine2}`}</div>
                      <div>{`${clientStore.clientInfo?.AddressCity}${
                        clientStore.clientInfo?.AddressCity ? "," : ""
                      } ${clientStore.clientInfo?.AddressState}${
                        clientStore.clientInfo?.AddressPostcode
                      } `}</div>
                      <div>{`${clientStore.clientInfo?.AddressCountry}`}</div>
                    </address>
                  )}
                </Label>
              </div>
            </FormGroup>
          )} */}
        </>
      )}

      {(!ecomStore.defaultShippingAddress?.Line1 || !values.same_as_default) &&
        !values.use_selected_address && (
          <>
            <AddressFormSection
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </>
        )}
      {!values.same_as_default && (
        <FormGroup>
          <div className=" custom-checkbox custom-control">
            <Field
              type="checkbox"
              id="save_address_as_default"
              className="custom-control-input"
              name="save_address_as_default"
            />
            <Label
              htmlFor="save_address_as_default"
              className="custom-control-label"
            >
              {t("shippingSectionComponent.saveAsDefault")}
            </Label>
          </div>
        </FormGroup>
      )}
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "clientStore",
  "ecomStore"
)(observer(ShippingSection));
