import React, { useEffect, useState } from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import axios from "axios";
import Helmet from "react-helmet";
import ReactAddToCalendar from "react-add-to-calendar";
import { useQuery } from "../../components/util";

interface Props {}

interface CalendarResponse {
  DateTimeDescription?: string;
  IcsText?: string;
  StartDateUtc?: Date;
  EndDateUtc?: Date;
  Summary?: string;
  PageTitle?: string;
}

type DropdownType = "apple" | "google" | "outlook" | "outlookcom" | "yahoo";

const AddToCalendar: React.FC<Props> = () => {
  const query = useQuery();
  const sesguid = query.get("sesguid");
  const apptguid = query.get("apptguid");
  const [calendarResponse, setCalendarResponse] = useState<CalendarResponse>(
    {}
  );

  const event = calendarResponse.IcsText?.split("\r\n").reduce(
    (obj: any, str, index) => {
      let strParts = str.split(":");
      if (strParts[0] && strParts[1]) {
        //<-- Make sure the key & value are not undefined
        obj[strParts[0].replace(/\s+/g, "").toLowerCase()] = strParts[1].trim(); //<-- Get rid of extra spaces at beginning of value strings
      }
      return obj;
    },
    {}
  );
  if (event) {
    event["title"] = calendarResponse.Summary;
    event["startTime"] = calendarResponse.StartDateUtc;
    event["endTime"] = calendarResponse.EndDateUtc;
  }

  useEffect(() => {
    axios
      .get("/AddToCalendar", {
        params: {
          sesguid,
          apptguid,
        },
      })
      .then((res: any) => {
        setCalendarResponse(res.data);
      });
  }, []);

  const DropdownItem = (dropdownType: DropdownType) => {
    const iconUrl = `https://www.addevent.com/gfx/icon-${dropdownType}-t5.svg`;
    let dropdownTitle;
    if (dropdownType === "outlookcom") {
      dropdownTitle = "Outlook.com";
    } else {
      dropdownTitle = dropdownType[0].toUpperCase() + dropdownType.slice(1);
    }
    return (
      <div
        className="calendar-dropdown-item"
        style={{ background: `url(${iconUrl}) 18px 40% no-repeat` }}
      >
        {dropdownTitle}
      </div>
    );
  };
  const CalendarBody = () => {
    if (!sesguid && !apptguid) {
      return (
        <div>Please input the session id or the appointment id in the url</div>
      );
    } else if (Object.keys(calendarResponse).length === 0) {
      return (
        <div>
          <Spinner style={{ position: "absolute", left: "50%", top: "50%" }} />
        </div>
      );
    }
    return (
      <Card className="add-to-calendar">
        <CardBody>
          <CardTitle tag="h3">{calendarResponse.PageTitle}</CardTitle>
          <CardTitle className="mb-1">Date and Time</CardTitle>
          <CardText>{calendarResponse.DateTimeDescription}</CardText>
          <Container style={{ cursor: "pointer" }}>
            <Row>
              <Col>
                <ReactAddToCalendar
                  dropdownClass="add-to-calendar__dropdown"
                  event={event}
                  buttonLabel="Add to Calendar"
                  buttonClassClosed="btn-toolbar"
                  listItems={[
                    {
                      apple: DropdownItem("apple"),
                    },
                    {
                      google: DropdownItem("google"),
                    },
                    { outlook: DropdownItem("outlook") },
                    {
                      outlookcom: DropdownItem("outlookcom"),
                    },
                    {
                      yahoo: DropdownItem("yahoo"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{calendarResponse.PageTitle}</title>
      </Helmet>
      <div className="p-3">
        <CalendarBody />
      </div>
    </>
  );
};

export default AddToCalendar;
