import { observable, action, toJS, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import { setAxiosClientKey } from "../components/axios-config";
import SessionStore from "./sessionStore";
import { FamilyMember } from "../interfaces/familyMember.int";

class ClientStoreClass {
  @persist("object") @observable clientInfo: any = {};
  @persist @observable clientKey: string = "";
  @persist("list") @observable familyMembers: any = [];
  @persist("list") @observable familyMemberRelationships: any = [];

  @persist @observable statusDescription: string = "";
  @persist @observable statusDueDate: string = "";
  @persist @observable defaultSessionKey: string = "";

  @persist("object") @observable clientLastSubscriptionData: any = {};
  @persist @observable clientSubscriptionWillRenew: boolean = false;
  @observable nextPaymentDueDate: string = "";

  @observable contractDetail: any = {};
  @observable hydrated: boolean = false;
  @observable error: string = "";

  @action async onLogin(data: any) {
    this.clientInfo = data;
    return true;
  }

  @computed get allFamilyMembers() {
    return this.familyMembers?.map((famMem: FamilyMember) => famMem) || [];
  }

  @computed get contracts() {
    return this.clientInfo.Contracts?.map((c: any) => c);
  }

  @action getClientInfo(defaultSessionKey?: any) {
    return axios({
      method: "GET",
      url: `/ibyClient`,
      params: {
        defaultSessionKey: defaultSessionKey,
      },
    })
      .then(async (res: AxiosResponse) => {
        this.clientInfo = { ...res.data };
        this.clientKey = res.data.Key;
        this.statusDescription = res.data.StatusDescription;
        this.statusDueDate = res.data.StatusDueDate;
        SessionStore.allSessions = res.data.Sessions;
        SessionStore.allSessions = SessionStore.allSessions
          ?.sort((a: any, b: any) => a.SessionKey.localeCompare(b.SessionKey))
          .reverse();
        setAxiosClientKey(res.data.Key);
        return res.data;
      })
      .catch((err: AxiosError) => {
        this.error = err.message;
      });
  }

  // Client Session Access

  @action putClientSessionAccess(token?: any) {
    const body = Object.assign({ ShareToken: toJS(token) });
    return axios({
      method: "PUT",
      url: `/ibyClientSessionAccess`,
      data: body,
    })
      .then(async (res: AxiosResponse) => {
        console.log(res);
        this.defaultSessionKey = res.data.DefaultSessionKey;
        return res.data;
      })
      .catch((err: AxiosError) => {
        this.error = err.message;
      });
  }

  @action getClientSessionInfo() {
    return axios({
      method: "GET",
      url: `/IBYClientSessionInfo`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
      },
    })
      .then(async (res: AxiosResponse) => {
        console.log(res);
        // this.clientInfo = { ...res.data };
        // this.clientKey = res.data.Key;
        SessionStore.allSessions = res.data;
        SessionStore.allSessions = SessionStore.allSessions
          ?.sort((a: any, b: any) => a.SessionKey.localeCompare(b.SessionKey))
          .reverse();
        console.log("all Sessions", SessionStore.allSessions);
        return res.data;
      })
      .catch((err: AxiosError) => {
        this.error = err.message;
      });
  }

  @action getWebForms() {
    return axios({
      method: "GET",
      url: `/ibyWebForms`,
      params: {
        clientKey: this.clientKey,
      },
    })
      .then(async (res: AxiosResponse) => {
        this.clientInfo = { ...this.clientInfo, WebForms: res.data };
        return res.data;
      })
      .catch((err: AxiosError) => {
        this.error = err.message;
      });
  }

  @action getUpcomingSessions() {
    return axios({
      method: "GET",
      url: "/ibyUpcomingSession",
      params: {
        clientKey: this.clientKey,
      },
    })
      .then(async (res: AxiosResponse) => {
        this.clientInfo = { ...this.clientInfo, UpcomingSessions: res.data };
        return res.data;
      })
      .catch((err: AxiosError) => {
        this.error = err.message;
      });
  }

  @action updateClient(obj: any, params = {}) {
    const body = Object.assign({ Key: toJS(this.clientInfo.Key) }, obj);
    return axios({
      method: "PUT",
      url: `/ibyClient`,
      data: body,
      params: params,
    })
      .then(async (res: AxiosResponse) => {
        this.refreshClientObject(res.data);
        setAxiosClientKey(res.data.Key);
        return res.data;
      })
      .catch((err: AxiosError) => {
        console.log(err.message);
        if (err.message.includes("400")) {
          this.error = "Incorrect Password";
        } else {
          this.error = err.message;
        }
      });
  }

  @action refreshClientObject(client: any) {
    this.clientKey = client.Key;
    if (!this.clientInfo) {
      this.clientInfo = client;
    } else {
      // Set individual client properties so as not to overwrite the client collections.
      this.clientInfo.AddressCity = client.AddressCity;
      this.clientInfo.AddressCountry = client.AddressCountry;
      this.clientInfo.AddressLine1 = client.AddressLine1;
      this.clientInfo.AddressLine2 = client.AddressLine2;
      this.clientInfo.AddressPostcode = client.AddressPostcode;
      this.clientInfo.AddressState = client.AddressState;
      this.clientInfo.AllowCallMarketing = client.AllowCallMarketing;
      this.clientInfo.AllowEmailMarketing = client.AllowEmailMarketing;
      this.clientInfo.AllowTextMarketing = client.AllowTextMarketing;
      this.clientInfo.ClientNumber = client.ClientNumber;
      this.clientInfo.EmailAddress = client.EmailAddress;
      this.clientInfo.FirstName = client.FirstName;
      this.clientInfo.LastName = client.LastName;
      this.clientInfo.OnlinePassword = client.OnlinePassword;
      this.clientInfo.OnlineUserName = client.OnlineUserName;
      this.clientInfo.PhoneNumber0 = client.PhoneNumber0;
      this.clientInfo.SMSTextable0 = client.SMSTextable0;
      this.clientInfo.PhoneNumber1 = client.PhoneNumber1;
      this.clientInfo.SMSTextable1 = client.SMSTextable1;
      this.clientInfo.CustomTextField01 = client.CustomTextField01;

      if (client.WebFormCount > 0) {
        this.clientInfo.WebFormCount = client.WebFormCount;
      }
      if (client.DefaultRecordType) {
        this.clientInfo.DefaultRecordType = client.DefaultRecordType;
        this.clientInfo.DefaultRecordKey = client.DefaultRecordKey;
      }
      this.clientInfo.PendingOrderKey = client.PendingOrderKey;
      this.clientInfo.PaymentMethodUrl = client.PaymentMethodUrl;
      this.clientInfo.ScheduleNowUrl = client.ScheduleNowUrl;
      this.clientInfo.FirstIBYLoginDate = client.FirstIBYLoginDate;
    }
  }

  @action getFamilyMembers() {
    return axios({
      method: "GET",
      url: `/ibyClientFamilyMembers`,
    }).then((res: AxiosResponse) => {
      this.familyMembers = res.data.FamilyMembers;
      this.familyMemberRelationships = res.data.Relationships;
    });
  }

  @action putFamilyMember(famMem: FamilyMember) {
    return axios({
      method: "PUT",
      url: `/ibyClientFamilyMember`,
      data: { ...famMem, ClientKey: ClientStore.clientInfo.Key },
    }).then((res: AxiosResponse) => {
      this.familyMembers = res.data.FamilyMembers;
    });
  }

  @action deleteFamilyMember(key: string) {
    return axios({
      method: "DELETE",
      url: `/ibyClientFamilyMember/${key}`,
    }).then((res: AxiosResponse) => {
      this.familyMembers = this.familyMembers.filter(
        (add: FamilyMember) => add.Key !== res.data
      );
    });
  }

  @action clearPendingOrderKey() {
    this.clientInfo.PendingOrderKey = "";
  }

  @action getContractDetail(key: string) {
    return axios({
      method: "GET",
      url: `/ibyContract/${key}`,
    })
      .then(async (res: AxiosResponse) => {
        this.contractDetail = res.data;
        return res.data;
      })
      .catch((err: AxiosError) => {
        console.log(err.message);
      });
  }

  @action acceptContract(pin: string) {
    console.log(this.contractDetail);
    const data = { Key: this.contractDetail.Key, Accepted: true };
    return axios({
      method: "PUT",
      url: `/ibyContract`,
      params: {
        pin: pin,
      },
      data: data,
    }).then(async (res: AxiosResponse) => {
      this.contractDetail = res.data;
      this.getClientInfo();
      return res.data;
    });
  }

  @action confirmUpcomingSession(key: string) {
    const data = {
      Key: key,
      Confirm: "true",
    };
    return axios({
      method: "PUT",
      url: "/IBYUpcomingSession",
      params: {
        clientKey: this.clientKey,
      },
      data,
    }).then(async (res: AxiosResponse) => {
      this.getUpcomingSessions();
      return res.data;
    });
  }

  @action cancelUpcomingSession(key: string) {
    const data = {
      Key: key,
      Cancel: "true",
    };
    return axios({
      method: "PUT",
      url: "/IBYUpcomingSession",
      params: {
        clientKey: this.clientKey,
      },
      data,
    }).then(async (res: AxiosResponse) => {
      this.getUpcomingSessions();
      return res.data;
    });
  }

  @action downloadImage(reqObj: any) {
    const data = {
      SessionKey: SessionStore.activeSession?.Key,
      ClientKey: this.clientKey,
    };
    const body = { ...data, ...reqObj };
    // console.log(body)
    return axios({
      method: "PUT",
      url: `/ibyFileDownload`,
      data: body,
    }).then(async (res: AxiosResponse) => {
      console.log(res.data);
      return res.data;
    });
  }

  @action getSubscriptionPlan() {
    return axios({
      method: "GET",
      url: `/ibySubscriptionPlan`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
      },
    }).then((res: AxiosResponse) => {
      // console.log("getSubscriptionPlan", res);
      // console.log(res.data[res.data.length - 1]);
      this.clientLastSubscriptionData = res.data[res.data.length - 1];
      this.nextPaymentDueDate =
        this.clientLastSubscriptionData?.NextPaymentDueDate;
    });
  }

  @action onLogout() {
    this.clientInfo = {};
    this.clientKey = "";
    this.familyMembers = [];
    this.familyMemberRelationships = [];
    this.clientInfo.CustomTextField01 = "";
    this.clientInfo.familyMembers = "";
    localStorage.clear();
  }
}
const hydrate = create({});
const ClientStore = new ClientStoreClass();

export default ClientStore;
hydrate("client", ClientStore).then((ClientStore) => {
  setAxiosClientKey(ClientStore.clientKey);
  ClientStore.hydrated = true;
});
