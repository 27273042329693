import React from "react";
import { Field } from "formik";
import { inject, observer } from "mobx-react";
import { Label, Col, Row } from "reactstrap";
import { ShippingMethod } from "../../../../../interfaces/shipping.int";

const DeliveryMethodSection: React.FC<any> = ({
  ecomStore,
  errors,
  values,
  setFieldValue,
  touched,
  content,
  publicEvents,
  eventStore,
  className,
}) => {
  return (
    <div className={className}>
      <h3 className="subtitle mb-3">{`Select a method`}</h3>
      <ul className="list-unstyled list-expand" role="presentation">
        {ecomStore.shippingMethods?.map((m: ShippingMethod, i: number) => (
          <li key={i}>
            <Row>
              <Col xs="9">
                <div className="custom-control custom-radio">
                  <Field
                    type="radio"
                    className={`custom-control-input ${
                      errors.shipping_method ? "is-invalid" : ""
                    }`}
                    name="shipping_method"
                    id={`shipping-method${i}`}
                    value={m.Key}
                    checked={values.shipping_method === m.Key && true}
                    onChange={() => setFieldValue("shipping_method", m.Key)}
                  />

                  <Label
                    className="custom-control-label font-weight-bold"
                    htmlFor={`shipping-method${i}`}
                  >
                    {m.Description}
                  </Label>
                </div>
              </Col>

              <Col xs="3" className="text-right">
                {m.Fee}
              </Col>
            </Row>
          </li>
        ))}
      </ul>

      <input
        type="hidden"
        className={`${errors.shipping_method ? "is-invalid" : ""}`}
      />
      <div className="invalid-feedback">{errors.shipping_method}</div>

      {/* <FormGroup className="mt-4">
        <div className="custom-checkbox custom-control">
          <Field
              type = "checkbox"
              id = "save_shipping_method"
              className = {`custom-control-input ${errors.save_shipping_method && touched.save_shipping_method ?'is-invalid':''}`}
              name="save_shipping_method"
          />
          <Label htmlFor = "save_shipping_method" className = {`${errors.save_shipping_method && touched.save_shipping_method ?'error ':''}custom-control-label`}>{`Save as my default delivery method`}</Label>
        </div>
      </FormGroup> */}
    </div>
  );
};
export default inject(
  "navigationStore",
  "ecomStore"
)(observer(DeliveryMethodSection));
