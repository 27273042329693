import React, { useState, useEffect, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { MdClose } from "react-icons/md";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import IframeResizer from "iframe-resizer-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PersonalInfo from "../../components/forms/personalInfo/personalInfo";

import FamilyMemberForm from "../../components/forms/familyMember/familyMember";
import FamilyMemberEditForm from "../../components/forms/familyMember/familyMemberEditForm";

import CommunicationForm from "../../components/forms/communication/communicationForm";
import UsernameForm from "../../components/forms/userName/usernameForm";
import PasswordForm from "../../components/forms/editPassword/editPasswordForm";
import { ShippingAddress } from "../../interfaces/address.int";
import EditAddressForm from "../../components/forms/editAddress/editAddressForm";
import { FaCheck } from "react-icons/fa";

const AccountPage: React.FC<any> = ({
  authStore,
  clientStore,
  ecomStore,
  navigationStore,
  guestStore,
}) => {
  const { t } = useTranslation();
  const [modalPersonal, setModalPersonal] = useState(false);
  const [modalFamilyMember, setModalFamilyMember] = useState(false);
  const [modalCommunication, setModalCommunication] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalReschedule, setModalReschedule] = useState(false);
  const [modalUsername, setModalUsername] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [modalConfirmCancel, setModalConfirmCancel] = useState(false);
  const [editAddressIndex, setEditAddressIndex] = useState<any>();
  const [editFamilyMemberIndex, setEditFamilyMemberIndex] = useState<any>();
  const [modalEditFamilyMember, setModalEditFamilyMember] = useState(false);
  const [deleteAddressIndex, setDeleteAddressIndex] = useState<any>();
  const [deleteFamilyMemberIndex, setDeleteFamilyMemberIndex] = useState<any>();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalFamilyMemberDelete, setModalFamilyMemberDelete] = useState(false);
  const [rescheduleUrl, setRescheduleUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionKeyToDelete, setSessionKeyToDelete] = useState("");

  useEffect(() => {
    if (
      !modalPersonal ||
      !modalFamilyMember ||
      !modalCommunication ||
      !modalPayment ||
      !modalSchedule ||
      !modalReschedule ||
      !modalUsername ||
      !modalPassword ||
      !modalAddress ||
      !modalDelete ||
      !modalFamilyMemberDelete ||
      !modalConfirmCancel
    ) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [
    modalPersonal,
    modalFamilyMember,
    modalCommunication,
    modalPayment,
    modalSchedule,
    modalReschedule,
    modalUsername,
    modalPassword,
    modalAddress,
    modalDelete,
    modalFamilyMemberDelete,
    modalConfirmCancel,
  ]);

  useEffect(() => {
    clientStore.getUpcomingSessions();
  }, [clientStore, modalReschedule, modalSchedule]);

  useEffect(() => {
    setLoading(false);
  }, [clientStore?.clientInfo?.UpcomingSessions]);

  const togglePersonal = (e: any) => {
    setModalPersonal(!modalPersonal);
    e.preventDefault();
  };

  const togglePersonalSuccess = (e: any) => {
    setModalPersonal(!modalPersonal);

    if (
      clientStore.statusDescription === "Free" &&
      authStore.userflowCreateAccount
    ) {
      navigationStore.push("/sessions");
      return;
    }

    if (
      clientStore.statusDescription === "Free" &&
      !authStore.userflowCreateAccount
    ) {
      navigationStore.push("/account");
      return;
    }

    if (
      clientStore.familyMembers?.length === 0 &&
      !(clientStore.statusDescription === "Free")
    ) {
      setModalFamilyMember(true);
    } else {
      setModalFamilyMember(false);
    }
    e.preventDefault();
  };

  const toggleFamilyMemberSuccess = (e: any) => {
    setModalFamilyMember(false);
    setTimeout(function () {
      setModalFamilyMember(true);
    }, 1000);
    e.preventDefault();
  };

  const toggleFamilyMemberEditSuccess = (e: any) => {
    setModalEditFamilyMember(false);
  };

  const closeFamilyMemberEditModal = (e: any) => {
    setModalEditFamilyMember(false);
    e.preventDefault();
  };

  const closeFamilyMemberModalFirst = (e: any) => {
    setModalFamilyMember(false);
    if (guestStore.allGuests?.length === 0) {
      navigationStore.push("/guests");
      return;
    } else {
      navigationStore.push("/account");
    }
    e.preventDefault();
  };

  const closeFamilyMemberModal = (e: any) => {
    setModalFamilyMember(false);
    if (authStore.userflowCreateAccount) {
      navigationStore.push("/guests");
      return;
    } else {
      navigationStore.push("/account");
    }

    e.preventDefault();
  };

  const toggleFamilyMember = (e: any) => {
    setModalFamilyMember(!modalFamilyMember);
    e.preventDefault();
  };
  const toggleFamilyMemberEdit = (e: any) => {
    setModalFamilyMember(!modalFamilyMember);
    e.preventDefault();
  };

  const toggleCommunication = (e: any) => {
    setModalCommunication(!modalCommunication);
    e.preventDefault();
  };

  const togglePayment = (e: any) => {
    setModalPayment(!modalPayment);
    e.preventDefault();
  };

  const toggleSchedule = (e: any) => {
    setModalSchedule(!modalSchedule);
    e.preventDefault();
  };

  const toggleReschedule = (e: any, rescheduleUrl?: string) => {
    if (rescheduleUrl) {
      setRescheduleUrl(rescheduleUrl);
    }
    setModalReschedule(!modalReschedule);
    e.preventDefault();
  };

  function toggleUsername(e: any) {
    setModalUsername(!modalUsername);
    e.preventDefault();
    e.stopPropagation();
  }
  function togglePassword(e: any) {
    setModalPassword(!modalPassword);
    e.preventDefault();
    e.stopPropagation();
  }

  function toggleAddress(e: any) {
    setModalAddress(!modalAddress);
    e.preventDefault();
    e.stopPropagation();
  }
  function toggleDelete(e: any) {
    setModalDelete(!modalDelete);
    e.preventDefault();
    e.stopPropagation();
  }
  function toggleFamilyMemberDelete(e: any) {
    setModalFamilyMemberDelete(!modalFamilyMemberDelete);
    e.preventDefault();
    e.stopPropagation();
  }
  function toggleConfirmCancel(e: any) {
    setModalConfirmCancel(!modalConfirmCancel);
    e.preventDefault();
    e.stopPropagation();
  }
  function selectAddress(index: number) {
    setEditAddressIndex(index);
    setModalAddress(true);
  }
  function selectFamilyMember(index: number) {
    setEditFamilyMemberIndex(index);
    setModalEditFamilyMember(true);
  }

  function showDeleteConfirm(index: number) {
    setDeleteAddressIndex(index);
    setModalDelete(true);
  }
  function showFamilyMemberDeleteConfirm(index: number) {
    setDeleteFamilyMemberIndex(index);
    setModalFamilyMemberDelete(true);
  }
  async function deleteAddress(key: string) {
    await ecomStore.deleteShippingAddress(key);
    setModalDelete(false);
  }
  async function deleteFamilyMember(key: string) {
    await clientStore.deleteFamilyMember(key);
    setModalFamilyMemberDelete(false);
  }
  function reformatDate(dateStr: string) {
    const dArr = dateStr.split("-");
    return dArr[1] + "/" + dArr[2] + "/" + dArr[0];
  }

  useEffect(() => {
    if (
      clientStore.clientInfo?.CustomTextField01?.length === 0 &&
      authStore.userflowCreateAccount
    ) {
      setModalPersonal(true);
    } else {
      setModalPersonal(false);
    }
  }, [authStore.userflowCreateAccount, clientStore.clientInfo]);

  return (
    <>
      <Helmet>
        <title>
          {t("AccountPage.title")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section className="page-section page-section-2column">
        <div className="page-section-main">
          <h1 className="mb-5">{t("AccountPage.title")}</h1>

          <Row>
            <Col md="6" className="mb-5">
              <h3 className="subtitle">{t("AccountPage.loginSubtitle")}</h3>
              <p className="mb-2">
                {t("AccountPage.loginText")}{" "}
                {clientStore?.clientInfo?.OnlineUserName}
              </p>
              <ul className="list-unstyled" role="presentation">
                <li>
                  <Button color="body-link-reverse" onClick={toggleUsername}>
                    {t("AccountPage.editUsername")}
                  </Button>
                </li>
                <li>
                  <Button color="body-link-reverse" onClick={togglePassword}>
                    {t("AccountPage.changePassword")}
                  </Button>
                </li>
              </ul>
            </Col>
            <Col md="6" className="mb-5">
              <h3 className="subtitle">
                {t("AccountPage.personalInfoSubtitle")}
              </h3>
              <address className="mb-2">
                {!!clientStore?.clientInfo?.FirstName && (
                  <span className="d-block">
                    {`${clientStore?.clientInfo?.FirstName} ${clientStore?.clientInfo?.LastName}`}
                  </span>
                )}

                <span className="d-block">
                  {clientStore?.clientInfo?.EmailAddress}
                </span>
                {authStore?.accountName.toLowerCase() == "annegeddes" && (
                  <span className="d-block account-holder-family-role">
                    <strong>
                      {" "}
                      {clientStore?.clientInfo?.CustomTextField01}
                    </strong>
                  </span>
                )}
              </address>
              <Button color="body-link-reverse" onClick={togglePersonal}>
                {t("AccountPage.edit")}
              </Button>
            </Col>
            <Col md="6" className="mb-5">
              <h3 className="subtitle">{t("AccountPage.paymentSubtitle")}</h3>
              <p className="mb-2">{t("AccountPage.paymentText")}</p>
              <Button color="body-link-reverse" onClick={togglePayment}>
                {t("AccountPage.edit")}
              </Button>
            </Col>

            <Col md="6" className="mb-5">
              <h3 className="subtitle">{t("AccountPage.accountstatus")}</h3>

              {clientStore.statusDescription === "Trial" && (
                <>
                  <p className="mb-2">
                    {t("AccountPage.trialAccount")}{" "}
                    {reformatDate(clientStore.statusDueDate.split("T")[0])}
                  </p>
                  <Button
                    color="body-link-reverse"
                    onClick={() => navigationStore.push("/subscription-trial")}
                  >
                    {t("AccountPage.clickhere")}
                  </Button>
                  {t("AccountPage.upgradeFromTrial")}
                  <strong>
                    {" "}
                    {authStore.monthlySubscriptionAmountFormatted}{" "}
                  </strong>{" "}
                  per month
                </>
              )}

              {clientStore.statusDescription === "Free" && (
                <>
                  <p className="mb-2">{t("AccountPage.freeAccount")}</p>
                  <Button
                    color="body-link-reverse"
                    onClick={() => navigationStore.push("/subscription-trial")}
                  >
                    {t("AccountPage.clickhere")}
                  </Button>{" "}
                  {t("AccountPage.upgradeFromFree")}
                  <strong>
                    {" "}
                    {authStore.monthlySubscriptionAmountFormatted}{" "}
                  </strong>
                  per month
                </>
              )}

              {clientStore.statusDescription === "Paid" &&
                clientStore.clientSubscriptionWillRenew === true && (
                  <p className="mb-2">
                    {t("AccountPage.subscriptionRenews")}{" "}
                    {clientStore.nextPaymentDueDate}
                  </p>
                )}
              {clientStore.statusDescription === "Paid" &&
                clientStore.clientSubscriptionWillRenew === false && (
                  <p className="mb-2">
                    {t("AccountPage.subscriptionExpires")}{" "}
                    {clientStore.nextPaymentDueDate}
                  </p>
                )}

              {(clientStore.statusDescription === null ||
                clientStore.statusDescription === "") && (
                <p className="mb-2">{t("AccountPage.accountStatusNotFound")}</p>
              )}
            </Col>

            <Col md="6" className="mb-5">
              <h3 className="subtitle">
                {t("AccountPage.communicationSubtitle")}
              </h3>
              <p className="mb-2">{t("AccountPage.communicationText")}</p>
              <Button color="body-link-reverse" onClick={toggleCommunication}>
                {t("AccountPage.edit")}
              </Button>
            </Col>

            {/* Family Members Table */}
            {!authStore.hidefamilyMembers && (
              <>
                <Col xs="12" xl="12" className="mb-5">
                  <h3 className="subtitle">
                    {t("AccountPage.familyMemberSubtitle")}
                  </h3>
                  {clientStore.familyMembers?.length > 0 && (
                    <p className="mb-1">
                      {t("familyMemberComponent.manageFamilyText")}
                    </p>
                  )}
                  {clientStore.familyMembers?.length === 0 ? (
                    <p className="mb-2">
                      {t("AccountPage.noFamilyMember", {
                        sessions: authStore.jobPlural.toLowerCase(),
                      })}
                    </p>
                  ) : loading ? (
                    <Row>
                      <Col xs={{ size: 12, offset: 6 }}>
                        <Spinner color="primary" size="lg" />
                      </Col>
                    </Row>
                  ) : (
                    <Table className="table">
                      <Thead>
                        <Tr>
                          <Th>{t("familyMemberComponent.nickname")}</Th>
                          <Th>{t("familyMemberComponent.familyMember")}</Th>
                          <Th>{t("familyMemberComponent.gender")}</Th>
                          <Th>{t("familyMemberComponent.BirthDate")}</Th>
                          <Th>{t("familyMemberComponent.tableAction")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {clientStore.familyMembers?.map(
                          (famMem: any, i: number) => (
                            <Fragment key={famMem.Key}>
                              <Tr>
                                <Td className="upcomingSession">
                                  {famMem.FirstName}
                                </Td>
                                <Td className="upcomingSession">
                                  {famMem.Relationship}
                                </Td>
                                <Td className="upcomingSession">
                                  {famMem.Gender}
                                </Td>
                                <Td className="upcomingSession">
                                  {famMem.Birthdate &&
                                    reformatDate(
                                      famMem.Birthdate.split("T")[0]
                                    )}
                                </Td>

                                <Td className="upcomingSession">
                                  <Button
                                    color="primary"
                                    style={{ width: "100%" }}
                                    className="d-inline-block mr-2 mb-2"
                                    size="sm"
                                    onClick={() => selectFamilyMember(i)}
                                  >
                                    {t("AccountPage.edit")}
                                  </Button>
                                </Td>

                                <Td className="upcomingSession">
                                  <Button
                                    color="danger"
                                    style={{ width: "80%" }}
                                    className="d-inline-block mr-2 mb-2"
                                    size="sm"
                                    onClick={() =>
                                      showFamilyMemberDeleteConfirm(i)
                                    }
                                  >
                                    {t("AccountPage.delete")}
                                  </Button>
                                </Td>
                              </Tr>
                            </Fragment>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}
                  <Row>
                    <Col>
                      <Button
                        color="body-link-reverse"
                        onClick={toggleFamilyMember}
                      >
                        {t("AccountPage.addFamilyMember")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            {ecomStore.allShippingAddresses && (
              <Col xs="12" xl="6" className="mb-5">
                <h3 className="subtitle">
                  {t("AccountPage.shippingSubtitle")}
                </h3>
                <p>{t("AccountPage.shippingText")}</p>
                <Row>
                  {ecomStore.allShippingAddresses?.map(
                    (add: ShippingAddress, i: number) => (
                      <Col key={add.Key} sm="6" xl="4" className="mb-4">
                        <address className="mb-2">
                          <span className="d-block">{add.Name}</span>
                          <span className="d-block">{add.Line1}</span>
                          {add.Line2 && (
                            <span className="d-block">{add.Line2}</span>
                          )}
                          <span className="d-block">{`${add.City}${
                            add.City && add.State ? "," : ""
                          } ${add.State} ${add.Postcode}`}</span>
                        </address>
                        <ul className="list-inline" role="presentational">
                          <li className="list-inline-item">
                            <Button
                              color="body-link-reverse"
                              onClick={() => selectAddress(i)}
                            >
                              {t("AccountPage.edit")}
                            </Button>
                          </li>
                          <li className="list-inline-item">
                            <Button
                              color="body-link-reverse"
                              onClick={() => showDeleteConfirm(i)}
                            >
                              {t("AccountPage.delete")}
                            </Button>
                          </li>
                          {add.Default && (
                            <li className="list-inline-item">
                              <span
                                className="badge-status badge-primary"
                                style={{ position: "relative", top: 1 }}
                              >
                                <FaCheck className="mr-2" />
                                {t("AccountPage.default")}
                              </span>
                            </li>
                          )}
                        </ul>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
            )}

            {/* Sessions Table */}
            {!authStore.hideUpcomingSessions && (
              <>
                <Col xs="12" xl="6" className="mb-5">
                  <h3 className="subtitle">
                    {t("AccountPage.upcomingSubtitle")} {authStore.jobPlural}
                  </h3>
                  {clientStore.clientInfo?.UpcomingSessions?.length > 0 && (
                    <p className="mb-1">
                      {t("AccountPage.upcomingText")} {authStore.jobPlural}
                    </p>
                  )}
                  {clientStore.clientInfo?.UpcomingSessions?.length === 0 ? (
                    <p>
                      {t("AccountPage.noUpcomingText", {
                        sessions: authStore.jobPlural.toLowerCase(),
                      })}
                    </p>
                  ) : loading ? (
                    <Row>
                      <Col xs={{ size: 12, offset: 6 }}>
                        <Spinner color="primary" size="lg" />
                      </Col>
                    </Row>
                  ) : (
                    <Table className="table">
                      <Thead>
                        <Tr>
                          <Th>{t("AccountPage.tableDate")}</Th>
                          <Th>{t("AccountPage.tableTime")}</Th>
                          <Th>{t("AccountPage.tableDescription")}</Th>
                          <Th>{t("AccountPage.tableAction")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {clientStore.clientInfo?.UpcomingSessions?.map(
                          (session: any) => (
                            <Fragment>
                              <Tr key={session.Key}>
                                <Td className="upcomingSession">
                                  {session.SessionDate}
                                </Td>
                                <Td className="upcomingSession">
                                  {session.StartTime}
                                </Td>
                                <Td className="upcomingSession">
                                  {session.Description}
                                </Td>
                                <Td className="upcomingSession">
                                  <div className="d-flex flex-column">
                                    {session.ShowConfirmBtn && (
                                      <Button
                                        onClick={() => {
                                          setLoading(true);
                                          clientStore.confirmUpcomingSession(
                                            session.Key
                                          );
                                        }}
                                        color="primary"
                                        style={{ width: "90%" }}
                                        className="text-white d-inline-block mb-2 mr-2"
                                        size="sm"
                                      >
                                        {t("AccountPage.confirm")}
                                      </Button>
                                    )}
                                    {session.ShowRescheduleBtn && (
                                      <Button
                                        onClick={(e) => {
                                          toggleReschedule(
                                            e,
                                            session.RescheduleUrl
                                          );
                                        }}
                                        color="primary"
                                        style={{ width: "90%" }}
                                        className="d-inline-block mr-2 mb-2"
                                        size="sm"
                                      >
                                        {t("AccountPage.reschedule")}
                                      </Button>
                                    )}
                                    {/* {session.ShowCancelBtn && <Button onClick={() => { setLoading(true); clientStore.cancelUpcomingSession(session.Key)}} color="danger" style={{width: '90%'}} className="d-inline-block mr-2 mb-2" size="sm">{t('AccountPage.cancel')}</Button>}</div> */}
                                    {session.ShowCancelBtn && (
                                      <Button
                                        onClick={(e) => {
                                          setSessionKeyToDelete(session.Key);
                                          toggleConfirmCancel(e);
                                        }}
                                        color="danger"
                                        style={{ width: "90%" }}
                                        className="d-inline-block mr-2 mb-2"
                                        size="sm"
                                      >
                                        {t("AccountPage.cancel")}
                                      </Button>
                                    )}
                                  </div>
                                </Td>
                              </Tr>
                            </Fragment>
                          )
                        )}
                      </Tbody>
                    </Table>
                  )}

                  <Row>
                    <Col>
                      <Button
                        style={{ padding: "1rem 1.5rem", fontSize: "1rem" }}
                        onClick={toggleSchedule}
                        color="primary"
                        size="lg"
                      >
                        {t("AccountPage.scheduleButton")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </div>
      </section>

      <Modal
        isOpen={modalDelete}
        toggle={toggleDelete}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleDelete}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleDelete}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.deleteAddressButton")}
        </ModalHeader>
        <ModalBody>
          <address className="mb-3">
            {ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Name}
            <br />
            {ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Line1}
            <br />
            {ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Line2 && (
              <>
                {ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Line2}
                <br />
              </>
            )}
            {`${ecomStore.allShippingAddresses?.[deleteAddressIndex]?.City}, ${ecomStore.allShippingAddresses?.[deleteAddressIndex]?.State} ${ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Postcode}`}
          </address>
          <Button
            color="danger"
            onClick={() =>
              deleteAddress(
                ecomStore.allShippingAddresses?.[deleteAddressIndex]?.Key
              )
            }
          >
            {t("AccountPage.delete")}
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalAddress}
        toggle={toggleAddress}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleAddress}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleAddress}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.updateAddressModalTitle")}
        </ModalHeader>
        <ModalBody>
          <EditAddressForm
            index={editAddressIndex}
            onSuccess={() => setModalAddress(!modalAddress)}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalUsername}
        toggle={toggleUsername}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={toggleUsername}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleUsername}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.updateUsernameModalTitle")}
        </ModalHeader>
        <ModalBody>
          <UsernameForm onSuccess={toggleUsername} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalPassword}
        toggle={togglePassword}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={togglePassword}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={togglePassword}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.updatePasswordModalTitle")}
        </ModalHeader>
        <ModalBody>
          <PasswordForm onSuccess={togglePassword} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalPersonal}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        {clientStore.clientInfo.CustomTextField01?.length === 0 ? (
          <ModalHeader>
            {t("AccountPage.updatePersonalInfoModalTitle")}
          </ModalHeader>
        ) : (
          <ModalHeader
            toggle={togglePersonal}
            close={
              <Button
                color="icon-action"
                className="close"
                onClick={togglePersonal}
              >
                <MdClose size="18" />
              </Button>
            }
          >
            {t("AccountPage.updatePersonalInfoModalTitle")}
          </ModalHeader>
        )}

        <ModalBody>
          <PersonalInfo onSuccess={togglePersonalSuccess} />
        </ModalBody>
      </Modal>

      {/* Add Family Member */}

      <Modal
        isOpen={modalFamilyMember}
        toggle={toggleFamilyMember}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader>{t("AccountPage.addFamilyMember")}</ModalHeader>
        <ModalBody>
          <FamilyMemberForm onSuccess={toggleFamilyMemberSuccess} />
          {authStore.userflowCreateAccount &&
            clientStore.familyMembers?.length === 0 && (
              <Row className="mb-2 mt-4 align-items-center">
                <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                  <Button
                    color="secondary text-truncate"
                    className="w-100"
                    onClick={closeFamilyMemberModalFirst}
                  >
                    Skip for Now
                  </Button>
                </Col>
              </Row>
            )}

          {clientStore.familyMembers?.length === 0 &&
            !authStore.userflowCreateAccount && (
              <Row className="mb-2 mt-4 align-items-center">
                <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                  <Button
                    color="secondary text-truncate"
                    className="w-100"
                    onClick={closeFamilyMemberModal}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            )}
          {clientStore.familyMembers?.length > 0 && (
            <Row className="mb-2 mt-4 align-items-center">
              <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                <Button
                  color="secondary text-truncate"
                  className="w-100"
                  onClick={closeFamilyMemberModal}
                >
                  No More Family Members
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      {/* Edit Family Member */}

      <Modal
        isOpen={modalEditFamilyMember}
        toggle={toggleFamilyMemberEdit}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader>{t("AccountPage.editFamilyMember")}</ModalHeader>
        <ModalBody>
          <FamilyMemberEditForm
            editAddressIndex={editFamilyMemberIndex}
            onSuccess={toggleFamilyMemberEditSuccess}
          />
          {clientStore.familyMembers?.length > 0 && (
            <Row className="mb-2 mt-4 align-items-center">
              <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                <Button
                  color="secondary text-truncate"
                  className="w-100"
                  onClick={closeFamilyMemberEditModal}
                >
                  Close
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalFamilyMemberDelete}
        toggle={toggleFamilyMemberDelete}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleFamilyMemberDelete}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleFamilyMemberDelete}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.deleteFamilyMember")}
        </ModalHeader>
        <ModalBody>
          <h3 className="subtitle">
            {clientStore.familyMembers?.[deleteFamilyMemberIndex]?.FirstName}
          </h3>
          <p className="mb-2">
            {clientStore.familyMembers?.[deleteFamilyMemberIndex]?.Relationship}
          </p>
          {/*<p className="mb-2">
            {clientStore.familyMembers?.[deleteFamilyMemberIndex]?.Gender}
          </p>
          <p className="mb-2">
            {clientStore.familyMembers?.[deleteFamilyMemberIndex]?.Birthdate}
          </p> */}
          <Button
            color="danger"
            onClick={() =>
              deleteFamilyMember(
                clientStore.familyMembers?.[deleteFamilyMemberIndex]?.Key
              )
            }
          >
            {t("AccountPage.delete")}
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalCommunication}
        toggle={toggleCommunication}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader
          toggle={toggleCommunication}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleCommunication}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.updateCommunicationModalTitle")}
        </ModalHeader>
        <ModalBody>
          <CommunicationForm onSuccess={toggleCommunication} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalPayment}
        toggle={togglePayment}
        wrapClassName="modal-box overflow-hidden"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={togglePayment}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={togglePayment}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.updatePaymentModalTitle")}
        </ModalHeader>
        <ModalBody>
          <div className="accountIframe">
            <IframeResizer
              log={false}
              scrolling={false}
              src={clientStore?.clientInfo?.PaymentMethodUrl}
              style={{ width: 1, minWidth: "100%", minHeight: 600, border: 0 }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalSchedule}
        toggle={toggleSchedule}
        wrapClassName="modal-box overflow-hidden"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleSchedule}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleSchedule}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.scheduleModalTitle")}
        </ModalHeader>
        <ModalBody>
          <div className="accountIframe">
            <IframeResizer
              log={false}
              scrolling={false}
              src={clientStore?.clientInfo?.ScheduleNowUrl}
              style={{
                width: 1,
                minWidth: "100%",
                minHeight: 600,
                border: 0,
                paddingLeft: 25,
                paddingBottom: 25,
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalConfirmCancel}
        toggle={toggleConfirmCancel}
        wrapClassName="modal-box overflow-hidden"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="md"
      >
        <ModalHeader
          toggle={toggleConfirmCancel}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleConfirmCancel}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.confirmCancel")}
        </ModalHeader>
        <ModalBody>
          <p>{t("AccountPage.areYouSure")}</p>
          <Row>
            <Col>
              <Button onClick={toggleConfirmCancel} block className="mb-3">
                {t("AccountPage.doNotCancel")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block
                color="danger"
                onClick={(e) => {
                  setLoading(true);
                  clientStore.cancelUpcomingSession(sessionKeyToDelete);
                  toggleConfirmCancel(e);
                }}
              >
                {loading ? <Spinner size="lg" /> : t("AccountPage.cancel")}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalReschedule}
        toggle={toggleReschedule}
        wrapClassName="modal-box overflow-hidden"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleReschedule}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleReschedule}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("AccountPage.rescheduleModalTitle")}
        </ModalHeader>
        <ModalBody>
          <div className="accountIframe">
            <IframeResizer
              log={false}
              scrolling={false}
              src={rescheduleUrl}
              style={{
                width: 1,
                minWidth: "100%",
                minHeight: 600,
                border: 0,
                paddingLeft: 25,
                paddingBottom: 25,
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default inject(
  "authStore",
  "clientStore",
  "ecomStore",
  "navigationStore",
  "guestStore"
)(observer(AccountPage));
