import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { FaCheckCircle } from "react-icons/fa";

import brokenImg from "../../imageTile/broken-img.png";

const PhotoTile: React.FC<any> = ({
  ecomStore,
  img,
  index = 0,
  active = false,
  className,
  ...props
}) => {
  const [hideButtons, setHideButtons] = useState(true);

  function handleImgErr(e: any) {
    if (e.target.src !== brokenImg) {
      e.target.onerror = null;
      e.target.src = brokenImg;
    }
  }
  const imgElement = React.useRef(null);

  const imageLoaded = (img: any) => {
    setTimeout(function () {
      setHideButtons(false);
    }, 300);
  };

  return (
    <figure
      className={`img-tile is-multi${!hideButtons ? " loaded" : ""}${
        active ? ` active` : ``
      } ${` ` + className}`}
      {...props}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div className="icon-active">
        <FaCheckCircle size={24} />
      </div>

      <div
        className="image-ratio"
        style={{
          width: `100%`,
          paddingTop: `${(img.height / img.width) * 100}%`,
        }}
      ></div>

      <div className={`img-wrapper`}>
        <img
          className="w-100"
          src={img.thumbnail}
          alt={`session ${index + 1}`}
          onError={handleImgErr}
          ref={imgElement}
          width={img.width}
          height={img.width}
          onLoad={(img) => imageLoaded(img)}
        />

        {!hideButtons && (
          <figcaption className="session-filename">
            {img.imageNumber}
          </figcaption>
        )}
      </div>
    </figure>
  );
};
export default inject("ecomStore")(observer(PhotoTile));
