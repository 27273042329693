import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
// import * as Yup from "yup";
import { 
  FormGroup,
  Label,
  FormFeedback,
  Spinner,
  Button
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import NumberIncrement from '../numberIncrement/numberIncrement';

interface ChildProps{
    onSubmit:(obj:AddToCartFormData)=>void,
    initialData?:AddToCartFormData,
    ajaxError?:string
}

export interface AddToCartFormData{
    quantity?:number,
}

const AddToCartForm: React.FC<ChildProps> = ({
    onSubmit,
    initialData,
    ajaxError
    }) => {
    const { t } = useTranslation();
    
    // const validationSchema =
    //     Yup.object().shape({
    //         quantity: Yup.number()
    //             .min(1)

    //     })

    const initialValues:AddToCartFormData = {
        quantity:1 
    }
    return (
      <Formik
          initialValues={Object.assign({}, initialValues, initialData)}
        //   validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
      >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting
              /* and other goodies */
          }) => ( 
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-group-add-cart">
                      <Label htmlFor = "quantity_input" className = {`sr-only`}>{t('addToCartFormComponent.quantity')}</Label>
                      <div className="row row-xs">
                        <div className="col col-qty">
                          <NumberIncrement
                            int = {values.quantity || 1}
                            onChange = {(e)=>setFieldValue('quantity', e.int)}
                            isSubmitting = {isSubmitting}
                          />
                          <ErrorMessage name = "email">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                        </div>
                        <div className="col col-btn">
                          <Button 
                            type="submit" 
                            color = "primary" 
                            block 
                            className={isSubmitting ? 'wait' : ''}
                            disabled={isSubmitting}
                          >
                            {`Add To Cart`}
                            {isSubmitting && <Spinner color = '#fff' size="sm" /> }
                          </Button>
                        </div>  
                      </div>
                    </FormGroup>
                    {ajaxError && (
                      <div className = "alert alert-danger" role="alert">{ajaxError}</div>
                    )}
                  </Form>
              )}
      </Formik>
    );

}

export default AddToCartForm;
