import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import {
  Row,
  DropdownItem,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { replace } from "formik";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const ReplaceItem: React.FC<any> = ({
  authStore,
  ecomStore,
  sessionStore,
  compositeStore,
  createOrderStore,
}) => {
  const { t } = useTranslation();
  const [spinner, setSpinner] = useState(false);
  const [itm, setItm] = useState<any>({});
  const [modal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState<any>({});
  useEffect(() => {
    // const replacementItems = ecomStore.croppedPhoto.ReplacementItems;
    setItm(ecomStore.croppedPhoto);
  }, [ecomStore.croppedPhoto]);

  async function handleDropdownClick(itemKey: any, replacementItemKey: any) {
    setSpinner(true);
    await ecomStore
      .swapPackageComponent(itemKey, replacementItemKey)
      .then(async () => {
        const priceItem = ecomStore.findItemByKey(
          ecomStore.croppedPhoto.ItemKey
        );
        const options = priceItem.Options;
        const enhancements = priceItem.Enhancements;
        if (ecomStore.croppedPhoto?.PreOrder) {
          createOrderStore.toggleSlider();
        } else if (
          (ecomStore.isImageRequired(ecomStore.croppedPhoto) &&
            !ecomStore.croppedPhoto.FileName) ||
          (ecomStore.croppedPhoto.allowImageSelection &&
            !ecomStore.croppedPhoto.FileName)
        ) {
          sessionStore.swappedPhoto = ecomStore.croppedPhoto;
          ecomStore.swapPhotoCartItemKey = ecomStore.croppedPhoto.Key;
          ecomStore.scrollCart = ecomStore.croppedPhoto.Key;
          createOrderStore.setStepNumber(5);
        } else if (
          ecomStore.isCropRequired(ecomStore.croppedPhoto) ||
          options?.length > 0 ||
          enhancements?.length > 0
        ) {
          ecomStore.setCroppedPhoto(ecomStore.croppedPhoto.Key);
          ecomStore.swapPhotoCartItemKey = ecomStore.croppedPhoto.Key;
          ecomStore.scrollCart = ecomStore.croppedPhoto.Key;
          createOrderStore.setStepNumber(6);
        } else {
          let updatedItem = ecomStore.currentPackageItems.find(
            (item: any) => item.Key === ecomStore.croppedPhoto.Key
          );
          let updatedItemIdx = ecomStore.currentPackageItems.findIndex(
            (item: any) => item.Key === ecomStore.croppedPhoto.Key
          );

          let found = false;
          while (
            updatedItem?.PackageItemKey &&
            ecomStore.currentPackageItems &&
            updatedItemIdx + 1 < ecomStore?.currentPackageItems?.length &&
            !found
          ) {
            const newItem = ecomStore.currentPackageItems[updatedItemIdx + 1];
            const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
            const options = priceItem.Options;
            const enhancements = priceItem.Enhancements;
            if (newItem.compositeTemplateKey) {
              ecomStore.scrollCart = newItem.Key;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              await compositeStore.handleComposite(newItem);
              createOrderStore.setStepNumber(7);
              found = true;
            } else if (newItem?.ReplacementItems?.length > 0) {
              ecomStore.setCroppedPhoto(newItem.Key);
              createOrderStore.setStepNumber(8);
              found = true;
            } else if (
              ecomStore.isImageRequired(newItem) ||
              newItem.allowImageSelection
            ) {
              sessionStore.swappedPhoto = newItem;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(5);
              found = true;
            } else if (
              ecomStore.isCropRequired(newItem) ||
              options?.length > 0 ||
              enhancements?.length > 0
            ) {
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.setCroppedPhoto(newItem.Key);
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(6);
              found = true;
            } else {
              updatedItemIdx += 1;
            }
          }
          if (
            !updatedItem?.PackageItemKey ||
            updatedItemIdx + 1 === ecomStore.currentPackageItems.length
          ) {
            createOrderStore.setStepNumber(4);
          }
        }
      });
    setSpinner(false);
  }

  if (spinner)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div className="mb-1" style={{ textAlign: "center" }}>
          Updating package item
        </div>
        <div style={{ textAlign: "center" }}>
          <Spinner color="primary" />
        </div>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>
          Repalce Item
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section className="product-modal-container" id="modal_body_123123123">
        <div className="product-modal-head">
          <h2>{itm.Description}</h2>
        </div>
        <div className="product-modal-list">
          {itm?.ReplacementItems?.map((replacementItem: any, idx: any) => {
            const item = ecomStore.getItemByKey(replacementItem.ItemKey);
            if (itm.ItemKey !== replacementItem.ItemKey) {
              return (
                <div
                  className="col-12 col-md-12 mb-5"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleDropdownClick(itm.Key, replacementItem.ItemKey);
                  }}
                  key={`priceListItem${idx}`}
                >
                  <article
                    className="card card-body card-product-list"
                    style={{
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                    key={`priceListItemArticle${idx}`}
                  >
                    <header>
                      {item.ProductImages?.[0].ThumbUrl && (
                        <div className="image ">
                          <img
                            className="img"
                            onClick={() => {}}
                            src={`${item.ProductImages?.[0].ThumbUrl}`}
                            alt=""
                          />
                          <span
                            className=""
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setShowModal(true);
                              setModalItem(item);
                            }}
                          >
                            <FiSearch />
                            {t("productGroupDetailComponent.viewLarger")}
                          </span>
                        </div>
                      )}
                      <div className="title">
                        <h4 style={{ cursor: "pointer" }} onClick={() => {}}>
                          {item.Name}
                        </h4>
                        <p className="price">
                          {item?.PriceStringFormatted === "$0.00"
                            ? "Free"
                            : item?.PriceStringFormatted}
                        </p>
                        <div className="summary" onClick={() => {}}>
                          {item.MarketingDescription}
                        </div>
                      </div>
                    </header>
                    {/* {item?.MarketingDescription && (
                      <div
                        className="summary"
                        onClick={() => {}}
                        dangerouslySetInnerHTML={{
                          __html: item.MarketingDescription || "",
                        }}
                      ></div>
                    )} */}
                    {/* {item.ProductImages?.[0].ThumbUrl && (
                      <Button
                        color="body-link"
                        onClick={() => {
                          handleDropdownClick(itm.Key, replacementItem.ItemKey);
                        }}
                      >
                        Select Item
                      </Button>
                    )} */}

                    <p className="mt-3 text-center">
                      <Button
                        color="body-link"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleDropdownClick(itm.Key, replacementItem.ItemKey);
                        }}
                      >
                        Select Item
                      </Button>
                    </p>
                  </article>
                </div>
              );
              //   return (
              //     <>
              //       <DropdownItem
              //         onClick={() => {
              //           handleDropdownClick(itm.Key, replacementItem.ItemKey);
              //         }}
              //         style={{ height: "10rem" }}
              //       >
              //         <Row
              //           style={{
              //             paddingLeft: "1rem",
              //             display: "flex",
              //             alignItems: "center",
              //           }}
              //         >
              //           {item?.ProductImages &&
              //             item?.ProductImages[0].ThumbUrl && (
              //               <>
              //                 {item?.ProductImages?.length && (
              //                   <img
              //                     className="pr-3"
              //                     alt="Product"
              //                     width={150}
              //                     src={item.ProductImages[0].ThumbUrl}
              //                   ></img>
              //                 )}
              //               </>
              //             )}
              //           <em>{item?.Name}</em>
              //         </Row>
              //         <Row style={{ padding: "1rem" }}>
              //           <p>{replacementItem.MarketingDescription}</p>
              //         </Row>
              //       </DropdownItem>
              //       {itm.ReplacementItems.length > 1 &&
              //         idx !== itm.ReplacementItems.length - 1 && (
              //           <DropdownItem divider></DropdownItem>
              //         )}
              //     </>
              //   );
            }
          })}

          {!itm?.MustReplace && (
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                handleDropdownClick(itm.Key, itm.ItemKey);
              }}
              className="col-12 col-md-12 mb-5"
              key={`priceListItemnone`}
            >
              <article
                className="card card-body card-product-list"
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
                key={`priceListItemArticlenone`}
              >
                <header>
                  <div className="title">
                    <h4 style={{ cursor: "pointer" }} onClick={() => {}}>
                      None
                    </h4>

                    <div className="summary" onClick={() => {}}>
                      Continue without selecting an item
                    </div>
                  </div>
                </header>

                <p className="mt-3 text-center">
                  <Button
                    color="body-link"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleDropdownClick(itm.Key, itm.ItemKey);
                    }}
                  >
                    Select Item
                  </Button>
                </p>
              </article>
            </div>
          )}

          <Modal
            isOpen={modal}
            toggle={() => setShowModal(!modal)}
            wrapClassName="modal-box"
            modalTransition={{ timeout: 500 }}
            backdropTransition={{ timeout: 0 }}
            centered
            key={`modal`}
          >
            <ModalHeader
              toggle={() => setShowModal(!modal)}
              close={
                <Button
                  color="icon-action"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <MdClose size="18" />
                </Button>
              }
            >
              {modalItem?.Name}
            </ModalHeader>
            <ModalBody>
              <img
                className="img"
                src={`${modalItem?.ProductImages?.[0].Url}`}
                alt=""
              />
            </ModalBody>
          </Modal>
        </div>
      </section>
    </>
  );
};
export default inject(
  "authStore",
  "ecomStore",
  "createOrderStore",
  "compositeStore",
  "sessionStore"
)(observer(ReplaceItem));
