import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormGroup, Button } from 'reactstrap';
import { FiPlus, FiMinus } from 'react-icons/fi';

interface ChildProps{
    onChange:(obj:any)=>void,
    int:number,
    isSubmitting?:any
}



const NumberIncrement: React.FC<ChildProps> = ({
    onChange,
    int,
    isSubmitting
    }) => {
    const initialValues:any = {
        int: int
    }
    return (
            <div id = "NumberIncrement">
     
                    <Formik
                        
                        initialValues={initialValues}
                        onSubmit={(onChange)}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                                <Form onSubmit={handleSubmit} className = "numberForm">
                                    <FormGroup className="form-increment">
                                        <Button 
                                            color = "icon-action" 
                                            onClick = {()=>{
                                                if(int > 1){
                                                    int -- 
                                                    setFieldValue('int', int)
                                                    onChange({int:int})
                                                }
                                            }}
                                            disabled={isSubmitting}>
                                              <FiMinus size="16" />
                                            </Button>
                                        <Field
                                            name = "int"
                                            className = "form-control"
                                            onChange= {(e:any)=>{
                                                int = e.target.value;
                                                setFieldValue('int', int)
                                                onChange({int:int})
                                            }}
                                            disabled={isSubmitting}
                                        />
                                        <Button 
                                            color = "icon-action"
                                            onClick = {()=>{
                                                int ++
                                                setFieldValue('int', int)
                                                onChange({int:int})
                                            }}
                                            disabled={isSubmitting}>
                                              <FiPlus size="16" />
                                            </Button>
                                    </FormGroup>
                                </Form>
                            )}
                    </Formik>
                </div>

    );

}

export default NumberIncrement;
