import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react'
import { Button, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import ImageTileList from '../../components/imageTileList/imageTileList';
import ResponsiveHeader from '../../components/responsiveHeader/responsiveHeader';

const FoldersPage: React.FC<any> = ({ sessionStore, authStore, loginPromptStore, navigationStore, imageGroupStore }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(new Array(imageGroupStore.imageGroupList.length).fill(false))
    let randomKey: number = Math.random();
    useEffect(() => {
      setIsOpen(new Array(imageGroupStore.imageGroupList.length).fill(false));
    }, [sessionStore.activeSession]);
    useEffect(() => {
        if (authStore.isGuestSession) {
            loginPromptStore.setShowLoginPrompt(true);
            navigationStore.goBack();
        }
    }, [])

    return (
        <>
          <ResponsiveHeader />
          <Helmet>
            <title>{t('FoldersPage.urlTitle')} {sessionStore.activeSession.Description} {authStore.companyName || authStore.companyName !== '' ? (
            '| ' + authStore.companyName
            ) : ' '}</title>
          </Helmet>

            <nav  className = "inner-section session-folders-nav py-3 mb-4 d-print-none">
              <ul className = "list-inline" role="presentation">
                <li className = "list-inline-item">
                  <Link to="/sessions">
                    <Button color="light" className="btn-badge">
                      <FiArrowLeft size="18" className="mr-2" />
                      {t('FoldersPage.viewFull')} {authStore.jobSingular.toLowerCase()}
                    </Button>
                  </Link>
                </li>

              </ul>
            </nav>
            
            {!imageGroupStore.imageGroupList?.map((imageGroup:any)=>(imageGroup.FileNames))?.flat(1)?.length &&
              <p className = "inner-section"> {t('FoldersPage.noFoldersFound')} {authStore.jobSingular.toLowerCase()}.</p>
            }



            {imageGroupStore.imageGroupList?.map((imageGroup: any, i: number) => {
              return(
                !!imageGroup.FileNames.length?
                <div key = {`${imageGroup}${i}${sessionStore.activeSession}${randomKey}`} className="inner-section">
                  <Button 
                    color="primary" 
                    onClick={() => {
                      setIsOpen(isOpen.map((b: boolean, index: number) => (i === index ? !b : b)));
                      setTimeout(function(){
                        window.dispatchEvent(new Event('resize'));
                      }, 100);
                    }} 
                    className="noline"
                    style={{ marginBottom: '1rem' }}
                  >
                      <i style={{ position: 'relative', top: -3 }}>{isOpen[i]?<RiArrowDownSLine size="28" />:<RiArrowRightSLine size="28" />}<AiOutlineFolderOpen size="28" /></i>
                      <span className="ml-3 h4">{imageGroup.GroupName}</span>
                  </Button>
                  <Collapse key={`group-collapse${i}${imageGroup}${sessionStore.activeSession}${randomKey}`} isOpen={isOpen[i]}>
                    <div style={{ paddingBottom: 50 }}>
                      <ImageTileList key={`imagetileList ${i}${imageGroup}${sessionStore.activeSession}${randomKey}`}
                        groupName = {imageGroup.GroupName}
                        images={sessionStore.allUnfilteredImages.filter((img: any) => (imageGroup.FileNames.includes(img.fileName)))} 
                        favorites={true}/>
                    </div>
                  </Collapse>
                </div>
            : ''
            )})
            }
        </>
    );
}

export default inject(
    'sessionStore',
    'authStore',
    'loginPromptStore',
    'navigationStore',
    'imageGroupStore'
)(observer(FoldersPage))
