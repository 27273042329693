// Do not add any nested objects to this json. I.e. the path to get a string should only include one '.'
export default {
  AccountPage: {
    title: "My Account",
    loginSubtitle: "Login info",
    loginText: "Logged in as",
    editUsername: "Edit username",
    changePassword: "Change password",
    personalInfoSubtitle: "Personal info",
    familyMemberSubtitle: "Family Members",
    addFamilyMember: "Add Family Member",
    editFamilyMember: "Edit Family Member",
    noFamilyMember: "You have not added any family members yet",
    deleteFamilyMember: "Are you sure you want to delete this family member?",
    edit: "Edit",
    delete: "Delete",
    default: "Default",
    paymentSubtitle: "Payment",
    paymentText: "Update saved credit card information",
    communicationSubtitle: "Communication",
    communicationText: "For promotions and newsletter opt-ins",
    shippingSubtitle: "Shipping addresses",
    shippingText: "Use for checkout as shipping information",
    upcomingSubtitle: "Upcoming",
    upcomingText: "Confirm, reschedule, or cancel",
    noUpcomingText: "You do not have any upcoming {{sessions}}",
    tableDate: "Date",
    tableTime: "Time",
    tableDescription: "Description",
    tableAction: "Action",
    confirm: "Confirm",
    reschedule: "Reschedule",
    cancel: "Cancel",
    confirmCancel: "Confirm Cancel",
    areYouSure: "Are you sure you want to cancel?",
    doNotCancel: "Do Not Cancel",
    scheduleButton: "Schedule Now",
    deleteAddressButton: "Are you sure you want to delete this address?",
    updateAddressModalTitle: "Update Address",
    updateUsernameModalTitle: "Update Username",
    updatePasswordModalTitle: "Change Password",
    updatePersonalInfoModalTitle: "Update personal info",
    updateCommunicationModalTitle: "Update opt-ins",
    updatePaymentModalTitle: "Payment",
    scheduleModalTitle: "Schedule",
    rescheduleModalTitle: "Reschedule",
    accountstatus: "Account Status",
    clickhere: "Click here",
    shareJoy: "Share on Anne's Joy Page",
    freeAccount: "You currently have a free account with limited access",
    upgradeFromFree: " to upgrade to a paid subscription for only",
    trialAccount: "You currently have a trial account which expires",
    upgradeFromTrial: " to upgrade to a paid subscription for only",
    subscriptionRenews:
      "You currently have a monthly subscription that will renew on",
    subscriptionExpires:
      "You currently have a monthly subscription that expires on",
    subscriptionCancel: "To cancel, visit your App Store – Subscriptions page",
    accountStatusNotFound: "Your account status was not found.",
  },
  ForgotPasswordPage: {
    title: "Forgot Password",
    subtitle:
      "An email will be sent to your email address with further instructions",
    alertSuccess:
      "You should receive an email shortly. Please check your junk or spam email folder if you cannot find the email.",
    backToLogin: "Back to login",
  },
  LoginPage: {
    title: "Sign in to view amazing images inspired by you!",
    annegeddestitle: "Welcome to the Anne Geddes Community!",
    description:
      "If you already have an account, log in here to view your images, or click 'Event Search' above to search for an event.",
    email: "Email address or Username",
    password: "Password",
    forgotPass: "Forgotten password",
    emailError: "Please enter an email address or username",
    ajaxError: "Invalid username or password",
    passwordError: "Please enter a password",
    loginButton: "Login",
  },
  ResetPasswordPage: {
    title: "Enter Your New Password",
    alertSuccess:
      "Your password has been successfully reset. You will be redirected back to the login page.",
    backToLogin: "Back to login",
  },
  SignupPage: {
    title: "Create an account",
    p1: "Already have an account?",
    p2: "to view your photos.",
    createAccountLink: "Log in here",
    email: "Email",
    password: "Password",
    signup: "Sign up",
    ajaxError: "Username already exists",
    ajaxErrorOther: "Oops, something went wrong. Please try again.",
  },
  ConfirmationPage: {
    tabTitle: "Thank you: Order",
    title: "Thank you for placing your order.",
    text: "Your order number is",
    relatedProducts: "You might be interested in...",
  },
  CheckoutPage: {
    urlTitle: "Secure Checkout",
    title: "Secure Checkout",
    subtitle: "4096-bit SSL/TSL protected page",
  },
  OrderReviewPage: {
    urlTitle: "Secure Checkout: Order Review",
    tableProductName: "Product Name",
    tablePrice: "Price",
    tableQuantity: "Quantity",
    tableSubtotal: "Subtotal",
    enter: "Enter it now",
    apply: "Apply",
    cancel: "Cancel",
    promo: "Promo:",
    shipping: "Shipping",
    tax: "Estimated Tax",
    total: "Total",
  },
  PaymentPage: {
    urlTitle: "Secure Checkout: Payment Info",
  },
  ShippingAddressPage: {
    urlTitle: "Secure Checkout: Shipping Info",
  },
  ContractsPage: {
    urlTitle: "Contracts:",
    title: "Contracts",
    invalidPIN: "Invalid PIN",
    uploaded: "Uploaded",
    accepted: "Accepted",
    printContract: "Print Contract",
    formLabel:
      "I have read and understood the terms above. I hereby agree to the terms of this agreement.",
    enterPin: "Enter Your Numeric Pin",
    confirm: "Confirm and submit",
  },
  DashboardPage: {
    loading: "loading...",
    dashboard: "Dashboard",
    title: "You currently don't have a {{session}}.",
    check: "Check",
    pendingContracts: "pending contracts",
    contactUs: "contact us",
    noImages: "You do not have any images for this",
  },
  EventSearchPage: {
    title: "Search for shared events and laughs",
    urlTitle: "Event Search",
    p1: "To find an event, select one from the list or search by event code or event date.",
    date: "Event Date",
    code: "Private Code",
    public: "Public Event",
    search: "Search",
  },
  OrganizationSearchPage: {
    title: "Search for your individual student account",
    urlTitle: "Organization Search",
    p1: "Already have an account? Log in here to view your photos.",
    search: "Login",
  },
  FavoritesPage: {
    urlTitle: "Favorite:",
  },
  FoldersPage: {
    urlTitle: "Folder:",
    viewFull: "View full",
    noFoldersFound: "No folders found for this",
  },
  FormsPage: {
    urlTitle: "Forms:",
    title: "Forms",
    uploaded: "Uploaded",
  },
  GuestsPage: {
    urlTitle: "Guests:",
    title: "Guests",
    error: "There was an error updating guest invitation.",
    success: "Link successfully resent",
    copy: "Copied to your clipboard",
    createNew: "Create New",
    invitationLinks: "Invitation links",
    noLinksFound: "No links found for this",
    tableDescription: "Description",
    tableDateCreated: "Date created",
    tableDateFirstUsed: "Date first used",
    tableAction: "Action",
    copyLink: "Copy link",
    remove: "Remove",
    invitedTitle: "Invited guests",
    noGuestsFound: "No guests found for this",
    tableName: "Name",
    tableEmail: "Email",
    tableDateInvited: "Date invited",
    edit: "Edit",
    resend: "Resend",
    updateGuest: "Update guest",
    createNewGuestInvitation: "Create New Guest Invitation",
    generateText:
      "Generate a shareable link to give others access to your images. Share the link via social media, a text, or through email.",
    emailInvite: "Email invite",
    sharableLink: "Shareable link",
  },
  HelpPage: {
    urlTitle: "Help",
    title: "Help",
    ourInfo: "Our Information",
    hereToHelp: "We're here to help",
    chatBy: "Chat with us by",
    clickingHere: "clicking here",
  },

  HomePage: {
    signIn: "Sign In",
    eventSearch: "Event Search",
    organizationSearch: "Student",
    privacy: "Privacy Statement",
    userAccessAgreement: "User Access Agreement",
    termsOfUse: "Terms of Use",
    close: "Close",
  },
  OrderDetailsPage: {
    urlTitle: "Order",
    photo: "photo",
    setCrop: "Set crop and options",
    cropRequired: "Crop is required before checkout",
    imageRequired: "Image is required before checkout",
    compositeRequired: "Composite customization is required before checkout",
    viewOrders: "View orders",
    order: "Order",
    details: "details",
    orderDate: "Order Date",
    makePayment: "Make payment",
    printOrder: "Print Order",
    orderDetails: "Order details",
    tableProduct: "Product",
    tablePrice: "Price",
    tableQty: "Qty",
    tableTotal: "Total",
    subtotal: "Subtotal",
    promo: "Promo:",
    shipping: "Shipping",
    tax: "Tax",
    payment: "Payment",
    total: "Total",
    finalize: "Finalize selection",
    shippingInfo: "Shipping info",
    delivery: "Delivery",
    confirm: "Confirm Submission",
    cancel: "Cancel",
    warning: "This action cannot be undone in the app.",
    submitting: "Submitting...",
    yesConfirm: "Yes, confirm",
  },
  OrderHistoryPage: {
    tableOrderNumber: "Order number",
    tableOrderDate: "Order date",
    tableDateCreated: "Date created",
    tableShippedTo: "Shipped to",
    tableStatus: "Status",
    tableDescription: "Description",
    tableOpenBalance: "Open Balance",
    tableTotal: "Total",
    orders: "Orders",
    orderHistory: "Order history",
    preOrders: "Pre-orders",
    noOrders: "Currently you don't have any order yet.",
  },
  SharePage: {
    urlTitle: "This is the title page",
    title: "This is the title page",
    iframe: "This is an iframe blank page.",
    shareImage: "Share this image:",
  },
  SplashPage: {
    wereSorry: "We're sorry,",
    doesntSeemTo: "doesn't seem to be a valid share token.",
    error:
      "We're sorry, it looks like something went wrong. Please notify your site administrator if this continues.",
    subdomainError:
      "This app expects a subdomain. Please check the url and make sure it looks like <Your Company Name>.inspiredbyyou.com",
  },

  AddImageToGroupModalComponent: {
    addToFolder: "Add To Folder",
  },
  AddImageToShareModalComponent: {
    socialShare: "Social Share",
    generatingShareable: "Generating shareable url...",
  },
  cartViewComponent: {
    urlTitle: "Shopping Cart",
    title: "Shopping Cart",
    photo: "photo",
    swap: "Swap",
    youHave: "You have",
    youHaveAdded: "You have added",
    unitsRemaining: "units remaining to be added to your order.",
    unitsTooMany: "units too many to your order.",
    cartFullModalTitle: "Too Many Units",
    tooMany:
      "This order contains too many units.  Please remove some products before checking out.",
    have: "Have",
    another: "another",
    a: "a",
    ok: "Ok",
    promoCode: "promo/coupon code?",
    promoCode1: "Promo code",
    removePromoCode: "Remove promo code",
    select: "Select",
    loading: " loading",
    setCropAndOptions: "Set crop and options",
    cropRequired: "Crop is required before checkout",
    checkout: "Check out",
    tableAction: "Action",
    tableProductName: "Product name",
    tablePrice: "Price",
    tableQuantity: "Quantity",
    tableSubtotal: "Subtotal",
    enterNow: "Enter it now",
    apply: "Apply",
    cancel: "Cancel",
    promo: "Promo:",
    noItems: "No items in the cart.",
    checkOurProducts: "Check our products",
    continueShopping: "Continue shopping",
    mustReplaceItemModalTitle: "Must replace Item",
    mustReplaceItemModalBody:
      "There is at least one package component that must be replaced before checking out.",
    mustReplace: "This package component must be replaced.",
    usShippingOnly: "Currently, orders can only be shipped to US addresses.",
  },
  createOrderIndexComponent: {
    title: "Create an Order",
    step1cta: "Proceed",
    finish: "Finish",
  },
  cropViewComponent: {
    cropImage: "Crop Image",
    prepaid: "Prepaid",
    shoppingCart: "Shopping Cart",
    image: "Image",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    reset: "Reset",
    cropOrientation: "Crop Orientation",
    selectPhotoOption: "Select option(s)",
    pleaseSelect: "Please select...",
    selectPhotoEnhancement: "Select enhancement(s)",
    selectColorEffect: "Select color effect",
    notes: "Notes",
    apply: "Apply",
    backToPreorder: "Back to pre order",
    backToShoppingCart: "Back to shopping cart",
  },
  photoSelectComponent: {
    swapPhoto: "Swap Photo",
    prepaid: "Prepaid",
    shoppingCart: "Shopping Cart",
    backToPreorder: "Back to pre order",
    backToShoppingCart: "Back to shopping cart",
  },
  photoTileComponent: {
    image: "Image",
  },
  productGroupDetailComponent: {
    urlTitle: "Category:",
    title: "Category:",
    selectYourProduct: "Select your product",
    selectCateogry: "Select category",
    sortBy: "Sort by:",
    bestSelling: "Best selling",
    viewLarger: "View larger",
    viewDetails: "View details",
  },
  productGroupListComponent: {
    urlTitle: "Products",
    title: "Select your product category",
    viewTheseProducts: "View these products",
  },
  productDetailTabsComponent: {
    description: "Description",
    features: "Features",
    delivery: "Delivery",
    noDescriptionFound: "No Description Found",
    noFeatureFound: "No Feature Information Found",
    noDeliveryFound: "No Delivery Information Found",
  },
  singleProductDetailComponent: {
    ajaxError:
      "Error adding to cart. Refresh the page or report this product to our support.",
    product: "Product",
    cancelAndBack: "Cancel and back to listing",
    selectPhotoOption: "Select option(s)",
    pleaseSelect: "Please select...",
    selectPhotoEnhancement: "Select enahncement(s)",
    selectColorEffect: "Select color effect",
    was: "was",
  },
  addImageToGroupFormComponent: {
    selectFolder: "Select a Folder or create new",
  },
  addressFormSectionComponent: {
    firstName: "First Name",
    lastName: "Last Name",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    city: "City",
    region: "Region",
    state: "State",
    postalCode: "Postal code",
    zipCode: "ZIP Code",
    country: "Country",
  },
  addToCartFormComponent: {
    quantity: "Quantity",
  },
  forgotPasswordFormComponent: {
    emailValidationError: "Please enter your email address",
    email: "Email",
  },
  resetPasswordFormComponent: {
    passwordValidation: "Please confirm your new password",
    password: "Password",
    enterPassword: "Enter your new password",
    confirmPassword: "Confirm Password",
    setNewPassword: "Set New Password",
  },
  signupFormComponent: {
    firstNameValidation: "Please enter your first name",
    lastNameValidation: "Please enter your last name",
    invalidEmailValidation: "Please enter a valid email",
    emailRequiredValidation: "Please enter an email address",
    confirmPasswordValidation: "Please confirm your password",
    countryRequiredValidation: "Please select a country",
    passwordsMustMatch: "Passwords must match",
    confirm: "Confirm",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
  },
  billingAddressFormComponent: {
    firstNameValidation: "Please enter your first name",
    lastNameValidaiton: "Please enter your last name",
    addressLine1Validation: "Please enter your street address",
    cityValidation: "Please enter your city",
    stateValidation: "Please enter your state",
    postalCodeValidation: "Please enter your postal code",
    invalidPostalCode: "Please enter a valid postal code",
    countryValidation: "Please enter your country",
    emailValidation: "Please enter a valid email",
    billingInformation: "Billing information",
    selectSavedAddress: "Select saved address",
    email: "Email address",
    phoneNumber: "Phone number",
    saveAsDefault: "Save billing addres as my default",
    saveAsShipping: "Same as shipping address",
    promotionalTexts: "May we send you promotional texts?",
    dataRates: "(Data and message rates may apply)",
    promotionalEmails: "May we send you promotional emails?",
    continue: "Continue",
    continueShopping: "Continue shopping",
  },
  shippingSectionComponent: {
    usingSelectedAddress: "Using selected address",
    useDefaultAddress: "Use default address",
    useClientAddress: "Use client address",
    saveAsDefault: "Save as Default",
  },
  shippingAddressFormComponent: {
    shippingMethodValidation: "Please select a shipping method",
  },
  communicationFormComponent: {
    permissionCall: "May we call you with promotional information?",
    permissionEmail: "May we email you with promotional information?",
    permissionText:
      "May we text you with promotional information? (Messaging and data rates may apply)",
  },
  editAddressFormComponent: {
    name: "Name",
    phoneNumber: "Phone Number",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    city: "City",
    region: "Region",
    postalCode: "Postal code",
    country: "Country",
    deliveryInstructions: "Delivery Instructions",
    accessCode: "Access Code",
    defaultAddress: "Default Address",
    applyChange: "Apply Change",
  },
  editPasswordFormComponent: {
    enterPassword: "Please enter your password",
    passwordCannotBeSame: "New password cannot be the same as the old one",
    confirmPassword: "Please confirm your new password",
    passwordsMustMatch: "Passwords must match",
    existingPassword: "Existing Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
  },
  eventSearchFormComponent: {
    selectEvent: "Please select an event",
    selectEventDate: "Please select an event date",
    enterCode: "Please enter a code",
  },
  inputSectionComponent: {
    selectDate: "Select a date",
    placeCodeHere: "Place code here",
    input: "input",
    selectEvent: "Select an event",
  },
  emailGuestFormComponent: {
    favorites: "Favorites",
    ajaxError: "An error occurred creating guest invitation",
    invalidEmail: "Please enter a valid email",
    noEmail: "Please enter an email",
    noFirstName: "Please enter a first name",
    noLastName: "Please enter a last name",
    email: "Email Address",
    firstName: "First Name",
    lastName: "Last Name",
    session: "Session",
    image: "Image",
    sendEmail: "Send Email",
    onlyInclude: "Only include Favorites from this",
  },
  joyFormComponent: {
    firstName: "Child's First Name",
    age: "Child's Age",
    ageYear: "Year(s)",
    ageMonth: "Month(s)",
    country: "Select Country",
    permission:
      "Do we have your permission to share this image with the rest of the world on Anne Geddes’s Joy Page?",
    yes: "Yes",
    no: "No",
    oneImage: "- You may only upload one image per month.",
    warning:
      "- Please upload appropriate content only ensuring all private parts are covered!",
  },
  linkGuestFormComponent: {
    enterDescription: "Please enter a description for your link",
    description: "Description",
    onlyIncludeFavoritesFromThis: "Only include Favorites from this",
    createLink: "Create Link",
  },
  personalInfoComponent: {
    invalidEmail: "Please enter a valid email address",
    primaryPhone: "Primary phone",
    secondaryPhone: "Secondary phone",
    mobilePhone: "This is a mobile phone",
    streetAddress: "Street address",
    house: "House",
    apartment: "Apartment",
    roomNumber: "Room Number",
    city: "City",
    region: "Region",
    state: "State",
    postalCode: "Postal Code",
    country: "Country",
    pleaseSelect: "Please select...",
  },
  familyMemberComponent: {
    nickname: "Nickname",
    familyMember: "Family Member",
    relationship: "Relationship",
    gender: "Gender",
    BirthDate: "Birth Date",
    familyMemberSubtitle: "Family Members",
    manageFamilyText: "Add, Modify, and Remove Family Members",
    noFamilyMember: "You have not added any family members yet",
    tableAction: "Action",
  },
  usernameFormComponent: {
    enterUsername: "Please enter a new username",
    onlineUsername: "Online Username",
    username: "Username",
  },
  imgTileDropdownComponent: {
    moreNav: "More navigation items",
    download: "Download",
    removeFrom: "Remove from",
    modifyImage: "Modify Image",
    folders: "Folders",
    makePrimary: "Make Primary",
    yearbookImage: "Yearbook Image",
    makeSecondary: "Make Secondary",
    socialShare: "Social Share",
    joyShare: "Share on Anne's Joy Page",
    unhide: "Unhide",
    hide: "Hide",
  },
  imageTileComponent: {
    view: "View",
    sessions: "Sessions",
    setAsPrimary: "Set as primary photo",
    setAsSecondary: "Set as secondary photo",
    image: "Image",
    caution: "Caution",
    separateSessionsMessage1:
      "You are not able to add images from two separate",
    separateSessionsMessage2: "to the same cart",
    doNotAdd: "Do not add this item to my cart.",
    clearCart:
      "Clear the contents of my cart and then add this item to my cart.",
  },
  loginPromptComponent: {
    title: "Oops!",
    message:
      "Please --login-- or --sign up-- if you would like to download images, favorite images, group images together, or add images to cart.",
  },
  menuDropdownComponent: {
    dashboard: "Dashboard",
    sessions: "{{Sessions}}",
    favorites: "Favorites",
    contracts: "Contracts",
    orders: "Orders",
    prepaidOrders: "Prepaid Orders",
    guests: "Guests",
    account: "Account",
  },
  userDropdownComponent: {
    logout: "Log Out",
  },
  navBarPage: {
    toggleNavigation: "Toggle navigation",
    cart: "Cart",
    sessions: "{{Sessions}}",
    photoshootsAndImages: "Photoshoots and Images",
    contracts: "Contracts",
    reviewSubmit: "Review contracts and submit",
    forms: "Forms",
    reviewForms: "Review web forms and submit",
    orders: "Orders",
    historyPrepaid: "History and pre-paid orders",
    orderHistory: "Order History",
    products: "Products",
    ourStore: "Our Store",
    OurStoreSubText: "Order Special Products",
    guests: "Guests",
    categoriesProducts: "Categories and Products",
    sharedFamily: "Shared to family",
    friends: "friends",
    myAccount: "My Account",
    login: "Login",
    billingInfo: "billing info",
    help: "Help",
    chat: "Chat for online support",
    shoppingCart: "Shopping Cart",
    checkout: "Get ready to checkout",
    joy: "Community Joy Board",
    joySubtext: "Instructions in FAQs",
    blog: "Anne’s Blog",
    blogSubtext: "Updates from Anne",
    signout: "Sign out",
    endGuest: "End my guest",
    securelySignout: "Let me securely log out",
  },
  productGalleryComponent: {
    viewLarger: "View larger",
  },
  responsiveHeaderComponent: {
    photos: "photos",
    guests: "Guests",
    allImages: "All Images",
    favorites: "Favorites",
    folders: "Folders",
    autoPlay: "Autoplay",
    download: "Download",
    cart: "Cart",
  },

  sessionsPage: {
    SessionSubTitle: "Add, Modify, or Remove",
    NoSessionText: "You have not yet created any",
    Name: "Name",
    Date: "Date",
    CreateNewText: "Create New",
    SharedText: "Shared",
    ViewText: "View",
    DeleteText: "Delete",
    ConfirmDelete: "Yes, Delete",
    EditText: "Edit",
    BackTo: "Back to",
  },
  SubscriptionPage: {
    welcomeText:
      "Subscribe now to have access to the entire Anne Gedde’s community, including Anne’s Blog, Anne’s Joy Page™, and the ability to email questions to Anne’s team of experts. In addition, you have unlimited photo storage and sharing abilities on our secure and redundant servers for all your precious family memories!",
    welcomeSubtext:
      "Subscribing now will automatically charge your card on file $4.99 each month, starting today. Subscriptions automatically renew every months unless cancelled within 24 hours before the end of the current period.  You can cancel anytime by emailing the email address in the Help page.  Any unused portion of the free trial will be forfeited if you purchase a subscription. The next screen, after clicking Subscribe Now, will prompt you for the credit card information to be used for this subscription",
  },
};
