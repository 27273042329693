// @ts-nocheck
import React, { useEffect } from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import { Route } from 'react-router-dom'
import { Router } from "react-router";
import { Helmet } from "react-helmet";
import { observer, inject } from "mobx-react";
import i18n from "i18next";
import ReactGA from "react-ga";

import navigationStore from "../stores/navigationStore";

import SignupPage from "../pages/auth/signup";
import ForgotPasswordPage from "../pages/auth/forgotPassword";
import HomePage from "../pages/home/home";
import SplashPage from "../pages/splash/splashPage";
import DashboardPage from "../pages/dashboard/dashboard";
import ProtectedRoute from "../components/protected-route/protected-route";
import SessionsPage from "../pages/sessions/sessions";
import SessionsList from "../pages/sessions/sessionsList";
import FavoritesPage from "../pages/favorites/favorites";
import CheckoutPage from "../pages/checkout";
import LoginPrompt from "../components/loginPrompt/loginPrompt";
import ContractsPage from "../pages/contracts/contracts";
import FormsPage from "../pages/forms/forms";
import OrderHistoryPage from "../pages/orderHistory/orderHistory";
import OrderDetailsPage from "../pages/orderHistory/orderDetails";
import AccountPage from "../pages/account/account";
import GuestsPage from "../pages/guests/guests";
import HelpPage from "../pages/help/help";
import BlankPage from "../pages/blank";
import SharePage from "../pages/share";
import FoldersPage from "../pages/folders/folders";
import AddImageToGroupModal from "../components/addImageToGroupModal/addImageToGroupModal";
import AddImageToShareModal from "../components/addImageToShareModal";
import SubscriptionTrial from "../components/subscriptionTrial";
import SubscriptionExpired from "../components/subscriptionExpired";
import JoyPage from "../pages/joy/JoyPage";
import AddToCalendarPage from "../pages/addToCalendar/addToCalendar";
import BlogPost from "../pages/blogs";
import BlogPostDetails from "../pages/blogs/BlogPostDetails";
import Embed from "../pages/embed";
import GlobalStyle from "../components/styles";
import PrivacyStatementPage from "../components/legal/privacyPage";
import TermsOfUsePage from "../components/legal/termsOfUsePage";

const AppRouter: React.FC<any> = ({ authStore }) => {
  // useEffect(()=>{
  //   console.log(authStore.language);
  //   i18n.changeLanguage(authStore?.language, (err) => {
  //     if (err) return console.log('something went wrong loading', err)
  //   });
  // }, []);

  if (authStore.GATrackingID) {
    ReactGA.initialize(authStore.GATrackingID);
    navigationStore.history.listen(() => {
      const urlToTrack = `${
        authStore.GAExternalDomain ? `${authStore.GAExternalDomain}.` : ""
      }${window.location.host.split(".")[1]}${
        window.location.pathname + window.location.search
      }`;
      ReactGA.set({ page: urlToTrack });
      ReactGA.pageview(urlToTrack);
    });
  }

  useEffect(() => {
    if (authStore.GATrackingID) {
      ReactGA.pageview(
        `${authStore.GAExternalDomain ? `${authStore.GAExternalDomain}.` : ""}${
          window.location.host.split(".")[1]
        }${window.location.pathname + window.location.search}`
      );
    }
  }, [authStore.GAExternalDomain, authStore.GATrackingID]);

  useEffect(() => {
    async function fetchData() {
      var subdomain = window.location.host.split(".")[0];
      const hasSubdomain = subdomain !== window.location.host;

      if (hasSubdomain) {
        if (subdomain.indexOf("-") >= 0) {
          subdomain = subdomain.substring(0, subdomain.indexOf("-"));
        }
        await authStore.getCompanyInfo(subdomain).catch((e: any) => {
          console.log(e);
        });

        // await ClientStore.getClientInfo().catch((e:any) => {
        //   console.log(e);
        // })

        // await SessionStore.getAllSessionDetail().catch((e:any) => {
        //   console.log(e);
        // })
      }
    }
    fetchData();
    let urlLang = "";
    if (window.location.search.includes("lng")) {
      urlLang = window.location.search.split("=")[1];
    }
    i18n.changeLanguage(urlLang || authStore?.language, (err) => {
      if (err) return console.log("something went wrong loading", err);
    });
  }, [authStore]);

  if (
    authStore?.styleSettings &&
    authStore.styleSettings.NextButtonColor === "Transparent"
  ) {
    authStore.styleSettings.NextButtonColor = "rgba(0,0,0,0)";
  }
  if (
    authStore?.styleSettings &&
    authStore.styleSettings.BackButtonColor === "Transparent"
  ) {
    authStore.styleSettings.BackButtonColor = "rgba(0,0,0,0)";
  }
  return (
    <Router history={navigationStore.history}>
      <Helmet htmlAttributes={{ lang: authStore?.language }}>
        <title>
          {authStore.companyName || authStore.companyName !== ""
            ? authStore.companyName
            : "InspiredByYou.com"}
        </title>
      </Helmet>
      {authStore?.styleSettings && (
        <GlobalStyle {...authStore?.styleSettings} />
      )}

      <Switch>
        <Route exact path="/blank" component={BlankPage} />
        <Route exact path="/share" component={SharePage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/embed" component={Embed} />

        <Route exact path="/privacy-policy" component={PrivacyStatementPage} />
        <Route exact path="/terms-of-use" component={TermsOfUsePage} />

        <Route path="/home" component={HomePage} />
        <ProtectedRoute
          exact
          path="/subscription-trial"
          component={SubscriptionTrial}
        />
        <ProtectedRoute
          exact
          path="/subscription-expired"
          component={SubscriptionExpired}
        />
        <ProtectedRoute exact path="/dashboard" component={DashboardPage} />
        <ProtectedRoute exact path="/dashboard" component={DashboardPage} />
        <ProtectedRoute exact path="/dashboard" component={DashboardPage} />
        <ProtectedRoute exact path="/sessions" component={SessionsPage} />
        <ProtectedRoute exact path="/sessions-list" component={SessionsList} />
        <ProtectedRoute exact path="/favorites" component={FavoritesPage} />
        <ProtectedRoute path="/checkout" component={CheckoutPage} />
        <ProtectedRoute exact path="/contracts" component={ContractsPage} />
        <ProtectedRoute exact path="/forms" component={FormsPage} />
        <ProtectedRoute exact path="/orders" component={OrderHistoryPage} />
        <ProtectedRoute exact path="/joy" component={JoyPage} />
        <Route exact path="/addtocalendar" component={AddToCalendarPage} />
        <ProtectedRoute exact path="/blog" component={BlogPost} />

        <ProtectedRoute
          exact
          path="/blog-details"
          component={BlogPostDetails}
        />
        <ProtectedRoute
          exact
          path="/orders/details"
          component={OrderDetailsPage}
        />
        <ProtectedRoute exact path="/account" component={AccountPage} />
        <ProtectedRoute exact path="/guests" component={GuestsPage} />
        <ProtectedRoute exact path="/help" component={HelpPage} />
        <ProtectedRoute exact path="/folders" component={FoldersPage} />

        <Route
          exact
          path="/"
          render={(props) => (
            <SplashPage {...props} searchTerm={window.location.search} />
          )}
        />

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      {/* loginPrompt is here so we can use Link from react-router-dom */}
      <LoginPrompt />
      <AddImageToGroupModal />
      <AddImageToShareModal />
    </Router>
  );
};

export default inject("authStore")(observer(AppRouter));
