import React, { useEffect, useState, useCallback, useRef } from "react";
import "./responsiveHeader.scss";
import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import Sticky from "react-stickynode";
import { observer, inject } from "mobx-react";
import Select from "react-select";

import { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import {
  AiOutlineHeart,
  AiOutlineFolderOpen,
  AiOutlinePlayCircle,
  AiOutlineCloudDownload,
  AiOutlineShoppingCart,
  AiOutlineUsergroupAdd,
  AiFillHeart,
  AiOutlineDelete,
} from "react-icons/ai";

import {
  Nav,
  NavItem,
  Badge,
  Spinner,
  Col,
  Alert,
  Progress,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { isActiveRoute } from "../util";
import ProgessSpinner from "../spinner/spinner";
import { FiUpload, FiUploadCloud } from "react-icons/fi";

import {
  // Button,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";

const ResponsiveHeader: React.FC<any> = ({
  createOrderStore,
  sessionStore,
  ecomStore,
  lightboxStore,
  authStore,
  clientStore,
  navigationStore,
  loginPromptStore,
  globalErrorStore: globalMsg,
}) => {
  const [files, setFiles] = useState<any>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [files]
  );

  const thumbs = files.map((file: any) => (
    <div className="upload-preview-thumb" key={file.name}>
      <div className="upload-preview-thumbInner">
        <img src={file.preview} alt="Preview Image" />
      </div>
    </div>
  ));

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const myFiles = files.map((file: any) => (
    <li key={file.path}>
      {file.path}
      <span>
        <AiOutlineDelete
          size="18"
          className="text-danger"
          onClick={removeFile(file)}
        />
      </span>
    </li>
  ));

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg",
  });

  const { t } = useTranslation();
  const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);

  const [uploadFileForm, setuploadFileForm] = useState(false);

  const [imageUploadStatus, setimageUploadStatus] = useState<boolean>(false);

  const [showFailedMessage, setFailedMessage] = useState(false);

  const [reload, setReload] = useState(false);

  const [ProgressBar, setProgressBar] = useState(false);
  const [mobileSuccessAlert, setMobileSuccessAlert] = useState(false);
  const [DownloadImageProgressBar, setDownloadImageProgressBar] =
    useState(false);
  const [totalImageCount, setTotalImageCount] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentDownloadImageIndex, setCurrentDownloadImageIndex] = useState(0);
  const [downloadImageText, setDownloadImageText] = useState("");
  const downloadRef = useRef<any>(null);

  const readUploadedFileAsText = (inputFile: any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  // handle submit button for form
  async function handleSubmitform(e: any) {
    e.preventDefault();

    let imageFileNames: any;

    imageFileNames = [];
    setuploadFileForm(false);
    setProgressBar(true);

    setTotalImageCount(files.length);

    for (let i = 0; i < files.length; i++) {
      imageFileNames.push(files[i].name);
    }

    let payload = {
      imageCount: files.length,
      FileNames: imageFileNames,
    };

    let uploadJob = await sessionStore.initImageUpload(
      payload.imageCount,
      payload.FileNames
    );

    if (uploadJob) {
      for (var i = 0; i < files.length; i++) {
        setCurrentImageIndex(i + 1);
        let url =
          uploadJob.UploadUri + "/" + payload.FileNames[i] + uploadJob.SASToken;

        let fileContents: any;
        fileContents = await readUploadedFileAsText(files[i]);
        fileContents = fileContents.split(",")[1];

        let imgBuffer = Buffer.from(fileContents, "base64");

        sessionStore.putSessionImageUploadBlobStorage(
          url,
          imgBuffer,
          imgBuffer.length
        );
      }

      await sessionStore.completeImageUpload(uploadJob.UploadJobId);

      await sessionStore.refreshSessionDetail(sessionStore.activeSession.Key);

      setProgressBar(false);
      setuploadFileForm(false);
      setimageUploadStatus(true);

      setTimeout(() => {
        sessionStore.refreshSessionDetail(sessionStore.activeSession.Key);
        setimageUploadStatus(false);
      }, 9000);
    } else {
      setProgressBar(false);
      setFailedMessage(true);

      setTimeout(() => {
        setFailedMessage(false);
      }, 7000);
    }
  }
  // button state whether it's disabled or enabled
  const [enabled, setEnabled] = useState(false);

  // using useEffect we can detect if user uploaded any file,
  // so enable submit button
  useEffect(() => {
    if (files.length === 0) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [files]);

  function clearUploadForm() {
    setFiles([]);

    setReload(true);

    setTimeout(() => {
      setReload(false);
    }, 1);
  }

  function cartSelected() {
    lightboxStore.closeLightBox();
    createOrderStore.setStepNumber(4);
    createOrderStore.toggleSlider();
  }

  // const [showLoading, setShowLoading] = useState<boolean>(false);

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  async function downloadImages() {
    if (authStore.isGuestSession) {
      loginPromptStore.setShowLoginPrompt(true);
      return;
    }

    setDownloadImageProgressBar(true);
    let zip = new JSZip();
    let folder = zip.folder(sessionStore?.activeSession?.SessionNumber);

    for (let i = 0; i < sessionStore.activeSessionImages.length; i++) {
      if (
        downloadRef!.current!.attributes["data-abortdownload"].nodeValue ===
        "true"
      ) {
        downloadRef!.current!.attributes["data-abortdownload"].nodeValue =
          false;
        setDownloadImageProgressBar(false);
        return;
      }

      if (!sessionStore.activeSessionImages[i].allowDownload) {
        continue;
      }

      setCurrentDownloadImageIndex(i + 1);
      setDownloadImageText(`Preparing Image ${i + 1} /
      ${sessionStore.activeSessionImages.length}`);
      const imageBlob = await fetch(
        sessionStore.activeSessionImages[i].downloadURL
      ).then((response) => response.blob());
      const imageFile = new File(
        [imageBlob],
        sessionStore.activeSessionImages[i].fileName
      );
      folder?.file(sessionStore.activeSessionImages[i].fileName, imageFile);
    }

    if (sessionStore?.activeSession?.CopyrightReleaseUrl?.length) {
      const releaseBlob = await fetch(
        sessionStore?.activeSession?.CopyrightReleaseUrl
      ).then((response) => response.blob());
      const ext =
        sessionStore?.activeSession?.CopyrightReleaseUrl.split(".").slice(-1);
      const releaseFile = new File([releaseBlob], `CopyrightRelease.${ext}`);
      folder?.file(`CopyrightRelease.${ext}`, releaseFile);
    }
    setDownloadImageText("Files prepared. Your download will start shortly...");
    await folder?.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, sessionStore?.activeSession?.SessionNumber);
    });

    setTimeout(() => {
      setDownloadImageProgressBar(false);
    }, 5000);

    // CODE TO DOWNLOAD ON SERVER SIDE
    // const a: any = document.createElement("a");
    // a.href = sessionStore?.activeSession?.SessionDownloadUrl;
    // a.download = sessionStore?.activeSession?.SessionNumber;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }

  function downloadImagesMobile() {
    setShowDownloadSpinner(true);
    clientStore.downloadImage({}).then((res: any) => {
      globalMsg.setErrorMessage(res.DisplayMessage, "success");
      setShowDownloadSpinner(false);
      setMobileSuccessAlert(true);
      setTimeout(() => {
        setMobileSuccessAlert(false);
      }, 10000);
    });
  }

  const toggleUploadFileForm = (e: any) => {
    setuploadFileForm(!uploadFileForm);
    clearUploadForm();
    e.preventDefault();
  };

  useEffect(() => {
    if (!uploadFileForm) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [uploadFileForm]);

  useEffect(() => {
    //Check For ClientKey After Idle State
    if (!clientStore.clientKey && !authStore.token) {
      authStore.logout();
      navigationStore.push("/home/login");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${authStore.jobSingular}: ${
          sessionStore?.activeSession?.Description
        } ${
          authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "
        }`}</title>
      </Helmet>

      {ProgressBar && (
        <>
          <div className="full-page-overlay">
            <span>
              Uploading Images {currentImageIndex}/{totalImageCount}
            </span>
            <Progress
              color="primary"
              value={(currentImageIndex / totalImageCount) * 100}
            />
          </div>
        </>
      )}

      {mobileSuccessAlert && (
        <Toast className="download-images-mobile">
          <ToastBody>
            <div className="mb-2">
              An email will be sent to your email account shortly with a link to
              download your images. Please download your images from a computer
              and not a mobile nor tablet device.
            </div>
          </ToastBody>
        </Toast>
      )}

      {DownloadImageProgressBar && (
        <>
          <Toast className="download-progress-bar">
            <ToastHeader>Downloading Images</ToastHeader>
            <ToastBody>
              <div className="mb-2">{downloadImageText}</div>
              <Progress
                animated
                value={
                  (currentDownloadImageIndex /
                    sessionStore.activeSessionImages.length) *
                  100
                }
              />
              <Button
                style={{ fontSize: ".75rem" }}
                className="mt-3 p-1"
                color="danger"
                onClick={() => {
                  downloadRef!.current!.attributes[
                    "data-abortdownload"
                  ].nodeValue = true;
                  return;
                }}
              >
                Cancel
              </Button>
            </ToastBody>
          </Toast>
        </>
      )}

      <>
        <Sticky enabled={true} innerZ={8} top={isBreakDownSm ? -22 : 0}>
          <div
            ref={downloadRef}
            id="abortdownload"
            className="d-none"
            data-abortdownload="false"
          ></div>
          <section className="inner-section nav-session-container">
            <div className="row align-items-top">
              <div className="col-md-5 col-xl-6">
                <div className="session-title session-title-with-dropdown">
                  {sessionStore?.activeSession && (
                    <div className="nav-select-session session-title-header">
                      <Select
                        onChange={(val: any) =>
                          val
                            ? sessionStore.clientSelectSession(val.value)
                            : null
                        }
                        options={sessionStore.sessionsDropdown}
                        // isDisabled={
                        //   Object.keys(sessionStore.guestSession).length > 0
                        // }
                        placeholder={
                          sessionStore?.activeSession?.Description
                            ? sessionStore?.activeSession?.Description
                            : `Select ${authStore.jobSingular}`
                        }
                        isSearchable={false}
                      />
                    </div>
                  )}
                  <p className="meta session-info-details">
                    {!!sessionStore?.activeSession?.SessionDate ? (
                      <span>{sessionStore.activeSession.SessionDate}</span>
                    ) : (
                      <Skeleton width={250} />
                    )}
                    {!!sessionStore?.activeSessionImages?.length && (
                      <Badge color="primary" pill>
                        {sessionStore.activeSessionImages.length}{" "}
                        {t("responsiveHeaderComponent.photos")}
                      </Badge>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-xl-6 p-0 xs-padding">
                <div className="nav-session-wrapper">
                  <nav className="nav-session">
                    <Nav role="presentation">
                      {!authStore.isGuestSession && (
                        <NavItem>
                          <Link to="/guests" className="nav-link">
                            <AiOutlineUsergroupAdd size="28" />
                            <span className="nav-label">
                              {t("responsiveHeaderComponent.guests")}
                            </span>
                          </Link>
                        </NavItem>
                      )}

                      {isActiveRoute("/favorites") ? (
                        <NavItem>
                          <Link to="/sessions" className="nav-link active">
                            <AiFillHeart size="28" />
                            <span className="nav-label">
                              {t("responsiveHeaderComponent.allImages")}
                            </span>
                          </Link>
                        </NavItem>
                      ) : (
                        <NavItem>
                          <Link to="/favorites" className="nav-link">
                            <AiOutlineHeart size="28" />
                            <span className="nav-label">
                              {t("responsiveHeaderComponent.favorites")}
                            </span>
                          </Link>
                        </NavItem>
                      )}
                      <NavItem>
                        <Link
                          to="/folders"
                          className={`nav-link${
                            isActiveRoute("/folders") ? " active" : ""
                          }`}
                        >
                          <AiOutlineFolderOpen size="28" />
                          <span className="nav-label">
                            {t("responsiveHeaderComponent.folders")}
                          </span>
                        </Link>
                      </NavItem>
                      <NavItem onClick={() => lightboxStore.openPlayLightbox()}>
                        <span
                          style={{ cursor: "pointer" }}
                          className="nav-link"
                        >
                          <AiOutlinePlayCircle size="28" />
                          <span className="nav-label">
                            {t("responsiveHeaderComponent.autoPlay")}
                          </span>
                        </span>
                      </NavItem>
                      {sessionStore?.activeSession?.IsGuest ||
                      !sessionStore?.activeSession?.SessionDownloadUrl ? (
                        <></>
                      ) : (
                        <NavItem className="d-md-none">
                          <span
                            onClick={downloadImagesMobile}
                            style={{ cursor: "pointer" }}
                            className="nav-link"
                          >
                            {showDownloadSpinner && (
                              <Spinner size="sm" style={{ color: "#242424" }} />
                            )}
                            {!showDownloadSpinner && (
                              <>
                                <AiOutlineCloudDownload size="28" />
                                <span className="nav-label">
                                  {t("responsiveHeaderComponent.download")}
                                </span>
                              </>
                            )}
                          </span>
                        </NavItem>
                      )}
                      {sessionStore?.activeSession?.SessionDownloadUrl && (
                        <NavItem className="d-none d-md-block">
                          <span
                            onClick={downloadImages}
                            style={{ cursor: "pointer" }}
                            className="nav-link"
                          >
                            {showDownloadSpinner && <ProgessSpinner />}
                            <AiOutlineCloudDownload size="28" />
                            <span className="nav-label">
                              {t("responsiveHeaderComponent.download")}
                            </span>
                          </span>
                        </NavItem>
                      )}

                      <NavItem
                        className="d-none d-md-block"
                        onClick={cartSelected}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          className="nav-link"
                        >
                          <AiOutlineShoppingCart size="28" />
                          <span className="nav-label">
                            {t("responsiveHeaderComponent.cart")}
                          </span>
                          {!!ecomStore.cartItems.length && (
                            <Badge color="primary" pill>
                              {
                                ecomStore.cartItems.filter(
                                  (item: any) => !item.PackageItemKey
                                ).length
                              }
                            </Badge>
                          )}
                        </span>
                      </NavItem>
                    </Nav>
                  </nav>
                </div>

                <div className="display-grid-col">
                  <div className="new-album-button mt-4">
                    <>
                      {sessionStore?.activeSession?.IsGuest ? (
                        <></>
                      ) : (
                        authStore.allowImageUpload &&
                        authStore.accountName !== "life365" &&
                        clientStore?.clientInfo?.SessionCount > 0 && (
                          <>
                            <div className="position-col">
                              <Button
                                color="primary"
                                size="small"
                                className="custom-button-text white-text mb-3"
                                onClick={toggleUploadFileForm}
                              >
                                <span>
                                  <FiUpload size="25" />
                                </span>
                                Upload Images
                              </Button>
                            </div>
                          </>
                        )
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Sticky>
      </>

      {imageUploadStatus && (
        <div className="d-flex mt-4 justify-content-center">
          <Col xs="12" sm="6">
            <Alert color="success upload-status">
              Click Refresh Images Button to see your new images!
            </Alert>
          </Col>
        </div>
      )}

      {showFailedMessage && (
        <div className="d-flex mt-4 justify-content-center">
          <Col xs="12" sm="6">
            <Alert color="danger upload-status">Upload Failed!!</Alert>
          </Col>
        </div>
      )}

      {sessionStore.clientSelectionloading && (
        <div className="full-page-overlay">
          <div className="spinner-center">
            <Spinner color="primary" size="lg" />
          </div>
        </div>
      )}

      <Modal
        isOpen={uploadFileForm}
        toggle={toggleUploadFileForm}
        wrapClassName="modal-box upload-form-modal"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <div className="upload-form-modal-content">
          <ModalBody>
            <form onSubmit={handleSubmitform} className="upload--container">
              <div className="upload-icon">
                <p>Upload Images</p>
              </div>

              <div className="upload--button mb-4">
                {!reload && (
                  <>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <span className="icon-box">
                        {" "}
                        <FiUploadCloud size="35" />
                      </span>
                      <p className="desktop-text">
                        Drop and drop images here or click here to select images
                      </p>
                      <p className="mobile-text">
                        Click click here to select images
                      </p>
                    </div>

                    <aside className="file-list">
                      <h4>Images</h4>
                      <ul>{myFiles}</ul>
                    </aside>

                    <aside className="upload-preview-thumbsContainer">
                      {thumbs}
                    </aside>
                  </>
                )}
              </div>
              {enabled ? (
                <>
                  <div className="reset-images" onClick={clearUploadForm}>
                    <AiOutlineDelete className="text-danger" size="20" />
                    Remove All Images
                  </div>
                </>
              ) : (
                <></>
              )}

              {enabled ? (
                <Button type="submit" color="primary" block>
                  Upload
                </Button>
              ) : (
                <Button type="submit" color="primary" disabled block>
                  Upload
                </Button>
              )}
            </form>

            <Row className="mb-2 mt-4 align-items-center">
              <Col className="text-sm-right col-12 mb-3 mb-sm-0 order-sm-2">
                <Button
                  color="secondary text-truncate"
                  className="w-100"
                  onClick={toggleUploadFileForm}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
export default inject(
  "sessionStore",
  "ecomStore",
  "createOrderStore",
  "lightboxStore",
  "clientStore",
  "authStore",
  "globalErrorStore",
  "navigationStore",
  "loginPromptStore"
)(observer(ResponsiveHeader));
