import React, { useState, useRef } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";
import { Button, Progress, Toast, ToastBody, ToastHeader } from "reactstrap";

import { postVariants } from "../../components/util";
import RelatedProducts from "../../components/relatedProducts/relatedProducts";

const ConfirmationPage: React.FC<any> = ({
  authStore,
  ecomStore,
  sessionStore,
}) => {
  const { t } = useTranslation();
  const [DownloadImageProgressBar, setDownloadImageProgressBar] =
    useState(false);
  const [currentDownloadImageIndex, setCurrentDownloadImageIndex] = useState(0);
  const downloadRef = useRef<any>(null);
  const [downloadImageText, setDownloadImageText] = useState("");
  async function downloadImages() {
    setDownloadImageProgressBar(true);
    let zip = new JSZip();
    let folder = zip.folder(sessionStore?.activeSession?.SessionNumber);

    for (let i = 0; i < sessionStore.activeSessionImages.length; i++) {
      setCurrentDownloadImageIndex(i + 1);
      setDownloadImageText(`Preparing Image ${i + 1} /
      ${sessionStore.activeSessionImages.length}`);
      const imageBlob = await fetch(
        sessionStore.activeSessionImages[i].downloadURL
      ).then((response) => response.blob());
      const imageFile = new File(
        [imageBlob],
        sessionStore.activeSessionImages[i].fileName
      );
      folder?.file(sessionStore.activeSessionImages[i].fileName, imageFile);
    }

    if (sessionStore?.activeSession?.CopyrightReleaseUrl?.length) {
      const releaseBlob = await fetch(
        sessionStore?.activeSession?.CopyrightReleaseUrl
      ).then((response) => response.blob());
      const ext =
        sessionStore?.activeSession?.CopyrightReleaseUrl.split(".").slice(-1);
      const releaseFile = new File([releaseBlob], `CopyrightRelease.${ext}`);
      folder?.file(`CopyrightRelease.${ext}`, releaseFile);
    }
    setDownloadImageText("Files prepared. Your download will start shortly...");
    await folder?.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, sessionStore?.activeSession?.SessionNumber);
    });

    setTimeout(() => {
      setDownloadImageProgressBar(false);
    }, 5000);

    // CODE TO DOWNLOAD ON SERVER SIDE
    // const a: any = document.createElement("a");
    // a.href = sessionStore?.activeSession?.SessionDownloadUrl;
    // a.download = sessionStore?.activeSession?.SessionNumber;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }
  return (
    <>
      <Helmet>
        <title>
          {t("ConfirmationPage.tabTitle")} #
          {ecomStore?.successfulOrder?.InvoiceNumber
            ? ecomStore?.successfulOrder?.InvoiceNumber
            : "..."}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <div className="text-center mb-4 mt-5">
          <FiCheckCircle size="72" className="mb-5 text-primary" />
          <h1 className="h3">{t("ConfirmationPage.title")}</h1>
          {!!ecomStore?.successfulOrder && (
            <p className="pb-5">
              {t("ConfirmationPage.text")}{" "}
              <Link
                to={`/orders/details?id=${ecomStore.successfulOrder.Key}`}
                className="body-link"
              >
                {ecomStore?.successfulOrder.InvoiceNumber}
              </Link>
            </p>
          )}
        </div>
        {authStore?.accountName === "tpp" && (
          <div className="text-center">
            Click here to download your images
            <div>
              <Button className="mt-4" color="primary" onClick={downloadImages}>
                Download
              </Button>
            </div>
          </div>
        )}

        {DownloadImageProgressBar && (
          <>
            <div
              ref={downloadRef}
              id="abortdownload"
              className="d-none"
              data-abortdownload="false"
            ></div>
            <Toast className="download-progress-bar">
              <ToastHeader>Downloading Images</ToastHeader>
              <ToastBody>
                <div className="mb-2">{downloadImageText}</div>
                <Progress
                  animated
                  value={
                    (currentDownloadImageIndex /
                      sessionStore.activeSessionImages.length) *
                    100
                  }
                />
                {/* <Button
                  style={{ fontSize: ".75rem" }}
                  className="mt-3 p-1"
                  color="danger"
                  onClick={() => {
                    downloadRef!.current!.attributes[
                      "data-abortdownload"
                    ].nodeValue = true;
                    return;
                  }}
                >
                  Cancel
                </Button> */}
              </ToastBody>
            </Toast>
          </>
        )}
        <RelatedProducts title={t("ConfirmationPage.relatedProducts")} />
      </motion.div>
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "ecomStore",
  "sessionStore"
)(observer(ConfirmationPage));
