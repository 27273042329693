export default{

    "AccountPage": {
        "title": "Mein Konto",
        "loginSubtitle": "Login-Info",
        "loginText": "Angemeldet als",
        "editUsername": "Benutzername bearbeiten",
        "changePassword": "Passwort ändern",
        "personalInfoSubtitle": "Persönliche Informationen",
        "familyMemberSubtitle": "Familienmitglieder",
        "edit": "Bearbeiten",
        "delete": "Löschen",
        "default": "Standard",
        "paymentSubtitle": "Zahlung",
        "paymentText": "Speichern gespeicherter Kreditkarteninformationen",
        "communicationSubtitle": "Kommunikation",
        "communicationText": "Für Promotions und Newsletter Opt-Ins",
        "shippingSubtitle": "Versandadressen",
        "shippingText": "Verwendung für die Kasse als Versandinformationen",
        "upcomingSubtitle": "Bevorstehende",
        "upcomingText": "Reterminieren oder Abbrechen bestätigen",
        "noUpcomingText": "Sie haben keine anstehenden Sitzungen",
        "tableDate": "Datum",
        "tableTime": "Zeit",
        "tableDescription": "Beschreibung",
        "tableAction": "Aktion",
        "confirm": "Bestätigen",
        "reschedule": "Neu planen",
        "cancel": "Abbrechen",
        "scheduleButton": "Schedule Now",
        "deleteAddressButton": "Möchten Sie diese Adresse wirklich löschen?",
        "updateAddressModalTitle": "Update-Adresse",
        "updateUsernameModalTitle": "Benutzername aktualisieren",
        "updatePasswordModalTitle": "Passwort ändern",
        "updatePersonalInfoModalTitle": "Persönliche Informationen aktualisieren",
        "updateCommunicationModalTitle": "Update-Opt-Ins",
        "updatePaymentModalTitle": "Zahlung",
        "scheduleModalTitle": "Zeitplan",
        "rescheduleModalTitle": "Neu planen",
        "clickhere": "Klicken Sie hier",
    },
    "ForgotPasswordPage": {
        "title": "Passwort vergessen",
        "subtitle": "Eine E-Mail mit weiteren Anweisungen wird an Ihre E-Mail-Adresse gesendet.",
        "alertSuccess": "Sie sollten in Kürze eine E-Mail erhalten. Bitte überprüfen Sie Ihren Junk- oder Spam-E-Mail-Ordner, wenn Sie die E-Mail nicht finden können.",
        "backToLogin": "Zurück zum Login",
    },
    "LoginPage": {
        "title": "Melden Sie sich an, um tolle Bilder zu sehen, die von Ihnen inspiriert wurden!",
        "description": "Wenn Sie bereits ein Konto hier haben, um Ihre Bilder anzuzeigen, oder klicken Sie oben auf \"Ereignissuche\", um nach einem Ereignis zu suchen.",
        "email": "E-Mail-Adresse oder Benutzername",
        "password": "Passwort",
        "forgotPass": "Passwort vergessen",
        "emailError": "Bitte geben Sie eine E-Mail-Adresse oder einen Benutzernamen ein",
        "ajaxError": "Ungültiger Benutzername oder Kennwort",
        "passwordError": "Bitte geben Sie ein Passwort ein",
        "loginButton": "Login",
    },
    "ResetPasswordPage": {
        "title": "Geben Sie Ihr neues Passwort ein",
        "alertSuccess": "Ihr Kennwort wurde erfolgreich zurückgesetzt. Sie werden zurück zur Anmeldeseite weitergeleitet.",
        "backToLogin": "Zurück zum Login",
    },
    "SignupPage": {
        "title": "Erstellen eines Kontos",
        "p1": "Sie haben bereits ein Konto?",
        "p2": ", um Ihre Fotos anzuzeigen.",
        "createAccountLink": "Melden Sie sich hier an",
        "email": "E-Mail",
        "password": "Passwort",
        "signup": "Anmelden",
        "ajaxError": "Oops etwas ging schief. Bitte versuchen Sie es erneut.",
        "ajaxErrorOther": "Oops etwas ging schief. Bitte versuchen Sie es erneut.",
    },
    "ConfirmationPage": {
        "tabTitle": "Vielen Dank",
        "title": "Vielen Dank für Ihre Bestellung.",
        "text": "Ihre Bestellnummer ist",
        "relatedProducts": "Sie könnten sich für...",
    },
    "CheckoutPage": {
        "urlTitle": "Sichere Kasse",
        "title": "Sichere Kasse",
        "subtitle": "4096-Bit SSL/TSL-geschützte Seite",
    },
    "OrderReviewPage": {
        "urlTitle": "Sichere Kasse",
        "tableProductName": "Produktname",
        "tablePrice": "Preis",
        "tableQuantity": "Menge",
        "tableSubtotal": "Zwischensumme",
        "enter": "Geben Sie es jetzt ein",
        "apply": "Gelten",
        "cancel": "Abbrechen",
        "promo": "Promo",
        "shipping": "Versand",
        "tax": "Steuer",
        "total": "Insgesamt",
    },
    "PaymentPage": {
        "urlTitle": "Sichere Kasse",
    },
    "ShippingAddressPage": {
        "urlTitle": "Sichere Kasse",
    },
    "ContractsPage": {
        "urlTitle": "Verträge",
        "title": "Verträge",
        "invalidPIN": "Ungültige PIN",
        "uploaded": "Hochgeladen",
        "accepted": "Akzeptiert",
        "printContract": "Druckvertrag",
        "formLabel": "Ich habe die obigen Begriffe gelesen und verstanden. Ich stimme hiermit den Bedingungen dieses Abkommens zu.",
        "enterPin": "Geben Sie Ihren numerischen Pin ein",
        "confirm": "Bestätigen und einreichen",
    },
    "DashboardPage": {
        "loading": "Werden geladen...",
        "dashboard": "Dashboard",
        "title": "Sie haben derzeit keine Sitzung.",
        "check": "Überprüfen Sie",
        "pendingContracts": "ausstehende Verträge",
        "contactUs": "kontaktieren Sie uns",
        "noImages": "Sie haben keine Bilder dafür",
    },
    "EventSearchPage": {
        "title": "Suche nach gemeinsam genutzten Veranstaltungen und lacht",
        "urlTitle": "Ereignissuche",
        "p1": "Um ein Ereignis zu finden, wählen Sie eines aus der Liste aus oder suchen Sie nach Ereigniscode oder Ereignisdatum.",
        "date": "Datum des Ereignisses",
        "code": "Privater Code",
        "public": "Öffentliche Veranstaltung",
        "search": "'Suche'",
    },
    "FavoritesPage": {
        "urlTitle": "Lieblings-",
    },
    "FoldersPage": {
        "urlTitle": "Ordner",
        "viewFull": "Ansicht voll anzeigen",
        "noFoldersFound": "Es wurden keine Ordner dafür gefunden.",
    },
    "FormsPage": {
        "urlTitle": "Formen",
        "title": "Formen",
        "uploaded": "Hochgeladen",
    },
    "GuestsPage": {
        "urlTitle": "Gäste",
        "title": "Gäste",
        "error": "Fehler beim Aktualisieren der Gasteinladung.",
        "success": "Link erfolgreich resent",
        "copy": "In die Zwischenablage kopiert",
        "createNew": "Neu erstellen",
        "invitationLinks": "Einladungslinks",
        "noLinksFound": "Es wurden keine Links hierfür gefunden",
        "tableDescription": "Beschreibung",
        "tableDateCreated": "Erstelltes Datum",
        "tableDateFirstUsed": "Datum der ersten Verwendung",
        "tableAction": "Aktion",
        "copyLink": "Link kopieren",
        "remove": "Entfernen",
        "invitedTitle": "Eingeladene Gäste",
        "noGuestsFound": "Keine Gäste dafür gefunden",
        "tableName": "Name",
        "tableEmail": "E-Mail",
        "tableDateInvited": "Datum eingeladen",
        "edit": "Bearbeiten",
        "resend": "Senden",
        "updateGuest": "Update-Gast",
        "createNewGuestInvitation": "Neue Gasteinladung erstellen",
        "generateText": "Generieren Sie einen gemeinsam nutzenden Link, um anderen Zugriff auf Ihre Bilder zu gewähren. Teilen Sie den Link über soziale Medien einen Text oder per E-Mail.",
        "emailInvite": "E-Mail-Einladung",
        "sharableLink": "Gemeinsames Link",
    },
    "HelpPage": {
        "urlTitle": "Hilfe",
        "title": "Hilfe",
        "ourInfo": "Unsere Informationen",
        "hereToHelp": "Wir sind hier, um zu helfen",
        "chatBy": "Chatten Sie mit uns von",
        "clickingHere": "klicken Sie hier",
    },
    "HomePage": {
        "signIn": "Anmelden",
        "eventSearch": "Ereignissuche",
        "privacy": "Datenschutzerklärung",
        "userAccessAgreement": "Benutzerzugriffsvereinbarung",
        "termsOfUse": "Nutzungsbedingungen",
        "close": "In der Nähe",
    },
    "OrderDetailsPage": {
        "urlTitle": "Bestellung",
        "photo": "Foto",
        "setCrop": "Festlegen von Zuschneide- und Optionen",
        "cropRequired": "Zuschneiden ist vor dem Check-out erforderlich",
        "viewOrders": "Bestellungen anzeigen",
        "order": "Bestellung",
        "details": "Details",
        "orderDate": "Bestelldatum",
        "makePayment": "Bezahlen",
        "printOrder": "Druckreihenfolge",
        "orderDetails": "Bestelldaten",
        "tableProduct": "Produkt",
        "tablePrice": "Preis",
        "tableQty": "Meng",
        "tableTotal": "Insgesamt",
        "subtotal": "Zwischensumme",
        "promo": "Promo",
        "shipping": "Versand",
        "tax": "Steuer",
        "payment": "Zahlung",
        "total": "Insgesamt",
        "finalize": "Auswahl abschließen",
        "shippingInfo": "Lieferinfo",
        "delivery": "Lieferung",
        "confirm": "Bestätigen der Einreichung",
        "cancel": "Abbrechen",
        "warning": "Diese Aktion kann in der App nicht rückgängig gemacht werden.",
        "submitting": "Einreichung...",
        "yesConfirm": "Ja bestätigen",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Bestell-Nr.",
        "tableOrderDate": "Bestelldatum",
        "tableDateCreated": "Erstelltes Datum",
        "tableShippedTo": "Versand an",
        "tableStatus": "Status",
        "tableDescription": "Beschreibung",
        "tableOpenBalance": "Offener Saldo",
        "tableTotal": "Insgesamt",
        "orders": "Bestellungen",
        "orderHistory": "Bestellhistorie",
        "preOrders": "Vorbestellungen",
        "noOrders": "Derzeit haben Sie noch keine Bestellung.",
    },
    "SharePage": {
        "urlTitle": "Dies ist die Titelseite",
        "title": "Dies ist die Titelseite",
        "iframe": "Dies ist eine leere iframe-Seite.",
        "shareImage": "Dieses Bild teilen",
    },
    "SplashPage": {
        "wereSorry": "Es tut uns leid",
        "doesntSeemTo": "scheint kein gültiges Freigabetoken zu sein.",
        "error": "Es tut uns leid, dass es so aussieht, als wäre etwas schief gelaufen. Bitte benachrichtigen Sie Ihren Siteadministrator, wenn dies weiterhin der Zuteil besteht.",
        "subdomainError": "Diese App erwartet eine Subdomain. Bitte überprüfen Sie die URL und stellen Sie sicher, dass sie aussieht wie \"Ihr Firmenname>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Hinzufügen zum Ordner",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Sozialer Anteil",
        "generatingShareable": "Freigabe-URL wird generiert...",
    },
    "cartViewComponent": {
        "urlTitle": "Warenkorb",
        "title": "Warenkorb",
        "photo": "Foto",
        "swap": "Swap",
        "youHave": "Sie haben",
        "youHaveAdded": "Sie haben",
        "unitsRemaining": "Einheiten, die Ihrer Bestellung noch hinzugefügt werden müssen.",
        "unitsTooMany": "Einheiten zu viele zu Ihrer Bestellung.",
        "cartFullModalTitle": "Zu viele Einheiten",
        "tooMany": "Dieser Auftrag enthält zu viele Einheiten.  Bitte entfernen Sie einige Produkte vor dem Auschecken.",
        "have": "Haben",
        "another": "anderer",
        "a": "Eine",
        "ok": "Okay",
        "promoCode": "Promo-/Gutscheincode?",
        "promoCode1": "Promo-Code",
        "removePromoCode": "Entfernen von Promo-Code",
        "select": "Wählen Sie",
        "loading": "Laden",
        "setCropAndOptions": "Festlegen von Zuschneide- und Optionen",
        "cropRequired": "Zuschneiden ist vor dem Check-out erforderlich",
        "checkout": "Abreise",
        "tableAction": "Aktion",
        "tableProductName": "Produktname",
        "tablePrice": "Preis",
        "tableQuantity": "Menge",
        "tableSubtotal": "Zwischensumme",
        "enterNow": "Geben Sie es jetzt ein",
        "apply": "Gelten",
        "cancel": "Abbrechen",
        "promo": "Promo",
        "noItems": "Keine Artikel im Warenkorb.",
        "checkOurProducts": "Überprüfen Sie unsere Produkte",
        "continueShopping": "Weiter einkaufen",
    },
    "createOrderIndexComponent": {
        "title": "Erstellen eines Auftrags",
        "step1cta": "Fortfahren",
        "finish": "Fertig stellen",
    },
    "cropViewComponent": {
        "cropImage": "Bild zuschneiden",
        "prepaid": "Prepaid",
        "shoppingCart": "Warenkorb",
        "image": "Bild",
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "reset": "Zurücksetzen",
        "cropOrientation": "Crop Orientation",
        "selectPhotoOption": "Fotooption auswählen",
        "pleaseSelect": "Bitte wählen Sie...",
        "selectPhotoEnhancement": "Fotoverbesserung auswählen",
        "selectColorEffect": "Farbeffekt auswählen",
        "notes": "Notizen",
        "apply": "Gelten",
        "backToPreorder": "Zurück zur Vorbestellung",
        "backToShoppingCart": "Zurück zum Warenkorb",
    },
    "photoSelectComponent": {
        "swapPhoto": "Swap Foto",
        "prepaid": "Prepaid",
        "shoppingCart": "Warenkorb",
        "backToPreorder": "Zurück zur Vorbestellung",
        "backToShoppingCart": "Zurück zum Warenkorb",
    },
    "photoTileComponent": {
        "image": "Bild",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Kategorie",
        "title": "Kategorie",
        "selectYourProduct": "Wählen Sie Ihr Produkt",
        "selectCateogry": "Kategorie auswählen",
        "sortBy": "Sortieren nach",
        "bestSelling": "Best verkauft",
        "viewLarger": "Ansicht größer",
        "viewDetails": "Details anzeigen",
    },
    "productGroupListComponent": {
        "urlTitle": "Produkte",
        "title": "Wählen Sie Ihre Produktkategorie",
        "viewTheseProducts": "Sehen Sie sich diese Produkte an",
    },
    "productDetailTabsComponent": {
        "description": "Beschreibung",
        "features": "Funktionen",
        "delivery": "Lieferung",
        "noDescriptionFound": "Keine Beschreibung gefunden",
        "noFeatureFound": "Keine Feature-Informationen gefunden",
        "noDeliveryFound": "Keine Lieferinformationen gefunden",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Fehler beim Hinzufügen zum Warenkorb. Aktualisieren Sie die Seite oder melden Sie dieses Produkt an unseren Support.",
        "product": "Produkt",
        "cancelAndBack": "Abbrechen und zurück zum Angebot",
        "selectPhotoOption": "Fotooption auswählen",
        "pleaseSelect": "Bitte wählen Sie...",
        "selectPhotoEnhancement": "Foto-Verlockung auswählen",
        "selectColorEffect": "Farbeffekt auswählen",
        "was": "War",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Wählen Sie einen Ordner aus oder erstellen Sie neue",
    },
    "addressFormSectionComponent": {
        "firstName": "Vorname",
        "lastName": "Nachname",
        "addressLine1": "Adresszeile 1",
        "addressLine2": "Adresszeile 2",
        "city": "Stadt",
        "region": "Region",
        "state": "Zustand",
        "postalCode": "Postleitzahl",
        "zipCode": "PLZ",
        "country": "Land",
    },
    "addToCartFormComponent": {
        "quantity": "Menge",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Bitte geben Sie Ihre E-Mail-Adresse ein",
        "email": "E-Mail",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Bitte bestätigen Sie Ihr neues Passwort",
        "password": "Passwort",
        "enterPassword": "Geben Sie Ihr neues Passwort ein",
        "confirmPassword": "Bestätigen Sie Kennwort:",
        "setNewPassword": "Neues Passwort festlegen",
    },
    "signupFormComponent": {
        "firstNameValidation": "Bitte geben Sie Ihren Vornamen ein",
        "lastNameValidation": "Bitte geben Sie Ihren Nachnamen ein",
        "invalidEmailValidation": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        "emailRequiredValidation": "Bitte geben Sie eine E-Mail-Adresse ein",
        "confirmPasswordValidation": "Bitte bestätigen Sie Ihr Passwort",
        "countryRequiredValidation": "Bitte wählen Sie ein Land",
        "passwordsMustMatch": "Kennwörter müssen übereinstimmen",
        "confirm": "Bestätigen",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "email": "E-Mail-Adresse",
        "password": "Passwort",
        "confirmPassword": "Bestätigen Sie Kennwort:",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Bitte geben Sie Ihren Vornamen ein",
        "lastNameValidaiton": "Bitte geben Sie Ihren Nachnamen ein",
        "addressLine1Validation": "Bitte geben Sie Ihre Adresse ein",
        "cityValidation": "Bitte geben Sie Ihre Stadt ein",
        "stateValidation": "Bitte geben Sie Ihren Zustand ein",
        "postalCodeValidation": "Bitte geben Sie Ihre Postleitzahl ein",
        "invalidPostalCode": "Bitte geben Sie eine gültige Postleitzahl ein",
        "countryValidation": "Bitte geben Sie Ihr Land ein",
        "emailValidation": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        "billingInformation": "Abrechnungsinformationen",
        "selectSavedAddress": "Gespeicherte Adresse auswählen",
        "email": "E-Mail-Adresse",
        "phoneNumber": "Telefonnummer",
        "saveAsDefault": "Speichern von Abrechnungs-Addres als Standard",
        "saveAsShipping": "Gleiches gilt für die Lieferadresse",
        "promotionalTexts": "Dürfen wir Ihnen Werbetexte zusenden?",
        "dataRates": "(Daten- und Nachrichtenraten können gelten)",
        "promotionalEmails": "Dürfen wir Ihnen Werbe-E-Mails senden?",
        "continue": "Weiter",
        "continueShopping": "Weiter einkaufen",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Verwenden ausgewählter Adresse",
        "useDefaultAddress": "Verwenden der Standardadresse",
        "useClientAddress": "Clientadresse verwenden",
        "saveAsDefault": "Als Standard speichern",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Bitte wählen Sie eine Versandart",
    },
    "communicationFormComponent": {
        "permissionCall": "Dürfen wir Sie mit Werbeinformationen anrufen?",
        "permissionEmail": "Dürfen wir Ihnen eine E-Mail mit Werbeinformationen senden?",
        "permissionText": "Dürfen wir Ihnen Werbeinformationen mit werbefördernden Informationen zuschreiben? (Messaging- und Datenraten können gelten)",
    },
    "editAddressFormComponent": {
        "name": "Name",
        "phoneNumber": "Telefonnummer",
        "addressLine1": "Adresszeile 1",
        "addressLine2": "Adresszeile 2",
        "city": "Stadt",
        "region": "Region",
        "postalCode": "Postleitzahl",
        "country": "Land",
        "deliveryInstructions": "Lieferanleitung",
        "accessCode": "Zugangscode",
        "defaultAddress": "Standardadresse",
        "applyChange": "Anwenden von Änderungen",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Bitte geben Sie Ihr Passwort ein",
        "passwordCannotBeSame": "Das neue Kennwort kann nicht mit dem alten übereinstimmen",
        "confirmPassword": "Bitte bestätigen Sie Ihr neues Passwort",
        "passwordsMustMatch": "Kennwörter müssen übereinstimmen",
        "existingPassword": "Vorhandenes Kennwort",
        "currentPassword": "Aktuelles Passwort",
        "newPassword": "Neues Passwort",
        "confirmNewPassword": "Neues Kennwort bestätigen",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Bitte wählen Sie ein Ereignis aus",
        "selectEventDate": "Bitte wählen Sie ein Ereignisdatum aus",
        "enterCode": "Bitte geben Sie einen Code ein",
    },
    "inputSectionComponent": {
        "selectDate": "Wählen Sie ein Datum",
        "placeCodeHere": "Platzieren Sie den Code hier",
        "input": "Eingabe",
        "selectEvent": "Auswählen eines Ereignisses",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoriten",
        "ajaxError": "Beim Erstellen einer Gasteinladung ist ein Fehler aufgetreten.",
        "invalidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        "noEmail": "Bitte geben Sie eine E-Mail-Adresse ein",
        "noFirstName": "Bitte geben Sie einen Vornamen",
        "noLastName": "Bitte geben Sie einen Nachnamen ein",
        "email": "E-Mail-Adresse",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "session": "Sitzung",
        "image": "Bild",
        "sendEmail": "E-Mail senden",
        "onlyInclude": "Nur Favoriten aus diesem",
    },
    "joyFormComponent": {
        "ageMonth": "Monat(e)",
        "yes": "Ja",
        "no": "Nein",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Bitte geben Sie eine Beschreibung für Ihren Link ein",
        "description": "Beschreibung",
        "onlyIncludeFavoritesFromThis": "Nur Favoriten aus diesem",
        "createLink": "Link erstellen",
    },
    "personalInfoComponent": {
        "invalidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        "primaryPhone": "Primäre Telefon",
        "secondaryPhone": "Sekundäres Telefon",
        "mobilePhone": "Dies ist ein Mobiltelefon",
        "streetAddress": "Straßenadresse",
        "house": "Haus",
        "apartment": "Wohnung",
        "roomNumber": "Zimmernummer",
        "city": "Stadt",
        "region": "Region",
        "state": "Zustand",
        "postalCode": "Postleitzahl",
        "country": "Land",
        "pleaseSelect": "Bitte wählen Sie...",
    },
    "familyMemberComponent": {
        "nickname": "Spitzname",
        "familyMember": "Familienmitglied",
        "relationship": "Beziehung",
        "gender": "Geschlecht",
        "BirthDate": "Geburtsdatum",
        "familyMemberSubtitle": "Familienmitglieder",
        "tableAction": "Aktion",
    },
    "usernameFormComponent": {
        "enterUsername": "Bitte geben Sie einen neuen Benutzernamen ein",
        "onlineUsername": "Online-Benutzername",
        "username": "Benutzername",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Weitere Navigationselemente",
        "download": "Herunterladen",
        "removeFrom": "Entfernen von",
        "modifyImage": "Bild ändern",
        "folders": "Ordner",
        "makePrimary": "Machen Sie Primär",
        "yearbookImage": "Jahrbuch Bild",
        "makeSecondary": "Make Secondary",
        "socialShare": "Sozialer Anteil",
        "unhide": "Einblenden",
        "hide": "Ausblenden",
    },
    "imageTileComponent": {
        "view": "Blick",
        "sessions": "Sitzungen",
        "setAsPrimary": "Als primäres Foto festlegen",
        "setAsSecondary": "Als sekundäres Foto eingestellt",
        "image": "Bild",
        "caution": "Vorsicht",
        "separateSessionsMessage1": "Sie können keine Bilder von zwei separaten",
        "separateSessionsMessage2": "auf den gleichen Warenkorb",
        "doNotAdd": "Fügen Sie diesen Artikel nicht in meinen Warenkorb.",
        "clearCart": "Löschen Sie den Inhalt meines Warenkorbs, und fügen Sie diesen Artikel dann in meinen Warenkorb.",
    },
    "loginPromptComponent": {
        "title": "Oops!",
        "message": "Bitte --login-- oder --sign up-- wenn Sie Bilder, die Bilder gruppieren möchten, zusammen gruppieren oder Bilder in den Warenkorb legen möchten.",
    },
    "menuDropdownComponent": {
        "dashboard": "Dashboard",
        "sessions": "Sitzungen",
        "favorites": "Favoriten",
        "contracts": "Verträge",
        "orders": "Bestellungen",
        "prepaidOrders": "Prepaid-Bestellungen",
        "guests": "Gäste",
        "account": "Konto",
    },
    "userDropdownComponent": {
        "logout": "Abmelden",
    },
    "navBarPage": {
        "toggleNavigation": "Umschalten der Navigation",
        "cart": "Warenkorb",
        "photoshootsAndImages": "Fotoshootings und Bilder",
        "contracts": "Verträge",
        "reviewSubmit": "Verträge überprüfen und einreichen",
        "forms": "Formen",
        "reviewForms": "Webformulare überprüfen und einreichen",
        "orders": "Bestellungen",
        "historyPrepaid": "Geschichte und Prepaid-Bestellungen",
        "orderHistory": "Bestellhistorie",
        "products": "Produkte",
        "guests": "Gäste",
        "categoriesProducts": "Kategorien und Produkte",
        "sharedFamily": "Gemeinsam mit der Familie",
        "friends": "Freunde",
        "myAccount": "Mein Konto",
        "login": "Login",
        "billingInfo": "Abrechnungsinformationen",
        "help": "Hilfe",
        "chat": "Chat für Online-Support",
        "shoppingCart": "Warenkorb",
        "checkout": "Machen Sie sich bereit für die Kasse",
        "signout": "Abmelden",
        "endGuest": "Beenden Sie meinen Gast",
        "securelySignout": "Lassen Sie mich sicher abmelden",
    },
    "productGalleryComponent": {
        "viewLarger": "Ansicht größer",
    },
    "responsiveHeaderComponent": {
        "photos": "Fotos",
        "guests": "Gäste",
        "allImages": "Alle Bilder",
        "favorites": "Favoriten",
        "folders": "Ordner",
        "autoPlay": "Autoplay",
        "download": "Herunterladen",
        "cart": "Warenkorb",
    },
    "sessionsPage": {
        "Name": "Name",
        "Date": "Datum",
        "CreateNewText": "Neu erstellen",
        "ViewText": "Blick",
        "DeleteText": "Löschen",
        "EditText": "Bearbeiten",
    },
}
