import React, { useState } from "react";
import SignupForm, {
  SignupFormData,
} from "../../components/forms/auth/signupForm";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import { postVariants } from "../../components/util";

const SignupPage: React.FC<any> = ({
  authStore,
  navigationStore,
  clientStore,
  guestStore,
}) => {
  const { t } = useTranslation();
  const [showSuccessMsg, setshowSuccessMsg] = useState<boolean>(false);

  const redirectToWelcomeScreen = () => {
    clientStore.getClientInfo();

    const checkTrialDate = () => {
      let today = new Date().toISOString().split(".")[0];

      console.log(Date.parse(today));

      if (Date.parse(clientStore.statusDueDate) >= Date.parse(today)) {
        return true;
      } else {
        return false;
      }
    };

    const isTrialSubscription = checkTrialDate();

    console.log("Date", clientStore.statusDueDate);

    console.log("Trial", isTrialSubscription);

    console.log("Expiry", !isTrialSubscription);

    //clientStore.getClientInfo();

    //Guest Free Account Flow

    if (clientStore.statusDescription === "Free") {
      navigationStore.push("/account");
      return;
    }

    /* Welcome Trial Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      isTrialSubscription
    ) {
      navigationStore.push("/subscription-trial");
      return;
    }

    /* Trial Expired Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      !isTrialSubscription
    ) {
      navigationStore.push("/subscription-expired");
      return;
    } else {
      navigationStore.push("/dashboard");
    }
  };

  const clientStatus = () => {
    if (authStore.fetchToken.length > 0) {
      console.log(authStore.fetchToken.length);
      return "Free";
    } else {
      return "Trial";
    }
  };

  let ClentstatusDescription = clientStatus();

  let trailDateFinder = new Date(
    new Date().getTime() + 30 * 24 * 60 * 60 * 1000
  );

  let ClentstatusDueDate = trailDateFinder.toISOString().split(".")[0];

  function onSubmit(obj: SignupFormData) {
    if (authStore.privateLabel.toLowerCase() === "annegeddes") {
      obj.statusDescription = ClentstatusDescription;
      obj.statusDueDate = ClentstatusDueDate;
    }

    authStore.createAccount(obj).then((res: any) => {
      if (res) {
        setshowSuccessMsg(true);
        setTimeout(function () {
          redirectToWelcomeScreen();
        }, 1000);
      }
    });
  }

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={postVariants}
    >
      <h1>{t("SignupPage.title")}</h1>
      <p>
        {t("SignupPage.p1")}{" "}
        <Link to={"/home/login"}>{t("SignupPage.createAccountLink")}</Link>{" "}
        {t("SignupPage.p2")}
      </p>
      {showSuccessMsg && (
        <div className="alert alert-success" role="alert">
          Account successfully created.
        </div>
      )}

      <SignupForm
        firstNameLabel={t("SignupPage.firstName")}
        lastNameLabel={t("SignupPage.lastName")}
        emailLabel={t("SignupPage.email")}
        country={t("SignupPage.Country")}
        passwordLabel={t("SignupPage.password")}
        ajaxError={authStore.signupError ? t("SignupPage.ajaxError") : ""}
        ajaxErrorOther={
          authStore.signupErrorOther ? t("SignupPage.ajaxErrorOther") : ""
        }
        onSubmit={onSubmit}
      />
      <Helmet>
        <title>
          {t("SignupPage.signup")} -{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? authStore.companyName
            : "InspiredByYou.com"}
        </title>
      </Helmet>
    </motion.div>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "guestStore",
  "clientStore"
)(observer(SignupPage));
