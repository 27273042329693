import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { XMasonry, XBlock } from "react-xmasonry";
import { useMediaQuery } from "react-responsive";
import { Waypoint } from "react-waypoint";
import { Spinner } from "reactstrap";

import ImageTile from "../imageTile/imageTile";

const ImageTileList: React.FC<any> = ({
  images,
  children,
  groupName = "",
  reload = true,
  favorites = false,
  authStore,
}) => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [isEndOfPage, setEndOfPage] = useState(false);
  const [listOnDisplay, setListOnDisplay] = useState([] as any);

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const isBreakDownXs = useMediaQuery({
    query: "(max-width: 479px)",
  });

  useEffect(() => {
    const startItemsTotal = isBreakDownSm ? 25 : 50;
    setTotalItems(images.length);
    setItemsPerPage(startItemsTotal);

    const iniDisplay: any[] = [];
    images.slice(0, startItemsTotal).map((img: any, i: number) => {
      iniDisplay.push(img);
    });

    setListOnDisplay([...iniDisplay]);
  }, []);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
    const itemsDisplayed = (currentPage + 1) * itemsPerPage;
    const iniDisplay: any[] = [];
    images
      .slice(itemsDisplayed - itemsPerPage, itemsDisplayed)
      .map((img: any, i: number) => {
        iniDisplay.push(img);
      });

    setListOnDisplay([...listOnDisplay, ...iniDisplay]);

    if (currentPage * itemsPerPage >= totalItems - itemsPerPage) {
      setEndOfPage(true);
    }
  };

  const gridDisplay = listOnDisplay.map((img: any, i: number) => {
    return (
      <XBlock key={`sessionGrid${i}${img.fileName}`}>
        <div className="pads">
          <ImageTile
            images={images}
            groupName={groupName}
            key={`imgTileList${i}${img.fileName}`}
            img={img}
            index={i}
            favorites={favorites}
          />
        </div>
      </XBlock>
    );
  });

  return (
    <>
      <XMasonry
        center={false}
        updateOnImagesLoad
        targetBlockWidth={
          isBreakDownXs || authStore.privateLabel === "picturepeople"
            ? 200
            : 300
        }
      >
        {gridDisplay}
      </XMasonry>

      <Waypoint onEnter={() => handleLoadMore()} bottomOffset={"-100px"}>
        {!isEndOfPage ? (
          <div
            style={{ paddingTop: 50, paddingBottom: 50, textAlign: "center" }}
          >
            <Spinner color="primary" />
          </div>
        ) : (
          <div></div>
        )}
      </Waypoint>

      {children}
    </>
  );
};

export default inject("authStore")(observer(ImageTileList));
