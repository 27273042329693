import React from 'react';
import { Helmet } from "react-helmet";
import { observer,inject } from 'mobx-react'
import { useTranslation } from 'react-i18next';

import SocialShare from '../../components/socialShare';

const SharePage: React.FC<any> = ({sessionStore, authStore, imageShareStore}) => {
  const { t } = useTranslation();
  return (
    <>
    <Helmet>
      <title>{t('SharePage.urlTitle')} | {authStore.companyName}</title>
    </Helmet>

    <article className="container-fluid text-center py-5" style={{maxWidth: 600}}>
      <header>
        <h1>{t('SharePage.title')}</h1>
      </header>
      <figure className="my-4">
        <img src={imageShareStore.selectedImage.preview} alt="" />
        <figcaption className="mt-2">{t('SharePage.iframe')}</figcaption>
      </figure>
      <footer>
        <p className="h5">{t('SharePage.shareImage')}</p>
        <SocialShare className="mt-4" imgFilename={imageShareStore.selectedImage.filename} imgUrl={imageShareStore.selectedImage.preview} />
      </footer>
    </article>
    </>
  );
}

export default inject(
  'sessionStore', 
  'authStore', 
  'imageShareStore',
  )(observer(SharePage))
