import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import ShippingAddressForm from "../../components/forms/checkout/shippingAddress/shippingAddressForm";

const ShippingAddressPage: React.FC<any> = ({
  authStore,
  clientStore,
  ecomStore,
  navigationStore,
  globalErrorStore: err,
  sessionStore,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!!!ecomStore.shippingMethods?.length) {
      ecomStore.getAllShippingMethods();
    }
  }, []);

  async function updateCart(obj: any) {
    return await ecomStore
      .updateCartMeta(obj)
      .catch((error: any) => console.log("there was an err", error));
  }

  function handleDefault(shipping_method: string) {
    return updateCart({
      ShippingAddressLine1: ecomStore.defaultShippingAddress.Line1,
      ShippingAddressLine2: ecomStore.defaultShippingAddress.Line2,
      ShippingAddressCity: ecomStore.defaultShippingAddress.City,
      ShippingAddressState: ecomStore.defaultShippingAddress.State,
      ShippingAddressPostalCode: ecomStore.defaultShippingAddress.Postcode,
      ShippingAddressCountry: ecomStore.defaultShippingAddress.Country,
      ShippingAddressName: ecomStore.defaultShippingAddress.Name,
      ShippingMethodKey: shipping_method,
    });
  }

  async function handleCartForClientAddress(shipping_method: string) {
    return updateCart({
      ShippingAddressLine1: clientStore.clientInfo.AddressLine1,
      ShippingAddressLine2: clientStore.clientInfo.AddressLine2,
      ShippingAddressCity: clientStore.clientInfo.AddressCity,
      ShippingAddressState: clientStore.clientInfo.AddressState,
      ShippingAddressPostalCode: clientStore.clientInfo.AddressPostcode,
      ShippingAddressCountry: clientStore.clientInfo.AddressCountry,
      ShippingAddressName: `${clientStore.clientInfo.FirstName} ${clientStore.clientInfo.LastName}`,
      ShippingMethodKey: shipping_method,
    });
  }

  async function onSubmit(obj: any) {
    // default address
    if (obj.same_as_default) {
      handleDefault(obj.shipping_method);
      return navigationStore.push("/checkout/orderreview");
    }
    // client address accounts for default
    if (obj.same_as_client) {
      // update cart with client address
      handleCartForClientAddress(obj.shipping_method);
      //save address
      ecomStore.setShippingAddress({
        Country: obj.country,
        Line1: obj.street_1,
        Line2: obj.street_2,
        Postcode: obj.zip,
        State: obj.state,
        City: obj.city,
        Default: true,
      });
      return navigationStore.push("/checkout/orderreview");
    }

    updateCart({
      ShippingAddressLine1: obj.street_1,
      ShippingAddressLine2: obj.street_2,
      ShippingAddressCity: obj.city,
      ShippingAddressState: obj.state,
      ShippingAddressPostalCode: obj.zip,
      ShippingAddressCountry: obj.country,
      ShippingAddressName: `${obj.first_name} ${obj.last_name}`,
      ShippingMethodKey: obj.shipping_method,
    });

    ecomStore.setShippingAddress({
      Key: obj.selected_address_key,
      Country: obj.country,
      Line1: obj.street_1,
      Line2: obj.street_2,
      Postcode: obj.zip,
      State: obj.state,
      City: obj.city,
      Name: `${obj.first_name} ${obj.last_name}`,
      Default: obj.save_address_as_default,
    });
    return navigationStore.push("/checkout/orderreview");
  }

  function onCancel() {
    navigationStore.replace("/sessions");
  }

  return (
    <>
      <Helmet>
        <title>
          {t("ShippingAddressPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <ShippingAddressForm onSubmit={onSubmit} onCancel={onCancel} />
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "clientStore",
  "ecomStore"
)(observer(ShippingAddressPage));
