import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import './index.scss'
import { observer, inject } from "mobx-react";
import { FiX } from "react-icons/fi";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AnimatePresence } from "framer-motion";

import useWindowDimensions from "../util/getDimensions";
import ProductGroupList from "./productGroupList/productGroupList";
import ProductGroupDetail from "./productGroupDetail/productGroupDetail";
import SingleProductDetail from "./singleProductDetail/singleProductDetail";
import CartView from "../cart/cartView";
import PhotoSelect from "./photoSelect/photoSelect";
import CropPhoto from "./cropView/cropView";
import CompositeView from "./compositeView";
import ReplaceItem from "./replaceItem/ReplaceItem";

const CreateOrderFlowComponent: React.FC<any> = ({
  createOrderStore,
  ecomStore,
}) => {
  const { t } = useTranslation();
  const [header, showHeader] = useState(true);
  const { innerHeight } = useWindowDimensions();

  useEffect(() => {
    if (createOrderStore.showSlider) {
      document.documentElement.classList.add("modal-open");
    } else {
      document.documentElement.classList.remove("modal-open");
    }
  }, [createOrderStore.showSlider]);

  useEffect(() => {
    showHeader(
      createOrderStore.stepNumber === 5 ||
        createOrderStore.stepNumber === 6 ||
        createOrderStore.stepNumber === 7
        ? false
        : true
    );
    document
      .getElementById("modal_body_123123123")
      ?.scrollIntoView({ behavior: "smooth" });
  }, [createOrderStore.stepNumber]);

  const toggle = () => createOrderStore.toggleSlider();
  const closeWindow = () => {
    toggle();
    ecomStore.cancelSelectedImage();
  };

  function addToCart() {
    createOrderStore.setStepNumber(1);
    createOrderStore.toggleSlider();
  }

  const closeBtn = (
    <Button color="text" className="close" onClick={closeWindow}>
      <FiX size="24" />
    </Button>
  );

  return (
    <Modal
      onClosed={() => {
        ecomStore.setOptions([]);
      }}
      isOpen={createOrderStore.showSlider}
      toggle={toggle}
      wrapClassName={`createOrderWrapper${
        createOrderStore.stepNumber === 7 ? " modal-fullwidth-open" : ""
      }`}
    >
      <ModalHeader
        toggle={toggle}
        close={closeBtn}
        className={!header ? "d-none" : ""}
      >
        <span className="sr-only">{t("createOrder.title")}</span>
      </ModalHeader>
      <ModalBody style={{ height: innerHeight }} id="scrollElement">
        <AnimatePresence exitBeforeEnter>
          {(() => {
            switch (createOrderStore.stepNumber) {
              case 1:
                return <ProductGroupList />;
              case 2:
                return <ProductGroupDetail />;
              case 3:
                return <SingleProductDetail />;
              case 4:
                return <CartView />;
              case 5:
                return <PhotoSelect />;
              case 6:
                return <CropPhoto />;
              case 7:
                return <CompositeView />;
              case 8:
                return <ReplaceItem />;
            }
          })()}
        </AnimatePresence>
      </ModalBody>
      <ModalFooter className="d-none">
        {createOrderStore.stepNumber >= 2 && (
          <Button
            color="secondary"
            onClick={() => createOrderStore.decrementStep()}
          >
            go back
          </Button>
        )}
        {createOrderStore.stepNumber < 4 && (
          <Button
            color="primary"
            onClick={() => createOrderStore.incrementStep()}
          >
            {t("createOrderIndexComponent.step1cta")}
          </Button>
        )}
        {createOrderStore.stepNumber === 4 && (
          <Button color="primary" onClick={addToCart}>
            {t("createOrderIndexComponent.finish")}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default inject(
  "createOrderStore",
  "ecomStore"
)(observer(CreateOrderFlowComponent));
