import React from 'react';
import './imgTileButton.scss';

const ImageTileButton: React.FC<any> = ({children, onClick, className}) => {
  // const {t} = useTranslation();


  return (
        <button onClick = {onClick} className ={`btn btn-link btn-icon${className ? ` ` + className : ``}`}>
            {children}
        </button>
  );
}
export default ImageTileButton;