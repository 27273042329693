import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Row,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { password } from '../../util';
import { useTranslation } from 'react-i18next';



const PasswordForm: React.FC<any> = ({ clientStore, onSuccess}) => {
    const { t } = useTranslation();

    async function updateClient(obj: any) {
        let body = {
            OnlinePassword:obj.NewPassword,
            ClientPassword:obj.ExistingPassword
        };
        await clientStore.updateClient(body, {ClientPassword:obj.ExistingPassword})
        if(!!!clientStore.error){
            onSuccess();
        }
    }
    
    const validationSchema =
        Yup.object().shape({
            ExistingPassword: Yup.string()
                .required(t('editPasswordFormComponent.enterPassword')),
            NewPassword: 
                password()
                .test('passwords-not-match', t('editPasswordFormComponent.passwordCannotBeSame'), function(value) {
                    return this.parent.ExistingPassword !== value;
                  }),
            ConfirmNewPassword: Yup.string()
                .required(t('editPasswordFormComponent.confirmPassword'))
                .test('passwords-match', t('editPasswordFormComponent.passwordsMustMatch'), function(value) {
                    return this.parent.NewPassword === value;
                  }),
        })

    const initialValues = {
        ExistingPassword:'',
        NewPassword: '',
        ConfirmNewPassword: '',
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={updateClient}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                isSubmitting
                /* and other goodies */
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="row-sm">
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('editPasswordFormComponent.existingPassword')}</Label>
                                <Field
                                    placeholder={t('editPasswordFormComponent.currentPassword')}
                                    name="ExistingPassword"
                                    type = "password"

                                    className = {`form-control ${errors.ExistingPassword?'is-invalid':''}`}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name = "ExistingPassword">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('editPasswordFormComponent.newPassword')}</Label>
                                <Field
                                    placeholder={t('editPasswordFormComponent.newPassword')}
                                    name="NewPassword"
                                    type = "password"
                                    className = {`form-control ${errors.NewPassword?'is-invalid':''}`}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name = "NewPassword">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>                        
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('editPasswordFormComponent.confirmNewPassword')}</Label>
                                <Field
                                    placeholder={t('editPasswordFormComponent.confirmNewPassword')}
                                    name="ConfirmNewPassword"
                                    type = "password"
                                    className = {`form-control ${errors.ConfirmNewPassword?'is-invalid':''}`}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name = "ConfirmNewPassword">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                            </Row>

                        {clientStore.error &&
                            <Alert color = "danger">{clientStore.error}</Alert>
                        }
                        <Button 
                          type="submit" 
                          color="primary" 
                          block 
                          className={isSubmitting ? 'wait' : ''}
                          disabled={isSubmitting}
                        >
                          {t('editAddressFormComponent.applyChange')}
                          {isSubmitting && <Spinner color = '#fff' size="sm" /> }
                        </Button>
                    </Form>
                )}
        </Formik>
    );

}
export default inject('clientStore')(observer(PasswordForm))
