import { observable, action, computed } from "mobx";
import axios, { AxiosResponse } from "axios";
import { create, persist } from "mobx-persist";
import ClientStore from "./clientStore";
import setAxiosDefaults from "../components/axios-config";
import EventStore from "./eventStore";
import SessionStore from "./sessionStore";
import ImageGroupStore from "./imageGroupStore";
import EcomStore from "./ecomStore";
import navigationStore from "./navigationStore";
import { guestAuthParams } from "../interfaces/auth.int";
import GuestStore from "./guestStore";
export class AuthStoreClass {
  @persist @observable versionNumber: string = "2.0.5";
  @persist @observable logoUrl: string = "";
  @persist @observable backgroundUrl: string = "";
  @persist @observable backgroundMobileUrl: string = "";
  @persist @observable accountName: string = "";
  @persist @observable isAnneGeddes: boolean = false;
  @persist @observable token: string = "";
  @persist @observable companyName: string = "";
  @persist @observable GATrackingID: string = "";
  @persist @observable GAExternalDomain: string = "";
  @persist @observable helpHtml: string = "";
  @persist @observable language: string = "";
  @persist @observable chatJs: string = "";
  @persist @observable chatUrl: string = "";
  @persist @observable jobSingular: string = "";
  @persist @observable jobPlural: string = "";
  @persist @observable addLine1: string = "";
  @persist @observable addLine2: string = "";
  @persist @observable addLine3: string = "";
  @persist @observable website: string = "";
  @persist @observable email: string = "";
  @persist @observable phone: string = "";
  @persist @observable country: string = "";
  @persist @observable SubscriptionPlanMaintKey: string = "";
  @persist @observable requirePaymentOnline: boolean = true;
  @persist @observable disableEventSearch: boolean = false;
  @persist @observable enableOrganizationSearch: boolean = false;
  @persist @observable disableSignUpTab: boolean = true;
  @persist @observable allowSocialShare: boolean = true;
  @persist @observable EnableCompositeTemplates: boolean = false;
  @persist @observable enableScheduleNow: boolean = false;
  @persist("object") @observable styleSettings: any = {};
  @persist @observable loaded: boolean = false;
  @persist @observable userflowCreateAccount: boolean = false;
  @persist @observable isGuestSession: boolean = false;
  @persist @observable hidefamilyMembers: boolean = false;
  @persist @observable hideUpcomingSessions: boolean = false;
  @persist @observable verificationPagePrompt: boolean = false;

  //Subscription Modules Data
  @persist @observable requirePaidSubscription: boolean = true;
  @persist @observable freeTrialDays: any;
  @persist @observable offerFreeTrial: boolean = true;
  @persist @observable monthlySubscriptionAmountFormatted: any;

  // if user is guest, then creates an account or logs in
  @persist @observable redirectUrl: string = "";
  @persist @observable requireGuestLogin: string = "";
  @persist @observable fetchToken: string = "";
  @persist @observable allowImageUpload: boolean = true;
  @persist @observable allowNewSessions: boolean = true;
  @persist @observable hideInvoiceDetailNotes: boolean = false;
  @persist @observable privateLabel: string = "";
  @persist @observable environmentLabel: string = "";
  @persist @observable AffirmEnabledOnline: boolean = false;
  @persist @observable AffirmPublicAPIKey: string = "";
  @persist("list") @observable organizations: Array<any> = [];
  @persist @observable VerificationPageDisplayMainEmail = false;
  @persist @observable VerificationPageDisplayNewPassword = false;
  @persist @observable VerificationPageDisplayMainPhone = false;
  @persist @observable VerificationPageDisplayName = false;
  @persist @observable VerificationPagePrompt = false;
  @observable isLoading = false;
  @observable isLoggingIn = false;
  @observable hydrated: boolean = false;
  @observable loginError: boolean = false;
  @observable signupError: boolean = false;
  @observable updateHeader: any;
  @persist("object") @observable signupResponse: any = {};

  @action async getCompanyInfo(accountName: string) {
    this.isLoading = true;
    // if (!this.loaded) {
    //   // console.log('clearing localstorage');
    //   localStorage.clear();
    // }

    this.accountName = accountName;
    if (
      accountName.toLowerCase() === "annegeddes" ||
      accountName.toLowerCase() === "annegeddestest"
    ) {
      this.isAnneGeddes = true;
    }
    const url = `${process.env.REACT_APP_BASE_URL}/${accountName}/ibyCompanyInfo`;
    this.environmentLabel = process.env.REACT_APP_ENVIRONMENT_LABEL || "";
    if (this.environmentLabel != "") {
      console.log("Environment: " + this.environmentLabel);
    }
    return await axios
      .get(url, {
        headers: {
          app_source: "StudioPlus.IBY_2.0",
          api_key: process.env.REACT_APP_GET_COMPANY_INFO_API_KEY,
        },
      })
      .then((res: AxiosResponse) => {
        this.companyName =
          res.data?.CompanyName || res.data?.CompanyName !== ""
            ? res.data.CompanyName
            : "";
        this.GATrackingID =
          res.data?.GATrackingID || res.data?.GATrackingID !== ""
            ? res.data.GATrackingID
            : "";
        this.GAExternalDomain =
          res.data?.GAExternalDomain || res.data?.GAExternalDomain !== ""
            ? res.data.GAExternalDomain
            : "";
        this.logoUrl =
          res.data?.LogoUrl || res.data?.LogoUrl !== "" ? res.data.LogoUrl : "";
        this.backgroundUrl = res.data?.BackgroundImageUrl;
        this.backgroundMobileUrl = res.data?.BackgroundImageUrlMobile;
        this.helpHtml = res.data.HelpPageHtml;
        this.language = res.data?.DefaultLanguage
          ? res.data?.DefaultLanguage
          : "en";
        this.chatJs = res.data.ChatJavascript;
        this.chatUrl = res.data?.ChatUrl;
        this.jobSingular =
          res.data?.JobDescriptorSingular ||
          res.data?.JobDescriptorSingular !== ""
            ? res.data.JobDescriptorSingular
            : "Session";
        this.jobPlural =
          res.data?.JobDescriptorPlural || res.data?.JobDescriptorPlural !== ""
            ? res.data.JobDescriptorPlural
            : "Sessions";
        this.addLine1 = res.data?.AddressLine1;
        this.addLine2 = res.data?.AddressLine2;
        this.addLine3 = res.data?.AddressLine3;
        this.website = res.data?.CompanyWebsiteUrl;
        this.email =
          res.data?.Email || res.data?.Email !== "" ? res.data?.Email : "";
        this.phone = res.data?.PhoneNumber;
        this.styleSettings = { ...res.data.CustomStyleSettings };
        this.requirePaymentOnline = res.data.RequirePaymentOnline;
        this.allowSocialShare = res.data.AllowSocialShare;
        this.country = res.data.Country;
        this.disableEventSearch = res.data.HideEventSearchTab;
        this.disableSignUpTab = res.data.HideSignUpTab;
        this.enableScheduleNow = res.data.EnableScheduleNow;
        this.hidefamilyMembers = res.data.HideFamilyMembers;
        this.hideUpcomingSessions = res.data.HideUpcomingSessions;
        this.requirePaidSubscription = res.data.RequirePaidSubscription;
        this.freeTrialDays = res.data.FreeTrialDays;
        this.offerFreeTrial = res.data.OfferFreeTrial;
        this.requireGuestLogin = res.data.RequireGuestLogin;
        this.monthlySubscriptionAmountFormatted =
          res.data.MonthlySubscriptionAmountFormatted;
        this.hideInvoiceDetailNotes = res.data.HideInvoiceDetailNotes;
        this.allowImageUpload = res.data.AllowImageUpload;
        this.allowNewSessions = res.data.AllowNewSessions;
        this.SubscriptionPlanMaintKey = res.data.SubscriptionPlanMaintKey;
        this.privateLabel =
          res.data.PrivateLabel !== "" ? res.data.PrivateLabel : "";
        this.AffirmEnabledOnline = res.data.AffirmEnabledOnline;
        this.AffirmPublicAPIKey = res.data.AffirmPublicAPIKey;
        this.verificationPagePrompt = res.data.VerificationPagePrompt;
        // this.enableOrganizationSearch = res.data.EnableOrganizationSearch;
        this.enableOrganizationSearch = res.data.EnableOrganizationSearch;
        this.organizations = res.data.Organizations;
        this.VerificationPageDisplayMainEmail =
          res.data.VerificationPageDisplayMainEmail;
        this.VerificationPageDisplayMainPhone =
          res.data.VerificationPageDisplayMainPhone;
        this.VerificationPageDisplayName = res.data.VerificationPageDisplayName;
        this.VerificationPageDisplayNewPassword =
          res.data.VerificationPageDisplayNewPassword;
        this.VerificationPagePrompt = res.data.VerificationPagePrompt;

        // Enable composites if set on company info object or query param included.
        if (res.data.EnableCompositeTemplates) {
          this.EnableCompositeTemplates = true;
        }

        setAxiosDefaults(this.accountName, this.token);
        if (!this.disableEventSearch) {
          EventStore.getAllPublicEcomEvents();
        }
        this.loaded = true;
        this.isLoading = false;
        return res.data;
      });
  }

  goToPaymentPortal(SubscriptionPlanMaintKey: string) {
    this.signupResponse.PaymentUrl =
      this.signupResponse.PaymentUrl +
      // `&redirectUrl=${window.location.origin}/subscription-trial?signupMaintKey=${SubscriptionPlanMaintKey}`;
      `&redirectUrl=${
        window.location.href.split("?")[0]
      }?signupMaintKey=${SubscriptionPlanMaintKey}`;

    window.location.href = this.signupResponse.PaymentUrl;
  }

  @action async signup(SubscriptionPlanMaintKey: string, bearerToken?: string) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Signup",
      data: {
        SubscriptionPlanMaintKey,
      },
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }).then((res: AxiosResponse) => {
      this.signupResponse = res.data;
      if (
        this.signupResponse.PaymentUrl.length > 0 &&
        !this.signupResponse.ConfirmationHtml
      ) {
        this.goToPaymentPortal(SubscriptionPlanMaintKey);
      }
    });
  }

  @action forgotPassword(body: any) {
    const data = { ...body };
    return axios({
      method: "PUT",
      url: `/ibyForgotPassword`,
      data: data,
    });
  }

  @action async guestAuthentication(params: guestAuthParams) {
    this.isLoggingIn = true;
    return axios({
      method: "GET",
      url: `/ibyAuthSession`,
      params: { ...params },
    })
      .then(async (res: AxiosResponse) => {
        this.isLoggingIn = false;
        this.isGuestSession = true;
        await this.authenticatedCallback(res.data);
        return res.data;
      })
      .catch(() => {
        this.isLoggingIn = false;
      });
  }
  @action async logout() {
    this.redirectUrl = "";
    this.token = "";
    this.loaded = false;
    this.isLoggingIn = false;
    this.fetchToken = "";
    ClientStore.onLogout();
    SessionStore.onLogout();
    ImageGroupStore.onLogout();
    EcomStore.onLogout();
    navigationStore.push("/home/login");
    localStorage.clear();
  }

  @action async login(loginRequest: any) {
    this.isLoading = true;
    this.isLoggingIn = true;
    const clientKey = axios.defaults.params["clientKey"];
    const sessionKey = axios.defaults.params["sessionKey"];
    delete axios.defaults.params["clientKey"];
    delete axios.defaults.params["sessionKey"];
    return axios({
      method: "GET",
      url: `/ibyAuthClient`,
      params: {
        username: loginRequest.email,
        clientLoginToken: loginRequest.clientLoginToken,
      },
      headers: {
        clientpassword: loginRequest.password,
      },
    })
      .then(async (res: AxiosResponse) => {
        axios.defaults.params["clientKey"] = clientKey;
        axios.defaults.params["sessionKey"] = sessionKey;
        this.isGuestSession = false;
        await this.authenticatedCallback(res.data); // Existing Dynamic Bearer Token;
        let defaultSessionKey = loginRequest.defaultSessionKey;

        if (this.fetchToken) {
          await ClientStore.putClientSessionAccess(this.fetchToken);
          defaultSessionKey = ClientStore.defaultSessionKey;
        }

        const client = await ClientStore.getClientInfo(defaultSessionKey);
        await GuestStore.getAllGuests();
        await ClientStore.getSubscriptionPlan();
        await ClientStore.getFamilyMembers();
        this.nonSyncLoadData(client);
        this.isLoading = false;
        this.isLoggingIn = false;
        return client.DefaultRecordType?.toUpperCase() || "account";
      })
      .catch((err: any) => {
        this.isLoading = false;
        this.isLoggingIn = false;
        this.loginError = true;
      });
  }

  @action async studentLogin(loginRequest: any) {
    this.isLoading = true;
    this.isLoggingIn = true;
    const clientKey = axios.defaults.params["clientKey"];
    const sessionKey = axios.defaults.params["sessionKey"];
    delete axios.defaults.params["clientKey"];
    delete axios.defaults.params["sessionKey"];
    return axios({
      method: "GET",
      url: `/ibyAuthStudent`,
      params: {
        organizationKey: loginRequest.organizationKey,
        lastName: loginRequest.lastName,
        studentID: loginRequest.studentID,
      },
    })
      .then(async (res: AxiosResponse) => {
        axios.defaults.params["clientKey"] = clientKey;
        axios.defaults.params["sessionKey"] = sessionKey;
        this.isGuestSession = false;
        await this.authenticatedCallback(res.data); // Existing Dynamic Bearer Token;
        let defaultSessionKey = loginRequest.defaultSessionKey;

        if (this.fetchToken) {
          await ClientStore.putClientSessionAccess(this.fetchToken);
          defaultSessionKey = ClientStore.defaultSessionKey;
        }

        const client = await ClientStore.getClientInfo(defaultSessionKey);
        await GuestStore.getAllGuests();
        await ClientStore.getSubscriptionPlan();
        await ClientStore.getFamilyMembers();
        this.nonSyncLoadData(client);
        this.isLoading = false;
        this.isLoggingIn = false;
        return client.DefaultRecordType?.toUpperCase() || "account";
      })
      .catch((err: any) => {
        this.isLoading = false;
        this.isLoggingIn = false;
        this.loginError = true;
      });
  }

  @action async refreshClient(loginRequest: any) {
    this.isGuestSession = false;
    const client = await ClientStore.getClientInfo(
      loginRequest?.defaultSessionKey
    );
    this.nonSyncLoadData(client);
    return client?.DefaultRecordType?.toUpperCase() || "account";
  }

  @action async nonSyncLoadData(client: any) {
    //force syncronous action so data is available to each subsequent call

    await SessionStore.onLogin(ClientStore.clientInfo);
    await EcomStore.onLogin(
      SessionStore?.activeSession?.PriceListKey,
      ClientStore.clientInfo.PendingOrderKey
    );
  }

  @action async authenticatedCallback(token: string) {
    this.loginError = false;
    this.token = token;
    setAxiosDefaults(this.accountName, this.token);
    return true;
  }

  @action async createAccount(createAccountRequest: any) {
    console.log(
      createAccountRequest.statusDescription,
      createAccountRequest.statusDueDate,
      "This is Status Due Date",
      this.fetchToken,
      "This is the Sharetoken"
    );
    const clientKey = axios.defaults.params["clientKey"];
    const sessionKey = axios.defaults.params["sessionKey"];
    delete axios.defaults.params["clientKey"];
    delete axios.defaults.params["sessionKey"];

    return axios({
      method: "PUT",
      url: `/ibyClient`,
      params: {
        preventDuplicates: true,
        SessionKey: SessionStore.activeSession.Key,
        shareToken: this.fetchToken ? this.fetchToken : undefined,
      },
      data: {
        FirstName: createAccountRequest.firstName,
        LastName: createAccountRequest.lastName,
        OnlinePassword: createAccountRequest.password,
        OnlineUserName: createAccountRequest.email,
        StatusDescription: createAccountRequest.statusDescription,
        StatusDueDate: createAccountRequest.statusDueDate,
      },
    })
      .then(async (res: AxiosResponse) => {
        this.loginError = false;

        this.userflowCreateAccount = true;
        axios.defaults.params["clientKey"] = clientKey;
        axios.defaults.params["sessionKey"] = sessionKey;
        if (res.data?.AuthToken) {
          this.token = res.data?.AuthToken;
          await this.authenticatedCallback(res.data?.AuthToken); // New Bearer Sign Up Token (One Time)
        }

        if (this.fetchToken) {
          await ClientStore.putClientSessionAccess(this.fetchToken);
        }
        await ClientStore.getClientInfo();
        await GuestStore.getAllGuests();
        await ClientStore.getFamilyMembers();
        if (SessionStore.activeSession.Key) {
          const client = await ClientStore.getClientInfo(
            SessionStore.activeSession.Key
          );
          this.nonSyncLoadData(client);
          await SessionStore.getDefaultRecord(SessionStore.activeSession.Key);
        }

        console.log(ClientStore);

        if (ClientStore.defaultSessionKey) {
          await SessionStore.getDefaultRecord(ClientStore.defaultSessionKey);
        }

        return true;
      })
      .catch((err: any) => {
        this.signupError = true;
      });
  }

  @action async resetPassword(token: string, newPassword: string) {
    return axios({
      method: "PUT",
      url: `/ibyResetPassword`,
      data: {
        PasswordResetGuid: token,
        NewPassword: newPassword,
      },
    });
  }

  @computed get countryFormatted() {
    var companyCountry = this.country;
    if (!companyCountry) {
      companyCountry = "USA";
    }
    if (
      companyCountry.toUpperCase() == "USA" ||
      companyCountry.toUpperCase() == "UNITED STATES" ||
      companyCountry.toUpperCase() == "UNITED STATES OF AMERICA"
    ) {
      companyCountry = "USA";
    }
    return companyCountry;
  }

  @computed get defaultUSA() {
    return this.countryFormatted == "USA";
  }
}

const hydrate = create({});
const AuthStore = new AuthStoreClass();

export default AuthStore;

hydrate("auth", AuthStore).then((AuthStore) => {
  setAxiosDefaults(AuthStore.accountName, AuthStore.token);

  AuthStore.hydrated = true;
});
