import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";
import AuthStore from "./authStore";
import LoginPromptStore from "./loginPromptStore";

// controls state of global create order slider
class CreateOrderClass {
  // Step 5: Photoselect
  // Step 6: Crop
  // Step 7: Composite

  @observable showSlider = false;
  @persist @observable stepNumber: number = 1;

  @action setStepNumber(nextStep: number) {
    this.stepNumber = nextStep;
  }

  @action incrementStep() {
    this.stepNumber++;
  }

  @action decrementStep() {
    this.stepNumber--;
  }

  @action toggleSlider() {
    if (!AuthStore.isGuestSession) {
      this.showSlider = !this.showSlider;
    } else {
      LoginPromptStore.setShowLoginPrompt(true);
    }
  }

  @action setSlider(stateIn: boolean) {
    this.showSlider = stateIn;
  }

  @action hardCancel() {
    this.showSlider = false;
    this.stepNumber = 1;
  }
}

const hydrate = create({});
const CreateOrderStore = new CreateOrderClass();
hydrate("CreateOrderStore", CreateOrderStore);
export default CreateOrderStore;
