import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Label,
  Button,
  Row,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import { observer, inject } from "mobx-react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const ForgotPasswordForm: React.FC<any> = ({ authStore, onSuccess }) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");

  function updateClient(obj: any, actions: any) {
    actions.setSubmitting(true);
    authStore
      .forgotPassword({ Username: obj.email })
      .then(() => {
        setAsyncErr("");
        onSuccess();
        actions.setSubmitting(false);
      })
      .catch((err: AxiosError) => {
        setAsyncErr(err.response?.data);
        actions.setSubmitting(false);
      });
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(
      t("forgotPasswordFormComponent.emailValidationError")
    ),
  });

  const initialValues = {
    email: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-12">
              <Label className="sr-only">
                {t("forgotPasswordFormComponent.email")}
              </Label>
              <Field
                placeholder={t("forgotPasswordFormComponent.email")}
                name="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                disabled={isSubmitting}
              />
              <ErrorMessage name="email">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
          </Row>
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}

          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {`Send Email`}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject("authStore")(observer(ForgotPasswordForm));
