import React from 'react';
import AppRouter from './router';
import GlobalErrorMessage from './components/globalErrorMessage/globalErrorMessage';

import './assets/styles/main.scss';

const App: React.FC<any> = () => {

  return (
        <>
          <AppRouter />
          <GlobalErrorMessage/>
        </>
  );
}

export default App;
