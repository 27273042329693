import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Col, Row, Spinner, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import AccountVerificationForm from "../../components/forms/auth/accountVerificationForm";
import { AxiosError } from "axios";

import { useQuery } from "../../components/util";
import ImageTile from "../../components/imageTile/imageTile";
import ProgessSpinner from "../../components/spinner/spinner";
import GlobalErrorStore from "../../stores/globalErrorStore";

const DashboardPage: React.FC<any> = ({
  authStore,
  sessionStore,
  eventStore,
  clientStore,
  navigationStore,
}) => {
  const [modal, setModal] = useState(
    clientStore.clientInfo.FirstIBYLoginDate ===
      clientStore.clientInfo.LastIBYLoginDate &&
      authStore.verificationPagePrompt
  );
  // const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const { t } = useTranslation();
  const query = useQuery();
  const isEmbed = query.get("embed");
  let spinnerContent;
  const titleData = {
    title: sessionStore.activeSession.Description || "",
    subHeading: !!sessionStore.activeSession.SessionDate
      ? sessionStore.activeSession.SessionDate
      : "",
    pillText: `${sessionStore.activeSessionImages.length} photos`,
  };

  useEffect(() => {
    if (isEmbed) {
      setTimeout(() => {
        window.parent.location.href = "/dashboard";
      }, 6000);
    }
  }, []);

  function RedirecttoSession() {
    navigationStore.push("/sessions-list");
  }

  function RedirecttoAlbum() {
    navigationStore.push("/sessions");
  }

  if (sessionStore.allSessionDetail?.length === 0) {
    spinnerContent = (
      <ProgessSpinner
        showSpinner={!sessionStore.activeSessionImages?.[0]}
        color="primary"
      />
    );
  } else {
    if (sessionStore.loading) {
      spinnerContent = (
        <>
          <h1 className="title p-5">
            Loading {authStore.jobSingular.toLowerCase()}
          </h1>
          <div className=" d-flex justify-content-center w-100">
            <Button
              className="text-center"
              color="primary text-truncate"
              onClick={RedirecttoAlbum}
            >
              Go to Album
            </Button>
          </div>
        </>
      );
    } else if (sessionStore.activeSessionImages?.length === 0) {
      spinnerContent = (
        <>
          <h1 className="title p-5">
            {t("DashboardPage.noImages")} {authStore.jobSingular.toLowerCase()}
          </h1>
          <div className=" d-flex justify-content-center w-100">
            <Button
              className="text-center"
              color="primary text-truncate"
              onClick={RedirecttoAlbum}
            >
              Go to Album
            </Button>
          </div>
        </>
      );
    }
  }

  useEffect(() => {
    //Check For ClientKey After Idle State
    if (!clientStore.clientKey && !authStore.token) {
      authStore.logout();
      navigationStore.push("/home/login");
    }
  }, []);

  const onSuccess = () => {
    setModal(false);
  };

  const onSubmit = async (obj: any) => {
    if (!obj.showName) {
      obj.FirstName = null;
      obj.LastName = null;
    }
    if (!obj.showEmail) {
      obj.EmailAddress = null;
      obj.AllowEmailMarketing = null;
    }
    if (!obj.showPhone) {
      obj.PhoneNumber0 = null;
      obj.AllowTextConfirmation = null;
      obj.AllowTextMarketing = null;
      obj.SMSTextable0 = null;
    }
    if (!obj.showPassword) {
      obj.OnlinePassword = null;
    }
    await clientStore
      .updateClient(obj)
      .catch((err: AxiosError) =>
        GlobalErrorStore.setErrorMessage(err.message)
      );
    onSuccess();
  };

  return (
    <>
      <Helmet>
        <title>
          {isEmbed ? t("DashboardPage.loading") : t("DashboardPage.dashboard")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>

      {isEmbed && (
        <div className="page-full-spinner">
          <div>
            <Spinner color="primary" size="sm" />
          </div>
        </div>
      )}

      {!clientStore?.clientInfo?.SessionCount && !sessionStore?.activeSession && (
        <>
          <h3 className="title p-5 ion-align-items-center center-align flex-wrap">
            <>You do not have any {authStore.jobPlural} yet.</>
          </h3>
        </>
      )}

      {authStore.allowNewSessions &&
        !(clientStore.statusDescription === "Free") && (
          <>
            {!clientStore?.clientInfo?.SessionCount ? (
              <div className="text-center">
                <h2 className="h3 text-center pt-5">Please Create New Album</h2>
                <Row className="mb-2 mt-4 text-center">
                  <Col className="text-center">
                    <Button
                      color="primary text-truncate"
                      onClick={RedirecttoSession}
                    >
                      Create Album
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <>{spinnerContent}</>
            )}
          </>
        )}

      {sessionStore.activeSessionImages?.[0] && (
        <ImageTile
          img={sessionStore.activeSessionImages[0]}
          single
          noActions
          titleData={titleData}
        />
      )}

      <Modal clasName="p-3" isOpen={modal} toggle={toggle}>
        <h1 className="p-3 mb-1">{"Account Verification"}</h1>
        <p className="px-3">
          Thank you for logging in! Please help us setup your account for the
          first time.
        </p>
        <ModalBody>
          <AccountVerificationForm onSubmit={onSubmit} />
        </ModalBody>
      </Modal>

      {/* {sessionStore.errorSessions && (
        <div className="page-section-full bg-light text-center">
          <div>
            <h1 className="h3">
              {t("DashboardPage.title", {
                session: authStore.jobSingular.toLowerCase(),
              })}
            </h1>
            <p>
              {t("DashboardPage.check")}{" "}
              <Link to="/contracts">{t("DashboardPage.pendingContracts")}</Link>{" "}
              or <Link to="/help">{t("DashboardPage.contactUs")}</Link>.
            </p>
          </div>
        </div>
      )} */}
    </>
  );
};

export default inject(
  "authStore",
  "sessionStore",
  "eventStore",
  "clientStore",
  "navigationStore"
)(observer(DashboardPage));
