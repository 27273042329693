import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Spinner,
  Toast,
  ToastHeader,
  ToastBody,
  Progress,
  Input,
  Label,
} from "reactstrap";
import { inject, observer } from "mobx-react";
import { XMasonry, XBlock } from "react-xmasonry";
import { FiArrowLeft } from "react-icons/fi";
import { motion } from "framer-motion";
import { Waypoint } from "react-waypoint";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { postReverseVariants, useQuery } from "../../util";
import PhotoTile from "./photoTile";

const PhotoSelect: React.FC<any> = ({
  authStore,
  createOrderStore,
  sessionStore,
  ecomStore,
  compositeStore,
  imageGroupStore,
}) => {
  const { t } = useTranslation();
  const [totalItems, setTotalItems] = useState(0);
  const [filterByFavorites, setFilterByFavorites] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [isEndOfPage, setEndOfPage] = useState(false);
  const [listOnDisplay, setListOnDisplay] = useState([] as any);
  const [waitToLoad, setWaitToLoad] = useState(false);

  const query = useQuery();
  const isPrepaid = query?.get("id");

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    const startItemsTotal = isBreakDownSm ? 25 : 50; //old- 24 : 32
    setTotalItems(sessionStore?.swapPhotoCurrentSessionImages.length);
    setItemsPerPage(startItemsTotal);
    const iniDisplay: any[] = [];
    sessionStore?.swapPhotoCurrentSessionImages
      .slice(0, startItemsTotal)
      .map((img: any, i: number) => {
        if (filterByFavorites) {
          if (imageGroupStore.favoriteImageList.includes(img.fileName)) {
            iniDisplay.push(img);
          }
        } else {
          iniDisplay.push(img);
        }
      });

    setListOnDisplay([...iniDisplay]);

    setTimeout(() => {
      setWaitToLoad(true);
    }, 600);
  }, [filterByFavorites]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);

    const itemsDisplayed = (currentPage + 1) * itemsPerPage;
    const iniDisplay: any[] = [];
    sessionStore?.swapPhotoCurrentSessionImages
      .slice(itemsDisplayed - itemsPerPage, itemsDisplayed)
      .map((img: any, i: number) => {
        if (filterByFavorites) {
          if (imageGroupStore.favoriteImageList.includes(img.fileName)) {
            iniDisplay.push(img);
          }
        } else {
          iniDisplay.push(img);
        }
      });

    setListOnDisplay([...listOnDisplay, ...iniDisplay]);

    if (currentPage * itemsPerPage >= totalItems - itemsPerPage) {
      setEndOfPage(true);
    }
  };

  console.log(sessionStore.activeSessionImages);

  const gridDisplay = listOnDisplay.map((photo: any, i: number) => {
    return (
      <XBlock key={`cropGrid${i}`}>
        <div className="pads">
          <PhotoTile
            key={`imgTileList${i}`}
            index={i}
            img={photo}
            onClick={async () => {
              const updateObj = {
                InvoiceKey: sessionStore.swappedPhoto?.PreOrder
                  ? ecomStore.activeInvoice.Key
                  : ecomStore.cart.Key,
                Key: ecomStore.swapPhotoCartItemKey,
                FileName: photo.fileName,
                ItemKey: "",
              };

              await ecomStore.updateCartItem(
                updateObj,
                photo.sessionKey,
                true,
                sessionStore.swappedPhoto?.PreOrder ? true : false
              );
              sessionStore.swappedPhoto.imageKey = photo.key;

              const updatedItem = ecomStore.cartItems.find(
                (item: any) => item.Key === ecomStore.swapPhotoCartItemKey
              );

              let updatedItemIdx = ecomStore.currentPackageItems.findIndex(
                (item: any) => item?.Key === ecomStore.swapPhotoCartItemKey
              );
              const priceItem = ecomStore.findItemByKey(updatedItem?.ItemKey);
              const options = priceItem?.Options;
              const enhancements = priceItem?.Enhancements;

              if (sessionStore.swappedPhoto?.PreOrder) {
                createOrderStore.toggleSlider();
              } else if (
                ecomStore.isCropRequired(updatedItem) ||
                options?.length > 0 ||
                enhancements?.length > 0
              ) {
                ecomStore.setCroppedPhoto(updatedItem.Key);
                ecomStore.scrollCart = updatedItem.Key;
                createOrderStore.setStepNumber(6);
              } else {
                let found = false;
                while (
                  updatedItem?.PackageItemKey &&
                  ecomStore.currentPackageItems &&
                  updatedItemIdx + 1 < ecomStore.currentPackageItems.length &&
                  !found
                ) {
                  const newItem =
                    ecomStore.currentPackageItems[updatedItemIdx + 1];
                  const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
                  const options = priceItem.Options;
                  const enhancements = priceItem.Enhancements;
                  if (newItem.compositeTemplateKey) {
                    ecomStore.scrollCart = newItem.Key;
                    ecomStore.swapPhotoCartItemKey = newItem.Key;
                    await compositeStore.handleComposite(newItem);
                    createOrderStore.setStepNumber(7);
                    found = true;
                  } else if (newItem?.ReplacementItems?.length > 0) {
                    ecomStore.setCroppedPhoto(newItem.Key);
                    createOrderStore.setStepNumber(8);
                    found = true;
                  } else if (
                    ecomStore.isImageRequired(newItem) ||
                    newItem.allowImageSelection
                  ) {
                    sessionStore.swappedPhoto = newItem;
                    ecomStore.swapPhotoCartItemKey = newItem.Key;
                    ecomStore.scrollCart = newItem.Key;
                    createOrderStore.setStepNumber(5);
                    found = true;
                  } else if (
                    ecomStore.isCropRequired(newItem) ||
                    options?.length > 0 ||
                    enhancements?.length > 0
                  ) {
                    ecomStore.swapPhotoCartItemKey = newItem.Key;
                    ecomStore.setCroppedPhoto(newItem.Key);
                    ecomStore.scrollCart = newItem.Key;
                    createOrderStore.setStepNumber(6);
                    found = true;
                  } else {
                    updatedItemIdx += 1;
                  }
                }
                if (
                  !updatedItem?.PackageItemKey ||
                  updatedItemIdx + 1 === ecomStore.currentPackageItems.length
                ) {
                  createOrderStore.setStepNumber(4);
                }
              }
            }}
            className={`photo-grid-image${
              photo.key === sessionStore.swappedPhoto.imageKey ? " active" : ""
            }`}
          />
        </div>
      </XBlock>
    );
  });

  if (ecomStore.activeInvoiceLoading || compositeStore.loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {isPrepaid
            ? `${t("photoSelectComponent.prepaid")} ${isPrepaid}`
            : t("photoSelectComponent.shoppingCart")}
          : {t("photoSelectComponent.swapPhoto")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postReverseVariants}
      >
        <section className="product-modal-container">
          <div className="product-modal-header">
            <Row className="align-items-center">
              <Col xs={12}>
                <Button
                  color="default-link"
                  onClick={() => {
                    sessionStore.swappedPhoto?.PreOrder
                      ? createOrderStore.toggleSlider()
                      : createOrderStore.setStepNumber(4);
                  }}
                >
                  <FiArrowLeft size="18" className="mr-2" />
                  {sessionStore.swappedPhoto?.PreOrder
                    ? t("photoSelectComponent.backToPreorder")
                    : t("photoSelectComponent.backToShoppingCart")}
                </Button>
              </Col>
            </Row>
            <div style={{ fontSize: "24px" }}>
              <strong>
                Please select an image for:{" "}
                {isPrepaid
                  ? ecomStore.cartInvoiceItems.find(
                      (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                    )?.Description
                  : ecomStore.cartItems.find(
                      (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                    )?.Description}{" "}
                {ecomStore.currentPackageItems.length > 0 &&
                  `(Item ${
                    ecomStore.currentPackageItems.findIndex(
                      (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                    ) + 1
                  } of ${ecomStore.currentPackageItems.length})`}
                {ecomStore.currentPackageItems.length > 0 && (
                  <Progress
                    value={
                      ecomStore.currentPackageItems.findIndex(
                        (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                      ) + 1
                    }
                    min="1"
                    max={ecomStore.currentPackageItems.length}
                  ></Progress>
                )}
              </strong>
            </div>
          </div>
          <div className="product-modal-photo-container">
            <div className="photo-grid px-1">
              {waitToLoad && (
                <>
                  <div style={{ padding: "0.5rem 2rem" }}>
                    <Input
                      type="checkbox"
                      onChange={(e: any) => {
                        setFilterByFavorites(e.target.checked);
                      }}
                    />
                    <Label check>Only Show Favorites</Label>
                  </div>
                  <XMasonry
                    center={false}
                    updateOnImagesLoad
                    targetBlockWidth={180}
                  >
                    {gridDisplay}
                  </XMasonry>

                  <Waypoint onEnter={handleLoadMore} topOffset={-200}>
                    {!isEndOfPage ? (
                      <div
                        style={{
                          paddingTop: 50,
                          paddingBottom: 50,
                          textAlign: "center",
                        }}
                      >
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Waypoint>
                </>
              )}
            </div>
          </div>
          {/* <div className="product-modal-footer">
            <Row className="align-items-center">
              <Col xs={12}>
                <Button
                  color="default-link"
                  onClick={() => {
                    sessionStore.swappedPhoto?.PreOrder
                      ? createOrderStore.toggleSlider()
                      : createOrderStore.setStepNumber(4);
                  }}
                >
                  <FiArrowLeft size="18" className="mr-2" />
                  {sessionStore.swappedPhoto?.PreOrder
                    ? t("photoSelectComponent.backToPreorder")
                    : t("photoSelectComponent.backToShoppingCart")}
                </Button>
              </Col>
            </Row>
          </div> */}
        </section>
      </motion.div>
    </>
  );
};
export default inject(
  "authStore",
  "createOrderStore",
  "sessionStore",
  "ecomStore",
  "compositeStore",
  "imageGroupStore"
)(observer(PhotoSelect));
