export default{

    "AccountPage": {
        "title": "Mitt konto",
        "loginSubtitle": "Inloggningsinfo",
        "loginText": "Inloggad som",
        "editUsername": "Redigera användarnamn",
        "changePassword": "Ändra lösenord",
        "personalInfoSubtitle": "Personlig information",
        "familyMemberSubtitle": "Familjemedlemmar",
        "edit": "Redigera",
        "delete": "Ta bort",
        "default": "Standard",
        "paymentSubtitle": "Betalning",
        "paymentText": "Uppdatera sparad kreditkortsinformation",
        "communicationSubtitle": "Kommunikation",
        "communicationText": "För kampanjer och nyhetsbrev opt-ins",
        "shippingSubtitle": "Leveransadresser",
        "shippingText": "Använd för kassan som leveransinformation",
        "upcomingSubtitle": "Kommande",
        "upcomingText": "Bekräfta omplanering eller avbokning",
        "noUpcomingText": "Du har inga kommande sessioner",
        "tableDate": "Datum",
        "tableTime": "Tid",
        "tableDescription": "Beskrivning",
        "tableAction": "Action",
        "confirm": "Bekräfta",
        "reschedule": "Omplanera",
        "cancel": "Avbryt",
        "scheduleButton": "Schema nu",
        "deleteAddressButton": "Vill du ta bort den här adressen?",
        "updateAddressModalTitle": "Uppdatera adress",
        "updateUsernameModalTitle": "Uppdatera användarnamn",
        "updatePasswordModalTitle": "Ändra lösenord",
        "updatePersonalInfoModalTitle": "Uppdatera personlig information",
        "updateCommunicationModalTitle": "Uppdatera opt-ins",
        "updatePaymentModalTitle": "Betalning",
        "scheduleModalTitle": "Schema",
        "rescheduleModalTitle": "Omplanera",
        "clickhere": "Klicka här",
    },
    "ForgotPasswordPage": {
        "title": "Glömt lösenord",
        "subtitle": "Ett e-postmeddelande kommer att skickas till din e-postadress med ytterligare instruktioner",
        "alertSuccess": "Du bör få ett mail inom kort. Kontrollera din skräppostmapp om du inte kan hitta e-postmeddelandet.",
        "backToLogin": "Tillbaka till inloggning",
    },
    "LoginPage": {
        "title": "Logga in för att se fantastiska bilder inspirerade av dig!",
        "description": "Om du redan har ett konto logga in här för att se dina bilder eller klicka på \"Händelsesökning\" ovan för att söka efter en händelse.",
        "email": "E-postadress eller användarnamn",
        "password": "Lösenord",
        "forgotPass": "Glömt lösenord",
        "emailError": "Ange en e-postadress eller ett användarnamn",
        "ajaxError": "Ogiltigt användarnamn eller lösenord",
        "passwordError": "Ange ett lösenord",
        "loginButton": "Logga in",
    },
    "ResetPasswordPage": {
        "title": "Ange ditt nya lösenord",
        "alertSuccess": "Ditt lösenord har återställts. Du kommer att omdirigeras tillbaka till inloggningssidan.",
        "backToLogin": "Tillbaka till inloggning",
    },
    "SignupPage": {
        "title": "Skapa ett konto",
        "p1": "Har du redan ett konto?",
        "p2": "för att visa dina foton.",
        "createAccountLink": "Logga in här",
        "email": "E-post",
        "password": "Lösenord",
        "signup": "Registrera sig",
        "ajaxError": "Oj något gick fel. Försök igen.",
        "ajaxErrorOther": "Oj något gick fel. Försök igen.",
    },
    "ConfirmationPage": {
        "tabTitle": "Tack",
        "title": "Tack för att du gjorde din beställning.",
        "text": "Ditt ordernummer är",
        "relatedProducts": "Du kanske är intresserad av...",
    },
    "CheckoutPage": {
        "urlTitle": "Säker utcheckning",
        "title": "Säker utcheckning",
        "subtitle": "4096-bitars SSL/TSL-skyddad sida",
    },
    "OrderReviewPage": {
        "urlTitle": "Säker utcheckning",
        "tableProductName": "Produktnamn",
        "tablePrice": "Pris",
        "tableQuantity": "Kvantitet",
        "tableSubtotal": "Delsumma",
        "enter": "Skriv in den nu",
        "apply": "Applicera",
        "cancel": "Avbryt",
        "promo": "Promo",
        "shipping": "Frakt",
        "tax": "Skatt",
        "total": "Totalt",
    },
    "PaymentPage": {
        "urlTitle": "Säker utcheckning",
    },
    "ShippingAddressPage": {
        "urlTitle": "Säker utcheckning",
    },
    "ContractsPage": {
        "urlTitle": "Kontrakt",
        "title": "Kontrakt",
        "invalidPIN": "Ogiltig PIN-kod",
        "uploaded": "Uppladdade",
        "accepted": "Accepterade",
        "printContract": "Skriv ut kontrakt",
        "formLabel": "Jag har läst och förstått villkoren ovan. Jag godkänner härmed villkoren i detta avtal.",
        "enterPin": "Ange din numeriska pin",
        "confirm": "Bekräfta och skicka",
    },
    "DashboardPage": {
        "loading": "Laddar...",
        "dashboard": "Instrumentpanelen",
        "title": "Du har för närvarande ingen session.",
        "check": "Kontrollera",
        "pendingContracts": "pågående kontrakt",
        "contactUs": "kontakta oss",
        "noImages": "Du har inga bilder för detta",
    },
    "EventSearchPage": {
        "title": "Sök efter delade händelser och skratt",
        "urlTitle": "Sök efter evenemang",
        "p1": "Om du vill hitta en händelse väljer du en i listan eller söker efter händelsekod eller händelsedatum.",
        "date": "Händelsedatum",
        "code": "Privat kod",
        "public": "Offentligt evenemang",
        "search": "\"Sök\"",
    },
    "FavoritesPage": {
        "urlTitle": "Favorit",
    },
    "FoldersPage": {
        "urlTitle": "Mappen",
        "viewFull": "Se hela",
        "noFoldersFound": "Inga mappar hittades för detta",
    },
    "FormsPage": {
        "urlTitle": "Former",
        "title": "Former",
        "uploaded": "Uppladdade",
    },
    "GuestsPage": {
        "urlTitle": "Gästerna",
        "title": "Gästerna",
        "error": "Ett fel uppstod när gästanbjudan skulle uppdateras.",
        "success": "Länka skickas igen",
        "copy": "Kopieras till Urklipp",
        "createNew": "Skapa nya",
        "invitationLinks": "Inbjudningslänkar",
        "noLinksFound": "Inga länkar hittades för detta",
        "tableDescription": "Beskrivning",
        "tableDateCreated": "Datum som skapats",
        "tableDateFirstUsed": "Datum som först användes",
        "tableAction": "Action",
        "copyLink": "Kopiera länk",
        "remove": "Ta bort",
        "invitedTitle": "Inbjudna gäster",
        "noGuestsFound": "Inga gäster hittades för detta",
        "tableName": "Namn",
        "tableEmail": "E-post",
        "tableDateInvited": "Inbjudet datum",
        "edit": "Redigera",
        "resend": "Skicka",
        "updateGuest": "Uppdatera gäst",
        "createNewGuestInvitation": "Skapa ny gästtjudan",
        "generateText": "Generera en delbar länk för att ge andra tillgång till dina bilder. Dela länken via sociala medier en text eller via e-post.",
        "emailInvite": "Inbjudan via e-post",
        "sharableLink": "Delningsbar länk",
    },
    "HelpPage": {
        "urlTitle": "Hjälp",
        "title": "Hjälp",
        "ourInfo": "Vår information",
        "hereToHelp": "Vi är här för att hjälpa",
        "chatBy": "Chatta med oss genom att",
        "clickingHere": "klicka här",
    },
    "HomePage": {
        "signIn": "Logga in",
        "eventSearch": "Sök efter evenemang",
        "privacy": "Sekretesspolicy",
        "userAccessAgreement": "Avtal om användaråtkomst",
        "termsOfUse": "Användarvillkor",
        "close": "Stäng",
    },
    "OrderDetailsPage": {
        "urlTitle": "Beställning",
        "photo": "Foto",
        "setCrop": "Ange beskärning och alternativ",
        "cropRequired": "Beskärning krävs före utcheckning",
        "viewOrders": "Visa order",
        "order": "Beställning",
        "details": "Detaljer",
        "orderDate": "Orderdatum",
        "makePayment": "Gör betalning",
        "printOrder": "Utskriftsordning",
        "orderDetails": "Orderdetaljer",
        "tableProduct": "Produkt",
        "tablePrice": "Pris",
        "tableQty": "Antal",
        "tableTotal": "Totalt",
        "subtotal": "Delsumma",
        "promo": "Promo",
        "shipping": "Frakt",
        "tax": "Skatt",
        "payment": "Betalning",
        "total": "Totalt",
        "finalize": "Slutför markering",
        "shippingInfo": "Fraktinformation",
        "delivery": "Leverans",
        "confirm": "Bekräfta inlämning",
        "cancel": "Avbryt",
        "warning": "Den här åtgärden kan inte ångras i appen.",
        "submitting": "Skicka...",
        "yesConfirm": "Ja bekräfta",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Ordernummer",
        "tableOrderDate": "Orderdatum",
        "tableDateCreated": "Datum som skapats",
        "tableShippedTo": "Levereras till",
        "tableStatus": "Status",
        "tableDescription": "Beskrivning",
        "tableOpenBalance": "Öppen balans",
        "tableTotal": "Totalt",
        "orders": "Beställningar",
        "orderHistory": "Beställ historik",
        "preOrders": "Förbeställningar",
        "noOrders": "För närvarande har du ingen beställning ännu.",
    },
    "SharePage": {
        "urlTitle": "Det här är titelsidan",
        "title": "Det här är titelsidan",
        "iframe": "Detta är en tom iframe-sida.",
        "shareImage": "Dela den här bilden",
    },
    "SplashPage": {
        "wereSorry": "Vi är ledsna",
        "doesntSeemTo": "verkar inte vara en giltig resurstoken.",
        "error": "Vi är ledsna att det ser ut som om något gick fel. Meddela webbplatsadministratören om detta fortsätter.",
        "subdomainError": "Den här appen förväntar sig en underdomän. Kontrollera webbadressen och se till att den ser ut som <Ditt företagsnamn>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Lägg till i mapp",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Social andel",
        "generatingShareable": "Generera delningsbar url...",
    },
    "cartViewComponent": {
        "urlTitle": "Kundvagn",
        "title": "Kundvagn",
        "photo": "Foto",
        "swap": "Swap",
        "youHave": "Du har",
        "youHaveAdded": "Du har lagt till",
        "unitsRemaining": "enheter som återstår att läggas till din beställning.",
        "unitsTooMany": "enheter för många till din beställning.",
        "cartFullModalTitle": "För många enheter",
        "tooMany": "Den här ordern innehåller för många enheter.  Ta gärna bort några produkter innan du checkar ut.",
        "have": "Har",
        "another": "Annan",
        "a": "A",
        "ok": "OK",
        "promoCode": "promo / kupongkod?",
        "promoCode1": "Befordra koden",
        "removePromoCode": "Ta bort kampanjkod",
        "select": "Välj",
        "loading": "Laddar",
        "setCropAndOptions": "Ange beskärning och alternativ",
        "cropRequired": "Beskärning krävs före utcheckning",
        "checkout": "Kontrollera",
        "tableAction": "Action",
        "tableProductName": "Produktnamn",
        "tablePrice": "Pris",
        "tableQuantity": "Kvantitet",
        "tableSubtotal": "Delsumma",
        "enterNow": "Skriv in den nu",
        "apply": "Applicera",
        "cancel": "Avbryt",
        "promo": "Promo",
        "noItems": "Inga varor i vagnen.",
        "checkOurProducts": "Kontrollera våra produkter",
        "continueShopping": "Fortsätt handla",
    },
    "createOrderIndexComponent": {
        "title": "Skapa en order",
        "step1cta": "Fortsätta",
        "finish": "Slutför",
    },
    "cropViewComponent": {
        "cropImage": "Beskär bild",
        "prepaid": "Förbetalda",
        "shoppingCart": "Kundvagn",
        "image": "Bild",
        "zoomIn": "Zooma in",
        "zoomOut": "Zooma ut",
        "reset": "Återställa",
        "cropOrientation": "Beskär orientering",
        "selectPhotoOption": "Välj fotoalternativ",
        "pleaseSelect": "Välj...",
        "selectPhotoEnhancement": "Välj fotoförbättring",
        "selectColorEffect": "Välj färgeffekt",
        "notes": "Anteckningar",
        "apply": "Applicera",
        "backToPreorder": "Tillbaka till förbeställning",
        "backToShoppingCart": "Tillbaka till kundvagnen",
    },
    "photoSelectComponent": {
        "swapPhoto": "Byt foto",
        "prepaid": "Förbetalda",
        "shoppingCart": "Kundvagn",
        "backToPreorder": "Tillbaka till förbeställning",
        "backToShoppingCart": "Tillbaka till kundvagnen",
    },
    "photoTileComponent": {
        "image": "Bild",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Kategori",
        "title": "Kategori",
        "selectYourProduct": "Välj din produkt",
        "selectCateogry": "Välj kategori",
        "sortBy": "Sortera efter",
        "bestSelling": "Bästsäljande",
        "viewLarger": "Visa större",
        "viewDetails": "Visa information",
    },
    "productGroupListComponent": {
        "urlTitle": "Produkter",
        "title": "Välj produktkategori",
        "viewTheseProducts": "Visa dessa produkter",
    },
    "productDetailTabsComponent": {
        "description": "Beskrivning",
        "features": "Funktioner",
        "delivery": "Leverans",
        "noDescriptionFound": "Ingen beskrivning hittades",
        "noFeatureFound": "Ingen funktionsinformation hittades",
        "noDeliveryFound": "Ingen leveransinformation hittades",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Det gick inte att lägga till i kundvagnen. Uppdatera sidan eller rapportera produkten till vår support.",
        "product": "Produkt",
        "cancelAndBack": "Avbryt och återgå till listning",
        "selectPhotoOption": "Välj fotoalternativ",
        "pleaseSelect": "Välj...",
        "selectPhotoEnhancement": "Välj foto enahncement",
        "selectColorEffect": "Välj färgeffekt",
        "was": "Var",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Markera en mapp eller skapa ny",
    },
    "addressFormSectionComponent": {
        "firstName": "Förnamn",
        "lastName": "Efternamn",
        "addressLine1": "Adress linje 1",
        "addressLine2": "Adressrad 2",
        "city": "Staden",
        "region": "Region",
        "state": "Statliga",
        "postalCode": "Postnummer",
        "zipCode": "Postnummer",
        "country": "Land",
    },
    "addToCartFormComponent": {
        "quantity": "Kvantitet",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Ange din e-postadress",
        "email": "E-post",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Bekräfta ditt nya lösenord",
        "password": "Lösenord",
        "enterPassword": "Ange ditt nya lösenord",
        "confirmPassword": "Bekräfta lösenord",
        "setNewPassword": "Ange nytt lösenord",
    },
    "signupFormComponent": {
        "firstNameValidation": "Ange ditt förnamn",
        "lastNameValidation": "Ange ditt efternamn",
        "invalidEmailValidation": "Ange ett giltigt e-postmeddelande",
        "emailRequiredValidation": "Ange en e-postadress",
        "confirmPasswordValidation": "Bekräfta ditt lösenord",
        "countryRequiredValidation": "Välj ett land",
        "passwordsMustMatch": "Lösenord måste stämma överens",
        "confirm": "Bekräfta",
        "firstName": "Förnamn",
        "lastName": "Efternamn",
        "email": "E-postadress",
        "password": "Lösenord",
        "confirmPassword": "Bekräfta lösenord",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Ange ditt förnamn",
        "lastNameValidaiton": "Ange ditt efternamn",
        "addressLine1Validation": "Ange din gatuadress",
        "cityValidation": "Ange din stad",
        "stateValidation": "Ange ditt tillstånd",
        "postalCodeValidation": "Ange ditt postnummer",
        "invalidPostalCode": "Ange ett giltigt postnummer",
        "countryValidation": "Ange ditt land",
        "emailValidation": "Ange ett giltigt e-postmeddelande",
        "billingInformation": "Faktureringsinformation",
        "selectSavedAddress": "Välj sparad adress",
        "email": "E-postadress",
        "phoneNumber": "Telefonnummer",
        "saveAsDefault": "Spara faktureringstillägg som standard",
        "saveAsShipping": "Samma som leveransadress",
        "promotionalTexts": "Kan vi skicka reklamtexter?",
        "dataRates": "(Data- och meddelandehastigheter kan gälla)",
        "promotionalEmails": "Kan vi skicka reklammeddelanden?",
        "continue": "Fortsätt",
        "continueShopping": "Fortsätt handla",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Använda vald adress",
        "useDefaultAddress": "Använd standardadress",
        "useClientAddress": "Använd klientadress",
        "saveAsDefault": "Spara som standard",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Välj en leveransmetod",
    },
    "communicationFormComponent": {
        "permissionCall": "Kan vi ringa dig med reklaminformation?",
        "permissionEmail": "Kan vi maila dig med reklaminformation?",
        "permissionText": "Kan vi skicka reklaminformation till dig? (Meddelande- och datahastigheter kan tillkomma)",
    },
    "editAddressFormComponent": {
        "name": "Namn",
        "phoneNumber": "Telefonnummer",
        "addressLine1": "Adress linje 1",
        "addressLine2": "Adressrad 2",
        "city": "Staden",
        "region": "Region",
        "postalCode": "Postnummer",
        "country": "Land",
        "deliveryInstructions": "Instruktioner för leverans",
        "accessCode": "Åtkomstkod",
        "defaultAddress": "Standardadress",
        "applyChange": "Använd ändring",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Ange ditt lösenord",
        "passwordCannotBeSame": "Nytt lösenord kan inte vara detsamma som det gamla",
        "confirmPassword": "Bekräfta ditt nya lösenord",
        "passwordsMustMatch": "Lösenord måste stämma överens",
        "existingPassword": "Befintligt lösenord",
        "currentPassword": "Aktuellt lösenord",
        "newPassword": "Nytt lösenord",
        "confirmNewPassword": "Bekräfta nytt lösenord",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Välj en händelse",
        "selectEventDate": "Välj ett evenemangsdatum",
        "enterCode": "Ange en kod",
    },
    "inputSectionComponent": {
        "selectDate": "Välj ett datum",
        "placeCodeHere": "Placera kod här",
        "input": "Input",
        "selectEvent": "Välj en händelse",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoriter",
        "ajaxError": "Ett fel uppstod när gästtjudan skulle skapas",
        "invalidEmail": "Ange ett giltigt e-postmeddelande",
        "noEmail": "Ange ett e-postmeddelande",
        "noFirstName": "Ange ett förnamn",
        "noLastName": "Ange ett efternamn",
        "email": "E-postadress",
        "firstName": "Förnamn",
        "lastName": "Efternamn",
        "session": "Session",
        "image": "Bild",
        "sendEmail": "Skicka e-post",
        "onlyInclude": "Inkludera endast favoriter från den här",
    },
    "joyFormComponent": {
        "ageMonth": "månad(er)",
        "yes": "Ja",
        "no": "Nej",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Ange en beskrivning för din länk",
        "description": "Beskrivning",
        "onlyIncludeFavoritesFromThis": "Inkludera endast favoriter från den här",
        "createLink": "Skapa länk",
    },
    "personalInfoComponent": {
        "invalidEmail": "Ange en giltig e-postadress",
        "primaryPhone": "Primära telefon",
        "secondaryPhone": "Sekundär telefon",
        "mobilePhone": "Detta är en mobiltelefon",
        "streetAddress": "Gatuadress",
        "house": "House",
        "apartment": "Lägenhet",
        "roomNumber": "Rumsnummer",
        "city": "Staden",
        "region": "Region",
        "state": "Statliga",
        "postalCode": "Postnummer",
        "country": "Land",
        "pleaseSelect": "Välj...",
    },
    "familyMemberComponent": {
        "nickname": "Smeknamn",
        "familyMember": "Familjemedlem",
        "relationship": "Relation",
        "gender": "Kön",
        "BirthDate": "Födelsedatum",
        "familyMemberSubtitle": "Familjemedlemmar",
        "tableAction": "Action",
    },
    "usernameFormComponent": {
        "enterUsername": "Ange ett nytt användarnamn",
        "onlineUsername": "Användarnamn online",
        "username": "Användarnamn",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Fler navigeringsobjekt",
        "download": "Hämta",
        "removeFrom": "Ta bort från",
        "modifyImage": "Ändra bild",
        "folders": "Mappar",
        "makePrimary": "Gör primärt",
        "yearbookImage": "Årsbok bild",
        "makeSecondary": "Gör sekundärt",
        "socialShare": "Social andel",
        "unhide": "Ta fram",
        "hide": "Dölj",
    },
    "imageTileComponent": {
        "view": "Visa",
        "sessions": "Session",
        "setAsPrimary": "Ange som primärt foto",
        "setAsSecondary": "Ange som sekundärt foto",
        "image": "Bild",
        "caution": "Försiktighet",
        "separateSessionsMessage1": "Du kan inte lägga till bilder från två separata",
        "separateSessionsMessage2": "till samma vagn",
        "doNotAdd": "Lägg inte till den här varan i min kundvagn.",
        "clearCart": "Rensa innehållet i min vagn och lägg sedan till denna artikel i min kundvagn.",
    },
    "loginPromptComponent": {
        "title": "Oj!",
        "message": "Vänligen --login-- eller --registrera dig - om du vill favoritbilder gruppera bilder tillsammans eller lägga till bilder i kundvagnen.",
    },
    "menuDropdownComponent": {
        "dashboard": "Instrumentpanelen",
        "sessions": "Session",
        "favorites": "Favoriter",
        "contracts": "Kontrakt",
        "orders": "Beställningar",
        "prepaidOrders": "Förbetalda beställningar",
        "guests": "Gästerna",
        "account": "Konto",
    },
    "userDropdownComponent": {
        "logout": "Logga ut",
    },
    "navBarPage": {
        "toggleNavigation": "Växla navigering",
        "cart": "Vagn",
        "photoshootsAndImages": "Fotograferingar och bilder",
        "contracts": "Kontrakt",
        "reviewSubmit": "Granska kontrakt och skicka in",
        "forms": "Former",
        "reviewForms": "Granska webbformulär och skicka",
        "orders": "Beställningar",
        "historyPrepaid": "Historia och förbetalda beställningar",
        "orderHistory": "Beställ historik",
        "products": "Produkter",
        "guests": "Gästerna",
        "categoriesProducts": "Kategorier och produkter",
        "sharedFamily": "Delas med familjen",
        "friends": "Vänner",
        "myAccount": "Mitt konto",
        "login": "Logga in",
        "billingInfo": "faktureringsinformation",
        "help": "Hjälp",
        "chat": "Chatt för onlinesupport",
        "shoppingCart": "Kundvagn",
        "checkout": "Gör dig redo att kassan",
        "signout": "Logga ut",
        "endGuest": "Avsluta min gäst",
        "securelySignout": "Låt mig säkert logga ut",
    },
    "productGalleryComponent": {
        "viewLarger": "Visa större",
    },
    "responsiveHeaderComponent": {
        "photos": "Bilder",
        "guests": "Gästerna",
        "allImages": "Alla bilder",
        "favorites": "Favoriter",
        "folders": "Mappar",
        "autoPlay": "Autoplay",
        "download": "Hämta",
        "cart": "Vagn",
    },
    "sessionsPage": {
        "Name": "Namn",
        "Date": "Datum",
        "CreateNewText": "Skapa nya",
        "ViewText": "Visa",
        "DeleteText": "Ta bort",
        "EditText": "Redigera",
    },
}
