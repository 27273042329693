import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { observer, inject } from 'mobx-react';
import AddImageToGroupForm from '../forms/addImageToGroupForm/addImageToGroupForm';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';


const AddImageToGroupModal: React.FC<any> = ({history, imageGroupStore}) => {
    const { t } = useTranslation();
    
    useEffect(()=>{
        if(imageGroupStore.addToGroup.showModal){
            imageGroupStore.toggleModal()
        }
    },[history.location.pathname])

    return (
        <Modal
        isOpen={imageGroupStore.addToGroup.showModal} 
        toggle={()=>imageGroupStore.toggleModal()} 
        wrapClassName = "modal-box" 
        modalTransition={{ timeout: 500 }} 
        backdropTransition={{ timeout: 0 }}
        centered
      >
        <ModalHeader 
            toggle={()=>imageGroupStore.toggleModal()} 
            close={
                <Button color="icon-action" className="close" onClick={()=>imageGroupStore.toggleModal()}>
                    <MdClose size="18" />
                </Button>}>{t('AddImageToGroupModalComponent.addToFolder')}
        </ModalHeader>
        <ModalBody> 
            <AddImageToGroupForm
                onSuccess = {()=>imageGroupStore.toggleModal()}
            />
          
        </ModalBody>
      </Modal>

    );

}
export default withRouter(inject(
    'imageGroupStore'
    )(observer(AddImageToGroupModal)))
