export default{

    "AccountPage": {
        "title": "Min konto",
        "loginSubtitle": "Logikk Info",
        "loginText": "Logget inn som",
        "editUsername": "Redigere brukernavn",
        "changePassword": "Endre passord",
        "personalInfoSubtitle": "Personlig informasjon",
        "familyMemberSubtitle": "Familiemedlemmer",
        "edit": "Rediger",
        "delete": "Slette",
        "default": "Standard",
        "paymentSubtitle": "Betaling",
        "paymentText": "Oppdatere lagret kredittkortinformasjon",
        "communicationSubtitle": "Kommunikasjon",
        "communicationText": "For kampanjer og nyhetsbrev opt-ins",
        "shippingSubtitle": "Leveringsadresser",
        "shippingText": "Bruk for betaling som forsendelsesinformasjon",
        "upcomingSubtitle": "Kommende",
        "upcomingText": "Bekreft omplanlegging eller avbryt",
        "noUpcomingText": "Du har ingen kommende økter",
        "tableDate": "Dato",
        "tableTime": "Tid",
        "tableDescription": "Beskrivelse",
        "tableAction": "Handlingen",
        "confirm": "Bekrefte",
        "reschedule": "Planlegge",
        "cancel": "Avbryt",
        "scheduleButton": "Planlegg nå",
        "deleteAddressButton": "Er du sikker på at du vil slette denne adressen?",
        "updateAddressModalTitle": "Oppdatere adresse",
        "updateUsernameModalTitle": "Oppdater brukernavn",
        "updatePasswordModalTitle": "Endre passord",
        "updatePersonalInfoModalTitle": "Oppdatere personlige opplysninger",
        "updateCommunicationModalTitle": "Oppdatere påmeldinger",
        "updatePaymentModalTitle": "Betaling",
        "scheduleModalTitle": "Tidsplan",
        "rescheduleModalTitle": "Planlegge",
        "clickhere": "Klikk her",
    },
    "ForgotPasswordPage": {
        "title": "Glemte passord",
        "subtitle": "En e-post vil bli sendt til e-postadressen din med ytterligere instruksjoner",
        "alertSuccess": "Du bør snart motta en e-post. Vennligst sjekk søppelpost- eller spam-e-postmappen hvis du ikke finner e-posten.",
        "backToLogin": "Tilbake til innlogging",
    },
    "LoginPage": {
        "title": "Logg inn for å se fantastiske bilder inspirert av deg!",
        "description": "Hvis du allerede har en kontologg her for å se bildene dine eller klikke på «Hendelsessøk» ovenfor for å søke etter en hendelse.",
        "email": "E-postadresse eller brukernavn",
        "password": "Passord",
        "forgotPass": "Glemt passord",
        "emailError": "Vennligst skriv inn en e-postadresse eller et brukernavn",
        "ajaxError": "Ugyldig brukernavn eller passord",
        "passwordError": "Vennligst skriv inn et passord",
        "loginButton": "Logg inn",
    },
    "ResetPasswordPage": {
        "title": "Skriv inn det nye passordet ditt",
        "alertSuccess": "Passordet ditt er tilbakestilt. Du vil bli omdirigert tilbake til innloggingssiden.",
        "backToLogin": "Tilbake til innlogging",
    },
    "SignupPage": {
        "title": "Opprette en konto",
        "p1": "Har du allerede en konto?",
        "p2": "for å vise bildene dine.",
        "createAccountLink": "Logg inn her",
        "email": "E-post",
        "password": "Passord",
        "signup": "melde seg på",
        "ajaxError": "Noe gikk galt! Vennligst forsøk på nytt.",
        "ajaxErrorOther": "Noe gikk galt! Vennligst forsøk på nytt.",
    },
    "ConfirmationPage": {
        "tabTitle": "Takk",
        "title": "Takk for at du legger inn bestillingen din.",
        "text": "Bestillingsnummeret ditt er",
        "relatedProducts": "Du er kanskje interessert i...",
    },
    "CheckoutPage": {
        "urlTitle": "Sikker utsjekking",
        "title": "Sikker utsjekking",
        "subtitle": "4096-biters SSL/TSL-beskyttet side",
    },
    "OrderReviewPage": {
        "urlTitle": "Sikker utsjekking",
        "tableProductName": "Produktnavn",
        "tablePrice": "Pris",
        "tableQuantity": "Antall",
        "tableSubtotal": "Delsum",
        "enter": "Skriv den inn nå",
        "apply": "Bruke",
        "cancel": "Avbryt",
        "promo": "Promo",
        "shipping": "Norge",
        "tax": "Skatt",
        "total": "Totalt",
    },
    "PaymentPage": {
        "urlTitle": "Sikker utsjekking",
    },
    "ShippingAddressPage": {
        "urlTitle": "Sikker utsjekking",
    },
    "ContractsPage": {
        "urlTitle": "Kontrakter",
        "title": "Kontrakter",
        "invalidPIN": "Ugyldig PIN-kode",
        "uploaded": "Lastet opp",
        "accepted": "Akseptert",
        "printContract": "Skrive ut kontrakt",
        "formLabel": "Jeg har lest og forstått vilkårene ovenfor. Jeg godtar herved vilkårene i denne avtalen.",
        "enterPin": "Skriv inn den numeriske stiften",
        "confirm": "Bekreft og send inn",
    },
    "DashboardPage": {
        "loading": "Laster inn...",
        "dashboard": "Dashboard",
        "title": "Du har for øyeblikket ikke en økt.",
        "check": "Sjekk",
        "pendingContracts": "ventende kontrakter",
        "contactUs": "kontakt oss",
        "noImages": "Du har ingen bilder for denne",
    },
    "EventSearchPage": {
        "title": "Søk etter delte arrangementer og latter",
        "urlTitle": "Søk etter hendelse",
        "p1": "Hvis du vil finne en hendelse, velger du en fra listen eller søker etter hendelseskode eller hendelsesdato.",
        "date": "Hendelsesdato",
        "code": "Privat kode",
        "public": "Offentlig arrangement",
        "search": "'Søk'",
    },
    "FavoritesPage": {
        "urlTitle": "Favoritt",
    },
    "FoldersPage": {
        "urlTitle": "Mappen",
        "viewFull": "Vis hele",
        "noFoldersFound": "Finner ingen mapper for dette",
    },
    "FormsPage": {
        "urlTitle": "Skjemaer",
        "title": "Skjemaer",
        "uploaded": "Lastet opp",
    },
    "GuestsPage": {
        "urlTitle": "Gjester",
        "title": "Gjester",
        "error": "Det oppstod en feil under oppdatering av gjesteinvitasjon.",
        "success": "Koblingen er vellykket på nytt",
        "copy": "Kopiert til utklippstavlen",
        "createNew": "Opprette nye",
        "invitationLinks": "Invitasjonskoblinger",
        "noLinksFound": "Ingen linker funnet for dette",
        "tableDescription": "Beskrivelse",
        "tableDateCreated": "Dato opprettet",
        "tableDateFirstUsed": "Dato som først brukes",
        "tableAction": "Handlingen",
        "copyLink": "Kopier kobling",
        "remove": "Fjerne",
        "invitedTitle": "Inviterte gjester",
        "noGuestsFound": "Ingen gjester funnet for dette",
        "tableName": "navn",
        "tableEmail": "E-post",
        "tableDateInvited": "Dato invitert",
        "edit": "Rediger",
        "resend": "Sende",
        "updateGuest": "Oppdater gjest",
        "createNewGuestInvitation": "Opprett ny gjesteinvitasjon",
        "generateText": "Generer en delbar kobling for å gi andre tilgang til bildene dine. Del lenken via sosiale medier en tekst eller via e-post.",
        "emailInvite": "E-postinvitasjon",
        "sharableLink": "Delbar kobling",
    },
    "HelpPage": {
        "urlTitle": "hjelp",
        "title": "hjelp",
        "ourInfo": "Vår informasjon",
        "hereToHelp": "Vi er her for å hjelpe",
        "chatBy": "Chat med oss ved å",
        "clickingHere": "Klikk her",
    },
    "HomePage": {
        "signIn": "logg inn",
        "eventSearch": "Søk etter hendelse",
        "privacy": "Personvernerklæringen",
        "userAccessAgreement": "Brukertilgangsavtale",
        "termsOfUse": "Vilkår for bruk",
        "close": "Lukk",
    },
    "OrderDetailsPage": {
        "urlTitle": "Rekkefølge",
        "photo": "Foto",
        "setCrop": "Angi beskjæring og alternativer",
        "cropRequired": "Avskjæring er nødvendig før kassen",
        "viewOrders": "Vis ordrer",
        "order": "Rekkefølge",
        "details": "Detaljer",
        "orderDate": "Ordredato",
        "makePayment": "Foreta betaling",
        "printOrder": "Rekkefølge",
        "orderDetails": "Ordredetaljer",
        "tableProduct": "Produktet",
        "tablePrice": "Pris",
        "tableQty": "Antall",
        "tableTotal": "Totalt",
        "subtotal": "Delsum",
        "promo": "Promo",
        "shipping": "Norge",
        "tax": "Skatt",
        "payment": "Betaling",
        "total": "Totalt",
        "finalize": "Fullføre valget",
        "shippingInfo": "Shipping Info",
        "delivery": "Levering",
        "confirm": "Bekreft innsending",
        "cancel": "Avbryt",
        "warning": "Denne handlingen kan ikke angres i appen.",
        "submitting": "Sende...",
        "yesConfirm": "Ja bekrefte",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Ordrenummer",
        "tableOrderDate": "Ordredato",
        "tableDateCreated": "Dato opprettet",
        "tableShippedTo": "Leveres til",
        "tableStatus": "Status",
        "tableDescription": "Beskrivelse",
        "tableOpenBalance": "Startsaldoen",
        "tableTotal": "Totalt",
        "orders": "Bestillinger",
        "orderHistory": "Bestillingshistorikk",
        "preOrders": "Forhåndsbestillinger",
        "noOrders": "Foreløpig har du ingen ordre ennå.",
    },
    "SharePage": {
        "urlTitle": "Dette er tittelsiden",
        "title": "Dette er tittelsiden",
        "iframe": "Dette er en iframe tom side.",
        "shareImage": "Del dette bildet",
    },
    "SplashPage": {
        "wereSorry": "Vi beklager",
        "doesntSeemTo": "ser ikke ut til å være et gyldig deletoken.",
        "error": "Vi beklager at det ser ut som noe gikk galt. Varsle systemansvarlig hvis dette fortsetter.",
        "subdomainError": "Denne appen forventer et underdomene. Vennligst sjekk url og kontroller at det ser ut som <Ditt firmanavn.\".inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Legg til i mappe",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Sosial andel",
        "generatingShareable": "Genererer delbar url...",
    },
    "cartViewComponent": {
        "urlTitle": "Handlekurv",
        "title": "Handlekurv",
        "photo": "Foto",
        "swap": "Bytte",
        "youHave": "Du har",
        "youHaveAdded": "Du har lagt til",
        "unitsRemaining": "enheter som gjenstår å bli lagt til bestillingen din.",
        "unitsTooMany": "enheter for mange til bestillingen din.",
        "cartFullModalTitle": "For mange enheter",
        "tooMany": "Denne ordren inneholder for mange enheter.  Vennligst fjern noen produkter før du sjekker ut.",
        "have": "Har",
        "another": "En",
        "a": "A",
        "ok": "ok",
        "promoCode": "promo/kupong kode?",
        "promoCode1": "Fremme koden",
        "removePromoCode": "Fjern kampanjekode",
        "select": "Velg",
        "loading": "Lasting",
        "setCropAndOptions": "Angi beskjæring og alternativer",
        "cropRequired": "Avskjæring er nødvendig før kassen",
        "checkout": "sjekk ut",
        "tableAction": "Handlingen",
        "tableProductName": "Produktnavn",
        "tablePrice": "Pris",
        "tableQuantity": "Antall",
        "tableSubtotal": "Delsum",
        "enterNow": "Skriv den inn nå",
        "apply": "Bruke",
        "cancel": "Avbryt",
        "promo": "Promo",
        "noItems": "Ingen varer i handlekurven.",
        "checkOurProducts": "Sjekk våre produkter",
        "continueShopping": "Fortsett å handle",
    },
    "createOrderIndexComponent": {
        "title": "Opprette en ordre",
        "step1cta": "Fortsette",
        "finish": "Fullfør",
    },
    "cropViewComponent": {
        "cropImage": "Beskjær bilde",
        "prepaid": "Forhåndsbetalte",
        "shoppingCart": "Handlekurv",
        "image": "Bilde",
        "zoomIn": "Zoom inn",
        "zoomOut": "Zoom ut",
        "reset": "Tilbakestille",
        "cropOrientation": "Beskjære retning",
        "selectPhotoOption": "Velg bildealternativ",
        "pleaseSelect": "Vennligst velg ...",
        "selectPhotoEnhancement": "Velg bildeforbedring",
        "selectColorEffect": "Velg fargeeffekt",
        "notes": "Notater",
        "apply": "Bruke",
        "backToPreorder": "Tilbake til forhåndsbestilling",
        "backToShoppingCart": "Tilbake til handlekurven",
    },
    "photoSelectComponent": {
        "swapPhoto": "Bytt bilde",
        "prepaid": "Forhåndsbetalte",
        "shoppingCart": "Handlekurv",
        "backToPreorder": "Tilbake til forhåndsbestilling",
        "backToShoppingCart": "Tilbake til handlekurven",
    },
    "photoTileComponent": {
        "image": "Bilde",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Kategori",
        "title": "Kategori",
        "selectYourProduct": "Velg produktet ditt",
        "selectCateogry": "Velg kategori",
        "sortBy": "Sorter etter",
        "bestSelling": "Bestselgende",
        "viewLarger": "Vis større",
        "viewDetails": "Vis detaljer",
    },
    "productGroupListComponent": {
        "urlTitle": "Produkter",
        "title": "Velg produktkategorien",
        "viewTheseProducts": "Vis disse produktene",
    },
    "productDetailTabsComponent": {
        "description": "Beskrivelse",
        "features": "Funksjoner",
        "delivery": "Levering",
        "noDescriptionFound": "Ingen beskrivelse funnet",
        "noFeatureFound": "Ingen funksjonsinformasjon funnet",
        "noDeliveryFound": "Ingen leveringsinformasjon funnet",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Feil under tillegging i handlekurven. Oppdater siden eller rapporter dette produktet til vår støtte.",
        "product": "Produktet",
        "cancelAndBack": "Avslutt og tilbake til oppføring",
        "selectPhotoOption": "Velg bildealternativ",
        "pleaseSelect": "Vennligst velg ...",
        "selectPhotoEnhancement": "Velg bilde-enahncement",
        "selectColorEffect": "Velg fargeeffekt",
        "was": "Var",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Velg en mappe eller opprett ny",
    },
    "addressFormSectionComponent": {
        "firstName": "Fornavn",
        "lastName": "Etternavn",
        "addressLine1": "Adresselinje 1",
        "addressLine2": "Adresselinje 2",
        "city": "Byen",
        "region": "Regionen",
        "state": "Tilstand",
        "postalCode": "Postnummer",
        "zipCode": "postnummer",
        "country": "Land",
    },
    "addToCartFormComponent": {
        "quantity": "Antall",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Vennligst skriv inn e-postadressen din",
        "email": "E-post",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Vennligst bekreft det nye passordet ditt",
        "password": "Passord",
        "enterPassword": "Skriv inn det nye passordet ditt",
        "confirmPassword": "Bekreft passord",
        "setNewPassword": "Angi nytt passord",
    },
    "signupFormComponent": {
        "firstNameValidation": "Vennligst skriv inn fornavnet ditt",
        "lastNameValidation": "Vennligst skriv inn etternavnet ditt",
        "invalidEmailValidation": "Vennligst skriv inn en gyldig e-post",
        "emailRequiredValidation": "Vennligst skriv inn en e-postadresse",
        "confirmPasswordValidation": "Vennligst bekreft passordet ditt",
        "countryRequiredValidation": "Velg et land",
        "passwordsMustMatch": "Passord må samsvare",
        "confirm": "Bekrefte",
        "firstName": "Fornavn",
        "lastName": "Etternavn",
        "email": "E-postadresse",
        "password": "Passord",
        "confirmPassword": "Bekreft passord",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Vennligst skriv inn fornavnet ditt",
        "lastNameValidaiton": "Vennligst skriv inn etternavnet ditt",
        "addressLine1Validation": "Vennligst skriv inn din gateadresse",
        "cityValidation": "Vennligst skriv inn byen din",
        "stateValidation": "Vennligst skriv inn din tilstand",
        "postalCodeValidation": "Vennligst skriv inn postnummeret ditt",
        "invalidPostalCode": "Vennligst skriv inn et gyldig postnummer",
        "countryValidation": "Vennligst skriv inn ditt land",
        "emailValidation": "Vennligst skriv inn en gyldig e-post",
        "billingInformation": "Faktureringsinformasjon",
        "selectSavedAddress": "Velg lagret adresse",
        "email": "E-postadresse",
        "phoneNumber": "Telefonnummer",
        "saveAsDefault": "Lagre faktureringstillegg som standard",
        "saveAsShipping": "Samme som leveringsadresse",
        "promotionalTexts": "Kan vi sende deg reklametekster?",
        "dataRates": "(Data- og meldingsfrekvenser kan gjelde)",
        "promotionalEmails": "Kan vi sende deg salgsfremmende e-poster?",
        "continue": "Fortsett",
        "continueShopping": "Fortsett å handle",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Bruke valgt adresse",
        "useDefaultAddress": "Bruke standardadresse",
        "useClientAddress": "Bruke klientadresse",
        "saveAsDefault": "Lagre som standard",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Velg en fraktmetode",
    },
    "communicationFormComponent": {
        "permissionCall": "Kan vi ringe deg med salgsfremmende informasjon?",
        "permissionEmail": "Kan vi sende deg en e-post med salgsfremmende informasjon?",
        "permissionText": "Kan vi sende deg en sms med salgsfremmende informasjon? (Meldinger og datahastigheter kan gjelde)",
    },
    "editAddressFormComponent": {
        "name": "navn",
        "phoneNumber": "Telefonnummer",
        "addressLine1": "Adresselinje 1",
        "addressLine2": "Adresselinje 2",
        "city": "Byen",
        "region": "Regionen",
        "postalCode": "Postnummer",
        "country": "Land",
        "deliveryInstructions": "Instruksjoner for levering",
        "accessCode": "Tilgangskode",
        "defaultAddress": "Standardadresse",
        "applyChange": "Bruk endring",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Vennligst skriv inn passordet ditt",
        "passwordCannotBeSame": "Nytt passord kan ikke være det samme som det gamle",
        "confirmPassword": "Vennligst bekreft det nye passordet ditt",
        "passwordsMustMatch": "Passord må samsvare",
        "existingPassword": "Eksisterende passord",
        "currentPassword": "Gjeldende passord",
        "newPassword": "Nytt passord",
        "confirmNewPassword": "Bekreft nytt passord",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Velg et arrangement",
        "selectEventDate": "Velg en arrangementsdato",
        "enterCode": "Vennligst skriv inn en kode",
    },
    "inputSectionComponent": {
        "selectDate": "Velg en dato",
        "placeCodeHere": "Legg inn koden her",
        "input": "Input",
        "selectEvent": "Velg en hendelse",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoritter",
        "ajaxError": "Det oppstod en feil under oppretting av gjesteinvitasjon",
        "invalidEmail": "Vennligst skriv inn en gyldig e-post",
        "noEmail": "Vennligst skriv inn en e-post",
        "noFirstName": "Skriv inn et fornavn",
        "noLastName": "Angi etternavn",
        "email": "E-postadresse",
        "firstName": "Fornavn",
        "lastName": "Etternavn",
        "session": "Økt",
        "image": "Bilde",
        "sendEmail": "Send e-post",
        "onlyInclude": "Inkluder bare Favoritter fra denne",
    },
    "joyFormComponent": {
        "ageMonth": "måned(er)",
        "yes": "ja",
        "no": "nei",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Vennligst skriv inn en beskrivelse for linken din",
        "description": "Beskrivelse",
        "onlyIncludeFavoritesFromThis": "Inkluder bare Favoritter fra denne",
        "createLink": "Opprett kobling",
    },
    "personalInfoComponent": {
        "invalidEmail": "Vennligst skriv inn en gyldig e-postadresse",
        "primaryPhone": "Primære telefonen",
        "secondaryPhone": "Sekundær telefon",
        "mobilePhone": "Dette er en mobiltelefon",
        "streetAddress": "Gateadresse",
        "house": "Huset",
        "apartment": "Leilighet",
        "roomNumber": "Romnummer (Room Number)",
        "city": "Byen",
        "region": "Regionen",
        "state": "Tilstand",
        "postalCode": "Postnummer",
        "country": "Land",
        "pleaseSelect": "Vennligst velg ...",
    },
    "familyMemberComponent": {
        "nickname": "Kallenavn",
        "familyMember": "Familiemedlem",
        "relationship": "Forholdet",
        "gender": "Kjønn",
        "BirthDate": "Fødselsdato",
        "familyMemberSubtitle": "Familiemedlemmer",
        "tableAction": "Handlingen",
    },
    "usernameFormComponent": {
        "enterUsername": "Vennligst skriv inn et nytt brukernavn",
        "onlineUsername": "Brukernavn på nett",
        "username": "Brukernavn",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Flere navigasjonselementer",
        "download": "Laste ned",
        "removeFrom": "Fjern fra",
        "modifyImage": "Endre bilde",
        "folders": "Mapper",
        "makePrimary": "Gjør primær",
        "yearbookImage": "Årbok bilde",
        "makeSecondary": "Gjør sekundær",
        "socialShare": "Sosial andel",
        "unhide": "Vis",
        "hide": "Skjul",
    },
    "imageTileComponent": {
        "view": "Vis",
        "sessions": "Økter",
        "setAsPrimary": "Angi som primært bilde",
        "setAsSecondary": "Angi som sekundært bilde",
        "image": "Bilde",
        "caution": "Forsiktighet",
        "separateSessionsMessage1": "Du kan ikke legge til bilder fra to separate",
        "separateSessionsMessage2": "til samme handlevogn",
        "doNotAdd": "Ikke legg til denne varen i handlekurven min.",
        "clearCart": "Fjern innholdet i handlekurven min, og legg deretter til denne varen i handlekurven min.",
    },
    "loginPromptComponent": {
        "title": "Oops!",
        "message": "Vennligst --logg inn- eller --registrer deg - hvis du ønsker å favorittbilder gruppebilder sammen eller legge til bilder i handlekurven.",
    },
    "menuDropdownComponent": {
        "dashboard": "Dashboard",
        "sessions": "Økter",
        "favorites": "Favoritter",
        "contracts": "Kontrakter",
        "orders": "Bestillinger",
        "prepaidOrders": "Forhåndsbetalte bestillinger",
        "guests": "Gjester",
        "account": "Konto",
    },
    "userDropdownComponent": {
        "logout": "Logg ut",
    },
    "navBarPage": {
        "toggleNavigation": "Veksle navigasjon",
        "cart": "Handlevogn",
        "photoshootsAndImages": "Photoshoots og bilder",
        "contracts": "Kontrakter",
        "reviewSubmit": "Gjennomgå kontrakter og send inn",
        "forms": "Skjemaer",
        "reviewForms": "Se gjennom nettskjemaer og send inn",
        "orders": "Bestillinger",
        "historyPrepaid": "Historikk og forhåndsbetalte bestillinger",
        "orderHistory": "Bestillingshistorikk",
        "products": "Produkter",
        "guests": "Gjester",
        "categoriesProducts": "Kategorier og produkter",
        "sharedFamily": "Delt med familie",
        "friends": "Venner",
        "myAccount": "Min konto",
        "login": "Logg inn",
        "billingInfo": "faktureringsinformasjon",
        "help": "hjelp",
        "chat": "Chat for støtte på internett",
        "shoppingCart": "Handlekurv",
        "checkout": "Gjør deg klar til kassen",
        "signout": "Logg av",
        "endGuest": "Avslutt gjesten min",
        "securelySignout": "La meg logge ut sikkert",
    },
    "productGalleryComponent": {
        "viewLarger": "Vis større",
    },
    "responsiveHeaderComponent": {
        "photos": "Bilder",
        "guests": "Gjester",
        "allImages": "Alle bilder",
        "favorites": "Favoritter",
        "folders": "Mapper",
        "autoPlay": "Autokjør",
        "download": "Laste ned",
        "cart": "Handlevogn",
    },
    "sessionsPage": {
        "Name": "navn",
        "Date": "Dato",
        "CreateNewText": "Opprette nye",
        "ViewText": "Vis",
        "DeleteText": "Slette",
        "EditText": "Rediger",
    },
}
