import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Button, Label, Collapse, Alert, Spinner } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { InvoiceLineItem } from "../../interfaces/invoice.int";
import { postVariants } from "../../components/util";
import noImg from "../../components/imageTile/broken-img.png";

const OrderReviewPage: React.FC<any> = ({
  authStore,
  navigationStore,
  ecomStore,
  sessionStore,
}) => {
  const { t } = useTranslation();
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    //console.log(ecomStore.cart);
    //console.log('Cart Items');
    //console.log(ecomStore.cartItems);
  });

  function resetCode() {
    setCode("");
    setShowPromoInput(false);
  }
  async function addCode(code: string) {
    await ecomStore.enterPromoCode(code);
    resetCode();
  }

  function thumbnail(itm: any, i: number) {
    if (!itm.allowImageSelection) {
      return "";
    }

    return (
      <figure className="thumb-edit">
        <div
          className="thumb thumb__not-rounded"
          style={{
            backgroundImage: `url(${
              `${itm?.ImageUrl || noImg}${
                !!itm?.ColorizationKey
                  ? `&colorizationKey=${itm.ColorizationKey}`
                  : ""
              }` || noImg
            }`,
          }}
        >
          <img src={`${itm?.ImageUrl || noImg}`} alt={itm.Description} />
        </div>
      </figure>
    );
  }

  function cartItem(itm: any, i: number) {
    // console.log('Cart itemize');
    // console.log(itm);
    return (
      <Tr key={`cartItem${i}`} className="parent-product">
        <Td className="th-product-thumbs" scope="row">
          <div className="mb-2 description">
            <strong>{itm.Description}</strong>{" "}
            <span className="font-weight-normal text-muted">
              {itm.ImageNumber &&
                !itm.PackageItemKey &&
                !ecomStore.cartItems[i + 1]?.PackageItemKey &&
                itm.allowImageSelection &&
                itm?.ImageUrl &&
                `(Image ${itm.ImageNumber})`}
            </span>
          </div>
          {thumbnail(itm, i)}
        </Td>
        <Td className="td-price text-right">{itm.PriceStringFormatted}</Td>
        <Td className="text-center td-product-quantity">{itm.Quantity}</Td>
        <Td className="text-right">
          {!itm.PackageItemKey && itm.ExtendedStringFormatted}
        </Td>
      </Tr>
    );
  }

  function packageComponent(itm: any, i: number) {
    // console.log('Cart package itemize');
    // console.log(itm);
    return (
      <Tr key={i} className="sub-product">
        <Td className="th-product-thumbs" scope="row">
          {itm.allowImageSelection ? (
            <strong
              className="d-flex"
              style={{ paddingRight: isBreakDownSm ? "5rem" : "" }}
            >
              <div
                style={{
                  maxWidth: 100,
                  flex: "0 0 100px",
                  marginRight: "1rem",
                }}
              >
                {thumbnail(itm, i)}
              </div>
              <div>
                {itm.Description}{" "}
                <span className="font-weight-normal text-muted">
                  {itm.ImageNumber &&
                    itm?.ImageUrl &&
                    `(Image ${itm.ImageNumber})`}
                </span>
              </div>
            </strong>
          ) : (
            <strong>{itm.Description}</strong>
          )}
        </Td>
        <Td className="td-price text-right responsive-hidden"></Td>
        <Td
          className={`text-center${itm.Quantity ? " td-product-quantity" : ""}`}
        >
          {itm.Quantity}
        </Td>
        <Td className="td-subtotal text-right responsive-hidden"></Td>
      </Tr>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t("OrderReviewPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      {ecomStore.cart && (
        <>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={postVariants}
          >
            <h2>{`Order review`}</h2>

            <Table className="table table-cart">
              <Thead>
                <Tr>
                  <Th scope="col" className="th-product">
                    {t("OrderReviewPage.tableProductName")}
                  </Th>
                  <Th scope="col" className="th-price text-right">
                    {t("OrderReviewPage.tablePrice")}
                  </Th>
                  <Th scope="col" className="th-quantity text-center">
                    {t("OrderReviewPage.tableQuantity")}
                  </Th>
                  <Th scope="col" className="th-subtotal text-right">
                    {t("OrderReviewPage.tableSubtotal")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {ecomStore.cartItems.map((itm: InvoiceLineItem, i: number) =>
                  !!itm.PackageItemKey
                    ? packageComponent(itm, i)
                    : cartItem(itm, i)
                )}
              </Tbody>

              {!isBreakDownSm && (
                <tfoot className="cart-footer">
                  <tr>
                    <th scope="col" colSpan={3} className="font-weight-bold">
                      {`Subtotal`}
                    </th>
                    <td className="text-right font-weight-bold">
                      {ecomStore.cart.SubtotalStringFormatted}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" colSpan={3}>
                      <form
                        className="form-promotion"
                        style={{ maxWidth: 300 }}
                      >
                        <Label htmlFor="promocode">
                          {`Have ${
                            !!ecomStore.appliedPromotions.length
                              ? "another"
                              : "a"
                          } promo/coupon code?`}{" "}
                          {!showPromoInput && (
                            <a
                              onClick={(e) => {
                                setShowPromoInput(true);
                                e.preventDefault();
                              }}
                              href="#"
                              className="body-link-thin"
                            >
                              {t("OrderReviewPage.enter")}
                            </a>
                          )}
                        </Label>
                        {ecomStore.promoCodeError && (
                          <Alert color="danger" size="sm">
                            {ecomStore.promoCodeError}
                          </Alert>
                        )}
                        <Collapse isOpen={showPromoInput}>
                          <div className="form-field text-appear-container">
                            <input
                              value={code}
                              id="promocode"
                              type="text"
                              onChange={(e: any) => setCode(e.target.value)}
                              className="form-control"
                              placeholder="Promo code"
                            />
                            <Button
                              color="default-link"
                              onClick={() => addCode(code)}
                            >
                              {t("OrderReviewPage.apply")}
                            </Button>
                            <Button
                              color="icon-action text-appear"
                              onClick={() => {
                                setShowPromoInput(false);
                                resetCode();
                              }}
                            >
                              <FiX size="20" className="text-danger" />
                              <span className="sr-only">
                                {t("OrderReviewPage.cancel")}
                              </span>
                            </Button>
                          </div>
                        </Collapse>
                      </form>
                    </th>
                    <td className="text-right">&nbsp;</td>
                  </tr>
                  {ecomStore.appliedPromotions?.map((pro: any, i: number) => (
                    <tr key={i} className="text-appear-container">
                      <th scope="col" colSpan={2} className="pt-0">
                        {t("OrderReviewPage.promo")} {pro.PromoCode} -{" "}
                        {pro.Description}{" "}
                        <Button
                          onClick={() =>
                            ecomStore.deletePromoCode(pro.PromoCode)
                          }
                          color="icon-action"
                          title="Remove promo code"
                          className="text-appear removePromo"
                        >
                          <AiOutlineDelete className="text-danger" />
                        </Button>
                      </th>
                      <td className="text-right pt-0" colSpan={2}>
                        {pro.DiscountAmountStringFormatted}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <th scope="col" colSpan={3}>
                      {t("OrderReviewPage.shipping")}
                    </th>
                    <td className="text-right">
                      {ecomStore.cart.ShippingAmountStringFormatted}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" colSpan={3}>
                      {t("OrderReviewPage.tax")}
                    </th>
                    <td className="text-right">
                      {ecomStore.cart.TaxTotalStringFormatted}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th
                      scope="col"
                      colSpan={3}
                      className="pt-4 font-weight-bold font-size-lg text-uppercase"
                    >
                      {t("OrderReviewPage.total")}
                    </th>
                    <td className="pt-4 font-weight-bold text-primary text-right font-size-lg">
                      {ecomStore.cart.TotalStringFormatted}
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>

            {isBreakDownSm && (
              <div className="cart-footer">
                <div className="row">
                  <div className="col-7 font-weight-bold">
                    {t("OrderReviewPage.tableSubtotal")}
                  </div>
                  <div className="col-5 text-right">
                    {ecomStore.cart.SubtotalStringFormatted}
                  </div>
                </div>

                <form className="form-promotion mt-3">
                  <Label htmlFor="promocode">
                    {`Have ${
                      !!ecomStore.appliedPromotions.length ? "another" : "a"
                    } promo/coupon code?`}{" "}
                    {!showPromoInput && (
                      <a
                        onClick={(e) => {
                          setShowPromoInput(true);
                          e.preventDefault();
                        }}
                        href="#"
                        className="body-link-thin"
                      >
                        {t("OrderReviewPage.enter")}
                      </a>
                    )}
                  </Label>
                  {ecomStore.promoCodeError && (
                    <Alert color="danger" size="sm">
                      {ecomStore.promoCodeError}
                    </Alert>
                  )}
                  <Collapse isOpen={showPromoInput}>
                    <div className="form-field text-appear-container">
                      <input
                        value={code}
                        id="promocode"
                        type="text"
                        onChange={(e: any) => setCode(e.target.value)}
                        className="form-control"
                        placeholder="Promo code"
                      />
                      <Button
                        color="default-link"
                        onClick={() => addCode(code)}
                      >
                        {t("OrderReviewPage.apply")}
                      </Button>
                      <Button
                        color="icon-action text-appear"
                        onClick={() => {
                          setShowPromoInput(false);
                          resetCode();
                        }}
                      >
                        <FiX size="20" className="text-danger" />
                        <span className="sr-only">
                          {t("OrderReviewPage.cancel")}
                        </span>
                      </Button>
                    </div>
                  </Collapse>
                </form>

                {ecomStore.appliedPromotions?.map((pro: any, i: number) => (
                  <div key={i} className="row mt-2">
                    <div className="pt-0 col-7">
                      {t("OrderReviewPage.promo")} {pro.PromoCode} -{" "}
                      {pro.Description}{" "}
                      <Button
                        onClick={() => ecomStore.deletePromoCode(pro.PromoCode)}
                        color="icon-action"
                        title="Remove promo code"
                        className="text-appear removePromo"
                      >
                        <AiOutlineDelete className="text-danger" />
                      </Button>
                    </div>
                    <div className="text-right pt-0 col-5">
                      {pro.DiscountAmountStringFormatted}
                    </div>
                  </div>
                ))}

                <div className="row mt-2">
                  <div className="col-7">{t("OrderReviewPage.shipping")}</div>
                  <div className="col-5 text-right">
                    {ecomStore.cart.ShippingAmountStringFormatted}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-7">{t("OrderReviewPage.tax")}</div>
                  <div className="col-5 text-right">
                    {ecomStore.cart.TaxTotalStringFormatted}
                  </div>
                </div>
                <div className="row mt-3 pt-3 border-top">
                  <div className="col-7 font-weight-bold font-size-lg text-uppercase">
                    {`Total`}
                  </div>
                  <div className="col-5 font-weight-bold text-primary text-right font-size-lg">
                    {ecomStore.cart.TotalStringFormatted}
                  </div>
                </div>
              </div>
            )}
          </motion.div>

          <Row className="mt-5 align-items-center">
            <Col sm="6" className="text-center text-sm-right order-sm-2">
              {authStore.requirePaymentOnline && ecomStore.cart.Total > 0 && (
                <Button
                  type="button"
                  color="primary"
                  className="px-5"
                  onClick={() => navigationStore.push("/checkout/payment")}
                >{`Continue`}</Button>
              )}
              {(!authStore.requirePaymentOnline ||
                ecomStore.cart.Total === 0) && (
                <Button
                  type="button"
                  color="primary"
                  className="px-5"
                  spi
                  onClick={async () => {
                    setLoading(true);
                    await ecomStore.submitOrderNoPayment();
                    setLoading(false);
                    navigationStore.push("/checkout/confirmation");
                  }}
                >
                  {loading ? <Spinner /> : `Submit Order`}
                </Button>
              )}
            </Col>
            <Col
              sm="6"
              className="text-center text-sm-left order-sm-1 mt-3 mt-sm-0"
            >
              <Button
                type="button"
                color="default-link"
                onClick={() => navigationStore.goBack()}
              >
                <FiArrowLeft className="mr-2" />
                {`Previous page`}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default inject(
  "authStore",
  "navigationStore",
  "ecomStore",
  "sessionStore"
)(observer(OrderReviewPage));
