import React, { useRef, useEffect, useState, Fragment } from "react";
import { observer, inject } from "mobx-react";
import ImageGallery from "react-image-gallery";
import {
  Button,
  Spinner,
  Modal,
  Col,
  Row,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlinePlayCircle,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlinePauseCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "../util/getDimensions";
import ImageTileDropdown from "../imageTile/imgTileDropdown/imgTileDropdown";
import ImageTileButton from "../imageTile/imgTIleButton/imgTileButton";

const Lightbox: React.FC<any> = ({
  authStore,
  lightboxStore,
  sessionStore,
  imageGroupStore,
  createOrderStore,
  ecomStore,
  clientStore,
  compositeStore,
}) => {
  const { t } = useTranslation();
  const gallery: any = useRef(null);
  const { innerHeight } = useWindowDimensions();
  const [imagesLoaded, setImagesLoaded] = useState(
    new Array(lightboxStore.lightboxPhotos?.length).fill(false)
  );
  const [modalCart, setModalCart] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  function playGallery() {
    gallery.current.fullScreen();
    gallery.current.play();
  }

  useEffect(() => {
    setImagesLoaded(
      new Array(lightboxStore.lightboxPhotos?.length).fill(false)
    );
  }, [lightboxStore.lightboxPhotos]);

  useEffect(() => {
    if (lightboxStore.playLightbox) {
      playGallery();
    }
  }, [lightboxStore.playLightbox]);

  const toggleCartModal = (e: any) => {
    setModalCart(!modalCart);
    e.preventDefault();
  };

  async function handleDeleteCartAndAddItem(e: any) {
    // Show spinner for delete button
    setSpinner(true);

    // Call DELETE API
    await ecomStore.deleteCart();

    // Toggle Modal
    toggleCartModal(e);

    // Set spinner to false
    setSpinner(false);

    // Return flow to addCartToSelected
    addToCartSelected(e);
  }

  async function addToCartSelected(e: any) {
    lightboxStore.showLightbox = false;
    lightboxStore.showLightbox = true;
    if (ecomStore.cart.InvoiceDetails?.length) {
      for (let cartItem of ecomStore.cart.InvoiceDetails) {
        if (sessionStore.activeSession.Key !== cartItem.SessionKey) {
          toggleCartModal(e);
          return;
        }
      }
    }
    const img = sessionStore.activeSessionImages[lightboxStore.activeIndex];

    if (img.compositeKey) {
      // Runs when trying to add an previously created composite to the cart
      await compositeStore.getCompositeByKey(img.compositeKey);
      const templateKeyToFind = compositeStore.activeComposite.TemplateKey;
      // Find price list item
      for (let priceListGroup of ecomStore.priceList.Groups) {
        // if (sessionStore.activeSession.Key !== )

        for (let item of priceListGroup.Items) {
          if (!item.CompositeTemplateKey) {
            continue;
          }
          if (item.CompositeTemplateKey === templateKeyToFind) {
            ecomStore.selectProductGroup(priceListGroup.Key);
            ecomStore.selectProduct(item.Key);
            createOrderStore.setStepNumber(3);
          }
        }
      }
    } else {
      createOrderStore.setStepNumber(1);
    }

    lightboxStore.closeLightBox();
    await createOrderStore.toggleSlider();
    ecomStore.selectedImageFileName =
      sessionStore.activeSessionImages[lightboxStore.activeIndex].fileName;
    ecomStore.selectedImageData =
      sessionStore.activeSessionImages[lightboxStore.activeIndex];
    /*
    console.log('Pressed cart icon');
    console.log('Session: ' + img.sessionKey );
    console.log('Item Key: ' + img.key );
    console.log('Filname: ' + img.fileName );
    */
  }

  async function deleteImageSelected(e: any) {
    // Show spinner for delete button
    setSpinner(true);

    await imageGroupStore.deleteSingleImage(
      sessionStore.activeSessionImages[lightboxStore.activeIndex]?.key
    );

    await sessionStore.refreshSessionDetail(sessionStore.activeSession.Key);

    // Set spinner to false

    setSpinner(false);

    lightboxStore.closeLightBox();
  }

  const leftNav = (onClick: any, disabled: boolean) => (
    <Button
      color="icon"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next slide"
      className="slide-nav-left"
    >
      <MdKeyboardArrowLeft size="32" />
    </Button>
  );

  const rightNav = (onClick: any, disabled: boolean) => (
    <Button
      color="icon"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous slide"
      className="slide-nav-right"
    >
      <MdKeyboardArrowRight size="32" />
    </Button>
  );

  const fullNav = (onClick: any, isFullscreen: boolean) => (
    <Button
      color="icon"
      onClick={onClick}
      aria-label="Open fullscreen"
      className="slide-nav-fullscreen"
    >
      {isFullscreen ? (
        <AiOutlineFullscreenExit size="24" />
      ) : (
        <AiOutlineFullscreen size="24" />
      )}
    </Button>
  );

  const playPauseNav = (onClick: any, isPlaying: boolean) => (
    <Button
      color="icon"
      onClick={onClick}
      aria-label="Play or pause slideshow"
      className="slide-nav-play-pause"
    >
      {isPlaying ? (
        <AiOutlinePauseCircle size="24" />
      ) : (
        <AiOutlinePlayCircle size="24" />
      )}
    </Button>
  );

  const handleHeartClick = () => {
    lightboxStore.showLightbox = false;
    lightboxStore.showLightbox = true;
    imageGroupStore.favoriteImageList.includes(
      sessionStore.activeSessionImages[lightboxStore.activeIndex]?.fileName
    )
      ? imageGroupStore.statefulRemoveImageFromGroup(
          sessionStore.activeSessionImages[lightboxStore.activeIndex]?.fileName,
          "Favorites"
        )
      : imageGroupStore.clientAddImageToGroup(
          sessionStore.activeSessionImages[lightboxStore.activeIndex]?.fileName,
          "Favorites"
        );
  };

  const imageTileMenu = () => (
    <>
      <ImageTileButton
        onClick={addToCartSelected}
        className="lb-cart btn-add-to-cart"
      >
        <AiOutlineShoppingCart size="24" />
      </ImageTileButton>

      {sessionStore?.activeSession?.IsGuest ||
      !(
        sessionStore.activeSession.Images.AllowImageDelete ||
        sessionStore.activeSessionImages[lightboxStore.activeIndex]?.AllowDelete
      ) ? null : (
        <>
          {!(clientStore.statusDescription === "Free") && (
            <>
              <ImageTileButton
                onClick={deleteImageSelected}
                className="delete-single-image"
              >
                {spinner ? (
                  <Spinner size="lg" color="primary" />
                ) : (
                  <AiOutlineDelete size="28" />
                )}
              </ImageTileButton>
            </>
          )}
        </>
      )}

      <ImageTileButton className="lb-favorite" onClick={handleHeartClick}>
        {imageGroupStore.favoriteImageList.includes(
          sessionStore.activeSessionImages[lightboxStore.activeIndex]?.fileName
        ) ? (
          <AiFillHeart size="24" />
        ) : (
          <AiOutlineHeart size="24" />
        )}
      </ImageTileButton>

      <div className="slide-action-buttons">
        <ImageTileDropdown
          inLightBox={true}
          img={sessionStore.activeSessionImages[lightboxStore.activeIndex]}
        />
      </div>
    </>
  );

  return (
    <Fragment>
      <div
        className={`lightbox-wrapper ${
          lightboxStore.showLightbox ? "active" : ""
        }`}
        style={{
          height: isBreakDownSm
            ? parseFloat(innerHeight) - 64
            : innerHeight - 118,
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        {lightboxStore.showLightbox && (
          <>
            {/* conditionally including it forces it to lose state */}
            {/* onImageLoad not called on edge cases so ignoring spinner */}
            {!imagesLoaded[lightboxStore.activeIndex] &&
              lightboxStore.activeIndex !== 0 &&
              lightboxStore.activeIndex !==
                lightboxStore.lightboxPhotos.length - 1 && (
                <div className="spinner-center">
                  <Spinner />
                </div>
              )}
            <ImageGallery
              ref={gallery}
              lazyLoad
              startIndex={lightboxStore.activeIndex}
              onSlide={(e) => {
                lightboxStore.activeIndex = e;
                setImagesLoaded(
                  imagesLoaded.map((loadState, idx) => {
                    if (idx === e) {
                      return true;
                    }
                    return loadState;
                  })
                );
              }}
              items={lightboxStore.lightboxPhotos || []}
              onImageLoad={() =>
                setImagesLoaded(
                  imagesLoaded.map((loadState, idx) => {
                    if (idx === lightboxStore.activeIndex) {
                      return true;
                    }
                    return loadState;
                  })
                )
              }
              renderLeftNav={leftNav}
              renderRightNav={rightNav}
              renderFullscreenButton={fullNav}
              renderPlayPauseButton={playPauseNav}
              renderCustomControls={imageTileMenu}
            />
            <Button
              className="slide-nav-close"
              color="icon"
              onClick={() => lightboxStore.closeLightBox()}
            >
              <MdClose size="28" />
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={modalCart}
        toggle={toggleCartModal}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="md"
      >
        <ModalHeader
          toggle={toggleCartModal}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleCartModal}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("imageTileComponent.caution")}
        </ModalHeader>
        <ModalBody>
          <p>
            {t("imageTileComponent.separateSessionsMessage1")}{" "}
            {authStore.jobPlural.toLowerCase()}{" "}
            {t("imageTileComponent.separateSessionsMessage2")}
          </p>
          <Row>
            <Col>
              <Button onClick={toggleCartModal} block className="mb-3">
                {t("imageTileComponent.doNotAdd")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={handleDeleteCartAndAddItem} block color="danger">
                {spinner ? (
                  <Spinner size="lg" />
                ) : (
                  t("imageTileComponent.clearCart")
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default inject(
  "authStore",
  "lightboxStore",
  "sessionStore",
  "imageGroupStore",
  "createOrderStore",
  "ecomStore",
  "clientStore",
  "compositeStore"
)(observer(Lightbox));
