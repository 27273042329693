export default{

    "AccountPage": {
        "title": "Mijn account",
        "loginSubtitle": "Login Info",
        "loginText": "Ingelogd als",
        "editUsername": "Gebruikersnaam bewerken",
        "changePassword": "Wachtwoord wijzigen",
        "personalInfoSubtitle": "Persoonlijke informatie",
        "familyMemberSubtitle": "Familieleden",
        "edit": "Bewerken",
        "delete": "Verwijderen",
        "default": "Standaard",
        "paymentSubtitle": "Betaling",
        "paymentText": "Opgeslagen creditcardgegevens bijwerken",
        "communicationSubtitle": "Communicatie",
        "communicationText": "Voor promoties en nieuwsbrief opt-ins",
        "shippingSubtitle": "Verzendadressen",
        "shippingText": "Gebruiken voor afhandeling als verzendgegevens",
        "upcomingSubtitle": "Komende",
        "upcomingText": "Opnieuw plannen bevestigen of annuleren",
        "noUpcomingText": "Je hebt geen aankomende sessies",
        "tableDate": "Datum",
        "tableTime": "Tijd",
        "tableDescription": "Beschrijving",
        "tableAction": "Actie",
        "confirm": "Bevestigen",
        "reschedule": "Opnieuw plannen",
        "cancel": "Annuleren",
        "scheduleButton": "Plan nu",
        "deleteAddressButton": "Weet u zeker dat u dit adres wilt verwijderen?",
        "updateAddressModalTitle": "Adres bijwerken",
        "updateUsernameModalTitle": "Gebruikersnaam bijwerken",
        "updatePasswordModalTitle": "Wachtwoord wijzigen",
        "updatePersonalInfoModalTitle": "Persoonlijke gegevens bijwerken",
        "updateCommunicationModalTitle": "Opt-ins bijwerken",
        "updatePaymentModalTitle": "Betaling",
        "scheduleModalTitle": "Schema",
        "rescheduleModalTitle": "Opnieuw plannen",
        "clickhere": "Klik hier",
    },
    "ForgotPasswordPage": {
        "title": "Wachtwoord vergeten",
        "subtitle": "Een e-mail wordt naar uw e-mailadres gestuurd met verdere instructies",
        "alertSuccess": "U ontvangt binnenkort een e-mail. Controleer uw e-mailmap voor ongewenste e-mail of spam als u de e-mail niet vinden.",
        "backToLogin": "Terug naar inloggen",
    },
    "LoginPage": {
        "title": "Meld je aan om geweldige foto's te bekijken die door jou geïnspireerd zijn!",
        "description": "Als je hier al een account hebt, log je hier al in om je afbeeldingen te bekijken of klik je op 'Zoeken naar gebeurtenissen' om naar een evenement te zoeken.",
        "email": "E-mailadres of gebruikersnaam",
        "password": "Wachtwoord",
        "forgotPass": "Vergeten wachtwoord",
        "emailError": "Voer een e-mailadres of gebruikersnaam in",
        "ajaxError": "Ongeldige gebruikersnaam of wachtwoord",
        "passwordError": "Voer een wachtwoord in",
        "loginButton": "Login",
    },
    "ResetPasswordPage": {
        "title": "Voer uw nieuwe wachtwoord in",
        "alertSuccess": "Uw wachtwoord is opnieuw ingesteld. U wordt teruggestuurd naar de inlogpagina.",
        "backToLogin": "Terug naar inloggen",
    },
    "SignupPage": {
        "title": "Een account maken",
        "p1": "Heb je al een account?",
        "p2": "om uw foto's te bekijken.",
        "createAccountLink": "Log hier in",
        "email": "E-mail",
        "password": "Wachtwoord",
        "signup": "Aanmelden",
        "ajaxError": "Oeps er ging iets mis. Probeer het opnieuw.",
        "ajaxErrorOther": "Oeps er ging iets mis. Probeer het opnieuw.",
    },
    "ConfirmationPage": {
        "tabTitle": "Bedankt",
        "title": "Bedankt voor het plaatsen van uw bestelling.",
        "text": "Uw bestelnummer is",
        "relatedProducts": "Misschien bent u geïnteresseerd in...",
    },
    "CheckoutPage": {
        "urlTitle": "Veilig afrekenen",
        "title": "Veilig afrekenen",
        "subtitle": "4096-bits SSL/TSL-beveiligde pagina",
    },
    "OrderReviewPage": {
        "urlTitle": "Veilig afrekenen",
        "tableProductName": "Productnaam",
        "tablePrice": "Prijs",
        "tableQuantity": "Hoeveelheid",
        "tableSubtotal": "Subtotaal",
        "enter": "Voer het nu in",
        "apply": "Toepassing",
        "cancel": "Annuleren",
        "promo": "Promo",
        "shipping": "Verzending",
        "tax": "Belasting",
        "total": "Totaal",
    },
    "PaymentPage": {
        "urlTitle": "Veilig afrekenen",
    },
    "ShippingAddressPage": {
        "urlTitle": "Veilig afrekenen",
    },
    "ContractsPage": {
        "urlTitle": "Contracten",
        "title": "Contracten",
        "invalidPIN": "Ongeldige pincode",
        "uploaded": "Geüpload",
        "accepted": "Aanvaard",
        "printContract": "Contract afdrukken",
        "formLabel": "Ik heb de bovenstaande termen gelezen en begrepen. Ik ga hierbij akkoord met de voorwaarden van deze overeenkomst.",
        "enterPin": "Voer uw numerieke pin in",
        "confirm": "Bevestigen en verzenden",
    },
    "DashboardPage": {
        "loading": "Bezig met laden...",
        "dashboard": "Dashboard",
        "title": "Je hebt momenteel geen sessie.",
        "check": "Selectievakje",
        "pendingContracts": "lopende contracten",
        "contactUs": "neem contact met ons op",
        "noImages": "Je hebt hier geen afbeeldingen voor",
    },
    "EventSearchPage": {
        "title": "Zoeken naar gedeelde gebeurtenissen en lacht",
        "urlTitle": "Zoeken naar gebeurtenissen",
        "p1": "Als u een gebeurtenis wilt zoeken, selecteert u een gebeurtenis in de lijst of zoekt u op gebeurteniscode of gebeurtenisdatum.",
        "date": "Evenementdatum",
        "code": "Privécode",
        "public": "Openbaar evenement",
        "search": "'Zoeken'",
    },
    "FavoritesPage": {
        "urlTitle": "Favoriete",
    },
    "FoldersPage": {
        "urlTitle": "Map",
        "viewFull": "Volledig bekijken",
        "noFoldersFound": "Hiervoor zijn geen mappen gevonden",
    },
    "FormsPage": {
        "urlTitle": "Formulieren",
        "title": "Formulieren",
        "uploaded": "Geüpload",
    },
    "GuestsPage": {
        "urlTitle": "Gasten",
        "title": "Gasten",
        "error": "Er is een fout opgetreden bij het bijwerken van de uitnodiging voor gasten.",
        "success": "Link met succes kwalijk",
        "copy": "Gekopieerd naar uw klembord",
        "createNew": "Nieuw",
        "invitationLinks": "Koppelingen voor uitnodigingen",
        "noLinksFound": "Geen links gevonden voor deze",
        "tableDescription": "Beschrijving",
        "tableDateCreated": "Datum gemaakt",
        "tableDateFirstUsed": "Datum voor het eerst gebruikt",
        "tableAction": "Actie",
        "copyLink": "Koppeling kopiëren",
        "remove": "Verwijderen",
        "invitedTitle": "Genodigden",
        "noGuestsFound": "Geen gasten gevonden voor deze",
        "tableName": "Naam",
        "tableEmail": "E-mail",
        "tableDateInvited": "Datum uitgenodigd",
        "edit": "Bewerken",
        "resend": "Verzenden",
        "updateGuest": "Gast bijwerken",
        "createNewGuestInvitation": "Nieuwe uitnodiging voor gasten maken",
        "generateText": "Genereer een deelbare link om anderen toegang te geven tot uw afbeeldingen. Deel de link via social media een tekst of via e-mail.",
        "emailInvite": "Uitnodiging voor e-mail",
        "sharableLink": "Deelbare koppeling",
    },
    "HelpPage": {
        "urlTitle": "Help",
        "title": "Help",
        "ourInfo": "Onze informatie",
        "hereToHelp": "We zijn hier om te helpen.",
        "chatBy": "Chat met ons door",
        "clickingHere": "hier te klikken",
    },
    "HomePage": {
        "signIn": "Aanmelden",
        "eventSearch": "Zoeken naar gebeurtenissen",
        "privacy": "Privacyverklaring",
        "userAccessAgreement": "Overeenkomst voor gebruikerstoegang",
        "termsOfUse": "Gebruiksvoorwaarden",
        "close": "Sluiten",
    },
    "OrderDetailsPage": {
        "urlTitle": "Bestelling",
        "photo": "Foto",
        "setCrop": "Bijsnijden en opties instellen",
        "cropRequired": "Bijsnijden is vereist voor het afrekenen",
        "viewOrders": "Orders weergeven",
        "order": "Bestelling",
        "details": "Details",
        "orderDate": "Orderdatum",
        "makePayment": "Betaling uitvoeren",
        "printOrder": "Afdrukvolgorde",
        "orderDetails": "Orderinformatie",
        "tableProduct": "Product",
        "tablePrice": "Prijs",
        "tableQty": "Aantal",
        "tableTotal": "Totaal",
        "subtotal": "Subtotaal",
        "promo": "Promo",
        "shipping": "Verzending",
        "tax": "Belasting",
        "payment": "Betaling",
        "total": "Totaal",
        "finalize": "Selectie afronden",
        "shippingInfo": "Verzending Info",
        "delivery": "Levering",
        "confirm": "Indiening bevestigen",
        "cancel": "Annuleren",
        "warning": "Deze actie kan niet ongedaan worden gemaakt in de app.",
        "submitting": "Indienen...",
        "yesConfirm": "Ja bevestigen",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Bestelnummer",
        "tableOrderDate": "Orderdatum",
        "tableDateCreated": "Datum gemaakt",
        "tableShippedTo": "Verzonden naar",
        "tableStatus": "Status",
        "tableDescription": "Beschrijving",
        "tableOpenBalance": "Open saldo",
        "tableTotal": "Totaal",
        "orders": "Bestellingen",
        "orderHistory": "Bestelgeschiedenis",
        "preOrders": "Pre-orders",
        "noOrders": "Momenteel heb je nog geen bestelling.",
    },
    "SharePage": {
        "urlTitle": "Dit is de titelpagina",
        "title": "Dit is de titelpagina",
        "iframe": "Dit is een lege iframe pagina.",
        "shareImage": "Deel deze afbeelding",
    },
    "SplashPage": {
        "wereSorry": "Het spijt ons.",
        "doesntSeemTo": "lijkt niet te zijn een geldig aandeel token.",
        "error": "Het spijt ons dat er iets mis is gegaan. Informeer uw sitebeheerder als dit doorgaat.",
        "subdomainError": "Deze app verwacht een subdomein. Controleer de url en zorg ervoor dat deze eruit ziet als <Your Company Name>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Toevoegen aan map",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Sociaal aandeel",
        "generatingShareable": "Het genereren van deelbare url...",
    },
    "cartViewComponent": {
        "urlTitle": "Winkelwagentje",
        "title": "Winkelwagentje",
        "photo": "Foto",
        "swap": "Swap",
        "youHave": "Je hebt",
        "youHaveAdded": "Je hebt toegevoegd",
        "unitsRemaining": "eenheden die nog aan uw bestelling moeten worden toegevoegd.",
        "unitsTooMany": "eenheden te veel op uw bestelling.",
        "cartFullModalTitle": "Te veel eenheden",
        "tooMany": "Deze order bevat te veel eenheden.  Verwijder sommige producten voordat u uitcheckt.",
        "have": "Hebben",
        "another": "Andere",
        "a": "A",
        "ok": "OK",
        "promoCode": "promo / coupon code?",
        "promoCode1": "Promocode",
        "removePromoCode": "Promotiecode verwijderen",
        "select": "Selecteer",
        "loading": "Laden",
        "setCropAndOptions": "Bijsnijden en opties instellen",
        "cropRequired": "Bijsnijden is vereist voor het afrekenen",
        "checkout": "Uitchecken",
        "tableAction": "Actie",
        "tableProductName": "Productnaam",
        "tablePrice": "Prijs",
        "tableQuantity": "Hoeveelheid",
        "tableSubtotal": "Subtotaal",
        "enterNow": "Voer het nu in",
        "apply": "Toepassing",
        "cancel": "Annuleren",
        "promo": "Promo",
        "noItems": "Geen items in de winkelwagen.",
        "checkOurProducts": "Bekijk onze producten",
        "continueShopping": "Doorgaan met winkelen",
    },
    "createOrderIndexComponent": {
        "title": "Een order maken",
        "step1cta": "Gaan",
        "finish": "Afwerking",
    },
    "cropViewComponent": {
        "cropImage": "Afbeelding bijsnijden",
        "prepaid": "Prepaid",
        "shoppingCart": "Winkelwagentje",
        "image": "Beeld",
        "zoomIn": "Inzoomen",
        "zoomOut": "Uitzoomen",
        "reset": "Reset",
        "cropOrientation": "Bijsnijden oriëntatie",
        "selectPhotoOption": "Optie Foto selecteren",
        "pleaseSelect": "Selecteer...",
        "selectPhotoEnhancement": "Fotoverbetering selecteren",
        "selectColorEffect": "Kleureffect selecteren",
        "notes": "Notities",
        "apply": "Toepassing",
        "backToPreorder": "Terug naar pre-order",
        "backToShoppingCart": "Terug naar winkelwagentje",
    },
    "photoSelectComponent": {
        "swapPhoto": "Foto ruilen",
        "prepaid": "Prepaid",
        "shoppingCart": "Winkelwagentje",
        "backToPreorder": "Terug naar pre-order",
        "backToShoppingCart": "Terug naar winkelwagentje",
    },
    "photoTileComponent": {
        "image": "Beeld",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Categorie",
        "title": "Categorie",
        "selectYourProduct": "Selecteer uw product",
        "selectCateogry": "Categorie selecteren",
        "sortBy": "Sorteren op",
        "bestSelling": "Best verkopende",
        "viewLarger": "Groter weergeven",
        "viewDetails": "Details weergeven",
    },
    "productGroupListComponent": {
        "urlTitle": "Producten",
        "title": "Selecteer uw productcategorie",
        "viewTheseProducts": "Bekijk deze producten",
    },
    "productDetailTabsComponent": {
        "description": "Beschrijving",
        "features": "Functies",
        "delivery": "Levering",
        "noDescriptionFound": "Geen beschrijving gevonden",
        "noFeatureFound": "Geen functie-informatie gevonden",
        "noDeliveryFound": "Geen leveringsgegevens gevonden",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Fout toevoegen aan winkelwagentje. Vernieuw de pagina of rapporteer dit product aan onze ondersteuning.",
        "product": "Product",
        "cancelAndBack": "Annuleren en terug naar aanbieding",
        "selectPhotoOption": "Optie Foto selecteren",
        "pleaseSelect": "Selecteer...",
        "selectPhotoEnhancement": "Selecteer foto-enahncement",
        "selectColorEffect": "Kleureffect selecteren",
        "was": "Was",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Een map selecteren of nieuwe maken",
    },
    "addressFormSectionComponent": {
        "firstName": "Voornaam",
        "lastName": "Achternaam",
        "addressLine1": "Adresregel 1",
        "addressLine2": "Adresregel 2",
        "city": "Stad",
        "region": "Regio",
        "state": "Staat",
        "postalCode": "Postcode",
        "zipCode": "Postcode",
        "country": "Land",
    },
    "addToCartFormComponent": {
        "quantity": "Hoeveelheid",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Voer uw e-mailadres in",
        "email": "E-mail",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Bevestig uw nieuwe wachtwoord",
        "password": "Wachtwoord",
        "enterPassword": "Voer uw nieuwe wachtwoord in",
        "confirmPassword": "Bevestig wachtwoord",
        "setNewPassword": "Nieuw wachtwoord instellen",
    },
    "signupFormComponent": {
        "firstNameValidation": "Voer uw voornaam in",
        "lastNameValidation": "Voer uw achternaam in",
        "invalidEmailValidation": "Voer een geldige e-mail in",
        "emailRequiredValidation": "Voer een e-mailadres in",
        "confirmPasswordValidation": "Bevestig uw wachtwoord",
        "countryRequiredValidation": "Selecteer een land",
        "passwordsMustMatch": "Wachtwoorden moeten overeenkomen met",
        "confirm": "Bevestigen",
        "firstName": "Voornaam",
        "lastName": "Achternaam",
        "email": "E-mailadres",
        "password": "Wachtwoord",
        "confirmPassword": "Bevestig wachtwoord",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Voer uw voornaam in",
        "lastNameValidaiton": "Voer uw achternaam in",
        "addressLine1Validation": "Voer uw adres in",
        "cityValidation": "Voer uw stad in",
        "stateValidation": "Voer uw staat in",
        "postalCodeValidation": "Voer uw postcode in",
        "invalidPostalCode": "Voer een geldige postcode in",
        "countryValidation": "Voer uw land in",
        "emailValidation": "Voer een geldige e-mail in",
        "billingInformation": "Factureringsgegevens",
        "selectSavedAddress": "Opgeslagen adres selecteren",
        "email": "E-mailadres",
        "phoneNumber": "Telefoonnummer",
        "saveAsDefault": "Factureringstoepassingen opslaan als mijn standaardwaarde",
        "saveAsShipping": "Hetzelfde als verzendadres",
        "promotionalTexts": "Mogen we u promotionele sms'jes sturen?",
        "dataRates": "(Gegevens en berichttarieven kunnen van toepassing zijn)",
        "promotionalEmails": "Mogen we u promotionele e-mails sturen?",
        "continue": "Blijven",
        "continueShopping": "Doorgaan met winkelen",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Geselecteerd adres gebruiken",
        "useDefaultAddress": "Standaardadres gebruiken",
        "useClientAddress": "Clientadres gebruiken",
        "saveAsDefault": "Opslaan als standaard",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Selecteer een verzendmethode",
    },
    "communicationFormComponent": {
        "permissionCall": "Mogen we u bellen met promotionele informatie?",
        "permissionEmail": "Mogen we u een e-mail sturen met promotionele informatie?",
        "permissionText": "Mogen we u sms'en met promotionele informatie? (Berichten- en datatarieven kunnen van toepassing zijn)",
    },
    "editAddressFormComponent": {
        "name": "Naam",
        "phoneNumber": "Telefoonnummer",
        "addressLine1": "Adresregel 1",
        "addressLine2": "Adresregel 2",
        "city": "Stad",
        "region": "Regio",
        "postalCode": "Postcode",
        "country": "Land",
        "deliveryInstructions": "Leveringsinstructies",
        "accessCode": "Toegangscode",
        "defaultAddress": "Standaardadres",
        "applyChange": "Wijziging toepassen",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Voer uw wachtwoord in",
        "passwordCannotBeSame": "Nieuw wachtwoord kan niet hetzelfde zijn als het oude wachtwoord",
        "confirmPassword": "Bevestig uw nieuwe wachtwoord",
        "passwordsMustMatch": "Wachtwoorden moeten overeenkomen met",
        "existingPassword": "Bestaand wachtwoord",
        "currentPassword": "Huidig wachtwoord",
        "newPassword": "Nieuw wachtwoord",
        "confirmNewPassword": "Bevestig het nieuwe wachtwoord",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Selecteer een gebeurtenis",
        "selectEventDate": "Selecteer een gebeurtenisdatum",
        "enterCode": "Voer een code in",
    },
    "inputSectionComponent": {
        "selectDate": "Een datum selecteren",
        "placeCodeHere": "Plaats hier code",
        "input": "Input",
        "selectEvent": "Een gebeurtenis selecteren",
    },
    "emailGuestFormComponent": {
        "favorites": "Favorieten",
        "ajaxError": "Er is een fout opgetreden bij het maken van een uitnodiging voor gasten",
        "invalidEmail": "Voer een geldige e-mail in",
        "noEmail": "Voer een e-mail in",
        "noFirstName": "Voer een voornaam",
        "noLastName": "Voer een achternaam",
        "email": "E-mailadres",
        "firstName": "Voornaam",
        "lastName": "Achternaam",
        "session": "Sessie",
        "image": "Beeld",
        "sendEmail": "E-mail verzenden",
        "onlyInclude": "Alleen favorieten uit deze",
    },
    "joyFormComponent": {
        "ageMonth": "maand(en)",
        "yes": "Ja",
        "no": "No",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Voer een beschrijving in voor uw link",
        "description": "Beschrijving",
        "onlyIncludeFavoritesFromThis": "Alleen favorieten uit deze",
        "createLink": "Koppeling maken",
    },
    "personalInfoComponent": {
        "invalidEmail": "Voer een geldig e-mailadres in",
        "primaryPhone": "Primaire telefoon",
        "secondaryPhone": "Secundaire telefoon",
        "mobilePhone": "Dit is een mobiele telefoon",
        "streetAddress": "Adres",
        "house": "Huis",
        "apartment": "Appartement",
        "roomNumber": "Kamernummer",
        "city": "Stad",
        "region": "Regio",
        "state": "Staat",
        "postalCode": "Postcode",
        "country": "Land",
        "pleaseSelect": "Selecteer...",
    },
    "familyMemberComponent": {
        "nickname": "Bijnaam",
        "familyMember": "Familielid",
        "relationship": "Relatie",
        "gender": "Geslacht",
        "BirthDate": "Geboortedatum",
        "familyMemberSubtitle": "Familieleden",
        "tableAction": "Actie",
    },
    "usernameFormComponent": {
        "enterUsername": "Voer een nieuwe gebruikersnaam in",
        "onlineUsername": "Online gebruikersnaam",
        "username": "Gebruikersnaam",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Meer navigatie-items",
        "download": "Downloaden",
        "removeFrom": "Verwijderen uit",
        "modifyImage": "Afbeelding wijzigen",
        "folders": "Mappen",
        "makePrimary": "Primair maken",
        "yearbookImage": "Jaarboek afbeelding",
        "makeSecondary": "Secundair maken",
        "socialShare": "Sociaal aandeel",
        "unhide": "Unhide",
        "hide": "Verbergen",
    },
    "imageTileComponent": {
        "view": "Weergave",
        "sessions": "Sessies",
        "setAsPrimary": "Instellen als primaire foto",
        "setAsSecondary": "Instellen als secundaire foto",
        "image": "Beeld",
        "caution": "Voorzichtigheid",
        "separateSessionsMessage1": "U geen afbeeldingen van twee afzonderlijke",
        "separateSessionsMessage2": "naar dezelfde winkelwagen",
        "doNotAdd": "Voeg dit object niet toe aan mijn winkelwagentje.",
        "clearCart": "Verwijder de inhoud van mijn winkelwagentje en voeg dit item toe aan mijn winkelwagentje.",
    },
    "loginPromptComponent": {
        "title": "Oeps!",
        "message": "Gelieve --login-- of --aanmelden-- als u wilt favoriete afbeeldingen groep beelden samen of afbeeldingen toe te voegen aan winkelwagen.",
    },
    "menuDropdownComponent": {
        "dashboard": "Dashboard",
        "sessions": "Sessies",
        "favorites": "Favorieten",
        "contracts": "Contracten",
        "orders": "Bestellingen",
        "prepaidOrders": "Prepaid bestellingen",
        "guests": "Gasten",
        "account": "Rekening",
    },
    "userDropdownComponent": {
        "logout": "Uitlogen",
    },
    "navBarPage": {
        "toggleNavigation": "Navigatie in- of uitschakelen",
        "cart": "Winkelwagen",
        "photoshootsAndImages": "Fotoshoots en afbeeldingen",
        "contracts": "Contracten",
        "reviewSubmit": "Contracten beoordelen en indienen",
        "forms": "Formulieren",
        "reviewForms": "Webformulieren bekijken en verzenden",
        "orders": "Bestellingen",
        "historyPrepaid": "Geschiedenis en pre-paid orders",
        "orderHistory": "Bestelgeschiedenis",
        "products": "Producten",
        "guests": "Gasten",
        "categoriesProducts": "Categorieën en producten",
        "sharedFamily": "Gedeeld met familie",
        "friends": "Vrienden",
        "myAccount": "Mijn account",
        "login": "Login",
        "billingInfo": "factureringsgegevens",
        "help": "Help",
        "chat": "Chat voor online ondersteuning",
        "shoppingCart": "Winkelwagentje",
        "checkout": "Maak je klaar om af te rekenen",
        "signout": "Afmelden",
        "endGuest": "Mijn gast beëindigen",
        "securelySignout": "Laat me veilig uitlogen",
    },
    "productGalleryComponent": {
        "viewLarger": "Groter weergeven",
    },
    "responsiveHeaderComponent": {
        "photos": "Foto 's",
        "guests": "Gasten",
        "allImages": "Alle afbeeldingen",
        "favorites": "Favorieten",
        "folders": "Mappen",
        "autoPlay": "Automatisch afspelen",
        "download": "Downloaden",
        "cart": "Winkelwagen",
    },
    "sessionsPage": {
        "Name": "Naam",
        "Date": "Datum",
        "CreateNewText": "Nieuw",
        "ViewText": "Weergave",
        "DeleteText": "Verwijderen",
        "EditText": "Bewerken",
    },
}
