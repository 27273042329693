import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import IframeResizer from "iframe-resizer-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const FormsPage: React.FC<any> = ({ authStore, clientStore }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    clientStore.getWebForms();
  }, [clientStore]);

  return (
    <>
      <Helmet>
        <title>
          {t("FormsPage.urlTitle")}{" "}
          {clientStore.clientInfo?.WebForms
            ? clientStore?.clientInfo?.WebForms[selectedIndex]?.Description
            : ""}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section className="page-section page-section-2column">
        <div className="page-section-main">
          <h1>{t("FormsPage.title")}</h1>
          <IframeResizer
            log={false}
            scrolling={false}
            src={
              clientStore.clientInfo.WebForms
                ? clientStore.clientInfo.WebForms[selectedIndex].Url
                : ""
            }
            style={{ width: 1, minWidth: "100%", minHeight: 600, border: 0 }}
          />
        </div>
        <aside className="page-section-aside">
          {clientStore.clientInfo?.WebForms?.map((form: any, i: number) => (
            <div
              key={i}
              className={`card-form ${
                selectedIndex === i ? "card-form-active" : ""
              } `}
              onClick={() => setSelectedIndex(i)}
            >
              <p className="mb-0">
                <span
                  className={`badge-status ${
                    form.Status.toUpperCase() === "ACTION REQUIRED"
                      ? " badge-danger"
                      : "badge-primary"
                  } `}
                >
                  {form.Status}
                </span>
              </p>
              <h4>{form.Description}</h4>
              <ul className="list-unstyled meta" role="presentation">
                <li>{`${form.StatusLongDescription} ${
                  form.SubmittedDateStringFormatted
                    ? form.SubmittedDateStringFormatted
                    : ""
                }`}</li>
                <li>
                  {t("FormsPage.uploaded")} {form.UploadedDateStringFormatted}
                </li>
              </ul>
            </div>
          ))}
        </aside>
      </section>
    </>
  );
};

export default inject("authStore", "clientStore")(observer(FormsPage));
