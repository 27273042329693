import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import es from './locales/es';
import da from './locales/da';
import de from './locales/de';
import fr from './locales/fr';
import it from './locales/it';
import nb from './locales/nb';
import nl from './locales/nl';
import sv from './locales/sv';

const resources = { en, es, da, de, fr, it, nb, nl, sv };

const detectionOptions = {
  order: ["querystring", "navigator", "htmlTag"],
  htmlTag: document.documentElement,
  lookupQuerystring: "lng",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
      resources,
      detection: detectionOptions,
      languages: ["en", "da", "de", "en", "es", "fr", "it", "nb", "nl", "sv"],
      fallbackLng: "en",
      interpolation: {
        escapeValue: false // react already safes from xss
      }
});

export default i18n;