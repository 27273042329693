import React from "react";

const TermsOfUsePage: React.FC<any> = () => {
  return (
    <>
      <div id="right_col" className="p-5">
        <p>
          <strong>
            IMPORTANT - THIS AGREEMENT CONTAINS AND EXPLAINS IMPORTANT LEGAL
            RIGHTS AND LIMITATIONS. PLEASE READ IT CAREFULLY, AND PRIOR TO
            INSTALLATION OR ANY USE OF THE SOFTWARE SERVICE.
          </strong>
        </p>
        
        <p>
          <strong>READ CAREFULLY:</strong> STUDIOPLUS LICENSES THE SOFTWARE,
          SERVICES, PRODUCTS AND OTHER LICENSED MATERIALS ONLY ON THE CONDITION
          THAT YOU ACCEPT ALL OF THE TERMS CONTAINED OR REFERENCED IN THIS
          AGREEMENT. <strong></strong>
          By selecting the “I accept” button or other button or mechanism
          designed to acknowledge agreement to the terms of an electronic copy
          of this Agreement, or by installing, downloading, accessing, or
          otherwise copying or using all or any portion of the StudioPlus
          Software, (i) you accept this Agreement on behalf of the entity for
          which you are authorized to act (e.g., an employer) and acknowledge
          that such entity is legally bound by this Agreement (and you agree to
          act in a manner consistent with this Agreement) or, if there is no
          such entity for which you are authorized to act, you accept this
          Agreement on behalf of yourself as an individual and acknowledge that
          you are legally bound by this Agreement, and (ii) you represent and
          warrant that you have the right, power and authority to act on behalf
          of and bind such entity (if any) or yourself. You may not accept this
          Agreement on behalf of another entity unless you are an employee or
          other agent of such other entity with the right, power and authority
          to act on behalf of such other entity. If you are unwilling to accept
          this Agreement, or you do not have the right, power and authority to
          act on behalf of and bind such entity or yourself as an individual (if
          there is no such entity), (a) DO NOT SELECT THE “I ACCEPT” BUTTON OR
          OTHERWISE CLICK ON ANY BUTTON OR OTHER MECHANISM DESIGNED TO
          ACKNOWLEDGE AGREEMENT, AND DO NOT INSTALL, DOWNLOAD, ACCESS, OR
          OTHERWISE COPY OR USE ALL OR ANY PORTION OF THE STUDIOPLUS MATERIALS;
          AND (b) WITHIN THIRTY (30) DAYS FROM THE DATE OF ACQUIRING THE
          STUDIOPLUS MATERIALS, LICENSEE MAY RETURN THE STUDIOPLUS MATERIALS
          (INCLUDING ANY COPIES) TO THE ENTITY FROM WHICH THEY WERE ACQUIRED FOR
          A REFUND OF THE APPLICABLE LICENSE FEES PAID BY YOU.
          <strong>
            This Terms of Use and License Agreement (the "Agreement") is a legal
            agreement between you (either an individual or a single entity) and
            StudioPlus Software, LLC ("StudioPlus") for the StudioPlus software
            product, which includes computer software and may include associated
            media, printed materials, and "online" or electronic documentation,
            as well as any enhancements, modifications or upgrades that may be
            offered to licensed end users as specified herein (collectively, the
            "Product").&nbsp; By installing or otherwise using the Product, you
            agree to be bound by the terms of this Agreement.&nbsp; If you do
            not agree to the terms of this Agreement, do not install or use the
            Product; you may, however, return it to the source for a full
            refund.
          </strong>
        </p>
        <p>
          <strong>1. Use of Online Services.</strong>{" "}
        </p>
        <p>
          <strong>a. Right to use.</strong> We grant you the right to access and
          use the Online Services and to install and use the Software included
          with your Subscription, as further described in this agreement. We
          reserve all other rights.
        </p>
        <p>
          <strong>b. Acceptable use. </strong>You may use the Product only in
          accordance with this Agreement. You may not reverse engineer,
          decompile, disassemble, or work around technical limitations in the
          Product, except to the extent applicable law permits it despite these
          limitations. You may not disable, tamper with, or otherwise attempt to
          circumvent any billing mechanism that meters your use of the Online
          Services. You may not rent, lease, lend, resell, transfer, or host the
          Product, or any portion thereof, to or for third parties except as
          expressly permitted in the Online Services Terms.
        </p>
        <p>
          {" "}
          <strong>c. End Users.</strong> You control access by End Users
          affiliated with you, and you are responsible for their use of the
          Product in accordance with this agreement. For example, you will
          ensure End Users comply with the Acceptable Use Policy.
        </p>
        <p>
          {" "}
          <strong>d. Customer Data and Proprietary Rights.</strong> You are
          solely responsible for the content of all Customer Data. The site
          services are the sole property of StudioPlus, and You acknowledge that
          you do not acquire any ownership rights by using the site
          services.&nbsp; The content available through the site services shall
          remain the property of the individual or entity that transmitted the
          content to StudioPlus on its behalf, and the use of such content is
          dictated by You, provided that your use of the service shall grant
          StudioPlus an irrevocable license to host and possess any such content
          for the purpose of providing the site services to You. You will secure
          and maintain all rights in Customer Data necessary for us to provide
          the Online Services to you without violating the rights of any third
          party or otherwise obligating StudioPlus to you or to any third party.
          StudioPlus does not and will not assume any obligations with respect
          to Customer Data or to your use of the Product other than as expressly
          set forth in this Agreement or as required by applicable law. While it
          has no obligation to do so, and consistent with any other rights
          reserved herein, StudioPlus reserves the right to remove or make
          inaccessible any content that is illegal or possible to cause any
          delay, harm, or disturbance of any kind or tend to portray StudioPlus
          in a negative light.
        </p>
        <p>
          {" "}
          <strong>e. Responsibility for your accounts.</strong> You are
          responsible for maintaining the confidentiality of any non-public
          authentication credentials associated with your use of the Online
          Services. You must promptly notify our customer support team about any
          possible misuse of your accounts or authentication credentials or any
          security incident related to the Online Services.
        </p>
        <p>
          {" "}
          <strong>f. </strong>
          <strong>Optional Support Services.</strong>&nbsp; Upon your request,
          StudioPlus may provide you with certain optional telephone support,
          maintenance, or error correction services related to the Product
          ("Support Services").&nbsp; Support Services are provided under and
          governed by the then-current StudioPlus policies and programs, which
          are available herein or at www.StudioPlusSoftware.com
          <strong>,</strong> and are subject at all times to prepayment of the
          applicable Support Services fees.&nbsp; Any supplemental software code
          provided to you as part of the Support Services shall be considered
          part of the Product and subject to the terms and conditions of this
          Agreement.&nbsp; With respect to technical information you provide to
          StudioPlus as part of the Support Services, StudioPlus may use such
          information for its business purposes, including for product support
          and development.&nbsp; StudioPlus will not utilize such technical
          information in a form that personally identifies you.
        </p>
        <p>
          {" "}
          <strong>g. Privacy and Data</strong>.&nbsp; Any information collected
          by StudioPlus resulting from your use of the Product is governed by
          the applicable StudioPlus Privacy Policy.
        </p>
        <p>
          <strong>h. Intellectual Property</strong>. All title, patents,
          intellectual property, trademarks and copyrights in and to the Product
          (including but not limited to any images, photographs, animations,
          video, audio, music, text, and "applets" incorporated into the
          Product), the accompanying printed materials, and any copies of the
          Product are owned by StudioPlus or its suppliers.&nbsp; The Product is
          protected by copyright laws and international treaty provisions.&nbsp;
          You may not copy or reproduce the printed materials accompanying the
          Product.&nbsp; You shall not remove StudioPlus copyright notices,
          restricted rights legends or any other notices from the Software and
          Documentation and such notices shall appear on any and all tangible
          media distributed by Company containing the Software and
          Documentation.
        </p>
        <p>
          {" "}
          <strong>2. Purchase of Services.</strong>
        </p>
        <p>
          <strong>a. Ordering.</strong>
        </p>
        <p>
          <strong>(i) </strong>By ordering or renewing a Subscription, you agree
          to the Offer Details for that Subscription. Unless otherwise
          specified, Online Services are offered on an "as available" basis. You
          may place orders for your Affiliates under this agreement and grant
          your Affiliates administrative rights to manage the Subscription, but,
          Affiliates may not place orders under this agreement. You also may
          assign the rights granted under Section 1.a to a third party for use
          by that third party in your internal business. If you grant any rights
          to Affiliates or third parties with respect to Software or your
          Subscription, such Affiliates or third parties will be bound by this
          agreement and you agree to be jointly and severally liable for any
          actions of such Affiliates or third parties related to their use of
          the Products.
        </p>
        <p>
          {" "}
          <strong>(ii) </strong>Some offers may permit you to modify the
          quantity of Online Services ordered during the Term of a Subscription.
          Additional quantities of Online Services added to a Subscription will
          expire at the end of that Subscription. If you decrease the quantity
          during a Term, we may charge you a cancellation fee for the decrease
          in quantity as described below in Section 3.b.
        </p>
        <p>
          {" "}
          <strong>b. Pricing and payment. </strong>Payments are due and must be
          made according to the Pricing Schedule for your Subscription.
          &nbsp;Payment for all Licensing Fees under this Agreement are due
          prior to the release of any software activation codes.&nbsp; Payments
          for all Enhancements are due according to the payment schedule as
          defined as per your specific agreement with StudioPlus.
          &nbsp;&nbsp;Payment of all other charges under this Agreement are due
          thirty (30) days from the date of StudioPlus's invoice. A late payment
          charge of one and one-half percent (1.5%) per month may be charged
          upon any unpaid balance remaining due after the due date.
        </p>
        <p>
          {" "}
          <strong>(i) </strong>In the case of a fixed-term pricing plan, the
          price level may be based on the quantity of Online Services you
          ordered. Some offers may permit you to modify the quantity of Online
          Services ordered during the Term and your price level may be adjusted
          accordingly, but price level changes will not be retroactive. During
          the Term of your Subscription, prices for Online Services will not be
          increased, as to your Subscription, from those posted in the Portal at
          the time your Subscription became effective or was renewed, except
          where prices are identified as temporary in the Offer Details, or for
          Previews or Non-StudioPlus Products. All prices are subject to change
          at the beginning of any Subscription renewal.
        </p>
        <p>
          {" "}
          <strong>(ii) </strong>In the case for non-fixed term pricing plans,
          pricing is subject to change at any time upon notice.
        </p>
        <p>
          {" "}
          <strong>c. Renewal.</strong>
        </p>
        <p>
          <strong>(i) </strong>Upon renewal of your Subscription, this agreement
          will terminate, and your Subscription will thereafter be governed, by
          the terms and conditions set forth in the Portal on the date on which
          your Subscription is renewed (the "Renewal Terms"). If you do not
          agree to any Renewal Terms, you may decline to renew your
          Subscription.
        </p>
        <p>
          {" "}
          <strong>(ii)</strong> In certain instances,<strong> </strong>You may
          choose to have a Subscription automatically renew or terminate upon
          expiration of the Term. Automatic renewal is pre-selected. You can
          change your selection at any time during the Term. If the existing
          Term is longer than one calendar month, we will provide you with
          notice of the automatic renewal before the expiration of the Term.
        </p>
        <p>
          {" "}
          <strong>(iii)</strong> In other instances, your Subscription will
          renew automatically for additional one-month terms until you terminate
          the Subscription.
        </p>
        <p>
          {" "}
          <strong>(iv) </strong>In limited or trial instances, renewal may not
          be permitted.
        </p>
        <p>
          {" "}
          <strong>d. Taxes</strong>. Prices are exclusive of any taxes. You must
          pay any applicable value added, goods and services, sales, or like
          taxes that are owed with respect to any order placed under this
          agreement and which we are permitted to collect from you under
          applicable law. You will be responsible for any applicable stamp taxes
          and for all other taxes that you are legally obligated to pay
          including any taxes that arise on the distribution or provision of
          Products to your Affiliates. We will be responsible for all taxes
          based on our net income or on our property ownership. If any taxes are
          required to be withheld on payments you make to us, you may deduct
          such taxes from the amount owed to us and pay them to the appropriate
          taxing authority; provided, however, that you promptly secure and
          deliver an official receipt for those withholdings and other documents
          we reasonably request to claim a foreign tax credit or refund. You
          must make certain that any taxes withheld are minimized to the extent
          possible under applicable law.
        </p>
        <p>
          {" "}
          e. <strong>Enhancements</strong>.&nbsp; StudioPlus agrees to provide
          custom Software enhancements (“Enhancements”) at a price and schedule
          as defined as per your specific agreement with StudioPlus.&nbsp; You
          acknowledge that any such Enhancements shall remain in the sole
          ownership in StudioPlus unless otherwise specifically transferred to
          you.
        </p>
        <p>
          {" "}
          <strong>3. Term, termination, and suspension.</strong>
        </p>
        <p>
          <strong>a. Agreement term and termination.</strong> This agreement
          will remain in effect until the expiration, termination, or renewal of
          your Subscription, whichever is earliest.
        </p>
        <p>
          {" "}
          <strong>b. Subscription termination.</strong> Unless specified in your
          enterprise agreement, you may terminate a Subscription at any time.
        </p>
        <p>
          {" "}
          <strong>(i) One-Month Subscription. </strong>A Subscription having a
          one-month Term may be terminated anytime without any cancellation fee.
          The termination will be effective at the end of the current paid Term.
          No refunds will be issued for unused Terms.
        </p>
        <p>
          {" "}
          <strong>(ii) Subscriptions of more than one-month</strong>. A request
          to terminate a Subscription longer than one month will take effect at
          the end of the Subscription Term.&nbsp; You will be and remain
          responsible for all fees that remain up to and through the end of the
          Subscription Term, regardless of the frequency of your access or
          use.&nbsp; You will not be entitled to any refund or offset for unused
          portions of the Term.
        </p>
        <p>
          {" "}
          <strong>c. Suspension. </strong>We may suspend your use of the Online
          Services if: (1) it is reasonably needed to prevent unauthorized
          access to Customer Data; (2) you fail to respond to a claim of alleged
          infringement under Section 5 within a reasonable time; (3) you do not
          pay amounts due under this agreement; or (4) you do not abide by the
          Acceptable Use Policy or you violate other terms of this Agreement. If
          one or more of these conditions occurs, then:
        </p>
        <p>
          {" "}
          <strong>(i) </strong>For Limited Offerings, we may suspend your use of
          the Online Services or terminate your Subscription and your account
          immediately without notice.
        </p>
        <p>
          {" "}
          <strong>(ii) </strong>For all other Subscriptions, a suspension will
          apply to the minimum necessary part of the Online Services and will be
          in effect only while the condition or need exists. We will give notice
          before we suspend, except where we reasonably believe we need to
          suspend immediately. We will give at least 30 days' notice before
          suspending for non-payment. If you do not fully address the reasons
          for the suspension within 60 days after we suspend, we may terminate
          your Subscription and delete your Customer Data without any retention
          period. We may also terminate your Subscription if your use of the
          Online Services is suspended more than twice in any 12-month period,
          and a reactivation fee may be assessed.
        </p>
        <p>
          {" "}
          <strong>4. Warranties.</strong>
        </p>
        <p>
          <strong>a. Limited warranty.</strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE STUDIOPLUS
          SOFTWARE AND SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH
          ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND STUDIOPLUS HEREBY
          DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE STUDIOPLUS
          SOFTWARE AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR
          CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
          PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, AND NON-INFRINGEMENT OF
          THIRD PARTY RIGHTS.
        </p>
        <p>
          STUDIOPLUS DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT
          OF THE STUDIOPLUS SOFTWARE AND SERVICES, THAT THE FUNCTIONS CONTAINED
          IN, OR SERVICES PERFORMED OR PROVIDED BY, THE STUDIOPLUS SOFTWARE WILL
          MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE STUDIOPLUS SOFTWARE
          OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY SERVICES
          WILL CONTINUE TO BE MADE AVAILABLE, THAT THE STUDIOPLUS SOFTWARE OR
          SERVICES WILL BE COMPATIBLE OR WORK WITH ANY THIRD PARTY SOFTWARE,
          APPLICATIONS OR THIRD PARTY SERVICES, OR THAT DEFECTS IN THE
          STUDIOPLUS SOFTWARE OR SERVICES WILL BE CORRECTED. INSTALLATION OF
          THIS STUDIOPLUS SOFTWARE MAY AFFECT THE USABILITY OF THIRD PARTY
          SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES.
        </p>
        <p>
          YOU FURTHER ACKNOWLEDGE THAT THE STUDIOPLUS SOFTWARE AND SERVICES ARE
          NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR ENVIRONMENTS WHERE
          THE FAILURE OR TIME DELAYS OF, OR ERRORS OR INACCURACIES IN THE
          CONTENT, DATA OR INFORMATION PROVIDED BY, THE STUDIOPLUS SOFTWARE OR
          SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR
          ENVIRONMENTAL DAMAGE.
        </p>
        <p>
          NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY STUDIOPLUS OR ITS
          AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE
          STUDIOPLUS SOFTWARE OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE
          COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
          LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE
          EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          {" "}
          <strong>b. Limitation of Liability</strong>
          TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL
          STUDIOPLUS BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL,
          INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT
          LIMITATION, DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA OR
          INFORMATION, FAILURE TO TRANSMIT OR RECEIVE ANY DATA, BUSINESS
          INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF
          OR RELATED TO YOUR USE OR INABILITY TO USE THE STUDIOPLUS SOFTWARE OR
          SERVICES OR ANY THIRD PARTY SOFTWARE OR APPLICATIONS IN CONJUNCTION
          WITH THE STUDIOPLUS SOFTWARE OR SERVICES, HOWEVER CAUSED, REGARDLESS
          OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF
          STUDIOPLUS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
          THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall StudioPlus’s
          total liability to you for all damages (other than as may be required
          by applicable law in cases involving personal injury) exceed the
          amount of one hundred dollars ($100.00). The foregoing limitations
          will apply even if the above stated remedy fails of its essential
          purpose. To the extent allowed by applicable law, implied warranties
          on the Product, if any, are limited to ninety (90) days.
        </p>
        <p>
          {" "}
          <strong>c. Exclusions.</strong> This limited warranty is subject to
          the following limitations:
        </p>
        <p>
          {" "}
          <strong>(i) </strong>any implied warranties, guarantees or conditions
          not able to be disclaimed as a matter of law will last one year from
          the start of the limited warranty;
        </p>
        <p>
          {" "}
          <strong>(ii) </strong>this limited warranty does not cover problems
          caused by accident, abuse or use of the Products in a manner
          inconsistent with this agreement or our published documentation or
          guidance, or resulting from events beyond our reasonable control;
        </p>
        <p>
          {" "}
          <strong>(iii) </strong>this limited warranty does not apply to
          problems caused by a failure to meet minimum system requirements; and
        </p>
        <p>
          {" "}
          <strong>(iv) </strong>this limited warranty does not apply to Previews
          or Limited Offerings.
        </p>
        <p>
          {" "}
          <strong>5. Defense of claims.</strong>
        </p>
        <p>
          <strong>a. Defense.</strong>
        </p>
        <p>
          <strong>(i) </strong>We will defend you against any claims made by an
          unaffiliated third party that a Product infringes that third party's
          patent, copyright or trademark or makes unlawful use of its trade
          secret.
        </p>
        <p>
          {" "}
          <strong>(ii) </strong>You will defend us against any claims made by an
          unaffiliated third party that (1) any Customer Data, Customer
          Solution, or Non-StudioPlus Products, or services you provide,
          directly or indirectly, in using a StudioPlus Product infringes the
          third party's patent, copyright, or trademark or makes unlawful use of
          its trade secret; or (2) arises from violation of the Acceptable Use
          Policy.
        </p>
        <p>
          {" "}
          <strong>b. Limitations.</strong> Our obligations in Section 5a won't
          apply to a claim or award based on: (i) any Customer Data,
          Non-StudioPlus Products, modifications you make to the Product, or
          services or materials you provide or make available as part of using
          the Product; (ii) your combination of the Product with, or damages
          based upon the value of, Customer Data, or a Non- StudioPlus Product,
          data, or business process; (iii) your use of a StudioPlus trademark
          without our express written consent, or your use of the Product after
          we notify you to stop due to a third-party claim; (iv) your
          redistribution of the Product to, or use for the benefit of, any
          unaffiliated third party; or (v) Products provided free of charge.
        </p>
        <p>
          {" "}
          <strong>c. Remedies.</strong> If we reasonably believe that a claim
          under Section 5.a.(i) may bar your use of the Product, we will seek
          to:(i) obtain the right for you to keep using it; or (ii) modify or
          replace it with a functional equivalent and notify you to stop use of
          the prior version of the Product. If these options are not
          commercially reasonable, we may terminate your rights to use the
          Product and then refund any advance payments for unused Subscription
          rights.
        </p>
        <p>
          {" "}
          <strong>d. Obligations.</strong> Each party must notify the other
          promptly of a claim under this Section. The party seeking protection
          must (i) give the other sole control over the defense and settlement
          of the claim; and (ii) give reasonable help in defending the claim.
          The party providing the protection will (1) reimburse the other for
          reasonable out-of-pocket expenses that it incurs in giving that help
          and (2) pay the amount of any resulting adverse final judgment or
          settlement. The parties' respective rights to defense and payment of
          judgments (or settlement the other consents to) under this Section 5
          are in lieu of any common law or statutory indemnification rights or
          analogous rights, and each party waives such common law or statutory
          rights.
        </p>
        <p>
          {" "}
          <strong>6. Support and Upgrades.</strong>
        </p>
        <p>
          <strong>a. Software Maintenance Program. </strong>StudioPlus may sell
          you a Software Maintenance Program (“SMP”), for each License, or may
          include an SMP with your Subscription, that provides regular upgrades
          and support during the term of the SMP. &nbsp;The retail price of the
          SMP is defined in the Portal.&nbsp; All other training, upgrade,
          support or other professional services shall be furnished by
          StudioPlus to you at an additional fee determined in accordance with
          StudioPlus then-current standard rates, which shall include all
          reasonable travel costs and expenses of StudioPlus.
        </p>
        <p>
          {" "}
          <strong>b. Support. </strong>StudioPlus will provide “First-Level”
          support direct to End Users of non-Enterprise SMP subscribers, and
          “Second-Level” support to senior-level support personnel of Enterprise
          SMP subscribers.<strong></strong>
        </p>
        <p>
          <strong>(i)</strong> StudioPlus will provide reasonable telephone,
          email, and chat support in English and during StudioPlus's non-holiday
          weekdays between the hours of 8:30 a.m. to 5 p.m. US Central
          Time.&nbsp; StudioPlus may make an effort to be available for support
          outside these hours, but does not guarantee availability outside these
          standard hours. StudioPlus may also make available other support
          material, including software documentation, training videos, and
          web-forums.
        </p>
        <p>
          <strong>(ii) </strong>You shall use commercially reasonable efforts to
          provide StudioPlus with the necessary access (e.g., access to database
          files, log files, or program files) required to provide Support.
          StudioPlus does not ensure its performance of the technical support
          described herein if such access is not provided by you when requested
          by StudioPlus. Support shall include but not be limited to (1) a
          diagnosis of problems or issues of the supported programs and (2)
          reasonable commercial efforts to resolve reported and verifiable
          errors in supported programs so that such supported programs perform
          in all material respects the functions described in the associated
          documentation.
        </p>
        <p>
          <strong>(iii)</strong> StudioPlus requires that SMP subscribers
          maintain appropriate hardware, database and operating system
          configurations at all times, as well as current database backups of
          StudioPlus Software. If we determine that the Problem may be related
          to a product, including but not limited to hardware or software, from
          a vendor with whom we have a cooperative support relationship, then we
          will collaboratively work with that vendor to address the Problem. If
          we determine that the Problem may be related to a product from a
          vendor that we do not have a cooperative support relationship with, we
          may ask you to request such vendor to work with us to address the
          Problem. If the issue relates to the third party product and not to
          the Licensed Software, then the third party vendor (and not
          StudioPlus) will determine the progress and resolution of that issue.
          StudioPlus Support Services do not include installing, fixing,
          conducting root cause analysis, providing product patches or updates
          for open source code or any other third party products or otherwise
          supporting third party products.
        </p>
        <p>
          <strong>(iv)</strong> StudioPlus requires that Enterprise SMP
          subscribers establish and maintain the organization and processes to
          provide “First Level Support” for the StudioPlus supported programs
          directly to your End Users. First Level Support shall include but not
          be limited to (1) a direct response to End Users with respect to
          inquiries concerning the performance, functionality or operation of
          the supported programs, (2) a direct response to End Users with
          respect to problems or issues with the supported programs, (3) a
          diagnosis of problems or issues of the supported programs, and (iv) a
          resolution of problems or issues of the supported programs. After
          reasonable commercial efforts, you are unable to diagnose or resolve
          problems or issues of the supported programs, you senior-level support
          personnel shall contact StudioPlus for “Second Level Support”.
        </p>
        <p>
          {" "}
          <strong>7. Software.</strong>
        </p>
        <p>
          <strong>
            a. Additional Software for use with the Online Services.{" "}
          </strong>
          To enable optimal access and use of certain Online Services, you may
          install and use certain Software in connection with your use of the
          Online Service. The number of copies of the Software you will be
          permitted to use or the number of devices on which you will be
          permitted to use the Software will be as described in the Online
          Services Terms in the product specific license terms for the Online
          Service. We may check the version of the Software you are using and
          recommend or download updates, with or without notice, to your
          devices. Failure to install updates may affect your ability to use
          certain functions of the Online Service. You must uninstall the
          Software when your right to use it ends. We may also disable it at
          that time. Your rights to access Software on any device do not give
          you any right to implement StudioPlus patents or other StudioPlus
          intellectual property in software or devices that access that device.
        </p>
        <p>
          {" "}
          <strong>b. License confirmation. </strong>Proof of your Software
          license is (1) this agreement, (2) any order confirmation, and (3)
          proof of payment.
        </p>
        <p>
          {" "}
          <strong>
            c. License rights are not related to fulfillment of Software media.
          </strong>{" "}
          Your acquisition of Software media or access to a network source does
          not affect your license to Software obtained under this agreement. We
          license Software to you, we do not sell it.
        </p>
        <p>
          {" "}
          <strong>d. Transferring and assigning licenses</strong>. License
          transfers are not permitted.
        </p>
        <p>
          {" "}
          <strong>8. Miscellaneous.</strong>
        </p>
        <p>
          <strong>a. Notices.</strong> You must send notices by mail, return
          receipt requested, to the address below.{" "}
        </p>
        <p>
          StudioPlus Software, LLC. 2070 River Reach Drive # 77 Naples, FL 34104
          You agree to receive electronic notices from us, which will be sent by
          email to the account administrator you specify in the Portal. Notices
          are effective on the date on the return receipt or, for email, when
          sent. You are responsible for ensuring that the account administrator
          email address that you specify in the Portal is accurate and current.
          Any email notice that we send to that email address will be effective
          when sent, whether or not you actually receive the email.
        </p>
        <p>
          {" "}
          <strong>b. Assignment.</strong> You may not assign this agreement
          either in whole or in part.
        </p>
        <p>
          {" "}
          <strong>c. Severability.</strong> If any part of this agreement is
          held unenforceable, the rest remains in full force and effect.
        </p>
        <p>
          {" "}
          <strong>d. Waiver.</strong> Failure to enforce any provision of this
          agreement will not constitute a waiver.
        </p>
        <p>
          <strong>e. No agency. </strong>This agreement does not create an
          agency, partnership, or joint venture.
        </p>
        <p>
          <strong>f. No third-party beneficiaries. </strong>There are no
          third-party beneficiaries to this agreement.
        </p>
        <p>
          {" "}
          <strong>g. Applicable law and venue.</strong> This agreement is
          governed by Florida law, without regard to its conflict of laws
          principles, except that (i) if you are a U.S. Government entity, this
          agreement is governed by the laws of the United States, and (ii) if
          you are a state or local government entity in the United States, this
          agreement is governed by the laws of that state. Any action to enforce
          this agreement must be brought in the State of Florida. This choice of
          jurisdiction does not prevent either party from seeking injunctive
          relief in any appropriate jurisdiction with respect to violation of
          intellectual property rights. In the event any lawsuit or other
          enforcement action is undertaken to enforce StudioPlus' rights
          hereunder, you agree to reimburse StudioPlus for its reasonable
          attorney’s fees and other costs incurred in enforcing such rights.
        </p>
        <p>
          {" "}
          <strong>h. Entire agreement.</strong> This agreement is the entire
          agreement concerning its subject matter and supersedes any prior or
          concurrent communications. In the case of a conflict between any
          documents in this agreement that is not expressly resolved in those
          documents, their terms will control in the following order of
          descending priority: (1) the Subscription Agreement, (2) these Terms,
          (3) any applicable Offer Details, and (4) any other documents in this
          agreement.
        </p>
        <p>
          {" "}
          <strong>i. Survival.</strong> The terms in Sections 1, 2.e, 4, 5, 6,
          7, 8, and 9 will survive termination or expiration of this agreement.
        </p>
        <p>
          {" "}
          <strong>j. Force majeure</strong>. Neither party will be liable for
          any failure in performance due to causes beyond that party's
          reasonable control (such as fire, explosion, power blackout,
          earthquake, flood, severe storms, strike, embargo, labor disputes,
          acts of civil or military authority, war, terrorism (including cyber
          terrorism), acts of God, acts or omissions of Internet traffic
          carriers, actions or omissions of regulatory or governmental bodies
          (including the passage of laws or regulations or other acts of
          government that impact the delivery of Online Services)). This Section
          will not, however, apply to your payment obligations under this
          agreement.
        </p>
        <p>
          {" "}
          <strong>k. Authority.</strong> If you are an individual accepting
          these terms on behalf of an entity, you represent that you have the
          legal authority to enter into this agreement on that entity's behalf.
        </p>
        <p>
          {" "}
          <strong>l. Irreparable harm</strong>.&nbsp; You agree that any breach
          or violation of this Agreement involving the unauthorized use or
          disclosure of confidential or proprietary information or materials is
          likely to result in irreparable harm to StudioPlus, and that in such
          event, that StudioPlus will be entitled to obtain a court order
          enjoining you from such conduct.
        </p>
        <p>
          {" "}
          <strong>m.</strong> <strong>Export Control. </strong>You may not use
          or otherwise export or re-export the StudioPlus Software except as
          authorized by United States law and the laws of the jurisdiction(s) in
          which the StudioPlus Software was obtained. In particular, but without
          limitation, the StudioPlus Software may not be exported or re-exported
          (a) into any U.S. embargoed countries or (b) to anyone on the U.S.
          Treasury Department’s list of Specially Designated Nationals or the
          U.S. Department of Commerce Denied Person’s List or Entity List or any
          other restricted party lists. By using the StudioPlus Software, you
          represent and warrant that you are not located in any such country or
          on any such list. You also agree that you will not use the StudioPlus
          Software for any purposes prohibited by United States law, including,
          without limitation, the development, design, manufacture or production
          of missiles or nuclear, chemical or biological weapons.
        </p>
        <p>
          <strong>n. Amendment</strong>.&nbsp; StudioPlus reserves the right to
          make changes to these terms from time to time, which shall be
          effective upon notice to you.&nbsp; You agree to promptly review any
          such revised terms.&nbsp;{" "}
        </p>
        <p>
          <strong>o. Privacy Policy</strong>.&nbsp; The terms and conditions of
          StudioPlus’s applicable Privacy Policy are hereby incorporated by
          referenced herein.<strong></strong>
        </p>
        <p>
          <strong>9.Definitions.</strong>
        </p>
        <p> Any reference in this agreement to "day" will be a calendar day.</p>
        <p>
          {" "}
          "Acceptable Use Policy" is set forth in the Online Services Terms.
        </p>
        <p>
          {" "}
          "Affiliate" means any legal entity that a party owns, that owns a
          party, or that is under common ownership with a party.
        </p>
        <p>
          "Ownership" means, for purposes of this definition, control of more
          than a 50% interest in an entity.
        </p>
        <p>
          {" "}
          "Customer Data" means all data, including all text, sound, video, or
          image files, and software, that are provided to StudioPlus by, or on
          behalf of, Customer through use of the Online Service. All Customer
          Data shall remain the property of You or Customer, as applicable.
        </p>
        <p>
          {" "}
          "End User" means any person you permit to access Customer Data hosted
          in the Online Services or otherwise use the Online Services, or any
          user of a Customer Solution.
        </p>
        <p>
          {" "}
          “Enterprise Customer” means an entity on an enterprise agreement to
          use the Product.
        </p>
        <p>
          {" "}
          "Non-StudioPlus Product" means any third-party-branded software, data,
          service, website or product.
        </p>
        <p>
          {" "}
          "Offer Details" means the pricing and related terms applicable to a
          Subscription offer, as published in the Portal.
        </p>
        <p>
          {" "}
          "Online Services" means any of the StudioPlus-hosted online services
          subscribed to by Customer under this agreement.
        </p>
        <p>
          {" "}
          “Online Services Terms” means the Terms of Use, User Access Agreement,
          or other applicable terms (including any Privacy or Security Policies)
          required for use and access to Online Services.
        </p>
        <p>
          {" "}
          "Previews" means preview, beta, or other pre-release version or
          feature of the Online Services or Software offered by StudioPlus to
          obtain customer feedback.
        </p>
        <p> "Portal" means the Online Services' respective web sites.</p>
        <p> "Product" means any Online Service (including any Software).</p>
        <p>
          {" "}
          "Software" means software we provide for installation on your device
          as part of your Subscription or to use with the Online Service to
          enable certain functionality.
        </p>
        <p>
          {" "}
          "Subscription" means an enrollment for Online Services for a defined
          Term as specified on the Portal. You may purchase multiple
          Subscriptions, which may be administered separately and which will be
          governed by the terms of a separate StudioPlus Subscription Agreement.
        </p>
        <p>
          {" "}
          "Term" means the duration of a Subscription (e.g., 30 days or 12
          months).
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Last revised: January 01, 2018</em>
        </p>
      </div>
    </>
  );
};

export default TermsOfUsePage;
