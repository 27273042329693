import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import {
  Label,
  FormFeedback,
  Row,
  Col,
  FormGroup,
  //   Alert
} from "reactstrap";
import {
  AllStateAbbreviations,
  allCountriesFormattedForDropdown,
} from "../../util";
import InputFloat from "../inputFloat";
import AuthStore from "../../../stores/authStore";

const AddressFormSection: React.FC<any> = ({
  errors,
  values,
  setFieldValue,
  touched,
  content,
  publicEvents,
  eventStore,
}) => {
  const { t } = useTranslation();
  if (!values.country) {
    values.country = AuthStore.countryFormatted;
  }

  return (
    <Row className="row-sm">
      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.first_name && touched.first_name ? "is-invalid" : ""
            }`}
            name="first_name"
            id="first_name"
            label={t("addressFormSectionComponent.firstName")}
            component={InputFloat}
          />
          <ErrorMessage name="first_name">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>

      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.last_name && touched.last_name ? "is-invalid" : ""
            }`}
            name="last_name"
            id="last_name"
            label={t("addressFormSectionComponent.lastName")}
            component={InputFloat}
          />
          <ErrorMessage name="last_name">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>

      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.street_1 && touched.street_1 ? "is-invalid" : ""
            }`}
            name="street_1"
            id="street_1"
            label={t("addressFormSectionComponent.addressLine1")}
            component={InputFloat}
          />
          <ErrorMessage name="street_1">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>

      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.street_2 && touched.street_2 ? "is-invalid" : ""
            }`}
            name="street_2"
            id="street_2"
            label={t("addressFormSectionComponent.addressLine2")}
            component={InputFloat}
          />
          <ErrorMessage name="street_2">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>

      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.city && touched.city ? "is-invalid" : ""
            }`}
            name="city"
            id="city"
            label={t("addressFormSectionComponent.city")}
            component={InputFloat}
          />
          <ErrorMessage name="city">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>
      {values.country !== "USA" && (
        <Col sm="6">
          <FormGroup>
            <Field
              className={`form-control ${
                errors.state && touched.state ? "is-invalid" : ""
              }`}
              name="state"
              id="state"
              label={t("addressFormSectionComponent.region")}
              component={InputFloat}
            />
            <ErrorMessage name="state">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
        </Col>
      )}
      {values.country === "USA" && (
        <Col sm="6">
          <FormGroup
            className={`form-field-float${values.state ? " active" : ""}`}
          >
            <Label htmlFor="state">
              {t("addressFormSectionComponent.state")}
            </Label>
            <Select
              id="state"
              classNamePrefix="react-select-group"
              className={`react-select-group-container ${
                errors.state && touched.state ? " is-invalid" : ""
              }`}
              name="state"
              placeholder=""
              defaultValue={
                values.state
                  ? { label: values.state, value: values.state }
                  : null
              }
              options={AllStateAbbreviations.map((s: string) => ({
                label: s,
                value: s,
              }))}
              onChange={(val: any) => {
                setFieldValue("state", val.value);
              }}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
            <ErrorMessage name="state">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
        </Col>
      )}

      <Col sm="6">
        <FormGroup>
          <Field
            className={`form-control ${
              errors.zip && touched.zip ? "is-invalid" : ""
            }`}
            name="zip"
            id="zip"
            label={
              values.country === "USA"
                ? t("addressFormSectionComponent.zipCode")
                : t("addressFormSectionComponent.postalCode")
            }
            component={InputFloat}
          />
          <ErrorMessage name="zip">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>

      <Col sm="6">
        <FormGroup
          className={`form-field-float${values.country ? " active" : ""}`}
        >
          {!AuthStore.countryFormatted && (
            <Label htmlFor="country">
              {t("addressFormSectionComponent.country")}
            </Label>
          )}

          {AuthStore.accountName.toLowerCase() === "annegeddes" ? (
            <>
              <Select
                id="country"
                classNamePrefix="react-select-group"
                className={`react-select-group-container ${
                  errors.country && touched.country ? " is-invalid" : ""
                }`}
                name="country"
                placeholder=""
                defaultValue={allCountriesFormattedForDropdown.find(
                  (opt: any) => opt.value === values.country
                )}
                options={allCountriesFormattedForDropdown}
                isDisabled={true}
                onChange={(val: any) => {
                  setFieldValue("country", val.value);
                }}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
              />
            </>
          ) : (
            <>
              <Select
                id="country"
                classNamePrefix="react-select-group"
                className={`react-select-group-container ${
                  errors.country && touched.country ? " is-invalid" : ""
                }`}
                name="country"
                placeholder=""
                defaultValue={allCountriesFormattedForDropdown.find(
                  (opt: any) => opt.value === values.country
                )}
                options={allCountriesFormattedForDropdown}
                onChange={(val: any) => {
                  setFieldValue("country", val.value);
                }}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
              />
            </>
          )}
          <ErrorMessage name="country">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>
        </FormGroup>
      </Col>
    </Row>
  );
};
export default AddressFormSection;
