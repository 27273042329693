import React from 'react';
import { Field, ErrorMessage } from 'formik';

const RadioSection: React.FC<any> = ({ errors, values, setFieldValue, touched, content }) => {
    return (
        <>
            <div className="custom-control custom-radio custom-control-inline">
                <Field
                    id="date_radio"
                    value="date"
                    className={`custom-control-input${errors.search_by && touched.search_by ? ' error' : ''}`}
                    type="radio"
                    name="search_by"
                    checked={values.search_by === 'date'}
                    onChange={(e:any) => setFieldValue('search_by', e.target.value)}
                />
                <label htmlFor="date_radio" className={`custom-control-label${errors.search_by && touched.search_by ? ' error' : ''}`}>{content.date}</label>
            </div>

            <div className="custom-control custom-radio custom-control-inline">
                <Field
                    value="code"
                    id="code_radio"
                    className={`custom-control-input${errors.search_by && touched.search_by ? ' error' : ''}`}
                    type="radio"
                    name="search_by"
                    checked={values.search_by === 'code'}
                    onChange={(e:any) => setFieldValue('search_by', e.target.value)}
                />
                <label htmlFor="code_radio"className={`custom-control-label${errors.search_by && touched.search_by ? ' error' : ''}`}>{content.code}</label>
            </div>

            <div className="custom-control custom-radio custom-control-inline">
                <Field
                    value="publicCode"
                    id="public_radio"
                    className={`custom-control-input${errors.search_by && touched.search_by ? ' error' : ''}`}
                    type="radio"
                    name="search_by"
                    checked={values.search_by === 'publicCode'}
                    onChange={(e:any) => setFieldValue('search_by', e.target.value)}
                />
                <label htmlFor="public_radio" className={`custom-control-label${errors.search_by && touched.search_by ? ' error' : ''}`}>{content.public}</label>
            </div>
            <ErrorMessage name="search_by">{(msg) => <div className="error">{msg}</div>}</ErrorMessage>
        </>
    )
}
export default RadioSection;