import React from 'react';
import { observer,inject } from 'mobx-react'

const BlankPage: React.FC<any> = ({sessionStore, authStore, loginPromptStore,navigationStore}) => {
  return (
    <p>This is an iframe blank page.</p>
  );
}

export default inject(
  'sessionStore', 
  'authStore', 
  'loginPromptStore',
  'navigationStore'
  )(observer(BlankPage))
