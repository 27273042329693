import React, { useEffect, useState, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
// import CommentForm from "./CommentForm";

import {
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  Spinner,
  Button,
  Card,
} from "reactstrap";

import "./Blog.scss";

import {
  AiOutlineUser,
  AiOutlineFieldTime,
  AiOutlineComment,
} from "react-icons/ai";
import CommentForm from "./CommentForm";
import { FiArrowLeft } from "react-icons/fi";

const BlogPostDetails: React.FC<any> = ({
  history,
  clientStore,
  wpStore,
  navigationStore,
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const [reloadCommentForm, setReloadCommentForm] = useState<boolean>(true);
  const [commentDetails, setCommentDetails] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);

  function getData() {
    setLoader(true);
    let id = query.get("id");
    if (!id) {
      history.push("/dashboard/blog");
      return;
    }

    wpStore.getBlogPost(id);
    setLoader(false);
  }

  async function getComments() {
    await wpStore.getBlogComments(query.get("id"));
    // console.log("wpStore.activeBlogComments", wpStore.activeBlogComments)

    setReloadCommentForm(false);
    // setTimeout(() => {
    setReloadCommentForm(true);
    // }, 100);

    if (wpStore.activeBlogComments.length > 4) {
      setCommentDetails(wpStore.activeBlogComments.slice(0, 4));
    } else {
      setCommentDetails(wpStore.activeBlogComments);
    }
  }

  const showFullComments = (e: any) => {
    setCommentDetails(wpStore.activeBlogComments);
  };
  const showLessComments = (e: any) => {
    setCommentDetails(wpStore.activeBlogComments.slice(0, 4));
  };

  useEffect(() => {
    getData();
    getComments();
  }, []);

  const backToBlog = (e: any) => {
    navigationStore.push("/blog");
  };

  function reformatDate(dateStr: any) {
    const dArr = dateStr.split("-");
    return dArr[1] + "/" + dArr[2] + "/" + dArr[0];
  }

  return (
    <>
      <>
        {loader && (
          <div className="spinner-center">
            <Spinner color="primary" />
          </div>
        )}

        {!loader && (
          <>
            {wpStore.activeBlogData.id ? (
              <section className="page-section page-section-2column centered-content-blog">
                <div className="page-section-main">
                  <div className="col-sm text-left text-sm-left order-sm-1 mt-3 mb-3 mt-sm-0">
                    <Button color="default-link" onClick={backToBlog}>
                      <FiArrowLeft className="mr-2" />
                      Back to Blog
                    </Button>
                  </div>

                  <Row>
                    <Col>
                      <Card className="mb-5">
                        <CardBody>
                          <CardTitle className="blog-title blog-detail-content">
                            <div
                              className="blog-title-content"
                              dangerouslySetInnerHTML={{
                                __html: wpStore.activeBlogData?.title?.rendered,
                              }}
                            ></div>
                          </CardTitle>
                        </CardBody>

                        <CardBody className="mt-1 mb-1 pt-1 pb-1">
                          <Row>
                            <Col md="6" sm="6" xs="6">
                              <CardSubtitle className="icon-col">
                                <AiOutlineUser size="22" />
                                <span className="author-name">
                                  {
                                    wpStore.activeBlogData?._embedded["author"][
                                      "0"
                                    ]?.name
                                  }
                                </span>
                              </CardSubtitle>
                            </Col>
                            <Col md="6" sm="6" xs="6">
                              <CardSubtitle className="icon-col float-right">
                                <AiOutlineFieldTime size="22" />
                                <span>
                                  {" "}
                                  {reformatDate(
                                    wpStore.activeBlogData?.date?.split("T")[0]
                                  )}
                                </span>
                              </CardSubtitle>
                            </Col>
                          </Row>
                        </CardBody>

                        <div className="mb-2 mt-2 blog-post-content blog-detail-content">
                          <div className="flex-col">
                            <div className="blog-image-col">
                              {wpStore.activeBlogData?._embedded[
                                "wp:featuredmedia"
                              ] ? (
                                <img
                                  src={
                                    wpStore.activeBlogData?._embedded[
                                      "wp:featuredmedia"
                                    ]["0"].source_url
                                  }
                                  className=""
                                  alt="img"
                                />
                              ) : (
                                <img src="/blog-banner.png" alt="img" />
                              )}
                            </div>
                            <div className="content-col">
                              <CardBody class="pl-0 pr-0">
                                <div
                                  className="blog-content"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      wpStore.activeBlogData?.content?.rendered,
                                  }}
                                ></div>
                              </CardBody>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CardText className="comment-box-wp">
                        <div className="comment-header">
                          <p>
                            {" "}
                            Showing <span>{commentDetails.length}</span> of
                            total{" "}
                            <span>{wpStore.activeBlogComments.length}</span>
                            Comments
                          </p>
                        </div>

                        <ul className="media-list comments-list">
                          {commentDetails?.map((comment: any, index: any) => (
                            <Fragment key={index}>
                              <li className="media">
                                {" "}
                                <span className="pull-left">
                                  <img
                                    className="media-object"
                                    src="/user.svg"
                                    alt="Avatar"
                                  />{" "}
                                </span>
                                <div className="media-body">
                                  <h6 className="media-heading">
                                    <span className="author-name">
                                      {comment?.author_name}
                                    </span>{" "}
                                    <small>
                                      {reformatDate(
                                        comment?.date?.split("T")[0]
                                      )}
                                    </small>
                                  </h6>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: comment?.content?.rendered,
                                    }}
                                  ></p>
                                </div>
                              </li>
                            </Fragment>
                          ))}
                        </ul>

                        <div className="bottom-comment-box">
                          {wpStore.activeBlogComments.length > 4 &&
                            (commentDetails.length <= 4 ? (
                              <p
                                className="toggle-comments"
                                onClick={showFullComments}
                              >
                                <span>
                                  <AiOutlineComment size="17" />{" "}
                                </span>{" "}
                                <span>See all comments</span>
                              </p>
                            ) : (
                              <p
                                className="toggle-comments"
                                onClick={showLessComments}
                              >
                                <span>
                                  <AiOutlineComment size="17" />{" "}
                                </span>{" "}
                                <span>See less comments</span>
                              </p>
                            ))}
                        </div>

                        <div className="user-comment-box">
                          <div className="reply-header">Leave a Comment</div>
                          {reloadCommentForm && (
                            <CommentForm
                              onSuccess={getComments}
                              postID={query.get("id")}
                              clientStore={clientStore}
                            />
                          )}
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </div>
              </section>
            ) : (
              <div className="d-flex lg-size-icon  align-item-center w-100 ion-justify-content-center ion-align-items-center mt-3 center-loader">
                <Spinner color="primary" />
              </div>
            )}
          </>
        )}
      </>
    </>
  );
};

export default inject(
  "authStore",
  "clientStore",
  "wpStore",
  "navigationStore"
)(observer(BlogPostDetails));
