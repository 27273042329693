import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import axios, { AxiosResponse } from "axios";
import {
  PriceList,
  PriceListGroup,
  PriceListItem,
  PriceListItemImage,
} from "../../interfaces/priceList.int";
import EcomStore from "../ecomStore";

export default class EcomPriceListBaseClass {
  @persist("object") @observable priceList: PriceList = {};
  @persist("object") @observable selectedProductGroup: PriceListGroup = {};
  @persist("object") @observable selectedProduct: PriceListItem = {};
  @persist("object") @observable croppedPhoto: any = {};
  @persist("list") @observable colorizationOptions: Array<any> = [];
  @persist @observable sortBy: string = "";

  @computed get colorizationOptionsFormattedForDropdown() {
    return this.colorizationOptions.map((option: any) => ({
      label: option.Description,
      value: option.Key,
    }));
  }
  @computed get productGroupItemsBySalesRating() {
    return (
      this.selectedProductGroup?.Items?.sort(
        (a: any, b: any) => a.SalesRating - b.SalesRating
      ).map((item: PriceListItem) => item) || []
    );
  }
  @computed get productGroupItemsByPriceLowToHigh() {
    return (
      this.selectedProductGroup?.Items?.sort(
        (a: any, b: any) => a.Price - b.Price
      ).map((item: PriceListItem) => item) || []
    );
  }
  @computed get productGroupItemsByPriceHighToLow() {
    return (
      this.selectedProductGroup?.Items?.sort(
        (a: any, b: any) => (a.Price - b.Price) * -1
      ).map((item: PriceListItem) => item) || []
    );
  }

  @computed get productGroupItemsByDescription() {
    return (
      this.selectedProductGroup?.Items?.sort((a: any, b: any) => {
        const descriptionA = a.Name.toUpperCase();
        const descriptionB = b.Name.toUpperCase();

        let comparison = 0;
        if (descriptionA > descriptionB) {
          comparison = 1;
        } else if (descriptionA < descriptionB) {
          comparison = -1;
        }
        return comparison;
      }).map((item: PriceListItem) => item) || []
    );
  }

  @computed get productGroupsFormattedForDropdown() {
    let formatted: Array<any> = [];
    if (this.priceList?.Groups?.length) {
      formatted = this.priceList.Groups.filter(function (obj) {
        // Only include groups where hidden is false.
        return !obj.HiddenGroup;
      }).map((group: PriceListGroup) => ({
        label: group.Name,
        value: group.Key,
      }));
    }
    return formatted;
  }
  @computed get relatedProducts() {
    let relatedProducts: Array<any> = [];
    if (!!this.selectedProduct?.RelatedItemKeys) {
      let formattedItems = this.priceList?.Groups?.map((group: any) => {
        const newItems = group.Items.map((item: any) => ({
          ...item,
          groupKey: group.Key,
        }));
        return { ...group, Items: newItems };
      });

      let allItems: any = formattedItems
        ?.map((group: any) => group.Items)
        .reduce((total: any, amount: any) => total.concat(amount));
      relatedProducts = allItems?.filter(
        (item: any) =>
          !!item?.Key &&
          this.selectedProduct?.RelatedItemKeys?.includes(item.Key)
      );
    }
    return relatedProducts;
  }

  @action findItemByKey(key: string) {
    return this.priceList?.Groups?.map((g: any) => g.Items)
      .flat(1)
      .find((i: any) => i.Key === key);
  }

  @computed get croppedPhotoEnhancementsFormattedForDropdown() {
    return (
      this.findItemByKey(this.croppedPhoto?.ItemKey)?.Enhancements?.map(
        (e: any) => ({
          label: `${e.Description} +$${e.Price.toFixed(2)}`,
          value: e.Key,
          price: e.Price,
          customLabel: e.Description,
        })
      ) || []
    );
  }

  @computed get croppedPhotoEnhancementsFormatted() {
    return (
      this.croppedPhoto?.Enhancements?.map((e: any) => ({
        label: e.EnhancementDescription,
        value: e.EnhancementKey,
      })) || []
    );
  }

  @computed get croppedPhotoOptionsFormattedForDropdown() {
    return (
      this.findItemByKey(this.croppedPhoto?.ItemKey)?.Options?.map(
        (e: any) => ({
          label: `${e.Description} +$${e.Price.toFixed(2)}`,
          customLabel: e.Description,
          value: e.Key,
          price: e.Price,
        })
      ) || []
    );
  }

  @computed get croppedPhotoOptionsFormatted() {
    return (
      this.croppedPhoto?.Options?.map((e: any) => ({
        label: e.OptionDescription,
        value: e.OptionKey,
      })) || []
    );
  }

  @computed get selectedProductEnhancementsFormattedForDropdown() {
    return (
      this.selectedProduct?.Enhancements?.map((e: any) => ({
        label: `${e.Description} +$${e.Price.toFixed(2)}`,
        value: e.Key,
        price: e.Price,
        customLabel: e.Description,
      })) || []
    );
  }
  @computed get selectedProductOptionsFormattedForDropdown() {
    return (
      this.selectedProduct?.Options?.map((e: any) => ({
        label: `${e.Description} +$${e.Price.toFixed(2)}`,
        customLabel: e.Description,
        value: e.Key,
        price: e.Price,
      })) || []
    );
  }

  @action selectProduct(key: string) {
    if (this.selectedProductGroup?.Items?.length) {
      this.selectedProduct =
        this.selectedProductGroup.Items.find(
          (i: PriceListItem) => i.Key === key
        ) || {};
    }
  }

  @action selectProductGroup(key: string) {
    if (this.priceList?.Groups?.length) {
      this.selectedProductGroup =
        this.priceList.Groups.find((g: PriceListGroup) => g.Key === key) || {};
      this.selectedProduct = {};
    }
  }

  @computed get selectedProductGalleryImages() {
    return this.selectedProduct?.ProductImages?.map(
      (img: PriceListItemImage) => ({
        original: `${img.Url}`,
        thumbnail: `${img.ThumbUrl}`,
        thumbnailClass: "gallery-custom-thumbnail",
        originalClass: "gallery-custom-original",
      })
    );
  }

  @action getPriceList(key: string) {
    return axios({
      method: "GET",
      url: `/ibyPriceList/${key}`,
    }).then((res: AxiosResponse) => {
      this.priceList = res.data;
      this.selectedProductGroup = {};
      if (!EcomStore.searchTerm) {
        this.selectedProduct = {};
      }
    });
  }
  @action getColorizationOptions() {
    return axios({
      method: "GET",
      url: `/ibyColorization`,
    }).then((res: AxiosResponse) => {
      //console.log(res.data);
      this.colorizationOptions = res.data;
    });
  }
}
