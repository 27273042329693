import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import ImageTileList from "../../components/imageTileList/imageTileList";
import ResponsiveHeader from "../../components/responsiveHeader/responsiveHeader";
import ProgressSpinner from "../../components/spinner/spinner";

const FavoritesPage: React.FC<any> = ({
  sessionStore,
  authStore,
  loginPromptStore,
  navigationStore,
}) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (authStore.isGuestSession) {
      loginPromptStore.setShowLoginPrompt(true);
      navigationStore.goBack();
    }
  }, []);

  useEffect(() => {
    setReload(true);

    setTimeout(() => {
      setReload(false);
    }, 500);
  }, [sessionStore.activeSessionFavoritedImages]);

  return (
    <>
      <ResponsiveHeader />
      <Helmet>
        <title>
          {t("FavoritesPage.urlTitle")} {sessionStore.activeSession.Description}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>

      {!reload && (
        <ImageTileList
          images={sessionStore.activeSessionFavoritedImages}
          favorites={true}
        >
          <ProgressSpinner
            showSpinner={sessionStore.loading}
            color="primary"
            containerClass="sessionList"
          />
        </ImageTileList>
      )}
      {!sessionStore.activeSessionFavoritedImages[0] && (
        <>
          <h3 className="title p-5 ion-align-items-center center-align flex-wrap">
            <>You do not have any favorite images.</>
          </h3>
        </>
      )}
    </>
  );
};

export default inject(
  "sessionStore",
  "authStore",
  "loginPromptStore",
  "navigationStore"
)(observer(FavoritesPage));
