export default{

    "AccountPage": {
        "title": "Mon compte",
        "loginSubtitle": "Infos de connexion",
        "loginText": "Connecté en tant que",
        "editUsername": "Modifier le nom d’utilisateur",
        "changePassword": "Changer mot de passe",
        "personalInfoSubtitle": "Informations personnelles",
        "familyMemberSubtitle": "Membres de la famille",
        "edit": "Edit",
        "delete": "Supprimer",
        "default": "Par défaut",
        "paymentSubtitle": "Paiement",
        "paymentText": "Mettre à jour les informations de carte de crédit enregistrées",
        "communicationSubtitle": "Communication",
        "communicationText": "Pour les promotions et les opt-ins newsletter",
        "shippingSubtitle": "Adresses d’expédition",
        "shippingText": "Utiliser pour la caisse comme informations d’expédition",
        "upcomingSubtitle": "Prochaine",
        "upcomingText": "Confirmer le report ou l’annulation",
        "noUpcomingText": "Vous n’avez pas de sessions à venir",
        "tableDate": "Date",
        "tableTime": "Heure",
        "tableDescription": "Description",
        "tableAction": "Action",
        "confirm": "Confirmer",
        "reschedule": "Reporter",
        "cancel": "Annuler",
        "scheduleButton": "Planifier maintenant",
        "deleteAddressButton": "Êtes-vous sûr de vouloir supprimer cette adresse ?",
        "updateAddressModalTitle": "Mettre à jour l’adresse",
        "updateUsernameModalTitle": "Mettre à jour le nom d’utilisateur",
        "updatePasswordModalTitle": "Changer mot de passe",
        "updatePersonalInfoModalTitle": "Mettre à jour les informations personnelles",
        "updateCommunicationModalTitle": "Mettre à jour les opt-ins",
        "updatePaymentModalTitle": "Paiement",
        "scheduleModalTitle": "Annexe",
        "rescheduleModalTitle": "Reporter",
        "clickhere": "Cliquez ici",
    },
    "ForgotPasswordPage": {
        "title": "Mot de passe oublié",
        "subtitle": "Un e-mail sera envoyé à votre adresse e-mail avec d’autres instructions",
        "alertSuccess": "Vous devriez recevoir un e-mail sous peu. Veuillez vérifier votre dossier de courrier indésirable ou de spam si vous ne trouvez pas l’e-mail.",
        "backToLogin": "Retour à la connexion",
    },
    "LoginPage": {
        "title": "Connectez-vous pour voir des images étonnantes inspirées par vous!",
        "description": "Si vous avez déjà un compte, connectez-vous ici pour afficher vos images ou cliquez sur 'Recherche d’événements' ci-dessus pour rechercher un événement.",
        "email": "Adresse e-mail ou nom d’utilisateur",
        "password": "Mot de passe",
        "forgotPass": "Mot de passe oublié",
        "emailError": "Veuillez entrer une adresse e-mail ou un nom d’utilisateur",
        "ajaxError": "Nom d’utilisateur ou mot de passe non valide",
        "passwordError": "S’il vous plaît entrer un mot de passe",
        "loginButton": "Ouverture de session",
    },
    "ResetPasswordPage": {
        "title": "Entrez votre nouveau mot de passe",
        "alertSuccess": "Votre mot de passe a été réinitialisé avec succès. Vous serez redirigé vers la page de connexion.",
        "backToLogin": "Retour à la connexion",
    },
    "SignupPage": {
        "title": "Créer un compte",
        "p1": "Vous avez déjà un compte ?",
        "p2": "pour afficher vos photos.",
        "createAccountLink": "Connectez-vous ici",
        "email": "Courriel",
        "password": "Mot de passe",
        "signup": "Inscrivez-vous",
        "ajaxError": "Oups quelque chose s’est mal passé. S’ll vous plaît, réessayez.",
        "ajaxErrorOther": "Oups quelque chose s’est mal passé. S’ll vous plaît, réessayez.",
    },
    "ConfirmationPage": {
        "tabTitle": "Merci",
        "title": "Merci d’avoir passé votre commande.",
        "text": "Votre numéro de commande est",
        "relatedProducts": "Vous pourriez être intéressé par ...",
    },
    "CheckoutPage": {
        "urlTitle": "Contrôle sécurisé",
        "title": "Contrôle sécurisé",
        "subtitle": "Page protégée SSL/TSL 4096 bits",
    },
    "OrderReviewPage": {
        "urlTitle": "Contrôle sécurisé",
        "tableProductName": "Nom du produit",
        "tablePrice": "Prix",
        "tableQuantity": "Quantité",
        "tableSubtotal": "Sous-total",
        "enter": "Entrez-le maintenant",
        "apply": "S’appliquent",
        "cancel": "Annuler",
        "promo": "Promo",
        "shipping": "Frais d'envoi",
        "tax": "Taxe",
        "total": "Total",
    },
    "PaymentPage": {
        "urlTitle": "Contrôle sécurisé",
    },
    "ShippingAddressPage": {
        "urlTitle": "Contrôle sécurisé",
    },
    "ContractsPage": {
        "urlTitle": "Contrats de",
        "title": "Contrats de",
        "invalidPIN": "NIP non valide",
        "uploaded": "Téléchargé",
        "accepted": "Accepté",
        "printContract": "Contrat d’impression",
        "formLabel": "J’ai lu et compris les termes ci-dessus. Je suis d’accord sur les termes de cet accord.",
        "enterPin": "Entrez votre épingle numérique",
        "confirm": "Confirmer et soumettre",
    },
    "DashboardPage": {
        "loading": "Chargement...",
        "dashboard": "Tableau de bord",
        "title": "Vous n’avez actuellement pas de session.",
        "check": "Vérifier",
        "pendingContracts": "contrats en attente",
        "contactUs": "Contactez-nous",
        "noImages": "Vous n’avez pas d’images pour cette",
    },
    "EventSearchPage": {
        "title": "Rechercher des événements partagés et rires",
        "urlTitle": "Recherche d’événements",
        "p1": "Pour trouver un événement, sélectionnez-en un dans la liste ou la recherche par code d’événement ou par date d’événement.",
        "date": "Date de l’événement",
        "code": "Code privé",
        "public": "Événement public",
        "search": "'Recherche'",
    },
    "FavoritesPage": {
        "urlTitle": "Préféré",
    },
    "FoldersPage": {
        "urlTitle": "Dossier",
        "viewFull": "Afficher complet",
        "noFoldersFound": "Aucun dossier trouvé pour cela",
    },
    "FormsPage": {
        "urlTitle": "Formes",
        "title": "Formes",
        "uploaded": "Téléchargé",
    },
    "GuestsPage": {
        "urlTitle": "Invités",
        "title": "Invités",
        "error": "Il y a eu une erreur de mise à jour de l’invitation des invités.",
        "success": "Lien ressentiment avec succès",
        "copy": "Copié dans votre presse-papiers",
        "createNew": "Créer de nouvelles",
        "invitationLinks": "Liens d’invitation",
        "noLinksFound": "Aucun lien trouvé pour cela",
        "tableDescription": "Description",
        "tableDateCreated": "Date de création",
        "tableDateFirstUsed": "Date d’utilisation pour la première fois",
        "tableAction": "Action",
        "copyLink": "Copier le lien",
        "remove": "Supprimer",
        "invitedTitle": "Invités",
        "noGuestsFound": "Aucun invité trouvé pour cela",
        "tableName": "Nom",
        "tableEmail": "Courriel",
        "tableDateInvited": "Date invitée",
        "edit": "Edit",
        "resend": "Renvoyer",
        "updateGuest": "Mettre à jour l’invité",
        "createNewGuestInvitation": "Créer une nouvelle invitation invité",
        "generateText": "Générer un lien partageable pour donner à d’autres l’accès à vos images. Partagez le lien via les médias sociaux un texte ou par e-mail.",
        "emailInvite": "Invitation par e-mail",
        "sharableLink": "Lien partageable",
    },
    "HelpPage": {
        "urlTitle": "Aide",
        "title": "Aide",
        "ourInfo": "Nos informations",
        "hereToHelp": "Nous sommes là pour vous aider",
        "chatBy": "Discutez avec nous par",
        "clickingHere": "en cliquant ici",
    },
    "HomePage": {
        "signIn": "Connexion",
        "eventSearch": "Recherche d’événements",
        "privacy": "Déclaration de confidentialité",
        "userAccessAgreement": "Contrat d’accès à l’utilisateur",
        "termsOfUse": "Conditions d’utilisation",
        "close": "Fermer",
    },
    "OrderDetailsPage": {
        "urlTitle": "Ordonnance",
        "photo": "Photo",
        "setCrop": "Définir la culture et les options",
        "cropRequired": "La récolte est requise avant la caisse",
        "viewOrders": "Afficher les commandes",
        "order": "Ordonnance",
        "details": "Détails",
        "orderDate": "Date de commande",
        "makePayment": "Effectuer le paiement",
        "printOrder": "Ordre d’impression",
        "orderDetails": "Détails de la commande",
        "tableProduct": "Produit",
        "tablePrice": "Prix",
        "tableQty": "Qté",
        "tableTotal": "Total",
        "subtotal": "Sous-total",
        "promo": "Promo",
        "shipping": "Frais d'envoi",
        "tax": "Taxe",
        "payment": "Paiement",
        "total": "Total",
        "finalize": "Finaliser la sélection",
        "shippingInfo": "Envoi d’informations",
        "delivery": "Livraison",
        "confirm": "Confirmer la soumission",
        "cancel": "Annuler",
        "warning": "Cette action ne peut pas être annulée dans l’application.",
        "submitting": "Soumettre...",
        "yesConfirm": "Oui confirmer",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Numéro de commande",
        "tableOrderDate": "Date de commande",
        "tableDateCreated": "Date de création",
        "tableShippedTo": "Expédié à",
        "tableStatus": "Statut",
        "tableDescription": "Description",
        "tableOpenBalance": "Équilibre ouvert",
        "tableTotal": "Total",
        "orders": "Commandes",
        "orderHistory": "Historique des commandes",
        "preOrders": "Précommandes",
        "noOrders": "Actuellement, vous n’avez pas encore de commande.",
    },
    "SharePage": {
        "urlTitle": "C’est la page de titre",
        "title": "C’est la page de titre",
        "iframe": "Il s’agit d’une page blanche iframe.",
        "shareImage": "Partager cette image",
    },
    "SplashPage": {
        "wereSorry": "Nous sommes désolés",
        "doesntSeemTo": "ne semble pas être un jeton de partage valide.",
        "error": "On est désolés qu’il semble que quelque chose se soit mal passé. Veuillez aviser votre administrateur de site si cela continue.",
        "subdomainError": "Cette application s’attend à un sous-domaine. S’il vous plaît vérifier l’URL et assurez-vous qu’il ressemble à <votre nom de société.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Ajouter au dossier",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Partage social",
        "generatingShareable": "Générer une url partageable...",
    },
    "cartViewComponent": {
        "urlTitle": "Panier",
        "title": "Panier",
        "photo": "Photo",
        "swap": "Swap",
        "youHave": "Vous avez",
        "youHaveAdded": "Vous avez ajouté",
        "unitsRemaining": "unités restantes à ajouter à votre commande.",
        "unitsTooMany": "unités de trop à votre commande.",
        "cartFullModalTitle": "Trop d’unités",
        "tooMany": "Cet ordre contient trop d’unités.  S’il vous plaît retirer certains produits avant de vérifier.",
        "have": "Ont",
        "another": "Autre",
        "a": "Un",
        "ok": "Bien",
        "promoCode": "promo / code promo?",
        "promoCode1": "Code Promo",
        "removePromoCode": "Supprimer le code promo",
        "select": "Sélectionnez",
        "loading": "Chargement",
        "setCropAndOptions": "Définir la culture et les options",
        "cropRequired": "La récolte est requise avant la caisse",
        "checkout": "Vérifier",
        "tableAction": "Action",
        "tableProductName": "Nom du produit",
        "tablePrice": "Prix",
        "tableQuantity": "Quantité",
        "tableSubtotal": "Sous-total",
        "enterNow": "Entrez-le maintenant",
        "apply": "S’appliquent",
        "cancel": "Annuler",
        "promo": "Promo",
        "noItems": "Pas d’articles dans le chariot.",
        "checkOurProducts": "Consultez nos produits",
        "continueShopping": "Continuer à magasiner",
    },
    "createOrderIndexComponent": {
        "title": "Créer un ordre",
        "step1cta": "Procéder",
        "finish": "Finition",
    },
    "cropViewComponent": {
        "cropImage": "Rogner l’Image",
        "prepaid": "Prépayé",
        "shoppingCart": "Panier",
        "image": "Image",
        "zoomIn": "Zoom avant",
        "zoomOut": "Zoom arrière",
        "reset": "Remise à zéro",
        "cropOrientation": "Orientation des cultures",
        "selectPhotoOption": "Sélectionner l’option photo",
        "pleaseSelect": "S’il vous plaît sélectionner ...",
        "selectPhotoEnhancement": "Sélectionner l’amélioration de la photo",
        "selectColorEffect": "Sélectionner l’effet de couleur",
        "notes": "Notes",
        "apply": "S’appliquent",
        "backToPreorder": "Retour à la précommande",
        "backToShoppingCart": "Retour au panier",
    },
    "photoSelectComponent": {
        "swapPhoto": "Swap Photo",
        "prepaid": "Prépayé",
        "shoppingCart": "Panier",
        "backToPreorder": "Retour à la précommande",
        "backToShoppingCart": "Retour au panier",
    },
    "photoTileComponent": {
        "image": "Image",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Catégorie",
        "title": "Catégorie",
        "selectYourProduct": "Sélectionnez votre produit",
        "selectCateogry": "Sélectionner la catégorie",
        "sortBy": "Trier par",
        "bestSelling": "Meilleure vente",
        "viewLarger": "Afficher plus grand",
        "viewDetails": "Afficher les détails",
    },
    "productGroupListComponent": {
        "urlTitle": "Produits",
        "title": "Sélectionnez votre catégorie de produits",
        "viewTheseProducts": "Voir ces produits",
    },
    "productDetailTabsComponent": {
        "description": "Description",
        "features": "fonctionnalités",
        "delivery": "Livraison",
        "noDescriptionFound": "Aucune description trouvée",
        "noFeatureFound": "Aucune information de fonctionnalité trouvée",
        "noDeliveryFound": "Aucune information de livraison trouvée",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Erreur d’ajout au panier. Actualisez la page ou signalez ce produit à notre support.",
        "product": "Produit",
        "cancelAndBack": "Annuler et revenir à la liste",
        "selectPhotoOption": "Sélectionner l’option photo",
        "pleaseSelect": "S’il vous plaît sélectionner ...",
        "selectPhotoEnhancement": "Sélectionner photo enahncement",
        "selectColorEffect": "Sélectionner l’effet de couleur",
        "was": "Était",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Sélectionner un dossier ou créer un nouveau",
    },
    "addressFormSectionComponent": {
        "firstName": "Prénom",
        "lastName": "Nom de famille",
        "addressLine1": "Adresse ligne 1",
        "addressLine2": "Adresse ligne 2",
        "city": "Ville",
        "region": "Région",
        "state": "État/Province",
        "postalCode": "Code postal",
        "zipCode": "Code postal",
        "country": "Pays",
    },
    "addToCartFormComponent": {
        "quantity": "Quantité",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Veuillez saisir votre adresse e-mail",
        "email": "Courriel",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Veuillez confirmer votre nouveau mot de passe",
        "password": "Mot de passe",
        "enterPassword": "Entrez votre nouveau mot de passe",
        "confirmPassword": "Confirmer mot de passe",
        "setNewPassword": "Définir un nouveau mot de passe",
    },
    "signupFormComponent": {
        "firstNameValidation": "Veuillez entrer votre prénom",
        "lastNameValidation": "Veuillez entrer votre nom de famille",
        "invalidEmailValidation": "Veuillez saisir un e-mail valide",
        "emailRequiredValidation": "Veuillez entrer une adresse e-mail",
        "confirmPasswordValidation": "Veuillez confirmer votre mot de passe",
        "countryRequiredValidation": "Veuillez sélectionner un pays",
        "passwordsMustMatch": "Les mots de passe doivent correspondre",
        "confirm": "Confirmer",
        "firstName": "Prénom",
        "lastName": "Nom de famille",
        "email": "Adresse de courriel",
        "password": "Mot de passe",
        "confirmPassword": "Confirmer mot de passe",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Veuillez entrer votre prénom",
        "lastNameValidaiton": "Veuillez entrer votre nom de famille",
        "addressLine1Validation": "Veuillez entrer votre adresse de rue",
        "cityValidation": "S’il vous plaît entrer dans votre ville",
        "stateValidation": "Veuillez entrer dans votre état",
        "postalCodeValidation": "Veuillez entrer votre code postal",
        "invalidPostalCode": "Veuillez entrer un code postal valide",
        "countryValidation": "Veuillez entrer dans votre pays",
        "emailValidation": "Veuillez saisir un e-mail valide",
        "billingInformation": "Informations de facturation",
        "selectSavedAddress": "Sélectionner l’adresse enregistrée",
        "email": "Adresse de courriel",
        "phoneNumber": "Numéro de téléphone",
        "saveAsDefault": "Enregistrer les addres de facturation comme mon défaut",
        "saveAsShipping": "Même adresse d’expédition",
        "promotionalTexts": "Pouvons-nous vous envoyer des textes promotionnels?",
        "dataRates": "(Les tarifs des données et des messages peuvent s’appliquer)",
        "promotionalEmails": "Pouvons-nous vous envoyer des courriels promotionnels?",
        "continue": "Continuer",
        "continueShopping": "Continuer à magasiner",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Utilisation de l’adresse sélectionnée",
        "useDefaultAddress": "Utiliser l’adresse par défaut",
        "useClientAddress": "Utiliser l’adresse client",
        "saveAsDefault": "Enregistrer comme valeur par défaut",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Veuillez sélectionner une méthode d’expédition",
    },
    "communicationFormComponent": {
        "permissionCall": "Pouvons-nous vous appeler avec des informations promotionnelles?",
        "permissionEmail": "Pouvons-nous vous envoyer des informations promotionnelles?",
        "permissionText": "Pouvons-nous vous envoyer des informations promotionnelles? (Les débits de messagerie et de données peuvent s’appliquer)",
    },
    "editAddressFormComponent": {
        "name": "Nom",
        "phoneNumber": "Numéro de téléphone",
        "addressLine1": "Adresse ligne 1",
        "addressLine2": "Adresse ligne 2",
        "city": "Ville",
        "region": "Région",
        "postalCode": "Code postal",
        "country": "Pays",
        "deliveryInstructions": "Instructions de livraison",
        "accessCode": "Code d’accès",
        "defaultAddress": "Adresse par défaut",
        "applyChange": "Appliquer la modification",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Veuillez saisir votre mot de passe",
        "passwordCannotBeSame": "Le nouveau mot de passe ne peut pas être le même que l’ancien",
        "confirmPassword": "Veuillez confirmer votre nouveau mot de passe",
        "passwordsMustMatch": "Les mots de passe doivent correspondre",
        "existingPassword": "Mot de passe existant",
        "currentPassword": "Mot de passe actuel",
        "newPassword": "Nouveau mot de passe",
        "confirmNewPassword": "Confirmer le nouveau mot de passe",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Sélectionnez un événement",
        "selectEventDate": "Veuillez sélectionner une date d’événement",
        "enterCode": "S’il vous plaît entrer un code",
    },
    "inputSectionComponent": {
        "selectDate": "Sélectionner une date",
        "placeCodeHere": "Placez le code ici",
        "input": "Entrée",
        "selectEvent": "Sélectionner un événement",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoris",
        "ajaxError": "Une erreur s’est produite en créant une invitation d’invité",
        "invalidEmail": "Veuillez saisir un e-mail valide",
        "noEmail": "S’il vous plaît entrer un e-mail",
        "noFirstName": "Veuillez saisir un prénom",
        "noLastName": "Veuillez entrer un nom de famille",
        "email": "Adresse de courriel",
        "firstName": "Prénom",
        "lastName": "Nom de famille",
        "session": "Session",
        "image": "Image",
        "sendEmail": "Envoyer un e-mail",
        "onlyInclude": "Inclure uniquement favoris de cette",
    },
    "joyFormComponent": {
        "ageMonth": "month (s)",
        "yes": "Oui",
        "no": "No",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Veuillez entrer une description de votre lien",
        "description": "Description",
        "onlyIncludeFavoritesFromThis": "Inclure uniquement favoris de cette",
        "createLink": "Créer un lien",
    },
    "personalInfoComponent": {
        "invalidEmail": "Veuillez entrer une adresse e-mail valide",
        "primaryPhone": "Téléphone principal",
        "secondaryPhone": "Téléphone secondaire",
        "mobilePhone": "Il s’agit d’un téléphone mobile",
        "streetAddress": "Adresse de rue",
        "house": "Maison",
        "apartment": "Appartement",
        "roomNumber": "Numéro de chambre",
        "city": "Ville",
        "region": "Région",
        "state": "État/Province",
        "postalCode": "Code postal",
        "country": "Pays",
        "pleaseSelect": "S’il vous plaît sélectionner ...",
    },
    "familyMemberComponent": {
        "nickname": "Pseudo",
        "familyMember": "Membre de la famille",
        "relationship": "Relation",
        "gender": "Entre les sexes",
        "BirthDate": "Date de naissance",
        "familyMemberSubtitle": "Membres de la famille",
        "tableAction": "Action",
    },
    "usernameFormComponent": {
        "enterUsername": "Veuillez entrer un nouveau nom d’utilisateur",
        "onlineUsername": "Nom d’utilisateur en ligne",
        "username": "Nom d’utilisateur",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Plus d’éléments de navigation",
        "download": "Télécharger",
        "removeFrom": "Retirer de",
        "modifyImage": "Modifier l’image",
        "folders": "Dossiers",
        "makePrimary": "Faire primaire",
        "yearbookImage": "Image de l’annuaire",
        "makeSecondary": "Rendre secondaire",
        "socialShare": "Partage social",
        "unhide": "Afficher",
        "hide": "Masquer",
    },
    "imageTileComponent": {
        "view": "Vue",
        "sessions": "Sessions",
        "setAsPrimary": "Définir comme photo principale",
        "setAsSecondary": "Définir comme photo secondaire",
        "image": "Image",
        "caution": "Attention",
        "separateSessionsMessage1": "Vous n’êtes pas en mesure d’ajouter des images à partir de deux",
        "separateSessionsMessage2": "au même chariot",
        "doNotAdd": "N’ajoutez pas cet article à mon panier.",
        "clearCart": "Effacer le contenu de mon panier, puis ajouter cet article à mon panier.",
    },
    "loginPromptComponent": {
        "title": "Oups!",
        "message": "S’il vous plaît --login--ou --inscrivez-vous-- si vous souhaitez les images préférées groupent des images ensemble ou ajouter des images au panier.",
    },
    "menuDropdownComponent": {
        "dashboard": "Tableau de bord",
        "sessions": "Sessions",
        "favorites": "Favoris",
        "contracts": "Contrats de",
        "orders": "Commandes",
        "prepaidOrders": "Commandes prépayées",
        "guests": "Invités",
        "account": "Compte",
    },
    "userDropdownComponent": {
        "logout": "Déconnecter",
    },
    "navBarPage": {
        "toggleNavigation": "Navigation de bascule",
        "cart": "Panier",
        "photoshootsAndImages": "Photoshoots et images",
        "contracts": "Contrats de",
        "reviewSubmit": "Examiner les contrats et soumettre",
        "forms": "Formes",
        "reviewForms": "Examiner les formulaires Web et soumettre",
        "orders": "Commandes",
        "historyPrepaid": "Historique et commandes prépayées",
        "orderHistory": "Historique des commandes",
        "products": "Produits",
        "guests": "Invités",
        "categoriesProducts": "Catégories et produits",
        "sharedFamily": "Partagé à la famille",
        "friends": "Amis",
        "myAccount": "Mon compte",
        "login": "Ouverture de session",
        "billingInfo": "informations de facturation",
        "help": "Aide",
        "chat": "Chat pour le support en ligne",
        "shoppingCart": "Panier",
        "checkout": "Préparez-vous à la caisse",
        "signout": "Déconnecter",
        "endGuest": "Fin de mon invité",
        "securelySignout": "Permettez-moi de me déconnecter en toute sécurité",
    },
    "productGalleryComponent": {
        "viewLarger": "Afficher plus grand",
    },
    "responsiveHeaderComponent": {
        "photos": "Photos",
        "guests": "Invités",
        "allImages": "Toutes les Images",
        "favorites": "Favoris",
        "folders": "Dossiers",
        "autoPlay": "Autoplay",
        "download": "Télécharger",
        "cart": "Panier",
    },
    "sessionsPage": {
        "Name": "Nom",
        "Date": "Date",
        "CreateNewText": "Créer de nouvelles",
        "ViewText": "Vue",
        "DeleteText": "Supprimer",
        "EditText": "Edit",
    },
}
