export default{

    "AccountPage": {
        "title": "Il mio account",
        "loginSubtitle": "Informazioni per il login",
        "loginText": "Effettuato l'accesso come",
        "editUsername": "Modifica nome utente",
        "changePassword": "Cambia Password",
        "personalInfoSubtitle": "Informazioni personali",
        "familyMemberSubtitle": "Membri della famiglia",
        "edit": "Modifica",
        "delete": "Elimina",
        "default": "Impostazione predefinita",
        "paymentSubtitle": "Pagamento",
        "paymentText": "Aggiornare i dati della carta di credito salvati",
        "communicationSubtitle": "Comunicazione",
        "communicationText": "Per promozioni e consenso esplicito alla newsletter",
        "shippingSubtitle": "Indirizzi di spedizione",
        "shippingText": "Utilizzare per il checkout come informazioni di spedizione",
        "upcomingSubtitle": "Prossimi",
        "upcomingText": "Conferma riprogrammazione o annullamento",
        "noUpcomingText": "Non hai sessioni imminenti",
        "tableDate": "Data",
        "tableTime": "Ore",
        "tableDescription": "Descrizione",
        "tableAction": "Azione",
        "confirm": "Confermare",
        "reschedule": "Ripianificare",
        "cancel": "Annulla",
        "scheduleButton": "Pianifica ora",
        "deleteAddressButton": "Sei sicuro di voler eliminare questo indirizzo?",
        "updateAddressModalTitle": "Indirizzo di aggiornamento",
        "updateUsernameModalTitle": "Aggiorna nome utente",
        "updatePasswordModalTitle": "Cambia Password",
        "updatePersonalInfoModalTitle": "Aggiornare le informazioni personali",
        "updateCommunicationModalTitle": "Aggiornare gli opt-in",
        "updatePaymentModalTitle": "Pagamento",
        "scheduleModalTitle": "Pianificazione",
        "rescheduleModalTitle": "Ripianificare",
        "clickhere": "Clicca qui",
    },
    "ForgotPasswordPage": {
        "title": "Hai dimenticato la Password",
        "subtitle": "Verrà inviata un'e-mail al tuo indirizzo e-mail con ulteriori istruzioni",
        "alertSuccess": "Si dovrebbe ricevere un'e-mail a breve. Si prega di controllare la cartella di posta indesiderata o spam se non riesci a trovare l'e-mail.",
        "backToLogin": "Torna al login",
    },
    "LoginPage": {
        "title": "Accedi per vedere immagini incredibili ispirate da te!",
        "description": "Se hai già un account accedi qui per visualizzare le tue immagini o fai clic su \"Ricerca eventi\" sopra per cercare un evento.",
        "email": "Indirizzo e-mail o nome utente",
        "password": "Password",
        "forgotPass": "Password dimenticata",
        "emailError": "Inserisci un indirizzo email o un nome utente",
        "ajaxError": "Nome utente o password non validi",
        "passwordError": "Inserisci una password",
        "loginButton": "Account di accesso",
    },
    "ResetPasswordPage": {
        "title": "Inserisci la tua nuova password",
        "alertSuccess": "La password è stata reimpostata correttamente. Si verrà reindirizzati alla pagina di accesso.",
        "backToLogin": "Torna al login",
    },
    "SignupPage": {
        "title": "Creare un account",
        "p1": "Hai già un account?",
        "p2": "per visualizzare le foto.",
        "createAccountLink": "Accedi qui",
        "email": "Posta elettronica",
        "password": "Password",
        "signup": "Registrati",
        "ajaxError": "Oops qualcosa è andato storto. Riprovare.",
        "ajaxErrorOther": "Oops qualcosa è andato storto. Riprovare.",
    },
    "ConfirmationPage": {
        "tabTitle": "Grazie",
        "title": "Grazie per aver effettuato l'ordine.",
        "text": "Il numero d'ordine è",
        "relatedProducts": "Potresti essere interessato a...",
    },
    "CheckoutPage": {
        "urlTitle": "Estrazione sicura",
        "title": "Estrazione sicura",
        "subtitle": "Pagina protetta SSL/TSL a 4096 bit",
    },
    "OrderReviewPage": {
        "urlTitle": "Estrazione sicura",
        "tableProductName": "Nome prodotto",
        "tablePrice": "Prezzo",
        "tableQuantity": "Quantità",
        "tableSubtotal": "Totale parziale",
        "enter": "Inseriscilo ora",
        "apply": "Applicare",
        "cancel": "Annulla",
        "promo": "Promo",
        "shipping": "Spedizione",
        "tax": "Tassa di soggiorno",
        "total": "Totale",
    },
    "PaymentPage": {
        "urlTitle": "Estrazione sicura",
    },
    "ShippingAddressPage": {
        "urlTitle": "Estrazione sicura",
    },
    "ContractsPage": {
        "urlTitle": "Contratti",
        "title": "Contratti",
        "invalidPIN": "PIN non valido",
        "uploaded": "Caricato",
        "accepted": "Accettato",
        "printContract": "Stampa contratto",
        "formLabel": "Ho letto e capito i termini di cui sopra. Con la presente sono d'accordo con i termini del presente accordo.",
        "enterPin": "Inserisci il tuo Pin numerico",
        "confirm": "Confermare e inviare",
    },
    "DashboardPage": {
        "loading": "Caricamento...",
        "dashboard": "Cruscotto",
        "title": "Al momento non hai una sessione.",
        "check": "Verifica",
        "pendingContracts": "contratti in sospeso",
        "contactUs": "contattaci",
        "noImages": "Non hai immagini per questo",
    },
    "EventSearchPage": {
        "title": "Cerca eventi condivisi e risate",
        "urlTitle": "Ricerca eventi",
        "p1": "Per trovare un evento, selezionane uno dall'elenco o cercalo in base al codice evento o alla data dell'evento.",
        "date": "Data dell'evento",
        "code": "Codice privato",
        "public": "Evento pubblico",
        "search": "'Cerca'",
    },
    "FavoritesPage": {
        "urlTitle": "Preferito",
    },
    "FoldersPage": {
        "urlTitle": "Cartella",
        "viewFull": "Visualizza completa",
        "noFoldersFound": "Nessuna cartella trovata per questo",
    },
    "FormsPage": {
        "urlTitle": "Forme",
        "title": "Forme",
        "uploaded": "Caricato",
    },
    "GuestsPage": {
        "urlTitle": "Ospiti",
        "title": "Ospiti",
        "error": "Si è verificato un errore durante l'aggiornamento dell'invito guest.",
        "success": "Collegamento inviato con successo",
        "copy": "Copiato negli Appunti",
        "createNew": "Creare un nuovo",
        "invitationLinks": "Link di invito",
        "noLinksFound": "Nessun link trovato per questo",
        "tableDescription": "Descrizione",
        "tableDateCreated": "Data di creazione",
        "tableDateFirstUsed": "Data di prima utilizzo",
        "tableAction": "Azione",
        "copyLink": "Copia collegamento",
        "remove": "Rimuovere",
        "invitedTitle": "Ospiti invitati",
        "noGuestsFound": "Nessun ospite trovato per questo",
        "tableName": "Nome",
        "tableEmail": "Posta elettronica",
        "tableDateInvited": "Data di invito",
        "edit": "Modifica",
        "resend": "Inviare",
        "updateGuest": "Aggiorna ospite",
        "createNewGuestInvitation": "Crea nuovo invito ospite",
        "generateText": "Genera un link condivisibile per consentire ad altri di accedere alle tue immagini. Condividi il link tramite social media un testo o via e-mail.",
        "emailInvite": "Invito e-mail",
        "sharableLink": "Link condivisibile",
    },
    "HelpPage": {
        "urlTitle": "Guida",
        "title": "Guida",
        "ourInfo": "Le nostre informazioni",
        "hereToHelp": "Siamo qui per aiutarti",
        "chatBy": "Chatta con noi",
        "clickingHere": "cliccando qui",
    },
    "HomePage": {
        "signIn": "Accedi",
        "eventSearch": "Ricerca eventi",
        "privacy": "Informativa sulla privacy",
        "userAccessAgreement": "Accordo di accesso utente",
        "termsOfUse": "Condizioni d'uso",
        "close": "Chiudere",
    },
    "OrderDetailsPage": {
        "urlTitle": "Ordine",
        "photo": "Foto",
        "setCrop": "Impostare il ritaglio e le opzioni",
        "cropRequired": "Il ritaglio è necessario prima del checkout",
        "viewOrders": "Visualizzare gli ordini",
        "order": "Ordine",
        "details": "Dettagli",
        "orderDate": "Data dell'ordine",
        "makePayment": "Effettuare il pagamento",
        "printOrder": "Ordine di stampa",
        "orderDetails": "Dettagli dell'ordine",
        "tableProduct": "Prodotto",
        "tablePrice": "Prezzo",
        "tableQty": "Q.tà",
        "tableTotal": "Totale",
        "subtotal": "Totale parziale",
        "promo": "Promo",
        "shipping": "Spedizione",
        "tax": "Tassa di soggiorno",
        "payment": "Pagamento",
        "total": "Totale",
        "finalize": "Finalizza selezione",
        "shippingInfo": "Info spedizioni",
        "delivery": "Consegna",
        "confirm": "Conferma invio",
        "cancel": "Annulla",
        "warning": "Questa azione non può essere annullata nell'app.",
        "submitting": "Presentazione...",
        "yesConfirm": "Sì conferma",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Numero d'ordine",
        "tableOrderDate": "Data dell'ordine",
        "tableDateCreated": "Data di creazione",
        "tableShippedTo": "Spedito a",
        "tableStatus": "Stato",
        "tableDescription": "Descrizione",
        "tableOpenBalance": "Saldo aperto",
        "tableTotal": "Totale",
        "orders": "Ordini",
        "orderHistory": "Cronologia degli ordini",
        "preOrders": "Pre-ordini",
        "noOrders": "Al momento non hai ancora alcun ordine.",
    },
    "SharePage": {
        "urlTitle": "Questa è la pagina del titolo",
        "title": "Questa è la pagina del titolo",
        "iframe": "Questa è una pagina vuota di iframe.",
        "shareImage": "Condividi questa immagine",
    },
    "SplashPage": {
        "wereSorry": "Siamo spiacenti.",
        "doesntSeemTo": "non sembra essere un token di condivisione valido.",
        "error": "Ci dispiace che sembra che qualcosa sia andato storto. Se l'amministratore del sito continua, informa l'amministratore del sito.",
        "subdomainError": "Questa applicazione si aspetta un sottodominio. Controlla l'URL e assicurati che abbia l'aspetto di <a0>Nome della tua azienda>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Aggiungi alla cartella",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Condivisione social network",
        "generatingShareable": "Generazione di URL condividibili in corso...",
    },
    "cartViewComponent": {
        "urlTitle": "Carrello",
        "title": "Carrello",
        "photo": "Foto",
        "swap": "Swap",
        "youHave": "Hai",
        "youHaveAdded": "Hai aggiunto",
        "unitsRemaining": "unità rimanenti da aggiungere all'ordine.",
        "unitsTooMany": "troppe unità al vostro ordine.",
        "cartFullModalTitle": "Troppe unità",
        "tooMany": "Questo ordine contiene troppe unità.  Si prega di rimuovere alcuni prodotti prima del check-out.",
        "have": "Avere",
        "another": "un altro",
        "a": "Un",
        "ok": "Ok",
        "promoCode": "codice promozionale/coupon?",
        "promoCode1": "Codice promo",
        "removePromoCode": "Rimuovere il codice promozionale",
        "select": "Selezionare",
        "loading": "Caricamento",
        "setCropAndOptions": "Impostare il ritaglio e le opzioni",
        "cropRequired": "Il ritaglio è necessario prima del checkout",
        "checkout": "Controlla",
        "tableAction": "Azione",
        "tableProductName": "Nome prodotto",
        "tablePrice": "Prezzo",
        "tableQuantity": "Quantità",
        "tableSubtotal": "Totale parziale",
        "enterNow": "Inseriscilo ora",
        "apply": "Applicare",
        "cancel": "Annulla",
        "promo": "Promo",
        "noItems": "Nessun articoli nel carrello.",
        "checkOurProducts": "Controlla i nostri prodotti",
        "continueShopping": "Continua a fare acquisti",
    },
    "createOrderIndexComponent": {
        "title": "Creazione di un ordine",
        "step1cta": "Procedere",
        "finish": "Finitura",
    },
    "cropViewComponent": {
        "cropImage": "Ritaglia immagine",
        "prepaid": "Prepagata",
        "shoppingCart": "Carrello",
        "image": "Immagine",
        "zoomIn": "Ingrandisci",
        "zoomOut": "Riduci zoom",
        "reset": "Reset",
        "cropOrientation": "Orientamento ritaglio",
        "selectPhotoOption": "Selezionare l'opzione seleziona foto",
        "pleaseSelect": "Si prega di selezionare...",
        "selectPhotoEnhancement": "Selezionare il miglioramento delle foto",
        "selectColorEffect": "Selezionare l'effetto colore",
        "notes": "Note",
        "apply": "Applicare",
        "backToPreorder": "Torna al preordine",
        "backToShoppingCart": "Torna al carrello",
    },
    "photoSelectComponent": {
        "swapPhoto": "Scambia foto",
        "prepaid": "Prepagata",
        "shoppingCart": "Carrello",
        "backToPreorder": "Torna al preordine",
        "backToShoppingCart": "Torna al carrello",
    },
    "photoTileComponent": {
        "image": "Immagine",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Categoria",
        "title": "Categoria",
        "selectYourProduct": "Seleziona il tuo prodotto",
        "selectCateogry": "Seleziona categoria",
        "sortBy": "Ordina per",
        "bestSelling": "I migliori da vendere",
        "viewLarger": "Vista più grande",
        "viewDetails": "Visualizzare i dettagli",
    },
    "productGroupListComponent": {
        "urlTitle": "Prodotti",
        "title": "Seleziona la tua categoria di prodotto",
        "viewTheseProducts": "Visualizza questi prodotti",
    },
    "productDetailTabsComponent": {
        "description": "Descrizione",
        "features": "Caratteristiche",
        "delivery": "Consegna",
        "noDescriptionFound": "Nessuna descrizione trovata",
        "noFeatureFound": "Nessuna informazione sulle funzionalità trovata",
        "noDeliveryFound": "Nessuna informazione di consegna trovata",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Errore durante l'aggiunta al carrello. Aggiorna la pagina o segnala questo prodotto al nostro supporto.",
        "product": "Prodotto",
        "cancelAndBack": "Annullare e tornare alla presentazione",
        "selectPhotoOption": "Selezionare l'opzione seleziona foto",
        "pleaseSelect": "Si prega di selezionare...",
        "selectPhotoEnhancement": "Seleziona l'enahncement fotografico",
        "selectColorEffect": "Selezionare l'effetto colore",
        "was": "Era",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Selezionare una cartella o crearne di nuove",
    },
    "addressFormSectionComponent": {
        "firstName": "Primo nome",
        "lastName": "Cognome",
        "addressLine1": "Riga indirizzo 1",
        "addressLine2": "Riga 2 indirizzo",
        "city": "Città",
        "region": "Regione",
        "state": "Stato",
        "postalCode": "Codice postale",
        "zipCode": "CAP",
        "country": "Paese",
    },
    "addToCartFormComponent": {
        "quantity": "Quantità",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Inserisci il tuo indirizzo email",
        "email": "Posta elettronica",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Conferma la nuova password",
        "password": "Password",
        "enterPassword": "Inserisci la tua nuova password",
        "confirmPassword": "Conferma Password",
        "setNewPassword": "Imposta nuova password",
    },
    "signupFormComponent": {
        "firstNameValidation": "Inserisci il tuo nome",
        "lastNameValidation": "Inserisci il tuo cognome",
        "invalidEmailValidation": "Inserisci un indirizzo email valido",
        "emailRequiredValidation": "Inserisci un indirizzo email",
        "confirmPasswordValidation": "Confermare la password",
        "countryRequiredValidation": "Si prega di selezionare un paese",
        "passwordsMustMatch": "Le password devono corrispondere",
        "confirm": "Confermare",
        "firstName": "Primo nome",
        "lastName": "Cognome",
        "email": "Indirizzo di posta elettronica",
        "password": "Password",
        "confirmPassword": "Conferma Password",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Inserisci il tuo nome",
        "lastNameValidaiton": "Inserisci il tuo cognome",
        "addressLine1Validation": "Inserisci il tuo indirizzo",
        "cityValidation": "Inserisci la tua città",
        "stateValidation": "Inserisci il tuo stato",
        "postalCodeValidation": "Inserisci il tuo codice postale",
        "invalidPostalCode": "Inserisci un codice postale valido",
        "countryValidation": "Inserisci il tuo paese",
        "emailValidation": "Inserisci un indirizzo email valido",
        "billingInformation": "Informazioni di fatturazione",
        "selectSavedAddress": "Selezionare l'indirizzo salvato",
        "email": "Indirizzo di posta elettronica",
        "phoneNumber": "Numero di telefono",
        "saveAsDefault": "Salva l'impostazione predefinita di billing addres",
        "saveAsShipping": "Uguale all'indirizzo di spedizione",
        "promotionalTexts": "Possiamo inviarti messaggi promozionali?",
        "dataRates": "(Possono essere applicate le frequenze dei dati e dei messaggi)",
        "promotionalEmails": "Possiamo inviarti e-mail promozionali?",
        "continue": "Continuare",
        "continueShopping": "Continua a fare acquisti",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Utilizzo dell'indirizzo selezionato",
        "useDefaultAddress": "Usa indirizzo predefinito",
        "useClientAddress": "Usa indirizzo client",
        "saveAsDefault": "Salva come predefinito",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Si prega di selezionare un metodo di spedizione",
    },
    "communicationFormComponent": {
        "permissionCall": "Possiamo chiamarti con informazioni promozionali?",
        "permissionEmail": "Possiamo inviarti un'email con informazioni promozionali?",
        "permissionText": "Possiamo inviarti un SMS con informazioni promozionali? (Potrebbero essere applicate le tariffe di messaggistica e dati)",
    },
    "editAddressFormComponent": {
        "name": "Nome",
        "phoneNumber": "Numero di telefono",
        "addressLine1": "Riga indirizzo 1",
        "addressLine2": "Riga 2 indirizzo",
        "city": "Città",
        "region": "Regione",
        "postalCode": "Codice postale",
        "country": "Paese",
        "deliveryInstructions": "Istruzioni per la consegna",
        "accessCode": "Codice di accesso",
        "defaultAddress": "Indirizzo predefinito",
        "applyChange": "Applica modifica",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Inserisci la tua password",
        "passwordCannotBeSame": "La nuova password non può essere uguale a quella precedente",
        "confirmPassword": "Conferma la nuova password",
        "passwordsMustMatch": "Le password devono corrispondere",
        "existingPassword": "Password esistente",
        "currentPassword": "Password corrente",
        "newPassword": "Nuova Password",
        "confirmNewPassword": "Conferma nuova Password",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Seleziona un evento",
        "selectEventDate": "Seleziona una data dell'evento",
        "enterCode": "Inserisci un codice",
    },
    "inputSectionComponent": {
        "selectDate": "Selezionare una data",
        "placeCodeHere": "Inserire il codice qui",
        "input": "Input",
        "selectEvent": "Selezionare un evento",
    },
    "emailGuestFormComponent": {
        "favorites": "Preferiti",
        "ajaxError": "Si è verificato un errore durante la creazione dell'invito guest",
        "invalidEmail": "Inserisci un indirizzo email valido",
        "noEmail": "Si prega di inserire una e-mail",
        "noFirstName": "Inserisci un nome",
        "noLastName": "Inserisci un cognome",
        "email": "Indirizzo di posta elettronica",
        "firstName": "Primo nome",
        "lastName": "Cognome",
        "session": "Sessione",
        "image": "Immagine",
        "sendEmail": "Invia e-mail",
        "onlyInclude": "Includi solo Preferiti da questo",
    },
    "joyFormComponent": {
        "ageMonth": "mesi",
        "yes": "Sì",
        "no": "No",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Inserisci una descrizione per il tuo link",
        "description": "Descrizione",
        "onlyIncludeFavoritesFromThis": "Includi solo Preferiti da questo",
        "createLink": "Crea collegamento",
    },
    "personalInfoComponent": {
        "invalidEmail": "Inserisci un indirizzo email valido",
        "primaryPhone": "Telefono primario",
        "secondaryPhone": "Telefono secondario",
        "mobilePhone": "Questo è un telefono cellulare",
        "streetAddress": "Indirizzo",
        "house": "Casa",
        "apartment": "Appartamento",
        "roomNumber": "Numero stanza",
        "city": "Città",
        "region": "Regione",
        "state": "Stato",
        "postalCode": "Codice postale",
        "country": "Paese",
        "pleaseSelect": "Si prega di selezionare...",
    },
    "familyMemberComponent": {
        "nickname": "Nickname",
        "familyMember": "Membro della famiglia",
        "relationship": "Relazione",
        "gender": "Genere",
        "BirthDate": "Data di nascita",
        "familyMemberSubtitle": "Membri della famiglia",
        "tableAction": "Azione",
    },
    "usernameFormComponent": {
        "enterUsername": "Inserisci un nuovo nome utente",
        "onlineUsername": "Nome utente online",
        "username": "Nome utente",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Altri elementi di spostamento",
        "download": "Scaricare",
        "removeFrom": "Rimuovi da",
        "modifyImage": "Modifica immagine",
        "folders": "Cartelle",
        "makePrimary": "Rendi principale",
        "yearbookImage": "Annuario immagine",
        "makeSecondary": "Rendi secondario",
        "socialShare": "Condivisione social network",
        "unhide": "Mostrare",
        "hide": "Nascondi",
    },
    "imageTileComponent": {
        "view": "Vista",
        "sessions": "Sessioni",
        "setAsPrimary": "Imposta come foto principale",
        "setAsSecondary": "Imposta come foto secondaria",
        "image": "Immagine",
        "caution": "Attenzione",
        "separateSessionsMessage1": "Non è possibile aggiungere immagini da due",
        "separateSessionsMessage2": "allo stesso carrello",
        "doNotAdd": "Non aggiungere questo articolo al mio carrello.",
        "clearCart": "Cancella il contenuto del carrello e aggiungi questo articolo al mio carrello.",
    },
    "loginPromptComponent": {
        "title": "Oops!",
        "message": "Si prega di -login-- o --sign up-- se si desidera immagini preferite raggruppare le immagini o aggiungere immagini al carrello.",
    },
    "menuDropdownComponent": {
        "dashboard": "Cruscotto",
        "sessions": "Sessioni",
        "favorites": "Preferiti",
        "contracts": "Contratti",
        "orders": "Ordini",
        "prepaidOrders": "Ordini prepagati",
        "guests": "Ospiti",
        "account": "Conto",
    },
    "userDropdownComponent": {
        "logout": "Esci",
    },
    "navBarPage": {
        "toggleNavigation": "Attiva/disattiva navigazione",
        "cart": "Carrello",
        "photoshootsAndImages": "Servizi fotografici e immagini",
        "contracts": "Contratti",
        "reviewSubmit": "Rivedere i contratti e inviare",
        "forms": "Forme",
        "reviewForms": "Esaminare i moduli Web e inviarli",
        "orders": "Ordini",
        "historyPrepaid": "Storico e ordini prepagati",
        "orderHistory": "Cronologia degli ordini",
        "products": "Prodotti",
        "guests": "Ospiti",
        "categoriesProducts": "Categorie e prodotti",
        "sharedFamily": "Condiviso con la famiglia",
        "friends": "amici",
        "myAccount": "Il mio account",
        "login": "Account di accesso",
        "billingInfo": "informazioni di fatturazione",
        "help": "Guida",
        "chat": "Chatta per il supporto online",
        "shoppingCart": "Carrello",
        "checkout": "Preparati al checkout",
        "signout": "Esci",
        "endGuest": "Termina il mio ospite",
        "securelySignout": "Consenti la disconnessione in modo sicuro",
    },
    "productGalleryComponent": {
        "viewLarger": "Vista più grande",
    },
    "responsiveHeaderComponent": {
        "photos": "Foto",
        "guests": "Ospiti",
        "allImages": "Tutte le immagini",
        "favorites": "Preferiti",
        "folders": "Cartelle",
        "autoPlay": "Autoplay",
        "download": "Scaricare",
        "cart": "Carrello",
    },
    "sessionsPage": {
        "Name": "Nome",
        "Date": "Data",
        "CreateNewText": "Creare un nuovo",
        "ViewText": "Vista",
        "DeleteText": "Elimina",
        "EditText": "Modifica",
    },
}
