import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Row,
    FormFeedback,
    Alert
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { password } from '../../util';



const ResetPasswordForm: React.FC<any> = ({ authStore, token, onSuccess}) => {
    const { t } = useTranslation();
    const [asyncErr, setAsyncErr] = useState('');

    function resetPassword(obj: any) {
        authStore.resetPassword(token,obj.password).then(()=>{
            setAsyncErr('')
            onSuccess();
        }).catch((err:AxiosError)=>setAsyncErr(err.message))
    }
    
    const validationSchema =
        Yup.object().shape({
            password:password(),
            confirmPassword: Yup.string()
                .required(t('resetPasswordFormComponent.passwordValidation')),
        })

    const initialValues = {
        password:'',
        confirmPassword:'',
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={resetPassword}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue
                /* and other goodies */
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="row-sm">
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('resetPasswordFormComponent.password')}</Label>
                                <Field
                                    placeholder={t('resetPasswordFormComponent.enterPassword')}
                                    type = "password"
                                    name="password"
                                    className = {`form-control ${errors.password?'is-invalid':''}`}
                                />
                                <ErrorMessage name = "password">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('resetPasswordFormComponent.confirmPassword')}</Label>
                                <Field
                                    placeholder={t('resetPasswordFormComponent.confirmPassword')}
                                    type = "password"
                                    name="confirmPassword"
                                    className = {`form-control ${errors.confirmPassword?'is-invalid':''}`}
                                />
                                <ErrorMessage name = "confirmPassword">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                        </Row>
                        {asyncErr &&
                            <Alert color = "danger">{asyncErr}</Alert>
                        }
                        <Button type="submit" color="primary" block >{t('resetPasswordFormComponent.setNewPassword')}</Button>
                    </Form>
                )}
        </Formik>
    );

}
export default inject('authStore', 'navigationStore')(observer(ResetPasswordForm))
