import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import SessionsForm from "../../components/sessionsForm/sessionsForm";
import SessionsEditForm from "../../components/sessionsForm/sessionsEditForm";
import { AiOutlineForm, AiOutlineDelete } from "react-icons/ai";
import {
  Card,
  CardText,
  CardBody,
  Row,
  Col,
  Spinner,
  Button,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

import { FiImage } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const SessionsList: React.FC<any> = ({
  sessionStore,
  authStore,
  history,
  clientStore,
  imageGroupStore,
  imageToView,
  navigationStore,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setisSubmitting] = useState(false);
  const [modalSessionForm, setModalSessionForm] = useState(false);
  const [deleteSessionIndex, setDeleteSessionIndex] = useState<any>();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDelete2ndConfirm, setmodalDelete2ndConfirm] = useState(false);
  const [editSessionIndex, setEditSessionIndex] = useState<any>();
  const [modalEditSession, setmodalEditSession] = useState(false);

  useEffect(() => {
    if (!authStore.allowNewSessions) {
      history.push("/sessions");
    }
  }, []);

  const toggleSessionFormSuccess = async (e: any) => {
    matchSessionwithSessionDetails();
    setModalSessionForm(false);
    history.push(
      `/sessions?sessionKey=${await sessionStore.allSessions[0].SessionKey}`
    );
  };

  const closeSessionFormModal = (e: any) => {
    setModalSessionForm(false);
    navigationStore.push("/sessions-list");
    e.preventDefault();
  };

  const toggleSessionForm = (e: any) => {
    setModalSessionForm(!modalSessionForm);
    e.preventDefault();
  };

  useEffect(() => {
    if (!modalSessionForm) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [modalSessionForm]);

  function toggleDelete(e: any) {
    setModalDelete(!modalDelete);
    e.preventDefault();
    e.stopPropagation();
  }

  function toggleDelete2(e: any) {
    setmodalDelete2ndConfirm(!modalDelete2ndConfirm);
    e.preventDefault();
    e.stopPropagation();
  }

  function showDeleteConfirm(index: number) {
    setDeleteSessionIndex(index);

    setModalDelete(true);
  }

  function confirmAgain() {
    setModalDelete(false);
    setmodalDelete2ndConfirm(true);
  }

  async function deleteSession(key: string) {
    setisSubmitting(true);
    await sessionStore.deleteSession(key);
    await clientStore.getClientInfo();
    await clientStore.getClientSessionInfo();
    matchSessionwithSessionDetails();
    setisSubmitting(false);
    setModalDelete(false);
    setmodalDelete2ndConfirm(false);
  }

  function selectSessions(index: number) {
    setEditSessionIndex(index);
    setmodalEditSession(true);
  }

  const closeSessionEditModal = (e: any) => {
    setmodalEditSession(false);
    e.preventDefault();
  };

  const toggleSessionEditSuccess = (e: any) => {
    setmodalEditSession(false);
  };

  const matchSessionwithSessionDetails = async () => {
    sessionStore.allSessions = sessionStore.allSessions
      ?.sort((a: any, b: any) => a.SessionKey.localeCompare(b.SessionKey))
      .reverse();
  };

  useEffect(() => {
    matchSessionwithSessionDetails();
    console.log("Order of Album in All Session", sessionStore.allSessions);
  }, []);

  useEffect(() => {
    if (!modalSessionForm) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [modalSessionForm]);

  return (
    <>
      <section className="page-section page-section-2column">
        <div className="page-section-main section-mr-0">
          <h1 className="mb-3">{authStore.jobPlural}</h1>

          <Row className="ion-no-margin ion-padding">
            <Col md="12" className="mb-5 album-list-col">
              {clientStore?.clientInfo?.SessionCount > 0 && (
                <p className="mb-3">
                  {t("sessionsPage.SessionSubTitle")} {authStore.jobPlural}
                </p>
              )}

              {clientStore?.clientInfo?.SessionCount === 0 && (
                <p className="mb-3">
                  {t("sessionsPage.NoSessionText")} {authStore.jobPlural}
                </p>
              )}
              {authStore.allowNewSessions &&
                !(clientStore.statusDescription === "Free") && (
                  <>
                    <>
                      {authStore.allowNewSessions &&
                        !(clientStore.statusDescription === "Free") && (
                          <Button
                            color="primary"
                            size="small"
                            className="custom-button-text white-text mb-5"
                            onClick={toggleSessionForm}
                          >
                            <FiImage size="18" className="mr-2" />
                            {t("sessionsPage.CreateNewText")}{" "}
                            {authStore.jobSingular}
                          </Button>
                        )}
                    </>
                  </>
                )}

              <>
                <Row>
                  {sessionStore.allSessions?.map(
                    (albumItem: any, i: number) => (
                      <Col
                        md="6"
                        sm="6"
                        lg="6"
                        xs="12"
                        key={albumItem.SessionKey}
                        className="mb-4 album-list"
                      >
                        <Card className="ion-no-margin family-members ion-no-padding mt-3">
                          <Row>
                            <Col md="6" sm="6" lg="6" xs="6">
                              <Button
                                color="primary"
                                size="sm"
                                className="small-button btn-sm"
                                onClick={() =>
                                  navigationStore.push(
                                    `/sessions?sessionKey=${albumItem.SessionKey}`
                                  )
                                }
                              >
                                <FiImage size="18" className="mr-2" />
                                {t("sessionsPage.ViewText")}{" "}
                                {authStore.jobSingular}
                              </Button>
                            </Col>
                            {!albumItem?.IsGuest &&
                            authStore.accountName.toLowerCase() ===
                              "annegeddes" ? (
                              <Col md="6" sm="6" lg="6" xs="6" className="mb-2">
                                <div className="d-flex align-center justify-content-end pt-3 pb-0 pr-3">
                                  <Button
                                    color="icon-action"
                                    className="corner-button top-align"
                                    onClick={() => selectSessions(i)}
                                  >
                                    <AiOutlineForm
                                      size="25"
                                      className="text-primary font-weight-bold"
                                    />
                                  </Button>

                                  <>
                                    <div>
                                      <Button
                                        color="icon-action"
                                        className="corner-button align-button-delete"
                                        onClick={() => {
                                          showDeleteConfirm(i);
                                        }}
                                      >
                                        <AiOutlineDelete
                                          size="25"
                                          className="text-danger font-weight-bold"
                                        />
                                      </Button>
                                    </div>
                                  </>
                                </div>
                              </Col>
                            ) : (
                              <Col md="6" sm="6" lg="6" xs="6">
                                <div className="d-flex align-center justify-content-end pt-3 pb-0 pr-3 min-height-align">
                                  <strong>
                                    {" "}
                                    {t("sessionsPage.SharedText")}{" "}
                                    {authStore.jobSingular}
                                  </strong>
                                </div>
                              </Col>
                            )}
                          </Row>

                          <CardBody className="primary">
                            <Row>
                              <Col md="6" sm="6" lg="6" xs="6">
                                <CardText className="title primary">
                                  {authStore.jobSingular}{" "}
                                  {t("sessionsPage.Name")}
                                </CardText>
                              </Col>
                              <Col md="6" sm="6" lg="6" xs="6">
                                <CardText className="details">
                                  {albumItem.SessionDescription}
                                </CardText>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6" sm="6" lg="6" xs="6">
                                <CardText className="title">
                                  {authStore.jobSingular}{" "}
                                  {t("sessionsPage.Date")}
                                </CardText>
                              </Col>
                              <Col md="6" sm="6" lg="6" xs="6">
                                <CardText className="details">
                                  {albumItem.SessionDate}
                                </CardText>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
              </>
            </Col>
          </Row>
        </div>
      </section>

      <Modal
        isOpen={modalSessionForm}
        toggle={toggleSessionForm}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader>Create New Album</ModalHeader>

        <ModalBody>
          <SessionsForm onSuccess={toggleSessionFormSuccess} />

          <Row className="mb-2 mt-4 align-items-center">
            <Col className="col-12 mb-3 mb-sm-0">
              <Button
                color="secondary text-truncate"
                className="w-100"
                onClick={closeSessionFormModal}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/*  Edit Album Modal */}

      <Modal
        isOpen={modalEditSession}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={closeSessionEditModal}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("sessionsPage.EditText")} {authStore.jobSingular}
        </ModalHeader>

        <ModalBody className="landscape-padding family-members-popup">
          <SessionsEditForm
            editSessionCurrentIndex={editSessionIndex}
            onSuccess={toggleSessionEditSuccess}
          />
          {clientStore.familyMembers?.length > 0 && (
            <Row className="mb-2 mt-4 align-items-center">
              <Col className="col-12 mb-3 mb-sm-0">
                <Button
                  size="large"
                  expand="block"
                  onClick={closeSessionEditModal}
                  color="secondary"
                >
                  Close
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      {/* Album Delete Ion Modal */}

      <Modal
        isOpen={modalDelete}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleDelete}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleDelete}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("sessionsPage.DeleteText")} {authStore.jobSingular}
        </ModalHeader>

        <ModalBody className="landscape-padding">
          <h4 className="image-delete-confirm">
            Are you sure you want to delete this album?{" "}
            <span>Note that ALL images will also be deleted.</span>
          </h4>

          <Button
            color="danger"
            expand="block"
            size="large"
            onClick={() => confirmAgain()}
          >
            <>
              {t("sessionsPage.DeleteText")} {authStore.jobSingular}
            </>

            {isSubmitting && (
              <span className="loader ml-3">
                <Spinner color="#fff" size="sm" />{" "}
              </span>
            )}
          </Button>
        </ModalBody>
      </Modal>

      {/* Album Delete Ion Modal and Confirmation */}

      <Modal
        isOpen={modalDelete2ndConfirm}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
      >
        <ModalHeader
          toggle={toggleDelete2}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleDelete2}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("sessionsPage.DeleteText")} {authStore.jobSingular}
        </ModalHeader>

        <ModalBody className="landscape-padding">
          <h4>Are you sure you want to permanently delete all images?</h4>

          <Button
            color="danger"
            expand="block"
            size="large"
            onClick={() =>
              deleteSession(
                sessionStore.allSessionList?.[deleteSessionIndex]?.SessionKey
              )
            }
          >
            {t("sessionsPage.ConfirmDelete")} {authStore.jobSingular}
            {isSubmitting && (
              <span className="loader ml-3">
                <Spinner color="#fff" size="sm" />{" "}
              </span>
            )}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default inject(
  "authStore",
  "sessionStore",
  "clientStore",
  "imageGroupStore",
  "navigationStore"
)(observer(SessionsList));
