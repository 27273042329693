import React from 'react';
import { inject, observer } from 'mobx-react';


const RelatedProducts: React.FC<any> = ({ ecomStore, createOrderStore, onSelect=()=>null, title = 'Related Products' }) => {
function selectProduct(groupKey:string, productKey:string){
    ecomStore.selectProductGroup(groupKey);
    ecomStore.selectProduct(productKey);
    createOrderStore.setStepNumber(3);
    createOrderStore.setSlider(true);
    onSelect();
}

    return (
        <>
            {!!ecomStore?.relatedProducts?.length &&
                <>
                    <h3 className="subtitle mt-5">{title}</h3>
                    <div className="row">
                        {ecomStore.relatedProducts?.map((p: any) => (
                            <div 
                                key={p.Key} 
                                className="col-6 col-md-4" 
                                style = {{cursor:'pointer'}} 
                                onClick = {()=>selectProduct(p.groupKey, p.Key)}
                            >
                                <div className="card card-product-grid">
                                    <img className="card-image img-fluid" src={`${p.ProductImages?.[0].ThumbUrl}`} alt="" />
                                    <div className="card-body">
                                        <h4>{p.Name}</h4>
                                        <p className="price">{p.PriceStringFormatted}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </>
            }
        </>
    )
}
export default inject('ecomStore', 'createOrderStore')(observer(RelatedProducts))