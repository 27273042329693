import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormGroup, Button, Row, Alert, Spinner } from "reactstrap";

import { observer, inject } from "mobx-react";
import Select from "react-select";
import {
  FamilyMemberRolesPersonalInfo,
  familyMemberRolesDropdown,
} from "../../util";
import { AxiosError } from "axios";
import InputFloat from "../inputFloat";
import { useTranslation } from "react-i18next";

const PersonalInfoForm: React.FC<any> = ({
  authStore,
  clientStore,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");
  const [otherFamilyMemberRole, setOtherFamilyMemberRole] = useState(false);
  async function updateClient(obj: any) {
    if (obj.CustomTextField01 === "Other") {
      obj.CustomTextField01 = obj.CustomTextField02;
    }
    await clientStore
      .updateClient(obj)
      .catch((err: AxiosError) => setAsyncErr(err.message));
    onSuccess(console.log("My account updated"));
  }

  useEffect(() => {
    if (initialValues.CustomTextField01 === "Other") {
      setOtherFamilyMemberRole(true);
    }
  }, []);

  const validationSchema =
    authStore?.accountName.toLowerCase() === "annegeddes"
      ? Yup.object().shape({
          FirstName: Yup.string()
            .matches(
              /^[a-zA-Z ]*$/,
              "No special characters or numbers allowed."
            )
            .required("Please fill this field"),
          LastName: Yup.string()
            .matches(
              /^[a-zA-Z ]*$/,
              "No special characters or numbers allowed."
            )
            .required("Please fill this field"),
          EmailAddress: Yup.string()
            .email(t("personalInfoComponent.invalidEmail"))
            .required("Please enter an email"),
          CustomTextField01: Yup.string().required("Please fill this field"),
          CustomTextField02: Yup.string()
            .matches(
              /^[a-zA-Z ]*$/,
              "No special characters or numbers allowed."
            )
            .when("CustomTextField01", {
              is: (value) => value === "Other",
              then: Yup.string().required("Please fill this field"),
            }),
        })
      : Yup.object().shape({
          FirstName: Yup.string()
            .matches(
              /^[a-zA-Z ]*$/,
              "No special characters or numbers allowed."
            )
            .required("Please fill this field"),
          LastName: Yup.string()
            .matches(
              /^[a-zA-Z ]*$/,
              "No special characters or numbers allowed."
            )
            .required("Please fill this field"),
          EmailAddress: Yup.string()
            .email(t("personalInfoComponent.invalidEmail"))
            .required("Please enter an email"),
        });
  const initialValues =
    authStore?.accountName.toLowerCase() === "annegeddes"
      ? {
          FirstName: clientStore.clientInfo.FirstName || "",
          LastName: clientStore.clientInfo.LastName || "",
          EmailAddress: clientStore.clientInfo.EmailAddress || "",
          CustomTextField01: clientStore.clientInfo.CustomTextField01 || "",
          CustomTextField02: clientStore.clientInfo.CustomTextField02 || "",
        }
      : {
          FirstName: clientStore.clientInfo.FirstName || "",
          LastName: clientStore.clientInfo.LastName || "",
          EmailAddress: clientStore.clientInfo.EmailAddress || "",
        };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-6">
              <Field
                label={t("emailGuestFormComponent.firstName")}
                name="FirstName"
                className="form-control"
                id="FirstName"
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.FirstName && touched.FirstName ? (
                <div className="invalid-feedback">{errors.FirstName}</div>
              ) : null}{" "}
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Field
                label={t("emailGuestFormComponent.lastName")}
                name="LastName"
                className="form-control"
                id="LastName"
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.LastName && touched.LastName ? (
                <div className="invalid-feedback">{errors.LastName}</div>
              ) : null}{" "}
            </FormGroup>
            <FormGroup className="col-12">
              <Field
                type="email"
                label={t("emailGuestFormComponent.email")}
                name="EmailAddress"
                className={`form-control ${
                  errors.EmailAddress ? "is-invalid" : ""
                }`}
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.EmailAddress && touched.EmailAddress ? (
                <div className="invalid-feedback">{errors.EmailAddress}</div>
              ) : null}{" "}
            </FormGroup>

            {authStore?.accountName.toLowerCase() === "annegeddes" && (
              <>
                <FormGroup className="col-sm-6">
                  <label className="label">
                    {t("familyMemberComponent.familyMember")}
                  </label>
                  <div className="select family-members-dropdown">
                    <Select
                      defaultValue={familyMemberRolesDropdown.find(
                        (opt: any) => opt.value === values.CustomTextField01
                      )}
                      options={familyMemberRolesDropdown}
                      onChange={(e: any) => {
                        if (e?.value) {
                          setFieldValue("CustomTextField01", e.value);
                        }
                        console.log(e?.value);
                        if (e?.value === FamilyMemberRolesPersonalInfo.Other) {
                          setOtherFamilyMemberRole(true);
                        } else {
                          setOtherFamilyMemberRole(false);
                        }
                      }}
                      className="react-select-group-container-simple"
                      classNamePrefix="react-select-group"
                      placeholder={t("familyMemberComponent.familyMember")}
                      isSearchable={false}
                    />
                  </div>
                  {errors.CustomTextField01 && touched.CustomTextField01 ? (
                    <div className="invalid-feedback">
                      {errors.CustomTextField01}
                    </div>
                  ) : null}{" "}
                </FormGroup>
                <FormGroup className="col-sm-6">
                  {otherFamilyMemberRole && (
                    <FormGroup className="col-sm-12 p-0 mt-0">
                      <Field
                        label="Other"
                        name="CustomTextField02"
                        className="form-control"
                        id="CustomTextField02"
                        component={InputFloat}
                        disabled={isSubmitting}
                      />
                      {errors.CustomTextField02 && touched.CustomTextField02 ? (
                        <div className="invalid-feedback">
                          {errors.CustomTextField02}
                        </div>
                      ) : null}{" "}
                    </FormGroup>
                  )}
                </FormGroup>
              </>
            )}
          </Row>
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {t("editAddressFormComponent.applyChange")}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject("clientStore", "authStore")(observer(PersonalInfoForm));
