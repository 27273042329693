import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { Spinner } from "reactstrap";

import ImageTileList from "../../components/imageTileList/imageTileList";
import ResponsiveHeader from "../../components/responsiveHeader/responsiveHeader";
import SessionHome from "./sessionHome";
import { Link, useLocation } from "react-router-dom";
import { Spinner, Button } from "reactstrap";
import { FiArrowLeft, FiRefreshCcw } from "react-icons/fi";

const SessionsPage: React.FC<any> = ({
  authStore,
  sessionStore,
  ecomStore,
  createOrderStore,
  clientStore,
  navigationStore,
}) => {
  const { t } = useTranslation();
  let imageContent;

  useEffect(() => {
    setReload(true);

    setTimeout(() => {
      setReload(false);
    }, 50);
  }, [sessionStore.activeSessionImages]);

  useEffect(() => {
    //Check For ClientKey After Idle State
    if (!clientStore.clientKey && !authStore.token) {
      authStore.logout();
      navigationStore.push("/home/login");
    }
  }, []);

  const query = useQuery();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let Session_Key = query.get("sessionKey") || sessionStore.activeSession.Key;
  const [reload, setReload] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 500);
  }, []);

  async function getData() {
    setShowLoading(true);
    if (Session_Key) {
      await sessionStore.getSessionDetailByKey(Session_Key);
      setShowLoading(false);
    }
  }

  useEffect(() => {
    if (Session_Key) {
      getData();
    }
  }, [Session_Key]);

  async function handleRefresh() {
    setShowLoading(true);
    console.log("Image All Sessions", sessionStore.allSessionDetail);
    await sessionStore.getSessionDetailByKey(sessionStore.activeSession.Key);
    if (clientStore?.clientInfo) {
      await clientStore.getClientSessionInfo();
    }
    setShowLoading(false);
  }

  useEffect(() => {
    if (
      ecomStore.priceList.Groups &&
      ecomStore.searchTerm &&
      !ecomStore.selectedProduct.Key
    ) {
      let queryParams = ecomStore.searchTerm.split("&");
      for (let query of queryParams) {
        if (query.includes("showItemKey")) {
          // Grab the showItemKey from URL
          productsSelected(query.split("=")[1]);
        }
      }
    }
  }, [
    ecomStore.priceList.Groups,
    ecomStore.searchTerm,
    ecomStore.selectedProduct.Key,
    productsSelected,
  ]);

  function productsSelected(key: string) {
    if (key) {
      ecomStore.selectedProduct = ecomStore.getItemByKey(key);
      createOrderStore.setStepNumber(3);
      createOrderStore.showSlider = true;
    }
  }

  if (sessionStore.loading) {
    imageContent = <h3 className="title p-5">Loading images...</h3>;
  } else if (
    sessionStore.activeSessionImages.length === 0 &&
    clientStore?.clientInfo?.SessionCount > 0
  ) {
    imageContent = <SessionHome />;
    // imageContent = (
    //   <h3 className="title p-5">
    //     {t("DashboardPage.noImages")} {authStore.jobSingular.toLowerCase()}
    //     {!sessionStore?.activeSession?.IsGuest &&
    //       authStore.allowImageUpload && (
    //         <span className="spacer-text">
    //           Click the Upload Images button to add images to this{" "}
    //           {authStore.jobSingular}.
    //         </span>
    //       )}
    //   </h3>
    // );
  } else {
    const sessionImages = sessionStore.getImagesBySession(
      sessionStore.activeSession.Key
    );
    imageContent = (
      <ImageTileList
        // images={sessionStore.getImagesBySession(sessionStore.activeSession.Key)}
        images={
          sessionImages.length > 0
            ? sessionImages
            : sessionStore.activeSessionImages
        }
      />
    );
  }

  if (sessionStore.loading) {
    imageContent = <h3 className="title p-5">Loading images...</h3>;
  } else if (
    authStore.isGuestSession &&
    sessionStore.allSessionDetail?.length === 0 &&
    clientStore?.clientInfo?.SessionCount > 0
  ) {
    if (
      sessionStore.getImagesBySession(sessionStore.activeSession.Key).length ===
      0
    ) {
      imageContent = (
        <h3 className="title p-5">
          {t("DashboardPage.noImages")} {authStore.jobSingular.toLowerCase()}
          {!sessionStore?.activeSession?.IsGuest &&
            authStore.allowImageUpload && (
              <span className="spacer-text">
                Click the Upload Images button to add images to this{" "}
                {authStore.jobSingular}.
              </span>
            )}
        </h3>
      );
    } else {
      imageContent = (
        <ImageTileList
          images={sessionStore.getImagesBySession(
            sessionStore.activeSession.Key
          )}
        />
      );
    }
  } else if (sessionStore.allSessionDetail?.length === 0) {
    if (authStore.companyName === "The Studio") {
      imageContent = <SessionHome />;
    }
  }

  return (
    <>
      <>
        <ResponsiveHeader />

        <Helmet>
          <title>
            {`${authStore.jobSingular}${
              sessionStore?.activeSession?.Description
                ? ": " + sessionStore?.activeSession?.Description
                : ""
            } ${
              authStore.companyName || authStore.companyName !== ""
                ? "| " + authStore.companyName
                : " "
            }`}
          </title>
        </Helmet>
        {/* {!reload &&
      (sessionStore.activeSessionImages[0] ? <ImageTileList images = {sessionStore.activeSessionImages} /> : <h1 className="title p-5">{t('DashboardPage.noImages')} {authStore.jobSingular.toLowerCase()}</h1>) } */}

        {showLoading && (
          <>
            <div className="full-page-overlay">
              <div className="spinner-center">
                <Spinner color="primary" size="lg" />
              </div>
            </div>
          </>
        )}

        <nav className="inner-section session-folders-nav py-3 mb-4 d-print-none">
          <ul className="list-inline flex-col-grid" role="presentation">
            {authStore.allowNewSessions && (
              <li className="list-inline-item">
                <Link to="/sessions-list">
                  <Button color="light" className="btn-badge">
                    <FiArrowLeft size="18" className="mr-2" />
                    {t("sessionsPage.BackTo")} {authStore.jobPlural}{" "}
                  </Button>
                </Link>
              </li>
            )}

            <li>
              <Button
                color="light"
                className="btn-badge"
                onClick={handleRefresh}
              >
                <FiRefreshCcw size="18" className="mr-2" />
                Refresh Images
              </Button>
            </li>
          </ul>
        </nav>

        {/* {!clientStore?.clientInfo?.SessionCount &&
          !sessionStore?.activeSession && (
            <>
              <h3 className="title p-5 ion-align-items-center center-align flex-wrap">
                <>You do not have any {authStore.jobPlural} yet.</>
              </h3>
            </>
          )} */}

        {/* {!reload && <SessionHome />} */}
        {!reload && imageContent}
      </>
    </>
  );
};

export default inject(
  "authStore",
  "sessionStore",
  "ecomStore",
  "createOrderStore",
  "clientStore",
  "navigationStore"
)(observer(SessionsPage));
