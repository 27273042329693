import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Spinner,
  Toast,
  ToastHeader,
  ToastBody,
  Progress,
} from "reactstrap";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import { FiArrowLeft, FiDelete, FiRotateCw } from "react-icons/fi";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

import { postReverseVariants, useQuery } from "../../util";
import useWindowDimensions from "../../util/getDimensions";
// import { toJS } from 'mobx';

const CropPhoto: React.FC<any> = ({
  authStore,
  createOrderStore,
  sessionStore,
  ecomStore,
  compositeStore,
}) => {
  const { t } = useTranslation();
  // used for displaying the image movement
  const [cropView, setCropView] = useState({
    x: ecomStore.croppedPhoto?.CropLeft * 100,
    y: ecomStore.croppedPhoto?.CropTop * 100,
  });

  // Don't show cropper if there is a composite key, separate screen for composites
  const showCropper: boolean =
    !ecomStore.croppedPhoto.compositeTemplateKey &&
    ecomStore.croppedPhoto.PreviewUrl;

  console.log(ecomStore.croppedPhoto.PreviewUrl);

  const [zoom, setZoom] = useState(1);
  const [optionKey, setOptionKey] = useState(ecomStore.croppedPhoto.OptionKey);
  const [photoOptions, setPhotoOptions] = useState<any>([]);
  const [photoEnhancements, setPhotoEnhancements] = useState<any>([]);
  const [enhancementKey, setEnhancementKey] = useState(
    ecomStore.croppedPhoto.EnhancementKey
  );
  const [colorEffectKey, setColorEffectKey] = useState(
    ecomStore?.croppedPhoto?.ColorizationKey ||
      sessionStore?.getImageByFilename(ecomStore?.croppedPhoto?.FileName)
        ?.defaultColorization ||
      ""
  );
  const [notes, setNotes] = useState(ecomStore.croppedPhoto?.Notes);

  const [aspectRatio, setAspectRatio] = useState(
    ecomStore.croppedPhoto?.CropRotated
      ? 1 / ecomStore.croppedPhoto?.aspectRatio
      : ecomStore.croppedPhoto?.aspectRatio
  );

  const [cropRotated, setCropRotated] = useState(
    ecomStore.croppedPhoto?.CropRotated
  );

  const [firstLoaded, setFirstLoaded] = useState(false);
  // percentage value for api call
  const [cropApi, setCropApi] = useState({
    x: ecomStore.croppedPhoto?.CropLeft * 100,
    y: ecomStore.croppedPhoto?.CropTop * 100,
    width: ecomStore.croppedPhoto?.CropWidth * 100,
    height: ecomStore.croppedPhoto?.CropHeight * 100,
  });
  const [initialCropped, setInitialCropped] = useState<any>();
  // const [isDefaultOption, setIsDefaultOption] = useState<boolean>(false);

  const query = useQuery();
  const isPrepaid = query?.get("id");
  const allowCrop =
    ecomStore.getAspectRatio(ecomStore.croppedPhoto?.ItemKey) > 0;

  const { innerHeight } = useWindowDimensions(true);

  // hook to display second instance when its values are set
  /*
    useEffect(()=>{
      console.log('Crop details');
      console.log(ecomStore.croppedPhoto);
      console.log({label:ecomStore.croppedPhoto.enhancementDescription, value:ecomStore.croppedPhoto.enhancementKey});
      console.log('croppedPhotoEnhancementsFormattedForDropdown');
      console.log(ecomStore.croppedPhotoEnhancementsFormattedForDropdown);
    },[])
    */
  useEffect(() => {
    setTimeout(() => setFirstLoaded(true), 1000);
  }, [initialCropped]);

  useEffect(() => {
    let setOpts: any = [];
    if (ecomStore?.croppedPhoto?.Options?.length) {
      for (let opt of ecomStore?.croppedPhoto.Options) {
        setOpts.push({
          OptionKey: opt.OptionKey,
          OptionDescription: opt.OptionDescription,
          Remove: false,
        });
      }
      setPhotoOptions(setOpts);
    }
    // setDefaultPhotoOptions(opts);
  }, [ecomStore?.croppedPhoto.Options]);

  const customStyles = {
    multiValue: (base: any, state: any) => ({
      ...base,
      maxWidth: "500px",
      whiteSpace: "normal",
    }),
  };

  useEffect(() => {
    let setEnhancements: any = [];
    if (ecomStore?.croppedPhoto?.Enhancements?.length) {
      for (let opt of ecomStore?.croppedPhoto.Enhancements) {
        if (!opt.EnhancementKey) continue;
        setEnhancements.push({
          EnhancementKey: opt.EnhancementKey,
          EnhancementDescription: opt.EnhancementDescription,
          Remove: false,
        });
      }
      setPhotoEnhancements(setEnhancements);
    }
    // setDefaultPhotoOptions(opts);
  }, [ecomStore?.croppedPhoto.Enhancements]);

  // reset aspect ratio on change of rotation
  useEffect(() => {
    var currentAspectRatio = 1;
    if (ecomStore.croppedPhoto?.aspectRatio > 0) {
      if (cropRotated) {
        currentAspectRatio = 1 / ecomStore.croppedPhoto?.aspectRatio;
      } else {
        currentAspectRatio = ecomStore.croppedPhoto?.aspectRatio;
      }
    }
    setAspectRatio(currentAspectRatio);
  }, [cropRotated]);

  function reset() {
    setCropRotated(ecomStore.croppedPhoto?.CropRotated);
    setCropView({ x: 0, y: 0 });
    setZoom(1);
  }

  function zoomIn() {
    setZoom(zoom * 1.1);
  }

  function zoomOut() {
    if (zoom <= 1.1) {
      setZoom(1);
    } else {
      setZoom(zoom * 0.9);
    }
  }

  const CustomOption = (props: {
    data: any;
    innerRef: any;
    innerProps: any;
  }) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div className="optionLabel" ref={innerRef} {...innerProps}>
        {data.customLabel} <span>+ {`$${data.price.toFixed(2)}`}</span>
      </div>
    );
  };

  function handlePhotoOptions(
    values: any,
    actionMeta: {
      action: any;
      removedValue: { value: any };
      option: { value: any; label: any };
    }
  ) {
    console.log(actionMeta);
    let options: any = photoOptions;
    switch (actionMeta.action) {
      case "remove-value":
        options.find(
          (option: { OptionKey: any }) =>
            option.OptionKey == actionMeta.removedValue.value
        ).Remove = true;
        break;
      case "select-option":
        if (options.length) {
          if (
            options.find(
              (option: { OptionKey: any }) =>
                option.OptionKey == actionMeta.option.value
            )
          ) {
            options.find(
              (option: { OptionKey: any }) =>
                option.OptionKey == actionMeta.option.value
            ).Remove = false;
          } else {
            options.push({
              OptionKey: actionMeta.option.value,
              OptionDescription: actionMeta.option.label,
              Remove: false,
            });
          }
        } else {
          options.push({
            OptionKey: actionMeta.option.value,
            OptionDescription: actionMeta.option.label,
            Remove: false,
          });
        }
        break;
      case "clear":
        for (let item of options) {
          item.Remove = true;
        }
        break;
    }
    setPhotoOptions(options);
  }

  function handlePhotoEnhancement(
    values: any,
    actionMeta: {
      action: any;
      removedValue: { value: any };
      option: { value: any; label: any };
    }
  ) {
    let options: any = photoEnhancements;
    console.log(ecomStore.croppedPhotoEnhancementsFormatted);

    switch (actionMeta.action) {
      case "remove-value":
        options.find(
          (option: { EnhancementKey: any }) =>
            option.EnhancementKey === actionMeta.removedValue.value
        ).Remove = true;
        break;
      case "select-option":
        if (options.length) {
          if (
            options.find(
              (option: { EnhancementKey: any }) =>
                option.EnhancementKey === actionMeta.option.value
            )
          ) {
            options.find(
              (option: { EnhancementKey: any }) =>
                option.EnhancementKey === actionMeta.option.value
            ).Remove = false;
          } else {
            options.push({
              EnhancementKey: actionMeta.option.value,
              EnhancementDescription: actionMeta.option.label,
              Remove: false,
            });
          }
        } else {
          options.push({
            EnhancementKey: actionMeta.option.value,
            EnhancementDescription: actionMeta.option.label,
            Remove: false,
          });
        }
        break;
      case "clear":
        for (let item of options) {
          item.Remove = true;
        }
        break;
    }
    setPhotoEnhancements(options);
  }

  async function finish() {
    const req = {
      InvoiceKey: sessionStore.swappedPhoto?.PreOrder
        ? ecomStore.activeInvoice.Key
        : ecomStore.cart.Key,
      Key: ecomStore.croppedPhoto.Key,
      CropRotated: cropRotated,
      CropLeft: cropApi.x / 100,
      CropWidth: cropApi.width / 100,
      CropHeight: cropApi.height / 100,
      CropTop: cropApi.y / 100,
      OptionKey: optionKey,
      Options: photoOptions,
      Enhancements: photoEnhancements,
      EnhancementKey: enhancementKey,
      ColorizationKey: colorEffectKey,
      Notes: notes,
      ItemKey: "",
    };

    await ecomStore
      .updateCartItem(
        req,
        ecomStore.croppedPhoto.SessionKey,
        true,
        sessionStore.swappedPhoto?.PreOrder ? true : false
      )
      .then(async () => {
        if (ecomStore.croppedPhoto?.PreOrder) {
          createOrderStore.toggleSlider();
        } else {
          let updatedItem = ecomStore.currentPackageItems.find(
            (item: any) => item.Key === ecomStore.swapPhotoCartItemKey
          );
          let updatedItemIdx = ecomStore.currentPackageItems.findIndex(
            (item: any) => item.Key === ecomStore.swapPhotoCartItemKey
          );

          let found = false;
          while (
            updatedItem?.PackageItemKey &&
            ecomStore.currentPackageItems &&
            updatedItemIdx + 1 < ecomStore.currentPackageItems.length &&
            !found
          ) {
            const newItem = ecomStore.currentPackageItems[updatedItemIdx + 1];
            const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
            const options = priceItem.Options;
            const enhancements = priceItem.Enhancements;

            if (newItem.compositeTemplateKey) {
              ecomStore.scrollCart = newItem.Key;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              await compositeStore.handleComposite(newItem);
              createOrderStore.setStepNumber(7);
              found = true;
            } else if (newItem?.ReplacementItems?.length > 0) {
              ecomStore.setCroppedPhoto(newItem.Key);
              createOrderStore.setStepNumber(8);
              found = true;
            } else if (
              ecomStore.isImageRequired(newItem) ||
              newItem.allowImageSelection
            ) {
              sessionStore.swappedPhoto = newItem;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(5);
              found = true;
            } else if (
              ecomStore.isCropRequired(newItem) ||
              options?.length > 0 ||
              enhancements?.length > 0
            ) {
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.setCroppedPhoto(newItem.Key);
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(6);
              found = true;
            } else {
              updatedItemIdx += 1;
            }
          }
          if (
            !updatedItem?.PackageItemKey ||
            updatedItemIdx + 1 === ecomStore.currentPackageItems.length
          ) {
            createOrderStore.setStepNumber(4);
          }
        }
      });

    return;
  }

  function rotate() {
    setCropRotated(!cropRotated);
  }

  async function firstMediaLoaded(e: any) {
    if (allowCrop) {
      if (
        !!ecomStore.croppedPhoto.CropLeft ||
        !!ecomStore.croppedPhoto.CropTop ||
        !!ecomStore.croppedPhoto.CropWidth ||
        !!ecomStore.croppedPhoto.CropHeight
      ) {
        await setInitialCropped({
          x: e.naturalWidth * Number(ecomStore.croppedPhoto.CropLeft),
          y: e.naturalHeight * Number(ecomStore.croppedPhoto.CropTop),
          width: e.naturalWidth * Number(ecomStore.croppedPhoto.CropWidth),
          height: e.naturalHeight * Number(ecomStore.croppedPhoto.CropHeight),
        });
      } else {
        setCropRotated(e.naturalWidth > e.naturalHeight);
        setFirstLoaded(true);
      }
    } else {
      reset();
    }
  }

  console.log(ecomStore.croppedPhotoEnhancementsFormattedForDropdown);
  console.log(ecomStore.croppedPhotoOptionsFormattedForDropdown);
  var toastText = "";
  if (
    ecomStore.croppedPhoto?.PreviewUrl &&
    !ecomStore.croppedPhoto.CompositeKey &&
    (ecomStore.croppedPhotoOptionsFormattedForDropdown ||
      ecomStore.croppedPhotoEnhancementsFormattedForDropdown)
  ) {
    toastText = "Please select a crop and add-ons for:";
  } else if (
    ecomStore.croppedPhoto?.PreviewUrl &&
    !ecomStore.croppedPhoto.CompositeKey &&
    !(
      ecomStore.croppedPhotoOptionsFormattedForDropdown ||
      ecomStore.croppedPhotoEnhancementsFormattedForDropdown
    )
  ) {
    toastText = "Please select a crop for:";
  } else if (
    !ecomStore.croppedPhoto?.PreviewUrl ||
    (ecomStore.croppedPhoto?.CompositeKey &&
      (ecomStore.croppedPhotoOptionsFormattedForDropdown ||
        ecomStore.croppedPhotoEnhancementsFormattedForDropdown))
  ) {
    toastText = "Please select your add ons for:";
  } else {
    toastText = "Please add notes (Optional)";
  }

  if (compositeStore.loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div className="mb-1" style={{ textAlign: "center" }}>
          Loading Template Builder
        </div>
        <div style={{ textAlign: "center" }}>
          <Spinner color="primary" />
        </div>
      </div>
    );
  return (
    <>
      <Helmet>
        <title>
          {ecomStore.croppedPhoto?.PreOrder
            ? `${t("cropViewComponent.prepaid")} ${isPrepaid}`
            : `${t("cropViewComponent.shoppingCart")}`}
          : {t("cropView.cropImage")} {ecomStore.croppedPhoto?.ImageNumber}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postReverseVariants}
      >
        <section className="product-modal-container">
          <div className="product-modal-header">
            <div>
              <Button
                color="default-link"
                onClick={() => {
                  ecomStore.croppedPhoto?.PreOrder
                    ? createOrderStore.toggleSlider()
                    : createOrderStore.setStepNumber(4);
                }}
              >
                <FiArrowLeft size="18" className="mr-2" />
                {ecomStore.croppedPhoto?.PreOrder
                  ? t("cropViewComponent.backToPreorder")
                  : t("cropViewComponent.backToShoppingCart")}
              </Button>
              <div style={{ fontSize: "24px" }}>
                <strong>
                  {toastText} {ecomStore.croppedPhoto?.Description}{" "}
                  {ecomStore.currentPackageItems.length > 0 &&
                    `(Item ${
                      ecomStore.currentPackageItems.findIndex(
                        (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                      ) + 1
                    } of ${ecomStore.currentPackageItems.length})`}
                  {ecomStore.currentPackageItems.length > 0 && (
                    <Progress
                      value={
                        ecomStore.currentPackageItems.findIndex(
                          (itm: any) =>
                            ecomStore.swapPhotoCartItemKey === itm.Key
                        ) + 1
                      }
                      min="1"
                      max={ecomStore.currentPackageItems.length}
                    ></Progress>
                  )}
                </strong>
              </div>
            </div>
          </div>
          <div className="product-modal-photo-container product-crop">
            <Row
              className="no-gutters product-crop-row"
              style={{ height: innerHeight - 74 }}
            >
              {showCropper && (
                <Col md={8}>
                  <figure
                    className="crop-photo"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    {/* // throw away instance so we can grab px values for dimensions */}
                    <Cropper
                      style={{ containerStyle: { zIndex: -1000 } }}
                      image={ecomStore.croppedPhoto?.PreviewUrl}
                      crop={cropView}
                      zoom={zoom}
                      onCropChange={() => null}
                      onZoomChange={() => null}
                      onMediaLoaded={firstMediaLoaded}
                      showGrid={false}
                      zoomSpeed={0.07}
                    />
                    {firstLoaded && (
                      <Cropper
                        image={`${ecomStore.croppedPhoto?.PreviewUrl}${
                          colorEffectKey
                            ? `&colorizationKey=${colorEffectKey}`
                            : ""
                        }`}
                        crop={cropView}
                        zoom={zoom}
                        aspect={aspectRatio}
                        showGrid={false}
                        initialCroppedAreaPixels={initialCropped}
                        onCropChange={setCropView}
                        onCropComplete={setCropApi}
                        onZoomChange={setZoom}
                        zoomSpeed={0.07}
                      />
                    )}
                    {/* brower caching color effect images so they load faster */}
                    <div
                      style={{
                        overflow: "hidden",
                        height: 0,
                        visibility: "hidden",
                      }}
                    >
                      {ecomStore.colorizationOptionsFormattedForDropdown?.map(
                        (val: any) => (
                          <img
                            key={val.label}
                            src={`${
                              ecomStore.croppedPhoto?.PreviewUrl
                            }${`&colorizationKey=${val.value}`}`}
                            alt={val.label}
                          />
                        )
                      )}
                    </div>
                    {/* brower caching color effect images so they load faster */}

                    <figcaption className="session-filename">
                      <ul className="d-flex list-unstyled buttons text-center">
                        <li>
                          {ecomStore?.croppedPhoto?.ImageNumber && (
                            <>
                              {t("cropViewComponent.image")}{" "}
                              {ecomStore.croppedPhoto.ImageNumber}
                            </>
                          )}
                        </li>
                        {showCropper && (
                          <>
                            <li className="mx-3">
                              <Button onClick={zoomIn} color="icon-top">
                                <AiOutlineZoomIn color="white" size={24} />
                              </Button>
                            </li>
                            <li>
                              <Button onClick={zoomOut} color="icon-top">
                                <AiOutlineZoomOut color="white" size={24} />
                              </Button>
                            </li>
                          </>
                        )}
                      </ul>
                    </figcaption>
                  </figure>
                </Col>
              )}

              <Col md={showCropper ? 4 : 12} className="crop-photo-col">
                <div className="crop-photo-actions">
                  {showCropper && (
                    <>
                      <ul
                        className="list-unstyled buttons text-center"
                        role="presentation"
                      >
                        <li>
                          <Button onClick={reset} color="icon-top">
                            <FiDelete size={24} />
                            <span>{t("cropViewComponent.reset")}</span>
                          </Button>
                        </li>
                        {ecomStore.croppedPhoto?.aspectRatio > 0 &&
                          ecomStore.croppedPhoto?.aspectRatio < 1 && (
                            <li>
                              <Button onClick={rotate} color="icon-top">
                                <FiRotateCw size={24} />
                                <span>
                                  {t("cropViewComponent.cropOrientation")}
                                </span>
                              </Button>
                            </li>
                          )}
                        {/* <li>
                    <Button 
                      onClick = {reset}
                      color = "icon-top"><FiToggleRight size={24} /><span>Auto-retouch</span></Button>
                  </li> */}
                      </ul>
                    </>
                  )}

                  <hr className="my-md-4" />
                  <h4 className="h6 mb-3">
                    {ecomStore.croppedPhoto?.Description +
                      " " +
                      `Image ${ecomStore.croppedPhoto?.ImageNumber}`}
                  </h4>
                  <form className="filter row row-sm text-left">
                    {!!ecomStore.croppedPhotoOptionsFormattedForDropdown
                      ?.length && (
                      <FormGroup className="col-12">
                        <div className="form-field-float active">
                          <Label>
                            {t("cropViewComponent.selectPhotoOption")}
                          </Label>
                          {true ? (
                            <Select
                              className="react-select-group-container"
                              classNamePrefix="react-select-group"
                              placeholder={t("cropViewComponent.pleaseSelect")}
                              // defaultValue={
                              //   !!ecomStore.croppedPhoto
                              //     ? ecomStore.croppedPhotoOptionsFormattedForDropdown.find(
                              //       (opt: any) => opt.value === optionKey
                              //     )
                              //     : null
                              // }

                              isMulti
                              styles={customStyles}
                              defaultValue={
                                ecomStore?.croppedPhotoOptionsFormatted
                              }
                              options={
                                ecomStore.croppedPhotoOptionsFormattedForDropdown
                              }
                              // options={dummyValue}

                              components={{ Option: CustomOption }}
                              onChange={(values: any, actionMeta: any) => {
                                setOptionKey("");
                                handlePhotoOptions(values, actionMeta);
                              }}
                              isDisabled={ecomStore.imageUpdate}
                            />
                          ) : (
                            <Select
                              options={[
                                { label: "None", value: "", price: 0 },
                              ].concat(
                                ecomStore.croppedPhotoOptionsFormattedForDropdown
                              )}
                              components={{ Option: CustomOption }}
                              className="react-select-group-container"
                              classNamePrefix="react-select-group"
                              placeholder={t("cropViewComponent.pleaseSelect")}
                              onChange={(e: any) => {
                                setOptionKey(e.value);
                              }}
                              defaultValue={
                                !!ecomStore.croppedPhoto
                                  ? ecomStore.croppedPhotoOptionsFormattedForDropdown.find(
                                      (opt: any) => opt.value === optionKey
                                    )
                                  : null
                              }
                              isDisabled={ecomStore.imageUpdate}
                            />
                          )}
                        </div>
                      </FormGroup>
                    )}
                    {!!ecomStore.croppedPhotoEnhancementsFormattedForDropdown
                      .length && (
                      <FormGroup className="col-12">
                        <div className="form-field-float active">
                          <Label>
                            {t("cropViewComponent.selectPhotoEnhancement")}
                          </Label>
                          <Select
                            className="react-select-group-container"
                            classNamePrefix="react-select-group"
                            placeholder={t("cropViewComponent.pleaseSelect")}
                            options={
                              ecomStore.croppedPhotoEnhancementsFormattedForDropdown
                            }
                            styles={customStyles}
                            onChange={(values: any, actionMeta: any) => {
                              handlePhotoEnhancement(values, actionMeta);
                            }}
                            components={{ Option: CustomOption }}
                            isMulti
                            defaultValue={
                              ecomStore?.croppedPhotoEnhancementsFormatted
                            }
                            isDisabled={ecomStore.imageUpdate}
                          />
                        </div>
                      </FormGroup>
                    )}
                    {!!ecomStore.colorizationOptionsFormattedForDropdown
                      ?.length && (
                      <FormGroup className="col-12">
                        <div className="form-field-float active">
                          <Label>
                            {t("cropViewComponent.selectColorEffect")}
                          </Label>
                          <Select
                            defaultValue={
                              colorEffectKey
                                ? ecomStore.colorizationOptionsFormattedForDropdown.find(
                                    (opt: any) => opt.value === colorEffectKey
                                  )
                                : null
                            }
                            className="react-select-group-container"
                            classNamePrefix="react-select-group"
                            placeholder={t(
                              "cropViewComponent.selectColorEffect"
                            )}
                            options={[{ label: "None", value: "" }].concat(
                              ecomStore.colorizationOptionsFormattedForDropdown
                            )}
                            onChange={(e: any) => setColorEffectKey(e.value)}
                            isDisabled={ecomStore.imageUpdate}
                          />
                        </div>
                      </FormGroup>
                    )}
                    {!authStore?.hideInvoiceDetailNotes && (
                      <>
                        {!ecomStore.selectedProduct.HideNotes ? (
                          <>
                            <FormGroup className="col-12">
                              <div className="form-field-float active">
                                <Label htmlFor="notes">
                                  {t("cropViewComponent.notes")}
                                </Label>
                                <textarea
                                  name="notes"
                                  id="notes"
                                  className="form-control"
                                  rows={2}
                                  cols={300}
                                  onChange={(e) => setNotes(e.target.value)}
                                  value={notes}
                                  placeholder="Type here..."
                                  disabled={ecomStore.imageUpdate}
                                />
                              </div>
                            </FormGroup>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <p className="col-12">
                      <Button
                        block
                        onClick={finish}
                        color="primary"
                        className={`justify-self-end${
                          ecomStore.imageUpdate ? " wait" : ""
                        }`}
                        disabled={ecomStore.imageUpdate}
                      >
                        {t("cropViewComponent.apply")}
                        {ecomStore.imageUpdate && (
                          <Spinner color="#fff" size="sm" />
                        )}
                      </Button>
                    </p>
                  </form>
                </div>
              </Col>
            </Row>
            {/* <div className="product-modal-footer">
              {ecomStore.currentPackageItems.length > 0 && (
                <div>
                  <Toast>
                    <ToastHeader>
                      Package item:{" "}
                      {
                        ecomStore.currentPackageItems.find(
                          (itm: any) =>
                            ecomStore.swapPhotoCartItemKey === itm.Key
                        ).Description
                      }{" "}
                      (
                      {ecomStore.currentPackageItems.findIndex(
                        (itm: any) => ecomStore.swapPhotoCartItemKey === itm.Key
                      ) + 1}{" "}
                      of {ecomStore.currentPackageItems.length})
                    </ToastHeader>
                    <ToastBody>
                      <Progress
                        value={
                          ecomStore.currentPackageItems.findIndex(
                            (itm: any) =>
                              ecomStore.swapPhotoCartItemKey === itm.Key
                          ) + 1
                        }
                        min="1"
                        max={ecomStore.currentPackageItems.length}
                      ></Progress>
                    </ToastBody>
                  </Toast>
                </div>
              )}
            </div> */}
          </div>
        </section>
      </motion.div>
    </>
  );
};
export default inject(
  "authStore",
  "createOrderStore",
  "sessionStore",
  "ecomStore",
  "compositeStore"
)(observer(CropPhoto));
