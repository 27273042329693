import React from 'react';
import { observer,inject } from 'mobx-react';
import { 
  Button,
  } from 'reactstrap';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import { PriceListGroup } from '../../../interfaces/priceList.int';

const ProductGroupList: React.FC<any> = ({authStore, ecomStore, createOrderStore}) => {
  const { t } = useTranslation();
  function selectGroup(key:string){
    ecomStore.selectProductGroup(key);
    createOrderStore.setStepNumber(2);
  }
  return (
  <>
    <Helmet>
      <title>{t('productGroupListComponent.urlTitle')} {authStore.companyName || authStore.companyName !== '' ? (
          '| ' + authStore.companyName
        ) : ' '}</title>
    </Helmet>
    <section className = "product-modal-container" id = "modal_body_123123123">
      <div className = "product-modal-head">
        <h2>{t('productGroupListComponent.title')}</h2>
      </div>
      <div className = "product-modal-list">
        {ecomStore?.priceList?.Groups?.map((group:PriceListGroup, i:number)=>(          
          <>
          {!group.HiddenGroup && ( // Check that group is not hidden.
          <article className = "row row-list align-items-center" key = {`priceListGroup${i}`} onClick = {()=> selectGroup(group?.Key || '')}>
            <div className = "col-md-6 col-lg-8 col-xl-7 order-md-2 mb-3 mb-md-0">
              <img className = "img-fluid" src={`${group.ImageUrl  }`} alt=""/>
            </div>
            <div className = "col-md-6 col-lg-4 col-xl-5 order-md-1">
              <h3 className = "mb-2">{group.Name}</h3>
              <p>{group.MarketingDescription ? group.MarketingDescription : ''}</p>
              <Button color="body-link">{t('productGroupListComponent.viewTheseProducts')}</Button>
            </div>
          </article>
          )}
          </>
        ))}
      </div>
    </section>
  </>
  );
}
export default inject('authStore','ecomStore','createOrderStore')(observer(ProductGroupList))