import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormGroup, Button, Spinner, Label, FormFeedback } from "reactstrap";
import { MdPermIdentity, MdAssignmentInd } from "react-icons/md";
import { observer, inject } from "mobx-react";
import { AuthStoreClass } from "../../../stores/authStore";
// import { ReactSelectOption } from "../../../interfaces";

interface ChildProps {
  onSubmit: (obj: OrganizationSearchFormData, actions: any) => void;
  content: any;
  authStore?: AuthStoreClass;
}

export interface OrganizationSearchFormData {
  organizationKey: string;
  studentID: string;
  lastName: string;
}

const OrganizationSearchForm: React.FC<ChildProps> = ({
  onSubmit,
  content,
  authStore,
}) => {
  const validationSchema = Yup.object().shape({
    organizationKey: Yup.string()
      // TODO add regex for password
      // .email('Please enter a valid email')
      .required("Organization Name Required")
      .test(
        "checkForDefault",
        "Invalid school selection",
        (value) => value !== "default"
      ),
    studentID: Yup.string().required("Invalid Student ID or Last Name"),
    lastName: Yup.string().required("Invalid Student ID or Last Name"),
  });

  const initialValues: any = {
    organizationKey: "default",
    studentID: "",
    lastName: "",
  };

  const [organizations, setOrganizations] = useState<any>([]);

  useEffect(() => {
    if (authStore && authStore?.organizations) {
      const filtered = authStore.organizations.filter((organization: any) => {
        return organization.OnlineActive && !organization.ParentOrganizationID;
      });
      //   setOrganizations(authStore.organizations);
      setOrganizations(filtered);
    }
  }, [authStore?.organizations]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="organizationKey_input"
              className={`${
                errors.organizationKey && touched.organizationKey
                  ? "error "
                  : ""
              }sr-only`}
            >
              Organization
            </Label>
            <Field
              className={`form-control ${
                errors.organizationKey && touched.organizationKey
                  ? "is-invalid"
                  : ""
              }`}
              name="organizationKey"
              id="organizationKey_input"
              component="select"
              onBlur={handleBlur}
              o
              value={values.organizationKey}
              disabled={isSubmitting}
            >
              <option selected disabled value="default">
                Select your School
              </option>
              {organizations.map((organization: any) => {
                return (
                  <option value={organization.Key}>
                    {organization.OnlineDisplayName}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage name="organizationKey">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="studentID_input"
              className={`${
                errors.studentID && touched.studentID ? "error " : ""
              }sr-only`}
            >
              Student ID
            </Label>
            <MdAssignmentInd size="20" />
            <Field
              className={`form-control ${
                errors.studentID && touched.studentID ? "is-invalid" : ""
              }`}
              type="text"
              name="studentID"
              id="studentID_input"
              placeholder={"Your Student ID"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.studentID}
              disabled={isSubmitting}
            />
            <ErrorMessage name="studentID">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="lastName_input"
              className={`${
                errors.lastName && touched.lastName ? "error " : ""
              }sr-only`}
            >
              Last Name
            </Label>
            <MdPermIdentity size="20" />
            <Field
              className={`form-control ${
                errors.lastName && touched.lastName ? "is-invalid" : ""
              }`}
              type="text"
              name="lastName"
              id="lastName_input"
              placeholder={"Your Last Name"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              disabled={isSubmitting}
            />
            <ErrorMessage name="lastName">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {content.search}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default inject("authStore")(observer(OrganizationSearchForm));
