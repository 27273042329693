import React from 'react';
import { observer, inject } from 'mobx-react';
// import ImageGallery from 'react-image-gallery';
import './globalErrorMessage.scss';
import { Alert } from 'reactstrap';
// import { Button } from 'reactstrap';
// import { MdClose } from 'react-icons/md'

const GlobalErrorMessage: React.FC<any> = ({ globalErrorStore:err }) => {
    return (
        // leaving wrapping div to allow for bg color transition
        <div className={`GlobalErrorMessage ${!!err.errorMessage ? 'active' : ''}`}>
            {!!err.errorMessage && 
            <Alert color = {err.messageColor} className = "">{err.errorMessage}</Alert>
            }

        </div>

    );
}
export default inject('globalErrorStore')(observer(GlobalErrorMessage))