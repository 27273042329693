import { createGlobalStyle } from "styled-components";
import { darken, transparentize } from "polished";

const GlobalStyle = createGlobalStyle`
  body,
  .table {
    color: ${(props) => props.SecondaryFontColor};
    font-family: ${(props) => props.SecondaryFontStyle};
  }

  h1,
  h2,
  h3,
  h4,
  .color-primary {
    color: ${(props) => props.PrimaryFontColor};
    font-family: ${(props) => props.PrimaryFontStyle};
  }

  input,
  select,
  textarea,
  .form-control {
    font-family: ${(props) => props.PrimaryFontStyle};
    color: ${(props) => props.TextboxFontColor};
    border-color: ${(props) => props.TextboxBorderColor};
    background-color: ${(props) => props.TextboxBackgroundColor};
  }

  input:focus,
  select:focus,
  textare:focus,
  .form-control:focus {
    color: ${(props) => props.TextboxFontColor};
  }

  ::-webkit-input-placeholder,
  :-moz-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    font-family: ${(props) => props.PrimaryFontStyle};
  }

  .nav-tabs .nav-link:hover, 
  .auth-header .nav .nav-link:hover,
  .nav-tabs .nav-link.active, 
  .auth-header .nav .nav-link.active,
  .card-form h4,
  .btn-default-link-bold,
  .btn-body-link,
  .price,
  .btn-body-link-reverse,
  .nav-session .nav-label,
  .nav-session .nav-link:hover svg,
  .nav-session .nav-link.active svg,
  a:not(.btn) {
    color: ${(props) => props.NextButtonColor};

    &:hover {
      color: ${(props) =>
        props.NextButtonColor && darken(0.1, props.NextButtonColor)};
    }
  }

  .btn-default-link,
  .btn-icon, 
  .thumb-edit .btn,
  .btn-icon-top {
    color: ${(props) => props.BackButtonFontColor};

    &:hover {
      color: ${(props) =>
        props.BackButtonFontColor && darken(0.1, props.BackButtonFontColor)};
    }
  }


  .nav-main .nav-link.active .badge, 
  .nav-main .nav-link.active .badge-status,
  .dropdown-item-icon:hover, 
  .dropdown-menu-icon button:hover,
  .text-primary {
    color: ${(props) => props.NextButtonColor} !important;
  }

  ::-moz-selection { background: ${(props) => props.NextButtonColor}; }
  ::selection { background: ${(props) => props.NextButtonColor}; }

  .btn {
    font-family: ${(props) => props.PrimaryFontStyle};
  }

  .progress-bar {
    background-color: ${(props) => props.NextButtonColor};
  }

  .form-control:focus {
    border-color: ${(props) => props.NextButtonColor};
  }

  .nav-tabs .nav-link::after, 
  .auth-header .nav .nav-link::after,
  .badge-primary,
  .custom-control-input:checked ~ .custom-control-label::before,
  .nav-main .nav-link.active,
  .dropdown-item-icon:focus, 
  .dropdown-menu-icon button:focus,
  .dropdown-item-icon:active, 
  .dropdown-menu-icon button:active,
  .btn-primary {
    color: ${(props) => props.NextButtonFontColor} !important;
    border-color: ${(props) => props.NextButtonColor};
    background-color: ${(props) => props.NextButtonColor};
  }

  .form-control:not(:disabled):focus, .react-select-group-container .react-select-group__control:not(:disabled):focus, .react-select-group-container .react-select-group-sm__control:not(:disabled):focus, .form-field-float label:not(:disabled):focus,
  .custom-control-input:focus ~ .custom-control-label::before,
  .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem ${(props) =>
      props.NextButtonColor && transparentize(0.8, props.NextButtonColor)};
  }

  .btn-primary.disabled, 
  .btn-primary:disabled,
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before,
  .btn-primary:focus,
  .btn-primary:hover {
    border-color: ${(props) =>
      props.NextButtonColor && darken(0.04, props.NextButtonColor)};
    background-color: ${(props) =>
      props.NextButtonColor && darken(0.04, props.NextButtonColor)};
  }

  .btn-primary:active,
  .btn-primary:active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem ${(props) =>
      props.BackButtonColor && transparentize(0.8, props.BackButtonColor)};
  }

  .btn-primary:active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-warning {
    color: ${(props) => props.BackButtonFontColor};
    border-color: ${(props) => props.BackButtonColor};
    background-color: ${(props) => props.BackButtonColor};
  }

  .photo-grid-composite .photo-grid-image::before {
    background-color: ${(props) =>
      props.BackButtonColor && transparentize(0.1, props.BackButtonColor)};
  }

  #ProductGallery .image-gallery-thumbnail.gallery-custom-thumbnail::before {
    border-color: ${(props) => props.NextButtonColor};
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23${(
      props
    ) =>
      props.NextButtonColor?.replace(
        "#",
        ""
      )}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }

  .family-members-dropdown{
    border: 1px solid ${(props) => props.NextButtonColor};
  }


  .position-col svg {
    color: ${(props) => props.NextButtonFontColor} !important;
  }

  .custom-file-input-upload::before{
    background: ${(props) => props.PrimaryFontColor} !important;
  }

  .blog-title .blog-title-content{
    color: ${(props) => props.PrimaryFontColor}!important;
    font-family:  ${(props) => props.PrimaryFontStyle};
  }

  .no-more-blogpost{
    color: ${(props) => props.PrimaryFontColor}!important;
    font-family:  ${(props) => props.PrimaryFontStyle};
  }

  .toggle-comments span, .comments-list .author-name{
    color: ${(props) => props.PrimaryFontColor}!important;
  }

  .album-list p.details.card-text{
    color: ${(props) => props.PrimaryFontColor}!important;
    font-family:  ${(props) => props.PrimaryFontStyle};
  }


  .select,
.react-select-group__control {
  background-color:${(props) => props.TextboxBackgroundColor} !important;
}
.react-select-group__single-value, .react-select-group__dropdown-indicator svg, .react-select-group__placeholder{
    color: ${(props) => props.PrimaryFontColor} !important;
}

.progress-bar {
  background-color: ${(props) => props.PrimaryFontColor} !important;
}

.primary-color{
  color: ${(props) => props.PrimaryFontColor} !important;
}

  @media (max-width: 767px) {
    .nav-main .nav-link:not(.active):hover svg, 
    .nav-main .nav-link:not(.active):focus svg, 
    .nav-main .nav-link:not(.active):active svg,
    .nav-main .nav-link:not(.active):hover .nav-label, 
    .nav-main .nav-link:not(.active):focus .nav-label, 
    .nav-main .nav-link:not(.active):active .nav-label {
      color: ${(props) => props.NextButtonColor};
    }
  }

  @media (max-width: 576px) {
    .auth-header .nav .nav-link {
      background-color: ${(props) => props.NextButtonColor};
      
      &.active {
        color: ${(props) => props.NextButtonColor};
        background-color: '#fff';
      }
    }
  }
`;

export default GlobalStyle;
