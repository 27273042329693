import { observable, action, computed } from "mobx";
import axios, { AxiosResponse, AxiosError } from "axios";
import { create, persist } from "mobx-persist";
import ClientStore from "./clientStore";
import { GuestInvitation } from "../interfaces/guest.int";
import SessionStore from "./sessionStore";

class GuestStoreClass {
  @persist("list") @observable allGuests: Array<any> = [];

  @observable hydrated: boolean = false;
  @observable error: string = "";
  @observable guestUpdating: any = null;
  @computed get activeSessionKey() {
    return SessionStore.activeShareSession?.SessionKey;
  }

  @computed get showGuestsSpinner() {
    return !this.activeSessionKey;
  }
  @computed get showNoLinksFound() {
    return !!this.activeSessionKey && !this.inviteLinksForActiveSession?.length;
  }
  @computed get showNoGuestsFound() {
    return !!this.activeSessionKey && !this.guestsForActiveSession?.length;
  }

  @computed get inviteLinks() {
    return (
      this.allGuests.filter((guest: GuestInvitation) => !!!guest.Email) || []
    );
  }

  @computed get inviteLinksForActiveSession() {
    return (
      this.inviteLinks?.filter(
        (guest: GuestInvitation) => guest.SessionKey === this.activeSessionKey
      ) || []
    );
  }

  @computed get allGuestInvitations() {
    return (
      this.allGuests.filter((guest: GuestInvitation) => !!guest.Email) || []
    );
  }

  @computed get guestsForActiveSession() {
    return (
      this.allGuestInvitations?.filter(
        (guest: GuestInvitation) => guest.SessionKey === this.activeSessionKey
      ) || []
    );
  }

  @action async getAllGuests() {
    return axios({
      method: "GET",
      url: `/ibyGuestInvitations`,
      params: {
        clientKey: ClientStore.clientKey,
      },
    }).then(async (res: AxiosResponse) => {
      this.allGuests = res.data;
      return res.data;
    });
  }

  deleteGuest(key: string) {
    this.guestUpdating = key;
    return axios({
      method: "DELETE",
      url: `/ibyGuestInvitation/${key}`,
    }).then(async (res: AxiosResponse) => {
      this.allGuests = this.allGuests.filter(
        (guest: GuestInvitation) => guest.Key !== res.data
      );
      this.guestUpdating = false;
      return res.data;
    });
  }

  setGuest(obj: GuestInvitation, params = {}) {
    const data: GuestInvitation = {
      ...obj,
      CreatedByClientKey: ClientStore.clientKey,
    };
    if (obj.SessionKey === "all") {
      data["ShareAllSessions"] = true;
    } else {
      data["SessionKey"] = obj.SessionKey;
    }
    if (obj.ImageGroup !== "all") {
      data["ImageGroup"] = obj.ImageGroup;
    }

    return axios({
      method: "PUT",
      url: `/ibyGuestInvitation`,
      data: data,
      params: params,
    })
      .then(async (res: AxiosResponse) => {
        const editIndex = this.allGuests
          .map((g: GuestInvitation) => g.Key)
          .indexOf(res.data.Key);
        if (editIndex === -1) {
          this.allGuests.push(res.data);
        } else {
          this.allGuests[editIndex] = res.data;
        }
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  }
}

const hydrate = create({});
const GuestStore = new GuestStoreClass();
hydrate("guest", GuestStore).then((GuestStore) => {
  GuestStore.hydrated = true;
});
export default GuestStore;
