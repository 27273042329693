import React, { useState, useEffect } from "react";
// import { useTranslation } from 'react-i18next';
import "./index.scss";
import { observer, inject } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { Nav, NavItem, Badge, Button } from "reactstrap";
import {
  AiOutlinePicture,
  AiOutlineForm,
  AiOutlineFileDone,
  AiOutlineHistory,
  AiOutlineTeam,
  AiOutlineUser,
  AiOutlineQuestionCircle,
  AiOutlinePoweroff,
  AiOutlineShop,
  AiOutlineShoppingCart,
  AiOutlineSmile,
  AiOutlineFileText,
} from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import InnerHTML from "dangerously-set-html-content";
import { useTranslation } from "react-i18next";

import Lightbox from "../lightbox/lightbox";
import { isActiveRoute, useQuery } from "../util";
import useWindowDimensions from "../util/getDimensions";

const NavBarPage: React.FC<any> = ({
  clientStore,
  sessionStore,
  createOrderStore,
  navigationStore,
  authStore,
  ecomStore,
  lightboxStore,
  children,
  open = true,
}) => {
  const { t } = useTranslation();
  const query = useQuery();
  const isEmbed = query.get("embed");
  // const {t} = useTranslation();
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(open);
  const { innerHeight } = useWindowDimensions();

  /*
  useEffect(()=>{
    console.log(clientStore?.clientInfo);
  })
  */

  useEffect(() => {
    lightboxStore.closeLightBox();
  }, [lightboxStore, location.pathname]);

  const isBreakDownMd = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 991px)",
  });

  const isBreakUpSm = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isBreakDownSm = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const handleMediaQueryChange = (matches?: any) => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 600);
  };

  const isPortrait = useMediaQuery(
    { orientation: "portrait" },
    undefined,
    handleMediaQueryChange
  );

  useEffect(() => {
    setNavOpen(!isBreakDownMd);
  }, [isBreakDownMd]);

  function cartSelected() {
    if (isBreakDownSm) {
      navUpdateMobile();
    }
    createOrderStore.toggleSlider();
    createOrderStore.setStepNumber(4);
  }

  function productsSelected() {
    if (isBreakDownSm) {
      navUpdate();
    }
    createOrderStore.setStepNumber(1);
    createOrderStore.toggleSlider();
  }

  function navUpdate() {
    setNavOpen(!navOpen);
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 600);
    return;
  }

  function navUpdateMobile() {
    setNavOpen(true);
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 600);
    return;
  }

  useEffect(() => {
    if (!clientStore.clientKey && !authStore.token) {
      authStore.logout();
      navigationStore.push("/home/login");
    }
  }, []);

  const numFormUpdates = clientStore.clientInfo.WebForms?.reduce(
    (acc: number, curr: any) => {
      if (curr.ActionRequired) {
        return acc + 1;
      }
      return acc;
    },
    0
  );

  return (
    <>
      <div className={`inner-page${navOpen ? ` nav-open` : ` nav-close`}`}>
        <header
          className="inner-header"
          style={{ height: isBreakUpSm ? innerHeight : "" }}
        >
          <nav className="nav-main">
            {authStore.logoUrl !== null ? (
              <Link
                to="/dashboard"
                className="branding-logo"
                onClick={() => isBreakDownSm && isPortrait && navUpdateMobile()}
              >
                {authStore.logoUrl ? (
                  <span
                    style={{ backgroundImage: `url(${authStore.logoUrl})` }}
                  >
                    <img
                      src={authStore.logoUrl}
                      alt={`${authStore.companyName} logo`}
                    />
                  </span>
                ) : (
                  <span className="d-none">{authStore.companyName}</span>
                )}
              </Link>
            ) : (
              <Link
                to="/dashboard"
                className="branding"
                onClick={() => isBreakDownSm && navUpdateMobile()}
              >
                {authStore.companyName}
              </Link>
            )}
            <Button
              color="link"
              className="nav-toggle"
              active={navOpen}
              onClick={navUpdate}
            >
              <span className="sr-only">
                {t("navBarPage.toggleNavigation")}
              </span>
              <span className="bar bar-1"></span>
              <span className="bar bar-2"></span>
              <span className="bar bar-3"></span>
            </Button>
            <span
              className="nav-cart-mobile"
              onClick={cartSelected}
              role="button"
            >
              <AiOutlineShoppingCart size="28" />
              <span className="sr-only">{t("navBarPage.cart")}</span>
              <Badge color="primary" pill>
                3
              </Badge>
            </span>

            <Nav vertical role="presentation">
              {authStore?.accountName !== "tpp" && (
                <NavItem>
                  <Link
                    to="/dashboard"
                    className={`nav-link ${
                      isActiveRoute("/dashboard") ? "active" : ""
                    }`}
                    title={!navOpen ? authStore.jobPlural : ""}
                    onClick={() => isBreakDownSm && navUpdate()}
                  >
                    <AiOutlinePicture size="20" />
                    <span className="nav-label">
                      <span className="label-title">Home</span>
                      <span className="label-desc">Go Home</span>
                    </span>
                  </Link>
                </NavItem>
              )}

              {/* JOY */}
              {authStore.accountName.toLowerCase() === "annegeddes" &&
                clientStore.clientInfo.StatusDescription !== "Free" && (
                  <NavItem>
                    <Link
                      to="/blog"
                      className={`nav-link ${
                        isActiveRoute("/blog") ? "active" : ""
                      }`}
                      title={!navOpen ? "Blog" : ""}
                      onClick={() => isBreakDownSm && navUpdate()}
                    >
                      <AiOutlineFileText size="20" />
                      <span className="nav-label">
                        <span className="label-title">
                          {t("navBarPage.blog")}
                        </span>
                        <span className="label-desc">
                          {t("navBarPage.blogSubtext")}
                        </span>
                      </span>
                    </Link>
                  </NavItem>
                )}

              {/* Blog */}
              {authStore.accountName.toLowerCase() === "annegeddes" &&
                clientStore.clientInfo.StatusDescription !== "Free" && (
                  <NavItem>
                    <Link
                      to="/joy"
                      className={`nav-link ${
                        isActiveRoute("/joy") ? "active" : ""
                      }`}
                      title={!navOpen ? "Joy" : ""}
                      onClick={() => isBreakDownSm && navUpdate()}
                    >
                      <AiOutlineSmile size="20" />
                      <span className="nav-label">
                        <span className="label-title">
                          {t("navBarPage.joy")}
                        </span>
                        <span className="label-desc">
                          {t("navBarPage.joySubtext")}
                        </span>
                      </span>
                    </Link>
                  </NavItem>
                )}

              <NavItem>
                <Link
                  to="/sessions-list"
                  className={`nav-link ${
                    isActiveRoute("/sessions") ||
                    isActiveRoute("/folders") ||
                    isActiveRoute("/favorites")
                      ? "active"
                      : ""
                  }`}
                  title={!navOpen ? authStore.jobPlural : ""}
                  onClick={() => isBreakDownSm && navUpdate()}
                >
                  <AiOutlinePicture size="20" />
                  <span className="nav-label">
                    <span className="label-title">{authStore.jobPlural}</span>
                    <span className="label-desc">
                      {t("navBarPage.photoshootsAndImages")}
                    </span>
                  </span>
                  {clientStore?.clientInfo?.SessionCount && (
                    <Badge color="primary" pill>
                      {clientStore.clientInfo.SessionCount}
                    </Badge>
                  )}
                </Link>
              </NavItem>

              {!!clientStore?.clientInfo?.ContractCount && (
                <NavItem>
                  <Link
                    to="/contracts"
                    className={`nav-link ${
                      isActiveRoute("/contracts") ? "active" : ""
                    }`}
                    title={!navOpen ? "Contracts" : ""}
                    onClick={() => isBreakDownSm && navUpdate()}
                  >
                    <AiOutlineFileDone size="20" />
                    <span className="nav-label">
                      <span className="label-title">
                        {t("navBarPage.contracts")}
                      </span>
                      <span className="label-desc">
                        {t("navBarPage.reviewSubmit")}
                      </span>
                    </span>
                    <Badge color="primary" pill>
                      {clientStore.clientInfo.ContractCount}
                    </Badge>
                  </Link>
                </NavItem>
              )}
              {!!clientStore?.clientInfo?.WebFormCount && (
                <NavItem>
                  <Link
                    to="/forms"
                    className={`nav-link ${
                      isActiveRoute("/forms") ? "active" : ""
                    }`}
                    title={!navOpen ? "Forms" : ""}
                    onClick={() => isBreakDownSm && navUpdate()}
                  >
                    <AiOutlineForm size="20" />
                    <span className="nav-label">
                      <span className="label-title">
                        {t("navBarPage.forms")}
                      </span>
                      <span className="label-desc">
                        {t("navBarPage.reviewForms")}
                      </span>
                    </span>

                    {numFormUpdates > 0 && (
                      <Badge color="primary" pill>
                        {/* {clientStore.clientInfo.WebFormCount} */}
                        {numFormUpdates}
                      </Badge>
                    )}
                  </Link>
                </NavItem>
              )}

              {authStore.accountName.toLowerCase() === "annegeddes" ? (
                <>
                  {!authStore.isGuestSession &&
                    !(clientStore.statusDescription === "Free") && (
                      <NavItem>
                        <Link
                          to="/orders"
                          className={`nav-link ${
                            isActiveRoute("/orders") ||
                            isActiveRoute("/orders/details")
                              ? "active"
                              : ""
                          }`}
                          title={!navOpen ? "Orders" : ""}
                          onClick={() => isBreakDownSm && navUpdate()}
                        >
                          <AiOutlineHistory size="20" />
                          <span className="nav-label">
                            <span className="label-title">
                              {t("navBarPage.orders")}
                            </span>
                            <span className="label-desc">
                              {t("navBarPage.orderHistory")}
                            </span>
                          </span>
                          {clientStore.clientInfo.OrderCount > 0 && (
                            <Badge color="primary" pill>
                              {clientStore.clientInfo.OrderCount}
                            </Badge>
                          )}
                        </Link>
                      </NavItem>
                    )}
                </>
              ) : (
                <>
                  {!authStore.isGuestSession &&
                    !(clientStore.statusDescription === "Free") && (
                      <NavItem>
                        <Link
                          to="/orders"
                          className={`nav-link ${
                            isActiveRoute("/orders") ||
                            isActiveRoute("/orders/details")
                              ? "active"
                              : ""
                          }`}
                          title={!navOpen ? "Orders" : ""}
                          onClick={() => isBreakDownSm && navUpdate()}
                        >
                          <AiOutlineHistory size="20" />
                          <span className="nav-label">
                            <span className="label-title">
                              {t("navBarPage.orders")}
                            </span>
                            <span className="label-desc">
                              {t("navBarPage.historyPrepaid")}
                            </span>
                          </span>
                          {clientStore.clientInfo.OrderCount > 0 && (
                            <Badge color="primary" pill>
                              {clientStore.clientInfo.OrderCount}
                            </Badge>
                          )}
                        </Link>
                      </NavItem>
                    )}
                </>
              )}
              {authStore.accountName.toLowerCase() === "annegeddes" ? (
                <>
                  <NavItem>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={productsSelected}
                      className={`nav-link ${
                        isActiveRoute("/products") ? "active" : ""
                      }`}
                      title={!navOpen ? "Products" : ""}
                    >
                      <AiOutlineShop size="20" />
                      <span className="nav-label">
                        <span className="label-title">
                          {t("navBarPage.ourStore")}
                        </span>
                        <span className="label-desc">
                          {t("navBarPage.OurStoreSubText")}
                        </span>
                      </span>
                    </span>
                  </NavItem>
                </>
              ) : (
                clientStore.clientKey && (
                  <>
                    <NavItem>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={productsSelected}
                        className={`nav-link ${
                          isActiveRoute("/products") ? "active" : ""
                        }`}
                        title={!navOpen ? "Products" : ""}
                      >
                        <AiOutlineShop size="20" />
                        <span className="nav-label">
                          <span className="label-title">
                            {t("navBarPage.products")}
                          </span>
                          <span className="label-desc">
                            {t("navBarPage.categoriesProducts")}
                          </span>
                        </span>
                      </span>
                    </NavItem>
                  </>
                )
              )}

              {!(clientStore.statusDescription === "Free") &&
                clientStore.clientKey && (
                  <>
                    <NavItem>
                      <Link
                        to="/guests"
                        className={`nav-link ${
                          isActiveRoute("/guests") ? "active" : ""
                        }`}
                        title={!navOpen ? "Guests" : ""}
                        onClick={() => isBreakDownSm && navUpdate()}
                      >
                        <AiOutlineTeam size="20" />
                        <span className="nav-label">
                          <span className="label-title">
                            {t("navBarPage.guests")}
                          </span>
                          <span className="label-desc">
                            {t("navBarPage.sharedFamily")} &amp;{" "}
                            {t("navBarPage.friends")}
                          </span>
                        </span>
                      </Link>
                    </NavItem>
                  </>
                )}

              {!authStore.isGuestSession && (
                <>
                  <NavItem>
                    <Link
                      to="/account"
                      className={`nav-link ${
                        isActiveRoute("/account") ? "active" : ""
                      }`}
                      title={!navOpen ? "Account" : ""}
                      onClick={() => isBreakDownSm && navUpdate()}
                    >
                      <AiOutlineUser size="20" />
                      <span className="nav-label">
                        <span className="label-title">
                          {t("navBarPage.myAccount")}
                        </span>
                        <span className="label-desc">
                          {t("navBarPage.login")} &amp;{" "}
                          {t("navBarPage.billingInfo")}
                        </span>
                      </span>
                    </Link>
                  </NavItem>
                </>
              )}

              <NavItem>
                <Link
                  to="/help"
                  className={`nav-link ${
                    isActiveRoute("/help") ? "active" : ""
                  }`}
                  title={!navOpen ? "Help" : ""}
                  onClick={() => isBreakDownSm && navUpdate()}
                >
                  <AiOutlineQuestionCircle size="20" />
                  <span className="nav-label">
                    <span className="label-title">{t("navBarPage.help")}</span>
                    <span className="label-desc">{t("navBarPage.chat")}</span>
                  </span>
                </Link>
              </NavItem>
              <NavItem className="nav-cart" onClick={cartSelected}>
                <span
                  style={{ cursor: "pointer" }}
                  className={`nav-link ${
                    location.pathname.includes("checkout") ? "active" : ""
                  }`}
                  title={!navOpen ? "Cart" : ""}
                >
                  <AiOutlineShoppingCart size="20" />
                  <span className="nav-label">
                    <span className="label-title">
                      {t("navBarPage.shoppingCart")}
                    </span>
                    <span className="label-desc">
                      {t("navBarPage.checkout")}
                    </span>
                  </span>
                  {!!ecomStore.cartItems.length && (
                    <Badge color="primary" pill>
                      {
                        ecomStore.cartItems.filter(
                          (item: any) => !item.PackageItemKey
                        ).length
                      }
                    </Badge>
                  )}
                </span>
              </NavItem>

              <NavItem
                style={{ cursor: "pointer" }}
                className="nav-signout mt-auto"
                onClick={() => authStore.logout()}
              >
                <span className="nav-link">
                  <AiOutlinePoweroff size="20" />
                  <span className="nav-label">
                    <span className="label-title">
                      {t("navBarPage.signout")}
                    </span>
                    {authStore.isGuestSession && (
                      <span className="label-desc">
                        {t("navBarPage.endGuest")}{" "}
                        {authStore.jobSingular.toLowerCase()}{" "}
                      </span>
                    )}
                    {!authStore.isGuestSession && (
                      <span className="label-desc">
                        {t("navBarPage.securelySignout")}
                      </span>
                    )}
                  </span>
                </span>
              </NavItem>
            </Nav>
          </nav>
        </header>

        <main className="inner-main">
          <div className="container-fluid">
            <div className="d-none d-print-block mt-5">
              <img
                src={
                  authStore.logoUrl !== null ? authStore.logoUrl : "/logo.png"
                }
                alt="logo"
              />
            </div>
            {children}
            <Lightbox />
          </div>
        </main>
      </div>
      {authStore.hydrated &&
        authStore.chatJs &&
        authStore.chatJs !== "" &&
        !isEmbed && <InnerHTML html={authStore.chatJs} />}
    </>
  );
};
export default inject(
  "clientStore",
  "sessionStore",
  "createOrderStore",
  "navigationStore",
  "authStore",
  "ecomStore",
  "lightboxStore"
)(observer(NavBarPage));
