import React from 'react';
import { Spinner } from 'reactstrap';

export interface ProgressSpinnerProps{
  showSpinner:boolean
  color?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
}


const ProgessSpinner: React.FC<any> = ({showSpinner, color = 'secondary', containerClass, ...props}) => {
  return (
    <>
      {showSpinner && 
        <section className = {`spinner-wrapper${containerClass ? ' ' + containerClass : ''}`} {...props}>
          <div className = "page-level">
            <Spinner 
              color = {color}
            /> 
          </div>
      </section>
    }
    </>

  );
}
export default ProgessSpinner;