import React from "react";


const UserAccessAgreement: React.FC<any> = () => {

    return (
    <>
<div id="right_col">
					<p>
 StudioPlus Software, LLC. (“StudioPlus”) operates certain  Services (including, but not limited to, StudioPlusSoftware.com and InspiredByYou.com),  to provide online access to information about StudioPlus and the products,  services, and opportunities we provide and to provide online proofing and  ordering services for our photographers (the “Service”). By accessing and using  the Services, You (“You” or “User”) agree to each of the terms and conditions  set forth herein (“Terms of Use”). Additional terms and conditions applicable  to specific areas or to particular content or transactions are also posted in  particular areas of the Services and, together with these Terms of Use, govern  your use of those areas, content or transactions. These Terms of Use  incorporate by reference the StudioPlus Privacy Statement (“Privacy  Statement”).&nbsp; These Terms of Use,  together with applicable additional terms and conditions, are referred to as  this “Agreement.” You must be at least 18 years of age to use the Services. By  using the Services, you certify that you are at least 18 years of age and that  you agree to be bound by these terms and conditions of use and you certify that  your use of this site is not in violation of any applicable Federal, State, or  local law or ordinance.&nbsp; If you do not  agree to be bound by these terms of use or if you are not at least 18 years of  age, please immediately exit the website and discontinue any use of the  Services. 

<strong>In consideration of StudioPlus  providing you with the Services, you agree to comply with these Terms of Use  (the "TOU"). </strong>If you do not agree to the terms and  conditions contained in the TOU, we do not consent to provide you with access  to the Services. </p>
                    <p>You are granted a revocable license to use the Services  according to the terms and conditions contained herein.
                      
                  StudioPlus reserves the right to modify this Agreement at any time without  prior notice. Your use of the Site following any such modification constitutes  your agreement to follow and be bound by the Agreement as modified. The last  date these Terms of Use were revised is set forth below.</p>
                    <p><strong>Users</strong>:&nbsp;  You may not print, republish, upload, post, transmit or distribute  content available through the site to online bulletin boards, message boards,  newsgroups, chat rooms, or in other any manner without our prior written  permission. Except for normal use by registered photographers who are paying to  use the site, modification of the content or use of the content for any  purpose, other than your own personal, noncommercial use is a violation of our  copyright and other proprietary rights, and can subject you to legal liability.
                      
You may not post or obtain any content using the web site which: is  threatening, obscene, pornographic or profane, or any other material that could  give rise to any civil or criminal liability under applicable law. While  StudioPlus does not and cannot review all content provided to it, and is not  responsible for such content, StudioPlus reserves the right to delete, edit or  rearrange content that it, in its sole discretion, deems abusive, defamatory,  obscene or in violation of copyright or trademark laws or otherwise  unacceptable, without notice or compensation or liability.&nbsp; You acknowledge that any content may be  removed, published, copied, modified, transmitted and displayed by StudioPlus  for the purposes of delivering the offered services. StudioPlus will not be  liable for any content provided to it, including the photographs and any content  added by account holders.&nbsp; 

In addition, in connection with your use of the site services, you agree not  to:                  </p>
                    <ul >
                     
                      <li >Restrict or inhibit  any other visitor from using the site services, including,  without limitation, by means of denial of service, "hacking" or  defacing any portion of any of our web sites;</li>
                      <li >Use the site services for any unlawful purpose; </li>
                        <li >Express or imply that any statements you make are endorsed        by us, without our prior written consent; </li>
                        <li >Modify, adapt, sublicense, translate, sell, reverse engineer,        decompile or disassemble any portion of the Site services or any of our        web sites; </li>
                        <li >"Frame" or "mirror" any content        available through the site services or any of our web sites without our        prior written authorization; </li>
                         <li >Use        any robot, spider, site search/retrieval application, or other manual or        automatic device or process to download, retrieve, index, "data        mine," or in any way reproduce or circumvent the navigational        structure or presentation of the content available through the site        services or any of our web sites; or </li>
                         <li >Harvest        or collect information about users of the site services without their        express consent.&nbsp;</li>
                  
                  </ul>
                    <p>You further agree that at no time will you, directly or  indirectly, engage in, assist, abet, encourage, facilitate or approve the  following:</p>
                    <ul >
                       <li >Upload       files that contain viruses, Trojan horses, worms, time bombs, cancelbots,       corrupted files, or any other similar software or programs that may damage       the operation of another's computer or property of another. </li>
                       <li >Download       any file posted by any photographer that you know, or reasonably should       know, cannot be legally reproduced, displayed, performed, and/or       distributed in such manner. </li>
                       <li >Falsify       or delete any copyright management information, such as author       attributions, legal or other proper notices or proprietary designations or       labels of the origin or source of software or other material contained in       a file that is uploaded. </li>
                       <li >Restrict       or inhibit any other user from using and enjoying the site services. </li>
                       <li >Violate       any code of conduct or other guidelines or applicable laws or regulations. </li>
                       <li >Create a false       identity for the purpose of misleading others. </li>
                       <li >Use, download or       otherwise copy, or provide (whether or not for a fee) to a person or       entity any directory of users of the Services or other user or usage information       or any portion thereof.</li>
                    </ul>
                    <p>While StudioPlus uses reasonable  efforts to ensure compliance with applicable laws relating to rights of privacy  or publicity, including the use of subject releases when necessary and  requiring the photographers to screen the photographs posted on the web site,  it makes no representations or warranties as to the accuracy, correctness or  reliability of the photographs, nor can StudioPlus ensure that all persons  depicted in the photographs have consented to the display of their image on  this web site. If your photograph appears on this web site without your consent  and you wish to have it removed, please copy the thumbnail image and e-mail it  to Info@StudioPlusSoftware.com  with your reason why you would like it removed.
                      You also agree to comply with all applicable laws, rules and regulations  in connection with your use of the Site services and the content made available  therein.</p>
<ol>
             <li > The User  may use the Service, the Site, and the information, writings, images and/or  other works that you see, hear or otherwise experience on the Site (singly or  collectively, the “Content”) solely for your non-commercial, personal purposes  and/or to learn about StudioPlus products and services. No right, title or  interest in any Content is transferred to you, whether as a result of  downloading such Content or otherwise. StudioPlus reserves complete title and  full intellectual property rights in all Content. Except as expressly  authorized by this Agreement, you may not use, alter, copy, distribute, transmit,  or derive another work from any Content obtained from the Site or the Service,  except as expressly permitted by the Terms of Use.
              
              You are solely responsible for all content you post on or submit to any forums  or message board contained on the site. You are aware and acknowledge that  StudioPlus has no obligation to monitor any forum or message board and you are  not relying upon StudioPlus to monitor the forums or message board.  Furthermore, neither StudioPlus nor the photographers are responsible or liable  to any person or entity whatsoever (including, without limitation, persons who  may use or rely on such data or materials or to whom such data or materials may  be furnished) for any loss, damage (whether actual, consequential, punitive or  otherwise), injury, claim, liability or other cause of any kind or character  whatsoever based upon or resulting from any information or opinions provided in  or through StudioPlus.&nbsp; Any forum or  message board shall be used only in a noncommercial manner.</li>
                       <li > StudioPlus  hereby claims all right, title and interest in the Content and this Site and  its affiliated Trademark, Tradename, Domain Name and the Content herein as  protected by U.S. and/or Foreign copyright, trademark, and other intellectual  property laws, and belong to StudioPlus or its partners, affiliates,  contributors or third parties. The copyrights in the Content are owned by  StudioPlus or other copyright owners who have authorized their use on the Site.  You acknowledge that you do not acquire any ownership  rights by using the site services.&nbsp; 
                        
                        You may not manipulate or alter in any way images or other Content on the Site  without specific permission from StudioPlus or the copyright owner.&nbsp; You are specifically prohibited from using  any of the marks or logos appearing throughout the Site without permission from  the trademark owner, except as permitted by applicable law.&nbsp; You are not  permitted to print any images or content from the site.&nbsp; 
                        
                        “StudioPlusSoftware.com,” "InspiredByYou.com," the StudioPlus Logo,  the InspiredByYou Logo, and other marks are trademarks and/or service marks of  StudioPlus. All other trademarks, service marks, and logos used on our web  sites are the trademarks, service marks, or logos of their respective owners.</li>
                       <li > Links on  the Site to third party web sites or information are provided solely as a  convenience to you. If you use these links, you will leave the Site. Such links  do not constitute or imply an endorsement, sponsorship, or recommendation by  StudioPlus of the third party, the third-party web site, or the information  contained therein. StudioPlus is not responsible for the availability of any  such web sites. StudioPlus is not responsible or liable for any such web site  or the content thereon. If you use the links to the web sites of StudioPlus  affiliates or service providers, you will leave the Site, and will be subject  to the terms of use and privacy policy applicable to those web sites.</li>
             <li > Any  software available for download via the Site is the copyrighted work of  StudioPlus and/or its licensors. Use of such software is governed by the terms  of the end user license agreement that accompanies or is included with the  software. Downloading, installing, and/or using any such software indicates  your acceptance of the terms of the end user license agreement.&nbsp; StudioPlus is not responsible nor liable for  any use or installation of such software.</li>
             <li >  StudioPlus is not responsible for any damage or inability to contact the  Site or the Service.&nbsp; StudioPlus uses  reasonable means to ensure that the Service is available 24 hours a day 7 days  a week. However, there will be occasions when the Service will be interrupted  for maintenance, upgrades and emergency repairs or due to failure of  telecommunications links and equipment that are beyond the control of  StudioPlus. You agree that StudioPlus shall not be liable to you for any  modification, suspension or discontinuance of the Service.</li>
             <li >  Photographers give permission to StudioPlus to create personal, password  protected websites (“Consumer Sites”) for the clients and customers of the  Photographer for the purpose of viewing, emailing, and purchasing posted  photographs.&nbsp; StudioPlus will host and  maintain said Consumer Sites containing images (“Images”) from the clients’ session.  You understand that StudioPlus is not responsible for allowing access to your  Customer Site beyond the express written terms contained herein.</li>
             <li > All of  the photographs featured on our Consumer Sites were created by photographers  who have contracted with StudioPlus to exhibit them for sale and distribution.  The copyrights in the photographs are owned by the photographers thereof who  have licensed to StudioPlus the right to exhibit them on the site. While  StudioPlus provides the opportunity for you to purchase photographs (“Product”)  from the Photographer, StudioPlus does not make any sale to you and is not  acting as a sale agent of Photographer.&nbsp;  NOTE THAT STUDIOPLUS IS ACTING MERELY FOR TRANSACTIONAL PURPOSES AND  DOES NOT COLLECT MONEY FROM YOU OR HANDLE ORDERS FOR PHOTOGRAPHER.&nbsp; StudioPlus may forward your order to  Photographer or to a Third Party on behalf of Photographer for your benefit and  convenience.&nbsp; At no time does StudioPlus  or any of its agents or assigns ever handle, collect or process any part of the  Product; and You agree not to hold StudioPlus liable for any faults, damages,  or issues regarding the Product.
              
            All items are shipped directly to you  by your photographer or their color lab and are subject to a shipment contract  under which the risk of loss and title for such items pass to you upon our  delivery to our shipping carrier. You may not reproduce, scan, display,  transmit, distribute or otherwise exploit the products, or any portion thereof,  in any manner, including, without limitation, print or electronic reproduction,  publication or any display of photographs, without the prior written consent of  your photographer. StudioPlus does not handle the order and does not control or  process the payment or fulfill the order for shipping.&nbsp; </li>
             <li > If you  register to become a StudioPlus member, you are responsible for maintaining the  confidentiality of your member identification and password information, and for  restricting access to your computer. You agree to accept responsibility for all  activities that occur under your member identification and password and to hold  StudioPlus harmless for any unauthorized use.&nbsp;&nbsp;  In order to visit and gain access to the site  services, you must use your email address to log in to any of our event or  portrait sites, which access is obtained by registration procedure. If the  information you provide in that registration procedure is untrue, inaccurate,  incomplete or outdated, or we have reasonable grounds to suspect it is, we  reserve the right to suspend or terminate your privileges without notice to  you, and to prohibit your current or future use of the site services. Our  policy with respect to the collection and use of your personal information is  set forth in our Privacy Policy.&nbsp; 
              
              As a visitor to our site, and by providing your e-mail address, you give  StudioPlus permission to contact you, via email, with information you  requested, with information on how to access your session to view and purchase  photographs, and on specials and future promotions from StudioPlus and its  partners, unless you notify us otherwise. Additionally, by providing your  e-mail address, you certify that you are 18 years of age or older.</li>
             <li > You, the  User, and all Photographers are responsible for all of the images uploaded,  shared or copied when using StudioPlus and therefore you must have the legal  right to copy and display each image that you upload. StudioPlus does not allow  photographers to upload and/or post any material that is obscene, offensive,  blasphemous, pornographic, threatening, menacing, abusive, harmful, an invasion  of privacy or publicity rights, defamatory, libelous, vulgar, illegal or  otherwise objectionable. Any image that violates child pornography laws, child  sexual exploitation laws and laws prohibiting the depiction of minors engaged  in sexual conduct will be removed and the proper authorities will be contacted.  StudioPlus does not actively monitor the content of members' accounts, however,  StudioPlus reserves the right to remove without notice any image, material or  content that StudioPlus deems, in its sole discretion, to be in violation of  any of the above-mentioned standards.
              
            If you are a client of an InspiredByYou.com  photographer and have a question or modification regarding your site, or you  wish to deactivate your event or portrait website, please ask your photographer  to contact StudioPlus. Please allow at least five business days for such  cancellation to become effective.&nbsp;  StudioPlus will, absent justifications provided herein, ordinarily not  make changes to a Photographer’s site without their authorization. </li>
             <li > You  understand that StudioPlus may prepare images for display on the Internet by  slightly reducing the resolution of images and digitally placing an indelible  “Proof” mark on the images indicating to the viewer that the photograph is  copyrighted material.&nbsp; Users are  hereinafter specifically prohibited from any reverse editing or subsequent use  of the edited photo.</li>
             <li >  StudioPlus will display the Photographer’s contact information on each  consumer website for the purpose of allowing those who visit each site to  contact the Photographer. If requested by photographer, StudioPlus will create  a link to Photographer’s email and/or website which will directly link back to  the Photographer’s studio website.&nbsp;  StudioPlus is not responsible for obtaining or identifying the  Photographer by any other means.</li>
             <li > You  understand that Photographer and StudioPlus are dealing with each other as  independent contractors, and there is no partnership, joint venture or agency  between Photographer and StudioPlus.&nbsp; You  agree that neither of the parties shall be bound or become liable because of  any representation, action or omission of the other.</li>
             <li > You understand  that Photographer is responsible for ensuring that StudioPlus has  Photographer’s current fee schedule for reprints and enlargements on sites.  User further understands that StudioPlus does not directly process Credit Card  payment but contracts with third parties who provide this service.&nbsp; StudioPlus is unable to answer questions or  reverse charges once made by Users, and any order inquiries or cancellations  must be made by the Photographer or its Third Party contractor.&nbsp; StudioPlus is not liable for any actions or  charges or extra handling or other fees that may be charged by your bank,  credit card company, or processing company on account of your purchase.&nbsp; 
              
              You acknowledge that StudioPlus has a no refund policy. Once an order is placed  either on the consumer site or through the StudioPlus admin area, the purchaser  agrees that all sales are final.</li>
             <li > In the  event of any damages or error that delays fulfillment of any order or request,  StudioPlus’ liability is limited to the fees that it is owed for that order  less any costs incurred by StudioPlus in the processing of that order.
              
              Your Photographer is responsible for fulfilling all  orders in a professional and timely manner. In no event will StudioPlus be  liable for any damages whatsoever, including, but not limited to any direct,  incidental, consequential, special, exemplary or other indirect damages arising  out of (i) the use of or inability to use the site, the service, or the  content, (ii) any transaction conducted through or facilitated by the site;  (iii) any claim attributable to errors, omissions, or other inaccuracies in the  site, the service and/or the content, (iv) unauthorized access to or alteration  of your transmissions or data, or (v) any other matter relating to the site,  the service, or the content, even if StudioPlus has been advised of the  possibility of such damages. If you are dissatisfied with the site, the  service, the content, or with the terms of use, your sole and exclusive remedy  is to discontinue using the site. 
              
            Because some states do not allow the exclusion or limitation of liability for  consequential or incidental damages, some of the above limitations may not  apply to you.&nbsp; <strong>STUDIOPLUS EXPRESSLY  DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTIES  OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TO THE GREATEST EXTENT  PERMITTED BY LAW.&nbsp; NEITHER STUDIOPLUS SOFTWARE LLC NOR ITS AFFILIATES,  SUPPLIERS, ADVERTISERS, AFFILIATES, OR AGENTS OR SPONSORS ARE RESPONSIBLE OR  LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,  PUNITIVE OR OTHER DAMAGES UNDER ANY CONTRACT, NEGLIGENCE, GROSS NEGLIGENCE,  STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE  SITE SERVICES AND/OR ANY CONTENT CONTAINED THEREIN, OR ANY PRODUCT OR SERVICE  USED OR PURCHASED THROUGH THE SITE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH  THE SITE SERVICES IS TO STOP USING IT. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY  TO COMPANY FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT,  TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE) SHALL BE THE  TOTAL AMOUNT PAID TO US BY YOU, IF ANY, FOR ACCESS TO THE SITE SERVICES. </strong>To the extent  allowed by applicable law, implied warranties on the Services, if any, are  limited to ninety (90) days. </li>
             <li > You  understand and agree that you are personally responsible for your behavior on  the Site. You agree to indemnify, defend and hold harmless StudioPlus, its  parent companies, subsidiaries, affiliated companies, joint ventures, business  partners, licensors, employees, agents, and any third-party information  providers to the Service from and against all claims, losses, expenses, damages  and costs (including, but not limited to, direct, incidental, consequential,  exemplary and indirect damages), and reasonable attorneys’ fees, resulting from  or arising out of your use, misuse, or inability to use the Site, the Service,  or the Content, or any violation by you of this Agreement. </li>
             <li >  Completing the login process obligates User to the terms and conditions  of this agreement. Access to continued posting services with StudioPlus assumes  continued compliance with this agreement. </li>
             <li > As noted  above, You are purchasing the Product from Your Photographer or its third-party  designee and not from StudioPlus, who merely forwards your order to your  Photographer or its third-party designee on your behalf and for Photographer’s  convenience.&nbsp; StudioPlus is not  responsible for any other services or demands regarding any other  responsibilities connected to Your purchase of the Product.</li>
             <li > Entire  Agreement/No Waiver. These Terms of Use constitute the entire agreement of the  parties with respect to the subject matter hereof. No waiver by StudioPlus of  any breach or default hereunder shall be deemed to be a waiver of any preceding  or subsequent breach or default.</li>
             <li >  Enforcement/ Choice of Law/ Choice of Forum. If any part of this  Agreement is determined by a court of competent jurisdiction to be invalid or  unenforceable, it will not impact any other provision of this Agreement, all of  which will remain in full force and effect. Any and all disputes relating to  this Agreement, your use of the Site, any other StudioPlus web site, the  Service, or the Content are governed by, and will be interpreted in accordance  with, the laws of the State of Minnesota, without regard to any conflict of  laws provisions.</li>
             <li > Changes  to Services.&nbsp; StudioPlus Software LLC may  at any time, and with no notice or with reasonable notice, change the site  services, including eliminating or discontinuing any content or feature of the  site services; or impose fees, charges or other conditions for use of the site  services.&nbsp; User acknowledges that such  may change and shall not rely on StudioPlus Software, LLC for any service or  feature or price.&nbsp; </li>
             <li >Inaccuracies.&nbsp;  A possibility exists that content available  through the Site services could include inaccuracies or errors, or materials  that violate the TOU. Additionally, a possibility exists that unauthorized  alterations could be made to the content available through the Site services by  third parties. Although we attempt to ensure the integrity of our web sites and  other products and services, we make no guarantees as to the completeness or  correctness of any content available through the Site services. In the event that  such a situation arises, please contact us at Info@StudioPlusSoftware.com with, if possible, a description of the material to be  checked and the location (URL) where such material can be found on our web  sites, if applicable, as well as information sufficient to enable us to contact  you. We will try to address your concerns as soon as reasonably practicable.</li>
             <li >  DMCA.&nbsp; The Digital Millennium  Copyright Act of 1998 (the "DMCA") provides recourse for copyright  owners who believe that material appearing on the Internet infringes their  rights under U.S. copyright law. If you believe in good faith that materials  hosted by StudioPlus Software, LLC or one of its subsidiaries infringe your  copyright, you (or your agent) may send us a notice requesting that the material  be removed, or access to it blocked. If you believe in good faith that a notice  of copyright infringement has been wrongly filed against you, the DMCA permits  you to send us a counter-notice. Notices and counter-notices must meet the  then-current statutory requirements imposed by the DMCA; see <a href="http://www.loc.gov/copyright/" target="_blank">www.loc.gov/copyright/</a> for details. Notices and counter-notices with  respect to our web sites should be sent to StudioPlus Software, LLC, 600  South Main Street, Cambridge, MN 55008 (763) 552-5500 Phone (763) 552-5502 Fax <a href="mailto:Info@StudioPlusSoftware.com" target="_new">Info@StudioPlusSoftware.com</a>.&nbsp; We suggest that you  consult your legal advisor before filing a notice or counter-notice. Also, be  aware that there can be penalties for false claims under the DMCA. Nothing  contained herein shall be construed as legal advice and it is not intended as  legal advice. </li>
             <li >  You understand and agree that StudioPlus may, under certain  circumstances and without prior notice to you, terminate your access to and use  of the site services. Cause for such termination may include, but not be  limited to, (i) breaches or violations of the TOU or other agreements or  guidelines, (ii) requests by law enforcement or other government or regulatory  authorities, or (iii) technical difficulties.</li>
            <li >The Service is directed solely to  individuals residing in the United States.&nbsp;  We make no representation that materials provided through the Service  are appropriate or available for use in other locations. Those who choose to  access the Service from other locations do so on their own initiative and at  their own risk, and are responsible for compliance with local laws, if and to  the extent applicable. We reserve the right to limit the availability of the  Service to any person, geographic area, or jurisdiction we so desire, at any  time and in our sole discretion, and to limit the quantities of any such  service or product that we provide. </li>
             
            </ol> 
           <p> <em>Last revised: December 07, 2015</em></p></div>

    </>
  );
};

export default UserAccessAgreement