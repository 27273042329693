import React from "react";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import OrganizationSearchForm, {
  OrganizationSearchFormData,
} from "../../components/forms/organizationSearch/OrganizationSearchForm";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import { postVariants } from "../../components/util";

const EventSearchPage: React.FC<any> = ({
  authStore,
  clientStore,
  navigationStore,
  eventStore,
}) => {
  const { t } = useTranslation();

  const redirectToWelcomeScreen = () => {
    const checkTrialDate = () => {
      let today = new Date().toISOString().split(".")[0];
      if (Date.parse(clientStore.statusDueDate) >= Date.parse(today)) {
        return true;
      } else {
        return false;
      }
    };

    const isTrialSubscription = checkTrialDate();

    //clientStore.getClientInfo();

    //Guest Free Account Flow

    if (
      clientStore.statusDescription === "Free" ||
      authStore?.accountName === "tpp"
    ) {
      navigationStore.push("/sessions");
      return;
    }

    /* Welcome Trial Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      isTrialSubscription
    ) {
      navigationStore.push("/subscription-trial");
      return;
    }

    /* Trial Expired Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      !isTrialSubscription
    ) {
      navigationStore.push("/subscription-expired");
      return;
    } else {
      navigationStore.push("/dashboard");
    }
  };

  async function onSubmit(obj: OrganizationSearchFormData, actions: any) {
    actions.setSubmitting(true);
    await authStore
      .studentLogin(obj)
      .then((res: any) => {
        if (res) {
          if (authStore.accountName.toLowerCase() === "annegeddes") {
            setTimeout(function () {
              redirectToWelcomeScreen();
            }, 1000);
          } else {
            redirectToWelcomeScreen();
          }
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  }

  return (
    <>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <h1>{t("OrganizationSearchPage.title")}</h1>
        <p>
          Already have an account?{" "}
          <Link to={"/home/login"}>{t("SignupPage.createAccountLink")}</Link> to
          view your photos.
        </p>
        <OrganizationSearchForm
          content={{
            search: t("OrganizationSearchPage.search"),
          }}
          onSubmit={onSubmit}
        />
      </motion.div>
      <Helmet>
        <title>
          {t("EventSearchPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "sessionStore",
  "eventStore",
  "clientStore"
)(observer(EventSearchPage));
