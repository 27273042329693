import React, { useState } from 'react';
import { observer,inject } from 'mobx-react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';


const ProductDetailTabs: React.FC<any> = ({ecomStore, ...props}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab:any) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
      <div {...props}>
        <Nav role="presentation" className="nav-tabs">
          {(!(ecomStore.selectedProduct.ProductDescription == null || ecomStore.selectedProduct.ProductDescription.trim() === '')) && 
          <NavItem>
            <NavLink
              className={ `${activeTab === '1'?'active':''}` }
              onClick={() => { toggle('1'); }}
            >
              {t('productDetailTabsComponent.description')}
            </NavLink>
          </NavItem>}
          {(!(ecomStore.selectedProduct.ProductFeatures == null || ecomStore.selectedProduct.ProductFeatures.trim() === '')) &&
          <NavItem>
            <NavLink
              className={ `${activeTab === '2'?'active':''}` }
              onClick={() => { toggle('2'); }}
            >
              {t('productDetailTabsComponent.features')}
            </NavLink>
          </NavItem>
          }
          {(!(ecomStore.selectedProduct.ProductDeliveryInfo == null || ecomStore.selectedProduct.ProductDeliveryInfo.trim() === '')) &&
          <NavItem>
            <NavLink
              className={ `${activeTab === '3'?'active':''}` }
              onClick={() => { toggle('3'); }}
            >
              {t('productDetailTabsComponent.delivery')}
            </NavLink>
          </NavItem>
          }
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <p dangerouslySetInnerHTML={{__html: ecomStore.selectedProduct.ProductDescription || ecomStore.selectedProduct.MarketingDescription }}></p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <p dangerouslySetInnerHTML={{__html: ecomStore.selectedProduct.ProductFeatures }}></p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <p dangerouslySetInnerHTML={{__html: ecomStore.selectedProduct.ProductDeliveryInfo }}></p>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
    </div>
  );
}
export default inject('ecomStore')(observer(ProductDetailTabs))