import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { observer,inject } from 'mobx-react'
import { 
  TabContent, 
  TabPane, 
  Nav, 
  NavItem, 
  NavLink,
  Row,
  Col,
  Badge
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive';
import * as Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';
import ProgressSpinner from '../../components/spinner/spinner';

import { Invoice } from '../../interfaces/invoice.int';

const OrderHistoryPage: React.FC<any> = ({authStore, ecomStore, navigationStore, history}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('');
  let location = useLocation();
  let scroller   = Scroll.scroller;
  let scroll     = Scroll.animateScroll;

  const isBreakDownSm = useMediaQuery({
    query: '(max-width: 767px)'
  })

  useEffect(()=>{
    setupData();

    if( location.hash === '#preorders' ) {
      setActiveTab('2');
    } else {
      setActiveTab('1');
    }

    if( location.state ) {
      const loc:any = location.state;
      scrollPosition(`invoice${loc?.scrollToInvoice}`);
    }else{
      scroll.scrollToTop({
        duration: 100
      });
    }

  },[])

  const scrollPosition = (ref:any) => {
    scroller.scrollTo(ref, {
      smooth: "easeInOutQuad",
      offset: isBreakDownSm ? -76 : 0,
    });
  }

  async function setupData(){
    await ecomStore.getAllInvoices();
    if (ecomStore?.preOrders?.length > 0) {
      setActiveTab('2');
    } else {
      setActiveTab('1');
    }
  }

  const toggle = (tab:any) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

function table(data:Array<Invoice>){
  return(
      <Table className="table table-cart-sm table-hover">
        <Thead>
          <Tr>
            <Th scope="col">{t('OrderHistoryPage.tableOrderNumber')}</Th>
            <Th scope="col">{t('OrderHistoryPage.tableOrderDate')}</Th>
            <Th scope="col">{t('OrderHistoryPage.tableShippedTo')}</Th>
            <Th scope="col">{t('OrderHistoryPage.tableStatus')}</Th>
            <Th scope="col">{t('OrderHistoryPage.tableDescription')}</Th>
            <Th scope="col">{t('OrderHistoryPage.tableOpenBalance')}</Th>
            <Th scope="col" className="text-right">{t('OrderHistoryPage.tableTotal')}</Th>
          </Tr>
        </Thead>
        <Tbody>
        {data?.map((inv:Invoice)=>(
          <Tr style={{cursor: 'pointer'}} onClick={() => history.push(`/orders/details?id=${inv.Key}`)} key = {inv.Key} name={`invoice${inv.InvoiceNumber}`}>
            <Td scope="row">
              <Link
              to = {`/orders/details?id=${inv.Key}`} 
              className="font-weight-bold">{inv.InvoiceNumber}</Link>
            </Td>
            <Td>
              {!!inv?.InvoiceDate &&
                <>{inv.InvoiceDate}</>
              }
            </Td>
            <Td>{inv.ShippingAddressName}</Td>
            <Td><span className="badge-status badge-light">{inv?.StatusDescription}</span></Td>
            <Td>{inv?.Description}</Td>
            {(inv.OpenBalance && inv.OpenBalance > 0) ? <Td>{inv?.OpenBalanceStringFormatted}</Td> : <Td></Td>}
            <Td className="text-right">{inv.TotalStringFormatted}</Td>
          </Tr>
          ))           
        }
        </Tbody>
      </Table>
  )
}

  return (
    <>
      <Helmet>
        <title>{activeTab === '1' ? 'Order History' : 'Pre-orders'} {authStore.companyName || authStore.companyName !== '' ? (
          '| ' + authStore.companyName
        ) : ' '}</title>
      </Helmet>
      <ProgressSpinner showSpinner = { ecomStore.activeInvoiceLoading } color="primary"/>
      {!ecomStore.activeInvoiceLoading && <section className="page-section">
        <Row className="align-items-center mb-5">
          <h1 className="col-sm-3 mb-md-0 text-truncate">{t('OrderHistoryPage.orders')}</h1>
          <Col sm="9">
            {!!ecomStore?.preOrders?.length &&
              <Nav role="presentation" className="nav-tabs justify-content-sm-end">
                <NavItem className="mb-sm-0">
                  <NavLink
                    className={ `${activeTab === '1'?'active':''}` }
                    onClick={() => { toggle('1'); navigationStore.push('#') }}
                  >
                    {t('OrderHistoryPage.orderHistory')}
                  </NavLink>
                </NavItem>
                <NavItem className="mb-sm-0">
                  <NavLink
                    className={ `${activeTab === '2'?'active':''}` }
                    onClick={() => { toggle('2'); navigationStore.push('#preorders') }}
                  >
                    {t('OrderHistoryPage.preOrders')}
                    <Badge color="primary" pill className="badge-sm ml-1">{ecomStore.preOrders.length}</Badge>
                  </NavLink>
                </NavItem>
              </Nav>
            }
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {ecomStore?.orderHistory.length > 0 ? table(ecomStore?.orderHistory) : <p>{t('OrderHistoryPage.noOrders')}</p>}
          </TabPane>
          {ecomStore?.preOrders?.length > 0 &&
            <TabPane tabId="2">
              {table(ecomStore?.preOrders)}
            </TabPane>
          }
        </TabContent>
      </section>}
      
    </>
  );
}

export default inject(
  'authStore',
  'ecomStore', 
  'navigationStore'
  )(withRouter(observer(OrderHistoryPage)));
