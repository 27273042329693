import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Row,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import CreatableSelect from 'react-select/creatable';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';


const AddImageToGroupForm: React.FC<any> = ({ imageGroupStore, onSuccess}) => {
    const { t } = useTranslation();
    const [asyncErr, setAsyncErr] = useState('');

    async function addToGroup(obj: any) {
        setAsyncErr('')
        const reqs = obj.groupNames.map(async (groupName:any)=>{
            return imageGroupStore.clientAddImageToGroup(imageGroupStore.addToGroup.fileName, groupName).catch((err:AxiosError)=>setAsyncErr(err.message))
        })
        await Promise.all(reqs);
        onSuccess();
    }
    
    const validationSchema =
        Yup.object().shape({
            groupNames: Yup.array()
            .of(Yup.string())
        })

    const initialValues = {
        groupNames:imageGroupStore.groupsForAddToGroupImage,
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={addToGroup}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                touched,
                isSubmitting
                /* and other goodies */
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="row-sm">
                        <FormGroup  className = "form-group-interactive col-12 ">
                        <Label htmlFor="groupName" className= "sr-only">{t('addImageToGroupFormComponent.selectFolder')}</Label>
                        <CreatableSelect
                            isMulti
                            id="groupName"
                            classNamePrefix="react-select-group"
                            defaultValue = {imageGroupStore.groupsForAddToGroupImage}
                            className={`react-select-group-container ${errors.groupNames && touched.groupNames ? ' is-invalid' : ''}`}
                            options = {imageGroupStore.imageGroupsFormattedForDropdown}
                            placeholder = {t('addImageToGroupFormComponent.selectFolder')}
                            onChange = {(val:any)=>{
                                setFieldValue('groupNames',val?.map((v:any)=>v.value) ||[])
                            }}
                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                            isDisabled = {isSubmitting}
                        />

                      <ErrorMessage name="groupName">{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                    </FormGroup>
                        </Row>
                        {asyncErr &&
                            <Alert color = "danger">{asyncErr}</Alert>
                        }
                        <Button 
                          type="submit" 
                          color="primary" 
                          block 
                          className={isSubmitting ? 'wait' : ''}
                          disabled={isSubmitting}
                        >
                          {t('editAddressFormComponent.applyChange')}
                          {isSubmitting && <Spinner color = '#fff' size="sm" /> }
                        </Button>
                    </Form>
                )}
        </Formik>
    );

}
export default inject('imageGroupStore')(observer(AddImageToGroupForm))
