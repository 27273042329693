import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { AiOutlineShop, AiOutlineContacts } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const HelpPage: React.FC<any> = ({
  sessionStore,
  authStore,
  loginPromptStore,
  navigationStore,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!authStore.chatUrl && authStore.chatUrl != "") {
      console.log(authStore.chatUrl);
      const chatScript = document.createElement("script");
      chatScript.innerHTML =
        'document.getElementById("openChat").addEventListener("click", e => { psdxx5ow(); e.preventDefault() } );';
      document.body.appendChild(chatScript);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("HelpPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section className="page-section">
        <h1 className="mb-5">{t("HelpPage.title")}</h1>

        {authStore.helpHtml ? (
          <div
            dangerouslySetInnerHTML={{ __html: authStore.helpHtml }}
            className="forceHtml"
          ></div>
        ) : (
          <div style={{ maxWidth: 700 }}>
            <div className="row">
              <div className="col-sm-5">
                <AiOutlineShop size="36" className="mb-2" />
                <h3 className="font-size-lg mb-2">{t("HelpPage.ourInfo")}</h3>
                <address>
                  {authStore.companyName}
                  {authStore.addLine1 && (
                    <>
                      <br />
                      {authStore.addLine1}
                    </>
                  )}
                  {authStore.addLine2 && (
                    <>
                      <br />
                      {authStore.addLine2}
                    </>
                  )}
                  {authStore.addLine3 && (
                    <>
                      <br />
                      {authStore.addLine3}
                    </>
                  )}
                </address>
              </div>

              <div className="col-sm-7 mt-4 mt-sm-0">
                <AiOutlineContacts size="36" className="mb-2" />
                <h3 className="font-size-lg mb-2">
                  {t("HelpPage.hereToHelp")}
                </h3>
                {authStore.phone && (
                  <div>
                    {`Give us a call at`}{" "}
                    <a href={`tel: ${authStore.phone}`}>{authStore.phone}</a>
                  </div>
                )}
                {authStore.email && (
                  <div>
                    {`Email us at`}{" "}
                    <a
                      href={`mailto: ${authStore.email}`}
                      title={authStore.email}
                    >
                      {authStore.email}
                    </a>
                  </div>
                )}
                {authStore.chatUrl && (
                  <div>
                    {t("HelpPage.chatBy")}{" "}
                    <a href={authStore.chatUrl} target="_blank" id="openChat">
                      {t("HelpPage.clickingHere")}
                    </a>
                  </div>
                )}
                {authStore.accountName.toLowerCase() === "annegeddes" && (
                  <div>
                    <a target="_blank" href="https://annegeddesbaby.com/help">
                      Click here{" "}
                    </a>
                    to read our FAQs
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default inject(
  "sessionStore",
  "authStore",
  "loginPromptStore",
  "navigationStore"
)(observer(HelpPage));
