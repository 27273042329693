import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Label,
  Button,
  Row,
  FormFeedback,
  Alert,
  CustomInput,
  Spinner,
} from "reactstrap";
import { observer, inject } from "mobx-react";
import { AxiosError } from "axios";
import InputFloat from "../inputFloat";
import { useTranslation } from "react-i18next";
import { SessionAbstract, Session } from "../../../interfaces/session.int";

const LinkGuestForm: React.FC<any> = ({
  guestStore,
  authStore,
  clientStore,
  sessionStore,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");

  async function updateClient(obj: any) {
    let update = {
      ...obj,
    };
    delete update.onlyFavorites;
    await guestStore
      .setGuest(update)
      .catch((err: AxiosError) => setAsyncErr(err.message));
    onSuccess();
  }

  const validationSchema = Yup.object().shape({
    Description: Yup.string().required(
      t("linkGuestFormComponent.enterDescription")
    ),
  });

  const initialValues = {
    Description: "",
    SessionKey: "all",
    ImageGroup: "all",
    onlyFavorites: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-12">
              <Label className="sr-only">
                {t("linkGuestFormComponent.description")}
              </Label>
              <Field
                label={t("linkGuestFormComponent.description")}
                name="Description"
                className={`form-control ${
                  errors.Description ? "is-invalid" : ""
                }`}
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Description">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                as="select"
                label={t("emailGuestFormComponent.session")}
                name="SessionKey"
                className={`form-control`}
                disabled={isSubmitting}
                onChange={(e: any) =>
                  setFieldValue("SessionKey", e.target.value)
                }
              >
                <option value="all">{`All ${authStore.jobPlural}`}</option>
                {clientStore.clientInfo.Sessions.map(
                  (session: SessionAbstract) => (
                    <option value={session.SessionKey} key={session.SessionKey}>
                      {session.SessionDescription || session.SessionKey}
                    </option>
                  )
                )}
              </Field>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                as="select"
                label={t("emailGuestFormComponent.image")}
                name="ImageGroup"
                className={`form-control`}
                disabled={isSubmitting}
                onChange={(e: any) =>
                  setFieldValue("ImageGroup", e.target.value)
                }
              >
                <option value="all">All Images</option>
                {values.SessionKey === "all" && (
                  <option value="favorites">Favorites</option>
                )}
                {values.SessionKey !== "all" &&
                  sessionStore.allSessionDetail
                    .filter(
                      (session: Session) => session.Key === values.SessionKey
                    )[0]
                    .Groups.map((group: any) => {
                      if (group.GroupName.toLowerCase() === "hidden") {
                        return <></>;
                      }
                      return (
                        <option value={group.GroupName} key={group.GroupName}>
                          {group.GroupName}
                        </option>
                      );
                    })}
              </Field>
            </FormGroup>
            {initialValues.onlyFavorites && (
              <FormGroup className="col-sm-12">
                <CustomInput
                  type="checkbox"
                  id="com13"
                  label={`${t(
                    "linkGuestFormComponent.onlyIncludeFavoritesFromThis"
                  )} ${authStore.jobSingular.toLowerCase()}`}
                  checked={values.onlyFavorites}
                  onChange={(e: any) =>
                    setFieldValue("onlyFavorites", e.target.checked)
                  }
                  disabled={isSubmitting}
                />
              </FormGroup>
            )}
          </Row>
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {t("linkGuestFormComponent.createLink")}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject(
  "guestStore",
  "authStore",
  "clientStore",
  "sessionStore"
)(observer(LinkGuestForm));
