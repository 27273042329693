import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
//   useLocation,
  Redirect
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import ProtectedRoute from "../components/protected-route/protected-route";
// import BillingAddressPage from "../pages/checkout/billingAddress";
import ConfirmationPage from "../pages/checkout/confirmation";
import OrderReviewPage from "../pages/checkout/orderReview";
import PaymentPage from "../pages/checkout/payment";
import ShippingAddressPage from "../pages/checkout/shippingAddress";

const CheckoutRouter: React.FC<any> = (props) => {
 const match = useRouteMatch();
//  const location = useLocation();
  return (
      <AnimatePresence exitBeforeEnter>
        <Switch>
          {/* <ProtectedRoute exact path={`${match.path}/billingaddress`} component = { BillingAddressPage } /> */}
          <ProtectedRoute exact path={`${match.path}/confirmation`} component = { ConfirmationPage } navbar={false} />
          <ProtectedRoute exact path={`${match.path}/orderreview`} component = { OrderReviewPage } navbar={false} />
          <ProtectedRoute exact path={`${match.path}/payment`} component = { PaymentPage } navbar={false} />
          <ProtectedRoute exact path={`${match.path}/shippingaddress`} component = { ShippingAddressPage } navbar={false} />
          <Route>
            <Redirect to = {`${match.path}/shippingaddress`} />
          </Route>
        </Switch>
      </AnimatePresence>
  );
}

export default CheckoutRouter;