import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormGroup, Button, Spinner } from "reactstrap";
import RadioSection from "./radioSection";
import InputSection from "./inputSection";
import { ReactSelectOption } from "../../../interfaces";
import { useTranslation } from "react-i18next";

interface ChildProps {
  onSubmit: (obj: EventSearchFormData, actions: any) => void;
  content: any;
  publicEvents: Array<ReactSelectOption>;
}

export interface EventSearchFormData {
  search_by: "date" | "code" | "publicCode";
  date: string;
  code: string;
  publicCode: any;
  visibleDate: string;
  code_by_date: string;
}

const EventSearchForm: React.FC<ChildProps> = ({
  onSubmit,
  content,
  publicEvents,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    date: Yup.date(),
    search_by: Yup.string(),
    code_by_date: Yup.string().when("search_by", {
      is: "date",
      then: Yup.string().required(t("eventSearchFormComponent.selectEvent")),
    }),
    visibleDate: Yup.string().when("search_by", {
      is: "date",
      then: Yup.string().required(
        t("eventSearchFormComponent.selectEventDate")
      ),
    }),
    publicCode: Yup.string().when("search_by", {
      is: "publicCode",
      then: Yup.string().required(t("eventSearchFormComponent.selectEvent")),
    }),
    code: Yup.string().when("search_by", {
      is: "code",
      then: Yup.string().required(t("eventSearchFormComponent.enterCode")),
    }),
  });

  const initialValues: EventSearchFormData = {
    search_by: "date",
    date: "",
    visibleDate: "",
    code: "",
    publicCode: "",
    code_by_date: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form>
          <FormGroup>
            <RadioSection
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              content={content}
            />
          </FormGroup>
          <FormGroup className="form-group-icon">
            <InputSection
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              content={content}
              publicEvents={publicEvents}
              disabled={isSubmitting}
            />
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {content.search}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EventSearchForm;
