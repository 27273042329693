import {observable, action, computed} from 'mobx';
import SessionStore from './sessionStore';
// import { SessionImageListItem } from '../interfaces/session.int';

class LightboxStoreClass {

	@observable showLightbox:boolean = false;
	@observable playLightbox:boolean = false;
	@observable activeIndex:number = 0;
	// @observable lightboxPhotos = [];

	@computed get lightboxPhotos() {
		return SessionStore.activeSessionImages?.map((img:any)=>({
			original: img.preview,
			thumbnail: img.thumbnail,
			thumbnailClass: 'custom-thumbnail',
	originalClass: 'custom-original',
	description: 'Image ' + img.imageNumber
			})) || []
	}

	// @computed get formattedLightboxPhotos() {
	// 	return this.lightboxPhotos?.map((img:any)=>({
	// 			original: img.preview,
	// 			thumbnail: img.thumbnail,
	// 			thumbnailClass: 'custom-thumbnail',
    //     originalClass: 'custom-original',
    //     description: 'Image ' + img.imageNumber
	// 			})) || []
	// }

	@action async openLightboxAtIndex(index:number) {
    document.body.classList.add('modal-open');
    document.documentElement.classList.add('modal-open');
		this.activeIndex = index;
		this.showLightbox = true;
	}

	@action async closeLightBox() {
		document.body.classList.remove('modal-open');
    document.documentElement.classList.remove('modal-open');
		this.showLightbox = false;
	}

	@action async openPlayLightbox() {
    document.body.classList.add('modal-open');
    document.documentElement.classList.add('modal-open');
		this.playLightbox = true;
		this.showLightbox = true;
	}

}

const LightboxStore = new LightboxStoreClass();
export default LightboxStore;