import React, { useState, useEffect } from "react";

import { observer, inject } from "mobx-react";
import { useLocation } from "react-router-dom";

import "../subscriptionTrial/subscription.scss";
import InnerHTML from "dangerously-set-html-content";
import Modal from "reactstrap/lib/Modal";
import {
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const SubscriptionExpired: React.FC<any> = ({
  authStore,
  sessionStore,
  clientStore,
  guestStore,
  history,
}) => {
  const { t } = useTranslation();
  const location = useLocation().search;
  const [errorMessage, setErrorMessage] = useState("");
  const [TrialExpiredScreenModal, setTrialExpiredScreenModal] = useState(true);

  useEffect(() => {
    // Check for paymentStatus in url: usercanceled, approved, other
    const splitParams = location.substring(1).split("&");
    let paymentStatus = "";

    for (let param of splitParams) {
      if (param.includes("paymentStatus")) {
        paymentStatus = param.split("=")[1];
      }
      if (param.includes("bearer")) {
        // Set axios bearer token
        const bearer = param.split("=")[1];
        authStore.signup(
          authStore.signupResponse.SubscriptionPlanMaintKey,
          bearer
        );
        // axios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
        return;
      }
    }
    if (!paymentStatus) return;
    if (paymentStatus === "usercanceled") {
      setErrorMessage("You did not complete the signup process");
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    } else {
      // Something went wrong alert
      setErrorMessage("Something went wrong. Please try again");
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    }
  }, []);

  const handleSubscribeClick = async () => {
    await authStore.signup(authStore.SubscriptionPlanMaintKey);
  };

  return (
    <>
      <Modal
        isOpen={TrialExpiredScreenModal}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="lg"
        className="subscription-modal"
      >
        {errorMessage.length > 0 && (
          <Alert
            style={{
              position: "absolute",
              top: "25px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            color="danger"
          >
            {errorMessage}
          </Alert>
        )}
        <div className="subscription-popup">
          <ModalHeader className="header">Trial Expired</ModalHeader>
          <ModalBody>
            {authStore.signupResponse.ConfirmationHtml ? (
              <>
                <div className="info-graphics-image">
                  <img src="images/Baby_Background_Vignette.jpeg" alt="" />
                </div>
                <div className="welcome-text">
                  <p>
                    <InnerHTML
                      html={authStore.signupResponse.ConfirmationHtml}
                    />
                  </p>
                </div>
                <Row>
                  <Col>
                    <Button
                      color="primary text-truncate"
                      className="w-100"
                      onClick={() => {
                        setTrialExpiredScreenModal(false);
                      }}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <div className="info-graphics-image">
                  <img src="images/Baby_Background_Vignette.jpeg" alt="" />
                </div>

                <div className="welcome-text">
                  <p>
                    Welcome to the Anne Geddes App. Your free trial has
                    currently expired.
                  </p>
                </div>

                <div className="welcome-sub-text">
                  <p>
                    <p>{t("SubscriptionPage.welcomeText")}</p>
                  </p>
                  <p>
                    <p>{t("SubscriptionPage.welcomeSubtext")}</p>
                  </p>
                </div>

                <div className="subscription-box">
                  <div className="subscription-text">
                    {authStore.monthlySubscriptionAmountFormatted}/month
                  </div>
                </div>
                <Row>
                  <Col>
                    <Button
                      color="primary text-truncate"
                      className="w-100"
                      onClick={handleSubscribeClick}
                    >
                      {authStore.isLoading ? (
                        <Spinner color="light" />
                      ) : (
                        "Subscribe Now"
                      )}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default inject(
  "authStore",
  "sessionStore",
  "clientStore",
  "guestStore"
)(observer(SubscriptionExpired));
