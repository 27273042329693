import React from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import PrivacyStatementPage from "../components/legal/privacyPage";
import TermsOfUsePage from "../components/legal/termsOfUsePage";
const PrivacyPageRouter: React.FC<any> = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route
          exact
          path={`${match.path}/privacy-policy`}
          component={PrivacyStatementPage}
        />
        <Route
          exact
          path={`${match.path}/privacy-policy`}
          component={PrivacyStatementPage}
        />
        <Route
          exact
          path={`${match.path}/terms-of-use`}
          component={TermsOfUsePage}
        />
      </Switch>
    </AnimatePresence>
    
  );
};

export default PrivacyPageRouter;
