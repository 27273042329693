import {observable, action} from 'mobx';

// controls state of global login prompt
class LoginPromptClass {
    
    @observable showLoginPrompt = false;

    @action togglePrompt() {
        this.showLoginPrompt = !this.showLoginPrompt;
    }    

    @action setShowLoginPrompt(stateIn:boolean){
        this.showLoginPrompt = stateIn;
    }
}
const LoginPromptStore = new LoginPromptClass();
export default LoginPromptStore;