import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { observer, inject } from "mobx-react";
import * as Yup from "yup";
import { FormGroup, Label, FormFeedback, Button, Spinner } from "reactstrap";
import InputFloat from "../inputFloat";
import { password } from "../../util";
import { useTranslation } from "react-i18next";

interface ChildProps {
  onSubmit: (obj: SignupFormData) => void;
  FirstNameLabel: string;
  lastNameLabel: string;
  emailLabel: string;
  passwordLabel: string;
  ajaxError: string;
  ajaxErrorOther: string;
}

export interface SignupFormData {
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  PhoneNumber: string;
  OnlinePassword: string;
  confirm_password: string;
  AllowEmailMarketing: boolean;
  AllowTextMarketing: boolean;
  AllowTextConfirmation: boolean;
}

const AccountVerificationForm: React.FC<any> = ({
  onSubmit,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  country,
  passwordLabel,
  ajaxError,
  ajaxErrorOther,
  clientStore,
  authStore,
}) => {
  const { t } = useTranslation();

  const initialValues: any = {
    FirstName: clientStore.clientInfo.FirstName || "",
    LastName: clientStore.clientInfo.LastName || "",
    EmailAddress: clientStore.clientInfo.EmailAddress || "",
    PhoneNumber0: clientStore.clientInfo.PhoneNumber0 || "",
    OnlinePassword: "",
    confirm_password: "",
    AllowEmailMarketing: clientStore.clientInfo.AllowEmailMarketing || false,
    AllowTextMarketing: clientStore.clientInfo.AllowTextMarketing || false,
    AllowTextConfirmation: false,
    SMSTextable0: clientStore.clientInfo.SMSTextable0 || false,
    showName: authStore.VerificationPageDisplayName,
    showEmail: authStore.VerificationPageDisplayMainEmail,
    showPassword: authStore.VerificationPageDisplayNewPassword,
    showConfirmPassword: authStore.VerificationPageDisplayNewPassword,
    // showPassword: false,
    showPhone: authStore.VerificationPageDisplayMainPhone,
  };
  const validationSchema = Yup.object().shape({
    showName: Yup.boolean(),
    showEmail: Yup.boolean(),
    showPassword: Yup.boolean(),
    showConfirmPassword: Yup.boolean(),
    showPhone: Yup.boolean(),
    FirstName: Yup.string().when("showName", {
      is: true,
      then: Yup.string().required(t("signupFormComponent.firstNameValidation")),
    }),
    LastName: Yup.string().when("showName", {
      is: true,
      then: Yup.string().required(t("signupFormComponent.lastNameValidation")),
    }),
    EmailAddress: Yup.string().when("showEmail", {
      is: true,
      then: Yup.string() // TODO add regex for password
        .email(t("signupFormComponent.invalidEmailValidation"))
        .required(t("signupFormComponent.emailRequiredValidation")),
    }),

    PhoneNumber0: Yup.string().when("showPhone", {
      is: true,
      then: Yup.string().required(t("Please enter a phone number")),
    }),
    // TODO add regex for password

    OnlinePassword: Yup.string().when("showPassword", {
      is: true,
      then: password().required(t("Please enter a password")),
    }),
    confirm_password: Yup.string().when("showConfirmPassword", {
      is: true,
      then: Yup.string()
        .required(t("signupFormComponent.confirmPasswordValidation"))
        .test(
          "passwords-match",
          t("signupFormComponent.passwordsMustMatch"),
          function (value) {
            return this.parent.OnlinePassword === value;
          }
        ),
    }),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          {ajaxError && (
            <div className="alert alert-danger" role="alert">
              {ajaxError}
            </div>
          )}

          {ajaxErrorOther && (
            <div className="alert alert-danger" role="alert">
              {ajaxErrorOther}
            </div>
          )}
          {authStore.VerificationPageDisplayName && (
            <>
              <FormGroup className="form-group-icon mb-4">
                <Field
                  className={`form-control ${
                    errors.FirstName && touched.FirstName ? "is-invalid" : ""
                  }`}
                  type="text"
                  name="FirstName"
                  id="FirstName_input"
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.FirstName}
                  component={InputFloat}
                />
                <ErrorMessage name="FirstName">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup className="form-group-icon mb-4">
                <Field
                  className={`form-control ${
                    errors.LastName && touched.LastName ? "is-invalid" : ""
                  }`}
                  type="text"
                  name="LastName"
                  id="LastName_input"
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.LastName}
                  component={InputFloat}
                />
                <ErrorMessage name="LastName">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            </>
          )}

          {authStore.VerificationPageDisplayMainEmail && (
            <>
              <FormGroup className="form-group-icon">
                <Field
                  className={`form-control ${
                    errors.EmailAddress && touched.EmailAddress
                      ? "is-invalid"
                      : ""
                  }`}
                  type="email"
                  name="EmailAddress"
                  label="Email"
                  id="email_input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.EmailAddress}
                  component={InputFloat}
                />
                <ErrorMessage name="EmailAddress">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup className="form-group-icon">
                <Field
                  className={`ml-3 ${
                    errors.AllowEmailMarketing && touched.AllowEmailMarketing
                      ? "is-invalid"
                      : ""
                  }`}
                  checked={values.AllowEmailMarketing}
                  type="checkbox"
                  name="AllowEmailMarketing"
                  onPress={() =>
                    setFieldValue(
                      "AllowEmailMarketing",
                      !values.AllowEmailMarketing
                    )
                  }
                  value={values.AllowEmailMarketing}
                />
                <Label className="ml-3">
                  May we email you with promotional information?
                </Label>
                <ErrorMessage name="AllowEmailMarketing">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            </>
          )}
          {authStore.VerificationPageDisplayMainPhone && (
            <>
              <FormGroup className="form-group-icon">
                <Label
                  htmlFor="phoneNumber_input"
                  className={`${
                    errors.PhoneNumber0 && touched.PhoneNumber0 ? "error " : ""
                  }sr-only`}
                >
                  Phone Number
                </Label>
                <Field
                  className={`form-control ${
                    errors.PhoneNumber0 && touched.PhoneNumber0
                      ? "is-invalid"
                      : ""
                  }`}
                  type="phoneNumber"
                  name="PhoneNumber0"
                  id="phoneNumber_input"
                  label="Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.PhoneNumber0}
                  component={InputFloat}
                />
                <ErrorMessage name="PhoneNumber">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup
                className="form-group-icon"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Field
                  className={`ml-3 ${
                    errors.SMSTextable0 && touched.SMSTextable0
                      ? "is-invalid"
                      : ""
                  }`}
                  checked={values.SMSTextable0}
                  type="checkbox"
                  name="SMSTextable0"
                  onPress={() =>
                    setFieldValue("SMSTextable0", !values.SMSTextable0)
                  }
                  value={values.SMSTextable0}
                />
                <Label className="ml-3 mb-0">
                  May we text you with important information regarding your
                  transactions and confirmations?
                </Label>
                <ErrorMessage name="SMSTextable0">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>

              <FormGroup className="form-group-icon">
                <Field
                  className={`ml-3 ${
                    errors.AllowTextMarketing && touched.AllowTextMarketing
                      ? "is-invalid"
                      : ""
                  }`}
                  checked={values.AllowTextMarketing}
                  type="checkbox"
                  name="AllowTextMarketing"
                  onPress={() =>
                    setFieldValue(
                      "AllowTextMarketing",
                      !values.AllowTextMarketing
                    )
                  }
                  value={values.AllowTextMarketing}
                />
                <Label className="ml-3">
                  May we text you with promotional information?
                </Label>
                <ErrorMessage name="AllowTextMarketing">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
                <Label className="mb-4 ml-5">
                  (Messaging and data rates may apply)
                </Label>
              </FormGroup>
            </>
          )}

          {authStore.VerificationPageDisplayNewPassword && (
            <>
              {" "}
              <FormGroup className="form-group-icon mt-1">
                <Label
                  htmlFor="password_input"
                  className={`${
                    errors.OnlinePassword && touched.OnlinePassword
                      ? "error "
                      : ""
                  }sr-only`}
                >
                  {passwordLabel}
                </Label>
                <Field
                  id="password_input"
                  className={`form-control ${
                    errors.OnlinePassword && touched.OnlinePassword
                      ? "is-invalid"
                      : ""
                  }`}
                  type="password"
                  name="OnlinePassword"
                  placeholder={"Create New Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.OnlinePassword}
                />
                <ErrorMessage name="OnlinePassword">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
              <FormGroup className="form-group-icon">
                <Label
                  htmlFor="confirm_password_input"
                  className={`${
                    errors.confirm_password && touched.confirm_password
                      ? "error "
                      : ""
                  }sr-only`}
                >
                  {t("signupFormComponent.confirm")} {passwordLabel}
                </Label>
                <Field
                  id="confirm_password_input"
                  className={`form-control ${
                    errors.confirm_password && touched.confirm_password
                      ? "is-invalid"
                      : ""
                  }`}
                  type="password"
                  name="confirm_password"
                  placeholder={t("signupFormComponent.confirmPassword")}
                />
                <ErrorMessage name="confirm_password">
                  {(msg) => <FormFeedback>{msg}</FormFeedback>}
                </ErrorMessage>
              </FormGroup>
            </>
          )}

          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {`Verify Account`}{" "}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default inject(
  "clientStore",
  "authStore"
)(observer(AccountVerificationForm));
