import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import update from "immutability-helper";

import ClientStore from "./clientStore";
import EcomStore from "./ecomStore";
import SessionStore from "./sessionStore";

import { Session, SessionImageListItem } from "../interfaces/session.int";

import {
  Composite,
  CompositeTemplateRecipe,
} from "../interfaces/composite.int";

class CompositeStoreClass {
  @persist("object") @observable compositeCartItem: any = {};
  @persist("object") @observable activeComposite: any = {};
  @persist("object") @observable activeRecipe: any = {};
  @persist("object") @observable activeRecipeNewLayers: any = [];
  @persist("object") @observable activeRecipeLayer: any = {};
  @persist("object") @observable activeRecipeLayerIndex: any = null;
  @persist("object") @observable activeImageCropper: any = [];
  @persist @observable firstVisit: boolean = true;

  @observable canvasRatio: number = 0.5;
  @observable activeLines: boolean = true;
  @observable loading: boolean = false;
  @observable saving: boolean = false;
  @observable reset: boolean = false;
  @observable error: boolean = false;

  @action async getCompositeByKey(key?: string) {
    this.activeComposite = {};
    this.loading = true;
    return await axios({
      method: "GET",
      url: `/IBYComposite`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        id: key,
      },
    })
      .then((res: AxiosResponse) => {
        this.activeComposite = res.data;
        this.activeRecipe = res.data.Recipe;
        this.activeRecipeNewLayers = res.data.Recipe.Layers;
        this.activeImageCropper = res.data.Recipe.Layers.map(() => {
          return { zoom: null };
        });
        setTimeout(() => {
          this.loading = false;
        }, 500);
        //console.log('Get Composite Key');
        //console.log(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        this.error = true;
      });
  }

  @action async getCompositeTemplate(key?: string, Filename: string = "") {
    this.activeComposite = {};
    this.loading = true;
    return await axios({
      method: "GET",
      url: `/IBYCompositeTemplate`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        id: key,
      },
    })
      .then((res: AxiosResponse) => {
        this.activeComposite = res.data;
        this.activeRecipe = res.data.Recipe;
        this.activeRecipeNewLayers = res.data.Recipe.Layers;
        if (Filename !== "") {
          const index = this.activeRecipeNewLayers.findIndex(
            (layer: any) => layer?.Type === "zone"
          );
          this.activeRecipeNewLayers[index] = {
            ...this.activeRecipeNewLayers[index],
            ZoneImage: {
              ...this.activeRecipeNewLayers[index].ZoneImage,
              Filename: Filename,
              Width: 300,
              Height: 300,
              X: 100,
              Y: 100,
            },
          };
          // this.activeRecipeNewLayers = this.activeRecipeNewLayers.map(
          //   (layer: any) => {
          //     if (layer?.Type === "zone") {
          //       return {
          //         ...layer,
          //         ZoneImage: {
          //           ...layer.ZoneImage,
          //           Filename: Filename,
          //           Width: 300,
          //           Height: 300,
          //         },
          //       };
          //     }
          //     return layer;
          //   }
          // );
        }

        console.log(this.activeRecipeNewLayers);

        this.activeImageCropper = res.data.Recipe.Layers.map(() => {
          return { zoom: null };
        });
        setTimeout(() => {
          this.loading = false;
        }, 500);
        //console.log('Get Template Key');
        //console.log(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        this.error = true;
      });
  }

  @action async saveCompositeSession(
    composite?: Composite,
    layers?: any,
    sessionKey?: string,
    isInvoice: boolean = false
  ) {
    const activeComposite: Composite = composite
      ? composite
      : this.activeComposite;
    const cartSessionKey: any = sessionKey
      ? sessionKey
      : EcomStore.cart.SessionKey;
    const activeLayers: any = layers ? layers : this.activeRecipeNewLayers;
    const activeCompositeKey: any = activeComposite?.TemplateKey
      ? activeComposite?.Key
      : null;
    const activeCompositeTemplateKey: any = activeComposite?.TemplateKey
      ? activeComposite?.TemplateKey
      : activeComposite?.Key;

    let mergedComposite: any = activeCompositeKey
      ? {
          Key: activeCompositeKey,
          TemplateKey: activeCompositeTemplateKey,
          SessionKey: cartSessionKey,
          ImageListItem: this.compositeCartItem,
          Recipe: {
            ...this.activeRecipe,
            Layers: activeLayers,
          },
        }
      : {
          TemplateKey: activeCompositeTemplateKey,
          SessionKey: cartSessionKey,
          ImageListItem: this.compositeCartItem,
          Recipe: {
            ...this.activeRecipe,
            Layers: activeLayers,
          },
        };

    //console.log('Saving composite');
    //console.log(mergedComposite)
    this.saving = true;

    return axios({
      method: "PUT",
      url: `/IBYComposite`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
      },
      data: mergedComposite,
    })
      .then((res: AxiosResponse) => {
        // Look for the imageListItem in the sessiondetails, if it is found, update the imagelistitem, else add it
        if (res.data.ImageListItem) {
          const session = SessionStore.allSessionDetail.find(
            (sessionDetail: Session) =>
              sessionDetail.Key === res.data.SessionKey
          );
          const existingImageListItem = session.Images.Images.find(
            (image: SessionImageListItem) =>
              image.Key === res.data.ImageListItem.Key
          );
          if (existingImageListItem) {
            // Update the ImageListItem in the session images
            SessionStore.allSessionDetail = SessionStore.allSessionDetail.map(
              (sessionDetail: Session) => {
                if (sessionDetail.Key !== session.Key) {
                  return sessionDetail;
                }
                return {
                  ...sessionDetail,
                  Images: {
                    ...sessionDetail.Images,
                    Images: sessionDetail.Images?.Images
                      ? sessionDetail.Images.Images.map(
                          (image: SessionImageListItem) => {
                            if (image.Key !== existingImageListItem.Key) {
                              return image;
                            }
                            return {
                              ...res.data.ImageListItem,
                              CompositeKey: res.data.Key,
                            };
                          }
                        )
                      : [res.data.ImageListItem],
                  },
                };
              }
            );
            SessionStore.activeSession = {
              ...SessionStore.activeSession,
              Images: {
                ...SessionStore.activeSession.Images,
                Images: SessionStore.activeSession.Images?.Images
                  ? SessionStore.activeSession.Images.Images.map(
                      (image: SessionImageListItem) => {
                        if (image.Key !== existingImageListItem.Key) {
                          return image;
                        }
                        return {
                          ...res.data.ImageListItem,
                          CompositeKey: res.data.Key,
                        };
                      }
                    )
                  : [
                      {
                        ...res.data.ImageListItem,
                        CompositeKey: res.data.Key,
                      },
                    ],
              },
            };
          } else {
            // Add the ImageListItem to the session images
            SessionStore.allSessionDetail = SessionStore.allSessionDetail.map(
              (sessionDetail: Session) => {
                if (sessionDetail.Key !== session.Key) {
                  return sessionDetail;
                }
                return {
                  ...sessionDetail,
                  Images: {
                    ...sessionDetail.Images,
                    Images: sessionDetail.Images?.Images
                      ? [
                          ...sessionDetail.Images.Images,
                          {
                            ...res.data.ImageListItem,
                            CompositeKey: res.data.Key,
                          },
                        ]
                      : [res.data.ImageListItem],
                  },
                };
              }
            );

            SessionStore.activeSession = {
              ...SessionStore.activeSession,
              Images: {
                ...SessionStore.activeSession.Images,
                Images: SessionStore.activeSession.Images?.Images
                  ? [
                      ...SessionStore.activeSession.Images.Images,
                      {
                        ...res.data.ImageListItem,
                        CompositeKey: this.activeComposite.Key,
                      },
                    ]
                  : [
                      {
                        ...res.data.ImageListItem,
                        CompositeKey: this.activeComposite.Key,
                      },
                    ],
              },
            };
          }
        }
        this.activeComposite = res.data;
        const req = {
          Key: this.compositeCartItem.itemKey,
          InvoiceKey: isInvoice
            ? EcomStore.activeInvoice.Key
            : EcomStore.cart.Key,
          CompositeKey: res.data.Key,
          FileName: res.data.ImageListItem
            ? res.data.ImageListItem.FileName
            : undefined,
        };
        EcomStore.updateCartItem(req, cartSessionKey, false, isInvoice);
        //console.log('Return after saving composite');
        //console.log(res.data)
        this.saving = false;
      })
      .catch((err: AxiosError) => {
        console.log(err);
        this.saving = false;
      });
  }

  @action getCompositeLayer(i: number) {
    return (
      this.activeRecipeNewLayers &&
      this.activeRecipeNewLayers.find((e?: any, index?: number) => index === i)
    );
  }

  @action resetLayers() {
    this.reset = true;
    this.activeRecipeLayer = {};
    this.activeRecipeLayerIndex = null;
    this.activeImageCropper = this.activeComposite.Recipe.Layers.map(() => {
      return { zoom: null };
    });
    setTimeout(() => {
      this.activeRecipeNewLayers = this.activeComposite.Recipe.Layers;
      this.loading = false;
      this.reset = false;
    }, 300);
  }

  @action setActiveRecipeLayer(i: number) {
    this.activeRecipeLayer = this.getCompositeLayer(i);
    this.activeRecipeLayerIndex = i;
  }

  @action updateActiveComposite(newRecipeLayer: any = {}) {
    if (
      typeof newRecipeLayer !== "object" ||
      typeof this.activeRecipeNewLayers !== "object" ||
      typeof this.activeRecipeLayerIndex !== "number"
    )
      return;
    let mergeObject: any = {};
    mergeObject[this.activeRecipeLayerIndex] = { $merge: newRecipeLayer };

    let merged: CompositeTemplateRecipe = update(
      this.activeRecipeNewLayers,
      mergeObject
    );
    this.activeRecipeNewLayers = merged;
    //console.log('Update composite store');
    //console.log(merged);
  }

  @action updateActiveCompositeZoom(newCropZoom: any = {}) {
    if (
      typeof newCropZoom !== "object" ||
      typeof this.activeImageCropper !== "object" ||
      typeof this.activeRecipeLayerIndex !== "number"
    )
      return;
    let mergeCropZoom: any = {};
    mergeCropZoom[this.activeRecipeLayerIndex] = { $merge: newCropZoom };

    let mergedCrop: any = update(this.activeImageCropper, mergeCropZoom);
    this.activeImageCropper = mergedCrop;
  }

  async handleComposite(itm: any, Filename = "") {
    this.compositeCartItem = {
      itemKey: itm.Key,
      compositeKey: itm.CompositeKey,
      compositeTemplate: itm.compositeTemplateKey,
    };

    if (itm.CompositeKey) {
      await this.getCompositeByKey(itm.CompositeKey);
    } else {
      await this.getCompositeTemplate(itm.compositeTemplateKey, Filename);
    }
  }

  @action async removeActiveComposite() {
    this.compositeCartItem = {};
    this.activeComposite = {};
    this.activeRecipe = {};
    this.activeRecipeLayer = {};
    this.activeRecipeNewLayers = {};
    this.activeRecipeLayerIndex = null;
    this.activeImageCropper = [];
    this.loading = false;
  }

  @observable hydrated: boolean = false;
}

const hydrate = create({});
const CompositeStore = new CompositeStoreClass();
hydrate("event", CompositeStore).then((CompositeStore) => {
  CompositeStore.hydrated = true;
});
export default CompositeStore;
