import {observable, action, reaction } from 'mobx';

class GlobalErrorStoreClass {

	@observable errorMessage:string = '';
	@observable messageColor:string = 'danger';
    @observable loginErrorMessage:string = '';
    
    autoMessageReset:any;

	@action async setErrorMessage(message:string, color = 'danger') {
        this.errorMessage = message;
        this.messageColor = color;
    }
    
    constructor(){
    // resets err message after 5 seconds
    this.autoMessageReset = reaction(
        () => this.errorMessage,
        (message) => {
            if(!!message){
                setTimeout(()=>{
                this.setErrorMessage('');
                this.messageColor = 'danger';
                }, 5000);
            }
        }
    )
    
    }


}

const GlobalErrorStore = new GlobalErrorStoreClass();
export default GlobalErrorStore;