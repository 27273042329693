import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { 
  MdGraphicEq
} from 'react-icons/md'
import { 
  Label,
  FormFeedback,
  Alert
} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment  from 'moment';
import { useTranslation } from 'react-i18next';

const InputSection: React.FC<any> = ({ errors, values, setFieldValue, touched, content, publicEvents, eventStore, disabled=false }) => {
    const { t } = useTranslation();
    
    function onDateChange(e:Date){
        setFieldValue('date', new Date(e).toISOString());
        setFieldValue('visibleDate', moment(e).format('MM/DD/YYYY'));
        const serverDate = `${moment(e).format("YYYY-MM-DD")}`;
        eventStore.getEcomEventsByDate(serverDate).catch((error:any)=>{
            console.log(error)
        })
    }

    return (
        <>
            {values.search_by === 'date' &&
                <>
                <div className="mb-3">
                    <label htmlFor="date_input" className="sr-only">{content.date} input</label>
                        <DatePicker
                            onChange = {(e:Date)=> onDateChange(e)}
                            id="date_input"
                            className = "form-control"
                            value = {values.visibleDate}
                            autoComplete = {'off'}
                            maxDate = {new Date()}
                            placeholderText={t('inputSectionComponent.selectDate')}
                        />
                        <input type="hidden" className={`${errors?.visibleDate ? 'is-invalid' : ''}`}/>
                        <ErrorMessage name="visibleDate">{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>

                        {eventStore.noEventFoundForDate &&
                            <Alert className = "mt-3" color="danger">{`Sorry, no events found for ${values.visibleDate}`}</Alert>
                        }
                </div>

                    {eventStore.ecomEventsByDateExist &&
                        <>
                            <Select
                                id="code_by_date"
                                classNamePrefix="react-select-group"
                                className={`react-select-group-container${errors.code_by_date && touched.code_by_date ? ' is-invalid' : ''}`}
                                name = "code_by_date"
                                options = {eventStore.dropdownEcomEventsByDate}
                                onChange = {(val:any)=>{setFieldValue('code_by_date',val.value)}}
                            />
                            <input type="hidden" className={`${(errors?.code_by_date && touched.code_by_date) ? 'is-invalid' : ''}`}/>
                            <ErrorMessage name="code_by_date">{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                        </>

                    }

                </>

            }
            {values.search_by === 'code' &&
                <>
                    <Label htmlFor="code_input" className="sr-only">{content.code} input</Label>
                    <MdGraphicEq size="20" />
                    <Field
                        id="code_input"
                        className={`form-control ${errors.code && touched.code ? 'is-invalid' : ''}`}
                        name = "code"
                        placeholder={t('inputSectionComponent.placeCodeHere')}
                        disabled={disabled}
                    />
                    <ErrorMessage name="code">{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                </>

            }
            {values.search_by === 'publicCode' &&
                <>
                    <Label htmlFor="public_code_input" className="sr-only">{content.code} {t('inputSectionComponent.input')}</Label>
                    <Select
                        id="public_code_input"
                        classNamePrefix="react-select-group"
                        className={`react-select-group-container ${errors.publicCode && touched.publicCode ? ' is-invalid' : ''}`}
                        name = "publicCode"
                        options = {publicEvents}
                        onChange = {(val:any)=>{
                            setFieldValue('publicCode',val.value)
                            setFieldValue('date',val.date);
                        }}
                        placeholder={t('inputSectionComponent.selectEvent')}
                        maxMenuHeight={200}
                        isDisabled={disabled}
                    />
                    <ErrorMessage name="publicCode">{(msg) => <FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                </>

            }
        </>
    )
}
export default inject('eventStore')(observer(InputSection))