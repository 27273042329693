import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
    FormGroup,
    Button,
    CustomInput,
    Alert,
    Spinner
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';


const CommunicationForm: React.FC<any> = ({ clientStore, onSuccess }) => {
    const { t } = useTranslation();
    const [asyncErr, setAsyncErr] = useState('');

    async function updateClient(obj: any) {
        await clientStore.updateClient(obj).catch((err:AxiosError)=>setAsyncErr(err.message))
        onSuccess();
    }

    const initialValues = {
        AllowCallMarketing: clientStore.clientInfo.AllowCallMarketing,
        AllowEmailMarketing: clientStore.clientInfo.AllowEmailMarketing,
        AllowTextMarketing: clientStore.clientInfo.AllowTextMarketing,
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={updateClient}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                isSubmitting
                /* and other goodies */
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <ul className="list-unstyled list-expand mt-3" role="presentation">
                                <li>
                                    <CustomInput 
                                    type="checkbox" 
                                    id="com1" 
                                    label={t('communicationFormComponent.permissionCall')}
                                    checked = {values.AllowCallMarketing}
                                    onChange = {(e:any)=>setFieldValue('AllowCallMarketing', e.target.checked)}
                                    />
                                </li>
                                <li>
                                    <CustomInput 
                                    type="checkbox" 
                                    id="com2" 
                                    label={t('communicationFormComponent.permissionEmail')} 
                                    checked = {values.AllowEmailMarketing}
                                    onChange = {(e:any)=>setFieldValue('AllowEmailMarketing', e.target.checked)}


                                    />
                                </li>
                                <li>
                                    <CustomInput 
                                    type="checkbox" 
                                    id="com3" 
                                    label={t('communicationFormComponent.permissionText')}  
                                    checked = {values.AllowTextMarketing}
                                    onChange = {(e:any)=>setFieldValue('AllowTextMarketing', e.target.checked)}


                                    />
                                </li>
                            </ul>
                        </FormGroup>
                        {asyncErr &&
                            <Alert color = "danger">{asyncErr}</Alert>
                        }
                        <Button 
                          type="submit" 
                          color="primary" 
                          block 
                          className={isSubmitting ? 'wait' : ''}
                          disabled={isSubmitting}
                        >
                          {t('editAddressFormComponent.applyChange')}
                          {isSubmitting && <Spinner color = '#fff' size="sm" /> }
                        </Button>
                    </Form>
                )}
        </Formik>
    );

}
export default inject('clientStore')(observer(CommunicationForm))
