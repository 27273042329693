import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Alert, Button, FormGroup, Spinner } from "reactstrap";
import { inject, observer } from "mobx-react";
import { Col, Row } from "reactstrap";

const CommentForm: React.FC<any> = ({
  onSuccess,
  authStore,
  clientStore,
  postID,
  wpStore,
}) => {
  const [
    showCommentConfirmation,
    setShowCommentConfirmation,
  ] = useState<boolean>(false);
  // const [clearContent, setClearContent] = useState<boolean>(false);

  async function updateComment(obj: any) {
    await wpStore.putBlogComment(obj);
    setShowCommentConfirmation(true);
    // setClearContent(true);
    onSuccess();
    setTimeout(() => {
      setShowCommentConfirmation(false);
    }, 70000);
  }

  const validationSchema = Yup.object().shape({
    content: Yup.string().required("Please fill this field"),
  });

  const initialValues = {
    content: "",
    post: postID,
    author_name: `${clientStore?.clientInfo?.FirstName} ${clientStore?.clientInfo?.LastName}`,
    author_email: clientStore?.clientInfo?.EmailAddress,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={updateComment}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          touched,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="row-sm">
              <Col>
                <div className="comment-textarea">
                  <FormGroup>
                    <Field
                      component="textarea"
                      rows="2"
                      placeholder={"Type your comments here..."}
                      name="content"
                      className={`form-control ${
                        errors.content ? "is-invalid" : ""
                      }`}
                      disabled={isSubmitting}
                    />
                    {errors.content && touched.content ? (
                      <div className="invalid-feedback">{errors.content}</div>
                    ) : null}
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div className="comment-post-button">
              <Button
                type="submit"
                color="primary"
                size="default"
                className={isSubmitting ? "wait" : ""}
                disabled={isSubmitting}
              >
                POST COMMENT
                {isSubmitting && (
                  <span className="loader">
                    <Spinner color="#fff" size="sm" />{" "}
                  </span>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showCommentConfirmation && (
        <>
          <Alert color="success">
            Thank you. Your comment has been submitted. It will appear once it
            has been approved by the moderator.
          </Alert>
        </>
      )}
    </>
  );
};
export default inject(
  "clientStore",
  "authStore",
  "wpStore"
)(observer(CommentForm));
