import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
import { postVariants } from "../../../util";

import ShippingSection from "./shippingSection/shippingSection";
import DeliveryMethodSection from "./deliveryMethod/deliveryMethod";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const ShippingAddressForm: React.FC<any> = ({
  onSubmit,
  ajaxError,
  onCancel,
  ecomStore,
  authStore,
  clientStore,
}) => {
  const { t } = useTranslation();

  const validator: any = (message: string) => {
    return Yup.string().test("oneOfRequired", message, function (field) {
      if (!this.parent.same_as_default && !this.parent.use_selected_address) {
        return !!field;
      } else {
        return true;
      }
    });
  };

  let val = {
    shipping_method: Yup.string().required(
      t("shippingAddressFormComponent.shippingMethodValidation")
    ),
    same_as_client: Yup.boolean(),
    same_as_default: Yup.boolean(),
    first_name: validator(t("billingAddressFormComponent.firstNameValidation")),
    last_name: validator(t("billingAddressFormComponent.lastNameValidaiton")),
    street_1: validator(
      t("billingAddressFormComponent.addressLine1Validation")
    ),
    street_2: Yup.string(),
    city: validator(t("billingAddressFormComponent.cityValidation")),
    state: validator(t("billingAddressFormComponent.stateValidation")),
    zip: validator(t("billingAddressFormComponent.postalCodeValidation")),
    country: validator(t("billingAddressFormComponent.countryValidation")),
  };

  if (!ecomStore.cart.ShowShippingMethods) {
    val.shipping_method = Yup.string();
  }

  const validationSchema = Yup.object().shape(val);

  const initialValues: any = {
    shipping_method: ecomStore.cart.ShippingMethodKey,
    same_as_client: false,
    same_as_default: !!ecomStore.defaultShippingAddress,
    use_selected_address: false,
    save_address_as_default: false,
    selected_address_key: "",
    first_name: "",
    last_name: "",
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
  return (
    <Formik
      initialValues={Object.assign({}, initialValues)}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          {ajaxError && (
            <div className="alert alert-danger" role="alert">
              {ajaxError}
            </div>
          )}
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={postVariants}
          >
            <ShippingSection
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
            {!!ecomStore.cart?.ShowShippingMethods && (
              <DeliveryMethodSection
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                className="mt-5"
              />
            )}
          </motion.div>
          {authStore.accountName.toLowerCase() === "annegeddes" && (
            <>
              <p className="primary-color mt-4">
                <strong> {t("cartViewComponent.usShippingOnly")}</strong>
              </p>
            </>
          )}

          <Row className="mt-5 align-items-center">
            <Col sm="6" className="text-center text-sm-right order-sm-2">
              {authStore.accountName.toLowerCase() === "annegeddes" ? (
                <>
                  <Button
                    type="submit"
                    color="primary"
                    className="px-5"
                    disabled={
                      (values.same_as_default &&
                        ecomStore.defaultShippingAddress?.Country !== "USA" &&
                        ecomStore.defaultShippingAddress?.Country !== "") ||
                      (values.save_address_as_default &&
                        values?.country !== "USA" &&
                        values?.country !== "") ||
                      (values.same_as_client &&
                        clientStore.clientInfo?.AddressCountry !== "USA" &&
                        clientStore.clientInfo?.AddressCountry !== "") ||
                      (values.use_selected_address &&
                        values?.country !== "USA" &&
                        values?.country !== "")
                        ? true
                        : false
                    }
                  >
                    {t("billingAddressFormComponent.continue")}
                  </Button>
                </>
              ) : (
                <>
                  <Button type="submit" color="primary" className="px-5">
                    {t("billingAddressFormComponent.continue")}
                  </Button>
                </>
              )}
            </Col>
            <Col
              sm="6"
              className="text-center text-sm-left order-sm-1 mt-3 mt-sm-0"
            >
              <Button type="button" color="default-link" onClick={onCancel}>
                <FiArrowLeft className="mr-2" />
                {t("billingAddressFormComponent.continueShopping")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default inject(
  "ecomStore",
  "authStore",
  "clientStore"
)(observer(ShippingAddressForm));
