//@ts-nocheck
import React, { useState, useEffect } from "react";
//import './index.scss'
import { observer, inject } from "mobx-react";
import Select from "react-select";
import { FiSearch } from "react-icons/fi";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toJS } from "mobx";
import { MdClose } from "react-icons/md";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { PriceListItem } from "../../../interfaces/priceList.int";

const ProductGroupDetail: React.FC<any> = ({
  authStore,
  ecomStore,
  createOrderStore,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    affirm.ui.refresh();

    if (!!!toJS(ecomStore.selectedProductGroup.hasOwnProperty("Name"))) {
      createOrderStore.decrementStep();
    }
  }, [ecomStore.selectedProductGroup]);

  const [filterKey, setFilterKey] = useState(
    ecomStore.sortBy || "productGroupItemsBySalesRating"
  );
  const [showModalAtIndex, setShowModalAtIndex] = useState(
    new Array(ecomStore[filterKey].length).fill(false)
  );
  useEffect(() => {
    setShowModalAtIndex(new Array(ecomStore[filterKey].length).fill(false));
  }, [filterKey]);

  function closeModal() {
    ecomStore.cancelSelectedImage();
    setShowModalAtIndex(new Array(ecomStore[filterKey].length).fill(false));
  }
  function openModal(i: number) {
    setShowModalAtIndex(
      showModalAtIndex.map((bool: boolean, index: number) =>
        index === i ? !bool : bool
      )
    );
  }
  const filterOptions = [
    {
      label: "Best Selling",
      value: "productGroupItemsBySalesRating",
    },
    {
      label: "Price (high to low)",
      value: "productGroupItemsByPriceHighToLow",
    },
    {
      label: "Price (low to high)",
      value: "productGroupItemsByPriceLowToHigh",
    },
    {
      label: "Description",
      value: "productGroupItemsByDescription",
    },
  ];

  // This function gets the label given the value in the filter options, used to get initial label in sortBy
  function getLabelFromValue(value: string) {
    for (let filterOption of filterOptions) {
      if (value === filterOption.value) {
        return filterOption.label;
      }
    }
    return "";
  }

  function selectProductItem(key: string) {
    ecomStore.selectProduct(key);
    createOrderStore.incrementStep();
  }
  return (
    <>
      <Helmet>
        <title>
          {t("productGroupDetailComponent.urlTitle")}{" "}
          {ecomStore?.selectedProductGroup?.Name
            ? ecomStore?.selectedProductGroup?.Name
            : ""}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <section className="product-modal-container" id="modal_body_123123123">
        <div className="product-modal-head">
          <h2>{t("productGroupDetailComponent.selectYourProduct")}</h2>
          <div className="row product-filter">
            <div className="col-sm-6">
              <label className="label">
                {t("productGroupDetailComponent.title")}
              </label>
              <div className="select" style={{ width: 200 }}>
                <Select
                  defaultValue={{
                    label: ecomStore.selectedProductGroup.Name,
                    value: 0,
                  }}
                  options={ecomStore.productGroupsFormattedForDropdown}
                  onChange={(e: any) => ecomStore.selectProductGroup(e.value)}
                  className="react-select-group-container-simple"
                  classNamePrefix="react-select-group"
                  placeholder={t("productGroupDetailComponent.selectCategory")}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="col-sm-6 justify-content-sm-end">
              <label className="label">
                {t("productGroupDetailComponent.sortBy")}
              </label>
              <div className="select" style={{ width: "50%" }}>
                <Select
                  defaultValue={filterKey}
                  className="react-select-group-container-simple"
                  classNamePrefix="react-select-group"
                  placeholder={getLabelFromValue(filterKey)}
                  options={filterOptions}
                  onChange={(e: any) => {
                    ecomStore.sortBy = e.value;
                    setFilterKey(e.value);
                  }}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="product-modal-list product-listing">
          <div className="row equal-height">
            {ecomStore[filterKey]?.map((item: PriceListItem, i: number) => (
              <>
                {!item.HiddenItem && ( // Check that item is not hidden.
                  <div
                    className="col-12 col-md-6 mb-5"
                    key={`priceListItem${i}`}
                  >
                    <article
                      className="card card-body card-product-list"
                      key={`priceListItemArticle${i}`}
                    >
                      <header>
                        {item.ProductImages?.[0].ThumbUrl && (
                          <div className="image ">
                            <img
                              className="img"
                              onClick={() => selectProductItem(item?.Key || "")}
                              src={`${item.ProductImages?.[0].ThumbUrl}`}
                              alt=""
                            />
                            <span
                              className="view-larger"
                              onClick={() => openModal(i)}
                            >
                              <FiSearch />
                              {t("productGroupDetailComponent.viewLarger")}
                            </span>
                          </div>
                        )}
                        <div className="title">
                          <h4
                            style={{ cursor: "pointer" }}
                            onClick={() => selectProductItem(item?.Key || "")}
                          >
                            {item.Name}
                          </h4>
                          <p className="price">{item?.PriceStringFormatted}</p>
                          {authStore.AffirmEnabledOnline && (
                            <p
                              className="affirm-as-low-as"
                              data-page-type="product"
                              data-amount={(
                                (item.Price ? item.Price : 0) * 100
                              ).toString()}
                            ></p>
                          )}

                          {item.ProductImages?.[0].ThumbUrl && (
                            <Button
                              color="body-link"
                              onClick={() => selectProductItem(item?.Key || "")}
                            >
                              {t("productGroupDetailComponent.viewDetails")}
                            </Button>
                          )}
                        </div>
                      </header>
                      {!!item?.MarketingDescription && (
                        <div
                          className="summary"
                          onClick={() => selectProductItem(item?.Key || "")}
                          dangerouslySetInnerHTML={{
                            __html: item.MarketingDescription || "",
                          }}
                        ></div>
                      )}
                      {!item.ProductImages?.[0].ThumbUrl && (
                        <p className="mt-3">
                          <Button
                            color="body-link"
                            onClick={() => selectProductItem(item?.Key || "")}
                          >
                            {t("productGroupDetailComponent.viewDetails")}
                          </Button>
                        </p>
                      )}
                      <Modal
                        isOpen={showModalAtIndex[i]}
                        toggle={closeModal}
                        wrapClassName="modal-box"
                        modalTransition={{ timeout: 500 }}
                        backdropTransition={{ timeout: 0 }}
                        centered
                        key={`modal${i}`}
                      >
                        <ModalHeader
                          toggle={closeModal}
                          close={
                            <Button
                              color="icon-action"
                              className="close"
                              onClick={closeModal}
                            >
                              <MdClose size="18" />
                            </Button>
                          }
                        >
                          {item.Name}
                        </ModalHeader>
                        <ModalBody>
                          <img
                            className="img"
                            src={`${item.ProductImages?.[0].Url}`}
                            alt=""
                          />
                        </ModalBody>
                      </Modal>
                    </article>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default inject(
  "authStore",
  "ecomStore",
  "createOrderStore"
)(observer(ProductGroupDetail));
