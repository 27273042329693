//@ts-nocheck
import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { motion } from "framer-motion";
import { XMasonry, XBlock } from "react-xmasonry";
import { Waypoint } from "react-waypoint";
import { useMediaQuery } from "react-responsive";

import CompositeTouch from "./CompositeTouch.png";
import CompositeMouse from "./CompositeMouse.png";

import {
  Row,
  Col,
  Spinner,
  Button,
  Alert,
  Toast,
  ToastHeader,
  Input,
  ToastBody,
  Progress,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { FiX, FiArrowLeft } from "react-icons/fi";
import {
  AiOutlineSave,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdBorderClear, MdBorderOuter, MdLoop } from "react-icons/md";

import { postReverseVariants, useQuery } from "../../util";
import PhotoTile from "../photoSelect/photoTile";

import CompositeLayerZone from "./CompositeLayerZone";
import CompositeLayerImage from "./CompositeLayerImage";
import CompositeLayerText from "./CompositeLayerText";
import ToolbarText from "./ToolbarText";
import ToolbarZone from "./ToolbarZone";

const CompositeView: React.FC<any> = ({
  authStore,
  createOrderStore,
  sessionStore,
  ecomStore,
  compositeStore,
  imageGroupStore,
}) => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [isEndOfPage, setEndOfPage] = useState(false);
  const [listOnDisplay, setListOnDisplay] = useState([] as any);
  const [activePhoto, setActivePhoto] = useState({} as any);
  const [getPhotoGridWindow, setPhotoGridWindow] = useState(false);
  const [readyGrid, setReadyGrid] = useState(false);
  const [onSuccessAlert, setOnSuccessAlert] = useState(false);
  const [filterByFavorites, setFilterByFavorites] = useState(false);
  const [showModal, setShowModal] = useState(compositeStore?.firstVisit);

  const query = useQuery();
  const isPrepaid = query?.get("id");

  const isBreakDownSm = useMediaQuery(
    {
      query: "(max-width: 767px)",
    },
    undefined,
    (matches) => {
      if (matches) {
        compositeStore.canvasRatio = 0.2;
      } else {
        compositeStore.canvasRatio = 0.2;
      }
    }
  );

  const isBreakDownMd = useMediaQuery({
    query: "(max-width: 991px)",
  });

  useEffect(() => {
    if (isBreakDownSm) {
      compositeStore.canvasRatio = 0.2;
    } else {
      compositeStore.canvasRatio = Math.min(
        window.innerWidth /
          (1.5 * compositeStore.activeComposite?.Recipe?.Width),
        window.innerHeight /
          (1.5 * compositeStore.activeComposite?.Recipe?.Height)
      );
    }

    handleQuery();

    return () => {
      compositeStore.removeActiveComposite();
    };
  }, []);

  useEffect(() => {
    if (compositeStore.activeComposite?.Key) {
      handleFillUp();
    }
  }, [compositeStore.activeComposite?.Key, filterByFavorites]);

  useEffect(() => {
    if (compositeStore.reset) {
      setActivePhoto({});
    }
  }, [compositeStore.reset]);

  function findImage(filename: any) {
    const theSessionKey: string = isPrepaid
      ? ecomStore.cartInvoiceItems[0].SessionKey
      : ecomStore.cart.SessionKey;
    return sessionStore.getImageByFilename(filename, theSessionKey);
  }

  async function handleQuery() {
    if (compositeStore.activeComposite.Key && !compositeStore.loading) {
      if (compositeStore.compositeCartItem.compositeKey) {
        await compositeStore.getCompositeByKey(
          compositeStore.compositeCartItem.compositeKey
        );
      } else {
        await compositeStore.getCompositeTemplate(
          compositeStore.compositeCartItem.compositeTemplate
        );
      }
    }
  }

  function handleFillUp() {
    console.log(ecomStore?.activeInvoice);
    const theSessionKey: string = isPrepaid
      ? ecomStore.cartInvoiceItems[0].SessionKey
      : ecomStore.cart.SessionKey;
    let sessionImages: any = sessionStore?.getImagesBySession(theSessionKey);
    console.log(theSessionKey);
    const startItemsTotal = isBreakDownSm ? 50 : 100;

    setTotalItems(sessionImages.length);
    setItemsPerPage(startItemsTotal);

    const iniDisplay: any[] = [];
    if (sessionImages.length > 0) {
      sessionImages.slice(0, startItemsTotal).map((img: any, i: number) => {
        if (!img.compositeKey) {
          if (filterByFavorites) {
            if (imageGroupStore.favoriteImageList.includes(img.fileName)) {
              iniDisplay.push(img);
            }
          } else {
            iniDisplay.push(img);
          }
        }
      });
    }
    console.log(imageGroupStore.favoriteImageList);
    // if (filterByFavorites) {
    //   sessionImages = sessionImages.filter((img: any) =>
    //     imageGroupStore.favoriteImageList.includes(img.fileName)
    //   );
    // }

    setListOnDisplay([...iniDisplay]);

    setReadyGrid(true);
  }

  const handleLoadMore = () => {
    const theSessionKey: string = isPrepaid
      ? ecomStore.cartInvoiceItems[0].SessionKey
      : ecomStore.cart.SessionKey;
    const sessionImages: any = sessionStore?.getImagesBySession(theSessionKey);

    setCurrentPage(currentPage + 1);

    const itemsDisplayed = (currentPage + 1) * itemsPerPage;
    const iniDisplay: any[] = [];
    sessionImages
      .slice(itemsDisplayed - itemsPerPage, itemsDisplayed)
      .map((img: any, i: number) => {
        console.log(img);
        if (!img.compositeKey) {
          iniDisplay.push(img);
        }
      });

    setListOnDisplay([...listOnDisplay, ...iniDisplay]);

    if (currentPage * itemsPerPage >= totalItems - itemsPerPage) {
      setEndOfPage(true);
    }
  };
  async function saveComposite() {
    await compositeStore
      .saveCompositeSession(
        null,
        null,
        isPrepaid
          ? ecomStore.cartInvoiceItems[0].SessionKey
          : ecomStore.cart.SessionKey,
        isPrepaid
      )
      .then(async () => {
        setOnSuccessAlert(true);
        const updatedItem = ecomStore.currentPackageItems.find(
          (item: any) => item.Key === ecomStore.swapPhotoCartItemKey
        );
        let updatedItemIdx = ecomStore.currentPackageItems.findIndex(
          (item: any) => item.Key === ecomStore.swapPhotoCartItemKey
        );
        let found = false;
        while (
          updatedItem?.PackageItemKey &&
          ecomStore.currentPackageItems &&
          updatedItemIdx + 1 < ecomStore.currentPackageItems.length &&
          !found
        ) {
          const newItem = ecomStore.currentPackageItems[updatedItemIdx + 1];
          const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
          const options = priceItem.Options;
          const enhancements = priceItem.Enhancements;
          if (newItem.compositeTemplateKey) {
            ecomStore.scrollCart = newItem.Key;
            ecomStore.swapPhotoCartItemKey = newItem.Key;
            await compositeStore.handleComposite(newItem);
            createOrderStore.setStepNumber(7);
            found = true;
          } else if (newItem?.ReplacementItems?.length > 0) {
            ecomStore.setCroppedPhoto(newItem.Key);
            createOrderStore.setStepNumber(8);
            found = true;
          } else if (
            ecomStore.isImageRequired(newItem) ||
            newItem.allowImageSelection
          ) {
            sessionStore.swappedPhoto = newItem;
            ecomStore.swapPhotoCartItemKey = newItem.Key;
            ecomStore.scrollCart = newItem.Key;
            createOrderStore.setStepNumber(5);
            found = true;
          } else if (
            ecomStore.cropRequired(newItem) ||
            options?.length > 0 ||
            enhancements?.length > 0
          ) {
            ecomStore.setCroppedPhoto(newItem.Key);
            ecomStore.scrollCart = newItem.Key;
            createOrderStore.setStepNumber(6);
            found = true;
          } else {
            updatedItemIdx += 1;
          }
        }
        if (
          !updatedItem?.PackageItemKey ||
          updatedItemIdx + 1 === ecomStore.currentPackageItems.length
        ) {
          createOrderStore.setStepNumber(4);
        }
        setTimeout(() => {
          setOnSuccessAlert(false);
        }, 3000);
      });
  }

  const handleClicked = (index: any, photoHandler?: any, type?: any) => {
    if (type === "grid") {
      setPhotoGridWindow(true);
      return;
    }

    if (type === "blur") {
      handleBlur();
      return;
    }

    if (index !== compositeStore.activeRecipeLayerIndex && type === "zone") {
      setActivePhoto(photoHandler);
    } else {
      handleBlur();
    }

    compositeStore.setActiveRecipeLayer(index);
  };

  const handleBlur = () => {
    setActivePhoto({});
    compositeStore.activeRecipeLayer = {};
    compositeStore.activeRecipeLayerIndex = {};
  };

  const gridDisplay = listOnDisplay.map((photo: any, i: number) => {
    return (
      <XBlock key={`cropGrid${i}`}>
        <div className="pads">
          <PhotoTile
            key={`imgTileList${i}`}
            index={i}
            img={photo}
            onClick={() => {
              setPhotoGridWindow(false);
              setActivePhoto(photo);
              if (
                compositeStore.activeRecipeLayerIndex !== null &&
                compositeStore.activeRecipeLayer?.Type === "zone"
              ) {
                // setPhotoGridWindow(false);
                // setActivePhoto(photo);
                //console.log(photo);
                // compositeStore.updateActiveComposite({
                //   ZoneImage: {
                //     ...compositeStore.activeRecipeLayer.ZoneImage,
                //     Filename: photo.fileName,
                //     Width: photo.width,
                //     Height: photo.height,
                //   },
                // });
              }
            }}
            className={`photo-grid-image${
              activePhoto?.fileName === photo.fileName ? " active" : ""
            }`}
          />
        </div>
      </XBlock>
    );
  });

  return (
    <>
      <Helmet>
        <title>
          {isPrepaid ? `Prepaid ${isPrepaid}` : "Shopping Cart"}: Customize
          Composite{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>

      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        wrapClassName="modal-box"
        size="lg"
        onOpened={() => (compositeStore.firstVisit = false)}
        centered
      >
        <ModalBody>
          <img
            style={{ position: "relative" }}
            src={"ontouchstart" in window ? CompositeTouch : CompositeMouse}
            alt="instructions"
          />
          <div
            // onClick={() => setShowModal(false)}
            style={{ textAlign: "end" }}
          >
            <Button color="primary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postReverseVariants}
      >
        <section className="product-modal-container">
          <div className="product-modal-photo-container">
            <div
              className={`photo-grid-composite px-1 bg-white${
                getPhotoGridWindow ? " active" : ""
              }`}
            >
              <Button
                color="text"
                className="close"
                onClick={() => setPhotoGridWindow(false)}
              >
                <FiX size="24" />
              </Button>
              <div style={{ padding: "0.5rem 2rem" }}>
                <Input
                  type="checkbox"
                  onChange={(e: any) => {
                    setFilterByFavorites(e.target.checked);
                  }}
                />
                <Label check>Only Show Favorites</Label>
              </div>
              {readyGrid && (
                <XMasonry
                  targetBlockWidth={150}
                  center={false}
                  updateOnImagesLoad
                  // targetBlockWidth={isBreakDownMd ? 150 : 100}
                >
                  {gridDisplay}
                </XMasonry>
              )}

              <Waypoint onEnter={handleLoadMore} topOffset={-200}>
                {!isEndOfPage ? (
                  <div
                    style={{
                      paddingTop: 50,
                      paddingBottom: 50,
                      textAlign: "center",
                    }}
                  >
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <div></div>
                )}
              </Waypoint>
            </div>

            <div className="composite-editor bg-light">
              {/* <div style={{ position: "absolute", right: "1.5rem" }}>
                <Toast>
                  <ToastBody>
                    <div>
                      <strong>1) Select Drop Zone</strong>
                    </div>
                    <div>
                      <strong>2) Select Image</strong>
                    </div>
                  </ToastBody>
                </Toast>
              </div> */}
              {onSuccessAlert && (
                <Alert
                  style={{
                    position: "absolute",
                    width: "fit-content",
                    zIndex: 10000,
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "2rem",
                  }}
                  color="success"
                >
                  Composite Template saved
                </Alert>
              )}

              {compositeStore.loading ? (
                <div className="page-full-spinner d-flex flex-column">
                  <div>Loading composite...</div>

                  <div>
                    <Spinner color="primary" size="sm" />
                  </div>
                </div>
              ) : (
                <div>
                  <header className="composite-header">
                    <Row className="align-items-center">
                      <Col sm="5" md="6" lg="4" className="d-none d-sm-block">
                        <Button
                          color="default-link"
                          className="mb-1"
                          onClick={() => {
                            isPrepaid
                              ? createOrderStore.toggleSlider()
                              : createOrderStore.setStepNumber(4);
                          }}
                        >
                          <FiArrowLeft size="18" className="mr-2" />
                          {isPrepaid
                            ? `Back to pre order`
                            : `Back to shopping cart`}
                        </Button>
                        <h1 className="h4">
                          {compositeStore.activeRecipe?.Description}
                        </h1>
                      </Col>

                      <Col sm="7" md="6" lg="8">
                        <div className="composite-toolbar d-flex align-items-center">
                          <div className="d-sm-none">
                            <Button
                              color="default-link"
                              onClick={() => {
                                isPrepaid
                                  ? createOrderStore.toggleSlider()
                                  : createOrderStore.setStepNumber(4);
                              }}
                            >
                              <FiArrowLeft size={22} />
                            </Button>
                          </div>
                          <div className="composite-toolbar-text ml-auto">
                            <Button
                              color="toolbar"
                              title="Reset"
                              className=""
                              onClick={() => {
                                compositeStore.canvasRatio += 0.05;
                              }}
                            >
                              <AiOutlineZoomIn size={22} />{" "}
                              <span className="d-none d-sm-inline-block">
                                Zoom In
                              </span>
                            </Button>
                            <Button
                              color="toolbar"
                              title="Reset"
                              className=""
                              onClick={() => {
                                compositeStore.canvasRatio -= 0.05;
                              }}
                            >
                              <AiOutlineZoomOut size={22} />{" "}
                              <span className="d-none d-sm-inline-block">
                                Zoom Out
                              </span>
                            </Button>
                            {/* {compositeStore.activeRecipeLayer?.Type ===
                              "zone" ||
                            compositeStore.activeRecipeLayer?.Type ===
                              "text" ? (
                              <>
                                {compositeStore.activeRecipeLayer?.Type ===
                                "text" ? (
                                  <ToolbarText />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )} */}
                            <Button
                              color="toolbar"
                              title="Reset"
                              className=""
                              onClick={() => {
                                compositeStore.resetLayers();
                              }}
                            >
                              <AiOutlineDelete size={22} />{" "}
                              <span className="d-none d-sm-inline-block">
                                Reset
                              </span>
                            </Button>
                            {/* <Button
                              color="toolbar"
                              title="Show/hide border"
                              className={
                                compositeStore.activeLines ? "active" : ""
                              }
                              onClick={() =>
                                (compositeStore.activeLines =
                                  !compositeStore.activeLines)
                              }
                            >
                              {compositeStore.activeLines ? (
                                <MdBorderOuter size={22} />
                              ) : (
                                <MdBorderClear size={22} />
                              )}
                            </Button> */}
                            <Button
                              color="toolbar"
                              title="Save"
                              disabled={compositeStore.saving}
                              className={`btn-primary`}
                              onClick={saveComposite}
                            >
                              {compositeStore.saving ? (
                                <div className="d-flex align-items-center">
                                  <div className="mr-2">
                                    <Spinner />
                                  </div>
                                  <div>Building Template</div>
                                </div>
                              ) : (
                                <>
                                  <AiOutlineSave size={22} />{" "}
                                  <span className="d-none d-sm-inline-block">
                                    Save &amp; Close
                                  </span>
                                </>
                              )}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ paddingTop: "1rem" }}>
                        <Col
                          className="composite-toast"
                          style={{ padding: "1rem", fontSize: "24px" }}
                          xs={4}
                          lg={2}
                        >
                          <div>
                            <strong>
                            {ecomStore.currentPackageItems?.length > 0 &&
                              `(Item ${
                                ecomStore.currentPackageItems.findIndex(
                                  (itm: any) =>
                                    ecomStore.swapPhotoCartItemKey === itm.Key
                                ) + 1
                              } of ${ecomStore.currentPackageItems?.length})`}
                            {ecomStore.currentPackageItems?.length > 0 && (
                              <Progress
                                value={
                                  ecomStore.currentPackageItems.findIndex(
                                    (itm: any) =>
                                      ecomStore.swapPhotoCartItemKey === itm.Key
                                  ) + 1
                                }
                                min="1"
                                max={ecomStore.currentPackageItems?.length}
                              ></Progress>
                            )}
                          </strong>
                            <strong>
                              Item 1 of 2
                              <Progress value={1} min="1" max={2}></Progress>
                            </strong>
                          </div>
                        </Col>
                        <Col xs={4} lg={2}>
                          <Toast>
                            <ToastBody>
                              <div>
                                <strong>1) Select Drop Zone</strong>
                              </div>
                              <div>
                                <strong>2) Select Image</strong>
                              </div>
                            </ToastBody>
                          </Toast>
                        </Col>
                      </Row> */}
                  </header>

                  <div className="blurout" onClick={() => handleBlur()}></div>
                  <div className="composite-canvas-container">
                    <Row style={{ padding: "1rem" }}>
                      <Col
                        className="composite-toast d-sm-none"
                        style={{ padding: "1rem", fontSize: "24px" }}
                        xs={6}
                        lg={4}
                      >
                        <Button
                          onClick={() => {
                            setPhotoGridWindow(true);
                          }}
                        >
                          View Images
                        </Button>
                      </Col>
                      <Col
                        className="composite-toast"
                        style={{ padding: "1rem", fontSize: "24px" }}
                        xs={6}
                        lg={4}
                      >
                        <div>
                          <strong>
                            {ecomStore.currentPackageItems?.length > 0 &&
                              `(Item ${
                                ecomStore.currentPackageItems.findIndex(
                                  (itm: any) =>
                                    ecomStore.swapPhotoCartItemKey === itm.Key
                                ) + 1
                              } of ${ecomStore.currentPackageItems?.length})`}
                            {ecomStore.currentPackageItems?.length > 0 && (
                              <Progress
                                value={
                                  ecomStore.currentPackageItems.findIndex(
                                    (itm: any) =>
                                      ecomStore.swapPhotoCartItemKey === itm.Key
                                  ) + 1
                                }
                                min="1"
                                max={ecomStore.currentPackageItems?.length}
                              ></Progress>
                            )}
                          </strong>
                          {/* <strong>
                            Item 1 of 2
                            <Progress value={1} min="1" max={2}></Progress>
                          </strong> */}
                        </div>
                      </Col>
                      {/* <Col xs={6} lg={4}>
                        <Toast>
                          <ToastBody>
                            <div>
                              <strong>1) Select Drop Zone</strong>
                            </div>
                            <div>
                              <strong>2) Select Image</strong>
                            </div>
                          </ToastBody>
                        </Toast>
                      </Col> */}
                    </Row>

                    <div
                      className="composite-canvas"
                      style={{
                        width: compositeStore?.activeComposite?.Recipe?.Width
                          ? Math.min(
                              compositeStore?.activeComposite?.Recipe?.Width *
                                compositeStore.canvasRatio,
                              window.innerWidth
                            )
                          : 0,
                        height: compositeStore?.activeComposite?.Recipe?.Height
                          ? Math.min(
                              compositeStore?.activeComposite?.Recipe?.Height *
                                compositeStore.canvasRatio,
                              window.innerHeight
                            )
                          : 0,
                      }}
                    >
                      {compositeStore.activeRecipeNewLayers?.map(
                        (item: any, i: number) => {
                          if (item.Type === "zone" && !compositeStore.reset) {
                            const originPhoto = findImage(
                              item?.ZoneImage?.Filename
                            );
                            if (i === 1) {
                              console.log(item);
                            }
                            return (
                              <CompositeLayerZone
                                photo={originPhoto}
                                index={i}
                                activePhoto={activePhoto}
                                setActivePhoto={setActivePhoto}
                                key={`${item}${i}`}
                                handleClicked={handleClicked}
                              />
                            );
                          }
                          if (item.Type === "text" && !compositeStore.reset) {
                            return (
                              <CompositeLayerText
                                index={i}
                                key={`${item}${i}`}
                                handleClicked={handleClicked}
                              />
                            );
                          }
                          if (item.Type === "image") {
                            return (
                              <CompositeLayerImage
                                index={i}
                                key={`${item}${i}`}
                                handleClicked={handleClicked}
                              />
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </motion.div>
    </>
  );
};
export default inject(
  "authStore",
  "createOrderStore",
  "sessionStore",
  "ecomStore",
  "compositeStore",
  "imageGroupStore"
)(observer(CompositeView));
