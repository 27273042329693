import React from "react";
import { inject, observer } from "mobx-react";

import {
  CompositeTemplateImageLayer,
  // CompositeTemplateColor,
} from "../../../interfaces/composite.int";

const CompositeLayerImage: React.FC<any> = ({
  compositeStore,
  textData = {},
  index,
  handleClicked,
  ...props
}) => {
  let zone: CompositeTemplateImageLayer =
    compositeStore.getCompositeLayer(index);

  let width: number = zone?.Width * compositeStore.canvasRatio;
  let height: number = zone?.Height * compositeStore.canvasRatio;
  let x: number = zone?.X * compositeStore.canvasRatio;
  let y: number = zone?.Y * compositeStore.canvasRatio;
  // let basecolor: CompositeTemplateColor = zone?.BaseColor;
  return (
    <div
      className="composite-layer layer-image"
      style={{
        width,
        height,
        top: y,
        left: x,
        opacity: zone.Opacity,
        zIndex: index + 1,
        pointerEvents: "none",
        // backgroundColor: `rgba(${basecolor?.R},${basecolor?.G},${basecolor?.B},1)`,
        backgroundImage: `url(${
          zone.SmallImageUrl ? zone.SmallImageUrl : zone.ImageUrl
        })`,
      }}
      onClick={() => {
        handleClicked(index, null, null);
      }}
    ></div>
  );
};

export default inject("compositeStore")(observer(CompositeLayerImage));
