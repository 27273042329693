export default{

    "AccountPage": {
        "title": "Mi cuenta",
        "loginSubtitle": "Informacion de login",
        "loginText": "Inicia sesión como",
        "editUsername": "Editar nombre de usuario",
        "changePassword": "Cambiar contraseña",
        "personalInfoSubtitle": "Información personal",
        "familyMemberSubtitle": "Miembros de la familia",
        "edit": "Editar",
        "delete": "Eliminar",
        "default": "Por defecto",
        "paymentSubtitle": "Pago",
        "paymentText": "Actualizar la información de la tarjeta de crédito guardada",
        "communicationSubtitle": "Comunicación",
        "communicationText": "Para promociones y inscripciones en boletines informativos",
        "shippingSubtitle": "Direcciones de envío",
        "shippingText": "Uso para el pago como información de envío",
        "upcomingSubtitle": "Próximos",
        "upcomingText": "Confirmar reprogramación o cancelación",
        "noUpcomingText": "No tiene próximas sesiones",
        "tableDate": "Fecha",
        "tableTime": "Hora",
        "tableDescription": "Descripción",
        "tableAction": "Acción",
        "confirm": "Confirmar",
        "reschedule": "Cambiar la fecha de",
        "cancel": "Cancelar",
        "scheduleButton": "Programar ahora",
        "deleteAddressButton": "¿Está seguro de que desea eliminar esta dirección?",
        "updateAddressModalTitle": "Actualizar dirección",
        "updateUsernameModalTitle": "Actualizar nombre de usuario",
        "updatePasswordModalTitle": "Cambiar contraseña",
        "updatePersonalInfoModalTitle": "Actualizar información personal",
        "updateCommunicationModalTitle": "Actualizar opt-ins",
        "updatePaymentModalTitle": "Pago",
        "scheduleModalTitle": "Horario",
        "rescheduleModalTitle": "Cambiar la fecha de",
        "clickhere": "Haga clic aquí",
    },
    "ForgotPasswordPage": {
        "title": "Olvidó su contraseña",
        "subtitle": "Se enviará un correo electrónico a su dirección de correo electrónico con más instrucciones",
        "alertSuccess": "Usted debe recibir un correo electrónico en breve. Compruebe su carpeta de correo no deseado o correo no deseado si no puede encontrar el correo electrónico.",
        "backToLogin": "Volver al inicio de sesión",
    },
    "LoginPage": {
        "title": "¡Inicia sesión para ver imágenes increíbles inspiradas en ti!",
        "description": "Si ya tienes una cuenta, inicia sesión aquí para ver tus imágenes o haz clic en \"Búsqueda de eventos\" arriba para buscar un evento.",
        "email": "Dirección de correo electrónico o nombre de usuario",
        "password": "Contraseña",
        "forgotPass": "Contraseña olvidada",
        "emailError": "Introduzca una dirección de correo electrónico o un nombre de usuario",
        "ajaxError": "Nombre de usuario o contraseña no válidos",
        "passwordError": "Por favor, introduzca una contraseña",
        "loginButton": "Iniciar sesión",
    },
    "ResetPasswordPage": {
        "title": "Introduzca su nueva contraseña",
        "alertSuccess": "Su contraseña se ha restablecido correctamente. Se le redirigirá de nuevo a la página de inicio de sesión.",
        "backToLogin": "Volver al inicio de sesión",
    },
    "SignupPage": {
        "title": "Crear una cuenta",
        "p1": "¿Ya tienes una cuenta?",
        "p2": "para ver tus fotos.",
        "createAccountLink": "Inicia sesión aquí",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "signup": "Únete",
        "ajaxError": "Si algo salió mal. Por favor, inténtelo de nuevo.",
        "ajaxErrorOther": "Si algo salió mal. Por favor, inténtelo de nuevo.",
    },
    "ConfirmationPage": {
        "tabTitle": "Gracias",
        "title": "Gracias por hacer su pedido.",
        "text": "Su número de pedido es",
        "relatedProducts": "Es posible que te interese...",
    },
    "CheckoutPage": {
        "urlTitle": "Pago seguro",
        "title": "Pago seguro",
        "subtitle": "Página protegida SSL/TSL de 4096 bits",
    },
    "OrderReviewPage": {
        "urlTitle": "Pago seguro",
        "tableProductName": "Nombre del producto",
        "tablePrice": "Precio",
        "tableQuantity": "Cantidad",
        "tableSubtotal": "Subtotal de",
        "enter": "Introdúzcalo ahora",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "promo": "promoción",
        "shipping": "Envío",
        "tax": "Impuesto",
        "total": "Total",
    },
    "PaymentPage": {
        "urlTitle": "Pago seguro",
    },
    "ShippingAddressPage": {
        "urlTitle": "Pago seguro",
    },
    "ContractsPage": {
        "urlTitle": "Contratos",
        "title": "Contratos",
        "invalidPIN": "PIN no válido",
        "uploaded": "Subido",
        "accepted": "Aceptado",
        "printContract": "Contrato de impresión",
        "formLabel": "He leído y entendido los términos anteriores. Por la presente acepto los términos de este acuerdo.",
        "enterPin": "Ingrese su Pin Numérico",
        "confirm": "Confirmar y enviar",
    },
    "DashboardPage": {
        "loading": "Cargando...",
        "dashboard": "Tablero de reportes",
        "title": "Actualmente no tiene una sesión.",
        "check": "Echale un vistazo",
        "pendingContracts": "contratos pendientes",
        "contactUs": "contáctenos",
        "noImages": "Usted no tiene ninguna imagen para este",
    },
    "EventSearchPage": {
        "title": "Búsqueda de eventos y risas compartidos",
        "urlTitle": "Búsqueda de eventos",
        "p1": "Para encontrar un evento, seleccione uno de la lista o busque por código de evento o fecha de evento.",
        "date": "Fecha del evento",
        "code": "Código privado",
        "public": "Evento público",
        "search": "'Buscar'",
    },
    "FavoritesPage": {
        "urlTitle": "Favorito",
    },
    "FoldersPage": {
        "urlTitle": "Carpeta de",
        "viewFull": "Ver completo",
        "noFoldersFound": "No se han encontrado carpetas para esto",
    },
    "FormsPage": {
        "urlTitle": "Formas",
        "title": "Formas",
        "uploaded": "Subido",
    },
    "GuestsPage": {
        "urlTitle": "Huéspedes",
        "title": "Huéspedes",
        "error": "Se ha producido un error al actualizar la invitación de invitado.",
        "success": "Enlace resentido con éxito",
        "copy": "Copiado en el portapapeles",
        "createNew": "Crear una nueva",
        "invitationLinks": "Enlaces de invitación",
        "noLinksFound": "No se han encontrado enlaces para esto",
        "tableDescription": "Descripción",
        "tableDateCreated": "Fecha de creación",
        "tableDateFirstUsed": "Fecha utilizada por primera vez",
        "tableAction": "Acción",
        "copyLink": "Copiar enlace",
        "remove": "Quitar",
        "invitedTitle": "Invitados",
        "noGuestsFound": "No se han encontrado invitados para esto",
        "tableName": "Nombre",
        "tableEmail": "Correo electrónico",
        "tableDateInvited": "Fecha de invitación",
        "edit": "Editar",
        "resend": "Reenviar",
        "updateGuest": "Actualizar invitado",
        "createNewGuestInvitation": "Crear nueva invitación de invitado",
        "generateText": "Genera un enlace compartible para dar a otros acceso a tus imágenes. Comparte el enlace a través de las redes sociales un texto o a través de correo electrónico.",
        "emailInvite": "Invitación por correo electrónico",
        "sharableLink": "Enlace compartible",
    },
    "HelpPage": {
        "urlTitle": "Ayuda",
        "title": "Ayuda",
        "ourInfo": "Nuestra información",
        "hereToHelp": "Estamos aquí para ayudar",
        "chatBy": "Chatee con nosotros por",
        "clickingHere": "haciendo clic aquí",
    },
    "HomePage": {
        "signIn": "Inicia sesión",
        "eventSearch": "Búsqueda de eventos",
        "privacy": "Declaración de privacidad",
        "userAccessAgreement": "Acuerdo de acceso al usuario",
        "termsOfUse": "Condiciones de uso",
        "close": "Cerrar",
    },
    "OrderDetailsPage": {
        "urlTitle": "Orden",
        "photo": "Foto",
        "setCrop": "Establecer recortes y opciones",
        "cropRequired": "Se requiere cultivo antes de la compra",
        "viewOrders": "Ver pedidos",
        "order": "Orden",
        "details": "Datos de",
        "orderDate": "Fecha de la orden",
        "makePayment": "Realizar el pago",
        "printOrder": "Orden de impresión",
        "orderDetails": "Detalles de la orden",
        "tableProduct": "Producto",
        "tablePrice": "Precio",
        "tableQty": "Cant.",
        "tableTotal": "Total",
        "subtotal": "Subtotal de",
        "promo": "promoción",
        "shipping": "Envío",
        "tax": "Impuesto",
        "payment": "Pago",
        "total": "Total",
        "finalize": "Finalizar la selección",
        "shippingInfo": "Información de envío",
        "delivery": "Entrega",
        "confirm": "Confirmar envío",
        "cancel": "Cancelar",
        "warning": "Esta acción no se puede deshacer en la aplicación.",
        "submitting": "Presentar...",
        "yesConfirm": "Sí confirmar",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Número de pedido",
        "tableOrderDate": "Fecha de la orden",
        "tableDateCreated": "Fecha de creación",
        "tableShippedTo": "Enviado a",
        "tableStatus": "Estado",
        "tableDescription": "Descripción",
        "tableOpenBalance": "Saldo",
        "tableTotal": "Total",
        "orders": "Órdenes de",
        "orderHistory": "Historial de pedidos",
        "preOrders": "Pre-pedidos",
        "noOrders": "Actualmente aún no tienes ningún pedido.",
    },
    "SharePage": {
        "urlTitle": "Esta es la portada",
        "title": "Esta es la portada",
        "iframe": "Esta es una página en blanco de iframe.",
        "shareImage": "Comparte esta imagen",
    },
    "SplashPage": {
        "wereSorry": "Lo sentimos.",
        "doesntSeemTo": "no parece ser un token de recurso compartido válido.",
        "error": "Lamentamos que parezca que algo salió mal. Notifique al administrador del sitio si esto continúa.",
        "subdomainError": "Esta aplicación espera un subdominio. Por favor, compruebe la url y asegúrese de que se parece a <Nombre de su empresa>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Añadir a carpeta",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Social Share",
        "generatingShareable": "Generación de url compartible...",
    },
    "cartViewComponent": {
        "urlTitle": "Carrito de compras",
        "title": "Carrito de compras",
        "photo": "Foto",
        "swap": "Intercambio",
        "youHave": "Usted tiene",
        "youHaveAdded": "Ha añadido",
        "unitsRemaining": "unidades restantes para ser añadidos a su pedido.",
        "unitsTooMany": "unidades demasiados a su pedido.",
        "cartFullModalTitle": "Demasiadas unidades",
        "tooMany": "Esta orden contiene demasiadas unidades.  Por favor, retire algunos productos antes de hacer el check-out.",
        "have": "have",
        "another": "Otro",
        "a": "Un",
        "ok": "OK",
        "promoCode": "¿Código de promoción/cupón?",
        "promoCode1": "Código de promoción",
        "removePromoCode": "Eliminar código promocional",
        "select": "Seleccione",
        "loading": " Carga",
        "setCropAndOptions": "Establecer recortes y opciones",
        "cropRequired": "Se requiere cultivo antes de la compra",
        "checkout": "Check-out",
        "tableAction": "Acción",
        "tableProductName": "Nombre del producto",
        "tablePrice": "Precio",
        "tableQuantity": "Cantidad",
        "tableSubtotal": "Subtotal de",
        "enterNow": "Introdúzcalo ahora",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "promo": "promoción",
        "noItems": "No hay artículos en el carro.",
        "checkOurProducts": "Consulta nuestros productos",
        "continueShopping": "Continuar comprando",
    },
    "createOrderIndexComponent": {
        "title": "Crear un pedido",
        "step1cta": "Proceder",
        "finish": "Acabado",
    },
    "cropViewComponent": {
        "cropImage": "Recortar imagen",
        "prepaid": "Prepago",
        "shoppingCart": "Carrito de compras",
        "image": "Imagen",
        "zoomIn": "Acercar",
        "zoomOut": "Alejar",
        "reset": "Reiniciar",
        "cropOrientation": "Orientación del cultivo",
        "selectPhotoOption": "Seleccione la opción de foto",
        "pleaseSelect": "Por favor, seleccione...",
        "selectPhotoEnhancement": "Seleccionar mejora de la foto",
        "selectColorEffect": "Seleccionar efecto de color",
        "notes": "Notas",
        "apply": "Aplicar",
        "backToPreorder": "Volver al orden previo",
        "backToShoppingCart": "Volver al carro de la compra",
    },
    "photoSelectComponent": {
        "swapPhoto": "Intercambiar foto",
        "prepaid": "Prepago",
        "shoppingCart": "Carrito de compras",
        "backToPreorder": "Volver al orden previo",
        "backToShoppingCart": "Volver al carro de la compra",
    },
    "photoTileComponent": {
        "image": "Imagen",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Categoría",
        "title": "Categoría",
        "selectYourProduct": "Seleccione su producto",
        "selectCateogry": "Seleccionar categoría",
        "sortBy": "Ordenar por",
        "bestSelling": "Las más vendidas",
        "viewLarger": "Ver más grande",
        "viewDetails": "Ver detalles",
    },
    "productGroupListComponent": {
        "urlTitle": "Productos",
        "title": "Seleccione su categoría de producto",
        "viewTheseProducts": "Ver estos productos",
    },
    "productDetailTabsComponent": {
        "description": "Descripción",
        "features": "Funciones",
        "delivery": "Entrega",
        "noDescriptionFound": "No se ha encontrado ninguna descripción",
        "noFeatureFound": "No se ha encontrado información de características",
        "noDeliveryFound": "No se encontró información de entrega",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Error al agregar al carrito. Actualice la página o informe de este producto a nuestro soporte.",
        "product": "Producto",
        "cancelAndBack": "Cancelar y volver a la lista",
        "selectPhotoOption": "Seleccione la opción de foto",
        "pleaseSelect": "Por favor, seleccione...",
        "selectPhotoEnhancement": "Seleccionar enahncement fotográfico",
        "selectColorEffect": "Seleccionar efecto de color",
        "was": "Fue",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Seleccione una carpeta o cree una nueva",
    },
    "addressFormSectionComponent": {
        "firstName": "Nombre",
        "lastName": "Apellido",
        "addressLine1": "Dirección línea 1",
        "addressLine2": "Dirección línea 2",
        "city": "Ciudad",
        "region": "Región",
        "state": "Estado",
        "postalCode": "Código postal",
        "zipCode": "Código postal",
        "country": "País",
    },
    "addToCartFormComponent": {
        "quantity": "Cantidad",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Por favor, introduzca su dirección de correo electrónico",
        "email": "Correo electrónico",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Confirme su nueva contraseña",
        "password": "Contraseña",
        "enterPassword": "Introduzca su nueva contraseña",
        "confirmPassword": "Confirmar contraseña",
        "setNewPassword": "Establecer nueva contraseña",
    },
    "signupFormComponent": {
        "firstNameValidation": "Por favor, introduzca su nombre",
        "lastNameValidation": "Por favor, introduzca su apellido",
        "invalidEmailValidation": "Por favor, introduzca un correo electrónico válido",
        "emailRequiredValidation": "Por favor, introduzca una dirección de correo electrónico",
        "confirmPasswordValidation": "Confirme su contraseña",
        "countryRequiredValidation": "Por favor seleccione un país",
        "passwordsMustMatch": "Las contraseñas deben coincidir",
        "confirm": "Confirmar",
        "firstName": "Nombre",
        "lastName": "Apellido",
        "email": "Dirección de correo electrónico",
        "password": "Contraseña",
        "confirmPassword": "Confirmar contraseña",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Por favor, introduzca su nombre",
        "lastNameValidaiton": "Por favor, introduzca su apellido",
        "addressLine1Validation": "Por favor, introduzca su dirección de calle",
        "cityValidation": "Por favor, introduzca su ciudad",
        "stateValidation": "Por favor ingrese su estado",
        "postalCodeValidation": "Por favor, introduzca su código postal",
        "invalidPostalCode": "Introduzca un código postal válido",
        "countryValidation": "Por favor, introduzca su país",
        "emailValidation": "Por favor, introduzca un correo electrónico válido",
        "billingInformation": "Información de facturación",
        "selectSavedAddress": "Seleccione la dirección guardada",
        "email": "Dirección de correo electrónico",
        "phoneNumber": "Número de teléfono",
        "saveAsDefault": "Guardar complementos de facturación como mi valor predeterminado",
        "saveAsShipping": "Igual que la dirección de envío",
        "promotionalTexts": "¿Podemos enviarle mensajes promocionales?",
        "dataRates": "(Pueden aplicarse tarifas de datos y mensajes)",
        "promotionalEmails": "¿Podemos enviarle correos electrónicos promocionales?",
        "continue": "Continuar",
        "continueShopping": "Continuar comprando",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Uso de la dirección seleccionada",
        "useDefaultAddress": "Usar la dirección predeterminada",
        "useClientAddress": "Usar la dirección del cliente",
        "saveAsDefault": "Guardar como predeterminado",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Seleccione un método de envío",
    },
    "communicationFormComponent": {
        "permissionCall": "¿Podemos llamarlo con información promocional?",
        "permissionEmail": "¿Podemos enviarle un correo electrónico con información promocional?",
        "permissionText": "¿Podemos enviarle un mensaje de texto con información promocional? (Pueden aplicarse tarifas de mensajería y datos)",
    },
    "editAddressFormComponent": {
        "name": "Nombre",
        "phoneNumber": "Número de teléfono",
        "addressLine1": "Dirección línea 1",
        "addressLine2": "Dirección línea 2",
        "city": "Ciudad",
        "region": "Región",
        "postalCode": "Código postal",
        "country": "País",
        "deliveryInstructions": "Instrucciones de entrega",
        "accessCode": "Código de acceso",
        "defaultAddress": "Dirección predeterminada",
        "applyChange": "Aplicar cambio",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Por favor, introduzca su contraseña",
        "passwordCannotBeSame": "La nueva contraseña no puede ser la misma que la anterior",
        "confirmPassword": "Confirme su nueva contraseña",
        "passwordsMustMatch": "Las contraseñas deben coincidir",
        "existingPassword": "Contraseña existente",
        "currentPassword": "Contraseña actual",
        "newPassword": "Nueva contraseña",
        "confirmNewPassword": "Confirme nueva contraseña",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Seleccione un evento",
        "selectEventDate": "Seleccione una fecha de evento",
        "enterCode": "Por favor, introduzca un código",
    },
    "inputSectionComponent": {
        "selectDate": "Seleccione una fecha",
        "placeCodeHere": "Coloque el código aquí",
        "input": "Entrada",
        "selectEvent": "Seleccione un evento",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoritos",
        "ajaxError": "Se ha producido un error al crear una invitación de invitado",
        "invalidEmail": "Por favor, introduzca un correo electrónico válido",
        "noEmail": "Por favor, introduzca un correo electrónico",
        "noFirstName": "Por favor ingrese un nombre",
        "noLastName": "Por favor ingrese un apellido",
        "email": "Dirección de correo electrónico",
        "firstName": "Nombre",
        "lastName": "Apellido",
        "session": "Sesión",
        "image": "Imagen",
        "sendEmail": "Enviar correo electrónico",
        "onlyInclude": "Incluya solo Favoritos de este",
    },
    "joyFormComponent": {
        "ageMonth": "mes (es)",
        "yes": "Sí",
        "no": "No",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Por favor, introduzca una descripción para su enlace",
        "description": "Descripción",
        "onlyIncludeFavoritesFromThis": "Incluya solo Favoritos de este",
        "createLink": "Crear enlace",
    },
    "personalInfoComponent": {
        "invalidEmail": "Introduzca una dirección de correo electrónico válida",
        "primaryPhone": "Teléfono principal",
        "secondaryPhone": "Teléfono secundario",
        "mobilePhone": "Este es un teléfono móvil",
        "streetAddress": "Dirección de la calle",
        "house": "Casa",
        "apartment": "Apartamento",
        "roomNumber": "Número de habitación",
        "city": "Ciudad",
        "region": "Región",
        "state": "Estado",
        "postalCode": "Código postal",
        "country": "País",
        "pleaseSelect": "Por favor, seleccione...",
    },
    "familyMemberComponent": {
        "nickname": "Apodo",
        "familyMember": "Miembro de la familia",
        "relationship": "Relación",
        "gender": "Género",
        "BirthDate": "Fecha de nacimiento",
        "familyMemberSubtitle": "Miembros de la familia",
        "tableAction": "Acción",
    },
    "usernameFormComponent": {
        "enterUsername": "Introduce un nuevo nombre de usuario",
        "onlineUsername": "Nombre de usuario en línea",
        "username": "Nombre de usuario",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Más elementos de navegación",
        "download": "Descargar",
        "removeFrom": "Retirar de",
        "modifyImage": "Modificar imagen",
        "folders": "Carpetas",
        "makePrimary": "Hacer Primaria",
        "yearbookImage": "Imagen de Anuario",
        "makeSecondary": "Hacer secundario",
        "socialShare": "Social Share",
        "unhide": "Mostrar",
        "hide": "Ocultar",
    },
    "imageTileComponent": {
        "view": "Ver",
        "sessions": "Sesiónes de",
        "setAsPrimary": "Establecer como foto principal",
        "setAsSecondary": "Establecer como foto secundaria",
        "image": "Imagen",
        "caution": "Precaución",
        "separateSessionsMessage1": "No puede agregar imágenes de dos",
        "separateSessionsMessage2": "al mismo carro",
        "doNotAdd": "No añada este artículo a mi carrito.",
        "clearCart": "Borra el contenido de mi carrito y luego agrega este artículo a mi carrito.",
    },
    "loginPromptComponent": {
        "title": "¡Vaya!",
        "message": "Por favor --login-- o --registrarse-- si desea que las imágenes favoritas en grupo de imágenes juntas o agregue imágenes al carrito.",
    },
    "menuDropdownComponent": {
        "dashboard": "Tablero de reportes",
        "sessions": "Sesiónes de",
        "favorites": "Favoritos",
        "contracts": "Contratos",
        "orders": "Órdenes de",
        "prepaidOrders": "Pedidos prepagados",
        "guests": "Huéspedes",
        "account": "Cuenta",
    },
    "userDropdownComponent": {
        "logout": "Cerrar sesión",
    },
    "navBarPage": {
        "toggleNavigation": "Alternar la navegación",
        "cart": "Carro",
        "photoshootsAndImages": "Sesión de fotos e imágenes",
        "contracts": "Contratos",
        "reviewSubmit": "Revisar los contratos y presentar",
        "forms": "Formas",
        "reviewForms": "Revisar formularios web y enviar",
        "orders": "Órdenes de",
        "historyPrepaid": "Historial y pedidos prepagado",
        "orderHistory": "Historial de pedidos",
        "products": "Productos",
        "guests": "Huéspedes",
        "categoriesProducts": "Categorías y productos",
        "sharedFamily": "Compartido con la familia",
        "friends": "Amigos",
        "myAccount": "Mi cuenta",
        "login": "Iniciar sesión",
        "billingInfo": "información de facturación",
        "help": "Ayuda",
        "chat": "Chatear para soporte en línea",
        "shoppingCart": "Carrito de compras",
        "checkout": "Prepárate para pagar",
        "signout": "Cerrar sesión",
        "endGuest": "Fin de mi invitado",
        "securelySignout": "Permítanme cerrar la sesión de forma segura",
    },
    "productGalleryComponent": {
        "viewLarger": "Ver más grande",
    },
    "responsiveHeaderComponent": {
        "photos": "Fotos",
        "guests": "Huéspedes",
        "allImages": "Todas las imágenes",
        "favorites": "Favoritos",
        "folders": "Carpetas",
        "autoPlay": "Autoplay",
        "download": "Descargar",
        "cart": "Carro",
    },
    "sessionsPage": {
        "Name": "Nombre",
        "Date": "Fecha",
        "CreateNewText": "Crear una nueva",
        "ViewText": "Ver",
        "DeleteText": "Eliminar",
        "EditText": "Editar",
    },
}
