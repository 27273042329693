import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Button,
  Row,
  FormFeedback,
  Alert,
  CustomInput,
  Spinner,
} from "reactstrap";
import { observer, inject } from "mobx-react";
import { AxiosError } from "axios";
import InputFloat from "../inputFloat";
import { useTranslation } from "react-i18next";
import { SessionAbstract, Session } from "../../../interfaces/session.int";

const EmailGuestForm: React.FC<any> = ({
  guestStore,
  authStore,
  sessionStore,
  onSuccess,
  initialData,
  clientStore,
}) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");

  async function updateClient(obj: any) {
    let error = false;
    let update = {
      ...obj,
      Key: initialData?.key || "",
    };
    delete update.onlyFavorites;
    await guestStore
      .setGuest(update, { resendEmail: true })
      .catch((err: AxiosError) => {
        error = true;
        setAsyncErr(t("emailGuestFormComponent.ajaxError"));
      });
    if (!error) {
      onSuccess();
    }
  }

  const validationSchema = Yup.object().shape({
    Email: Yup.string()
      .email(t("emailGuestFormComponent.invalidEmail"))
      .required(t("emailGuestFormComponent.noEmail")),
    FirstName: Yup.string().required(t("emailGuestFormComponent.noFirstName")),
    LastName: Yup.string().required(t("emailGuestFormComponent.noLastName")),
  });

  const initialValues = {
    Email: initialData?.Email || "",
    FirstName: initialData?.FirstName || "",
    LastName: initialData?.LastName || "",
    SessionKey: "all",
    ImageGroup: "all",
    onlyFavorites: initialData?.ImageGroup?.toUpperCase() === "FAVORITES",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-12">
              <Field
                label={t("emailGuestFormComponent.email")}
                name="Email"
                className={`form-control ${errors.Email ? "is-invalid" : ""}`}
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="Email">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                label={t("emailGuestFormComponent.firstName")}
                name="FirstName"
                className={`form-control ${
                  errors.FirstName ? "is-invalid" : ""
                }`}
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="FirstName">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                label={t("emailGuestFormComponent.lastName")}
                name="LastName"
                className={`form-control ${
                  errors.LastName ? "is-invalid" : ""
                }`}
                component={InputFloat}
                disabled={isSubmitting}
              />
              <ErrorMessage name="LastName">
                {(msg) => <FormFeedback>{msg}</FormFeedback>}
              </ErrorMessage>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                as="select"
                label={t("emailGuestFormComponent.session")}
                name="SessionKey"
                className={`form-control`}
                disabled={isSubmitting}
                onChange={(e: any) =>
                  setFieldValue("SessionKey", e.target.value)
                }
              >
                <option value="all">{`All ${authStore.jobPlural}`}</option>
                {clientStore.clientInfo.Sessions.map(
                  (session: SessionAbstract) => (
                    <option value={session.SessionKey} key={session.SessionKey}>
                      {session.SessionDescription || session.SessionKey}
                    </option>
                  )
                )}
              </Field>
            </FormGroup>
            <FormGroup className="col-sm-12">
              <Field
                as="select"
                label={t("emailGuestFormComponent.image")}
                name="ImageGroup"
                className={`form-control`}
                disabled={isSubmitting}
                onChange={(e: any) =>
                  setFieldValue("ImageGroup", e.target.value)
                }
              >
                <option value="all">All Images</option>
                {values.SessionKey === "all" && (
                  <option value="favorites">Favorites</option>
                )}
                {values.SessionKey !== "all" &&
                  sessionStore.allSessionDetail
                    .filter(
                      (session: Session) => session.Key === values.SessionKey
                    )[0]
                    .Groups.map((group: any) => {
                      if (group.GroupName.toLowerCase() === "hidden") {
                        return <></>;
                      }
                      return (
                        <option value={group.GroupName} key={group.GroupName}>
                          {group.GroupName}
                        </option>
                      );
                    })}
              </Field>
            </FormGroup>
          </Row>
          {initialValues.onlyFavorites && (
            <Row className="mb-3">
              <FormGroup className="col-sm-12">
                <CustomInput
                  type="checkbox"
                  id="com1"
                  label={`${t(
                    "emailGuestFormComponent.onlyInclude"
                  )} ${authStore.jobSingular.toLowerCase()}`}
                  checked={values.onlyFavorites}
                  onChange={(e: any) =>
                    setFieldValue("onlyFavorites", e.target.checked)
                  }
                  disabled={isSubmitting}
                />
              </FormGroup>
            </Row>
          )}
          {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {t("emailGuestFormComponent.sendEmail")}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default inject(
  "guestStore",
  "authStore",
  "clientStore",
  "sessionStore",
  "imageGroupStore"
)(observer(EmailGuestForm));
