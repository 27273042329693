import { observable, action, computed, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import update from "immutability-helper";
import {
  Invoice,
  InvoiceLineItem,
  InvoicesAbstract,
} from "../interfaces/invoice.int";
import ClientStore from "./clientStore";
import SessionStore from "./sessionStore";
import { ShippingMethod } from "../interfaces/shipping.int";
import EcomPriceListBaseClass from "./ecomClasses/priceListBase";
import { PriceListGroup } from "../interfaces/priceList.int";
import { ShippingAddress } from "../interfaces/address.int";
import AuthStore from "./authStore";
import CompositeStore from "./compositeStore";

class EcomStoreClass extends EcomPriceListBaseClass {
  @persist @observable selectedImageFileName: string = "";
  @persist("object") @observable selectedImageData: any = {};
  @persist("list") @observable shippingMethods: Array<ShippingMethod> = [];
  @persist("list") @observable allInvoices: Array<InvoicesAbstract> = [];
  @persist("list") @observable allInvoiceDetail: Array<any> = [];
  @persist("object") @observable activeInvoice: any = {};
  @observable activeInvoiceLoading: boolean = false;
  @observable activeInvoiceError: boolean = false;
  @observable promoCodeError: string = "";

  @persist("list") @observable shippingAddresses: any = [];
  @persist("object") @observable cart: Invoice = {};
  @observable cartErrors: any = [];
  @persist @observable swapPhotoCartItemKey: string = "";
  @observable currentPackageItems: any = [];
  @persist("object") @observable successfulOrder?: any = {};

  @observable imageUpdate: any = null;
  @observable scrollCart: any = null;
  @observable searchTerm: string = "";

  @observable enhancementKey: string = "";
  @observable enhancementDescription: string = "";

  @observable optionKey: string = "";
  @observable optionDescription: string = "";

  @observable options: any = [];
  @observable enhancements: any = [];

  @observable colorizationKey: string = "";
  @observable notes: string = "";
  @observable hydrated: boolean = false;

  @computed get sessionKey() {
    return this.cart.SessionKey;
  }

  @computed get appliedPromotions() {
    return this.cart?.Promotions?.map((pro: any) => pro) || [];
  }

  @action async submitOrderNoPayment() {
    await this.updateCartMeta(this.cart, this.cart.Key, false, true);
    this.onCheckout();
  }

  @action async updateCartMeta(
    obj: any,
    key?: string,
    isPreOrder?: boolean,
    submitOrder?: boolean
  ) {
    const body = Object.assign({ Key: key ? key : this.cart.Key }, obj);
    return await axios({
      method: "PUT",
      url: `/ibyInvoice`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
        submitInvoice: submitOrder,
      },
      data: body,
    }).then((res: AxiosResponse) => {
      if (isPreOrder) {
        this.activeInvoice = res.data;
      } else {
        this.cart = res.data;
      }
    });
  }

  @action async refreshCart(obj: any) {
    const body = Object.assign({ Key: this.cart.Key }, obj);
    return await axios({
      method: "GET",
      url: `/ibyInvoice/${this.cart.Key}`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
      },
      data: body,
    })
      .then((res: AxiosResponse) => {
        this.cart = res.data;
      })
      .catch(() => this.clearCart());
  }

  @action clearCart() {
    this.cart = {};
  }

  @action setOptions(options: any) {
    this.options = options;
  }

  @action setEnhancements(enhancements: any) {
    this.enhancements = enhancements;
  }

  @computed get cartItems() {
    // console.log('cart items');
    // console.log(this.cart);
    return (
      this.cart?.InvoiceDetails?.map((d: InvoiceLineItem) => ({
        ...d,
        ItemizeImage: SessionStore.getImgUrl(d.SessionKey, d.FileName),
        ImageUrl:
          SessionStore.getImgUrl(d.SessionKey, d.FileName) + d.CropQueryString,
        PreviewUrl: SessionStore.getImgUrl(d.SessionKey, d.FileName, true),
        aspectRatio: this.getAspectRatio(d.ItemKey),
        imageKey: SessionStore.getImgKey(d.SessionKey, d.FileName),
        photoRequired: this.getPhotoRequired(d.ItemKey),
        allowImageSelection: this.getAllowImageSelection(d),
        compositeTemplateKey: this.getCompositeTemplateKey(d.ItemKey),
        compositeImage: this.getCompositeImage(d.ItemKey),
      })) || []
    );
  }

  @action getItemByKey(itemKey?: string) {
    return this.priceList.Groups?.map((item: PriceListGroup) => item.Items)
      ?.flat(1)
      ?.find((item) => item?.Key === itemKey);
  }

  @action getAspectRatio(itemKey?: string) {
    return this.getItemByKey(itemKey)?.CropRatio;
  }

  @action getCompositeTemplateKey(itemKey?: string) {
    return this.getItemByKey(itemKey)?.CompositeTemplateKey;
  }

  @action getCompositeImage(itemKey?: string) {
    return this.getItemByKey(itemKey)?.ProductImages;
  }

  @action getPhotoRequired(itemKey?: string) {
    return this.getItemByKey(itemKey)?.RequireImage;
  }
  @action getOptionRequired(itemKey?: string) {
    return this.getItemByKey(itemKey)?.RequireOption;
  }
  @action getEnhancementRequired(itemKey?: string) {
    return this.getItemByKey(itemKey)?.RequireEnhancement;
  }

  @action getAllowImageSelection(lineItem?: any) {
    var itemKey = lineItem?.ItemKey;
    var item = this.getItemByKey(itemKey);
    if (item == null || item?.AllowImageSelection) {
      return true;
    } else {
      return false;
    }
  }

  @action setCroppedPhoto(key: string, isInvoice: boolean = false) {
    this.croppedPhoto = isInvoice
      ? this.cartInvoiceItems?.find((i: any) => i.Key === key)
      : this.cartItems.find((i: any) => i.Key === key);
  }

  @action async deleteCartItem(itemKey: string, cartKey: string) {
    this.imageUpdate = true;
    return await axios({
      method: "DELETE",
      url: `/ibyInvoiceLineItem/${itemKey}`,
      params: {
        invoiceKey: cartKey,
      },
    })
      .then((res: AxiosResponse) => {
        this.cart = res.data;
        this.imageUpdate = false;
        this.cartErrors = [];
      })
      .catch(() => (this.imageUpdate = false));
  }

  @action async deleteCart() {
    return await axios({
      method: "DELETE",
      url: `/IBYInvoice/${this.cart.Key}`,
    })
      .then((res: AxiosResponse) => {
        this.cart = {};
        this.cartErrors = [];
        ClientStore.clearPendingOrderKey();
      })
      .catch(() => {});
  }

  @action async addToCart(quantity: number, isPrepaidOrder: boolean = false) {
    /*
      console.log('Add to cart');
      console.log('Session: ' + SessionStore.activeSession.Key );
      console.log('Item Key: ' + this.selectedImageData.key );
      console.log('Filname: ' + this.selectedImageFileName );
      console.log('Item data:');
      console.log( this.selectedImageData );
      */

    // Blank out successful order property.
    this.successfulOrder = null;

    return axios({
      method: "PUT",
      url: `/ibyInvoiceLineItem`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
        isPrepaidOrder,
      },
      data: {
        InvoiceKey: this.cart.Key || "",
        SessionKey: SessionStore.activeSession.Key,
        ItemKey: this.selectedProduct.Key,
        Quantity: Number(quantity),
        FileName: this.selectedImageFileName,
        EnhancementKey: this.enhancementKey,
        EnhancementDescription: this.enhancementDescription,
        OptionKey: this.optionKey,
        OptionDescription: this.optionDescription,
        ColorizationKey: this.colorizationKey,
        Notes: this.notes,
        CompositeKey: CompositeStore.activeComposite.Key || null,
        Options: this.options,
        Enhancements: this.enhancements,
      },
    }).then((res: AxiosResponse) => {
      this.cancelSelectedImage();
      this.cart = res.data;
      this.cartErrors = [];
      // console.log('Saved cart');
      // console.log(res.data);
    });
  }

  @action swapPackageComponent(itemKey: any, replacementItemKey: any) {
    return axios({
      method: "PUT",
      url: "/ibyInvoiceLineItem",
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
      },
      data: {
        InvoiceKey: this.cart.Key || "",
        Key: itemKey,
        ItemKey: replacementItemKey,
      },
    })
      .then((res: AxiosResponse) => {
        this.cart = res.data;
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  @action updateCartItem(
    item: any,
    sessionKey?: string,
    isImage?: boolean,
    isInvoice: boolean = false
  ) {
    this.activeInvoiceLoading = true;
    const defaultData = {
      InvoiceKey: this.cart.Key || "",
      ItemKey: this.selectedProduct.Key,
    };
    //console.log('Update cart');
    //console.log(item);
    //console.log(Object.assign(defaultData, item));

    if (isImage) {
      this.imageUpdate = item.Key;
    }

    //console.log('request');
    //console.log(item);

    return axios({
      method: "PUT",
      url: `/ibyInvoiceLineItem`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: sessionKey || SessionStore.activeSession.Key,
      },
      data: Object.assign(defaultData, item),
    })
      .then((res: AxiosResponse) => {
        this.activeInvoiceLoading = false;
        //console.log('response');
        //console.log(res.data.InvoiceDetails);
        if (isInvoice) {
          //console.log('update');
          const index = this.allInvoiceDetail.findIndex(
            (r) => r.Key === res.data.Key
          );
          const newData = update(this.allInvoiceDetail, {
            $splice: [[index, 1, res.data]],
          });
          //console.log(newData);
          this.allInvoiceDetail = newData;
          this.activeInvoice = res.data;
        } else {
          this.cart = res.data;
        }
        this.imageUpdate = null;
      })
      .catch(() => {
        this.imageUpdate = null;
        this.activeInvoiceLoading = false;
      });
  }

  @action enterPromoCode(code: string) {
    return axios({
      method: "PUT",
      url: `/ibyInvoicePromo`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
      },
      data: {
        promoCode: code,
        invoiceKey: this.cart.Key,
      },
    })
      .then((res: AxiosResponse) => {
        this.cart = res.data;
        this.promoCodeError = "";
      })
      .catch((err) => {
        this.promoCodeError = err.response.data.split(".")[0];
      });
  }

  @computed get orderHistory() {
    return this.allInvoices?.filter((inv: Invoice) => !inv?.PrepaidOrder);
  }

  @computed get preOrders() {
    return this.allInvoices?.filter((inv: Invoice) => !!inv?.PrepaidOrder);
  }

  @action async getOrderHistoryDetail(key: string, forceAjax = false) {
    if (
      this.allInvoiceDetail.map((inv: Invoice) => inv.Key).includes(key) &&
      !forceAjax
    ) {
      this.activeInvoice = this.allInvoiceDetail.find(
        (inv: Invoice) => inv.Key === key
      );
      return;
    } else {
      this.activeInvoiceLoading = true;
      return axios({
        method: "GET",
        url: `/ibyInvoice/${key}`,
        params: {
          clientKey: ClientStore.clientInfo.Key,
          SessionKey: SessionStore.activeSession.Key,
        },
      })
        .then((res: AxiosResponse) => {
          this.allInvoiceDetail.push(res.data);
          this.activeInvoice = res.data;
          this.activeInvoiceLoading = false;
          console.log("from apis");
          console.log(res.data);
        })
        .catch((err: AxiosError) => {
          this.activeInvoiceLoading = false;
          this.activeInvoiceError = true;
        });
    }
  }

  @computed get cartInvoiceItems() {
    return (
      this.activeInvoice?.InvoiceDetails?.map((d: InvoiceLineItem) => ({
        ...d,
        ItemizeImage: SessionStore.getImgUrl(d.SessionKey, d.FileName),
        ImageUrl: SessionStore.getImgUrl(d.SessionKey, d.FileName),
        PreviewUrl: SessionStore.getImgUrl(d.SessionKey, d.FileName, true),
        aspectRatio: this.getAspectRatio(d.ItemKey),
        imageKey: SessionStore.getImgKey(d.SessionKey, d.FileName),
        photoRequired: this.getPhotoRequired(d.ItemKey),
        allowImageSelection: this.getAllowImageSelection(d),
        PreOrder: true,
        compositeTemplateKey: this.getCompositeTemplateKey(d.ItemKey),
        compositeImage: this.getCompositeImage(d.ItemKey),
      })) || []
    );
  }

  @action deletePromoCode(code: string) {
    return axios({
      method: "DELETE",
      url: `/ibyInvoicePromo`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
        SessionKey: SessionStore.activeSession.Key,
        promoCode: code,
        invoiceKey: this.cart.Key,
      },
    }).then((res: AxiosResponse) => {
      this.cart = res.data;
    });
  }

  @action getAllInvoices() {
    this.activeInvoiceLoading = true;
    return axios({
      method: "GET",
      url: `/ibyInvoices`,
      params: {
        clientKey: ClientStore.clientInfo.Key,
      },
    })
      .then((res: AxiosResponse) => {
        this.allInvoices = res.data;
        this.activeInvoiceLoading = false;
        ClientStore.clientInfo.OrderCount = this.allInvoices.length;
      })
      .catch((err) => {
        this.activeInvoiceLoading = false;
      });
  }

  @action getInvoiceByKey(key: string) {
    return axios({
      method: "GET",
      url: `/ibyInvoice/${key}`,
    }).then((res: AxiosResponse) => {
      this.cart = res.data;
    });
  }

  @action getAllShippingMethods() {
    return axios({
      method: "GET",
      url: `/ibyShippingMethods`,
      params: {
        InvoiceKey: this.cart.Key,
      },
    }).then((res: AxiosResponse) => {
      this.shippingMethods = res.data;
    });
  }

  @computed get orderRequiresShipping() {
    if (!this.cart?.InvoiceDetails) return false;
    for (let invoiceItem of this.cart?.InvoiceDetails) {
      const item = this.getItemByKey(invoiceItem.ItemKey);
      if (!item?.NoShipping) {
        return true;
      }
    }
    return false;
  }

  @computed get defaultShippingAddress() {
    const filtered = this.shippingAddresses.filter((add: any) => add.Default);
    if (!!filtered.length) {
      return filtered[0];
    } else if (!filtered.length && this.shippingAddresses.length) {
      return this.shippingAddresses[0];
    } else {
      return null;
    }
  }
  @computed get allShippingAddresses() {
    return this.shippingAddresses?.map((add: ShippingAddress) => add) || [];
  }
  @computed get allShippingAddressesFormattedForDropdown() {
    return (
      this.shippingAddresses
        ?.filter((add: any) => !add.Default)
        ?.map((add: any) => ({
          label: `${add.Line1} ${add.City}, ${add.State} ${add.Postcode}`,
          value: toJS(add),
        })) || []
    );
  }

  @action getAllShippingAddresses() {
    return axios({
      method: "GET",
      url: `/ibyClientShippingAddress`,
    }).then((res: AxiosResponse) => {
      this.shippingAddresses = res.data;
    });
  }

  @action async setShippingAddress(add: ShippingAddress) {
    return await axios({
      method: "PUT",
      url: `/ibyClientShippingAddress`,
      data: { ...add, ClientKey: ClientStore.clientInfo.Key },
    }).then((res: AxiosResponse) => {
      this.shippingAddresses = res.data;
    });
  }

  @action deleteShippingAddress(key: string) {
    return axios({
      method: "DELETE",
      url: `/ibyClientShippingAddress/${key}`,
    }).then((res: AxiosResponse) => {
      this.shippingAddresses = this.shippingAddresses.filter(
        (add: ShippingAddress) => add.Key !== res.data
      );
    });
  }

  @action onCheckout() {
    this.successfulOrder = this.cart;
    this.cart = {};
    ClientStore.clientInfo.PendingOrderKey = "";
    this.getAllInvoices();
  }

  isImageRequired(cartItem: any) {
    return cartItem.photoRequired && !cartItem.imageKey;
  }

  isCropRequired(cartItem: any) {
    if (
      AuthStore.companyName === "Messina Photography" ||
      AuthStore.companyName === "Charleen's Portrait Studio LLC"
    )
      return false;
    return (
      cartItem.photoRequired &&
      !cartItem.CompositeKey &&
      cartItem.aspectRatio != 0 &&
      cartItem.CropLeft === 0 &&
      cartItem.CropWidth === 0 &&
      cartItem.CropWidth === 0 &&
      cartItem.CropWidth === 0
    );
  }

  isCompositeRequired(cartItem: any) {
    return false;
    // return (
    //   cartItem.compositeTemplateKey &&
    //   AuthStore.EnableCompositeTemplates &&
    //   !cartItem.CompositeKey
    // );
  }

  isEnhancementRequired(cartItem: any) {
    return (
      this.getEnhancementRequired(cartItem.ItemKey) &&
      cartItem.Enhancements.length === 0
    );
  }

  isOptionRequired(cartItem: any) {
    return (
      this.getOptionRequired(cartItem.ItemKey) &&
      cartItem.Enhancements.length === 0
    );
  }

  @action validateCart(items?: any) {
    let validateItems;
    if (items) {
      validateItems = items;
    } else {
      validateItems = this.cartItems;
    }
    const validArr = validateItems.map((item: any) => {
      let error = "";
      if (this.cart.PrepaidOrder) {
        return error;
      }
      if (this.isImageRequired(item)) {
        error += "imageRequired";
      }
      if (this.isCropRequired(item)) {
        // has not been cropped
        error += "cropRequired";
      }
      if (this.isCompositeRequired(item)) {
        error += "compositeRequired";
      }
      if (this.isEnhancementRequired(item)) {
        error += "enhancementRequired";
      }
      if (this.isOptionRequired(item)) {
        error += "optionRequired";
      }
      return error;
    });
    this.cartErrors = [...validArr];

    const validItems = validateItems.map((item: any, index: number) => {
      return {
        key: item.Key,
        isValid: validArr[index] === "",
      };
    });

    return validItems?.find((itm: any) => {
      return !itm.isValid;
    });
  }

  @action async onLogin(priceListKey: string = "", invoiceKey: string = "") {
    this.getAllShippingAddresses();
    this.getColorizationOptions();
    if (!!priceListKey && ClientStore?.clientInfo?.Key) {
      this.getPriceList(priceListKey);
    }
    if (!!invoiceKey) {
      await this.getInvoiceByKey(invoiceKey);
    }
    if (!!this.cart?.ShowShippingMethods) {
      await this.getAllShippingMethods();
    }
    await this.getAllInvoices();
  }

  @action cancelSelectedImage() {
    this.optionKey = this.enhancementKey = this.enhancementDescription = "";
    this.selectedProduct = {};
    this.selectedImageFileName = "";
    this.selectedImageData = {};
  }

  @action onLogout() {
    this.shippingMethods = [];
    this.priceList = {};
    this.selectedProductGroup = {};
    this.selectedProduct = {};
    this.selectedImageFileName = "";
    this.selectedImageData = {};
    this.allInvoices = [];
    this.allInvoiceDetail = [];
    this.cart = {};
    this.croppedPhoto = {};
    this.swapPhotoCartItemKey = "";
    this.colorizationOptions = [];
    this.shippingMethods = [];
    this.shippingAddresses = [];
  }
}
const hydrate = create({});
const EcomStore = new EcomStoreClass();
export default EcomStore;
hydrate("ecom", EcomStore).then((store) => {
  store.hydrated = true;
});
