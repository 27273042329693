import axios from "axios";
import AuthStore from "../../stores/authStore";
import EcomStore from "../../stores/ecomStore";
import GlobalErrorStore from "../../stores/globalErrorStore";

export function setAxiosClientKey(key: string) {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  axios.defaults.params["clientKey"] = key;
}

export function setAxiosSessionKey(key: string) {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  axios.defaults.params["sessionKey"] = key;
}

export function setAxiosLocationNumber(key: string) {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  if (key) {
    axios.defaults.params["locationNumber"] = key;
  }
}

export function setAxiosPriceListKey(key: string) {
  if (!axios.defaults.params) {
    axios.defaults.params = {};
  }
  if (key) {
    axios.defaults.params["priceListKey"] = key;
  }
}

export default function setAxiosDefaults(accountName: string, token: string) {
  axios.defaults.headers.common["app_source"] = "StudioPlus.IBY_2.0";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${accountName}`;

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        GlobalErrorStore.setErrorMessage(
          "Due to inactivity and for security purposes, you have been logged out. Please log back in."
        );
        AuthStore.logout();
      } else if (
        error?.response?.status === 400 &&
        error?.response?.statusText.includes("Client Login Token expired")
      ) {
        GlobalErrorStore.loginErrorMessage = "This link has expired.";
      } else if (
        error?.response.status === 400 &&
        AuthStore.token &&
        Object.keys(EcomStore.cart).length > 0
      ) {
        GlobalErrorStore.setErrorMessage(error.response.data);
      }
      return Promise.reject(error);
    }
  );
}
