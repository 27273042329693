//@ts-nocheck
import React, { useState, useRef, useEffect } from "react";
//import './index.scss'
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  Label,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Progress,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

import { MdClose } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
import ProductDetailTabs from "./productDetailTabs/productDetailTabs";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import AddToCartForm, {
  AddToCartFormData,
} from "../../forms/addToCart/addToCartForm";
import ProductGallery from "../../product-gallery/productGallery";
import RelatedProducts from "../../relatedProducts/relatedProducts";
import { AiFillFormatPainter } from "react-icons/ai";

const SingleProductDetail: React.FC<any> = ({
  authStore,
  ecomStore,
  sessionStore,
  createOrderStore,
  compositeStore,
}) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [modalCart, setModalCart] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [ajaxError, setAjaxError] = useState<any>();
  const scrollTarget: any = useRef(null);
  const [photoOptions, setPhotoOptions] = useState<any>([]);
  const [photoEnhancements, setPhotoEnhancements] = useState<any>([]);
  const [price, setPrice] = useState(0);

  function productRecommendationSelected() {
    scrollTarget.current.scrollIntoView({ behavior: "smooth" });
  }

  console.log(modalCart);

  const toggleCartModal = (e: any) => {
    setModalCart(!modalCart);
    e.preventDefault();
  };

  async function handleDeleteCartAndAddItem(e: any) {
    // Show spinner for delete button
    setSpinner(true);

    // Call DELETE API
    await ecomStore.deleteCart();

    // Toggle Modal
    toggleCartModal(e);

    // Set spinner to false
    setSpinner(false);
    return;
    // Return flow to addCartToSelected
    goToCart(e);
  }

  async function goToCart(obj: AddToCartFormData) {
    if (ecomStore.cart.InvoiceDetails?.length) {
      for (let cartItem of ecomStore.cart.InvoiceDetails) {
        if (sessionStore.activeSession.Key !== cartItem.SessionKey) {
          toggleCartModal();
          return;
        }
      }
    }
    var imageFileName = ecomStore.selectedImageFileName;
    setQuantity(obj.quantity);
    setSubmitting(true);
    if (ecomStore.selectedProduct?.ItemType === "Package") {
      ecomStore.selectedImageFileName = "";
    }
    await ecomStore
      .addToCart(obj.quantity)
      .then(async () => {
        ecomStore.setEnhancements([]);
        ecomStore.setOptions([]);
        let newItem;
        if (ecomStore.cartItems.slice(-1)[0].PackageItemKey) {
          const index =
            ecomStore.cartItems.toReversed().find((item) => item.PackageHeader)
              .LineID + 1;
          ecomStore.currentPackageItems = ecomStore.cartItems.slice(index - 1);
          newItem = ecomStore.cartItems.find((item) => item.LineID === index);
        } else {
          newItem = ecomStore.cartItems.slice(-1)[0];
          ecomStore.currentPackageItems = [];
        }
        const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
        const options = priceItem.Options;
        const enhancements = priceItem.Enhancements;
        if (ecomStore.cart?.PrepaidOrder) {
          createOrderStore.setStepNumber(4);
        } else if (newItem.compositeTemplateKey) {
          ecomStore.scrollCart = newItem.Key;
          ecomStore.swapPhotoCartItemKey = newItem.Key;
          await compositeStore.handleComposite(newItem, imageFileName);

          createOrderStore.setStepNumber(7);
        } else if (newItem.ReplacementItems?.length > 0) {
          ecomStore.setCroppedPhoto(newItem.Key);
          createOrderStore.setStepNumber(8);
        } else if (
          (ecomStore.isImageRequired(newItem) && !newItem.FileName) ||
          (newItem.allowImageSelection && !newItem.FileName)
        ) {
          sessionStore.swappedPhoto = newItem;
          ecomStore.swapPhotoCartItemKey = newItem.Key;
          ecomStore.scrollCart = newItem.Key;
          createOrderStore.setStepNumber(5);
        } else if (ecomStore.isCropRequired(newItem)) {
          ecomStore.setCroppedPhoto(newItem.Key);
          ecomStore.scrollCart = newItem.Key;
          createOrderStore.setStepNumber(6);
        } else if (options?.length > 0 || enhancements?.length > 0) {
          ecomStore.setCroppedPhoto(newItem.Key);
          ecomStore.scrollCart = newItem.Key;
          createOrderStore.setStepNumber(6);
        } else if (newItem.PackageItemKey) {
          const updatedItem = ecomStore.currentPackageItems.find(
            (item: any) => item.Key === newItem.Key
          );
          let updatedItemIdx = ecomStore.currentPackageItems.findIndex(
            (item: any) => item.Key === newItem.Key
          );
          let found = false;
          while (
            newItem?.PackageItemKey &&
            ecomStore.currentPackageItems &&
            updatedItemIdx + 1 < ecomStore.currentPackageItems.length &&
            !found
          ) {
            const newItem = ecomStore.currentPackageItems[updatedItemIdx + 1];
            const priceItem = ecomStore.findItemByKey(newItem.ItemKey);
            const options = priceItem.Options;
            const enhancements = priceItem.Enhancements;

            if (newItem.compositeTemplateKey) {
              ecomStore.scrollCart = newItem.Key;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              await compositeStore.handleComposite(newItem);
              createOrderStore.setStepNumber(7);
              found = true;
            } else if (newItem.ReplacementItems?.length > 0) {
              ecomStore.setCroppedPhoto(newItem.Key);
              createOrderStore.setStepNumber(8);
              found = true;
            } else if (
              ecomStore.isImageRequired(newItem) ||
              newItem.allowImageSelection
            ) {
              sessionStore.swappedPhoto = newItem;
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(5);
              found = true;
            } else if (
              ecomStore.isCropRequired(newItem) ||
              options?.length > 0 ||
              enhancements?.length > 0
            ) {
              ecomStore.swapPhotoCartItemKey = newItem.Key;
              ecomStore.setCroppedPhoto(newItem.Key);
              ecomStore.scrollCart = newItem.Key;
              createOrderStore.setStepNumber(6);
              found = true;
            } else {
              updatedItemIdx += 1;
            }

            if (updatedItemIdx + 1 === ecomStore.currentPackageItems.length) {
              createOrderStore.setStepNumber(4);
            }
          }
        } else {
          createOrderStore.setStepNumber(4);
        }
        setSubmitting(false);
      })
      .catch(() => {
        setAjaxError(t("singleProductDetailComponent.ajaxError"));
        setSubmitting(false);
      });
  }

  function handlePhotoOptions(values: any, actionMeta) {
    let options: any = photoOptions;
    switch (actionMeta.action) {
      case "remove-value":
        setPrice(price - actionMeta.removedValue.price);
        options.find(
          (option) => option.OptionKey == actionMeta.removedValue.value
        ).Remove = true;
        break;
      case "select-option":
        setPrice(price + actionMeta.option.price);
        if (options.length) {
          if (
            options.find(
              (option) => option.OptionKey == actionMeta.option.value
            )
          ) {
            options.find(
              (option) => option.OptionKey == actionMeta.option.value
            ).Remove = false;
          } else {
            options.push({
              OptionKey: actionMeta.option.value,
              OptionDescription: actionMeta.option.label,
              Remove: false,
            });
          }
        } else {
          options.push({
            OptionKey: actionMeta.option.value,
            OptionDescription: actionMeta.option.label,
            Remove: false,
          });
        }
        break;
      case "clear":
        setPrice(ecomStore.selectedProduct.Price);
        for (let item of options) {
          item.Remove = true;
        }
        break;
    }
    setPhotoOptions(options);
    ecomStore.setOptions(options);
  }

  function handlePhotoEnhancement(
    values: any,
    actionMeta: {
      action: any;
      removedValue: { value: any };
      option: { value: any; label: any };
    }
  ) {
    let options: any = photoEnhancements;

    switch (actionMeta.action) {
      case "remove-value":
        setPrice(price - actionMeta.removedValue.price);
        options.find(
          (option: { EnhancementKey: any }) =>
            option.EnhancementKey === actionMeta.removedValue.value
        ).Remove = true;
        break;
      case "select-option":
        setPrice(price + actionMeta.option.price);
        if (options.length) {
          if (
            options.find(
              (option: { EnhancementKey: any }) =>
                option.EnhancementKey === actionMeta.option.value
            )
          ) {
            options.find(
              (option: { EnhancementKey: any }) =>
                option.EnhancementKey === actionMeta.option.value
            ).Remove = false;
          } else {
            options.push({
              EnhancementKey: actionMeta.option.value,
              EnhancementDescription: actionMeta.option.label,
              Remove: false,
            });
          }
        } else {
          options.push({
            EnhancementKey: actionMeta.option.value,
            EnhancementDescription: actionMeta.option.label,
            Remove: false,
          });
        }
        break;
      case "clear":
        for (let item of options) {
          item.Remove = true;
        }
        break;
    }
    setPhotoEnhancements(options);
    ecomStore.setEnhancements(options);
  }

  useEffect(() => {
    affirm.ui.refresh();
  }, [price]);

  useEffect(() => {
    setPrice(ecomStore.selectedProduct.Price);
  }, [ecomStore.selectedProduct.Price]);

  useEffect(() => {
    return () => {
      setPhotoOptions([]);
    };
  }, [photoOptions]);

  useEffect(() => {
    affirm.ui.refresh();
  }, []);

  useEffect(() => {
    console.log("companyName", authStore?.companyName);
    console.log("accountName", authStore?.accountName);
  });
  const CustomOption = (props: {
    data: any;
    innerRef: any;
    innerProps: any;
  }) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div className="optionLabel" ref={innerRef} {...innerProps}>
        {data.customLabel} <span>+ {`$${data.price.toFixed(2)}`}</span>
      </div>
    );
  };

  if (compositeStore.loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div className="mb-1" style={{ textAlign: "center" }}>
          Loading Template Builder
        </div>
        <div style={{ textAlign: "center" }}>
          <Spinner color="primary" />
        </div>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>
          {ecomStore?.selectedProductGroup?.Name
            ? ecomStore?.selectedProductGroup?.Name
            : t("singleProductDetailComponent.product")}
          :{" "}
          {ecomStore?.selectedProduct?.Name
            ? ecomStore?.selectedProduct?.Name
            : ""}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      {!ecomStore.selectedProduct.Key ? (
        <div className="text-center mt-5">
          <Spinner
            color="primary"
            style={{ height: "2.5rem", width: "2.5rem" }}
          />
        </div>
      ) : (
        <section
          className="product-modal-container"
          id="modal_body_123123123"
          ref={scrollTarget}
        >
          <div className="product-modal-head">
            <Button
              color="default-link"
              onClick={() => createOrderStore.decrementStep()}
            >
              <FiArrowLeft className="mr-2" />
              {t("singleProductDetailComponent.cancelAndBack")}
            </Button>
          </div>
          <div className="product-modal-list">
            <div className="row">
              <div
                className={
                  ecomStore?.selectedProductGalleryImages?.length
                    ? "col-md-6"
                    : "col-12"
                }
              >
                <h1>{ecomStore.selectedProduct.Name}</h1>
                <div style={{ maxWidth: 400 }}>
                  {!!ecomStore.selectedProductOptionsFormattedForDropdown
                    .length && (
                    <FormGroup className="form-field-float active">
                      <Label>
                        {t("singleProductDetailComponent.selectPhotoOption")}
                      </Label>
                      {true ? (
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          // options={[{ label: "None", value: "" }].concat(
                          //   ecomStore.selectedProductOptionsFormattedForDropdown
                          // )}
                          options={
                            ecomStore.selectedProductOptionsFormattedForDropdown
                          }
                          components={{ Option: CustomOption }}
                          className="react-select-group-container"
                          classNamePrefix="react-select-group"
                          placeholder={t(
                            "singleProductDetailComponent.pleaseSelect"
                          )}
                          onChange={(values: any, actionMeta: any) => {
                            handlePhotoOptions(values, actionMeta);
                            // ecomStore.optionKey = e.value;
                            // ecomStore.optionDescription = e.label;
                          }}
                          // defaultValue={{ label: "None", value: "" }}
                          isDisabled={submitting}
                        />
                      ) : (
                        <Select
                          options={[
                            { label: "None", value: "", price: 0 },
                          ].concat(
                            ecomStore.selectedProductOptionsFormattedForDropdown
                          )}
                          components={{ Option: CustomOption }}
                          className="react-select-group-container"
                          classNamePrefix="react-select-group"
                          placeholder={t(
                            "singleProductDetailComponent.pleaseSelect"
                          )}
                          onChange={(e: any) => {
                            ecomStore.optionKey = e.value;
                            ecomStore.optionDescription = e.label;
                          }}
                          defaultValue={{ label: "None", value: "" }}
                          isDisabled={submitting}
                        />
                      )}
                    </FormGroup>
                  )}
                  {!!ecomStore.selectedProductEnhancementsFormattedForDropdown
                    .length && (
                    <FormGroup className="form-field-float active">
                      <Label>
                        {t(
                          "singleProductDetailComponent.selectPhotoEnhancement"
                        )}
                      </Label>
                      <Select
                        options={
                          ecomStore.selectedProductEnhancementsFormattedForDropdown
                        }
                        components={{ Option: CustomOption }}
                        className="react-select-group-container"
                        classNamePrefix="react-select-group"
                        placeholder={t(
                          "singleProductDetailComponent.pleaseSelect"
                        )}
                        onChange={(values: any, actionMeta: any) => {
                          console.log(values);
                          handlePhotoEnhancement(values, actionMeta);

                          // ecomStore.optionKey = e.value;
                          // ecomStore.optionDescription = e.label;
                        }}
                        isMulti
                        // defaultValue={{ label: "None", value: "" }}
                        isDisabled={submitting}
                      />
                    </FormGroup>
                  )}
                  {!!ecomStore.selectedProduct?.AllowImageSelection &&
                    !!ecomStore.colorizationOptionsFormattedForDropdown
                      ?.length && (
                      <FormGroup className="form-field-float active">
                        <Label>
                          {t("singleProductDetailComponent.selectColorEffect")}
                        </Label>
                        <Select
                          className="react-select-group-container"
                          classNamePrefix="react-select-group"
                          placeholder={t(
                            "singleProductDetailComponent.pleaseSelect"
                          )}
                          options={[{ label: "None", value: "" }].concat(
                            ecomStore.colorizationOptionsFormattedForDropdown
                          )}
                          onChange={(e: any) => {
                            ecomStore.colorizationKey = e.value;
                          }}
                          defaultValue={{ label: "None", value: "" }}
                          isDisabled={submitting}
                        />
                      </FormGroup>
                    )}
                </div>
                <p className="price-group price mt-4">
                  <span className="price-selling">
                    {ecomStore.selectedProduct.PriceStringFormatted.charAt(0) +
                      price.toFixed(2)}
                  </span>
                  {!!ecomStore?.selectedProduct?.PreviousPrice && (
                    <>
                      &nbsp;
                      <span className="price-orig">
                        {t("singleProductDetailComponent.was")}{" "}
                        {ecomStore.selectedProduct.PreviousPriceStringFormatted}
                      </span>
                    </>
                  )}
                </p>
                {authStore.AffirmEnabledOnline && (
                  <p
                    className="affirm-as-low-as"
                    data-page-type="product"
                    data-amount={(price * 100).toString()}
                  ></p>
                )}

                {ecomStore.cart.PrepaidOrder}
                <AddToCartForm
                  onSubmit={goToCart}
                  initialData={{ quantity }}
                  ajaxError={ajaxError}
                />
              </div>
              {ecomStore?.selectedProductGalleryImages?.length > 0 && (
                <div className="col-md-6 mt-5 mt-md-0">
                  <div className="image text-center">
                    <ProductGallery />
                  </div>
                </div>
              )}
            </div>

            <ProductDetailTabs className="mt-5 product-tabs" />
            <RelatedProducts onSelect={productRecommendationSelected} />
          </div>
          <Modal
            isOpen={modalCart}
            toggle={toggleCartModal}
            wrapClassName="modal-box"
            modalTransition={{ timeout: 500 }}
            backdropTransition={{ timeout: 0 }}
            centered
            size="md"
          >
            <ModalHeader
              toggle={toggleCartModal}
              close={
                <Button
                  color="icon-action"
                  className="close"
                  onClick={toggleCartModal}
                >
                  <MdClose size="18" />
                </Button>
              }
            >
              {t("imageTileComponent.caution")}
            </ModalHeader>
            <ModalBody>
              <p>
                {t("imageTileComponent.separateSessionsMessage1")}{" "}
                {t("imageTileComponent.sessions", {
                  sessions: authStore.jobPlural.toLowerCase(),
                })}{" "}
                {t("imageTileComponent.separateSessionsMessage2")}
              </p>
              <Row>
                <Col>
                  <Button onClick={toggleCartModal} block className="mb-3">
                    {t("imageTileComponent.doNotAdd")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={handleDeleteCartAndAddItem}
                    block
                    color="danger"
                  >
                    {spinner ? (
                      <Spinner size="lg" />
                    ) : (
                      t("imageTileComponent.clearCart")
                    )}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </section>
      )}
    </>
  );
};
export default inject(
  "authStore",
  "ecomStore",
  "createOrderStore",
  "sessionStore",
  "compositeStore"
)(observer(SingleProductDetail));
