import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Alert } from "reactstrap";

import { postVariants } from "../../components/util";
import ForgotPasswordForm from "../../components/forms/auth/forgotPasswordForm";

const ForgotPasswordPage: React.FC<any> = ({ authStore, navigationStore }) => {
  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <h1>{t("ForgotPasswordPage.title")}</h1>
        <p>{t("ForgotPasswordPage.subtitle")}</p>
        <ForgotPasswordForm onSuccess={() => setShowSuccess(true)} />
        {showSuccess && (
          <Alert color="success">{t("ForgotPasswordPage.alertSuccess")}</Alert>
        )}
        <p>
          <Link to={`/home/login`}>{t("ForgotPasswordPage.backToLogin")}</Link>
        </p>
      </motion.div>
      <Helmet>
        <title>
          {t("ForgotPasswordPage.title")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore"
)(observer(ForgotPasswordPage));
