import {observable, action, computed} from 'mobx';
import { create, persist } from 'mobx-persist'
import { EcomEvent } from '../interfaces/event.int';
import axios from 'axios';

class EventStoreClass {
    

    // events
    @persist('list') @observable allPublicEcomEvents:Array<EcomEvent> = [];
    @observable ecomEventsByDate:Array<EcomEvent> = [];
    @persist('object') @observable activeEcomEvent:EcomEvent = {};

    @observable eventByDateQueried:boolean = false;

    

    @observable hydrated:boolean = false;



    // formatted for dropdown on select event page
    @computed get dropdownPublicEcomEvents() {
        return this.allPublicEcomEvents.map(e=>
            (
                {
                    label:e?.Description || '', 
                    value:e?.SessionKey || '',
                    date:e?.EventDate || ''
                }
            )
        )
    }

    @action getPublicEcomEventById(id:string) {
        return axios({
            method:'GET',
            url:`/ibyevent/${id}`
        }).then(res=>{

            this.activeEcomEvent = res.data;
            return res.data;
        })
    }

    @action getEcomEventsByDate(date:string) {
        return axios({
            method:'GET',
            url:'/ibyevents',
            params:{
                eventDate:date
            }
        }).then(res=>{
            this.eventByDateQueried = true;
            this.ecomEventsByDate = res.data;
        })
    }
    @computed get ecomEventsByDateExist() {
        return !!this.ecomEventsByDate?.length;
    }

    @computed get noEventFoundForDate() {
        return !this.ecomEventsByDateExist && this.eventByDateQueried;
    }
    // formatted for dropdown on select event page after date selection
    @computed get dropdownEcomEventsByDate() {
        return this.ecomEventsByDate.map(e=>
            (
                {
                    label:e?e.Description:'', 
                    value:e?e.SessionKey:''
                }
            )
        )
    }


    @action getAllPublicEcomEvents() {
        axios({
            method:'GET',
            url:'/ibyevents'
        }).then(res=>{
            this.allPublicEcomEvents = res.data;
        })
    }
}

const hydrate = create({});
const EventStore = new EventStoreClass();
hydrate('event', EventStore).then((EventStore)=>{
	EventStore.hydrated = true;

})
export default EventStore;