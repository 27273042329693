import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { FormGroup, Label, FormFeedback, Button, Spinner } from "reactstrap";
import { MdMailOutline, MdLockOutline, MdPermIdentity } from "react-icons/md";
import { allCountriesFormattedForDropdownUSAFirst, password } from "../../util";
import { useTranslation } from "react-i18next";

interface ChildProps {
  onSubmit: (obj: SignupFormData) => void;
  firstNameLabel: string;
  lastNameLabel: string;
  emailLabel: string;
  passwordLabel: string;
  ajaxError: string;
  country: string;
  ajaxErrorOther: string;
}

export interface SignupFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  country: string;
  confirm_password: string;
  statusDescription: string;
  statusDueDate: string;
}

const SignupForm: React.FC<ChildProps> = ({
  onSubmit,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  country,
  passwordLabel,
  ajaxError,
  ajaxErrorOther,
}) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t("signupFormComponent.firstNameValidation")
    ),
    lastName: Yup.string().required(
      t("signupFormComponent.lastNameValidation")
    ),
    email: Yup.string()
      // TODO add regex for password
      .email(t("signupFormComponent.invalidEmailValidation"))
      .required(t("signupFormComponent.emailRequiredValidation")),

    country: Yup.string().required(
      t("signupFormComponent.countryRequiredValidation")
    ),

    password: password(),
    confirm_password: Yup.string()
      .required(t("signupFormComponent.confirmPasswordValidation"))
      .test(
        "passwords-match",
        t("signupFormComponent.passwordsMustMatch"),
        function (value) {
          return this.parent.password === value;
        }
      ),
  });

  const initialValues: SignupFormData = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    password: "",
    confirm_password: "",
    statusDescription: "",
    statusDueDate: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          {ajaxError && (
            <div className="alert alert-danger" role="alert">
              {ajaxError}
            </div>
          )}

          {ajaxErrorOther && (
            <div className="alert alert-danger" role="alert">
              {ajaxErrorOther}
            </div>
          )}

          <FormGroup className="form-group-icon">
            <Label
              htmlFor="firstName_input"
              className={`${
                errors.firstName && touched.firstName ? "error " : ""
              }sr-only`}
            >
              {firstNameLabel}
            </Label>
            <MdPermIdentity size="20" />
            <Field
              className={`form-control ${
                errors.firstName && touched.firstName ? "is-invalid" : ""
              }`}
              type="text"
              name="firstName"
              id="firstName_input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              placeholder={t("signupFormComponent.firstName")}
            />
            <ErrorMessage name="firstName">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="lastName_input"
              className={`${
                errors.lastName && touched.lastName ? "error " : ""
              }sr-only`}
            >
              {lastNameLabel}
            </Label>
            <MdPermIdentity size="20" />
            <Field
              className={`form-control ${
                errors.lastName && touched.lastName ? "is-invalid" : ""
              }`}
              type="text"
              name="lastName"
              id="lastName_input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              placeholder={t("signupFormComponent.lastName")}
            />
            <ErrorMessage name="lastName">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="email_input"
              className={`${
                errors.email && touched.email ? "error " : ""
              }sr-only`}
            >
              {emailLabel}
            </Label>
            <MdMailOutline size="20" />
            <Field
              className={`form-control ${
                errors.email && touched.email ? "is-invalid" : ""
              }`}
              type="email"
              name="email"
              id="email_input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t("signupFormComponent.email")}
            />
            <ErrorMessage name="email">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>

          <FormGroup className="form-field-float white-bg country-dropdown active">
            <Label htmlFor="country">
              {t("addressFormSectionComponent.country")}
            </Label>

            <Select
              id="country"
              classNamePrefix="react-select-group"
              className={`react-select-group-container ${
                errors.country && touched.country ? " is-invalid" : ""
              }`}
              name="country"
              placeholder="Please Select"
              defaultValue={allCountriesFormattedForDropdownUSAFirst.find(
                (opt: any) => opt.value === values.country
              )}
              options={allCountriesFormattedForDropdownUSAFirst}
              onChange={(val: any) => {
                setFieldValue("country", val.value);
              }}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
            />
          </FormGroup>

          <ErrorMessage name="country">
            {(msg) => <FormFeedback>{msg}</FormFeedback>}
          </ErrorMessage>

          <FormGroup className="form-group-icon">
            <Label
              htmlFor="password_input"
              className={`${
                errors.password && touched.password ? "error " : ""
              }sr-only`}
            >
              {passwordLabel}
            </Label>
            <MdLockOutline size="20" />
            <Field
              id="password_input"
              className={`form-control ${
                errors.password && touched.password ? "is-invalid" : ""
              }`}
              type="password"
              name="password"
              placeholder={t("signupFormComponent.password")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <ErrorMessage name="password">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup className="form-group-icon">
            <Label
              htmlFor="confirm_password_input"
              className={`${
                errors.confirm_password && touched.confirm_password
                  ? "error "
                  : ""
              }sr-only`}
            >
              {t("signupFormComponent.confirm")} {passwordLabel}
            </Label>
            <MdLockOutline size="20" />
            <Field
              id="confirm_password_input"
              className={`form-control ${
                errors.confirm_password && touched.confirm_password
                  ? "is-invalid"
                  : ""
              }`}
              type="password"
              name="confirm_password"
              placeholder={t("signupFormComponent.confirmPassword")}
            />
            <ErrorMessage name="confirm_password">
              {(msg) => <FormFeedback>{msg}</FormFeedback>}
            </ErrorMessage>
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            block
            className={isSubmitting ? "wait" : ""}
            disabled={isSubmitting}
          >
            {`Create an Account`}{" "}
            {isSubmitting && <Spinner color="#fff" size="sm" />}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
