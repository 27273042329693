export default{

    "AccountPage": {
        "title": "Min konto",
        "loginSubtitle": "Login Info",
        "loginText": "Logget ind som",
        "editUsername": "Rediger brugernavn",
        "changePassword": "Skift adgangskode",
        "personalInfoSubtitle": "Personlige oplysninger",
        "familyMemberSubtitle": "Familiemedlemmer",
        "edit": "Rediger",
        "delete": "Slet",
        "default": "Standard",
        "paymentSubtitle": "Betaling",
        "paymentText": "Opdater gemte kreditkortoplysninger",
        "communicationSubtitle": "Kommunikation",
        "communicationText": "For kampagner og nyhedsbreve opt-ins",
        "shippingSubtitle": "Leveringsadresser",
        "shippingText": "Bruges til kassen som forsendelsesoplysninger",
        "upcomingSubtitle": "Kommende",
        "upcomingText": "Bekræft omplanlæg eller annuller",
        "noUpcomingText": "Du har ingen kommende sessioner",
        "tableDate": "Dato",
        "tableTime": "Tidspunkt",
        "tableDescription": "Beskrivelse",
        "tableAction": "Handling",
        "confirm": "Bekræfte",
        "reschedule": "Omplanlæg",
        "cancel": "Annuller",
        "scheduleButton": "Planlæg nu",
        "deleteAddressButton": "Er du sikker på, at du vil slette denne adresse?",
        "updateAddressModalTitle": "Opdater adresse",
        "updateUsernameModalTitle": "Opdater brugernavn",
        "updatePasswordModalTitle": "Skift adgangskode",
        "updatePersonalInfoModalTitle": "Opdatere personlige oplysninger",
        "updateCommunicationModalTitle": "Opdater tilmeldinger",
        "updatePaymentModalTitle": "Betaling",
        "scheduleModalTitle": "Tidsplan",
        "rescheduleModalTitle": "Omplanlæg",
        "clickhere": "Klik her",
    },
    "ForgotPasswordPage": {
        "title": "Glemt kodeord",
        "subtitle": "En e-mail vil blive sendt til din e-mail-adresse med yderligere instruktioner",
        "alertSuccess": "Du bør modtage en e-mail snarest. Tjek din junk eller spam e-mail mappe, hvis du ikke kan finde e-mailen.",
        "backToLogin": "Tilbage for at logge ind",
    },
    "LoginPage": {
        "title": "Log ind for at se fantastiske billeder inspireret af dig!",
        "description": "Hvis du allerede har en konto logge ind her for at se dine billeder eller klik på 'Event Search' ovenfor for at søge efter en begivenhed.",
        "email": "E-mailadresse eller brugernavn",
        "password": "Adgangskode",
        "forgotPass": "Glemt adgangskode",
        "emailError": "Indtast en e-mailadresse eller et brugernavn",
        "ajaxError": "Ugyldigt brugernavn eller ugyldigt brugernavn",
        "passwordError": "Angiv en adgangskode",
        "loginButton": "Login",
    },
    "ResetPasswordPage": {
        "title": "Angiv din nye adgangskode",
        "alertSuccess": "Adgangskoden er blevet nulstillet. Du vil blive omdirigeret tilbage til login-siden.",
        "backToLogin": "Tilbage for at logge ind",
    },
    "SignupPage": {
        "title": "Oprette en konto",
        "p1": "Har du allerede en konto?",
        "p2": "for at se dine billeder.",
        "createAccountLink": "Log ind her",
        "email": "E-mail",
        "password": "Adgangskode",
        "signup": "Tilmeld dig",
        "ajaxError": "Ups noget gik galt. Prøv igen.",
        "ajaxErrorOther": "Ups noget gik galt. Prøv igen.",
    },
    "ConfirmationPage": {
        "tabTitle": "Tak",
        "title": "Tak, fordi du afgiver din ordre.",
        "text": "Dit ordrenummer er",
        "relatedProducts": "Du er måske interesseret i...",
    },
    "CheckoutPage": {
        "urlTitle": "Sikker betaling",
        "title": "Sikker betaling",
        "subtitle": "4096-bit SSL/TSL-beskyttet side",
    },
    "OrderReviewPage": {
        "urlTitle": "Sikker betaling",
        "tableProductName": "Produktnavn",
        "tablePrice": "Pris",
        "tableQuantity": "Mængde",
        "tableSubtotal": "Subtotal",
        "enter": "Indtast det nu",
        "apply": "Anvende",
        "cancel": "Annuller",
        "promo": "Promo",
        "shipping": "Forsendelse",
        "tax": "Skat",
        "total": "I alt",
    },
    "PaymentPage": {
        "urlTitle": "Sikker betaling",
    },
    "ShippingAddressPage": {
        "urlTitle": "Sikker betaling",
    },
    "ContractsPage": {
        "urlTitle": "Kontrakter",
        "title": "Kontrakter",
        "invalidPIN": "Ugyldig pinkode",
        "uploaded": "Uploadet",
        "accepted": "Accepteret",
        "printContract": "Udskriv kontrakt",
        "formLabel": "Jeg har læst og forstået ovenstående vilkår. Jeg accepterer hermed vilkårene i denne aftale.",
        "enterPin": "Indtast din numeriske pin",
        "confirm": "Bekræft og send",
    },
    "DashboardPage": {
        "loading": "Indlæser...",
        "dashboard": "Dashboard",
        "title": "Du har i øjeblikket ikke en session.",
        "check": "Check",
        "pendingContracts": "ventende kontrakter",
        "contactUs": "kontakt os",
        "noImages": "Du har ingen billeder til denne",
    },
    "EventSearchPage": {
        "title": "Søg efter fælles begivenheder og grin",
        "urlTitle": "Søgning efter begivenheder",
        "p1": "Hvis du vil finde en begivenhed, skal du vælge en på listen eller søge efter hændelseskode eller hændelsesdato.",
        "date": "Dato for begivenheden",
        "code": "Privat kode",
        "public": "Offentlig begivenhed",
        "search": "'Søg'",
    },
    "FavoritesPage": {
        "urlTitle": "Favorit",
    },
    "FoldersPage": {
        "urlTitle": "Mappe",
        "viewFull": "Vis fuld",
        "noFoldersFound": "Der blev ikke fundet nogen mapper til dette",
    },
    "FormsPage": {
        "urlTitle": "Former",
        "title": "Former",
        "uploaded": "Uploadet",
    },
    "GuestsPage": {
        "urlTitle": "Gæsterne",
        "title": "Gæsterne",
        "error": "Der opstod en fejl under opdatering af gæsteinvitationen.",
        "success": "Linket blev sendt i direkte forbindelse",
        "copy": "Kopieret til Udklipsholder",
        "createNew": "Opret en ny",
        "invitationLinks": "Links til invitationer",
        "noLinksFound": "Der blev ikke fundet nogen links til dette",
        "tableDescription": "Beskrivelse",
        "tableDateCreated": "Dato for oprettet",
        "tableDateFirstUsed": "Dato, der er brugt første gang",
        "tableAction": "Handling",
        "copyLink": "Kopier link",
        "remove": "Fjern",
        "invitedTitle": "Inviterede gæster",
        "noGuestsFound": "Ingen gæster fundet for dette",
        "tableName": "Navn",
        "tableEmail": "E-mail",
        "tableDateInvited": "Dato inviteret",
        "edit": "Rediger",
        "resend": "Sende",
        "updateGuest": "Opdater gæst",
        "createNewGuestInvitation": "Opret ny gæsteinvitation",
        "generateText": "Generer et link, der kan deles, for at give andre adgang til dine billeder. Del linket via sociale medier en tekst eller via e-mail.",
        "emailInvite": "Invitation af mail",
        "sharableLink": "Link, der kan deles",
    },
    "HelpPage": {
        "urlTitle": "Hjælp",
        "title": "Hjælp",
        "ourInfo": "Vores oplysninger",
        "hereToHelp": "Vi er her for at hjælpe",
        "chatBy": "Chat med os ved",
        "clickingHere": "At klikke her",
    },
    "HomePage": {
        "signIn": "Log på",
        "eventSearch": "Søgning efter begivenheder",
        "privacy": "Erklæring om beskyttelse af personlige oplysninger",
        "userAccessAgreement": "Brugeradgangsaftale",
        "termsOfUse": "Vilkår for anvendelse",
        "close": "Luk",
    },
    "OrderDetailsPage": {
        "urlTitle": "Rækkefølge",
        "photo": "Foto",
        "setCrop": "Angive beskæring og indstillinger",
        "cropRequired": "Beskæring er påkrævet før kassen",
        "viewOrders": "Vis ordrer",
        "order": "Rækkefølge",
        "details": "Detaljer",
        "orderDate": "Ordredato",
        "makePayment": "Fore foretage betaling",
        "printOrder": "Udskrive ordre",
        "orderDetails": "Ordreoplysninger",
        "tableProduct": "Produkt",
        "tablePrice": "Pris",
        "tableQty": "Antal",
        "tableTotal": "I alt",
        "subtotal": "Subtotal",
        "promo": "Promo",
        "shipping": "Forsendelse",
        "tax": "Skat",
        "payment": "Betaling",
        "total": "I alt",
        "finalize": "Afslutte markeringen",
        "shippingInfo": "Shipping Info",
        "delivery": "Levering",
        "confirm": "Bekræft indsendelse",
        "cancel": "Annuller",
        "warning": "Denne handling kan ikke fortrydes i appen.",
        "submitting": "Indsende...",
        "yesConfirm": "Ja bekræft",
    },
    "OrderHistoryPage": {
        "tableOrderNumber": "Ordrenummer",
        "tableOrderDate": "Ordredato",
        "tableDateCreated": "Dato for oprettet",
        "tableShippedTo": "Sendt til",
        "tableStatus": "Status",
        "tableDescription": "Beskrivelse",
        "tableOpenBalance": "Åbne saldo",
        "tableTotal": "I alt",
        "orders": "Ordrer",
        "orderHistory": "Ordrehistorik",
        "preOrders": "Forudbestillinger",
        "noOrders": "I øjeblikket har du ikke nogen ordre endnu.",
    },
    "SharePage": {
        "urlTitle": "Dette er titelsiden",
        "title": "Dette er titelsiden",
        "iframe": "Dette er en iframe tom side.",
        "shareImage": "Del dette billede",
    },
    "SplashPage": {
        "wereSorry": "Vi beklager",
        "doesntSeemTo": "synes ikke at være et gyldigt sharetoken.",
        "error": "Vi er kede af, at det ser ud til, at noget gik galt. Giv webstedets administrator besked, hvis dette fortsætter.",
        "subdomainError": "Denne app forventer et underdomæne. Kontroller url'en, og sørg for, at den ser ud som <Dit firmanavn>.inspiredbyyou.com",
    },
    "AddImageToGroupModalComponent": {
        "addToFolder": "Føj til mappe",
    },
    "AddImageToShareModalComponent": {
        "socialShare": "Social andel",
        "generatingShareable": "Generering af url-adresse, der kan deles...",
    },
    "cartViewComponent": {
        "urlTitle": "Indkøbskurv",
        "title": "Indkøbskurv",
        "photo": "Foto",
        "swap": "Swap",
        "youHave": "Du har",
        "youHaveAdded": "Du har tilføjet",
        "unitsRemaining": "enheder, der mangler at blive føjet til din ordre.",
        "unitsTooMany": "enheder for mange til din ordre.",
        "cartFullModalTitle": "For mange enheder",
        "tooMany": "Denne ordre indeholder for mange enheder.  Fjern nogle produkter, før du tjekker ud.",
        "have": "Har",
        "another": "Anden",
        "a": "A",
        "ok": "Ok",
        "promoCode": "promo / kupon kode?",
        "promoCode1": "Kampagnekode",
        "removePromoCode": "Fjern kampagnekode",
        "select": "Vælg",
        "loading": "Indlæsning",
        "setCropAndOptions": "Angive beskæring og indstillinger",
        "cropRequired": "Beskæring er påkrævet før kassen",
        "checkout": "Tjek ud",
        "tableAction": "Handling",
        "tableProductName": "Produktnavn",
        "tablePrice": "Pris",
        "tableQuantity": "Mængde",
        "tableSubtotal": "Subtotal",
        "enterNow": "Indtast det nu",
        "apply": "Anvende",
        "cancel": "Annuller",
        "promo": "Promo",
        "noItems": "Ingen varer i vognen.",
        "checkOurProducts": "Tjek vores produkter",
        "continueShopping": "Fortsæt med at handle",
    },
    "createOrderIndexComponent": {
        "title": "Oprette en ordre",
        "step1cta": "Fortsætte",
        "finish": "Finish",
    },
    "cropViewComponent": {
        "cropImage": "Beskær billede",
        "prepaid": "Forudbetalte",
        "shoppingCart": "Indkøbskurv",
        "image": "Billede",
        "zoomIn": "Zoom ind",
        "zoomOut": "Zoom ud",
        "reset": "Nulstille",
        "cropOrientation": "Beskæringsretning",
        "selectPhotoOption": "Vælg fotoindstilling",
        "pleaseSelect": "Vælg...",
        "selectPhotoEnhancement": "Vælg fotoforbedring",
        "selectColorEffect": "Vælg farveeffekt",
        "notes": "Noter",
        "apply": "Anvende",
        "backToPreorder": "Tilbage til forudbestilling",
        "backToShoppingCart": "Tilbage til indkøbskurven",
    },
    "photoSelectComponent": {
        "swapPhoto": "Byt foto",
        "prepaid": "Forudbetalte",
        "shoppingCart": "Indkøbskurv",
        "backToPreorder": "Tilbage til forudbestilling",
        "backToShoppingCart": "Tilbage til indkøbskurven",
    },
    "photoTileComponent": {
        "image": "Billede",
    },
    "productGroupDetailComponent": {
        "urlTitle": "Kategori",
        "title": "Kategori",
        "selectYourProduct": "Vælg dit produkt",
        "selectCateogry": "Vælg kategori",
        "sortBy": "Sorter efter",
        "bestSelling": "Bedst sælgende",
        "viewLarger": "Se større",
        "viewDetails": "Vis detaljer",
    },
    "productGroupListComponent": {
        "urlTitle": "Produkter",
        "title": "Vælg din produktkategori",
        "viewTheseProducts": "Se disse produkter",
    },
    "productDetailTabsComponent": {
        "description": "Beskrivelse",
        "features": "Funktioner",
        "delivery": "Levering",
        "noDescriptionFound": "Der blev ikke fundet nogen beskrivelse",
        "noFeatureFound": "Der blev ikke fundet nogen funktionsoplysninger",
        "noDeliveryFound": "Der blev ikke fundet nogen leveringsoplysninger",
    },
    "singleProductDetailComponent": {
        "ajaxError": "Der opstod en fejl under tilføjelse til indkøbsvognen. Opdater siden, eller rapportér dette produkt til vores support.",
        "product": "Produkt",
        "cancelAndBack": "Annuller og tilbage til listen",
        "selectPhotoOption": "Vælg fotoindstilling",
        "pleaseSelect": "Vælg...",
        "selectPhotoEnhancement": "Vælg foto enahncement",
        "selectColorEffect": "Vælg farveeffekt",
        "was": "Var",
    },
    "addImageToGroupFormComponent": {
        "selectFolder": "Vælg en mappe, eller opret ny",
    },
    "addressFormSectionComponent": {
        "firstName": "Fornavn",
        "lastName": "Efternavn",
        "addressLine1": "Adresselinje 1",
        "addressLine2": "Adresselinje 2",
        "city": "City",
        "region": "Regionen",
        "state": "Staten",
        "postalCode": "Postnummer",
        "zipCode": "Postnummer",
        "country": "Land",
    },
    "addToCartFormComponent": {
        "quantity": "Mængde",
    },
    "forgotPasswordFormComponent": {
        "emailValidationError": "Indtast din e-mailadresse",
        "email": "E-mail",
    },
    "resetPasswordFormComponent": {
        "passwordValidation": "Bekræft din nye adgangskode",
        "password": "Adgangskode",
        "enterPassword": "Angiv din nye adgangskode",
        "confirmPassword": "Bekræft adgangskode",
        "setNewPassword": "Angiv ny adgangskode",
    },
    "signupFormComponent": {
        "firstNameValidation": "Indtast dit fornavn",
        "lastNameValidation": "Indtast dit efternavn",
        "invalidEmailValidation": "Angiv en gyldig e-mail",
        "emailRequiredValidation": "Indtast en e-mailadresse",
        "confirmPasswordValidation": "Bekræft din adgangskode",
        "countryRequiredValidation": "Vælg et land",
        "passwordsMustMatch": "Adgangskoder skal svare til",
        "confirm": "Bekræfte",
        "firstName": "Fornavn",
        "lastName": "Efternavn",
        "email": "E-mail-adresse",
        "password": "Adgangskode",
        "confirmPassword": "Bekræft adgangskode",
    },
    "billingAddressFormComponent": {
        "firstNameValidation": "Indtast dit fornavn",
        "lastNameValidaiton": "Indtast dit efternavn",
        "addressLine1Validation": "Indtast din adresse",
        "cityValidation": "Indtast din by",
        "stateValidation": "Indtast din tilstand",
        "postalCodeValidation": "Indtast dit postnummer",
        "invalidPostalCode": "Angiv et gyldigt postnummer",
        "countryValidation": "Indtast dit land",
        "emailValidation": "Angiv en gyldig e-mail",
        "billingInformation": "Oplysninger om fakturering",
        "selectSavedAddress": "Vælg gemt adresse",
        "email": "E-mail-adresse",
        "phoneNumber": "Telefonnummer",
        "saveAsDefault": "Gem faktureringsdingre som standard",
        "saveAsShipping": "Samme som leveringsadresse",
        "promotionalTexts": "Må vi sende dig salgsfremmende tekster?",
        "dataRates": "(Data- og meddelelsestakster kan være gældende)",
        "promotionalEmails": "Må vi sende dig salgsfremmende e-mails?",
        "continue": "Fortsat",
        "continueShopping": "Fortsæt med at handle",
    },
    "shippingSectionComponent": {
        "usingSelectedAddress": "Brug af markeret adresse",
        "useDefaultAddress": "Brug standardadresse",
        "useClientAddress": "Brug klientadresse",
        "saveAsDefault": "Gem som standard",
    },
    "shippingAddressFormComponent": {
        "shippingMethodValidation": "Vælg en forsendelsesmetode",
    },
    "communicationFormComponent": {
        "permissionCall": "Må vi ringe til dig med salgsfremmende oplysninger?",
        "permissionEmail": "Må vi sende dig en e-mail med salgsfremmende oplysninger?",
        "permissionText": "Må vi sms'e dig med salgsfremmende oplysninger? (Der kan gælde besked- og datatakster)",
    },
    "editAddressFormComponent": {
        "name": "Navn",
        "phoneNumber": "Telefonnummer",
        "addressLine1": "Adresselinje 1",
        "addressLine2": "Adresselinje 2",
        "city": "City",
        "region": "Regionen",
        "postalCode": "Postnummer",
        "country": "Land",
        "deliveryInstructions": "Leveringsinstruktioner",
        "accessCode": "Adgangskode",
        "defaultAddress": "Standardadresse",
        "applyChange": "Anvend ændring",
    },
    "editPasswordFormComponent": {
        "enterPassword": "Indtast din adgangskode",
        "passwordCannotBeSame": "Ny adgangskode kan ikke være den samme som den gamle",
        "confirmPassword": "Bekræft din nye adgangskode",
        "passwordsMustMatch": "Adgangskoder skal svare til",
        "existingPassword": "Eksisterende adgangskode",
        "currentPassword": "Aktuel adgangskode",
        "newPassword": "Ny adgangskode",
        "confirmNewPassword": "Bekræft ny adgangskode",
    },
    "eventSearchFormComponent": {
        "selectEvent": "Vælg en begivenhed",
        "selectEventDate": "Vælg en begivenhedsdato",
        "enterCode": "Angiv en kode",
    },
    "inputSectionComponent": {
        "selectDate": "Vælg en dato",
        "placeCodeHere": "Placer kode her",
        "input": "Input",
        "selectEvent": "Vælg en begivenhed",
    },
    "emailGuestFormComponent": {
        "favorites": "Favoritter",
        "ajaxError": "Der opstod en fejl under oprettelse af gæsteinvitation",
        "invalidEmail": "Angiv en gyldig e-mail",
        "noEmail": "Indtast en e-mail",
        "noFirstName": "Angiv et fornavn",
        "noLastName": "Angiv et efternavn",
        "email": "E-mail-adresse",
        "firstName": "Fornavn",
        "lastName": "Efternavn",
        "session": "Session",
        "image": "Billede",
        "sendEmail": "Send mail",
        "onlyInclude": "Medtag kun favoritter fra denne",
    },
    "joyFormComponent": {
        "ageMonth": "måned(er)",
        "yes": "Ja",
        "no": "Nej",
    },
    "linkGuestFormComponent": {
        "enterDescription": "Angiv en beskrivelse af dit link",
        "description": "Beskrivelse",
        "onlyIncludeFavoritesFromThis": "Medtag kun favoritter fra denne",
        "createLink": "Opret kæde",
    },
    "personalInfoComponent": {
        "invalidEmail": "Angiv en gyldig e-mailadresse",
        "primaryPhone": "Primære telefon",
        "secondaryPhone": "Sekundær telefon",
        "mobilePhone": "Dette er en mobiltelefon",
        "streetAddress": "Gadeadresse",
        "house": "House",
        "apartment": "Lejlighed",
        "roomNumber": "Værelsesnummer",
        "city": "City",
        "region": "Regionen",
        "state": "Staten",
        "postalCode": "Postnummer",
        "country": "Land",
        "pleaseSelect": "Vælg...",
    },
    "familyMemberComponent": {
        "nickname": "Brugernavn",
        "familyMember": "Familiemedlem",
        "relationship": "Forholdet",
        "gender": "Køn",
        "BirthDate": "Fødselsdato",
        "familyMemberSubtitle": "Familiemedlemmer",
        "tableAction": "Handling",
    },
    "usernameFormComponent": {
        "enterUsername": "Indtast et nyt brugernavn",
        "onlineUsername": "Online brugernavn",
        "username": "Username",
    },
    "imgTileDropdownComponent": {
        "moreNav": "Flere navigationselementer",
        "download": "Download",
        "removeFrom": "Fjern fra",
        "modifyImage": "Rediger billede",
        "folders": "Mapper",
        "makePrimary": "Gør primær",
        "yearbookImage": "Årbog Image",
        "makeSecondary": "Gør sekundær",
        "socialShare": "Social andel",
        "unhide": "Vis",
        "hide": "Skjul",
    },
    "imageTileComponent": {
        "view": "Se",
        "sessions": "Sessioner",
        "setAsPrimary": "Angiv som primært foto",
        "setAsSecondary": "Angiv som sekundært foto",
        "image": "Billede",
        "caution": "Forsigtighed",
        "separateSessionsMessage1": "Du kan ikke tilføje billeder fra to separate",
        "separateSessionsMessage2": "til samme vogn",
        "doNotAdd": "Føj ikke denne vare til min indkøbsvogn.",
        "clearCart": "Ryd indholdet af min indkøbsvogn, og føj derefter denne vare til min indkøbsvogn.",
    },
    "loginPromptComponent": {
        "title": "Ups!",
        "message": "Venligst --login -- eller --tilmeld dig - hvis du ønsker at yndlingsbilleder gruppe billeder sammen eller tilføje billeder til indkøbskurven.",
    },
    "menuDropdownComponent": {
        "dashboard": "Dashboard",
        "sessions": "Sessioner",
        "favorites": "Favoritter",
        "contracts": "Kontrakter",
        "orders": "Ordrer",
        "prepaidOrders": "Forudbetalte ordrer",
        "guests": "Gæsterne",
        "account": "Konto",
    },
    "userDropdownComponent": {
        "logout": "Log af",
    },
    "navBarPage": {
        "toggleNavigation": "Slå navigation til/fra",
        "cart": "Indkøbsvogn",
        "photoshootsAndImages": "Fotooptagelser og billeder",
        "contracts": "Kontrakter",
        "reviewSubmit": "Gennemgå kontrakter, og indsend",
        "forms": "Former",
        "reviewForms": "Gennemse webformularer, og send",
        "orders": "Ordrer",
        "historyPrepaid": "Historik og forudbetalte ordrer",
        "orderHistory": "Ordrehistorik",
        "products": "Produkter",
        "guests": "Gæsterne",
        "categoriesProducts": "Kategorier og produkter",
        "sharedFamily": "Delt med familie",
        "friends": "Venner",
        "myAccount": "Min konto",
        "login": "Login",
        "billingInfo": "faktureringsoplysninger",
        "help": "Hjælp",
        "chat": "Chat for online support",
        "shoppingCart": "Indkøbskurv",
        "checkout": "Gør dig klar til kassen",
        "signout": "Log af",
        "endGuest": "Afslut min gæst",
        "securelySignout": "Lad mig sikkert logge ud",
    },
    "productGalleryComponent": {
        "viewLarger": "Se større",
    },
    "responsiveHeaderComponent": {
        "photos": "Billeder",
        "guests": "Gæsterne",
        "allImages": "Alle billeder",
        "favorites": "Favoritter",
        "folders": "Mapper",
        "autoPlay": "Obduktioner",
        "download": "Download",
        "cart": "Indkøbsvogn",
    },
    "sessionsPage": {
        "Name": "Navn",
        "Date": "Dato",
        "CreateNewText": "Opret en ny",
        "ViewText": "Se",
        "DeleteText": "Slet",
        "EditText": "Rediger",
    },
}
