import React, {useState, useEffect} from 'react';
import {
  Label,
} from 'reactstrap';

const InputFloat: React.FC<any> = ({ field, form, label, type='text', ...props }) => {
  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleInputChange = () => {
    if( field.value === '' && !focus ) {
      setActive(false);
    }else{
      setActive(true);
    }
  }

  useEffect(()=>{
    handleInputChange();
  }, [field.value, focus]);
  
  return(
    <div className={`form-field-float${active ? ' active' : ''}`}>
      <Label htmlFor={props.id ? props.id : field.name}>{label}</Label>
      {type === 'textarea' ?
      <textarea id={props.id ? props.id : field.name} type={type} {...field} {...props} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
      :
      <input id={props.id ? props.id : field.name} type={type} {...field} {...props} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
      }
    </div>
  )
}

export default InputFloat