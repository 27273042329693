import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer,inject } from 'mobx-react';
import { motion } from "framer-motion";
import { postVariants, useQuery } from '../../components/util';
import { Alert } from 'reactstrap';
import ResetPasswordForm from '../../components/forms/auth/resetPasswordForm';

const ResetPasswordPage: React.FC<any> = ({authStore, navigationStore}) => {
  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false)
  const [token, setToken] = useState('')

  const query = useQuery();

  useEffect(()=>{
    if(showSuccess){
        setTimeout(()=>{
            navigationStore.replace('/home/login');
        },4000)
    }
},[showSuccess])

  useEffect(()=>{
    const tok = query.get('resetPasswordId');
    if(!!!tok){
        return navigationStore.replace('/home/login');
    }else{
        setToken(tok);
    }
},[])


  return (
    <motion.div initial="initial" animate="enter" exit="exit" variants={postVariants}>        
     <h1>{t('ResetPasswordPage.title')}</h1>
     {!!token &&
        <ResetPasswordForm
        token = {token}
        onSuccess = {()=>setShowSuccess(true)}
        />
     }
      {showSuccess &&
        <Alert color = 'success'>{t('ResetPasswordPage.alertSuccess')}</Alert>
      }
      <p><Link to={`/home/login`}>{t('ResetPasswordPage.backToLogin')}</Link></p>
    </motion.div>
  );
}
export default inject('authStore', 'navigationStore')(observer(ResetPasswordPage))

