import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
// import InnerHTML from 'dangerously-set-html-content';
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
  Nav,
  NavItem,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { MdClose } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

import HomeRouter from "../../router/homeRouter";
import PrivacyPageRouter from "../../router/privacyPageRouter";
import PrivacyStatement from "../../components/legal/privacy";
import UserAccessAgreement from "../../components/legal/userAccessAgreement";
import TermsOfUse from "../../components/legal/termsOfUse";

const HomePage: React.FC<any> = ({
  navigationStore,
  authStore,
  globalErrorStore,
  sessionStore,
}) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState<any>();
  const [backgroundUrl, setBackgroundUrl] = useState<any>();
  const [backgroundMobileUrl, setBackgroundMobileUrl] = useState<any>();

  const isBreakDownXs = useMediaQuery({
    query: "(max-width: 575px)",
  });

  useEffect(() => {
    if (!authStore?.accountName) {
      navigationStore.replace("/");
    }

    if (authStore?.backgroundUrl) {
      setBackgroundUrl(authStore?.backgroundUrl);
    }

    if (authStore?.backgroundMobileUrl) {
      setBackgroundMobileUrl(authStore?.backgroundMobileUrl);
    } else if (authStore?.backgroundUrl) {
      setBackgroundMobileUrl(authStore?.backgroundUrl);
    }
  }, []);

  function showPrivacyStatement() {
    setModalTitle("Privacy Policy");
    setModalBody(<PrivacyStatement />);
    setShowModal(true);
  }

  function showUserAccessAgreement() {
    setModalTitle("User Access and Use Agreement");
    setModalBody(<UserAccessAgreement />);
    setShowModal(true);
  }

  function showTermsOfUse() {
    setModalTitle("Terms of Use and License");
    setModalBody(<TermsOfUse />);
    setShowModal(true);
  }

  if (authStore.isLoggingIn || sessionStore.loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>Logging you in...</p>
          <Spinner style={{ margin: "0 auto" }} color="primary" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="auth-page"
        style={{
          backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "",
        }}
      >
        <PrivacyPageRouter />

        <div className="wrapper">
          {!!globalErrorStore.loginErrorMessage && (
            <Alert color="warning" className="alert-auth">
              {globalErrorStore.loginErrorMessage}
            </Alert>
          )}
          <div className="wrapper-pads">
            <header className="auth-header">
              <nav className="container">
                <div
                  className="branding"
                  style={{ backgroundImage: `url(${authStore.logoUrl})` }}
                >
                  {authStore.companyName}
                </div>

                <Nav
                  role="presentation"
                  style={{
                    backgroundImage:
                      isBreakDownXs && backgroundMobileUrl
                        ? `url(${backgroundMobileUrl})`
                        : "",
                  }}
                >
                  <NavItem>
                    <Link
                      to={`${match.url}/login`}
                      className={`nav-link${
                        window.location.pathname !== "/home/event-search" &&
                        window.location.pathname !== "/home/signup" &&
                        window.location.pathname !== "/home/organization-search"
                          ? ` active`
                          : ``
                      }`}
                    >
                      {t("HomePage.signIn")}
                    </Link>
                  </NavItem>

                  {!authStore.disableEventSearch && (
                    <NavItem>
                      <Link
                        to={`${match.url}/event-search`}
                        className={`nav-link${
                          window.location.pathname === "/home/event-search"
                            ? ` active`
                            : ``
                        }`}
                      >
                        {t("HomePage.eventSearch")}
                      </Link>
                    </NavItem>
                  )}

                  {authStore.enableOrganizationSearch && (
                    <NavItem>
                      <Link
                        to={`${match.url}/organization-search`}
                        className={`nav-link${
                          window.location.pathname ===
                          "/home/organization-search"
                            ? ` active`
                            : ``
                        }`}
                      >
                        {t("HomePage.organizationSearch")}
                      </Link>
                    </NavItem>
                  )}

                  {!authStore.disableSignUpTab && (
                    <NavItem>
                      <Link
                        to={`${match.url}/signup`}
                        className={`nav-link${
                          window.location.pathname === "/home/signup"
                            ? ` active`
                            : ``
                        }`}
                      >
                        {t("SignupPage.signup")}
                      </Link>
                    </NavItem>
                  )}
                </Nav>
              </nav>
            </header>
            <main className="auth-main">
              <div className="container">
                <HomeRouter />
              </div>
            </main>
            <footer className="auth-footer">
              <div className="container">
                <nav>
                  <Nav role="presentation">
                    <NavItem>
                      <Link onClick={showPrivacyStatement} to={`#`}>
                        {t("HomePage.privacy")}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link onClick={showUserAccessAgreement} to={`#`}>
                        {t("HomePage.userAccessAgreement")}
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link onClick={showTermsOfUse} to={`#`}>
                        {t("HomePage.termsOfUse")}
                      </Link>
                    </NavItem>
                  </Nav>
                </nav>
                <p>&copy;{new Date().getFullYear()} StudioPlus Software, LLC</p>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        size="lg"
        centered
      >
        <ModalHeader
          toggle={() => setShowModal(!showModal)}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={() => setShowModal(!showModal)}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {modalTitle}
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>
          <div className="text-center mt-4 w-100">
            <Button onClick={() => setShowModal(!showModal)} color="primary">
              {t("HomePage.close")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default inject(
  "navigationStore",
  "authStore",
  "globalErrorStore",
  "sessionStore"
)(observer(HomePage));
