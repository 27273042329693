import React from "react";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import EventSearchForm, {
  EventSearchFormData,
} from "../../components/forms/eventSearch/eventSearchForm";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import { postVariants } from "../../components/util";
import { guestAuthParams } from "../../interfaces/auth.int";

const EventSearchPage: React.FC<any> = ({
  authStore,
  sessionStore,
  navigationStore,
  eventStore,
}) => {
  const { t } = useTranslation();

  function redirect() {
    let url = "home/login";
    if (authStore.token) {
      url = "/dashboard";
    }
    return navigationStore.push(url);
  }

  async function onSubmit(obj: EventSearchFormData, actions: any) {
    actions.setSubmitting(true);

    switch (obj.search_by) {
      case "code":
        const event: any = await eventStore.getPublicEcomEventById(obj.code);
        if (event?.SessionKey) {
          const params: guestAuthParams = {
            eventKey: event.SessionKey,
            eventDate: event.EventDate,
          };
          await authStore.guestAuthentication(params);
          await sessionStore.getSessionDetailByKey(event.SessionKey);
          await redirect();
        }
        actions.setSubmitting(false);
        return;
      case "publicCode":
        const params: guestAuthParams = {
          eventKey: obj.publicCode,
          eventDate: obj.date,
        };
        await authStore.guestAuthentication(params);
        await sessionStore.getSessionDetailByKey(obj.publicCode);
        await redirect();
        actions.setSubmitting(false);
        return;
      case "date":
        const dateParams: guestAuthParams = {
          eventKey: obj.code_by_date,
          eventDate: obj.date,
        };
        await authStore.guestAuthentication(dateParams);
        await sessionStore.getSessionDetailByKey(obj.code_by_date);
        await redirect();
        actions.setSubmitting(false);
        return;
    }
  }

  return (
    <>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <h1>{t("EventSearchPage.title")}</h1>
        <p>{t("EventSearchPage.p1")}</p>
        <EventSearchForm
          publicEvents={eventStore.dropdownPublicEcomEvents}
          content={{
            date: t("EventSearchPage.date"),
            code: t("EventSearchPage.code"),
            public: t("EventSearchPage.public"),
            search: t("EventSearchPage.search"),
          }}
          onSubmit={onSubmit}
        />
      </motion.div>
      <Helmet>
        <title>
          {t("EventSearchPage.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
    </>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "sessionStore",
  "eventStore"
)(observer(EventSearchPage));
