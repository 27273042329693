import React, { useEffect, useState } from "react";

import { observer, inject } from "mobx-react";

import InfiniteScroll from "react-infinite-scroll-component";

import "./Blog.scss";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
} from "reactstrap";

import { AiOutlineFieldTime, AiOutlineUser } from "react-icons/ai";
import Spinner from "reactstrap/lib/Spinner";

const BlogPost: React.FC<any> = ({ history, wpStore }) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [posts, setPosts] = useState<any>([]);
  const [pageCounter, setpageCounter] = useState(1);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );

  async function loadPosts() {
    await wpStore.getAllBlogs();
    if (wpStore.allBlogs && wpStore.allBlogs.length > 0) {
      setPosts([...posts, ...wpStore.allBlogs]);
      setDisableInfiniteScroll(wpStore.allBlogs.length <= posts);
    } else {
      setDisableInfiniteScroll(true);
    }
  }

  useEffect(() => {
    loadPosts().then((res: any) => {
      setLoader(false);
    });
    setpageCounter(pageCounter + 1);
  }, []);

  async function fetchData() {
    setpageCounter(pageCounter + 1);
    console.log(pageCounter);
    await loadPosts();
  }

  function reformatDate(dateStr: any) {
    const dArr = dateStr.split("-");
    return dArr[1] + "/" + dArr[2] + "/" + dArr[0];
  }

  function truncateText(str: any) {
    str = str.replace(/<[^>]+>/g, "");
    return str.length > 150 ? str.substring(0, 150) + "..." : str;
  }

  return (
    <>
      <section className="page-section page-section-2column">
        <div className="page-section-main blog-main-section">
          <div className="header">
            <h1 className="mb-2">Anne’s Blog</h1>
          </div>

          {loader && (
            <div className="spinner-center">
              <Spinner color="primary" />
            </div>
          )}
          {!loader && (
            <InfiniteScroll
              dataLength={posts.length} //This is important field to render the next data
              next={fetchData}
              hasMore={disableInfiniteScroll}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p className="no-more-blogpost" style={{ textAlign: "center" }}>
                  All blogs are loaded
                </p>
              }
            >
              <Row className="mt-1">
                {posts.map((post: any, index: any) => (
                  <Col
                    md="6"
                    sm="6"
                    xs="12"
                    lg="4"
                    className="mb-1 blog-columns"
                    key={index}
                  >
                    <Card
                      className="my-2 mx-0 blog-post-content"
                      onClick={() =>
                        history.push(`/blog-details?id=${post?.id}`)
                      }
                    >
                      <div className="flex-col">
                        <div className="blog-image-col">
                          {post?._embedded["wp:featuredmedia"] ? (
                            <CardImg
                              top
                              width="100%"
                              src={
                                post._embedded["wp:featuredmedia"]["0"]
                                  .source_url
                              }
                              alt="Card image cap"
                            />
                          ) : (
                            <CardImg
                              top
                              width="100%"
                              src="/blog-banner.png"
                              alt="img"
                            />
                          )}
                        </div>
                        <div className="content-col">
                          <CardBody>
                            <CardTitle className="blog-title">
                              <div
                                className="blog-title-content"
                                dangerouslySetInnerHTML={{
                                  __html: post.title?.rendered,
                                }}
                              ></div>
                            </CardTitle>

                            <CardText tag="div">
                              <div
                                className="blog-content-short"
                                dangerouslySetInnerHTML={{
                                  __html: truncateText(post.content?.rendered),
                                }}
                              ></div>
                            </CardText>
                          </CardBody>
                        </div>
                      </div>
                      <CardBody className="mt-1 mb-1 pt-1 pb-1">
                        <Row>
                          <Col md="6" sm="6" xs="6">
                            <CardSubtitle className="icon-col">
                              {" "}
                              <AiOutlineUser size="22" />
                              <span className="author-name">
                                {post._embedded["author"]["0"]?.name}
                              </span>
                            </CardSubtitle>
                          </Col>
                          <Col md="6" sm="6" xs="6">
                            <CardSubtitle className="icon-col float-right">
                              {" "}
                              <AiOutlineFieldTime size="22" />
                              <span>
                                {" "}
                                {reformatDate(post.date?.split("T")[0])}
                              </span>
                            </CardSubtitle>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}
        </div>
      </section>
    </>
  );
};

export default inject(
  "authStore",
  "clientStore",
  "wpStore"
)(observer(BlogPost));
