import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import "./splashPage.scss";
import { guestAuthParams } from "../../interfaces/auth.int";
import { useQuery } from "../../components/util";
import { Spinner } from "reactstrap";
// import ProgessSpinner from "../../components/spinner/spinner";
// import { Alert } from "reactstrap";

const SplashPage: React.FC<any> = ({
  authStore,
  eventStore,
  navigationStore,
  globalErrorStore: err,
  sessionStore,
  searchTerm,
  ecomStore,
  createOrderStore,
  clientStore,
}) => {
  const { t } = useTranslation();

  const query = useQuery();

  function onErr(shareToken: string) {
    err.setErrorMessage(
      `${t("SplashPage.wereSorry")} ${shareToken} ${t(
        "SplashPage.doesntSeemTo"
      )}`
    );
    navigationStore.push("/home/login");
  }

  async function redirect() {
    const forgotPasswordString = query.get("resetPasswordId");
    if (!!forgotPasswordString) {
      await navigationStore.push(
        `/home/reset-password?resetPasswordId=${forgotPasswordString}`
      );
    } else if (!!authStore.token) {
      await authStore.refreshClient();
      await navigationStore.push("/dashboard");
    } else {
      await navigationStore.push("/home/login");
    }
  }

  async function fetchData(
    shareToken: any,
    clientLoginToken?: any,
    username?: any,
    password?: any,
    searchBy?: any,
    eventKey?: any,
    eventDate?: any,
    defaultSessionKey?: any,
    enableComposites?: any
  ) {
    var subdomain = window.location.host.split(".")[0];
    const hasSubdomain = subdomain !== window.location.host;
    let companyInfoError = !hasSubdomain;

    if (hasSubdomain) {
      if (subdomain.indexOf("-") >= 0) {
        // Handling environment name suffix
        subdomain = subdomain.substring(0, subdomain.indexOf("-"));
      }

      if (!!clientLoginToken && !authStore.token) {
        authStore.logout();
      }

      await authStore.getCompanyInfo(subdomain).catch((e: any) => {
        companyInfoError = true;
        err.setErrorMessage(t("SplashPage.error"));
      });
    } else {
      err.setErrorMessage(t("SplashPage.subdomainError"));
    }

    if (!!enableComposites && enableComposites == "true") {
      authStore.EnableCompositeTemplates = true;
    }

    if (!!shareToken && !companyInfoError) {
      // Session Share Token
      if (!authStore.requireGuestLogin) {
        // Do not require guest login immediately.

        const params: guestAuthParams = { shareToken: shareToken };
        await authStore.guestAuthentication(params).catch((e: any) => {
          onErr(shareToken);
        });

        await sessionStore
          .getSessionDetailByShareToken(shareToken)
          .catch((e: any) => {
            onErr(shareToken);
          });

        if (!err.errorMessage) {
          // Redirect to sessions page.
          navigationStore.push("/sessions");
        }
      } else {
        // If New Or Existing User Click on the Dynamic Link //
        if (!clientStore.clientKey && !authStore.token) {
          authStore.logout();

          authStore.fetchToken = shareToken;
          console.log(authStore.fetchToken);
          console.log("Dynamic Link here");
          navigationStore.push("/home/signup");
          return;
        }

        //If the user is already logged in

        if (clientStore.clientKey) {
          // Redirect to sessions screen

          if (shareToken) {
            await clientStore.putClientSessionAccess(shareToken);

            await clientStore.getClientInfo(clientStore.defaultSessionKey);
          }

          navigationStore.push("/sessions");
        }
      }
    } else if (!!clientLoginToken && !companyInfoError) {
      // Client Login Token
      const params: any = { clientLoginToken };
      if (!!defaultSessionKey) {
        params.defaultSessionKey = defaultSessionKey;
      }
      await authStore.login(params).then((res: any) => {
        if (res) {
          const url = authStore.redirectUrl || generateUrl(res);
          navigationStore.push(url);
        }
      });
    } else if (!!username && !!password) {
      console.log(decodeURIComponent(password));
      const params: any = {
        email: atob(decodeURIComponent(username)),
        password: atob(decodeURIComponent(password)),
      };
      await authStore.login(params).then((res: any) => {
        if (res) {
          const url = authStore.redirectUrl || generateUrl(res);
          navigationStore.push(url);
        }
      });
    } else if (!!searchBy && !!eventKey && !!eventDate) {
      switch (searchBy) {
        case "code":
          const event: any = await eventStore.getPublicEcomEventById(eventKey);
          if (event?.SessionKey) {
            const params: guestAuthParams = {
              eventKey: event.SessionKey,
              eventDate,
            };
            await authStore.guestAuthentication(params);
            await sessionStore.getSessionDetailByKey(event.SessionKey);
            await redirect();
          }
          break;
        case "publicCode":
          const params: guestAuthParams = { eventKey, eventDate };
          await authStore.guestAuthentication(params);
          await sessionStore.getSessionDetailByKey(eventKey);
          await redirect();
          break;
        case "date":
          const dateParams: guestAuthParams = { eventKey, eventDate };
          await authStore.guestAuthentication(dateParams);
          await sessionStore.getSessionDetailByKey(eventKey);
          await redirect();
          break;
      }
    } else if (!shareToken && !companyInfoError) {
      // No token; redirect to login page
      setTimeout(() => redirect(), 500);
    }
  }

  function generateUrl(type: string) {
    switch (type) {
      case "SESSION":
        if (searchTerm.includes("defaultSessionKey")) {
          return `/sessions/?${ecomStore.searchTerm}`;
        }
        return "/dashboard";
      case "CONTRACT":
        return "/contracts";
      case "WEBFORM":
        return "/forms";
      default:
        return "/account";
    }
  }
  useEffect(() => {
    (async () => {
      let searchTerm = window.location.search;

      // Extract query parameters, get rid of question mark at first character if there is one
      if (searchTerm?.includes("?")) {
        searchTerm = searchTerm.slice(1);
      }
      ecomStore.searchTerm = searchTerm;
      const queryParams = searchTerm.split("&");
      for (let query of queryParams) {
        if (query.includes("promoCode")) {
          await ecomStore.enterPromoCode(query.split("=")[1]);
        }
        if (query.includes("defaultSessionKey")) {
          // Set activeSession to the defaultSessionKey
          sessionStore.activeSession = query.split("=")[1];
        }
      }
    })();
  }, []);

  useEffect(() => {
    fetchData(
      query.get("shareToken"),
      query.get("clientLoginToken"),
      query.get("username"),
      query.get("password"),
      query.get("searchBy"),
      query.get("eventKey"),
      query.get("eventDate"),
      query.get("defaultSessionKey"),
      query.get("enableComposites")
    );
  }, [authStore, navigationStore]);

  return (
    <>
      {authStore.isLoading || !sessionStore.allSessionDetail.length ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default inject(
  "authStore",
  "eventStore",
  "navigationStore",
  "globalErrorStore",
  "sessionStore",
  "ecomStore",
  "createOrderStore",
  "clientStore"
)(observer(SplashPage));
