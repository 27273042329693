import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import SocialShare from '../socialShare';

const AddImageToShareModal: React.FC<any> = ({history, imageShareStore}) => {
  const { t } = useTranslation();
  const [sharedImage, setSharedImage] = useState({} as any);
    
    useEffect(()=>{
        if(imageShareStore.addToShare.showModal){
          imageShareStore.toggleModal()
        }
    },[history.location.pathname])

    useEffect(()=>{
      if(imageShareStore.addToShare.showModal){
        getUrl();
      }else{
        setSharedImage({});
      }
    }, [imageShareStore.addToShare.showModal])

    const getUrl = async() => {
      await imageShareStore.generateUrl({ShareType:'SocialShare'
          ,Filename:imageShareStore.selectedImage.fileName
          ,SourceImageUrl:imageShareStore.selectedImage.preview
          ,SourceImageHeight:imageShareStore.selectedImage.height
          ,SourceImageWidth:imageShareStore.selectedImage.width
        }
        ).then(async (res:any)=>{
        setSharedImage(res);
      }).catch((e:any)=>{
        console.log(e);
      });
    }

    return (
        <Modal
        isOpen={imageShareStore.addToShare.showModal} 
        toggle={()=>imageShareStore.toggleModal()} 
        wrapClassName = "modal-box" 
        modalTransition={{ timeout: 500 }} 
        backdropTransition={{ timeout: 0 }}
        size="md"
        style={{maxWidth: '22.5rem'}}
        centered
      >
        <ModalHeader 
          toggle={()=>imageShareStore.toggleModal()} 
          close={
              <Button color="icon-action" className="close" onClick={()=>imageShareStore.toggleModal()}>
                  <MdClose size="18" />
                </Button>}>{t('AddImageToShareModalComponent.socialShare')}
        </ModalHeader>
        <ModalBody> 
          {sharedImage?.ShareUrl ?
            <SocialShare className="text-center mt-4" url={sharedImage?.ShareUrl} imgUrl={sharedImage?.ShareImageUrl} />
          : <p>{t('AddImageToShareModalComponent.generatingShareable')}</p>}
        </ModalBody>
      </Modal>

    );

}
export default withRouter(inject(
    'imageShareStore'
    )(observer(AddImageToShareModal)))
