// @ts-nocheck
import React, { useState, useEffect, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineForm } from "react-icons/ai";
import { MdCropRotate, MdFormatShapes, MdClose } from "react-icons/md";
import {
  Label,
  Button,
  Collapse,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  ModalFooter,
} from "reactstrap";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Helmet } from "react-helmet";
import * as Scroll from "react-scroll";
import { useTranslation } from "react-i18next";

import { postVariants } from "../util";
import { InvoiceLineItem } from "../../interfaces/invoice.int";
import NumberIncrement from "../forms/numberIncrement/numberIncrement";
import RelatedProducts from "../relatedProducts/relatedProducts";
import noImg from "../imageTile/broken-img.png";

const CartView: React.FC<any> = ({
  authStore,
  ecomStore,
  createOrderStore,
  navigationStore,
  sessionStore,
  compositeStore,
}) => {
  const { t } = useTranslation();
  const [dropdownState, setDropdownState] = useState({
    activeDropdown: 0,
    isOpen: false,
  });
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [modalCartFull, setModalCartFull] = useState(false);
  const [modalReplaceItem, setModalReplaceItem] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [cartSpinner, setCartSpinner] = useState(false);
  const [code, setCode] = useState("");
  const [removeLoader, setRemoveLoader] = useState<any>([]);
  let scroller = Scroll.scroller;
  let showUnitsRemainingLabel;
  let unitsRemainingLabel;
  useEffect(() => {
    if (authStore.AffirmEnabledOnline) {
      affirm.ui.refresh();
    }

    // Resets the promo code error when updating the cart
    if (ecomStore) {
      ecomStore.promoCodeError = "";
    }
  }, [ecomStore.cart]);

  useEffect(() => {
    return () => {
      // Resets the promoCode error when unmounting component
      if (ecomStore) {
        ecomStore.promoCodeError = "";
      }
    };
  }, []);

  useEffect(() => {
    const refresh = async () => await ecomStore.refreshCart();
    refresh();

    if (ecomStore.scrollCart) {
      scrollPosition(`itemKey${ecomStore.scrollCart}`);
    }
  }, []);

  const toggleCartFullModal = (e: any) => {
    setModalCartFull(!modalCartFull);
    e.preventDefault();
  };
  const toggleModalReplaceItem = (e: any) => {
    setModalReplaceItem(!modalReplaceItem);
  };

  const toggle = (idx: number) =>
    setDropdownState({
      ...dropdownState,
      isOpen: !dropdownState.isOpen,
      activeDropdown: idx,
    });

  const scrollPosition = (ref: any, err: boolean = false) => {
    scroller.scrollTo(ref, {
      smooth: "easeInOutQuad",
      containerId: "scrollElement",
      offset: isBreakDownSm ? -130 : -30,
      isDynamic: true,
      delay: err ? 0 : 1000,
    });

    ecomStore.scrollCart = null;
  };

  function checkout(e: any) {
    for (let cartItem of ecomStore.cartItems) {
      if (cartItem.MustReplace) {
        toggleModalReplaceItem(e);
        return;
      }
    }
    if (ecomStore.cart.UnitsRemaining < 0) {
      toggleCartFullModal(e);
      return;
    }
    const inValid = ecomStore.validateCart();
    if (inValid?.key) {
      scrollPosition(`itemKey${inValid.key}`, true);
    } else {
      createOrderStore.toggleSlider();
      if (ecomStore.orderRequiresShipping) {
        navigationStore.push("/checkout/shippingaddress");
      } else {
        navigationStore.push("/checkout/orderreview");
      }
    }
  }

  function resetCode() {
    setCode("");
    setShowPromoInput(false);
  }

  async function addCode(code: string) {
    await ecomStore.enterPromoCode(code);
    resetCode();
  }

  const isBreakDownSm = useMediaQuery({
    query: "(min-width: 768px)",
  });

  function thumbnail(itm: any, i: number) {
    let mainImage: string = noImg;

    if (!ecomStore.getAllowImageSelection(itm)) {
      return "";
    }

    //const session = sessionStore.allSessionDetail.find((s:any)=>s.Key === itm.SessionKey);

    //console.log(itm);
    //console.log(sessionStore.getImgUrl(itm.SessionKey, itm.FileName));
    if (itm?.FileName) {
      mainImage = itm.PreviewUrl;
    } else if (itm.compositeTemplateKey) {
      if (
        typeof itm?.compositeImage === "object" &&
        Array.isArray(itm?.compositeImage)
      ) {
        mainImage = Object.assign({}, itm?.compositeImage)[0]?.ThumbUrl;
      }
    }
    return (
      <>
        <figure
          className={`thumb-edit${
            ecomStore.imageUpdate ||
            removeLoader.some((item: any) => item === itm.Key)
              ? " loading"
              : ""
          }`}
        >
          <div
            className={`thumb thumb__not-rounded`}
            style={{
              backgroundImage: `url(${
                `${mainImage}${
                  !!itm?.ColorizationKey
                    ? `&colorizationKey=${itm.ColorizationKey}`
                    : ""
                }` || noImg
              }`,
            }}
          >
            <img src={`${mainImage}`} alt={itm.Description} />
            {ecomStore.imageUpdate === itm.Key && (
              <div className="spinner">
                <Spinner color="primary" size="sm" />
              </div>
            )}
          </div>
          <ul role="presentation">
            {itm.compositeTemplateKey && authStore.EnableCompositeTemplates ? (
              <>
                <li>
                  <Button
                    onClick={async () => {
                      ecomStore.scrollCart = itm.Key;
                      await compositeStore.handleComposite(itm);
                      ecomStore.currentPackageItems = [];
                      createOrderStore.setStepNumber(7);
                    }}
                  >
                    <MdFormatShapes size="22" />
                    <span>Customize</span>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      ecomStore.setCroppedPhoto(itm.Key);
                      ecomStore.scrollCart = itm.Key;
                      createOrderStore.setStepNumber(6);
                    }}
                  >
                    <MdCropRotate size="22" />
                    <span>Set options</span>
                  </Button>
                </li>
                {ecomStore?.cartErrors[i]?.includes("compositeRequired") && (
                  <p className="text-danger font-weight-normal font-size-sm mt-3">
                    {t("OrderDetailsPage.compositeRequired")}
                  </p>
                )}
              </>
            ) : (
              <>
                <li>
                  <Button
                    onClick={() => {
                      sessionStore.swappedPhoto = itm;
                      ecomStore.swapPhotoCartItemKey = itm.Key;
                      ecomStore.scrollCart = itm.Key;
                      ecomStore.currentPackageItems = [];
                      createOrderStore.setStepNumber(5);
                    }}
                  >
                    <AiOutlineForm size="22" />
                    <span>{`${!!itm.FileName ? "Swap" : "Select"} `}photo</span>
                  </Button>
                </li>
                <li>
                  <Button
                    disabled={!itm.FileName}
                    onClick={() => {
                      ecomStore.setCroppedPhoto(itm.Key);
                      ecomStore.currentPackageItems = [];
                      ecomStore.scrollCart = itm.Key;
                      createOrderStore.setStepNumber(6);
                    }}
                  >
                    <MdCropRotate size="22" />
                    <span>Set crop and options</span>
                  </Button>
                </li>
              </>
            )}
          </ul>
        </figure>
        {ecomStore?.cartErrors[i]?.includes("cropRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            {t("OrderDetailsPage.cropRequired")}
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("imageRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            {t("OrderDetailsPage.imageRequired")}
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("enhancementRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            Enhancement Required
          </p>
        )}
        {ecomStore?.cartErrors[i]?.includes("optionRequired") && (
          <p className="mb-0 text-danger font-weight-normal font-size-sm mt-3">
            Option Required
          </p>
        )}
      </>
    );
  }

  async function handleDropdownClick(itemKey: any, replacementItemKey: any) {
    setSpinner(true);
    await ecomStore.swapPackageComponent(itemKey, replacementItemKey);
    setSpinner(false);
  }

  const deleteCartItem = async (itm: any) => {
    setRemoveLoader([...removeLoader, itm.Key]);
    await ecomStore
      .deleteCartItem(itm.Key, ecomStore.cart.Key)
      .then((res: any) => {
        setRemoveLoader(removeLoader.filter((item: any) => item !== itm.Key));
      })
      .catch(() => {
        setRemoveLoader(removeLoader.filter((item: any) => item !== itm.Key));
      });
  };

  function cartItem(itm: any, i: number) {
    return (
      <Tr
        key={`cartItem${itm.Key}`}
        className="parent-product"
        name={`itemKey${itm.Key}`}
      >
        <Td className="td-action">
          {removeLoader.some((item: any) => item === itm.Key) ? (
            <Spinner color="primary" size="sm" />
          ) : (
            !itm.CannotRemove && (
              <Button
                color="icon-action"
                onClick={() => !ecomStore.imageUpdate && deleteCartItem(itm)}
                disabled={ecomStore.imageUpdate ? true : false}
              >
                <AiOutlineDelete
                  className={`removeProduct${
                    !ecomStore.imageUpdate ? " text-danger" : ""
                  }`}
                />
              </Button>
            )
          )}
        </Td>
        <Td className="th-product-thumbs" scope="row">
          <div className="mb-2 description">
            <strong>{itm.Description}</strong>{" "}
            <span className="font-weight-normal text-muted">
              {itm.ImageNumber &&
                !itm.PackageItemKey &&
                !ecomStore.cartItems[i + 1]?.PackageItemKey &&
                itm.allowImageSelection &&
                itm?.ImageUrl &&
                `Image ${itm.ImageNumber}`}
            </span>
            <div className="font-weight-normal text-muted">
              {itm?.Enhancements.map((enhancement: any, idx) => (
                <span>
                  <i>
                    {idx !== 0 && ", "}
                    {enhancement.EnhancementDescription.replace("+", "")}
                  </i>
                </span>
              ))}
              {itm?.Options.map((option: any, idx) => (
                <span>
                  <i>
                    {itm.Enhancements.length !== 0 && ", "}
                    {option.OptionDescription.replace("+", "")}
                  </i>
                </span>
              ))}
            </div>
          </div>
          {thumbnail(itm, i)}
        </Td>
        <Td className="td-price text-right">{itm.PriceStringFormatted}</Td>
        <Td className="text-center td-product-quantity">
          {!itm.CannotRemove && (
            <NumberIncrement
              int={itm?.Quantity || 1}
              onChange={(obj) => {
                if (obj.int <= 0) {
                  deleteCartItem(itm);
                  return;
                }
                return ecomStore.updateCartItem(
                  { Key: itm.Key, Quantity: obj.int },
                  null,
                  true
                );
              }}
              isSubmitting={
                removeLoader.some((item: any) => item === itm.Key) ||
                ecomStore.imageUpdate
                  ? true
                  : false
              }
            />
          )}
        </Td>
        <Td className="text-right">
          {!itm.PackageItemKey && itm.ExtendedStringFormatted}
        </Td>
      </Tr>
    );
  }

  function packageComponent(itm: any, i: number) {
    // console.log('Cart package itemize');
    // console.log(itm);
    return (
      <Tr key={i} className="sub-product" name={`itemKey${itm.Key}`}>
        <Td className="td-action responsive-hidden"></Td>
        <Td className="th-product-thumbs" scope="row">
          {itm.ReplacementItems?.length > 0 ? (
            <ButtonDropdown
              className="mb-2 description"
              size="md"
              isOpen={
                dropdownState.isOpen && dropdownState.activeDropdown === i
              }
              toggle={() => toggle(i)}
            >
              <DropdownToggle
                color="primary"
                style={{ height: "100%", borderRadius: ".25rem" }}
                caret={!spinner}
              >
                {spinner ? (
                  <div className="spinner">
                    <Spinner color="white" size="sm" />
                  </div>
                ) : (
                  <Fragment>
                    <strong>{itm.Description}</strong>{" "}
                    <span>
                      {itm.ImageNumber &&
                        !itm.PackageItemKey &&
                        !ecomStore.cartItems[i + 1]?.PackageItemKey &&
                        itm.allowImageSelection &&
                        itm?.ImageUrl &&
                        `Image ${itm.ImageNumber}`}
                    </span>
                  </Fragment>
                )}
              </DropdownToggle>
              <DropdownMenu>
                {/* {itm.ReplacementItems.map((replacementItem: any) => { if(itm.ItemKey !== replacementItem.ItemKey) { return <DropdownItem onClick={() => { setSpinner(true); ecomStore.swapPackageComponent(itm.Key, replacementItem.ItemKey); setSpinner(false)}}>{replacementItem.Description}</DropdownItem> }})}  */}
                {itm.ReplacementItems.map((replacementItem: any, idx) => {
                  const item = ecomStore.getItemByKey(replacementItem.ItemKey);
                  if (itm.ItemKey !== replacementItem.ItemKey) {
                    return (
                      <>
                        <DropdownItem
                          onClick={() => {
                            handleDropdownClick(
                              itm.Key,
                              replacementItem.ItemKey
                            );
                          }}
                        >
                          <Row style={{ paddingLeft: "1rem" }}>
                            <em>{item?.Name}</em>
                          </Row>
                          <Row style={{ width: "25rem" }}>
                            {item?.ProductImages &&
                              item?.ProductImages[0].ThumbUrl && (
                                <Col xs={4}>
                                  {item?.ProductImages?.length && (
                                    <img
                                      alt="Product"
                                      width={200}
                                      src={item.ProductImages[0].ThumbUrl}
                                    ></img>
                                  )}
                                </Col>
                              )}

                            <Col
                              style={{
                                textWrap: "wrap",
                              }}
                              xs={8}
                            >
                              <p style={{ marginBottom: "0.5rem" }}>
                                {item?.MarketingDescription}
                              </p>
                              <p className="price">
                                {item?.PriceStringFormatted === "$0.00"
                                  ? "Free"
                                  : item?.PriceStringFormatted}
                              </p>
                            </Col>
                          </Row>
                        </DropdownItem>

                        {itm.ReplacementItems.length > 1 &&
                          idx !== itm.ReplacementItems.length - 1 && (
                            <DropdownItem divider></DropdownItem>
                          )}
                      </>
                    );
                  }
                })}
              </DropdownMenu>
              {itm.MustReplace && (
                <div className="d-flex align-self-center">
                  <p className="ml-3 text-danger">
                    {t("cartViewComponent.mustReplace")}
                  </p>
                </div>
              )}
            </ButtonDropdown>
          ) : (
            <div className="mb-2 description">
              <strong>{itm.Description}</strong>{" "}
              <span className="font-weight-normal text-muted">
                {itm.ImageNumber &&
                  itm.allowImageSelection &&
                  itm?.ImageUrl &&
                  `(Image ${itm.ImageNumber})`}
              </span>
            </div>
          )}
          {thumbnail(itm, i)}
        </Td>
        <Td className="td-price text-right responsive-hidden"></Td>
        <Td
          className={`text-center${itm.Quantity ? " td-product-quantity" : ""}`}
        >
          {itm.Quantity}
        </Td>
        <Td className="td-subtotal text-right responsive-hidden"></Td>
      </Tr>
    );
  }
  if (ecomStore.cart.UnitsRemaining === 0) {
    showUnitsRemainingLabel = false;
  } else if (ecomStore.cart.UnitsRemaining > 0) {
    showUnitsRemainingLabel = true;
    unitsRemainingLabel = (
      <Label style={{ color: "black" }}>
        {t("cartViewComponent.youHave")} {ecomStore.cart.UnitsRemaining}{" "}
        {t("cartViewComponent.unitsRemaining")}
      </Label>
    );
  } else if (ecomStore.cart.UnitsRemaining < 0) {
    showUnitsRemainingLabel = true;
    unitsRemainingLabel = (
      <Label style={{ color: "red" }}>
        {t("cartViewComponent.youHaveAdded")}{" "}
        {Math.abs(ecomStore.cart.UnitsRemaining)}{" "}
        {t("cartViewComponent.unitsTooMany")}
      </Label>
    );
  }

  if (compositeStore.loading || cartSpinner) {
    let spinnerText = "";

    if (compositeStore.loading) {
      spinnerText = "Loading Template Builder";
    } else if (cartSpinner) {
      spinnerText = "Deleting cart";
    }
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div className="mb-1" style={{ textAlign: "center" }}>
          {spinnerText}
        </div>
        <div style={{ textAlign: "center" }}>
          <Spinner color="primary" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t("cartViewComponent.urlTitle")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <section className="product-modal-container" id="modal_body_123123123">
          <div className="product-modal-head">
            <div className="row align-items-center">
              <div className="col-sm-5 text-center text-sm-left">
                <h2>{t("cartViewComponent.title")}</h2>
              </div>

              {ecomStore.cart && ecomStore.cartItems.length > 0 && (
                <div className="col-sm-7 text-center text-sm-right mt-3 mt-md-0 d-none d-sm-block">
                  <span>
                    <span>{`Subtotal: `}</span>
                    <span className="price font-weight-bold">
                      {ecomStore.cart.SubtotalStringFormatted}
                    </span>
                  </span>
                  {authStore.AffirmEnabledOnline && (
                    <p
                      class="affirm-as-low-as"
                      data-page-type="cart"
                      data-amount={(ecomStore.cart.Subtotal * 100).toString()}
                    ></p>
                  )}

                  <Button
                    color="primary"
                    disabled={
                      !ecomStore.cartItems?.length || ecomStore.imageUpdate
                    }
                    className="px-5 ml-3"
                    onClick={checkout}
                  >
                    {t("cartViewComponent.checkout")}
                  </Button>
                </div>
              )}
              <div className="col-12">
                {authStore.accountName.toLowerCase() === "annegeddes" && (
                  <>
                    <p className="primary-color mt-4 mb-4">
                      <strong> {t("cartViewComponent.usShippingOnly")}</strong>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="product-modal-list cart-view">
            {ecomStore.cart?.RemovedPromotionsMessages?.map(
              (message: string) => (
                <Alert color="danger" size="sm">
                  {message}
                </Alert>
              )
            )}
            {ecomStore.cart && ecomStore.cartItems.length > 0 ? (
              <Table className="table table-cart">
                <Thead>
                  <Tr>
                    <Th scope="col" className="th-action">
                      <span className="sr-only">
                        {t("cartViewComponent.tableAction")}
                      </span>
                    </Th>
                    <Th scope="col" className="th-product">
                      {t("cartViewComponent.tableProductName")}
                    </Th>
                    <Th scope="col" className="th-price text-right">
                      {t("cartViewComponent.tablePrice")}
                    </Th>
                    <Th scope="col" className="th-quantity text-center">
                      {t("cartViewComponent.tableQuantity")}
                    </Th>
                    <Th scope="col" className="th-subtotal text-right">
                      {t("cartViewComponent.tableSubtotal")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {ecomStore.cartItems.map((itm: InvoiceLineItem, i: number) =>
                    !!itm.PackageItemKey
                      ? packageComponent(itm, i)
                      : cartItem(itm, i)
                  )}
                </Tbody>

                {isBreakDownSm && (
                  <tfoot className="cart-footer">
                    <tr>
                      <th scope="col" colSpan={3}>
                        <div className="cart-footer-wrapper">
                          <form className="form-promotion">
                            {showUnitsRemainingLabel && unitsRemainingLabel}
                            <Label htmlFor="promocode">
                              {`${t("cartViewComponent.have")} ${
                                !!ecomStore.appliedPromotions.length
                                  ? t("cartViewComponent.another")
                                  : t("cartViewComponent.a")
                              } ${t("cartViewComponent.promoCode")} `}
                              {!showPromoInput && (
                                <a
                                  onClick={(e) => {
                                    ecomStore.promoCodeError = "";
                                    setShowPromoInput(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  href="#"
                                  className="body-link-thin"
                                >
                                  {t("cartViewComponent.enterNow")}
                                </a>
                              )}
                            </Label>
                            {ecomStore.promoCodeError && (
                              <Alert color="danger" size="sm">
                                {ecomStore.promoCodeError}
                              </Alert>
                            )}
                            <Collapse isOpen={showPromoInput}>
                              <div className="form-field text-appear-container">
                                <input
                                  value={code}
                                  id="promocode"
                                  type="text"
                                  onChange={(e: any) => setCode(e.target.value)}
                                  className="form-control"
                                  placeholder={t(
                                    "cartViewComponent.promoCode1"
                                  )}
                                />
                                <Button
                                  color="default-link"
                                  onClick={() => addCode(code)}
                                >
                                  {t("cartViewComponent.apply")}
                                </Button>
                                <Button
                                  color="icon-action text-appear"
                                  onClick={() => {
                                    setShowPromoInput(false);
                                    resetCode();
                                  }}
                                >
                                  <FiX size="20" className="text-danger" />
                                  <span className="sr-only">
                                    {t("cartViewComponent.cancel")}
                                  </span>
                                </Button>
                              </div>
                            </Collapse>
                          </form>
                        </div>
                      </th>
                      <td className="text-right" colSpan={2}>{` `}</td>
                    </tr>
                    {ecomStore.appliedPromotions?.map((pro: any, i: number) => (
                      <tr key={i} className="text-appear-container">
                        <th scope="col" colSpan={3} className="pt-0">
                          <div className="cart-footer-wrapper">
                            {t("cartViewComponent.promo")} {pro.PromoCode} -{" "}
                            {pro.Description}
                            <Button
                              color="icon-action"
                              title="Remove promo code"
                              className="text-appear removePromo"
                              onClick={() =>
                                ecomStore.deletePromoCode(pro.PromoCode)
                              }
                            >
                              <AiOutlineDelete className="text-danger" />
                            </Button>
                          </div>
                        </th>
                        <td className="text-right pt-0" colSpan={2}>
                          {pro.DiscountAmountStringFormatted}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th scope="col" colSpan={3}>
                        <div className="font-weight-bold cart-footer-wrapper">{`Subtotal: `}</div>
                      </th>
                      <td
                        colSpan={2}
                        className=" font-weight-bold text-primary text-right"
                      >
                        {ecomStore.cart.SubtotalStringFormatted}
                      </td>
                    </tr>
                  </tfoot>
                )}
              </Table>
            ) : (
              <p
                className="text-center text-sm-left"
                style={{ marginTop: "10px" }}
              >
                {t("cartViewComponent.noItems")}{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    createOrderStore.setStepNumber(2);
                    e.preventDefault();
                  }}
                  className="body-link-thin"
                >
                  {t("cartViewComponent.checkOurProducts")}
                </a>
              </p>
            )}

            {!isBreakDownSm &&
              ecomStore.cart &&
              ecomStore.cartItems.length > 0 && (
                <div className="cart-footer">
                  <form className="form-promotion mb-3">
                    {showUnitsRemainingLabel && unitsRemainingLabel}
                    <Label htmlFor="promocode">
                      {`${t("cartViewComponent.have")} ${
                        !!ecomStore.appliedPromotions.length
                          ? t("cartViewComponent.another")
                          : t("cartViewComponent.a")
                      } ${t("cartViewComponent.promoCode")}`}
                      &nbsp;
                      {!showPromoInput && (
                        <a
                          onClick={(e) => {
                            setShowPromoInput(true);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          href="#"
                          className="body-link-thin"
                        >
                          {t("cartViewComponent.enterNow")}
                        </a>
                      )}
                    </Label>
                    {ecomStore.promoCodeError && (
                      <Alert color="danger" size="sm">
                        {ecomStore.promoCodeError}
                      </Alert>
                    )}
                    <Collapse isOpen={showPromoInput}>
                      <div className="form-field text-appear-container">
                        <input
                          value={code}
                          id="promocode"
                          type="text"
                          onChange={(e: any) => setCode(e.target.value)}
                          className="form-control"
                          placeholder={t("cartViewComponent.promoCode1")}
                        />
                        <Button
                          color="default-link"
                          onClick={() => addCode(code)}
                        >
                          {t("cartViewComponent.apply")}
                        </Button>
                        <Button
                          color="icon-action text-appear"
                          onClick={() => {
                            setShowPromoInput(false);
                            resetCode();
                          }}
                        >
                          <FiX size="20" className="text-danger" />
                          <span className="sr-only">
                            {t("cartViewComponent.cancel")}
                          </span>
                        </Button>
                      </div>
                    </Collapse>
                  </form>
                  {ecomStore.appliedPromotions?.map((pro: any, i: number) => (
                    <Row key={i} className="text-appear-container">
                      <Col xs="7" className="pt-0">
                        <div className="cart-footer-wrapper">
                          <span className="pr-1">
                            {t("cartViewComponent.promo")}
                          </span>
                          <span className="text-nowrap">
                            {pro.PromoCode} - {pro.Description}
                            <Button
                              color="icon-action"
                              title={t("cartViewComponent.removePromoCode")}
                              className="text-appear removePromo"
                              onClick={() =>
                                ecomStore.deletePromoCode(pro.PromoCode)
                              }
                            >
                              <AiOutlineDelete className="text-danger" />
                            </Button>
                          </span>
                        </div>
                      </Col>
                      <Col xs="5" className="text-right pt-0" colSpan={2}>
                        {pro.DiscountAmountStringFormatted}
                      </Col>
                    </Row>
                  ))}
                  <div className="row mt-2">
                    <div className="col-7 font-weight-bold">{`Subtotal: `}</div>
                    <div className="col-5 font-weight-bold text-primary text-right">
                      {ecomStore.cart.SubtotalStringFormatted}
                    </div>
                  </div>
                </div>
              )}

            {ecomStore.cart && ecomStore.cartItems.length > 0 && (
              <div className="row align-items-center mt-5">
                <div className="col-sm text-center text-sm-right order-sm-2">
                  <Button
                    color="primary"
                    className="px-5"
                    disabled={
                      !ecomStore.cartItems?.length || ecomStore.imageUpdate
                    }
                    onClick={checkout}
                  >
                    {" "}
                    {t("cartViewComponent.checkout")}
                  </Button>
                  <Button
                    color="danger"
                    className="px-5 mt-3"
                    onClick={() => setModalDelete(true)}
                  >
                    Delete Cart
                  </Button>
                  {authStore.AffirmEnabledOnline && (
                    <p
                      class="affirm-as-low-as"
                      data-page-type="cart"
                      data-amount={(ecomStore.cart.Subtotal * 100).toString()}
                    ></p>
                  )}
                </div>
                <div className="col-sm text-center text-sm-left order-sm-1 mt-3 mt-sm-0">
                  <Button
                    color="default-link"
                    onClick={() => {
                      createOrderStore.setStepNumber(2);
                    }}
                  >
                    <FiArrowLeft className="mr-2" />
                    {t("cartViewComponent.continueShopping")}
                  </Button>
                </div>
              </div>
            )}

            <RelatedProducts title="A Few Recommendations" />
          </div>
        </section>
      </motion.div>
      <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
        <ModalBody>Are you sure you want to delete your cart?</ModalBody>
        <ModalFooter>
          <Button
            style={{ padding: "0.5rem 2rem" }}
            color="danger"
            onClick={async () => {
              setCartSpinner(true);
              setModalDelete(false);
              await ecomStore.deleteCart();
              setCartSpinner(false);
            }}
          >
            Yes
          </Button>{" "}
          <Button
            style={{ padding: "0.5rem 2rem" }}
            color="secondary"
            onClick={() => setModalDelete(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalCartFull}
        toggle={toggleCartFullModal}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="md"
      >
        <ModalHeader
          toggle={toggleCartFullModal}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleCartFullModal}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("cartViewComponent.cartFullModalTitle")}
        </ModalHeader>
        <ModalBody>
          <p>{t("cartViewComponent.tooMany")}</p>
          <Row>
            <Col>
              <Button onClick={toggleCartFullModal} block className="mb-3">
                {t("cartViewComponent.ok")}
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalReplaceItem}
        toggle={toggleModalReplaceItem}
        wrapClassName="modal-box"
        modalTransition={{ timeout: 500 }}
        backdropTransition={{ timeout: 0 }}
        centered
        size="md"
      >
        <ModalHeader
          toggle={toggleModalReplaceItem}
          close={
            <Button
              color="icon-action"
              className="close"
              onClick={toggleModalReplaceItem}
            >
              <MdClose size="18" />
            </Button>
          }
        >
          {t("cartViewComponent.mustReplaceItemModalTitle")}
        </ModalHeader>
        <ModalBody>
          <p>{t("cartViewComponent.mustReplaceItemModalBody")}</p>
          <Row>
            <Col>
              <Button onClick={toggleModalReplaceItem} block className="mb-3">
                {t("cartViewComponent.ok")}
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </ModalBody>
      </Modal>
    </>
  );
};
export default inject(
  "authStore",
  "ecomStore",
  "createOrderStore",
  "navigationStore",
  "sessionStore",
  "compositeStore"
)(observer(CartView));
