import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormGroup, Button, Col, Row, Alert, Spinner } from "reactstrap";
import Select from "react-select";
import InputFloat from "../inputFloat";
import { observer, inject } from "mobx-react";
import Axios, { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { allCountriesFormattedForDropdown } from "../../util";

const JoyForm: React.FC<any> = ({
  clientStore,
  sessionStore,
  onSuccess,
  imgKey,
}) => {
  const { t } = useTranslation();
  const [asyncErr, setAsyncErr] = useState("");

  async function updateClient(obj: any) {
    const intervalText = obj.Age > 1 ? obj.Interval + "s" : obj.Interval;
    const Description = `Joy from ${obj.Country}, ${obj.FirstName}, ${obj.Age} ${intervalText}`;

    await Axios({
      method: "PUT",
      url: "/IBYPublicImage",
      data: {
        Description,
        SessionKey: sessionStore.activeSession.Key,
        SessionImageKey: imgKey,
      },
    }).catch((err: AxiosError) => setAsyncErr(err.message));
    onSuccess();
  }

  const initialValues = {
    FirstName: "",
    Age: "",
    Interval: "week",
    Country: clientStore.clientInfo.AddressCountry || "",
  };

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("Please fill this field"),
    Age: Yup.string().required("Please fill this field"),
    Interval: Yup.string().required("Please fill this field"),
    Country: Yup.string().required("Please fill this field"),
  });

  const intervalOptions = [
    { value: "week(s)", label: "week(s)" },
    { value: "month(s)", label: "month(s)" },
    { value: "year(s)", label: "year(s)" },
  ];

  const ageOptions = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={updateClient}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="row-sm">
            <FormGroup className="col-sm-12">
              <Field
                label={t("joyFormComponent.firstName")}
                name="FirstName"
                className="form-control"
                id="FirstName"
                component={InputFloat}
                disabled={isSubmitting}
              />
              {errors.FirstName && touched.FirstName ? (
                <div className="invalid-feedback">{errors.FirstName}</div>
              ) : null}{" "}
            </FormGroup>
            <Col className="col-sm-12">
              <p>{t("joyFormComponent.age")}</p>
            </Col>

            <FormGroup className="col-sm-6">
              <Select
                id="Age"
                classNamePrefix="react-select-group"
                className={`react-select-group-container mt-3`}
                name="Age"
                options={ageOptions}
                onChange={(val: any) => {
                  setFieldValue("Age", val.value);
                }}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                isDisabled={isSubmitting}
              />
              {errors.Age && touched.Age ? (
                <div className="invalid-feedback">{errors.Age}</div>
              ) : null}{" "}
            </FormGroup>

            <FormGroup className="col-sm-6">
              <Select
                id="Interval"
                classNamePrefix="react-select-group"
                className={`react-select-group-container mt-3`}
                name="Interval"
                options={intervalOptions}
                defaultValue={{ value: "week(s)", label: "week(s)" }}
                onChange={(val: any) => {
                  setFieldValue("Interval", val.value);
                }}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                isDisabled={isSubmitting}
              />
              {errors.Interval && touched.Interval ? (
                <div className="invalid-feedback">{errors.Interval}</div>
              ) : null}{" "}
            </FormGroup>

            <FormGroup className="col-sm-12">
              <Select
                id="country"
                classNamePrefix="react-select-group"
                className={`react-select-group-container mt-3`}
                name="country"
                defaultValue={allCountriesFormattedForDropdown.find(
                  (opt: any) => opt.value === values.Country
                )}
                options={allCountriesFormattedForDropdown}
                placeholder={t("joyFormComponent.country")}
                onChange={(val: any) => {
                  setFieldValue("Country", val.value);
                }}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                isDisabled={isSubmitting}
              />
              {errors.Country && touched.Country ? (
                <div className="invalid-feedback">{errors.Country}</div>
              ) : null}{" "}
            </FormGroup>
            <p>{t("joyFormComponent.permission")}</p>

            {asyncErr && <Alert color="danger">{asyncErr}</Alert>}
            <Col sm={{ offset: 3 }}>
              <Button
                type="submit"
                color="primary"
                style={{ marginRight: "1rem" }}
                className={isSubmitting ? "wait" : ""}
                disabled={isSubmitting}
              >
                {t("joyFormComponent.yes")}
                {isSubmitting && <Spinner color="#fff" size="sm" />}
              </Button>
              <Button
                onClick={() => onSuccess()}
                color="primary"
                className={isSubmitting ? "wait" : ""}
                disabled={isSubmitting}
              >
                {t("joyFormComponent.no")}
                {isSubmitting && <Spinner color="#fff" size="sm" />}
              </Button>
            </Col>
            <p style={{ fontSize: "0.85rem" }} className="mb-1 mt-3">
              {t("joyFormComponent.oneImage")}
            </p>
            <p style={{ fontSize: "0.85rem" }}>
              {t("joyFormComponent.warning")}
            </p>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default inject("sessionStore", "clientStore")(observer(JoyForm));
