import React from "react";
import LoginForm, {
  LoginFormData,
} from "../../components/forms/auth/loginForm";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Spinner } from "reactstrap";

import { postVariants } from "../../components/util";

const LoginPage: React.FC<any> = ({
  authStore,
  navigationStore,
  clientStore,
  guestStore,
  sessionStore,
}) => {
  const { t } = useTranslation();

  const redirectToWelcomeScreen = () => {
    const checkTrialDate = () => {
      let today = new Date().toISOString().split(".")[0];
      if (Date.parse(clientStore.statusDueDate) >= Date.parse(today)) {
        return true;
      } else {
        return false;
      }
    };

    const isTrialSubscription = checkTrialDate();

    //clientStore.getClientInfo();

    //Guest Free Account Flow

    if (
      clientStore.statusDescription === "Free" ||
      authStore?.accountName === "tpp"
    ) {
      navigationStore.push("/sessions");
      return;
    }

    /* Welcome Trial Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      isTrialSubscription
    ) {
      navigationStore.push("/subscription-trial");
      return;
    }

    /* Trial Expired Screen */
    if (
      authStore.requirePaidSubscription &&
      authStore.offerFreeTrial &&
      clientStore.statusDescription === "Trial" &&
      !isTrialSubscription
    ) {
      navigationStore.push("/subscription-expired");
      return;
    } else if (
      !sessionStore.activeSessionImages ||
      sessionStore.activeSessionImages.length === 0
    ) {
      navigationStore.push("/sessions");
    } else {
      navigationStore.push("/dashboard");
    }
  };

  // function generateUrl(type: string) {
  //   switch (type) {
  //     case "SESSION":
  //       return "/dashboard";
  //     case "CONTRACT":
  //       return "/contracts";
  //     case "WEBFORM":
  //       return "/forms";
  //     default:
  //       return "/account";
  //   }
  // }

  async function onSubmit(obj: LoginFormData, actions: any) {
    actions.setSubmitting(true);
    await authStore
      .login(obj)
      .then((res: any) => {
        if (res) {
          if (authStore.accountName.toLowerCase() === "annegeddes") {
            setTimeout(function () {
              redirectToWelcomeScreen();
            }, 1000);
          } else {
            redirectToWelcomeScreen();
          }
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  }

  if (authStore.isLoggingIn || sessionStore.loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>Logging you in...</p>
          <Spinner style={{ margin: "0 auto" }} color="primary" />
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={postVariants}
    >
      {authStore.accountName.toLowerCase() === "annegeddes" ? (
        <h1>{t("LoginPage.annegeddestitle")}</h1>
      ) : (
        <>
          <h1>{t("LoginPage.title")}</h1>
        </>
      )}

      {!authStore.disableEventSearch && <p>{t("LoginPage.description")}</p>}
      {!authStore.disableSignUpTab && (
        <p>
          If you already have an account, log in here to view your images, or
          click 'Sign Up' to create an account
        </p>
      )}
      <LoginForm
        emailLabel={t("LoginPage.email")}
        emailError={t("LoginPage.emailError")}
        passwordLabel={t("LoginPage.password")}
        passwordError={t("LoginPage.passwordError")}
        ajaxError={authStore.loginError ? t("LoginPage.ajaxError") : ""}
        submitBtn={t("LoginPage.loginButton")}
        onSubmit={onSubmit}
      />
      <p>
        <Link to={`/home/forgot-password`}>{t("LoginPage.forgotPass")}</Link>
      </p>
      {authStore.privateLabel === "picturepeople" && (
        <p>
          <a href={"mailto: CustomerService@picturepeople.com"}>
            Need Help? Contact CustomerService@picturepeople.com
          </a>
        </p>
      )}

      {/* <p style={{ display: "none" }}>Version {authStore.versionNumber}</p>       */}
      <p style={{ display: "none" }}>{`Version ${authStore.versionNumber}`}</p>
      {authStore.environmentLabel && (
        <p>{`Environment: ${authStore.environmentLabel} (${authStore.versionNumber})`}</p>
      )}
      <Helmet>
        <title>
          {t("LoginPage.title")}{" "}
          {authStore.companyName || authStore.companyName !== ""
            ? "| " + authStore.companyName
            : " "}
        </title>
      </Helmet>
    </motion.div>
  );
};
export default inject(
  "authStore",
  "navigationStore",
  "clientStore",
  "guestStore",
  "sessionStore"
)(observer(LoginPage));
