import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {
    FormGroup,
    Label,
    Button,
    Row,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';



const UsernameForm: React.FC<any> = ({ clientStore, onSuccess}) => {
    const { t } = useTranslation();
    const [asyncErr, setAsyncErr] = useState('');

    async function updateClient(obj: any) {
        await clientStore.updateClient(obj).catch((err:AxiosError)=>setAsyncErr(err.message))
        onSuccess();
    }
    
    const validationSchema =
        Yup.object().shape({
            OnlineUserName: Yup.string()
                .required(t('usernameFormComponent.enterUsername'))
        })

    const initialValues = {
        OnlineUserName: clientStore.clientInfo.OnlineUserName || '',
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={updateClient}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                isSubmitting
                /* and other goodies */
            }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className="row-sm">
                            <FormGroup className="col-sm-12">
                                <Label className="sr-only">{t('usernameFormComponent.onlineUsername')}</Label>
                                <Field
                                    placeholder={t('usernameFormComponent.username')}
                                    name="OnlineUserName"
                                    className = {`form-control ${errors.OnlineUserName?'is-invalid':''}`}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name = "OnlineUserName">{(msg)=><FormFeedback>{msg}</FormFeedback>}</ErrorMessage>
                            </FormGroup>
                        </Row>
                        {asyncErr &&
                            <Alert color = "danger">{asyncErr}</Alert>
                        }
                        <Button 
                          type="submit" 
                          color="primary" 
                          block 
                          className={isSubmitting ? 'wait' : ''}
                          disabled={isSubmitting}
                        >
                          {t('editAddressFormComponent.applyChange')}
                          {isSubmitting && <Spinner color = '#fff' size="sm" /> }
                        </Button>
                    </Form>
                )}
        </Formik>
    );

}
export default inject('clientStore')(observer(UsernameForm))
